<template>
  <div>
    <in-page-menu :activeBadge="activeBadge" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20 mt-1">
      <v-form @submit.prevent="submitOffspringForm" v-model="form.isValid">
        <div class="d-flex flex-wrap justify-center">
          <div class="mr-md-6 mr-3 flex-grow-1" style="max-width: 650px">
            <div class="d-flex">
              <v-menu :close-on-content-click="false" max-height="500" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    Toggle Visibility
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :key="index"
                    v-for="(field, index) in Object.keys(visibilityState)"
                  >
                    <v-checkbox
                      @change="
                        $utils.setObjectValues(
                          visibilityState,
                          !toggleVisibility
                        )
                      "
                      :value="toggleVisibility"
                      class="ma-0"
                      hide-details
                      v-if="field == 'All'"
                    ></v-checkbox>
                    <v-checkbox
                      class="ma-0"
                      hide-details
                      v-else
                      v-model="visibilityState[field]"
                    ></v-checkbox>
                    <v-subheader>
                      {{ visibilityStateTranslation[field] }}
                    </v-subheader>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- Reorder -->
              <v-checkbox
                class="mt-0"
                hide-details
                v-model="form.reorderIsActivated"
              >
                <template #label>
                  <div class="mt-2">Reorder</div>
                </template>
              </v-checkbox>
              <v-text-field
                class="mb-1 ml-2"
                clearable
                dense
                hide-details
                label="Group"
                outlined
                v-if="visibilityState.group"
                v-model="form.fields.group"
              >
              </v-text-field>
            </div>
            <draggable draggable=".draggable" group="offspringForm">
              <search-animal
                :allowedStatuses="['alive', 'cull', 'sick']"
                :isDraggable="form.reorderIsActivated"
                :resetFields="clearDam"
                :showEID="false"
                :visualErrorState="damRequired"
                @search-result="setAnimalResult($event, 'dam')"
                allowedGender="female"
                cardStyle
                cardTitle="Dam"
                class="mb-7"
                dense
              >
                <template #append-card-title>
                  <a
                    :href="`/animal-details?id=${parentSearchResults.dam.guid}`"
                    style="font-size: 1.3rem"
                    target="_blank"
                    v-if="
                      parentSearchResults.dam &&
                      !parentSearchResults.dam.animalIsNew
                    "
                  >
                    <p class="custom-main-tag ma-0 ml-2">
                      {{
                        parentSearchResults.dam &&
                        parentSearchResults.dam.doc &&
                        parentSearchResults.dam.doc.derived &&
                        parentSearchResults.dam.doc.derived.summaries &&
                        parentSearchResults.dam.doc.derived.summaries.main
                          .tagValues
                      }}
                    </p>
                  </a>
                </template>
                <template #bottom-section>
                  <div class="w-100">
                    <v-text-field
                      :error="damRequired"
                      :label="getTranslation('New Visual')"
                      class="my-2 ml-0 mb-0"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-model="form.fields.newDam"
                    >
                    </v-text-field>
                  </div>
                </template>
              </search-animal>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                class="py-2"
                id="offspring"
              >
                <v-row class="mx-0">
                  <v-btn
                    @click="
                      settingState.annualInternationalLetter.visualPrefix =
                        !settingState.annualInternationalLetter.visualPrefix;
                      settingState.annualInternationalLetter.visualSuffix = false;
                    "
                    class="mr-1 p-0"
                    color="primary"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                    {{ annualInternationalLetter }}
                  </v-btn>
                  <v-text-field
                    :error="isFieldEmpty('offspring') && eid.length != 15"
                    :prefix="
                      settingState.annualInternationalLetter.visualPrefix
                        ? annualInternationalLetter
                        : ''
                    "
                    :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                    :suffix="
                      settingState.annualInternationalLetter.visualSuffix
                        ? annualInternationalLetter
                        : ''
                    "
                    class="mb-3"
                    clearable
                    dense
                    hide-details
                    label="Offspring Visual"
                    outlined
                    style="max-width: 200px"
                    v-model="form.fields.offspring"
                  >
                  </v-text-field>
                  <v-btn
                    @click="
                      settingState.annualInternationalLetter.visualSuffix =
                        !settingState.annualInternationalLetter.visualSuffix;
                      settingState.annualInternationalLetter.visualPrefix = false;
                    "
                    class="ml-1"
                    color="primary"
                  >
                    {{ annualInternationalLetter }}
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                class="mb-3 d-flex justify-space-between"
              >
                <v-text-field
                  :error="
                    (isFieldEmpty('offspring') && eid.length != 15) ||
                    (eid.length > 0 && eid.length < 15) ||
                    (eid.length > 0 && !!eid.match(/[^\d]/))
                  "
                  :label="getTranslation('prefixEID')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="flex-shrink-1"
                  clearable
                  dense
                  hide-details
                  hint="EID"
                  id="eidPrefix"
                  outlined
                  style="max-width: 320px"
                  v-model="form.fields.eidPrefix"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        :disabled="flagIncrementEID"
                        class="ma-0 mr-2"
                        hide-details
                        v-model="lockedState.eidPrefix"
                      ></v-checkbox>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :error="
                    (isFieldEmpty('offspring') && eid.length != 15) ||
                    (eid.length > 0 && eid.length < 15) ||
                    (eid.length > 0 && !!eid.match(/[^\d]/))
                  "
                  :label="getTranslation('postfixEID')"
                  class="flex-shrink-1"
                  clearable
                  dense
                  hide-details
                  hint="EID"
                  id="eidPostfix"
                  outlined
                  style="max-width: 320px"
                  v-model="form.fields.eidPostfix"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <span
                        @click="toggleIncrementEID"
                        :class="flagIncrementEID ? 'btn-success' : 'btn-danger'"
                        class="btn mr-2 p-0"
                      >
                        &nbsp;+1&nbsp;
                      </span>
                      <v-checkbox
                        :disabled="flagIncrementEID"
                        class="ma-0"
                        hide-details
                        v-model="lockedState.eidPostfix"
                      ></v-checkbox>
                    </div>
                    <v-icon disabled class="mt-1"> mdi-eye-minus </v-icon>
                  </template>
                </v-text-field>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="birthDateOffspring"
              >
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="form.showBirthDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :error="isFieldEmpty('birthDate')"
                      :label="getLabelTranslation('birthDate')"
                      :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                      append-icon="mdi-calendar"
                      class="mb-3"
                      dense
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="form.fields.birthDate"
                      v-on:click:append="form.showBirthDateCalendar = true"
                      v-on="on"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.birthDate"
                          ></v-checkbox>
                          <v-icon class="mt-1" disabled> mdi-eye-minus </v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="form.showBirthDateCalendar = false"
                    v-model="form.fields.birthDate"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div :class="{ draggable: form.reorderIsActivated }" id="breed">
                <v-select
                  :items="options.breeds"
                  :label="getLabelTranslation('breed')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="breed"
                  outlined
                  return-object
                  v-if="visibilityState.breed"
                  v-model="form.fields.breed"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.breed"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.breed = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
              </div>
              <div :class="{ draggable: form.reorderIsActivated }" id="sex">
                <v-select
                  :error="isFieldEmpty('sex')"
                  :label="getLabelTranslation('sex')"
                  :items="options.sexes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="sex"
                  outlined
                  return-object
                  v-model="form.fields.sex"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.sex"
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="birthWeight"
              >
                <v-text-field
                  :error="birthWeightError"
                  :label="getLabelTranslation('birthWeight')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  id="birthWeight"
                  outlined
                  v-if="visibilityState.birthWeight"
                  v-model="form.fields.birthWeight"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.birthWeight"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.birthWeight = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="calvingScore"
              >
                <v-select
                  :items="options.calvingScores"
                  :label="getLabelTranslation('calvingEase')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  item-value="value"
                  outlined
                  v-if="visibilityState.calvingScore"
                  v-model="form.fields.calvingScore"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.calvingScore"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.calvingScore = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="calfVigor"
              >
                <v-select
                  :error="calfVigorError"
                  :items="options.calfVigors"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Calf Vigor"
                  outlined
                  v-model="form.fields.calfVigor"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.calfVigor"
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
              </div>
              <div :class="{ draggable: form.reorderIsActivated }" id="status">
                <v-select
                  :error="isFieldEmpty('status')"
                  :label="getLabelTranslation('status')"
                  :items="options.statuses"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  item-value="value"
                  outlined
                  v-model="form.fields.status"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.status"
                      ></v-checkbox>
                      <v-icon disabled class="mt-1"> mdi-eye-minus </v-icon>
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="calfComment"
              >
                <v-textarea
                  :label="getLabelTranslation('calfComment')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  rows="2"
                  v-if="visibilityState.calfComment"
                  v-model="form.fields.calfComment"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.calfComment"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.calfComment = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-textarea>
              </div>
              <div
                :class="{ draggable: form.reorderIsActivated }"
                id="damComment"
              >
                <v-textarea
                  :label="getLabelTranslation('damComment')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  rows="2"
                  v-if="visibilityState.damComment"
                  v-model="form.fields.damComment"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.damComment"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.damComment = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-textarea>
              </div>
              <v-row
                :class="{ draggable: form.reorderIsActivated }"
                class="ma-0"
                v-if="visibilityState.vaccinationsList"
              >
                <v-col
                  class="d-flex align-center mb-3 pa-0"
                  cols="1"
                  style="max-width: 5%"
                  v-if="form.reorderIsActivated"
                >
                  <v-icon> mdi-drag </v-icon>
                </v-col>
                <v-col
                  :cols="form.reorderIsActivated ? 10 : 11"
                  :style="{ 'min-width': minWidth }"
                  class="pa-0"
                >
                  <v-row class="ma-0">
                    <v-col
                      :key="idx"
                      class="pa-0"
                      cols="12"
                      v-for="({ actualDose, treatment }, idx) in form.fields
                        .vaccinationsList"
                    >
                      <v-row class="ma-0">
                        <v-col cols="11" class="pa-0">
                          <v-row class="ma-0">
                            <v-col class="pa-0" cols="7" md="8">
                              <v-select
                                :error="
                                  actualDose && $utils.isWhitespace(treatment)
                                "
                                :items="options.treatments"
                                :label="getLabelTranslation('treatment')"
                                :menu-props="{
                                  offsetY: true,
                                  closeOnClick: true,
                                }"
                                class="mb-3"
                                clearable
                                dense
                                hide-details
                                item-text="name"
                                outlined
                                return-object
                                v-model="
                                  form.fields.vaccinationsList[idx].treatment
                                "
                              >
                                <template #append-outer>
                                  <div class="d-flex align-center">
                                    <v-checkbox
                                      class="ma-0"
                                      hide-details
                                      v-model="
                                        lockedState.vaccinationsList[idx]
                                          .treatment
                                      "
                                    ></v-checkbox>
                                  </div>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col class="pa-0" cols="5" md="4">
                              <v-text-field
                                :error="
                                  treatment && $utils.isWhitespace(actualDose)
                                "
                                :label="getLabelTranslation('doseGiven')"
                                :rules="[rules.positiveOrEmpty]"
                                class="mb-3"
                                clearable
                                dense
                                hide-details="auto"
                                outlined
                                type="number"
                                v-model="
                                  form.fields.vaccinationsList[idx].actualDose
                                "
                              >
                                <template #append-outer>
                                  <div class="d-flex align-center">
                                    <v-checkbox
                                      class="ma-0"
                                      hide-details
                                      v-model="
                                        lockedState.vaccinationsList[idx]
                                          .actualDose
                                      "
                                    ></v-checkbox>
                                  </div>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="pa-0 d-flex align-center" cols="1">
                          <v-btn
                            @click="addVaccinationItem"
                            class="px-0 mb-3"
                            color="success"
                            max-width="36"
                            min-width="36"
                            v-if="
                              idx === form.fields.vaccinationsList.length - 1
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            @click="removeVaccinationItem(idx)"
                            class="px-0 mb-3"
                            color="error"
                            max-width="36"
                            min-width="36"
                            v-else
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  class="d-flex align-center pa-0"
                  cols="1"
                  style="max-width: 4%"
                >
                  <v-icon @click="visibilityState.vaccinationsList = false">
                    mdi-eye-minus
                  </v-icon>
                </v-col>
              </v-row>
              <div :class="{ draggable: form.reorderIsActivated }" id="color">
                <v-select
                  :items="options.colors"
                  :label="getLabelTranslation('color')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  outlined
                  return-object
                  v-if="visibilityState.color"
                  v-model="form.fields.color"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.color"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.color = false"
                      >
                        mdi-eye-minus
                      </v-icon>
                    </div>
                  </template>
                </v-select>
              </div>
            </draggable>
            <div class="my-4">
              <div class="d-flex flex-wrap">
                <v-btn
                  class="light-blue darken-4 white--text flex-grow-1"
                  large
                  type="submit"
                >
                  {{ getLabelTranslation("save") }}
                </v-btn>
                <v-btn @click="clearFields" color="primary" large outlined>
                  {{ getTranslation("Reset") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div
            class="mr-md-6 mr-3 flex-grow-1 justify-content-center mt-11"
            style="max-width: 650px"
          >
            <draggable draggable=".draggable" group="offspringForm">
              <search-animal
                :allowedStatuses="['alive', 'cull', 'sick']"
                :isDraggable="form.reorderIsActivated"
                :resetFields="clearAdoptDam"
                :showEID="false"
                @search-result="setAnimalResult($event, 'adoptDam')"
                allowedGender="female"
                cardStyle
                cardTitle="Adopt Dam"
                class="mb-3"
                dense
              >
                <template #append-card-title>
                  <a
                    :href="`/animal-details?id=${parentSearchResults.adoptDam.guid}`"
                    style="font-size: 1.3rem"
                    target="_blank"
                    v-if="
                      parentSearchResults.adoptDam &&
                      !parentSearchResults.adoptDam.animalIsNew
                    "
                  >
                    <p class="custom-main-tag ma-0 ml-2">
                      {{
                        parentSearchResults.adoptDam &&
                        parentSearchResults.adoptDam.doc &&
                        parentSearchResults.adoptDam.doc.derived &&
                        parentSearchResults.adoptDam.doc.derived.summaries &&
                        parentSearchResults.adoptDam.doc.derived.summaries.main
                          .tagValues
                      }}
                    </p>
                  </a>
                </template>
                <template #bottom-section>
                  <div class="w-100">
                    <v-text-field
                      :label="getTranslation('New Visual')"
                      class="my-2 ml-0 mb-0"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-model="form.fields.newAdoptDam"
                    >
                    </v-text-field>
                  </div>
                </template>
              </search-animal>
              <search-animal
                :allowedStatuses="['alive', 'cull', 'sick', 'semen']"
                :isDraggable="form.reorderIsActivated"
                :resetFields="clearSire"
                :showEID="false"
                @search-result="setAnimalResult($event, 'sire')"
                allowedGender="male"
                cardStyle
                cardTitle="Sire"
                class="mb-3"
                dense
              >
                <template #append-card-title>
                  <a
                    :href="`/animal-details?id=${parentSearchResults.sire.guid}`"
                    style="font-size: 1.3rem"
                    target="_blank"
                    v-if="
                      parentSearchResults.sire &&
                      !parentSearchResults.sire.animalIsNew
                    "
                  >
                    <p class="custom-main-tag ma-0 ml-2">
                      {{
                        parentSearchResults.sire &&
                        parentSearchResults.sire.doc &&
                        parentSearchResults.sire.doc.derived &&
                        parentSearchResults.sire.doc.derived.summaries &&
                        parentSearchResults.sire.doc.derived.summaries.main
                          .tagValues
                      }}
                    </p>
                  </a>
                </template>
                <template #bottom-section>
                  <div class="w-100">
                    <v-text-field
                      :label="getTranslation('New Visual')"
                      class="my-2 ml-0 mb-0"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-model="form.fields.newSire"
                    >
                    </v-text-field>
                  </div>
                </template>
              </search-animal>
            </draggable>
          </div>
        </div>
      </v-form>
    </v-row>
    <v-row class="ml-4 mr-4">
      <a
        :class="{
          'btn btn-lg active mt-2': year.year == selectedYear,
          disabled: year.disabled,
          'btn mt-4': year.year != selectedYear,
        }"
        :key="idx"
        @click="selectedYear = year.year"
        v-for="(year, idx) in years"
      >
        {{ year.year }}
      </a>
      <!-- Tables -->
      <div class="px-4">
        <!-- Table for year -->
        <div class="my-4">
          <v-subheader class="pl-0 title">Offspring</v-subheader>
          <div class="d-flex flex-wrap">
            <div class="flex-grow-1 d-flex align-center mb-2">
              <!-- Reset search -->
              <v-btn class="mr-2" @click="resetSearch" color="primary" text
                >Reset Search</v-btn
              >
              <!-- Export -->
              <export-button
                :headers="offspringSummaryHeaders"
                :items="offspringSummary.data"
                filename="Offspring"
              />
            </div>
            <v-text-field
              :label="getTranslation('search')"
              @input="runGlobalSearch"
              append-icon="mdi-magnify"
              class="mb-2"
              clearable
              dense
              hide-details
              outlined
              style="max-width: 250px"
              v-model="offspringSummary.search"
            ></v-text-field>
          </div>
          <v-data-table
            :headers="offspringSummaryHeaders"
            :loading="loading"
            :items="offspringSummary.data"
            @current-items="getCurrentItemsOfMainTable"
            class="elevation-1"
            mobile-breakpoint="0"
            must-sort
            sort-by="createdOn"
            sort-desc
          >
            <template #item.tagValues="{ item }">
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: item.guid },
                }"
                class="subtitle-1 text-none"
                target="_blank"
              >
                {{ item.tagValues }}
              </router-link>
            </template>
            <template #item.birthDate="{ item }">
              <div>
                {{ $utils.renderValueAs(item.birthDate, "datetime", true) }}
              </div>
            </template>
            <template #item.createdOn="{ item }">
              <div>
                {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
              </div>
            </template>
            <template #item.damTags="{ item }">
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: item.damId },
                }"
                class="subtitle-1 text-none"
                target="_blank"
              >
                {{ item.damTags }}
              </router-link>
            </template>
            <template #item.sireTags="{ item }">
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: item.sireId },
                }"
                class="subtitle-1 text-none"
                target="_blank"
              >
                {{ item.sireTags }}
              </router-link>
            </template>
            <template #header="{ props }">
              <tr>
                <td :key="idx" v-for="(header, idx) in props.headers">
                  <v-text-field
                    :label="header.text"
                    class="my-2"
                    dense
                    height="40"
                    hide-details
                    solo
                    v-if="header.filter"
                    v-model="offspringSummary.searchInputs[header.value]"
                  ></v-text-field>
                  <div v-else class="my-2" style="height: 40px"></div>
                  <v-divider class="mt-0"></v-divider>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- Table for day -->
        <div class="mb-4">
          <v-subheader class="pl-0 title">Daily Summary</v-subheader>
          <div class="d-flex justify-end">
            <v-text-field
              :label="getTranslation('search')"
              append-icon="mdi-magnify"
              class="mb-2"
              clearable
              dense
              hide-details
              outlined
              style="max-width: 250px"
              v-model="dailySummary.search"
            ></v-text-field>
          </div>
          <v-data-table
            :headers="dailyAndMonthSummaryHeaders"
            :loading="loading"
            :items="dailySummary.data"
            :search="dailySummary.search"
            class="elevation-1"
            mobile-breakpoint="0"
          >
          </v-data-table>
        </div>
        <!-- Table for month -->
        <div class="mb-7">
          <v-subheader class="pl-0 title">Monthly Summary</v-subheader>
          <div class="d-flex justify-end">
            <v-text-field
              :label="getTranslation('search')"
              append-icon="mdi-magnify"
              class="mb-2"
              clearable
              dense
              hide-details
              outlined
              style="max-width: 250px"
              v-model="monthlySummary.search"
            ></v-text-field>
          </div>
          <v-data-table
            :headers="dailyAndMonthSummaryHeaders"
            :loading="loading"
            :items="monthlySummary.data"
            :search="monthlySummary.search"
            class="elevation-1"
            mobile-breakpoint="0"
          >
            <template #item.minBirthDateOfGroup="{ item }">
              <div>
                {{ $moment(item.minBirthDateOfGroup).format("YYYY MMM") }}
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import TranslationMixin from "../mixins/Translations";
export default {
  name: "Offspring",
  metaInfo: {
    title: "Offspring",
  },
  mixins: [TranslationMixin],
  data: () => {
    const initialStateOfFields = {
      adoptDam: null,
      birthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      birthWeight: null,
      breed: null,
      calfComment: null,
      calfVigor: null,
      calvingScore: null,
      color: null,
      dam: null,
      damComment: null,
      doseAdministered: null,
      eidPostfix: null,
      eidPrefix: null,
      group: null,
      newDam: null,
      newAdoptDam: null,
      newSire: null,
      offspring: null,
      sex: null,
      sire: null,
      status: "marketable",
      vaccinationsList: [{ treatment: null, actualDose: null }],
    };
    return {
      activeBadge: "?",
      animalsByBirthYear: null,
      clearAdoptDam: false,
      clearDam: false,
      clearSire: false,
      dailySummary: {
        data: [],
        search: null,
      },
      flagIncrementEID: false,
      form: {
        fields: JSON.parse(JSON.stringify(initialStateOfFields)),
        isValid: true,
        reorderIsActivated: false,
        showBirthDateCalendar: false,
        mandatoryFields: [
          { field: "birthDate", name: "Birth Date" },
          { field: "calfVigor", name: "Calf Vigor" },
          { field: "status", name: "Status" },
          { field: "sex", name: "Sex" },
        ],
      },
      geoLocation: null,
      herdMeta: null,
      loading: false,
      initialStateOfFields,
      lockedState: {
        birthDate: false,
        birthWeight: false,
        breed: false,
        calfComment: false,
        calvingScore: false,
        color: false,
        damComment: false,
        doseAdministered: false,
        eidPrefix: false,
        eidPostfix: false,
        sex: false,
        status: false,
        vaccinationsList: [{ actualDose: false, treatment: false }],
      },
      monthlySummary: {
        data: [],
        search: null,
      },
      offspringSummary: {
        data: [],
        dataClone: [],
        filteredItemsByVuetify: [],
        search: null,
        searchInputs: {
          birthDate: null,
          comment: null,
          damTags: null,
          pastureOrPen: null,
          sex: null,
          sireTags: null,
          status: null,
          tagValues: null,
        },
      },
      options: {
        breeds: [],
        calfVigors: [],
        calvingScores: [],
        colors: [],
        herds: [],
        locations: [],
        sexes: [],
        statuses: [],
        treatments: [],
      },
      parentSearchResults: {
        adoptDam: null,
        sire: null,
        dam: null,
      },
      rules: {
        EID: (value) =>
          // convert null to string and numbers to string and strings to string
          /^$|^\d{15}$/.test(
            (value == null ? "" : value + "")
              .replace(/-/g, "")
              .replace(/ /g, "")
          ) || "Invalid ID",
        positiveOrEmpty: (number) =>
          +number > 0 || !number ? true : "Number must be positive or empty",
      },
      settingState: {
        annualInternationalLetter: {
          visualPrefix: false,
          visualSuffix: false,
        },
      },
      selectedYear: null,
      visibilityState: {
        All: true, // Stub reference to all others
        birthWeight: true,
        breed: true,
        calfComment: true,
        calvingScore: true,
        color: true,
        damComment: true,
        vaccinationsList: true,
      },
      years: [],
    };
  },
  computed: {
    annualInternationalLetter: function () {
      // From https://guidelines.beefimprovement.org/index.php/International_animal_identification_letter
      return [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "M",
        "N",
        "P",
        "R",
        "S",
        "T",
        "U",
        "W",
        "X",
        "Y",
        "Z",
      ][(new Date().getFullYear() - 121) % 22];
    },
    birthWeightError: function () {
      return (
        !this.isFieldEmpty("birthWeight") &&
        (+this.form.fields.birthWeight === 0 ||
          !this.$utils.checkIfIsCorrectNumericValue(
            this.form.fields.birthWeight.trim()
          ))
      );
    },
    calfVigorError: function () {
      return (
        this.isFieldEmpty("calfVigor") ||
        this.vigorImpliesDead(this.form.fields.calfVigor) !==
          this.statusImpliesDead
      );
    },
    calfVisualWithAnnualInternationalLetter: function () {
      const visual = this.form.fields.offspring
        ? this.form.fields.offspring.trim()
        : null;

      if (!visual) return null;

      if (this.settingState.annualInternationalLetter.visualPrefix)
        return this.annualInternationalLetter + " " + visual;
      if (this.settingState.annualInternationalLetter.visualSuffix)
        return visual + " " + this.annualInternationalLetter;
      return visual;
    },
    damRequired: function () {
      return (
        !this.form.fields.newDam &&
        (this.parentSearchResults.dam === null ||
          this.parentSearchResults.dam.animalIsNew) &&
        this.isFieldEmpty("dam")
      );
    },
    eid: function () {
      return (
        "" +
        (this.form.fields.eidPrefix || "") +
        (this.form.fields.eidPostfix || "")
      );
    },
    minWidth: function () {
      return this.form.reorderIsActivated ? "91%" : "96%";
    },
    toggleVisibility: function () {
      return this.$utils.evaluateObjectValues(this.visibilityState, true);
    },
    dailyAndMonthSummaryHeaders: function () {
      return [
        { text: this.getTranslation("date"), value: "minBirthDateOfGroup" },
        { text: "# " + this.getTranslation("alive"), value: "totalAlive" },
        { text: "# " + this.getTranslation("dead"), value: "totalDead" },
        { text: this.getTranslation("offspringPage.total"), value: "total" },
      ];
    },
    offspringSummaryHeaders: function () {
      return [
        {
          filter: this.customFilter("tagValues"),
          sortable: false,
          text: this.getTranslation("fields.tagValues"),
          value: "tagValues",
        },
        {
          filter: this.customFilter("birthDate"),
          text: this.getTranslation("birthDate"),
          value: "birthDate",
        },
        {
          filter: this.customFilter("receivingRanch"),
          text: this.getTranslation("receivingRanch"),
          value: "receivingRanch",
        },
        {
          filter: this.customFilter("transferred"),
          text: this.getTranslation("transferred"),
          value: "transferred",
        },
        {
          filter: this.customFilter("damTags"),
          text: this.getTranslation("damTags"),
          value: "damTags",
        },
        {
          filter: this.customFilter("sex"),
          text: this.getLabelTranslation("sex"),
          value: "sex",
        },
        {
          filter: this.customFilter("location"),
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
        },
        {
          filter: this.customFilter("status"),
          text: this.getTranslation("status"),
          value: "status",
        },
        {
          filter: this.customFilter("sireTags"),
          text: this.getTranslation("sireTags"),
          value: "sireTags",
        },
        {
          filter: this.customFilter("comment"),
          text: this.getTranslation("comment"),
          value: "comment",
        },
        {
          filter: this.customFilter("createdOn"),
          text: this.getTranslation("createdOn"),
          value: "createdOn",
        },
      ];
    },
    statusImpliesDead: function () {
      return this.form.fields.status == "dead";
    },
    visibilityStateTranslation: function () {
      return {
        All: this.getTranslation("All"),
        birthDate: this.getTranslation("birthDate"),
        birthWeight: this.getTranslation("birthWeight"),
        breed: this.getTranslation("breed"),
        calfComment: this.getTranslation("calfComment"),
        calfVigor: this.getTranslation("fields.vigor"),
        calvingScore: this.getTranslation(
          "calfWeanPage.information.calvingScore"
        ),
        color: this.getTranslation("color"),
        damComment: this.getTranslation("damComment"),
        gender: this.getTranslation("gender"),
        group: this.getTranslation("group"),
        herd: this.getTranslation("Herd"),
        sex: this.getTranslation("sex"),
        vaccinationsList: this.getTranslation("treatment"),
      };
    },
  },
  watch: {
    "form.fields.calfVigor": function (val) {
      if (this.vigorImpliesDead(val)) {
        this.form.fields.status = "dead";
        return;
      }
      this.form.fields.status = "marketable";
    },
    "settingState.annualInternationalLetter.visualPrefix": function (val) {
      localStorage.setItem(
        this.orgUserScoping(
          "settingState.annualInternationalLetter.visualPrefix"
        ),
        val
      );
    },
    "settingState.annualInternationalLetter.visualSuffix": function (val) {
      localStorage.setItem(
        this.orgUserScoping(
          "settingState.annualInternationalLetter.visualSuffix"
        ),
        val
      );
    },
    flagIncrementEID: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.flagIncrementEID`,
          JSON.stringify(val)
        );
        this.storeFormValuesOnLocalStorage();
      },
    },
    lockedState: {
      deep: true,
      handler: function (state) {
        localStorage.setItem("offspring.lockedState", JSON.stringify(state));
        this.storeFormValuesOnLocalStorage();
      },
    },
    "form.fields": {
      deep: true,
      handler: function () {
        this.storeFormValuesOnLocalStorage();
      },
    },
    visibilityState: {
      deep: true,
      handler: function () {
        localStorage.setItem(
          "offspring.visibility.state",
          JSON.stringify(this.visibilityState)
        );
      },
    },
    selectedYear: "fillTables",
  },
  created: async function () {
    this.setValuesFromLocalStorage();
    await this.fillSelectOptions();
    await this.updatePillDataAndSelectedYear();
    this.fillTables();
  },
  methods: {
    getDefaultStateOfVisibility: function () {
      return {
        All: true, // Stub reference to all others
        birthWeight: true,
        breed: true,
        calfComment: true,
        calvingScore: true,
        color: true,
        damComment: true,
        vaccinationsList: true,
      };
    },
    addVaccinationItem: function () {
      this.form.fields.vaccinationsList.push({
        treatment: null,
        actualDose: null,
      });
      this.lockedState.vaccinationsList.push({
        treatment: false,
        actualDose: false,
      });
    },
    removeVaccinationItem: function (idx) {
      this.form.fields.vaccinationsList.splice(idx, 1);
      this.lockedState.vaccinationsList.splice(idx, 1);
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    toggleIncrementEID: function () {
      this.lockedState.eidPrefix =
        this.lockedState.eidPostfix =
        this.flagIncrementEID =
          !this.flagIncrementEID;
    },
    incrementEID: function () {
      if (this.eid == null || this.eid.length === 0) {
        return;
      }

      const eid = +this.eid + 1 + "";
      const length1 = this.form.fields.eidPrefix.length;

      this.form.fields.eidPrefix = eid.substring(0, length1);
      this.form.fields.eidPostfix = eid.substring(length1, 15);
    },
    setAnimalResult: function ({ animal, visual }, type) {
      this.parentSearchResults[type] =
        animal || HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);

      if (type === "dam") {
        const inheritableInformation = Animal.inheritableInfoOf(
          this.parentSearchResults[type]
        );
        if (inheritableInformation.breed) {
          const found = this.options.breeds.find(
            ({ breed }) => breed == inheritableInformation.breed
          );
          if (found) {
            this.form.fields.breed = found;
          }
        }
      }
    },
    setValuesFromLocalStorage: function () {
      this.settingState.annualInternationalLetter.visualPrefix = JSON.parse(
        localStorage.getItem(
          this.orgUserScoping(
            "settingState.annualInternationalLetter.visualPrefix"
          )
        )
      );
      this.settingState.annualInternationalLetter.visualSuffix = JSON.parse(
        localStorage.getItem(
          this.orgUserScoping(
            "settingState.annualInternationalLetter.visualSuffix"
          )
        )
      );

      const visibilityState = this.$utils.getItemFromLocalStorage(
        "offspring.visibility.state"
      );
      // Check for missing fields too
      const visibilityKeys = Object.keys(visibilityState);
      // Add those missing fields
      const missingVisibilityKeys = Object.keys(
        this.getDefaultStateOfVisibility()
      ).filter((key) => !visibilityKeys.includes(key));
      if (missingVisibilityKeys.length > 0) {
        missingVisibilityKeys.forEach((key) => (visibilityState[key] = true));
      } else if (visibilityKeys.length > 0)
        this.visibilityState = visibilityState;

      const formValues = this.$utils.getItemFromLocalStorage(
        "offspring.formValues"
      );
      if (Object.keys(formValues).length > 0)
        this.form.fields = {
          ...this.$options.data().form.fields,
          ...formValues,
        };

      this.flagIncrementEID =
        JSON.parse(localStorage.getItem(`${this.$userID}.flagIncrementEID`)) ||
        false;

      const lockedState = this.$utils.getItemFromLocalStorage(
        "offspring.lockedState"
      );
      if (!lockedState.vaccinationsList) {
        lockedState.vaccinationsList = [
          { actualDose: false, treatment: false },
        ];
      }

      if (Object.keys(lockedState).length > 0) this.lockedState = lockedState;

      if (this.form.fields.birthDate == null)
        this.form.fields.birthDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
    },
    storeFormValuesOnLocalStorage: function () {
      const lockedFields = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, true)
      );
      const formValues = this.$utils.filterObjectByArrayOfKeys(
        this.form.fields,
        lockedFields
      );

      if (this.form.fields.vaccinationsList.length > 1)
        formValues.vaccinationsList = this.form.fields.vaccinationsList.map(
          (treatment, idx) => {
            const currentState = this.lockedState.vaccinationsList[idx];
            return {
              actualDose: currentState.actualDose ? treatment.actualDose : null,
              treatment: currentState.treatment ? treatment.treatment : null,
            };
          }
        );
      localStorage.setItem(
        "offspring.formValues",
        JSON.stringify({ ...this.$options.data().form.fields, ...formValues })
      );
    },
    runGlobalSearch: function () {
      const input = this.offspringSummary.search;
      if (!input || input.length == 0)
        this.offspringSummary.data = this.offspringSummary.dataClone;
      else {
        this.offspringSummary.data = this.offspringSummary.dataClone.filter(
          (record) => {
            const results = Object.keys(record).filter((key) => {
              const value = record[key];
              if (value) {
                return String(value)
                  .toLowerCase()
                  .includes(input.trim().toLowerCase());
              }
            });
            if (results.length > 0) return record;
          }
        );
      }
    },
    getCurrentItemsOfMainTable: function (items) {
      this.offspringSummary.filteredItemsByVuetify = items;
    },
    resetSearch: function () {
      if (window.confirm("Reset search?")) {
        this.offspringSummary.search = null;
        Object.keys(this.offspringSummary.searchInputs).forEach((input) => {
          this.offspringSummary.searchInputs[input] = null;
        });
        this.offspringSummary.data = this.offspringSummary.dataClone;
      }
    },
    customFilter: function (item) {
      const search = (this.offspringSummary.searchInputs[item] || "")
        .trim()
        .toLowerCase();
      return function (val) {
        const value = String(val || "")
          .trim()
          .toLowerCase();
        return value != null && search != null && value.indexOf(search) !== -1;
      };
    },
    fillTables: function () {
      this.offspringSummary.data = this.getSummaryTableForYear();
      this.offspringSummary.dataClone = this.$utils.copyObject(
        this.offspringSummary.data
      );
      this.dailySummary.data = this.getSummaryTableForYearByGranule("day");
      this.monthlySummary.data = this.getSummaryTableForYearByGranule("month");
    },
    getSummaryTableForYear: function () {
      return (this.animalsByBirthYear[this.selectedYear] || [])
        .map((animalBirthDateAndSummary) =>
          this.filterOnlyRequiredProperties(animalBirthDateAndSummary.summary)
        )
        .sort((a, b) => b.birthDate.localeCompare(a.birthDate));
    },
    filterOnlyRequiredProperties: function (summary) {
      const requiredProperties = [
        ...this.offspringSummaryHeaders.map(({ value }) => value),
        "damId",
        "guid",
        "sireId",
      ];

      Object.keys(summary).forEach((summaryProp) => {
        if (!requiredProperties.includes(summaryProp))
          delete summary[summaryProp];
      });

      return summary;
    },
    getSummaryTableForYearByGranule: function (granularity) {
      const data = this.animalsByBirthYear[this.selectedYear];
      let tableDataByGranule = (data || []).reduce(
        (reduction, animalBirthDateAndSummary) => {
          const granule = moment(animalBirthDateAndSummary.birthDate)
            .startOf(granularity)
            .unix();
          let currentSet = reduction[granule];
          if (!currentSet)
            currentSet = reduction[granule] = {
              minBirthDateOfGroup: animalBirthDateAndSummary.birthDate,
              total: 0,
              totalAlive: 0,
              totalDead: 0,
            };

          currentSet.total++;

          if (
            currentSet.minBirthDateOfGroup > animalBirthDateAndSummary.birthDate
          )
            currentSet.minBirthDateOfGroup =
              animalBirthDateAndSummary.birthDate;

          let alive =
            animalBirthDateAndSummary.summary.status.toLowerCase() !== "dead";
          if (alive) currentSet.totalAlive++;
          else currentSet.totalDead++;

          return reduction;
        },
        {}
      );

      return Object.keys(tableDataByGranule)
        .reverse()
        .reduce((reduction, month) => {
          const monthData = tableDataByGranule[month];
          monthData.minBirthDateOfGroup = moment(
            monthData.minBirthDateOfGroup.toISOString()
          ).format("YYYY MMM DD");
          reduction.push(monthData);
          return reduction;
        }, []);
    },
    fillSelectOptions: async function () {
      this.options.breeds = this.getEnumOptions("breeds").map((item) => {
        return {
          id: item.value,
          breed: item.label,
        };
      });
      this.herdMeta = this.$herdMeta;
      this.herdMeta.loaded.then(async () => {
        const sexes = await this.herdMeta.getMetaSexesAsync(true, true, true);
        this.options.sexes = sexes;

        const treatments = await this.herdMeta.getMetaVaccinationsAsync(
          true,
          true,
          true
        );
        this.options.treatments = treatments || [];

        const locations = await this.herdMeta.getMetaLocationsAsync(
          true,
          true,
          true
        );
        this.options.locations = locations.map(({ id, name }) => ({
          id,
          name,
        }));

        const colors = await this.herdMeta.getMetaColorsAsync(true, true, true);
        this.options.colors = colors.map(({ id, color: name }) => ({
          id,
          name,
        }));
      });

      this.options.calvingScores = this.getEnumOptions("birthCalvingEases");
      this.options.calfVigors = this.getEnumOptions("vigors");
      this.options.statuses = this.getEnumOptions("statusesOffspring");

      const herdOptions = await this.herdMeta.getMetaHerdsAsync(
        true,
        true,
        false
      );
      if (herdOptions instanceof Error) this.options.herds = [];
      else this.options.herds = herdOptions;
    },
    clearFields: function () {
      const initialState = this.$options.data().form.fields;
      for (const field in this.lockedState) {
        if (field === "vaccinationsList") {
          initialState.vaccinationsList = this.lockedState.vaccinationsList.map(
            (t, idx) => ({
              actualDose: t.actualDose
                ? this.form.fields.vaccinationsList[idx].actualDose
                : null,
              treatment: t.treatment
                ? this.form.fields.vaccinationsList[idx].treatment
                : null,
            })
          );
        } else initialState[field] = this.form.fields[field];
      }

      // Reset parent search results
      this.parentSearchResults.adoptDam =
        this.parentSearchResults.dam =
        this.parentSearchResults.sire =
          null;

      // Reset searches
      this.clearAdoptDam = !this.clearAdoptDam;
      this.clearDam = !this.clearDam;
      this.clearSire = !this.clearSire;
      this.form.fields = initialState;

      if (this.flagIncrementEID) {
        this.incrementEID();
      }
    },
    showHiddenFields: function () {
      Object.keys(this.visibilityState).forEach((key) => {
        this.visibilityState[key] = true;
      });
    },
    submitOffspringForm: async function () {
      this.geoLocation = await this.$utils.getGeoLocation();
      if (this.formIsValid() && this.form.isValid) {
        this.addDataAsync()
          .done(async (_animal) => {
            this.clearFields();
            await this.updatePillDataAndSelectedYear();
            this.fillTables();
          })
          .fail((e) => {
            console.log(">>>>>>>>>> Error submitOffspringForm", e);
          });
      } else {
        this.$notify({
          group: "forms",
          text: "Invalid form.",
          title: "Error",
          type: "error",
        });
      }
    },
    formIsValid: function () {
      const fieldRequiredIsEmpty = this.form.mandatoryFields.find(({ field }) =>
        this.isFieldEmpty(field)
      );

      if (fieldRequiredIsEmpty) {
        this.$notify({
          group: "forms",
          text: `${fieldRequiredIsEmpty.name} is required.`,
          title: "Error",
          type: "error",
        });
        return false;
      }

      if (this.birthWeightError) {
        this.$notify({
          group: "forms",
          text: "Birth weight is not valid.",
          title: "Error",
          type: "error",
        });
        return false;
      }

      if (this.calfVigorError) {
        this.$notify({
          group: "forms",
          text: "Calf vigor is not valid.",
          title: "Error",
          type: "error",
        });
        return false;
      }

      if (
        this.form.fields.newDam == null &&
        (this.parentSearchResults.dam === null ||
          this.parentSearchResults.dam.animalIsNew)
      ) {
        this.$notify({
          group: "forms",
          text: "Dam visual is required for new dams.",
          title: "Error",
          type: "error",
        });
        return false;
      }

      if (
        this.isFieldEmpty("offspring") &&
        (!this.eid || this.eid.length != 15)
      ) {
        this.$notify({
          group: "forms",
          text: "Visual is required.",
          title: "Error",
          type: "error",
        });
        return false;
      }

      const eid = this.eid;
      if (
        (eid.length > 0 && eid.length !== 15) ||
        (this.isFieldEmpty("offspring") && eid.length !== 15)
      ) {
        this.$notify({
          group: "forms",
          text: "EID must be exactly 15 digits.",
          title: "Invalid field",
          type: "error",
        });
        return false;
      }

      const birthWeight = this.form.fields.birthWeight || "";
      if (birthWeight != "" && (isNaN(+birthWeight) || +birthWeight <= 0)) {
        this.$notify({
          group: "forms",
          text: "Birth weight must be greater than 0 or empty.",
          title: "Invalid field",
          type: "error",
        });
        return false;
      }

      return true;
    },
    addDataAsync: function () {
      const d = $.Deferred();

      const reason = "New Calf";

      const groupNumber = this.form.fields.group
        ? this.form.fields.group.trim()
        : null;
      const damComment = this.form.fields.damComment
        ? this.form.fields.damComment.trim()
        : null;

      const parentalTypes = [
        {
          comment: damComment,
          create:
            this.form.fields.newDam &&
            (this.parentSearchResults.dam === null ||
              this.parentSearchResults.dam.animalIsNew),
          errors: [],
          friendly: "Dam",
          gender: "female",
          groupNumber,
          hasInheritableInformation:
            this.parentSearchResults.dam &&
            this.parentSearchResults.dam.derived &&
            this.parentSearchResults.dam.derived.birth &&
            this.parentSearchResults.dam.derived.birth.breed !== null,
          instance: this.parentSearchResults.dam,
          isUnique: true,
          relationship: "dam",
          status: "alive",
          tagValue: this.form.fields.newDam,
          tagValues: null, // To be populated later
        },
        {
          create:
            this.form.fields.newAdoptDam &&
            (this.parentSearchResults.adoptDam === null ||
              this.parentSearchResults.adoptDam.animalIsNew),
          errors: [],
          friendly: "Adopted Dam",
          gender: "female",
          groupNumber,
          hasInheritableInformation: this.parentSearchResults.adoptDam !== null,
          instance: this.parentSearchResults.adoptDam,
          isUnique: true,
          relationship: "adoptDam",
          status: "alive",
          tagValue: this.form.fields.newAdoptDam,
          tagValues: null, // To be populated later
        },
        {
          create:
            this.form.fields.newSire &&
            (this.parentSearchResults.sire === null ||
              this.parentSearchResults.sire.animalIsNew),
          errors: [],
          friendly: "Sire",
          gender: "male",
          groupNumber,
          hasInheritableInformation: this.parentSearchResults.sire !== null,
          instance: this.parentSearchResults.sire,
          isUnique: true,
          relationship: "sire",
          status: "alive",
          tagValue: this.form.fields.newSire,
          tagValues: null, // To be populated later
        },
      ];

      const self = this;
      const parents = parentalTypes.map((parent) => {
        if (parent.create && parent.tagValue)
          parent.instance = new Animal(
            Animal.guidWithTagValue(parent.tagValue),
            this.herdMeta
          );

        return parent;
      });

      let parentsReferenced = parents.filter(
        (parent) => parent.tagValue || parent.instance
      );
      if (!parentsReferenced.length) {
        d.reject("At least 1 parent must be referenced.");
        return d.promise();
      }

      parents
        .filter((parent) => !parent.tagValue)
        .forEach((parent) => {
          this.$notify({
            group: "forms",
            text: `Tag was not provided for parent animal. (${parent.friendly})`,
            title: "Info",
            type: "info",
          });
          console.log("Tag was not provided for parent animal");
        });

      let birthDate = moment(this.form.fields.birthDate).toISOString();
      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(birthDate).startOf("day"), "days") === 0;

      birthDate = dateIsToday
        ? new Date().toISOString()
        : moment(birthDate).startOf("day").toISOString();

      const userId = this.$userID;

      $.when
        .apply(
          $,
          parents
            .filter((parent) => parent.instance)
            .map((parent) => {
              const deferParentUpdated = self.updateParentAsync(
                parent.instance,
                parent.create,
                birthDate,
                parent.comment,
                parent.gender,
                parent.groupNumber,
                reason,
                parent.status,
                parent.tagValue,
                userId
              );

              deferParentUpdated.fail(parent.errors.concat).done();
              return deferParentUpdated;
            })
        )
        .fail(() => {
          self.$notify({
            group: "forms",
            text: "Errors are blocking save from finishing. Please refresh and try again.",
            title: "Error",
            type: "danger",
          });
          parents
            .filter((parent) => parent.errors)
            .forEach((parent) => {
              self.$notify({
                group: "forms",
                text: [parent.friendly, ""]
                  .concat(parent.messages)
                  .join("<br />"),
                title: "Error",
                type: "danger",
              });
            });
          d.reject();
        })
        .done(() => {
          // Since those parents have been properly staged, we can assume we are safe to save
          const parentsSaved = parents
            .filter((parent) => parent.instance)
            .map((parent) => {
              const dParentSaved = $.Deferred();

              parent.instance
                .save()
                .fail(() => {
                  dParentSaved.reject();
                  if (parent.create)
                    self.$notify({
                      group: "forms",
                      text: `Failed to create parent. (${parent.friendly})`,
                      title: "Error",
                      type: "danger",
                    });
                  else
                    self.$notify({
                      group: "forms",
                      text: `Failed to update parent. (${parent.friendly})`,
                      title: "Error",
                      type: "danger",
                    });
                })
                .done(() => {
                  const notifications = [];
                  notifications.push(parent.friendly);
                  notifications.push("");

                  parent.tagValues = parent.instance.getFlatSummary().tagValues;
                  const animalLink = Animal.hrefLinkToAnimal(
                    parent.instance.guid,
                    parent.tagValues
                  );
                  if (parent.create)
                    notifications.push(`Parent was created. (${animalLink})`);
                  else
                    notifications.push(`Parent was updated. (${animalLink})`);

                  self.$notify({
                    group: "forms",
                    text: notifications.join("<br />"),
                    title: "Success",
                    type: "success",
                  });

                  if (!parent.isUnique)
                    self.$notify({
                      group: "forms",
                      text: [
                        parent.friendly,
                        "",
                        "Tag was not unique. Might be a duplicate animal.",
                      ].join("<br />"),
                      title: "Error",
                      type: "danger",
                    });

                  dParentSaved.resolve(parent);
                });

              return dParentSaved.promise();
            });

          $.when
            .apply($, parentsSaved)
            .fail(d.reject)
            .done((...parents) => {
              self
                .addCalfAsync(
                  parents,
                  true,
                  birthDate,
                  groupNumber,
                  reason,
                  userId
                )
                .fail(d.reject)
                .done((animal) => {
                  animal
                    .save()
                    .fail((e) => {
                      this.$notify({
                        group: "forms",
                        text: "Failed to save offspring",
                        title: "Error",
                        type: "danger",
                      });
                      d.reject();
                      console.error(e);
                    })
                    .done(() => {
                      d.resolve(animal);
                      this.$notify({
                        group: "forms",
                        text: [
                          "Offspring",
                          "",
                          Animal.hrefLinkToAnimal(
                            animal.guid,
                            animal.getFlatSummary().tagValues
                          ),
                          "Created",
                        ].join("<br />"),
                        title: "Success",
                        type: "success",
                      });
                    });
                });
            });
        });

      return d.promise();
    },
    updateParentAsync: function (
      animal,
      isNew,
      timeRecordedISO,
      comment,
      gender,
      groupNumber,
      reason,
      status,
      visual,
      userId
    ) {
      const d = $.Deferred();
      const promises = [];
      const errors = [];

      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        const latitude = this.geoLocation.latitude || null;
        const longitude = this.geoLocation.longitude || null;
        if (latitude || longitude) {
          const deferGeoPoint = animal.modify(
            "geopoints",
            null,
            "GPSLat",
            +latitude,
            false,
            true,
            {
              GPSLong: +longitude,
              GPSSampleTime: timeRecordedISO,
            }
          );
          promises.push(deferGeoPoint);

          deferGeoPoint.fail((e) => {
            errors.push("geo location");
            console.error(e);
          });
        }
      }

      if (comment) {
        const commentId = this.$utils.guid();
        const deferComment = animal.modify(
          "comments",
          null,
          "comment",
          comment,
          false,
          true,
          {
            id: commentId,
            timeRecorded: timeRecordedISO,
            userId: userId,
          }
        );

        promises.push(deferComment);

        deferComment.fail((e) => {
          errors.push(`Failed to record dam comment.`);
          d.reject(e);
        });
      }

      if (groupNumber) {
        const deferLocation = animal.modify(
          "movements",
          null,
          "groupNumber",
          groupNumber,
          false,
          true,
          {
            reason: reason,
            timeRecorded: timeRecordedISO,
            userId,
          }
        );

        promises.push(deferLocation);

        deferLocation.fail((e) => {
          errors.push(`Failed to record location.`);
          d.reject(e);
        });
      }

      if (visual) {
        const tag = {
          createdBy: userId,
          status: "active",
          tagId: null,
          tagValue: visual,
          timeRecorded: timeRecordedISO,
          type: "visual",
        };
        const deferVisual = animal.insertIDforAnimal(tag, !isNew, false, true);
        promises.push(deferVisual);

        deferVisual.fail((e) => {
          errors.push(`Failed to record visual (${visual}).`);
          console.error(e);
        });
      }

      if (isNew) {
        if (gender) {
          const deferGender = animal.modify(
            "genders",
            null,
            "gender",
            gender,
            false,
            true,
            {
              createdBy: userId,
              timeRecorded: timeRecordedISO,
            }
          );
          promises.push(deferGender);

          deferGender.fail((e) => {
            errors.push("Failed to record gender.");
            console.error(e);
          });
        }

        if (status) {
          const deferStatus = animal.modify(
            "status",
            null,
            "status",
            status,
            false,
            true,
            {
              createdBy: userId,
              timeRecorded: timeRecordedISO,
            }
          );
          promises.push(deferStatus);

          deferStatus.fail((e) => {
            errors.push("Failed to record status.");
            console.error(e);
          });
        }
      }

      $.when
        .apply($, promises)
        .fail((_e) => {
          d.reject(errors);
        })
        .done(d.resolve);

      return d.promise();
    },
    addCalfAsync: function (
      parents,
      isNew,
      birthDate,
      groupNumber,
      reason,
      userId
    ) {
      const d = $.Deferred();
      const promises = [];
      const errors = [];

      const calfEID = this.eid || null;
      const calfVisual = this.calfVisualWithAnnualInternationalLetter;
      const animal = new Animal(
        Animal.guidWithTagValue(calfEID || calfVisual),
        this.herdMeta
      );

      // Determine whether a comment will be stored
      const comment = this.form.fields.calfComment
        ? this.form.fields.calfComment.trim()
        : null;
      const commentId = comment ? Utils.guid() : null;

      const dateIsToday = moment().diff(birthDate, "days") === 0;
      if (dateIsToday) {
        const latitude = this.geoLocation.latitude || null;
        const longitude = this.geoLocation.longitude || null;
        if (latitude || longitude) {
          const deferGeoPoint = animal.modify(
            "geopoints",
            null,
            "GPSLat",
            +latitude,
            false,
            true,
            {
              GPSLong: +longitude,
              GPSSampleTime: birthDate,
            }
          );
          promises.push(deferGeoPoint);

          deferGeoPoint.fail((e) => {
            errors.push("geo location");
            console.error(e);
          });
        }
      }

      if (groupNumber) {
        const deferMovement = animal.modify(
          "movements",
          null,
          "groupNumber",
          groupNumber,
          false,
          true,
          {
            reason: reason,
            timeRecorded: birthDate,
            userId: userId, // TODO: Test whether this even needs to be passed in
          }
        );
        promises.push(deferMovement);

        deferMovement.fail((e) => {
          errors.push("pasture/pen");
          console.error(e);
        });
      }

      // EID into the animalIDs
      if (calfEID) {
        const tag = {
          createdBy: userId, // TODO: Test whether this even needs to be passed in
          status: "active",
          tagId: null,
          tagValue: calfEID,
          timeRecorded: birthDate,
          type: "eid",
        };
        const deferTag = animal.insertIDforAnimal(tag, !isNew, false, true);
        promises.push(deferTag);

        deferTag.fail((e) => {
          errors.push(`tag (${tag.tagValue})`);
          console.error(e);
        });
      }

      if (calfVisual) {
        const tag = {
          createdBy: userId,
          status: "active",
          tagId: null,
          tagValue: calfVisual,
          timeRecorded: birthDate,
          type: "visual",
        };
        const deferTag = animal.insertIDforAnimal(tag, !isNew, false, true);
        promises.push(deferTag);

        deferTag.fail((e) => {
          errors.push(`tag (${tag.tagValue})`);
          console.error(e);
        });
      }

      parents
        .filter((parent) => parent.instance)
        .forEach((parent) => {
          // There is a possibility that the parent is not saved, but the guid may contain proposed tag value of the parent which might be helpful later, therefore we allow the child to store the reference to the parent regardless of whether the parent is successfully saved.
          const deferRelationShip = animal.modify(
            parent.relationship + "Ids",
            null,
            parent.relationship + "Id",
            parent.instance.guid,
            false,
            true,
            {
              parentTags: parent.tagValues,
              timeRecorded: birthDate,
            }
          );

          promises.push(deferRelationShip);

          deferRelationShip.fail((e) => {
            errors.push(`relationship to ${parent.friendly}`);
            console.error(e);
          });
        });

      const birthCalvingEase = this.form.fields.calvingScore
        ? this.form.fields.calvingScore.trim()
        : null;
      if (birthCalvingEase) {
        const deferBirthCalvingEase = animal.modify(
          "birthCalvingEases",
          null,
          "birthCalvingEase",
          birthCalvingEase,
          false,
          true,
          {
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferBirthCalvingEase);

        deferBirthCalvingEase.fail((e) => {
          errors.push("birth calving ease");
          console.error(e);
        });
      }

      const treatments = this.form.fields.vaccinationsList;
      treatments.forEach(({ actualDose, treatment }) => {
        if (treatment && actualDose) {
          promises.push(
            animal.modify(
              "treatments",
              null,
              "vaccinationsId",
              treatment.id,
              false,
              true,
              {
                actualDose,
                commentId,
                id: this.$utils.guid(),
                vaccinationTime: birthDate,
              }
            )
          );

          promises[promises.length - 1].fail((e) => {
            errors.push("treatment");
            console.error(e);
          });
        }
      });

      const vigor = this.form.fields.calfVigor
        ? this.form.fields.calfVigor.trim()
        : null;
      if (vigor) {
        const deferVigor = animal.modify(
          "vigors",
          null,
          "vigor",
          vigor,
          false,
          true,
          {
            birthDate,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferVigor);

        deferVigor.fail((e) => {
          errors.push("vigor");
          console.error(e);
        });
      }

      if (birthDate) {
        const deferBirthDate = animal.modify(
          "birthDates",
          null,
          "birthDate",
          birthDate,
          false,
          true,
          {
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferBirthDate);

        deferBirthDate.fail((e) => {
          errors.push("birth date");
          console.error(e);
        });
      }

      if (this.form.fields.breed) {
        const deferBreed = animal.modify(
          "breeds",
          null,
          "breedId",
          this.form.fields.breed.id,
          false,
          true,
          {
            breed: this.form.fields.breed.breed,
            timeRecorded: birthDate,
            userId: this.$userID,
          }
        );
        promises.push(deferBreed);

        deferBreed.fail((e) => {
          errors.push("breed");
          console.error(e);
        });
      }

      const birthWeight = this.form.fields.birthWeight
        ? this.form.fields.birthWeight.trim()
        : null;
      if (birthWeight && !isNaN(birthWeight)) {
        const deferBirthWeight = animal.modify(
          "birthWeights",
          null,
          "birthWeight",
          +birthWeight,
          false,
          true,
          {
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferBirthWeight);

        deferBirthWeight.fail((e) => {
          errors.push("birth weight");
          console.error(e);
        });
      }

      const color = this.form.fields.color || null;
      if (color) {
        const deferColor = animal.modify(
          "colors",
          null,
          "colorId",
          color.id,
          false,
          true,
          {
            color: color.name,
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferColor);

        deferColor.fail((e) => {
          errors.push("color");
          console.error(e);
        });
      }

      const gender = this.form.fields.sex && this.form.fields.sex.gender;
      if (gender) {
        const deferGender = animal.modify(
          "genders",
          null,
          "gender",
          gender,
          false,
          true,
          {
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferGender);

        deferGender.fail((e) => {
          errors.push("gender");
          console.error(e);
        });
      }

      const sexId = this.form.fields.sex && this.form.fields.sex.id;
      if (sexId) {
        const deferSex = animal.modify(
          "sexes",
          null,
          "sexId",
          sexId,
          false,
          true,
          {
            commentId: commentId,
            gender: this.form.fields.sex.gender,
            sex: this.form.fields.sex.sex,
            timeRecorded: birthDate,
            userId: this.$userID,
          }
        );
        promises.push(deferSex);

        deferSex.fail((e) => {
          errors.push("sex");
          console.error(e);
        });

        const gender = this.form.fields.sex.gender;
        if (gender) {
          const deferGender = animal.modify(
            "genders",
            null,
            "gender",
            gender,
            false,
            true,
            {
              timeRecorded: birthDate,
              userId: this.$userID,
            }
          );
          promises.push(deferGender);

          deferGender.fail((e) => {
            errors.push("gender");
            console.error(e);
          });
        }
      }

      const status = this.form.fields.status
        ? this.form.fields.status.trim()
        : null;
      if (status) {
        const deferStatus = animal.modify(
          "status",
          null,
          "status",
          status,
          false,
          true,
          {
            commentId: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferStatus);

        deferStatus.fail((e) => {
          errors.push("status");
          console.error(e);
        });
      }

      if (commentId) {
        const deferComment = animal.modify(
          "comments",
          null,
          "comment",
          comment,
          false,
          true,
          {
            id: commentId,
            timeRecorded: birthDate,
          }
        );
        promises.push(deferComment);

        deferComment.fail((e) => {
          errors.push("comment");
          console.error(e);
        });
      }

      // Set last so we can make the animal inherit only values that are not provided by the customer's form
      parents
        .filter((parent) => parent.hasInheritableInformation)
        .forEach((parent) => {
          if (parent.relationship === "dam") {
            const inheritableInformation = Animal.inheritableInfoOf(
              parent.instance
            );
            if (!inheritableInformation) return;

            /// Note that some code is commented out here because some inheritance is used to update form values directly before save
            // if (inheritableInformation.breed) {
            //   const deferBreed = animal.modify(
            //     "breeds",
            //     null,
            //     "breed",
            //     inheritableInformation.breed,
            //     false,
            //     true,
            //     {
            //       timeRecorded: birthDate,
            //     }
            //   );
            //   promises.push(deferBreed);

            //   deferBreed.fail((e) => {
            //     errors.push("inherited data from dam: breed");
            //     console.error(e);
            //   });
            // }

            if (inheritableInformation.herd) {
              const deferHerd = animal.modify(
                "herds",
                null,
                "herd",
                inheritableInformation.herd,
                false,
                true,
                {
                  timeRecorded: birthDate,
                }
              );
              promises.push(deferHerd);

              deferHerd.fail((e) => {
                errors.push("inherited data from dam: herd");
                console.error(e);
              });
            }

            if (inheritableInformation.originId) {
              const deferOriginId = animal.modify(
                "origins",
                null,
                "originId",
                inheritableInformation.originId,
                false,
                true,
                { timeRecorded: birthDate }
              );
              promises.push(deferOriginId);

              deferOriginId.fail((e) => {
                errors.push("inherited data from dam: origin");
                console.error(e);
              });
            } else if (inheritableInformation.origin) {
              const deferOrigin = animal.modify(
                "origins",
                null,
                "origin",
                inheritableInformation.origin,
                false,
                true,
                { timeRecorded: birthDate }
              );
              promises.push(deferOrigin);

              deferOrigin.fail((e) => {
                errors.push("inherited data from dam: origin");
                console.error(e);
              });
            }

            if (inheritableInformation.locationId) {
              const deferLocation = animal.modify(
                "movements",
                null,
                "locationId",
                inheritableInformation.locationId,
                false,
                true,
                {
                  location: inheritableInformation.location,
                  timeRecorded: birthDate,
                }
              );
              promises.push(deferLocation);

              deferLocation.fail((e) => {
                errors.push("inherited data from dam: pasture/pen");
                console.error(e);
              });
            }

            if (inheritableInformation.percentCross) {
              const deferPercentCross = animal.modify(
                "percentCrosses",
                null,
                "percentCross",
                inheritableInformation.percentCross,
                false,
                true,
                { timeRecorded: birthDate }
              );
              promises.push(deferPercentCross);

              deferPercentCross.fail((e) => {
                errors.push("inherited data from dam: percentCross");
                console.error(e);
              });
            }

            if (inheritableInformation.species) {
              const deferspecies = animal.modify(
                "species",
                null,
                "species",
                inheritableInformation.species,
                false,
                true,
                { timeRecorded: birthDate }
              );
              promises.push(deferspecies);

              deferspecies.fail((e) => {
                errors.push("inherited data from dam: species");
                console.error(e);
              });
            }
          }
        });

      $.when
        .apply($, promises)
        .fail(() => {
          d.reject(errors.map((error) => `Failed to set ${error}`));
        })
        .then(() => {
          d.resolve(animal);
        });

      return d.promise();
    },
    updatePillDataAndSelectedYear: function () {
      const d = $.Deferred();
      const self = this;

      this.loading = true;
      self.herdMeta
        .getAnimalsWithBirthdateAsync()
        .then((animalsBirthDateAndSummary) => {
          let activeBadge = 0;
          const today = this.$moment().startOf("day").toISOString();
          self.animalsByBirthYear = animalsBirthDateAndSummary
            .map((result) => result.value)
            .reduce((reduction, animalBirthDateAndSummary) => {
              const year = animalBirthDateAndSummary.birthDate.format("YYYY");

              if (today <= animalBirthDateAndSummary.birthDate.toISOString()) {
                activeBadge++;
              }

              let currentSet = reduction[year];
              if (!currentSet) currentSet = reduction[year] = [];
              currentSet.push(animalBirthDateAndSummary);
              return reduction;
            }, {});

          this.activeBadge = activeBadge;

          // newest first
          const yearsDescending = Object.keys(self.animalsByBirthYear)
            .map((year) => +year)
            .sort((a, b) => b - a);

          const expandedYears = this.expandYears(yearsDescending);

          // use first year as selection if year is not yet selected
          this.selectedYear = this.selectedYear
            ? self.selectedYear
            : expandedYears[0].year;

          this.years = expandedYears;

          d.resolve();
        })
        .catch((e) => {
          console.error("No years available. Default to not filtering.", e);
          d.resolve();
        })
        .finally(() => (this.loading = false));

      return d.promise();
    },
    expandYears: function (years) {
      // Add current year if it isn't there
      const currentYear = moment().format("YYYY");
      if (years[0] != currentYear) {
        years.reverse().push(currentYear);
        years.reverse();
      }

      let lastInsertedPillYear = years[0];
      return years.reduce((reduction, year) => {
        // insert years that have no births
        if (year < lastInsertedPillYear - 1) {
          if (year < lastInsertedPillYear - 20) {
            reduction.push({
              year: "......",
              disabled: true,
            });
          } else {
            for (let i = lastInsertedPillYear - 1; i > year; i--) {
              reduction.push({
                year: i,
                disabled: true,
              });
            }
          }
        }

        reduction.push({
          year: year,
          disabled: false,
        });
        lastInsertedPillYear = year;
        return reduction;
      }, []);
    },
    isFieldEmpty: function (field) {
      const input = this.form.fields[field];
      return !input || this.$utils.stringIsEmpty(JSON.stringify(input));
    },
    vigorImpliesDead: function (vigor) {
      return (
        vigor === "dead_on_arrival" || vigor === "died_shortly_after_birth"
      );
    },
  },
};
</script>
