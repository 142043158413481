<template>
  <div class="dashboard-background pt-10 h-full">
    <v-row class="" no-gutters>
      <v-col class="px-4" md="6" offset-md="3" offset-sm="0">
        <v-card
          class="rounded-lg light-elevation dashboard-light-border pa-4"
          elevation="0"
        >
          <v-card-title class="d-flex">
            My settings
            <v-btn
              :class="{ invisible: editing.settings }"
              @click="editing.settings = !editing.settings"
              class="ml-auto"
              color="primary"
              icon
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form lazy-validation ref="form" v-model="settingsFormValid">
              <v-select
                :disabled="!editing.settings"
                :items="languages"
                @change="touched.locale = true"
                autocomplete="language"
                item-text="label"
                item-value="key"
                label="Locale"
                required
                v-model="settings.locale"
              />
              <v-select
                :disabled="!editing.settings"
                :items="$moment.tz.names()"
                @change="touched.timezone = true"
                label="Timezone"
                required
                v-model="settings.timezone"
              />
              <v-text-field
                :disabled="!editing.settings"
                :rules="generalRules"
                @change="touched.fullName = true"
                autocomplete="given-name"
                label="Name"
                required
                v-model="settings.fullName"
              />
              <v-text-field
                :disabled="!editing.settings"
                @change="touched.phoneNumber = true"
                autocomplete="tel"
                label="Phone number"
                v-model="settings.phoneNumber"
              />
              <div class="mt-2" v-if="editing.settings">
                <v-btn
                  :disabled="!settingsFormValid || submittingSettings"
                  :loading="submittingSettings"
                  @click="save"
                  class="rounded-lg"
                  color="success"
                >
                  <span class="px-6"> Save </span>
                </v-btn>
                <v-btn
                  :disabled="submittingSettings"
                  @click="cancelSettings"
                  class="ml-4 rounded-lg"
                  text
                >
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
          <v-card-title class="d-flex">
            Password
            <v-btn
              :class="{ invisible: editing.passwords }"
              @click="editing.passwords = !editing.passwords"
              class="ml-auto"
              color="primary"
              icon
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-if="!editing.passwords">
              <v-text-field value="*************" label="Current" disabled />
            </div>
            <v-form @submit.prevent v-else v-model="passwordFormValid">
              <v-text-field
                :rules="[required, minLength]"
                :type="showPasswords ? 'text' : 'password'"
                autocomplete="new-password"
                counter
                label="Current Password"
                prepend-icon="mdi-lock-outline"
                required
                v-model="passwords.current"
              >
                <template v-slot:append>
                  <v-icon @click="showPasswords = !showPasswords">
                    {{ showPasswords ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </template>
              </v-text-field>
              <v-text-field
                :rules="[required, minLength]"
                :type="showPasswords ? 'text' : 'password'"
                autocomplete="new-password"
                counter
                label="New Password"
                prepend-icon="mdi-lock-outline"
                v-model="passwords.new"
              >
                <template v-slot:append>
                  <v-icon :color="successPass ? 'green' : 'red'">
                    {{ passRule }}
                  </v-icon>
                </template>
              </v-text-field>
              <v-text-field
                :rules="[required, minLength, matchingPasswords]"
                :type="showPasswords ? 'text' : 'password'"
                autocomplete="new-password"
                counter
                label="Confirm Password"
                prepend-icon="mdi-lock-outline"
                v-model="passwords.confirm"
              >
                <template v-slot:append>
                  <v-icon :color="successPass1 ? 'green' : 'red'">
                    {{ passRule1 }}
                  </v-icon>
                </template>
              </v-text-field>
              <div class="mt-2" v-if="editing.passwords">
                <v-btn
                  :disabled="!passwordFormValid || submittingPasswords"
                  :loading="submittingPasswords"
                  @click="savePassword"
                  class="rounded-lg"
                  color="success"
                >
                  <span class="px-6"> Save </span>
                </v-btn>
                <v-btn
                  :disabled="submittingPasswords"
                  @click="cancelPasswords"
                  class="ml-4 rounded-lg"
                  text
                >
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <online-warning />
  </div>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      languages: TranslationMixin.data().languages,
      settings: {
        fullName: "",
        locale: "",
        phoneNumber: "",
        timezone: "",
      },
      passwords: {
        confirm: "",
        current: "",
        new: "",
      },
      editing: {
        passwords: false,
        settings: false,
      },
      touched: {
        fullName: false,
        locale: false,
        phoneNumber: false,
        timezone: false,
      },
      settingsFormValid: false,
      passwordFormValid: false,
      successPass: false,
      successPass1: false,
      showPasswords: false,
      generalRules: [(v) => !!v || "This field is required"],
      submittingSettings: false,
      submittingPasswords: false,
      committedValues: {},
    };
  },
  created() {
    this.fetchSettings();
    this.axiosConfig = { params: { token: this.getToken }, timeout: 4 * 1000 };
  },
  computed: {
    ...mapGetters({ getToken: "User/getToken", getName: "User/getName" }),
    passRule() {
      if (this.passwords.new === "") {
        this.successPass = false;
        return ""; // is empty
      } else if (this.minLength(this.passwords.new) === true) {
        this.successPass = true;
        return "mdi-check"; // ok
      } else {
        this.successPass = false;
        return "mdi-close"; // password wrong
      }
    },
    passRule1() {
      if (this.passwords.confirm === "") {
        this.successPass1 = false;
        return ""; // is empty
      } else if (
        this.minLength(this.passwords.confirm) === true &&
        this.matchingPasswords() === true
      ) {
        this.successPass1 = true;
        return "mdi-check"; // ok
      } else {
        this.successPass1 = false;
        return "mdi-close"; // password wrong
      }
    },
  },
  methods: {
    ...mapActions({ setName: "User/setName" }),
    required(value) {
      return Boolean(value) || "This field is required.";
    },
    minLength(value) {
      return value.length >= 8 || "Password should have at least 8 characters.";
    },
    matchingPasswords() {
      return (
        this.passwords.new === this.passwords.confirm ||
        "Passwords does not match."
      );
    },
    async fetchSettings() {
      let response;
      try {
        response = await axios.get(`/api/my/settings`, {
          params: { token: this.getToken },
          timeout: 4 * 1000,
        });
      } catch (e) {
        this.$notify({
          group: "forms",
          text: "We were not able to retrieve current data. Are you online?",
          title: "Error",
          type: "error",
        });
        return;
      }

      const { data } = response;
      Object.assign(this.settings, {
        locale: data.locale,
        timezone: data.timezone,
        fullName: this.getName,
        phoneNumber: data.number,
      });
      this.committedValues = { ...this.settings };
    },
    async save() {
      this.submittingSettings = true;
      try {
        await Promise.all([
          this.saveLocale(),
          this.saveTimezone(),
          this.saveFullName(),
          this.savePhoneNumber(),
        ]);
      } catch (e) {
        this.submittingSettings = false;
        return;
      }
      this.editing.settings = false;
      this.submittingSettings = false;
    },
    async saveLocale() {
      if (!this.touched.locale) return;

      const localeUrl = `/api/my/locale/${this.settings.locale}`;
      try {
        await axios.patch(localeUrl, null, this.axiosConfig);
      } catch (e) {
        this.notifyError("Locale");
        throw e;
      }

      this.notifySuccess("Locale");
      this.touched.locale = false;
      this.committedValues.locale = this.settings.locale;
    },
    async saveTimezone() {
      if (!this.touched.timezone) return;

      const timezoneUrl = `/api/my/timezone/${this.settings.timezone}`;
      try {
        await axios.patch(timezoneUrl, null, this.axiosConfig);
      } catch (e) {
        this.notifyError("Timezone");
        throw e;
      }

      this.notifySuccess("Timezone");
      this.touched.timezone = false;
      this.committedValues.timezone = this.settings.timezone;
    },
    async saveFullName() {
      if (!this.touched.fullName) return;

      const firstNameUrl = `/api/my/first_name/${this.settings.fullName}`;
      const lastNameUrl = `/api/my/last_name/`;
      try {
        await axios.patch(firstNameUrl, null, this.axiosConfig);
        await axios.patch(lastNameUrl, null, this.axiosConfig);
      } catch (e) {
        this.notifyError("Name");
        throw e;
      }

      this.notifySuccess("Name");
      this.touched.fullName = false;
      this.committedValues.fullName = this.settings.fullName;
    },
    async savePhoneNumber() {
      if (!this.touched.phoneNumber) return;

      const phoneNumberUrl = `/api/my/phone_number/${this.settings.phoneNumber}`;
      try {
        await axios.patch(phoneNumberUrl, null, this.axiosConfig);
      } catch (e) {
        this.notifyError("Phone number");
        throw e;
      }

      this.notifySuccess("Phone number");
      this.touched.phoneNumber = false;
      this.committedValues.phoneNumber = this.settings.phoneNumber;
    },
    async savePassword() {
      const changePasswordUrl = `/api/my/password/${this.passwords.current}/new/${this.passwords.new}`;
      this.submittingPasswords = true;

      try {
        await axios.patch(changePasswordUrl, null, this.axiosConfig);
        this.editing.passwords = false;
        this.$notify({
          group: "forms",
          text: "Your password was updated.",
          title: "",
          type: "success",
        });
        this.passwords = { current: "", new: "", confirm: "" };
      } catch (e) {
        let message =
          "An error occurred while saving the information, please try again later.";

        if (e.response.status === 401)
          message = "Current password is not correct.";

        this.$notify({
          group: "forms",
          text: message,
          title: "Error",
          type: "error",
        });
      } finally {
        this.submittingPasswords = false;
      }
    },
    cancelSettings() {
      this.editing.settings = false;
      Object.assign(this.settings, this.committedValues);
    },
    cancelPasswords() {
      this.editing.passwords = false;
      this.passwords = { current: "", new: "", confirm: "" };
    },
    notifyError(field) {
      this.$notify({
        group: "forms",
        text: `An error occurred while saving the ${field}, please try again later.`,
        title: "Error",
        type: "error",
      });
    },
    notifySuccess(field) {
      this.$notify({
        group: "forms",
        text: `${field} was updated.`,
        title: "",
        type: "success",
      });
    },
  },
};
</script>

<style scoped>
.elevation-0.light-elevation {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.15), 0 1px 2px -1px rgb(0 0 0 / 0.15) !important;
}
</style>
