<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M4.50039 3.80039V5.90039H3.80039C3.02719 5.90039 2.40039 6.52719 2.40039 7.30039V9.40039C2.40039 10.1736 3.02719 10.8004 3.80039 10.8004H4.50039V12.2004C4.50039 12.9736 5.12719 13.6004 5.90039 13.6004H10.1004C10.8736 13.6004 11.5004 12.9736 11.5004 12.2004V10.8004H12.2004C12.9736 10.8004 13.6004 10.1736 13.6004 9.40039V7.30039C13.6004 6.52719 12.9736 5.90039 12.2004 5.90039H11.5004V3.80039C11.5004 3.02719 10.8736 2.40039 10.1004 2.40039H5.90039C5.12719 2.40039 4.50039 3.02719 4.50039 3.80039ZM10.1004 3.80039H5.90039V5.90039H10.1004V3.80039ZM10.1004 9.40039H5.90039V12.2004H10.1004V9.40039Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
