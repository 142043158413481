<template>
  <div>
    <summary-container :headerTitle="getTranslation('Group Statistics')">
      <template #append-header>
        <div>
          <v-select
            :items="groupNumberByLastUsed"
            :label="getTranslation('Group')"
            :menu-props="{ offsetY: true }"
            clearable
            dense
            filled
            hide-details
            outlined
            v-model="selectedGroupNumber"
          >
          </v-select>
        </div>
      </template>
      <template #prepend-header>
        <div>
          <v-btn color="primary" @click="refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>
      </template>
      <template #content v-if="selectedGroupNumber">
        <v-row class="mb-8">
          <v-col>
            <v-data-table
              :headers="summaryTable.headers"
              :items="[summariesByGroupNumber[selectedGroupNumber]]"
              dense
              hide-default-footer
              hide-default-header
              mobile-breakpoint="0"
            >
              <template #header="slotProps">
                <thead>
                  <tr>
                    <th
                      style="font-size: 1.6rem"
                      :key="index"
                      v-for="({ text }, index) in slotProps.props.headers"
                    >
                      {{ text }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template #item="props">
                <tr class="dataFont">
                  <td>{{ props.item.total }}</td>
                  <td>{{ props.item.c1 }}</td>
                  <td>{{ props.item.c2 }}</td>
                  <td>{{ props.item.late }}</td>
                  <td>{{ props.item.breed_up_percent }}</td>
                  <td>{{ props.item.open_cull }}</td>
                  <td>{{ props.item.preg_cull }}</td>
                  <td>{{ props.item.cull_percent }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <v-data-table
              :headers="recentEntryTable.headers"
              :items="[
                summariesByGroupNumber[selectedGroupNumber].latestResult,
              ]"
              dense
              hide-default-footer
              hide-default-header
            >
              <template #header="slotProps">
                <thead>
                  <tr>
                    <th
                      style="font-size: 1.6rem"
                      :key="index"
                      v-for="({ text }, index) in slotProps.props.headers"
                    >
                      {{ text }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <router-link
                      :to="{
                        name: 'AnimalDetails',
                        query: { id: item.animalId },
                      }"
                      class="title text-h6 text-none"
                      target="_blank"
                    >
                      {{ item.tagValues }}
                    </router-link>
                  </td>
                  <td class="text-h6">
                    {{ item.breed }}
                  </td>
                  <td class="text-h6">
                    {{ item.status }}
                  </td>
                  <td class="text-h6">
                    {{ item.cycle }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>

      <template #content v-else>
        <p>Select a group number.</p>
      </template>
    </summary-container>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import SummaryContainer from "../SummaryContainer";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "group-summary",
  components: {
    "summary-container": SummaryContainer,
  },
  mixins: [TranslationMixin],
  data: () => ({
    currentGroupNumber: null,
    changeWatch: null,
    initialLoad: true,
    pregCheckResultsLast24h: [],
    selectedGroupNumber: null,
    sequence: null,
  }),
  created: function () {
    this.init();
    this.setUpWatch();

    EventBus.$on("summary-refresh-start", this.init);

    EventBus.$on("summary-refresh-done", async () => {
      EventBus.$emit("set-current-group-number", this.currentGroupNumber);
    });

    EventBus.$on("set-current-group-number", (groupNumber) => {
      this.currentGroupNumber = groupNumber;
      EventBus.$emit("count-for-group-number", {
        groupNumber,
        count:
          this.summariesByGroupNumber &&
          this.summariesByGroupNumber[groupNumber]
            ? this.summariesByGroupNumber[groupNumber].total
            : 0,
      });
    });
  },
  methods: {
    // When database changes -> update

    setUpWatch: function () {
      this.changeWatch = this.$herdMeta.pouches.organization
        .changes({
          filter: function (doc) {
            return doc.tableName === "animals";
          },
          live: true,
          return_docs: false,
          since: "now",
        })
        .on("change", (change) => {
          if (this.sequence && this.sequence > change.seq) return;
          this.sequence = change.seq;
          this.init();
        })
        .on("error", function (err) {
          // handle errors
        });
    },
    beforeDestroy() {
      // Memory leak still occurs even though we call this. It seems that it is not firing at all or not soon enough.
      // TODO: Find way to fix this memory leak
      if (this.changeWatch) this.changeWatch.cancel();
    },
    init: function () {
      return this.$herdMeta.loaded
        .then(() => {
          return this.$herdMeta.pouches.organization
            .query("local_views/groupPregCheckResults", {
              group_level: 1,
            })
            .then((results) => {
              const startOfToday = moment().startOf("day").unix();
              this.pregCheckResultsLast24h = results.rows
                .map((result) => result.value)
                .map((result) => {
                  // Map in values that is primarily in main summary
                  result.pregCheck.animalId = result.main.animalId;
                  result.pregCheck.breed = result.main.breed;
                  result.pregCheck.color = result.main.color;
                  result.pregCheck.status = result.main.status;
                  result.pregCheck.tagValues = result.main.tagValues;
                  return result.pregCheck;
                })
                // Filter right away so we don't keep a ton in memory for long
                .filter(
                  (result) => moment(result.createdOn).unix() >= startOfToday
                );

              if (this.groupNumberByLastUsed.length == 0) {
                this.selectedGroupNumber = null;
              } else if (
                this.initialLoad ||
                !this.selectedGroupNumber ||
                (this.groupNumberByLastUsed.length &&
                  (!this.summariesByGroupNumber[this.selectedGroupNumber] ||
                    (this.groupNumberByLastUsed.length > 1 &&
                      this.selectedGroupNumber ==
                        this.groupNumberByLastUsed[1])))
              ) {
                this.selectedGroupNumber = this.groupNumberByLastUsed[0];
              }

              EventBus.$emit("summary-refresh-done", this.currentGroupNumber);
              this.initialLoad = false;
            })
            .catch(console.error);
        })
        .fail();
    },
    refresh: function () {
      this.$herdMeta.reload().fail().then(this.init);
    },
  },
  computed: {
    groupNumberByLastUsed: function () {
      return Object.keys(this.groupToLastCheck)
        .reduce((reduction, key) => {
          reduction.push(this.groupToLastCheck[key]);
          return reduction;
        }, [])
        .sort(
          (resultA, resultB) =>
            // Sorting createdOn if possible, otherwise use preg check time
            moment(
              resultB.createdOn || resultB.pregCheckTime || resultB.timeRecorded
            ).unix() -
            moment(
              resultA.createdOn || resultA.pregCheckTime || resultA.timeRecorded
            ).unix()
        )
        .map((result) => result.groupNumber);
    },
    groupToLastCheck: function () {
      return Object.keys(this.groupToPregChecks).reduce((reduction, key) => {
        let last = null;

        this.groupToPregChecks[key].forEach((pregCheckResult) => {
          if (!last || last.createdOn < pregCheckResult.createdOn)
            last = pregCheckResult;
        });
        reduction[key] = last;

        return reduction;
      }, {});
    },
    groupToPregChecks: function () {
      return this.pregCheckResultsLast24h.reduce((reduction, value) => {
        if (!reduction[value.groupNumber]) {
          reduction[value.groupNumber] = [];
        }
        reduction[value.groupNumber].push(value);
        return reduction;
      }, {});
    },
    recentEntryTable: function () {
      return {
        headers: [
          {
            text: this.getTranslation(
              "pregCheckPage.groupSummary.previousEntryTag"
            ),
            value: "tagValues",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.breed"),
            value: "breed",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.status"),
            value: "status",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.cycle"),
            value: "cycle",
          },
        ],
      };
    },
    summariesByGroupNumber: function () {
      const summaries = {};
      for (const [groupNumber, resultsForGroup] of Object.entries(
        this.groupToPregChecks
      )) {
        const summary = {
          breed_up_percent: 0,
          c1: 0,
          c2: 0,
          cull_percent: 0,
          late: 0,
          latestResult: this.groupToLastCheck[groupNumber],
          open_cull: 0,
          preg_cull: 0,
          total: 0,
        };

        for (const value of resultsForGroup) {
          summary.total++;
          if (value.cycle == 1 || value.cycle.toLowerCase() == "cycle 1")
            summary.c1++;
          else if (value.cycle == 2 || value.cycle.toLowerCase() == "cycle 2")
            summary.c2++;
          else if (value.cycle.toLowerCase() == "late") summary.late++;
          if (value.status && value.status.toLowerCase() == "cull") {
            if (
              value.cycle.toLowerCase() == "open" ||
              value.cycle.toLowerCase() == "open/cull"
            )
              summary.open_cull++;
            else summary.preg_cull++;
          }
          summary.breed_up_percent =
            Math.round(
              ((summary.c1 + summary.c2 + summary.late) / summary.total) * 1000
            ).toFixed(1) / 10;
          summary.cull_percent =
            Math.round(
              ((summary.open_cull + summary.preg_cull) / summary.total) * 1000,
              1
            ).toFixed(1) / 10;
        }
        summaries[groupNumber] = summary;
      }

      return summaries;
    },
    summaryTable: function () {
      return {
        headers: [
          {
            text: this.getTranslation("pregCheckPage.groupSummary.totalTested"),
            value: "total",
          },
          {
            text: "C1",
            value: "c1",
          },
          {
            text: "C2",
            value: "c2",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.late"),
            value: "late",
          },
          {
            text:
              this.getTranslation("pregCheckPage.groupSummary.breedUp") +
              " (%)",
            value: "breed_up_percent",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.openCull"),
            value: "open_cull",
          },
          {
            text: this.getTranslation("pregCheckPage.groupSummary.pregCull"),
            value: "preg_cull",
          },
          {
            text:
              this.getTranslation("pregCheckPage.groupSummary.cull") + " (%)",
            value: "cull_percent",
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
.dataFont td {
  font-size: 1.6rem !important;
}
</style>