<template>
  <v-card
    class="h-full rounded-lg light-elevation dashboard-light-border"
    elevation="0"
  >
    <v-card-text class="title-and-content-wrapper h-full">
      <slot name="title-container">
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <v-icon color="#3465a1" large class="mr-4" v-if="icon">
              {{ icon }}
            </v-icon>
            <div class="text-h5 font-weight-medium dashboard-card-title">
              {{ title }}
            </div>
          </div>
          <div class="d-flex align-center">
            <slot name="title-append" />
            <a :href="link">
              <v-icon class="ml-1" v-if="link != null">mdi-open-in-new</v-icon>
            </a>
          </div>
        </div>
      </slot>
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.elevation-0.light-elevation {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.15), 0 1px 2px -1px rgb(0 0 0 / 0.15) !important;
}
</style>
