const initialState = () => ({
  idChanged: false,
  initialOrganizationID: null,
  name: "",
  organizationID: null,
  organizationItems: [],
});

// state
const state = initialState();

// getters
const getters = {
  getIDChanged: (state) => state.idChanged,
  getInitialOrganizationID: (state) => state.initialOrganizationID,
  getName: (state) => state.name,
  getOrganizationID: (state) => state.organizationID,
  getOrganizationItems: (state) => state.organizationItems,
};

// actions
const actions = {
  reset: ({ commit }) => {
    commit("RESET");
  },
  setName: ({ commit }, { name }) => {
    commit("setName", { name });
  },
  setID: ({ commit }, { id }) => {
    commit("setID", { id });
  },
  setIDChanged: ({ commit }, { changed }) => {
    commit("setIDChanged", { changed });
  },
  setInitialOrganizationID: ({ commit }, { organizationID }) => {
    commit("setInitialOrganizationID", { organizationID });
  },
  setOrganizationItems: ({ commit }, { items }) => {
    commit("setOrganizationItems", { items });
  },
  setOrganizationID: ({ commit }, { organizationID }) => {
    commit("setOrganizationID", { organizationID });
  },
};

// mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setName(state, { name }) {
    state.name = name;
  },
  setIDChanged(state, { changed }) {
    state.idChanged = changed;
  },
  setInitialOrganizationID: (state, { organizationID }) => {
    state.initialOrganizationID = organizationID;
  },
  setOrganizationItems(state, { items }) {
    state.organizationItems = items;
  },
  setOrganizationID(state, { organizationID }) {
    state.organizationID = organizationID;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
