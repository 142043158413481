<template>
  <div>
    <v-app-bar
      app
      class="white-header"
      clipped-left
      height="64"
      style="z-index: 203"
    >
      <v-app-bar-nav-icon class="buttons" @click="toggleDrawer">
        <v-icon>{{ drawerIcon }}</v-icon>
      </v-app-bar-nav-icon>
      <v-img
        class="ml-1"
        :src="require('@/assets/logos/horizontal-logo.svg')"
        max-height="100"
        max-width="120"
      >
      </v-img>
      <v-spacer></v-spacer>
      <div class="d-flex align-end">
        <check-duplicates
          v-if="
            $vuetify.breakpoint.mdAndUp && totalAnimals && totalAnimals < 1000
          "
        />
        <div v-if="devicesConnected">
          <v-badge
            :color="
              devicesConnected === 'not valid'
                ? 'error'
                : devicesConnected.length === 0
                ? 'secondary'
                : 'primary'
            "
            :content="
              devicesConnected === 'not valid' || !devicesConnected.length
                ? '0'
                : devicesConnected.length
            "
            class="mr-2"
            bordered
            left
            overlap
          >
            <v-btn
              :color="
                devicesConnected === 'not valid'
                  ? 'error'
                  : devicesConnected.length === 0
                  ? 'default'
                  : 'primary'
              "
              class="white--text text-decoration-none"
              depressed
            >
              <v-icon
                v-if="
                  devicesConnected === 'not valid' ||
                  devicesConnected.length == 0
                "
                >mdi-cloud-alert</v-icon
              >
              <v-icon v-else>mdi-cloud</v-icon>
            </v-btn>
          </v-badge>
        </div>
        <v-btn
          class="pa-0 mr-2"
          color="#9b9b9b"
          max-width="36"
          min-width="36"
          @click="openSearchAnimalDialog"
        >
          <v-icon color="white">mdi-magnify</v-icon>
        </v-btn>
        <h2 class="mb-0">
          <span class="ma-0 badge badge-secondary">{{ totalAnimals }}</span>
        </h2>
      </div>
      <template v-if="colorLed === 'error' || pouchSyncInfo === null">
        <v-icon
          :color="colorLed"
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mx-2"
        >
          {{ statusConnection }}
        </v-icon>
      </template>
      <template v-if="pouchSyncInfo !== null">
        <v-icon
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mx-2"
          color="error"
          v-if="speed === 'error'"
          >mdi-close</v-icon
        >
        <v-icon
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mx-2"
          :color="pouchSyncInfoColor"
          v-else-if="pouchSyncInfo.direction === 'push'"
          >mdi-arrow-up</v-icon
        >
        <v-icon
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mx-2"
          :color="pouchSyncInfoColor"
          v-else-if="pouchSyncInfo.direction === 'pull'"
          >mdi-arrow-down</v-icon
        >
      </template>
    </v-app-bar>
    <shared-drawer
      ref="sharedDrawer"
      :drawer-config="{ app: true, clipped: true, temporary: true }"
      :show-append-content="true"
      :show-user-menu="false"
      :allow-mini-variant="false"
      @drawer-changed="drawer = $event"
    />
    <v-snackbar color="red" v-model="showErrorOrganizations">
      {{ labels.errorMessageOrganizations }}
      <v-btn
        text
        color="white--text"
        @click.native="showErrorOrganizations = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      max-width="400px"
      scrollable
      transition="dialog-transition"
      v-model="searchDialog.show"
    >
      <v-card>
        <v-card-title class="px-6 pb-0 d-flex justify-space-between">
          <h4 class="ma-0">Search Animal</h4>
          <v-icon @click="searchDialog.show = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider class="mb-0"></v-divider>
        <v-card-text class="pa-2 pt-0 pr-0" style="max-height: 500px">
          <search-animal
            :allowedStatuses="[
              'alive',
              'cull',
              'harvested',
              'inTransit',
              'lost',
              'marketable',
              'missing',
              'semen',
              'sick',
              'sold',
              'stolen',
            ]"
            :resetFields="searchDialog.resetFields"
            dense
            quickSearchMode
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueOfflineMixin } from "vue-offline";
import HeaderMixin from "@/mixins/Header";
import OrganizationSwitcher from "@/components/headers/OrganizationSwitcher.vue";
import UserMenu from "@/components/headers/UserMenu.vue";
import SharedDrawer from "@/components/headers/SharedDrawer.vue";

export default {
  name: "Vertical",
  mixins: [HeaderMixin, VueOfflineMixin],
  components: {
    OrganizationSwitcher,
    UserMenu,
    SharedDrawer,
  },
  data: () => ({
    colorLed: "",
    drawer: false,
    labels: {
      errorMessageOrganizations: "Error retrieving organizations",
      switchOrganization: "Switch",
      testOrganization: "Test Organization",
    },
    showErrorOrganizations: false,
  }),
  methods: {
    toggleDrawer() {
      this.$refs.sharedDrawer.toggle();
    },
  },
  computed: {
    drawerIcon: function () {
      if (this.drawer) {
        return "mdi-close";
      } else {
        return "mdi-menu";
      }
    },
    pouchSyncInfoColor: function () {
      switch (this.speed) {
        case "fast":
          return "success";
        case "medium":
          return "yellow";
        case "slow":
          return "orange";
        case "super slow":
          return "error";
        default:
          return "success";
      }
    },
    statusConnection: function () {
      if (this.isOnline) {
        this.colorLed = "success";
        return "mdi-wifi";
      } else {
        this.colorLed = "error";
        return "mdi-wifi-off";
      }
    },
  },
};
</script>
<style scoped>
.buttons:focus {
  outline: none;
}
.HoverButton:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
>>> .user-menu .activator {
  padding-left: 0;
}
>>> .organization-button {
  width: 100%;
}
</style>
