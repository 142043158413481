<template>
  <div>
    <h4 class="mr-2 mb-0" v-if="showTitle">Treatment History</h4>
    <div class="mb-2" v-if="showExtraInformation">
      <div
        class="d-flex flex-wrap justify-center align-center"
        style="max-width: 230px"
        v-if="
          table.data[0] &&
          table.data[0].timeRecorded &&
          table.data[0].rectalTemperature
        "
      >
        <p
          :class="[
            'subtitle-treatment',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          Last Recorded Rectal Temperature
          {{ $utils.renderValueAs(table.data[0].timeRecorded, "date") }}
        </p>
        <p
          :class="[
            'treatment-value',
            'mb-0',
            $vuetify.theme.dark ? 'white--text' : '',
          ]"
        >
          {{ table.data[0].rectalTemperature }}
        </p>
      </div>
    </div>
    <export-button
      :disabled="table.data.length == 0"
      :filename="`Treatment_History_${animalID}-${$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      )}`"
      :headers="tableHeaders"
      :items="table.data"
      buttonFormat="icon"
      class="mb-2"
    />
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled"
          @click="deleteTreament(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.actualDose="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'treatments',
              item.id,
              'actualDose',
              item.actualDose,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.actualDose"
        >
        </v-text-field>
      </template>
      <template #item.rectalTemperature="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'treatments',
              item.id,
              'rectalTemperature',
              item.rectalTemperature,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.rectalTemperature"
        >
        </v-text-field>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.actualWithdrawlDate="{ item }">
        {{ $utils.renderValueAs(item.actualWithdrawlDate, "datetime", true) }}
      </template>
      <template #item.vaccinationTime="{ item }">
        <div class="d-flex">
          <div>
            {{ $utils.renderValueAs(item.vaccinationTime, "datetime", true) }}
          </div>
          <v-btn
            @click="openDatetimeDialog(item)"
            class="pa-0 ml-1"
            color="#3565A1"
            max-width="32"
            min-width="32"
            small
            v-if="!disabled"
          >
            <v-icon class="white--text">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.expirationDate="{ item }">
        {{ $utils.renderValueAs(item.expirationDate, "datetime", true) }}
      </template>
      <template #item.purchaseDate="{ item }">
        {{ $utils.renderValueAs(item.purchaseDate, "datetime", true) }}
      </template>
    </v-data-table>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="datetimeDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>
            {{ getTranslation("Treatment Time") }}
          </h4>
          <v-icon class="buttons" @click="datetimeDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0" />
        <v-card-text class="py-2">
          <datetime-component v-model="datetimeDialog.datetime" />
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pb-4 pr-6 d-flex justify-end">
          <v-btn
            @click="datetimeDialog.show = false"
            class="mr-2 rounded-lg normal-case body-1"
            text
          >
            {{ getTranslation("animalsPages.cancel") }}
          </v-btn>
          <v-btn
            :disabled="!datetimeDialog.datetime"
            @click="modifyTreatmentTime"
            class="rounded-lg primary-button normal-case body-1"
          >
            <span class="px-6">
              {{ getTranslation("animalsPages.save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "TreatmentHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
    showExtraInformation: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      datetimeDialog: {
        datetime: null,
        item: null,
        show: false,
      },
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("actions"),
          value: "actions",
        },
        {
          text: this.getTranslation("vaccinationName"),
          value: "vaccinationName",
        },
        {
          text: this.getTranslation("Treatment Time"),
          value: "vaccinationTime",
          width: 180,
        },
        { text: this.getTranslation("doseGiven"), value: "actualDose" },
        { text: this.getTranslation("dose"), value: "requiredDose" },
        { text: this.getTranslation("doseCost"), value: "cost" },
        {
          text: this.getTranslation("actualWithdrawlDate"),
          value: "actualWithdrawlDate",
          width: 160,
        },
        {
          text: this.getTranslation("comment"),
          value: "comment",
        },
        {
          text: this.getTranslation("Rectal Temperature"),
          value: "rectalTemperature",
        },
        { text: this.getTranslation("manufacturer"), value: "manufacturer" },
        {
          text: this.getTranslation("purchaseDate"),
          value: "purchaseDate",
          width: 160,
        },
        {
          text: this.getTranslation("expirationDate"),
          value: "expirationDate",
          width: 160,
        },
        {
          text: this.getTranslation("Medication Lot Number"),
          value: "lotNumber",
        },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      this.herdMeta.loaded.done(async () => {
        const doc = await this.pouches.organization.get(this.animalID);
        this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
        this.getAnimalTreatments();
      });
    },
    getAnimalTreatments: function () {
      this.table.loading = true;
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.treatmentHistory || [];
          this.table.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.table.loading = false;
        });
    },
    deleteTreament: async function (item) {
      try {
        await this.animal.deleteRecord("treatments", item.id);
        this.getAnimalTreatments();
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
    openDatetimeDialog: function (item) {
      const datetime = Object.assign({}, this.$options.data().datetimeDialog);
      datetime.datetime = this.$moment(
        this.$moment(item.vaccinationTime).toISOString()
      ).format("YYYY-MM-DD HH:mm A");
      datetime.item = item;
      Object.assign(this.datetimeDialog, datetime);
      this.datetimeDialog.show = true;
    },
    modifyTreatmentTime: function () {
      const item = this.datetimeDialog.item;
      const dt = this.datetimeDialog.datetime;

      this.animal.modify(
        "treatments",
        item.id,
        "vaccinationTime",
        this.$moment(dt).format("YYYY-MM-DDTHH:mm"),
        true,
        false
      );
      this.datetimeDialog.show = false;
    },
  },
};
</script>
<style scoped>
>>> .v-stepper__wrapper {
  display: flex;
  justify-content: center;
}
.subtitle-treatment {
  color: #4d4843;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.treatment-value {
  color: #3a3633;
  font-size: 30px;
  font-weight: 600;
}
</style>
