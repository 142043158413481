<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8">
      <v-col cols="12">
        <div class="my-6">
          <v-alert type="info" color="amber accent-4" dismissible>
            <strong>Instructions</strong>
            <ul>
              <li>Use CSV files</li>
              <li>Make sure child data is in the left column</li>
            </ul>
          </v-alert>
          <template v-if="rows.length > 0">
            <v-alert
              class="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              <div>{{ error }}</div>
            </v-alert>
          </template>
          <v-alert class="success" v-if="completed">
            <div>Completed!</div>
          </v-alert>
          <div class="d-flex flex-wrap justify-space-between">
            <file-reader
              accept=".csv"
              output="binary"
              @reader-load="loadTextFromFileAndParse"
            />
            <button
              class="btn btn-primary"
              :class="!validate ? 'disabled' : ''"
              @click="importRelationships()"
            >
              {{ t.import }}
            </button>
          </div>
          <div v-if="rows.length > 0">
            <div class="btn-group" data-toggle="buttons">
              <label class="btn btn-primary" @click="parentType = 'dam'">
                <input
                  type="radio"
                  name="parentType"
                  autocomplete="off"
                  class="hide"
                />
                Dam
              </label>
              <label class="btn btn-primary" @click="parentType = 'adoptDam'">
                <input
                  type="radio"
                  name="parentType"
                  autocomplete="off"
                  class="hide"
                />
                Adopted Dam
              </label>
              <label class="btn btn-primary" @click="parentType = 'sire'">
                <input
                  type="radio"
                  name="parentType"
                  autocomplete="off"
                  class="hide"
                />
                Sire
              </label>
              <label class="btn btn-primary" @click="parentType = 'coverSire'">
                <input type="radio" name="parentType" class="hide" />
                Cover Sire
              </label>
            </div>
            <br />
            <div>
              <div v-if="!!selectedAnimal">
                <div
                  v-for="(match, index) in selectedAnimal.searchResult
                    .matchesLoaded"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: 'AnimalDetails',
                      query: { id: match.id },
                    }"
                    class="text-h6 text-none"
                    target="_blank"
                  >
                    {{ match.tagValues }}
                  </router-link>
                  <a
                    class="btn btn-primary white--text"
                    @click="selectedAnimal.disambiguateWith(match.id)"
                    >Select{{
                      selectedAnimal.selectedAnimal &&
                      selectedAnimal.selectedAnimal.guid == match.id
                        ? "ed"
                        : ""
                    }}</a
                  >
                </div>
                <a
                  class="btn btn-secondary white--text"
                  @click="selectedAnimal = null"
                  >Close</a
                >
              </div>

              <table class="table table-striped mt-5">
                <thead>
                  <tr>
                    <th class="border border-right-1" colspan="3">Child</th>
                    <th class="border border-right-1" colspan="3">Parent</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>
                      {{ headerRow[0] }}
                    </th>
                    <th>Status</th>
                    <th>
                      {{ headerRow[1] }}
                    </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) of rows" :key="index">
                    <td>
                      <button>
                        <v-icon color="red" @click="deleteRow(index)"
                          >mdi-delete</v-icon
                        >
                      </button>
                    </td>
                    <td>
                      <v-icon v-if="row.child.saved">mdi-check</v-icon>
                      <input
                        :disabled="locked"
                        type="text"
                        v-model="row.child.eid"
                        @input="row.child.update()"
                      />
                      <router-link
                        :to="{
                          name: 'AnimalDetails',
                          query: { id: row.child.getGuid() },
                        }"
                        class="btn btn-primary white--text ml-2 pt-0 pb-0 text-h6 text-none"
                        target="_blank"
                        v-if="row.child.saved || row.child.alreadyExists()"
                      >
                        View
                      </router-link>
                    </td>
                    <td>
                      <span
                        v-if="!!childrenDuplicatedInSet[row.child.eid]"
                        class="badge warning"
                        >Duplicated</span
                      >
                      <span class="badge success" v-if="row.child.isNew()"
                        >New</span
                      >
                      <span class="badge info" v-if="row.child.alreadyExists()"
                        >Exists</span
                      >
                      <span
                        class="badge btn warning"
                        v-if="row.child.isDisambiguated()"
                        @click="displayAmbiguities(row.child)"
                        >Disambiguated</span
                      >
                      <span
                        class="badge btn error"
                        v-if="row.child.hasAmbiguousEID()"
                        @click="displayAmbiguities(row.child)"
                        >Ambiguous</span
                      >
                      <span
                        class="badge btn error"
                        v-if="row.child.hasInvalidEID()"
                        >Invalid</span
                      >
                    </td>
                    <td>
                      <v-icon v-if="row.parent.saved">mdi-check</v-icon>
                      <input
                        :disabled="locked"
                        type="text"
                        v-model="row.parent.eid"
                        @input="row.parent.update()"
                      />
                      <router-link
                        :to="{
                          name: 'AnimalDetails',
                          query: { id: row.parent.getGuid() },
                        }"
                        class="btn btn-primary white--text ml-2 pt-0 pb-0 text-h6 text-none"
                        target="_blank"
                        v-if="row.parent.saved || row.parent.alreadyExists()"
                      >
                        View
                      </router-link>
                    </td>
                    <td>
                      <span class="badge success" v-if="row.parent.isNew()"
                        >New</span
                      >
                      <span class="badge info" v-if="row.parent.alreadyExists()"
                        >Exists</span
                      >
                      <span
                        class="badge btn warning"
                        v-if="row.parent.isDisambiguated()"
                        @click="displayAmbiguities(row.parent)"
                        >Disambiguated</span
                      >
                      <span
                        class="badge btn error"
                        v-if="row.parent.hasAmbiguousEID()"
                        @click="displayAmbiguities(row.parent)"
                        >Ambiguous</span
                      >
                      <span
                        class="badge btn error"
                        v-if="row.parent.hasInvalidEID()"
                        >Invalid</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import FileReader from "vue-filereader";
import TranslationMixin from "@/mixins/Translations";

export class Row {
  constructor(herdMeta, animalsByEID, userId, childEID, parentEID) {
    this.child = new DisambiguatableAnimal(
      herdMeta,
      animalsByEID,
      userId,
      childEID
    );
    this.parent = new DisambiguatableAnimal(
      herdMeta,
      animalsByEID,
      userId,
      parentEID
    );
    this.isLoaded = $.Deferred();

    $.when.apply($, [this.child.isLoaded, this.parent.isLoaded]).then(() => {
      this.isLoaded.resolve();
    });
  }
}

export class DisambiguatableAnimal {
  constructor(herdMeta, animalsByEID, userId, eid) {
    this.animalsByEID = animalsByEID;
    this.eid = (eid || "").replace(" ", "");
    this.herdMeta = herdMeta;
    this.userId = userId;

    this.reset();

    this.searchForAnimalsByEID(this.herdMeta, this.animalsByEID, this.eid);
  }

  reset() {
    this.busy = true;
    this.isLoaded = $.Deferred();
    this.saved = false;
    this.searchResult = {
      matches: [],
    };
    this.selectedAnimal = null;
  }

  getSelection() {
    if (this.isNew()) return this.searchResult.animal;
    if (this.alreadyExists()) return this.searchResult.animal;
    if (this.isDisambiguated()) return this.selectedAnimal;
    if (!this.hasInvalidEID()) return this.selectedAnimal;
    throw "error";
  }

  getGuid() {
    return this.getSelection().guid;
  }

  loadMatches() {
    const self = this;
    const d = $.Deferred();

    this.busy = true;
    $.when(d).always(() => (this.busy = false));

    if (this.searchResult.matches.length === 0) return d.resolve();
    if (
      this.searchResult.matchesLoaded &&
      this.searchResult.matchesLoaded.length === 0
    )
      return d.resolve();

    let matchesLoaded = [];
    $.when
      .apply(
        $,
        this.searchResult.matches.sort().map((duplicate_doc_id) => {
          const d = $.Deferred();

          this.herdMeta.pouches.organization
            .get(duplicate_doc_id)
            .then((doc) => {
              matchesLoaded.push({
                id: duplicate_doc_id,
                tagValues: doc.derived.summaries.main.tagValues,
              });
              d.resolve();
            });

          return d.promise();
        })
      )
      .done(() => {
        self.searchResult.matchesLoaded = matchesLoaded;
        d.resolve();
      });

    return d.promise();
  }

  update() {
    this.eid = this.eid.replace(" ", "").replace(/[^\d]/g, "");
    this.reset();
    this.searchForAnimalsByEID(this.herdMeta, this.animalsByEID, this.eid);
  }

  asyncSaveParent() {
    let d = $.Deferred();

    if (this.isNew())
      d = this.getSelection().insertIDforAnimal(
        {
          status: "active",
          tagId: null,
          tagValue: this.eid,
          type: "eid",
        },
        false,
        false,
        false
      );
    else d.resolve(this.selectedAnimal);

    d.then(() => {
      this.saved = true;
    });

    return d;
  }

  asyncSaveChildWithRelationship(parentType, parentAnimal) {
    const d = $.Deferred();
    let dIDsaved = $.Deferred();

    const animal = this.getSelection();
    if (this.isNew()) {
      dIDsaved = animal.insertIDforAnimal(
        {
          status: "active",
          tagId: null,
          tagValue: this.eid,
          type: "eid",
        },
        false,
        false,
        true
      );
    } else {
      dIDsaved.resolve();
    }

    $.when(dIDsaved).then(() => {
      animal
        .modify(
          parentType + "Ids",
          null,
          parentType + "Id",
          parentAnimal.guid,
          false,
          false,
          {
            parentTags: parentAnimal.getTagSummary(),
            timeRecorded: new Date().toISOString(),
          }
        )
        .done(d.resolve);
    });

    d.then(() => {
      this.saved = true;
    });

    return d.promise();
  }

  alreadyExists() {
    return (
      this.eid.length && !this.isNew() && this.searchResult.matches.length === 1
    );
  }

  hasInvalidEID() {
    return this.eid.length && this.eid.length != 15;
  }

  hasAmbiguousEID() {
    return (
      !this.eid.length ||
      (this.searchResult.matches.length > 1 && !this.isDisambiguated())
    );
  }

  isNew() {
    return (
      this.eid.length &&
      this.searchResult.matches.length === 0 &&
      !this.hasInvalidEID()
    );
  }

  isDisambiguated() {
    return this.eid.length && !this.isNew() && this.selectedAnimal;
  }

  disambiguateWith(doc_id) {
    this.herdMeta.pouches.organization.get(doc_id).then((doc) => {
      this.selectedAnimal = new Animal(doc_id, this.herdMeta, doc, this.userId);
    });
  }

  searchForAnimalsByEID(herdMeta, animalsByEID, eid) {
    if (!eid || eid.length === 0) {
      return {
        matches: [],
      };
    }
    this.busy = true;

    let d;
    if (animalsByEID[eid]) d = animalsByEID[eid];
    else
      animalsByEID[eid] = d =
        herdMeta.getFirstAnimalWithActiveTagAndWhetherIsUnique(eid, "eid");

    d.then((searchResult) => {
      this.searchResult = searchResult;

      if (this.isNew()) {
        this.searchResult.animal = new Animal(
          Utils.guid(),
          this.herdMeta,
          {},
          this.userId
        );
      }
      this.isLoaded.resolve();
      this.busy = false;
    });

    return d;
  }
}

export default {
  name: "ImportRelationships",
  metaInfo: {
    title: "Import Relationships",
  },
  components: { FileReader },
  mixins: [TranslationMixin],
  data: () => ({
    animalsByEID: {},
    batchId: Utils.guid(),
    busy: false,
    completed: false,
    console: console,
    contents: [],
    herdMeta: null,
    loadDummyData: false, // set to true to enable mock data for some easy testing
    locked: false,
    parentType: null,
    rows: [],
    selectedAnimal: null,
  }),
  computed: {
    t: function () {
      return {
        import: this.translate.importRelationships.import[this.localeLang],
      };
    },
    validate: function () {
      return this.errors.length == 0;
    },
    headerRow: function () {
      return this.contents[0];
    },
    childrenDuplicatedInSet: function () {
      const duplicatesInSet = {};

      let lastValue = null;
      this.rows
        .map((row) => row.child.eid)
        .sort()
        .forEach((value) => {
          if (lastValue && lastValue === value) {
            duplicatesInSet[value] = true;
          }
          lastValue = value;
        });

      return duplicatesInSet;
    },
    errors: function () {
      const errors = [];

      {
        if (!this.parentType) errors.push("Parent type is not set.");
      }

      {
        if (Object.keys(this.childrenDuplicatedInSet).length > 0)
          errors.push("A child is in the set more than once.");
      }

      {
        let childHasErrors = false;
        this.rows
          .map((row) => row.child)
          .forEach((animal) => {
            childHasErrors |= animal.hasAmbiguousEID();
            childHasErrors |= animal.hasInvalidEID();
            if (childHasErrors) return false;
            return true;
          });
        if (childHasErrors) errors.push("A child has errors.");
      }

      {
        let parentHasErrors = false;
        this.rows
          .map((row) => row.parent)
          .forEach((animal) => {
            parentHasErrors |= animal.hasAmbiguousEID();
            parentHasErrors |= animal.hasInvalidEID();
            if (parentHasErrors) return false;
            return true;
          });
        if (parentHasErrors) errors.push("A parent has errors.");
      }

      return errors;
    },
  },
  created: function () {
    this.reset();

    this.loadDummyDataAndProcess();
  },
  methods: {
    deleteRow: function (rowIndex) {
      if (confirm("Delete is no reversible. Continue?"))
        this.rows.splice(rowIndex, 1);
    },
    displayAmbiguities: function (animal) {
      animal.loadMatches().then(() => {
        this.selectedAnimal = animal;
      });
    },
    // Loads some mock data rapid testing.
    loadDummyDataAndProcess: function () {
      if (!this.loadDummyData) return;
      this.contents = [
        ["child eid", "parent eid"],
        ["123451234512342", "123451234512312"],
        ["123451234512343", "123451234512311"],
        ["123451234512344", "123451234512314"],
        ["123451234512345", "123451234512315"],
        ["123451234512345", "123451234512311"],
      ];

      this.processContents();
    },
    importRelationships: function () {
      if (!this.validate) return;
      const self = this;
      this.locked = true;

      // Collect parents by eid
      const parentsByEID = this.rows
        .map((row) => row.parent)
        .reduce((reduction, parent) => {
          if (!reduction[parent.getGuid()])
            reduction[parent.getGuid()] = parent;
          return reduction;
        }, {});

      // Make them all reference the same parent
      this.rows = this.rows.map((row) => {
        row.parent = parentsByEID[row.parent.getGuid()];
        return row;
      });

      const deferParentsSaved = Object.keys(parentsByEID).map((parentEID) =>
        // Save each unique parent
        parentsByEID[parentEID].asyncSaveParent()
      );

      $.when.apply($, deferParentsSaved).then(() => {
        // For each child, save
        $.when
          .apply(
            $,
            self.rows.map((row) => {
              row.child.asyncSaveChildWithRelationship(
                self.parentType,
                row.parent.getSelection()
              );
            })
          )
          .then(() => {
            self.completed = true;
          });
      });
    },
    loadTextFromFileAndParse: function (ev) {
      const self = this;

      this.reset();
      Papa.parse(ev.data, {
        skipEmptyLines: true,
        worker: true,
        step: function (results) {
          self.contents.push(results.data);
        },
        complete: function () {
          console.log("loaded");
          self.processContents();
        },
      });
    },
    processContents: function () {
      this.herdMeta.loaded.then(() => {
        const userId = this.$userID;
        this.busy = true;
        this.rows = this.contents
          .splice(1, this.contents.length - 1)
          .map(
            (parentAndChild) =>
              new Row(
                this.herdMeta,
                this.animalsByEID,
                userId,
                parentAndChild[0].replace(" ", ""),
                parentAndChild[1].replace(" ", "")
              )
          );

        $.when
          .apply(
            $,
            this.rows.map((row) => row.isLoaded)
          )
          .then(() => {
            this.locked = false;
            this.busy = false;
          });
      });
    },
    reset: function () {
      this.animalsByEID = {};
      this.batchId = Utils.guid();

      this.herdMeta = this.$herdMeta;
      this.completed = false;
      if (this.contents.length) this.herdMeta.reload();
      this.contents = [];
    },
  },
};
</script>
<style scoped>
input,
select {
  border: 1px solid #ccc;
}
.badge {
  margin-right: 5px !important;
  width: 90px;
}
</style>
