<template>
  <div class="dashboard-background">
    <dashboard-feeder
      :herd-meta="herdMeta"
      :pouches="pouches"
      v-if="isFeeder"
    />
    <dashboard-packer
      :herd-meta="herdMeta"
      :pouches="pouches"
      v-if="isPacker"
    />
    <dashboard-producer
      :herd-meta="herdMeta"
      :pouches="pouches"
      v-if="isProducer"
    />
  </div>
</template>

<script>
import DashboardFeeder from "@/components/dashboard/DashboardFeeder.vue";
import DashboardPacker from "@/components/dashboard/DashboardPacker.vue";
import DashboardProducer from "@/components/dashboard/DashboardProducer.vue";

export default {
  components: { DashboardFeeder, DashboardPacker, DashboardProducer },
  name: "Dashboard",
  metaInfo: { title: "Dashboard" },
  computed: {
    isFeeder() {
      return false;
    },
    isPacker() {
      return false;
    },
    isProducer() {
      return true; // always producer for now
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    await this.$herdMeta.loaded;
  },
};
</script>
