var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('summary-card',{attrs:{"link":"/animals","title":"Total head","total":_vm.total},scopedSlots:_vm._u([{key:"append-title",fn:function(){return [_c('div',{staticClass:"d-flex ml-2"},[_c('div',{staticClass:"align-center d-flex justify-end w-8"},[(_vm.countMissingBirthdates > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"\n                align-center\n                d-inline-flex\n                justify-center\n                lighten-4\n                mr-1\n                rounded-circle\n                warning\n              ",attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,false,2022968938)},[_c('span',[_vm._v(" Some animals are missing a birth date. ("+_vm._s(_vm.countMissingBirthdates)+") ")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"\n                align-center\n                d-inline-flex\n                justify-center\n                lighten-4\n                mr-1\n                rounded-circle\n                success\n              ",attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}])},[_c('span',[_vm._v("No errors/warning detected during computation.")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"\n                align-center\n                d-inline-flex\n                grey\n                justify-center\n                lighten-4\n                mr-1\n                rounded-circle\n              ",attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Data shows head with statuses: alive, cull, sick, or marketable.")])])],1)])]},proxy:true},{key:"append-content",fn:function(){return [(_vm.delta >= 0)?_c('v-chip',{staticClass:"green green--text ml-auto lighten-5"},[_vm._v(" +"+_vm._s(_vm.delta)+" in the last 30 days ")]):_c('v-chip',{staticClass:"lighten-5 ml-auto red red--text"},[_vm._v(" "+_vm._s(_vm.delta)+" in the last 30 days ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }