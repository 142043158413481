<template>
  <div class="dashboard-background">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex mb-6">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-btn
              @click="printTable"
              class="normal-case rounded-lg primary-button"
              text
            >
              <v-icon class="mr-1" primary="true">mdi-printer</v-icon>
              Print
            </v-btn>
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1">mdi-open-in-new</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" md="4">
                <v-form lazy-validation>
                  <v-select
                    :items="yearsAvailable"
                    :label="getTranslation('year')"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    @input="generateReport"
                    class="mt-0"
                    hide-details
                    item-text="type"
                    item-value="id"
                    v-model="PregReport.year"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="[]"
        :items="[]"
        :loading="true"
        class="dashboard-data-table my-5"
        hide-default-footer
        loading-text="Loading... Please wait"
        v-if="PregReport.loading"
      />
      <div class="printable printable-metadata">
        <h5>
          {{ getOrganizationName }}
        </h5>
        <h5>
          Generated: {{ $moment().local().format("MM-DD-YYYY HH:mm:ss") }}
        </h5>
        <h5>Report Year: {{ PregReport.year }}</h5>
        <h5>Report: Pregnacy test summary</h5>
      </div>
      <div class="printable">
        <div
          class="my-5"
          v-for="(table, $index) in PregReport.tableDataByHerd"
          :key="$index"
        >
          <div class="printable printable-metadata">
            <br /><br /><br /><br />
          </div>
          <v-data-table
            :headers="PregReportHeaders"
            :items="table.data"
            class="dashboard-data-table"
            hide-default-footer
            show-select
            v-model="table.selected"
          >
            <template #top="{ options, pagination, updateOptions }">
              <div class="d-flex align-center">
                <div class="h6">{{ table.herd }}</div>
                <v-data-footer
                  :items-per-page-text="
                    getTranslation('animalsPages.itemsPerPage')
                  "
                  :options="options"
                  :pagination="pagination"
                  @update:options="updateOptions"
                  class="ml-auto"
                />
              </div>
            </template>
            <template #body.append>
              <tr>
                <th colspan="2" class="body-1"><strong> Totals </strong></th>
                <th
                  class="body-1"
                  v-for="(header, $index) in PregReportHeaders.slice(
                    1,
                    PregReportHeaders.length
                  )"
                  :key="$index"
                >
                  <strong>{{ getTotalOf(table, header.value) }}</strong>
                </th>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "preg-report",
  data: () => ({
    notAssigned: "Not Assigned",
    PregReport: {
      currentPage: 1,
      data: [],
      loading: true,
      perPage: 10,
      tableDataByHerd: [],
      year: new Date().getFullYear(),
    },
  }),
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getOrganizationName: "Organization/getName",
    }),
    PregReportHeaders: function () {
      /**
       * We no longer need "key" and "label" fields for the data table
       * but are still used to compute or fill values
       */
      return [
        {
          align: "start",
          key: "breed",
          label: this.getTranslation("calfWeanPage.groupSummary.breed"),
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.breed"),
          value: "breed",
        },
        {
          align: "start",
          key: "numberTested",
          label: this.getTranslation("Number Tested"),
          sortable: false,
          text: this.getTranslation("Number Tested"),
          value: "numberTested",
        },
        {
          align: "start",
          key: "cull",
          label: this.getTranslation("Cull"),
          sortable: false,
          text: this.getTranslation("Cull"),
          value: "cull",
        },
        {
          align: "start",
          key: "percentCull",
          label: this.getTranslation("Percent Cull"),
          sortable: false,
          text: this.getTranslation("Percent Cull"),
          value: "percentCull",
        },
        {
          align: "start",
          key: "retained",
          label: this.getTranslation("Retained"),
          sortable: false,
          text: this.getTranslation("Retained"),
          value: "retained",
        },
        {
          align: "start",
          key: "percentRetained",
          label: this.getTranslation("Percent Retained"),
          sortable: false,
          text: this.getTranslation("Percent Retained"),
          value: "percentRetained",
        },
        {
          align: "start",
          key: "open",
          label: this.getTranslation("open"),
          sortable: false,
          text: this.getTranslation("open"),
          value: "open",
        },
        {
          align: "start",
          key: "percentOpen",
          label: this.getTranslation("Percent Open"),
          sortable: false,
          text: this.getTranslation("Percent Open"),
          value: "percentOpen",
        },
        {
          align: "start",
          key: "late",
          label: this.getTranslation("Late"),
          sortable: false,
          text: this.getTranslation("Late"),
          value: "late",
        },
        {
          align: "start",
          key: "percentLate",
          label: this.getTranslation("Percent Late"),
          sortable: false,
          text: this.getTranslation("Percent Late"),
          value: "percentLate",
        },
        {
          align: "start",
          key: "cycle1",
          label: this.getTranslation("Cycle") + " 1",
          sortable: false,
          text: this.getTranslation("Cycle") + " 1",
          value: "cycle1",
        },
        {
          align: "start",
          key: "percentCycle1",
          label: this.getTranslation("Percent Cycle") + " 1",
          sortable: false,
          text: this.getTranslation("Percent Cycle") + " 1",
          value: "percentCycle1",
        },
        {
          align: "start",
          key: "cycle2",
          label: this.getTranslation("Cycle") + " 2",
          sortable: false,
          text: this.getTranslation("Cycle") + " 2",
          value: "cycle2",
        },
        {
          align: "start",
          key: "percentCycle2",
          label: this.getTranslation("Percent Cycle") + " 2",
          sortable: false,
          text: this.getTranslation("Percent Cycle") + " 2",
          value: "percentCycle2",
        },
      ];
    },
    yearsAvailable: function () {
      const actualYear = new Date().getFullYear();
      let yearsAvailable = [];
      for (let index = -5; index <= 0; index++) {
        yearsAvailable.push(actualYear + index);
      }
      return yearsAvailable.reverse();
    },
  },
  mounted: function () {
    this.generateReport();
  },
  methods: {
    generateReport: function () {
      this.PregReport.loading = true;
      this.$herdMeta.loaded
        .fail(() => {})
        .then(() => {
          this.$herdMeta.pouches.organization
            .query("local_views/pregChecks")
            .then((results) => {
              const data = results.rows
                .map(({ value }) => value)
                .filter(
                  ({ summary }) => !!summary.pregCheckTime && !!summary.cycle
                )
                .map(({ summary }) => ({
                  ...summary,
                  cycle: String(summary.cycle),
                }))
                .filter(({ pregCheckTime }) => {
                  const currentYearStart = new Date(this.PregReport.year, 0, 1);
                  const currentYearEnd = new Date(
                    this.PregReport.year + 1,
                    0,
                    1
                  );

                  return (
                    new Date(pregCheckTime) >= currentYearStart &&
                    new Date(pregCheckTime) < currentYearEnd
                  );
                });
              const dataByHerd = this.getDataByHerd(data);
              const dataByBreed = this.getDataByBreed(dataByHerd);
              this.PregReport.tableDataByHerd = dataByBreed.sort((a, b) => {
                if (a.herd.toLowerCase() > b.herd.toLowerCase()) return 1;
                if (a.herd.toLowerCase() < b.herd.toLowerCase()) return -1;
                return 0;
              });
              this.PregReport.loading = false;
            });
        });
    },
    getDataByHerd: function (data) {
      const herdObject = data.reduce((previous, result) => {
        if (result.herd) {
          if (!previous[result.herd]) previous[result.herd] = [result];
          else previous[result.herd].push(result);
        } else {
          if (!previous[this.notAssigned])
            previous[this.notAssigned] = [result];
          else previous[this.notAssigned].push(result);
        }
        return previous;
      }, {});

      return Object.keys(herdObject).map((herd) => {
        return {
          data: herdObject[herd],
          herd,
          total: herdObject[herd].length,
        };
      });
    },
    getDataByBreed: function (dataFiltered) {
      let dataByBreed = dataFiltered.map((element) => {
        const breedObj = element.data.reduce((previous, result) => {
          if (result.breed) {
            if (!previous[result.breed]) previous[result.breed] = [result];
            else previous[result.breed].push(result);
          } else {
            if (!previous[this.notAssigned])
              previous[this.notAssigned] = [result];
            else previous[this.notAssigned].push(result);
          }
          return previous;
        }, {});

        const breedArray = Object.keys(breedObj).map((breed, index) => {
          const breedLength = breedObj[breed].length;
          const cullLength = breedObj[breed].filter(
            (obj) =>
              obj.status &&
              obj.status.length &&
              obj.status.toLowerCase().includes("cull")
          ).length;
          const cycle1Length = breedObj[breed].filter(
            (obj) =>
              obj.cycle &&
              obj.cycle.length &&
              obj.cycle.toLowerCase().includes("cycle 1")
          ).length;
          const cycle2Length = breedObj[breed].filter(
            (obj) =>
              obj.cycle &&
              obj.cycle.length &&
              obj.cycle.toLowerCase().includes("cycle 2")
          ).length;
          const lateLength = breedObj[breed].filter(
            (obj) =>
              obj.cycle &&
              obj.cycle.length &&
              obj.cycle.toLowerCase().includes("late")
          ).length;
          const openLength = breedObj[breed].filter(
            (obj) =>
              obj.cycle &&
              obj.cycle.length &&
              obj.cycle.toLowerCase().includes("open")
          ).length;
          const retainedLength = breedLength - cullLength;
          return {
            id: index,
            breed,
            cull: cullLength,
            cycle1: cycle1Length,
            cycle2: cycle2Length,
            late: lateLength,
            numberTested: breedObj[breed].length,
            open: openLength,
            percentCull: this.$utils.round((cullLength * 100) / breedLength),
            percentCycle1: this.$utils.round(
              (cycle1Length * 100) / breedLength
            ),
            percentCycle2: this.$utils.round(
              (cycle2Length * 100) / breedLength
            ),
            percentLate: this.$utils.round((lateLength * 100) / breedLength),
            percentOpen: this.$utils.round((openLength * 100) / breedLength),
            percentRetained: this.$utils.round(
              (retainedLength * 100) / breedLength
            ),
            retained: retainedLength,
          };
        });
        return {
          ...element,
          data: breedArray,
          selected: [],
        };
      });
      if (dataByBreed.length == 0) {
        dataByBreed = [
          {
            data: [],
            herd: this.notAssigned,
            total: 0,
            selected: [],
          },
        ];
      }
      return dataByBreed;
    },
    getSumOfColumn: function (array, column) {
      if (column.includes("percent")) column = column.replace("percent", "");
      column = column.toLowerCase();
      return array.reduce((previous, result) => {
        previous += result[column];
        return previous;
      }, 0);
    },
    printTable: function () {
      window.print();
    },
    exportToCSV: function () {
      const selected = this.PregReport.tableDataByHerd.filter(
        (table) => table.selected.length
      );
      if (!selected.length) {
        return this.$notify({
          group: "forms",
          text: "Please select at least 1 row.",
          title: "",
          type: "info",
        });
      }
      this.PregReport.tableDataByHerd.forEach((table) => {
        if (!table.selected.length) return;

        const headers = this.PregReportHeaders.filter((header) => header.text);
        const fields = headers.map((header) => header.text);
        const data = table.selected.map((row) =>
          headers.map(({ value }) => row[value])
        );
        this.$herdMeta.exportToCSV(fields, data, table.herd);
      });
    },
    getTotalOf: function (table, field) {
      return table.data.reduce((result, item) => {
        return (Number(item[field]) || 0) + result;
      }, 0);
    },
  },
};
</script>

<style scoped>
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
