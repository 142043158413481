<template>
  <svg viewBox="0 0 20 20">
    <svg
      fill="none"
      height="22"
      viewBox="0 0 21 22"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M4.12152 4.44824C3.61916 4.44824 3.21191 4.85549 3.21191 5.35785C3.21191 5.86021 3.61916 6.26746 4.12152 6.26746V13.5443C4.12152 14.549 4.93601 15.3635 5.94074 15.3635H8.29278L7.11676 16.5395C6.76154 16.8948 6.76154 17.4707 7.11676 17.8259C7.47198 18.1811 8.04791 18.1811 8.40314 17.8259L10.4888 15.7403L12.5744 17.8259C12.9296 18.1811 13.5056 18.1811 13.8608 17.8259C14.216 17.4707 14.216 16.8948 13.8608 16.5395L12.6848 15.3635H15.0368C16.0415 15.3635 16.856 14.549 16.856 13.5443V6.26746C17.3584 6.26746 17.7656 5.86021 17.7656 5.35785C17.7656 4.85549 17.3584 4.44824 16.856 4.44824H4.12152ZM14.7704 8.72986C15.1256 8.37463 15.1256 7.7987 14.7704 7.44348C14.4152 7.08826 13.8392 7.08826 13.484 7.44348L10.4888 10.4387L9.31275 9.26269C8.95752 8.90747 8.38159 8.90747 8.02637 9.26269L6.20715 11.0819C5.85193 11.4371 5.85193 12.0131 6.20715 12.3683C6.56238 12.7235 7.13831 12.7235 7.49353 12.3683L8.66956 11.1923L9.84558 12.3683C10.2008 12.7235 10.7767 12.7235 11.132 12.3683L14.7704 8.72986Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        const dark =
          this.$parent.themeableProvide.isDark || this.$vuetify.theme.dark;
        return dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
