<template>
  <div class="dashboard-background h-full">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex mb-6">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-btn
              :disabled="isBusy || !validFields || loading"
              @click="openTransactionDialog(arrayItems[0])"
              class="normal-case rounded-lg primary-button"
              text
              v-if="arrayItems.length === 1 || loading"
            >
              <v-icon class="mr-1" primary="true">mdi-plus</v-icon>
              New
            </v-btn>
            <v-menu elevation="0" offset-y v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="normal-case rounded-lg primary-button"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-1" primary="true"> mdi-plus </v-icon>
                  New
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="openTransactionDialog(organization)"
                  v-for="(organization, $index) in arrayItems"
                  :key="$index"
                >
                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" md="4" v-if="children.length > 1">
                <v-select
                  :items="children"
                  @change="generateInventory"
                  class="mt-0"
                  hide-details
                  item-text="name"
                  label="Organization"
                  return-object
                  v-model="childValue"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :items="years"
                  @change="generateInventory"
                  class="mt-0"
                  hide-details
                  label="Year"
                  v-model="yearValue"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-center">
                <v-select
                  :items="months"
                  @change="generateInventory"
                  class="mt-0"
                  hide-details
                  item-text="name"
                  item-value="value"
                  label="Month"
                  v-model="monthValue"
                />
                <div class="d-flex align-center">
                  <v-btn
                    @click="prevMonth"
                    class="normal-case rounded-lg light-blue-button mx-3"
                    icon
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span>Month</span>
                  <v-btn
                    :disabled="
                      +monthValue == new Date().getMonth() + 1 &&
                      +yearValue == new Date().getFullYear()
                    "
                    @click="nextMonth"
                    class="normal-case rounded-lg light-blue-button mx-3"
                    icon
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="[]"
        :items="[]"
        :loading="true"
        class="dashboard-data-table my-5"
        hide-default-footer
        loading-text="Loading... Please wait"
        v-if="loading"
      />
      <div
        class="my-5"
        v-for="(organization, $index) in arrayItems"
        :key="$index"
      >
        <v-data-table
          :headers="fields"
          :items="organization.items"
          class="dashboard-data-table"
          hide-default-footer
        >
          <template #top="{ options, pagination, updateOptions }">
            <div class="d-flex align-center">
              <div class="h6">{{ organization.name }}</div>
              <v-data-footer
                :items-per-page-text="
                  getTranslation('animalsPages.itemsPerPage')
                "
                :options="options"
                :pagination="pagination"
                @update:options="updateOptions"
                class="ml-auto"
              />
            </div>
          </template>
          <template v-slot:item.recordDate="{ item }">
            <td class="whitespace-nowrap">
              <v-btn
                @click="openTransactionDialog(organization, item)"
                class="primary-button rounded-lg"
                icon
                small
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="suggestDelete(organization, item)"
                class="rounded-lg buttons ml-1 mr-2"
                color="red"
                icon
                small
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <span class="font-weight-medium">{{ item.recordDate }}</span>
            </td>
          </template>
          <template #body.append>
            <tr>
              <th colspan="2" class="body-1"><strong> Totals </strong></th>
              <th
                class="body-1"
                v-for="(header, $index) in fields.slice(2, fields.length)"
                :key="$index"
              >
                <strong>{{ getTotalOf(organization, header.value) }}</strong>
              </th>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog
      max-width="300px"
      scrollable
      transition="dialog-transition"
      v-model="showDeleteDialog"
    >
      <v-card>
        <v-card-title color="red" class="white--text headline red lighten-2">
          Warning
        </v-card-title>
        <v-card-text class="pa-5">
          Are you sure you want to delete this record?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="deletingItem"
            @click="deleteTransactions"
            class="buttons"
            color="red white--text"
            rounded
          >
            Yes
          </v-btn>
          <v-btn
            @click="showDeleteDialog = false"
            class="buttons"
            color="primary"
            rounded
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TransactionDialog
      :month-value="monthValue"
      :year-value="yearValue"
      @saved="generateInventory"
      ref="transactionDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import TransactionDialog from "@/components/dialogs/TransactionDialog.vue";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "ReportInventory",
  components: { TransactionDialog },
  metaInfo: { title: "Inventory Report" },
  mixins: [TranslationMixin],
  data: () => ({
    loading: true,
    isBusy: false,
    delete: "D",
    showDeleteDialog: false,
    arrayItems: [],
    fields: [
      {
        align: "start",
        sortable: true,
        text: "Date",
        value: "recordDate",
      },
      {
        align: "start",
        sortable: false,
        text: "Description",
        value: "comments",
      },
      {
        align: "start",
        sortable: true,
        text: "Calves",
        value: "calves",
      },
      {
        align: "start",
        sortable: true,
        text: "Two",
        value: "two",
      },
      {
        align: "start",
        sortable: true,
        text: "Cows",
        value: "cows",
      },
      {
        align: "start",
        sortable: true,
        text: "Bulls",
        value: "bulls",
      },
      {
        align: "start",
        sortable: true,
        text: "Steers",
        value: "steers",
      },
      {
        align: "start",
        sortable: true,
        text: "Rph",
        value: "rph",
      },
      {
        align: "start",
        sortable: true,
        text: "Heifers",
        value: "heifers",
      },
      {
        align: "start",
        sortable: true,
        text: "Total cattle",
        value: "total_cattle",
      },
      {
        align: "start",
        sortable: true,
        text: "Hay",
        value: "hay",
      },
      {
        align: "start",
        sortable: true,
        text: "Cake",
        value: "cake",
      },
      {
        align: "start",
        sortable: true,
        text: "Salt",
        value: "salt",
      },
      {
        align: "start",
        sortable: true,
        text: "Mineral",
        value: "mineral",
      },
      {
        align: "start",
        sortable: true,
        text: "Calf pellets",
        value: "calf_pellets",
      },
      {
        align: "start",
        sortable: true,
        text: "Protein",
        value: "protein",
      },
      {
        align: "start",
        sortable: true,
        text: "Other feed",
        value: "other_feed",
      },
    ],
    children: [],
    childValue: "",
    monthValue: new Date().getMonth() + 1,
    yearValue: new Date().getFullYear(),
    months: [
      { name: "January", value: 1 },
      { name: "February", value: 2 },
      { name: "March", value: 3 },
      { name: "April", value: 4 },
      { name: "May", value: 5 },
      { name: "June", value: 6 },
      { name: "July", value: 7 },
      { name: "August", value: 8 },
      { name: "September", value: 9 },
      { name: "October", value: 10 },
      { name: "November", value: 11 },
      { name: "December", value: 12 },
    ],
    itemToDelete: {},
    deletingItem: false,
  }),
  computed: {
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
      getUserID: "User/getUserID",
    }),
    childrenToRender: function () {
      return this.childValue.isSelf ? this.children : [this.childValue];
    },
    years: function () {
      let syears = [];

      let startYear = new Date().getFullYear() - 3;
      if (this.yearValue < startYear) startYear = this.yearValue;

      const endYear = new Date().getFullYear();

      for (var i = 0; i <= endYear - startYear; ++i) syears.push(startYear + i);
      return syears;
    },
    inventoryDate: function () {
      const year = this.yearValue;
      const month = this.monthValue;
      return `${year}-${month}`;
    },
    validFields: function () {
      return (
        this.childValue != "" && this.yearValue != "" && this.monthValue != ""
      );
    },
  },
  created: async function () {
    const children = await axios.get("/api/organization/getChildren", {
      params: {
        organizationId: this.getOrganizationID,
        token: this.$token,
      },
    });
    this.children = children.data.organizations;

    if (children.data.organizations.length > 1) {
      for (const child of this.children) {
        if (child.isSelf) {
          child.name += " (has children)";
          this.$notify({
            group: "forms",
            type: "info",
            title: "Info",
            text: "This organization has children.",
          });
          break;
        }
      }
    }
    if (this.children.length) {
      this.childValue = this.children[0];
      this.generateInventory();
    } else {
      this.childValue = "";
    }
  },
  methods: {
    openTransactionDialog: function (organization, item) {
      if (item) {
        const itemToEdit = { ...item };
        itemToEdit.date = itemToEdit.recordDate;
        itemToEdit.description = itemToEdit.comments;
        delete itemToEdit.recordDate;
        delete itemToEdit.comments;
        return this.$refs.transactionDialog.open(organization, itemToEdit);
      }
      this.$refs.transactionDialog.open(organization);
    },
    prevMonth: async function () {
      this.monthValue--;
      if (this.monthValue == 0) {
        this.monthValue = 12;
        this.yearValue--;
      }
      this.generateInventory();
    },
    nextMonth: async function () {
      this.monthValue++;
      if (this.monthValue == 13) {
        this.monthValue = 1;
        this.yearValue++;
      }
      this.generateInventory();
    },
    generateInventory: async function () {
      this.loading = true;
      this.isBusy = true;
      if (!this.validFields) {
        this.$notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "Invalid fields",
        });
        this.isBusy = false;
        return;
      }

      this.arrayItems = [];
      for (const item of this.childrenToRender) {
        const items = await this.fetchTableData(item.id);
        items.forEach((item, index) => (item.id = index));

        this.arrayItems.push({
          id: item.id,
          name: item.name,
          items: items,
        });
      }
      this.isBusy = false;
      this.loading = false;
    },
    suggestDelete: function (organization, item) {
      this.itemToDelete.organization = organization;
      this.itemToDelete.item = item;
      this.showDeleteDialog = true;
    },
    deleteTransactions: async function () {
      if (this.deletingItem) return;

      this.deletingItem = true;
      const data = {
        organizationId: this.itemToDelete.organization.id,
        recordId: this.itemToDelete.item.recordId,
      };
      const res = await this.$refs.transactionDialog.newPostRequest(
        this.delete,
        data,
        this.itemToDelete.organization.id
      );
      if (res === null) {
        this.deletingItem = false;
        return this.$notify({
          group: "forms",
          type: "error",
          title: "error",
          text: `Can't delete record ${data.recordId}`,
        });
      }
      this.showDeleteDialog = false;
      this.deletingItem = false;
      this.$notify({
        group: "forms",
        type: "success",
        title: "Success",
        text: "The row was deleted",
      });
      this.generateInventory();
    },
    fetchTableData: async function (organizatonID) {
      const inventory = await this.getInventoryByOrganization(
        organizatonID,
        this.inventoryDate
      );
      return new Promise((resolve, reject) => {
        resolve(inventory.data.data);
      });
    },
    getInventoryByOrganization: function (
      organizationId,
      inventoryDate = "2021-06"
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get("/herd-api/api/organization/inventory.cfm", {
            params: {
              organizationId,
              inventoryDate,
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(null);
          });
      });
    },
    getTotalOf: function (organization, field) {
      return organization.items.reduce((result, item) => {
        return (Number(item[field]) || 0) + result;
      }, 0);
    },
  },
};
</script>

<style scoped>
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
