<template>
  <v-dialog
    :class="{ 'margin-top': headerHeight }"
    v-model="show"
    scrollable
    persistent
    max-width="350px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>Warning!</v-card-title>
      <v-card-text>
        {{ translations.switchedOrganization }}
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="updateConfig" color="success">Reload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TranslationMixin from "@/mixins/Translations";
import { mapActions, mapGetters } from "vuex";
import { IsLoggedIn } from "@/utils/functions/auth";

export default {
  name: "OrganizationUpdated",
  data: () => ({
    show: false,
    checkChanges: null,
  }),
  props: ["value"],
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
      getUserID: "User/getUserID",
      initialOrganizationID: "Organization/getInitialOrganizationID",
      initialUserID: "User/getInitialUserID",
    }),
    isUserLoggedIn: function () {
      return IsLoggedIn();
    },
    headerHeight: function () {
      return $(".v-toolbar__content").outerHeight();
    },
    translations: function () {
      return {
        switchedOrganization:
          this.translate.switchedOrganization[this.localeLang],
      };
    },
  },
  created: function () {
    this.checkChanges = setInterval(() => {
      if (!this.isUserLoggedIn) {
        this.show = false;
      } else if (
        (this.getUserID != this.initialUserID ||
          this.getOrganizationID != this.initialOrganizationID) &&
        this.getUserID != this.getOrganizationID
      ) {
        this.show = true;
      } else {
        this.show = false;
      }
    }, 1000);
  },
  beforeDestroy: function () {
    this.show = false;
    if (this.checkChanges != null) {
      clearInterval(this.checkChanges);
    }
  },
  methods: {
    ...mapActions({ setOrgName: "Organization/setName" }),
    updateConfig: function () {
      this.$router.go();
    },
  },
};
</script>
