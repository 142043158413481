<template>
  <div>
    <summary-container :headerTitle="getTranslation('Group Statistics')">
      <template #append-header>
        <div>
          <v-select
            :items="groupKeyListInWindow"
            :label="getTranslation('Group')"
            :menu-props="{ offsetY: true }"
            clearable
            dense
            filled
            hide-details
            outlined
            v-model="selectedGroupNumber"
          >
          </v-select>
        </div>
      </template>
      <template #content>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers.summaryTableHeader"
              :items="summaryTableItems"
              class="dashboard-data-table"
              hide-default-footer
              mobile-breakpoint="0"
            />
            <v-subheader class="pa-0 mt-5" style="font-size: 16px"
              >Previous Entry</v-subheader
            >
            <v-data-table
              :headers="headers.previousEntry"
              :items="previousEntryItems"
              class="dashboard-data-table"
              hide-default-footer
              mobile-breakpoint="0"
            >
              <template #item.tagValues="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.guid },
                  }"
                  class="subtitle-2"
                  target="_blank"
                >
                  {{ item.tagValues }}
                </router-link>
              </template>
              <template #item.gnrhReceived="{ item }">
                {{
                  item.gnrhReceived
                    ? getTranslation("Yes")
                    : getTranslation("No")
                }}
              </template>
              <template #item.statusId="{ item }">
                {{
                  item.statusId
                    ? getTranslation(
                        `enums.fertilizationStatusImplant.${item.statusId}`
                      )
                    : "?"
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </summary-container>
  </div>
</template>
<script>
import SummaryContainer from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "group-summary",
  mixins: [TranslationMixin],
  components: {
    "summary-container": SummaryContainer,
  },
  props: {
    fertilizations: {
      default: () => [],
      type: Array,
    },
    groupNumber: {
      default: null,
      type: String,
    },
    processedAsBred: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    herdMeta: null,
    selectedGroupNumber: null,
  }),
  computed: {
    headers: function () {
      return {
        previousEntry: [
          {
            sortable: false,
            text: this.getTranslation("calfWeanPage.groupSummary.tag"),
            value: "tagValues",
          },
          {
            sortable: false,
            text: this.getTranslation("GnRH Received"),
            value: "gnrhReceived",
          },
          {
            sortable: false,
            text: this.getTranslation("status"),
            value: "statusId",
          },
          {
            sortable: false,
            text: this.getTranslation("calfWeanPage.groupSummary.weight"),
            value: "weight",
          },
        ],
        summaryTableHeader: [
          {
            sortable: false,
            text: this.getTranslation("Through the Chute"),
            value: "total",
          },
          {
            sortable: false,
            text: this.processedAsBred
              ? this.getTranslation("Bred")
              : this.getTranslation("Processed"),
            value: "processed",
          },
          {
            sortable: false,
            text: this.getTranslation("Skipped"),
            value: "skipped",
          },
          {
            sortable: false,
            text: this.getTranslation("Flagged for Cull"),
            value: "flaggedForCull",
          },
          {
            sortable: false,
            text: this.getTranslation("GnRH Received"),
            value: "gnrhReceived",
          },
          {
            sortable: false,
            text: this.getTranslation("Avg Weight"),
            value: "avgWeight",
          },
        ],
      };
    },
    lastCreatedEntry: function () {
      return this.fertilizations.reduce((reduction, current) => {
        if (
          !reduction ||
          reduction.createdOn.localeCompare(current.createdOn) < 1
        )
          return current;
        return reduction;
      }, null);
    },
    lastCreatedDate: function () {
      return this.lastCreatedEntry.createdOn;
    },
    dateCutOff: function () {
      return moment(this.lastCreatedDate).subtract(1, "day").toISOString();
    },
    groupKeysInWindow: function () {
      return this.fertilizations
        .filter(
          ({ createdOn }) => createdOn.localeCompare(this.dateCutOff) >= 0
        )
        .reduce((reduction, entry) => {
          if (!reduction[entry.groupNumber])
            reduction[entry.groupNumber] = true;
          return reduction;
        }, {});
    },
    groupKeyListInWindow: function () {
      return Object.keys(this.groupKeysInWindow).sort();
    },
    eventsForGroupsInWindow: function () {
      return this.fertilizations.filter(
        ({ groupNumber }) => this.groupKeysInWindow[groupNumber]
      );
    },
    groupsInWindowAndEvents: function () {
      return this.eventsForGroupsInWindow.reduce((reduction, current) => {
        if (!reduction[current.groupNumber])
          reduction[current.groupNumber] = [];

        reduction[current.groupNumber].push(current);

        return reduction;
      }, {});
    },
    totalsForGroup: function () {
      return this.groupKeyListInWindow.reduce((reduction, groupNumber) => {
        const summary = {
          avgWeight: 0,
          flaggedForCull: 0,
          gnrhReceived: 0,
          processed: 0,
          skipped: 0,
          total: 0,
          totalWeiged: 0,
          totalWeight: 0,
        };
        this.groupsInWindowAndEvents[groupNumber].forEach((entry) => {
          summary.total++;
          summary.flaggedForCull += entry.cull ? 1 : 0;
          summary.gnrhReceived += entry.gnrhReceived ? 1 : 0;
          summary.skipped += entry.statusId === "skipped";
          summary.processed = summary.total - summary.skipped;

          if (!entry.weight) return;
          summary.totalWeiged++;
          summary.totalWeight += +entry.weight;
          summary.avgWeight = Utils.round(
            summary.totalWeight / summary.totalWeiged,
            2
          );
        });
        reduction[groupNumber] = summary;

        return reduction;
      }, {});
    },
    groupToLastEntry: function () {
      return this.groupKeyListInWindow.reduce((reduction, groupNumber) => {
        let last = null;

        this.groupsInWindowAndEvents[groupNumber].forEach((entry) => {
          if (!last || last.createdOn.localeCompare(entry.createdOn) < 0)
            last = entry;
        });
        reduction[groupNumber] = last;

        return reduction;
      }, {});
    },
    summariesByGroupNumber: function () {
      const summaries = {};

      for (const [groupNumber] of Object.entries(this.groupToLastEntry)) {
        const groupStats = this.totalsForGroup[this.selectedGroupNumber];

        const summary = {
          latestResult: this.groupToLastEntry[this.selectedGroupNumber],
          ...groupStats,
        };

        if (groupNumber != "undefined") summaries[groupNumber] = summary;
      }

      return summaries;
    },
    previousEntryItems: function () {
      if (this.summaryTableItems.length == 0) return [];
      else
        return [
          this.summariesByGroupNumber[this.selectedGroupNumber].latestResult,
        ];
    },
    summaryTableItems: function () {
      if (
        !this.selectedGroupNumber ||
        !this.summariesByGroupNumber[this.selectedGroupNumber]
      )
        return [];
      return [this.summariesByGroupNumber[this.selectedGroupNumber]];
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;

    if (this.groupKeysInWindow[this.groupNumber]) {
      this.selectedGroupNumber = this.groupNumber;
    } else {
      this.selectedGroupNumber = this.lastCreatedEntry.groupNumber;
    }

    // Useful to debug computed values
    // console.debug("selectedGroupNumber", this.selectedGroupNumber);
    // console.debug("fertilizations", this.fertilizations);
    // console.debug("lastCreatedEntry", this.lastCreatedEntry);
    // console.debug("groupKeysInWindow", this.groupKeysInWindow);
    // console.debug("groupKeyListInWindow", this.groupKeyListInWindow);
    // console.debug("groupToLastEntry", this.groupToLastEntry);
    // console.debug("totalsForGroup", this.totalsForGroup);
    // console.debug("summariesByGroupNumber", this.summariesByGroupNumber);
  },
};
</script>
