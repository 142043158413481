<template>
  <span>
    <v-btn
      :disabled="disabled"
      @click="exportData('csv')"
      class="pa-0"
      color="#3565A1"
      max-width="40"
      min-width="40"
      v-if="buttonFormat == 'icon'"
    >
      <v-icon class="white--text">mdi-file-export-outline</v-icon>
    </v-btn>
    <v-btn
      :disabled="disabled"
      @click="exportData('csv')"
      class="pa-0 white--text"
      color="#3565A1"
      max-width="80"
      min-width="80"
      v-else-if="buttonFormat == 'raised'"
    >
      <v-icon class="white--text">mdi-download</v-icon>
      CSV
    </v-btn>
    <v-menu :close-on-content-click="false" offset-y v-else>
      <template #activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          color="primary"
          depressed
          text
          v-bind="attrs"
          v-on="on"
        >
          Export
          <v-icon> mdi-menu-down </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          :key="idx"
          @click="exportData(option.text)"
          link
          v-for="(option, idx) in options"
        >
          <v-list-item-title>
            {{ option.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>
<script>
export default {
  name: "ExportButton",
  props: {
    allowIncludeID: {
      default: false,
      type: Boolean,
    },
    buttonFormat: {
      type: String,
      default: "menu",
    },
    changeValueOf: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    filename: {
      default: "Data",
      type: String,
    },
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    ignoreFields: {
      type: Array,
    },
  },
  data: () => ({
    options: [{ text: "CSV" }, { text: "Excel" }],
  }),
  methods: {
    orderData: function () {
      if (this.ignoreFields && this.ignoreFields.length)
        this.items = this.items.map((item) => {
          this.ignoreFields.forEach((field) => {
            delete item[field];
          });
          return item;
        });
      const props = this.headers
        ? this.headers.map(({ value }) => value)
        : Object.keys(this.items[0]);

      if (props.includes("actions")) props.splice(props.indexOf("actions"), 1);

      if (!this.allowIncludeID && props.includes("id"))
        props.splice(props.indexOf("id"), 1);
      if (this.changeValueOf.length)
        props.push(...this.changeValueOf.map(({ value }) => value));
      const items = this.$utils
        .copyObject(this.items)
        .map((obj) => {
          Object.keys(obj).forEach((key) => {
            if (!props.includes(key)) delete obj[key];
          });
          return obj;
        })
        .map((item) => {
          const newObj = {};
          props.forEach((prop) => {
            /// Known HTML generated and stored by Animal.js
            newObj[prop] = this.$utils.replaceStringsInMainString(
              item[prop],
              "\n",
              "<br />"
            );
            /// Known case that breaks CSV generation
            newObj[prop] = this.$utils.replaceStringsInMainString(
              newObj[prop],
              "number ",
              "#"
            );
            // Replace potential double spaces introduced by above replacement
            newObj[prop] = this.$utils.replaceStringsInMainString(
              newObj[prop],
              " ",
              "  "
            );
          });
          this.changeValueOf.forEach(({ prop, value }) => {
            newObj[prop] = item[value];
            delete newObj[value];
          });
          return newObj;
        });
      return items;
    },
    exportData: function (type) {
      const items = this.orderData();
      if (type.toLowerCase() == "csv") {
        const csv = Papa.unparse(items);
        const link = document.createElement("a");
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", `${this.filename}.csv`);
        link.click();
        link.remove();
      } else if (type.toLowerCase() === "excel") {
        const ws = XLSX.utils.json_to_sheet(items);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, `${this.filename}.xlsx`);
      }
    },
  },
};
</script>
