<template>
  <div>
    <v-stepper alt-labels non-linear v-model="step">
      <v-stepper-header>
        <v-stepper-step editable step="1"> Date </v-stepper-step>
        <v-divider />
        <v-stepper-step editable step="2"> Time </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-date-picker
            @input="$emit('input', datetimeValue)"
            v-model="date"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-time-picker
            @input="$emit('input', datetimeValue)"
            scrollable
            v-model="time"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <p class="mb-0">{{ datetimeValue }}</p>
  </div>
</template>
<script>
export default {
  name: "DatetimeComponent",
  props: {
    datetime: String,
  },
  model: {
    prop: "datetime",
    event: "input",
  },
  data() {
    return {
      date: null,
      step: 1,
      time: null,
    };
  },
  computed: {
    datetimeValue: function () {
      return !!this.date && !!this.time && `${this.date} ${this.time}`;
    },
  },
  watch: {
    datetime: {
      immediate: true,
      handler: function (val) {
        if (!!val && val.length) {
          const currentValue = this.$moment(val, "YYYY-MM-DD HH:mm A");
          this.date = currentValue.format("YYYY-MM-DD");
          this.time = currentValue.format("HH:mm");
        } else {
          this.date = this.$moment().format("YYYY-MM-DD");
          this.time = this.$moment().format("HH:mm");
        }
      },
    },
  },
};
</script>
<style scoped>
>>> .v-stepper__wrapper {
  display: flex;
  justify-content: center;
}
</style>