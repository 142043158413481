<template>
  <div>
    <online-warning />
    <in-page-menu />
    <v-row class="ma-0 mx-5 pt-20">
      <v-col>
        <div>
          <h4>
            {{ getTranslation("Inbound Manifests") }}
            <export-button
              :filename="`Inbound_Manifest_Summaries_${new Date().toISOString()}`"
              :headers="tableHeaders"
              :items="table.data"
            />
          </h4>
          <v-divider class="mt-0"></v-divider>
        </div>
        <!-- Table -->
        <v-data-table
          :headers="tableHeaders"
          :items="table.data"
          :loading="table.loading"
          :search="table.search"
          class="elevation-1"
          sort-by="copiedDateTimeISO"
          sort-desc
          v-model="table.selected"
        >
          <template #item.allow_data_share="{ item }">
            <v-checkbox
              :disabled="
                !!item.receipt_first_carcass_score_date && item.allow_data_share
              "
              @click="toggleAllowDataShare(item)"
              v-model="item.allow_data_share_dummy"
            />
          </template>
          <template #item.avg_hot_weight="{ item }">
            {{ item.avg_hot_weight || "-" }}
          </template>
          <template #item.choice="{ item }">
            {{ item.choice || "-" }}
          </template>
          <template #item.createdOn="{ item }">
            <div>
              {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
            </div>
          </template>
          <template #item.copiedDateTimeISO="{ item }">
            <div>
              {{
                $utils.renderValueAs(item.copiedDateTimeISO, "datetime", true)
              }}
            </div>
          </template>
          <template #item.discards="{ item }">
            {{ item.discards || "-" }}
          </template>
          <template #item.load_scores="{ item }">
            <v-btn
              :disabled="!item.animals.length"
              @click="openEditManifestDialog(item)"
              ><v-icon>mdi-pencil-ruler</v-icon></v-btn
            >
          </template>
          <template #item.export_averages="{ item }">
            <v-btn
              :disabled="item.scored == 0"
              @click="exportAveragesToCSV(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <template #item.export_carcass_data="{ item }">
            <v-btn @click="exportCarcassDataToCSV(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <template #item.export_totals="{ item }">
            <v-btn
              :disabled="item.scored == 0"
              @click="exportTotalsToCSV(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <template #item.lastUpdated="{ item }">
            <div>
              {{
                $utils.renderValueAs(item.lastUpdated, "datetime", true) || "-"
              }}
            </div>
          </template>
          <template #item.receipt_date="{ item }">
            <v-checkbox
              :disabled="!!item.receipt_first_carcass_score_date"
              @click="toggleReceipt(item)"
              v-model="item.receipt_dummy"
            />
          </template>
          <template #item.origin_lots="{ item }">
            <div>
              {{ item.origin_lots.join(", ") }}
            </div>
          </template>
          <template #item.packer_lots="{ item }">
            <div>
              {{ item.packer_lots.join(", ") }}
            </div>
          </template>
          <template #item.prime="{ item }">
            {{ item.prime || "-" }}
          </template>
          <template #item.select="{ item }">
            {{ item.select || "-" }}
          </template>
          <template #item.shipDate="{ item }">
            <div>
              {{ $utils.renderValueAs(item.shipDate, "datetime", true) }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="dialogLoadScores.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Set Carcass Data</h4>
          <v-icon class="buttons" @click="dialogLoadScores.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-alert
          border="bottom"
          colored-border
          dismissible
          elevation="2"
          icon="mdi-alert"
          type="warning"
        >
          In order to load carcass scores correctly the csv file must comply
          with the following:
          <ul>
            <li>Required Columns: <i>Live Weight, Hot Weight, EID</i></li>
            <li>
              At least one of these columns must be present:
              <i>{{
                carcass_fields
                  .filter(
                    (field) =>
                      !["live weight", "hot weight", "eid"].includes(field)
                  )
                  .map((field) => $utils.capitalizeString(field, " "))
                  .sort()
                  .join(", ")
              }}</i>
            </li>
          </ul>
        </v-alert>
        <v-card-text class="py-2">
          <div class="d-flex flex-wrap justify-center">
            <div
              @dragenter.prevent
              @dragover.prevent
              @drop.prevent="dropHandler"
              class="mb-3 drop_zone"
            >
              <div
                style="max-width: 170px"
                class="d-flex justify-center align-center flex-wrap"
              >
                <v-file-input
                  class="d-flex justify-center"
                  hide-input
                  v-model="fileSelected"
                />
                <p>Select a file or drop it here</p>
                <p>File: {{ fileSelected && fileSelected.name }}</p>
              </div>
            </div>
            <v-btn
              :disabled="!fileSelected"
              @click="setCarcassDataToManifest"
              color="success"
            >
              Import
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
import { mapGetters } from "vuex";
export default {
  name: "InboundManifests",
  metaInfo: {
    title: "Inbound Manifests",
  },
  mixins: [TranslationMixin],
  data() {
    return {
      required_fields: [
        { fieldName: "eid", regExp: /eid|uhf|id|ear tag|tag ?number/gi },
        { fieldName: "hot weight", regExp: /hot ?weight|hot ?wt|hcw/gi },
        {
          fieldName: "live weight",
          regExp: /live|live ?weight|live ?Weight|live ?wt/gi,
        },
      ],
      carcass_data_fields: [
        {
          fieldName: "yield grade",
          regExp: /yield grade|yield grade|yieldgrade/gi,
        },
        {
          fieldName: "lot",
          regExp: /lt|lot/gi,
        },
        {
          fieldName: "pen",
          regExp: /pn|pen|pen ?#/gi,
        },
        {
          fieldName: "pen",
          regExp: /dress ?%?|^dp$|yield ?%?|^yp ?%?$/gi,
        },
        {
          fieldName: "quality grade",
          regExp: /quality ?grade|carcass ?grade?|qg/gi,
        },
        {
          fieldName: "marbling score",
          regExp: /marbling score|marblingscore|marbling|marble|ms/gi,
        },
        {
          fieldName: "back fat",
          regExp: /back fat|bckfatln|bk fat|^bf$/gi,
        },
        {
          fieldName: "rea",
          regExp: /rib eye area|rib eye rea|ribeye|rrea|^rea$/gi,
        },
        {
          fieldName: "30+",
          regExp: /30\+$/gi,
        },
        {
          fieldName: "dark cutter",
          regExp: /dark cutter$/gi,
        },
        {
          fieldName: "hard bone",
          regExp: /hard bone$/gi,
        },
        {
          fieldName: "cab",
          regExp: /cab$/gi,
        },
        {
          fieldName: "chbp",
          regExp: /chbp$/gi,
        },
      ],
      carcass_fields: [
        "lot",
        "pen",
        "30+",
        "back fat",
        "cab",
        "carcass grade",
        "chbp",
        "dark cutter",
        "hard bone",
        "hot weight",
        "live weight",
        "marbling score",
        "no roll",
        "rea",
        "thirty plus",
        "yield grade",
      ],
      dialogLoadScores: {
        manifest: null,
        show: false,
      },
      dummyData: null,
      fileSelected: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: false,
        search: null,
        selected: [],
      },
    };
  },
  created: function () {
    this.dummyData = {
      _id: `packing_manifest-${new Date().toISOString()}-${this.$utils.guid()}`,
      animals: [
        {
          id: "animal_27af9633-ae1b-95e8-67fa-c1c9fd5fa23c",
          _rev: "5-9ed5cef1b7c60ff799529c18530890d9",
          carcass_data: {
            choice: 0,
            hot_weight: 1700,
            live_weight: 2000,
            prime: 0,
            select: 0,
          },
          clearanceDate: null,
          lastReadTime: "2022-09-09T16:24:16.000",
          lotNumber: "FSTTestLot #801",
          visuals: [],
          weight: null,
          eids: ["999010000068237", "999010000068237", "999010000068237"],
          penNumber: {
            _id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            _rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            archived: 0,
            description: "10",
            endDate: null,
            friendlyName: "",
            intakeLocation: false,
            latitude: "",
            longitude: "",
            name: "101",
            rank: "",
            reader: null,
            sicknessThreshold: null,
            size: "",
            sizeUnits: "",
            startDate: null,
            tableName: "locations",
            id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            createdBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            createdOn: "2022-09-06T17:23:02.805Z",
            deleted: 0,
            lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            lastUpdated: "2022-09-06T17:23:02.805Z",
            feedAlias: "",
            rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            enabledVT: false,
            orderVT: 3,
          },
        },
        {
          id: "animal_5d2f0619-bee2-e154-490c-23d95b3a6062",
          _rev: "7-c7b45ab1cd808ed4dabf0a3cf761d8cb",
          carcass_data: {
            choice: 10,
            hot_weight: 1700,
            live_weight: 2000,
            prime: 70,
            select: 20,
          },
          clearanceDate: null,
          lastReadTime: "2022-09-09T16:24:19.000",
          lotNumber: "FSTTestLot #801",
          visuals: [],
          weight: null,
          eids: ["999010000068225", "999010000068225", "999010000068225"],
          penNumber: {
            _id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            _rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            archived: 0,
            description: "10",
            endDate: null,
            friendlyName: "",
            intakeLocation: false,
            latitude: "",
            longitude: "",
            name: "101",
            rank: "",
            reader: null,
            sicknessThreshold: null,
            size: "",
            sizeUnits: "",
            startDate: null,
            tableName: "locations",
            id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            createdBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            createdOn: "2022-09-06T17:23:02.805Z",
            deleted: 0,
            lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            lastUpdated: "2022-09-06T17:23:02.805Z",
            feedAlias: "",
            rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            enabledVT: false,
            orderVT: 3,
          },
        },
        {
          id: "animal_f3157ed6-2a05-588c-71f9-a6ab6f0cabf1",
          _rev: "8-ebd02edd48226cf6ea7204b9d0e78194",
          carcass_data: {
            choice: 20,
            hot_weight: 1600,
            live_weight: 2000,
            prime: 40,
            select: 40,
          },
          clearanceDate: null,
          lastReadTime: "2022-09-09T16:24:19.000",
          lotNumber: "FSTTestLot #801",
          visuals: [],
          weight: null,
          eids: ["999010000068223", "999010000068223", "999010000068223"],
          penNumber: {
            _id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            _rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            archived: 0,
            description: "10",
            endDate: null,
            friendlyName: "",
            intakeLocation: false,
            latitude: "",
            longitude: "",
            name: "101",
            rank: "",
            reader: null,
            sicknessThreshold: null,
            size: "",
            sizeUnits: "",
            startDate: null,
            tableName: "locations",
            id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            createdBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            createdOn: "2022-09-06T17:23:02.805Z",
            deleted: 0,
            lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            lastUpdated: "2022-09-06T17:23:02.805Z",
            feedAlias: "",
            rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            enabledVT: false,
            orderVT: 3,
          },
        },
        {
          id: "animal_057e79ab-2eee-9a74-09b1-30d0cf3d720d",
          _rev: "8-24b472bcc5b5deda930d5c2a15db1635",
          clearanceDate: null,
          lastReadTime: "2022-09-09T16:24:23.000",
          lotNumber: "FSTTestLot #801",
          visuals: [],
          weight: null,
          eids: ["999010000068221", "999010000068221", "999010000068221"],
          penNumber: {
            _id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            _rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            archived: 0,
            description: "10",
            endDate: null,
            friendlyName: "",
            intakeLocation: false,
            latitude: "",
            longitude: "",
            name: "101",
            rank: "",
            reader: null,
            sicknessThreshold: null,
            size: "",
            sizeUnits: "",
            startDate: null,
            tableName: "locations",
            id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            createdBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            createdOn: "2022-09-06T17:23:02.805Z",
            deleted: 0,
            lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            lastUpdated: "2022-09-06T17:23:02.805Z",
            feedAlias: "",
            rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            enabledVT: false,
            orderVT: 3,
          },
        },
        {
          id: "animal_bf30530e-090c-21e4-583f-498be9ecfbfa",
          _rev: "5-2664a96f1a90ac4f797c58a031f20eed",
          clearanceDate: null,
          lastReadTime: "2022-09-09T16:24:23.000",
          lotNumber: "FSTTestLot #801",
          visuals: [],
          weight: null,
          eids: ["999010000068238", "999010000068238", "999010000068238"],
          penNumber: {
            _id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            _rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            archived: 0,
            description: "10",
            endDate: null,
            friendlyName: "",
            intakeLocation: false,
            latitude: "",
            longitude: "",
            name: "101",
            rank: "",
            reader: null,
            sicknessThreshold: null,
            size: "",
            sizeUnits: "",
            startDate: null,
            tableName: "locations",
            id: "location_6a65bd4c-3a44-6833-044e-512e0d4e0319",
            createdBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            createdOn: "2022-09-06T17:23:02.805Z",
            deleted: 0,
            lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
            lastUpdated: "2022-09-06T17:23:02.805Z",
            feedAlias: "",
            rev: "1-ac31d0f1ec5415e42a9ba4153acd5b52",
            enabledVT: false,
            orderVT: 3,
          },
        },
        {
          id: null,
          _rev: null,
          carcass_data: {
            choice: 20,
            hot_weight: 1500,
            live_weight: 2000,
            prime: 70,
            select: 10,
          },
          clearanceDate: null,
          lastReadTime: null,
          lotNumber: null,
          visuals: [],
          weight: null,
          eids: [],
        },
      ],
      copiedDateTimeISO: null,
      createdOn: new Date().toISOString(),
      destination: {
        _id: "packer_765d420f-b99e-9443-38c1-e2c4e596c345",
        _rev: "3-5aec90b30e1e31a70ff83266bff02b59",
        archived: 0,
        name: "Cargill Trial Account",
        organizationId: "0f5424bc-58a7-513e-c00e-aa67d18e1f8b",
        tableName: "packers",
        id: "packer_765d420f-b99e-9443-38c1-e2c4e596c345",
        createdBy: "1376cfd9-d8a3-0118-28b5-19f591b86191",
        createdOn: "2022-09-06T17:13:02.400Z",
        deleted: 0,
        lastUpdatedBy: "89025c4e-0027-11e7-a4b6-d3ace26a69cf",
        lastUpdated: "2022-09-06T17:13:02.400Z",
        rev: "3-5aec90b30e1e31a70ff83266bff02b59",
        enabledVT: false,
        orderVT: 0,
      },
      lastUpdated: new Date().toISOString(),
      lots: ["FSTTestLot #801"],
      readerGroups: ["Office Test Readers"],
      receipt: {
        first_carcass_score_date: new Date().toISOString(),
        date: null,
      },
      organization: {
        deleted: false,
        paid: true,
        createdOn: "September, 6 2022 17:08:20",
        id: "d31118ca-27a1-2a3e-e0de-fac844e5a9b1",
        lastUpdated: "September, 9 2022 21:21:34",
        name: "Cobalt sept 6",
      },
      shipDate: new Date().toISOString(),
      tableName: "packing_manifest",
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    // this.testing();
    this.getTableData();
  },
  methods: {
    ...mapGetters({
      getToken: "User/getToken",
    }),
    exportAveragesToCSV: function (item) {
      this.$utils.exportToCSV({
        data: [{ ...item.statistics.averages }],
        filename: `manifest_averages_${item._id}_${new Date().toISOString()}`,
      });
    },
    exportTotalsToCSV: function (item) {
      this.$utils.exportToCSV({
        data: [{ ...item.statistics.totals }],
        filename: `manifest_totals_${item._id}_${new Date().toISOString()}`,
      });
    },
    getTableData: function () {
      this.table.loading = true;
      this.pouches.organization
        .query("local_views/packingManifests")
        .then((results) => {
          this.table.data = results.rows
            .map((row) => row.value)
            .filter(
              ({ destination }) =>
                destination.organizationId == this.$organizationID
            )
            .map((manifest) => ({
              allow_data_share: manifest.allow_data_share,
              // dummy target for checkbox that is later discarded
              allow_data_share_dummy: manifest.allow_data_share,
              avg_hot_weight:
                manifest.statistics.averages.hot_weight &&
                parseFloat(manifest.statistics.averages.hot_weight.toFixed(2)),
              choice: manifest.statistics.totals.choice,
              discards: manifest.statistics.totals.no_roll,
              origin_lots: manifest.statistics.aggregates.origin_lots,
              packer_lots: manifest.statistics.aggregates.packer_lots,
              prime: manifest.statistics.totals.prime,
              // dummy target for checkbox that is later discarded
              receipt_dummy:
                manifest.receipt.date ||
                manifest.receipt.first_carcass_score_date,
              receipt_date: manifest.receipt.date,
              receipt_first_carcass_score_date:
                manifest.receipt.first_carcass_score_date,
              scored: manifest.statistics.totals.scored,
              select: manifest.statistics.totals.select,
              sending_org: manifest.organization.name,
              total_animals: manifest.statistics.totals.promised,
              ...manifest,
            }));
          this.table.loading = false;
        })

        .catch((e) => {
          console.log("Error getting data", e);
        });
    },
    setCarcassDataToManifest: function () {
      const manifest = this.dialogLoadScores.manifest;
      const db = this.pouches.organization;
      const self = this;
      db.get(manifest._id)
        .then(function (doc) {
          return db.put({
            ...doc,
            animals: manifest.animals,
            lastUpdated: new Date().toISOString(),
            receipt: manifest.receipt,
          });
        })
        .then(function (res) {
          console.log(res);
          self.$notify({
            group: "forms",
            text: "Manifest Updated",
            title: "Success",
            type: "success",
          });
          self.getTableData();
        })
        .catch(function (err) {
          console.log("Error: Set Carcass Data to manifest", err);
          self.$notify({
            group: "forms",
            text: "An error occcurred.",
            title: "Error",
            type: "error",
          });
        });
      this.dialogLoadScores.manifest = null;
      this.dialogLoadScores.show = false;
      this.fileSelected = null;
    },
    toggleAllowDataShare: function (manifest) {
      const db = this.pouches.organization;
      const self = this;

      db.get(manifest._id)
        .then(function (doc) {
          return db.put({
            ...doc,
            allow_data_share: !doc.allow_data_share,
            lastUpdated: new Date().toISOString(),
          });
        })
        .then(function (res) {
          console.log(res);
          self.$notify({
            group: "forms",
            text: "Manifest Updated",
            title: "Success",
            type: "success",
          });
          self.getTableData();
        })
        .catch(function (err) {
          console.log("Error: Set Carcass Data to manifest", err);
          self.$notify({
            group: "forms",
            text: "An error occcurred.",
            title: "Error",
            type: "error",
          });
        });
      this.dialogLoadScores.manifest = null;
      this.dialogLoadScores.show = false;
      this.fileSelected = null;
    },
    toggleReceipt: function (manifest) {
      const db = this.pouches.organization;
      const self = this;

      db.get(manifest._id)
        .then(function (doc) {
          return db.put({
            ...doc,
            lastUpdated: new Date().toISOString(),
            receipt: {
              date: manifest.receipt.date ? null : new Date().toISOString(),
            },
          });
        })
        .then(function (res) {
          console.log(res);
          self.$notify({
            group: "forms",
            text: "Manifest Updated",
            title: "Success",
            type: "success",
          });
          self.getTableData();
        })
        .catch(function (err) {
          console.log("Error: Set Carcass Data to manifest", err);
          self.$notify({
            group: "forms",
            text: "An error occcurred.",
            title: "Error",
            type: "error",
          });
        });
      this.dialogLoadScores.manifest = null;
      this.dialogLoadScores.show = false;
      this.fileSelected = null;
    },
    dropHandler: function (e) {
      const file = e.dataTransfer.files[0];
      this.fileSelected = file;
    },
    openEditManifestDialog: function (manifest) {
      this.dialogLoadScores.manifest = manifest;
      this.dialogLoadScores.show = true;
    },
    exportCarcassDataToCSV: function (manifest) {
      const animals = manifest.animals;
      const carcass_fields = this.carcass_fields.map((field) =>
        field.replace(" ", "_")
      );
      // Convert Manifest.Animals to a proper structure for Papaparse
      const carcass_data_array = animals.map((animal) => {
        const carcass_data = {};
        carcass_fields.forEach((field) => {
          const csv_field = this.$utils.capitalizeString(field, "_");
          carcass_data[csv_field] =
            animal.carcass_data && animal.carcass_data[field];
        });
        return {
          Guid: animal.id,
          "Manifest ID": manifest._id,
          EID: animal.eids,
          Visuals: animal.visuals,
          "Origin Lot #": animal.lotNumber,
          "Origin Pen #": animal.penNumber && animal.penNumber.name,
          ...carcass_data,
        };
      });
      this.$utils.exportToCSV({
        data: carcass_data_array,
        filename: `carcass_data_${
          manifest.organization.id
        }_${new Date().toISOString()}`,
      });
    },
    testing: function () {
      axios
        .post("/api/organizations/outboundmanifest", this.dummyData, {
          params: {
            token: this.getToken(),
          },
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    },
  },
  computed: {
    tableHeaders: function () {
      return [
        { text: "Sending Org", value: "sending_org" },
        { text: "Total Animals", value: "total_animals" },
        { text: "Discards", value: "discards" },
        { text: "Scores", value: "fulfillment" },
        { text: "Choice", value: "choice" },
        { text: "Select", value: "select" },
        { text: "Prime", value: "prime" },
        { text: "Avg hot weight", value: "avg_hot_weight" },
        { text: "Origin Lot #", value: "origin_lots" },
        { text: "Packer Lot #", value: "packer_lots" },
        // { text: "Created On", value: "createdOn" },
        { text: "Data Receipt Time", value: "copiedDateTimeISO" },
        { text: "Ship date", value: "shipDate" },
        { text: "Last Updated", value: "lastUpdated" },
        // { text: "Closed", value: "close" },
        { text: "Received", value: "receipt_date" },
        { text: "Share data", value: "allow_data_share" },
        { text: "Load Scores", value: "load_scores" },
        { text: "Averages", value: "export_averages" },
        { text: "Totals", value: "export_totals" },
        { text: "Scores", value: "export_carcass_data" },
      ];
    },
  },
  watch: {
    fileSelected: async function (file) {
      this.fileSelected = file && file.type == "text/csv" ? file : null;
      const manifest = this.dialogLoadScores.manifest;
      const carcass_fields = this.carcass_fields;
      const carcass_json = await this.$utils.convertCSVtoJson(
        this.fileSelected
      );

      const carcass_data_props = Object.keys(carcass_json);

      const requiredFieldMissing = this.required_fields.find(
        (requiredField) => {
          const field = carcass_data_props.find((field) =>
            field.match(requiredField.regExp)
          );
          if (
            !field ||
            !carcass_json[field].filter((elem) => elem.length).length
          ) {
            return requiredField;
          }
        }
      );

      if (requiredFieldMissing) {
        this.$notify({
          group: "forms",
          text: `${requiredFieldMissing.fieldName} is required.`,
          title: "Error",
          type: "error",
        });
        this.fileSelected = null;
        return;
      }
      const atLeastOneIsRequired = this.carcass_data_fields.find(
        (oneFieldRequired) => {
          const field = carcass_data_props.find((field) =>
            field.match(oneFieldRequired.regExp)
          );
          if (
            field &&
            carcass_json[field].filter((elem) => elem.length).length
          ) {
            return oneFieldRequired;
          }
        }
      );

      if (!atLeastOneIsRequired) {
        this.$notify({
          group: "forms",
          text: `At least one of the following fields is required: ${this.carcass_data_fields.map(
            ({ fieldName }) => fieldName
          )}.`,
          title: "Error",
          type: "error",
        });
        this.fileSelected = null;
        return;
      }

      const eids = carcass_json.EID || carcass_json.eid;

      eids.forEach((eid, idx) => {
        // Get carcass_data related to the current eid
        const carcass_data = {
          createdBy: this.$userID,
          createdOn: new Date().toISOString(),
          timeRecorded: new Date().toISOString(),
        };

        carcass_data_props.forEach((key) => {
          const field = carcass_fields.find((field) =>
            key.toLowerCase().trim().includes(field)
          );
          const value = carcass_json[key][idx];
          if (field && value && String(value).length)
            carcass_data[field.replace(" ", "_")] = isNaN(value)
              ? value
              : +value;
        });

        // Find animal eid and set carcass_data
        const animal = manifest.animals.find(({ eids }) => eids.includes(eid));
        if (animal) {
          animal.carcass_data = animal.carcass_data
            ? {
                ...animal.carcass_data,
                ...carcass_data,
              }
            : carcass_data;
        } else {
          manifest.animals.push({
            eids: eid ? [eid] : [],
            visuals: [],
            carcass_data,
          });
        }
      });

      console.log(manifest);
    },
  },
};
</script>
<style scoped>
.drop_zone {
  width: 100%;
  height: 400px;
  border: dashed blue 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>