var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu'),_c('div',{staticClass:"g-row pt-20 px-4"},[_c('div',{staticClass:"g-col"},[_c('div',{staticClass:"g-row mb-4"},[_c('div',{staticClass:"dropzone d-flex flex-wrap justify-center",attrs:{"draggable":"true","id":"dropzone"},on:{"dragleave":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)}}},[_c('div',{staticClass:"dropzone-text mb-2"},[_vm._v(" "+_vm._s(_vm.getTranslation("fileETL.dropFile"))+" ")]),_c('input',{ref:"fileInput",staticClass:"file-input",attrs:{"accept":".csv, .xls, .xlsx","id":"fileInput","type":"file"},on:{"change":_vm.getSelectedFile}}),_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_vm._v(" "+_vm._s(_vm.getTranslation("fileETL.useFile"))+" ")]),(_vm.selectedFile && _vm.selectedFile.name)?_c('p',{staticClass:"mb-0 mt-2"},[_vm._v(" "+_vm._s(_vm.selectedFile.name)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"g-row"},[_c('div',{staticClass:"g-row"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"disabled":!_vm.toggleCategories.length || _vm.loading}},'v-btn',attrs,false),on),[_vm._v(" Toggle Column "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.controls.toggleColumn.show),callback:function ($$v) {_vm.$set(_vm.controls.toggleColumn, "show", $$v)},expression:"controls.toggleColumn.show"}},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"400px"},attrs:{"dense":""}},_vm._l((_vm.toggleCategories),function(category,idx){return _c('v-list-item',{key:idx,staticStyle:{"max-height":"40px"}},[_c('v-checkbox',{staticClass:"custom-field",attrs:{"label":category.label,"dense":"","hide-details":""},on:{"change":function($event){return _vm.toggleColumn(category)}},model:{value:(category.isVisible),callback:function ($$v) {_vm.$set(category, "isVisible", $$v)},expression:"category.isVisible"}})],1)}),1)],1),_c('v-btn',{staticClass:"py-0 mr-3",attrs:{"disabled":!_vm.rowsSelected.length || _vm.loading,"color":"red darken-3"},on:{"click":function($event){_vm.dialogs.deleteRows.show = true}}},[_vm._v(" "+_vm._s(_vm.getTranslation("delete"))+" "),(_vm.rowsSelected.length > 0)?_c('span',{staticClass:"v-btn__content"},[_vm._v(" ("+_vm._s(_vm.rowsSelected.length)+") ")]):_vm._e(),_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"py-0",attrs:{"disabled":_vm.invalidImport},on:{"click":_vm.importCarcassData}},[_vm._v(" "+_vm._s(_vm.getTranslation("import"))+" "),(_vm.validData.length > 0)?_c('span',{staticClass:"v-btn__content"},[_vm._v(" ("+_vm._s(_vm.validData.length)+") ")]):_vm._e()]),(_vm.table.data.length > 0)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"color":"info","icon":""},on:{"click":function($event){_vm.infoTips.import.show = !_vm.infoTips.import.show}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])],1)]}}],null,false,2162099980),model:{value:(_vm.infoTips.import.show),callback:function ($$v) {_vm.$set(_vm.infoTips.import, "show", $$v)},expression:"infoTips.import.show"}},[_c('span',{staticClass:"warning-message"},[(!_vm.groupNumberValidated)?_c('span',[_vm._v(" Group Number is required. ")]):(_vm.duplicatedCategories.length > 0)?_c('span',[_vm._v(" Duplicated categories. Table must have only 1 column per category. ")]):(_vm.idIsMissing)?_c('span',[_vm._v(" CarcID or EID are required. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.validData.length)+" rows are validated correctly. ")])])]):_vm._e()],1),_c('div',{staticClass:"g-row"},[_c('v-text-field',{staticClass:"custom-field",staticStyle:{"max-width":"150px"},attrs:{"disabled":!_vm.table.data.length,"error":!_vm.extraFields.groupNumber &&
              _vm.invalidDataCounter.groupNumber.counter == _vm.table.data.length,"label":_vm.getTranslation('Group Number'),"dense":"","hide-details":"","outlined":""},model:{value:(_vm.extraFields.groupNumber),callback:function ($$v) {_vm.$set(_vm.extraFields, "groupNumber", $$v)},expression:"extraFields.groupNumber"}}),(_vm.table.data.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"color":"info","icon":""},on:{"click":function($event){_vm.infoTips.groupNumber.show = !_vm.infoTips.groupNumber.show}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])],1)]}}],null,false,4170341228),model:{value:(_vm.infoTips.groupNumber.show),callback:function ($$v) {_vm.$set(_vm.infoTips.groupNumber, "show", $$v)},expression:"infoTips.groupNumber.show"}},[_c('span',{staticClass:"warning-message",staticStyle:{"max-width":"200px"}},[_vm._v(" This group number will replace all the group number column ")])]):_vm._e()],1),_c('v-data-table',{ref:"importerTable",staticClass:"w-100 dashboard-data-table sticky-header custom-padding",attrs:{"headers":_vm.table.headers,"items":_vm.table.data,"loading":_vm.loading,"height":"560","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"primary","height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}]),model:{value:(_vm.table.progressIndicator),callback:function ($$v) {_vm.$set(_vm.table, "progressIndicator", $$v)},expression:"table.progressIndicator"}})]},proxy:true},(_vm.table.data.length > 0)?{key:"header",fn:function(ref){
              var props = ref.props;
return [_c('tr',{ref:"categories"},_vm._l((props.headers),function(h,idx){return _c('th',{key:idx},[(_vm.showTableTopIndicators)?_c('div',{staticClass:"d-flex justify-center align-center my-2",staticStyle:{"height":"36px"}},[(h.changeAllAbility.checkboxEnabled)?_c('v-checkbox',{staticClass:"custom-field",attrs:{"dense":"","hide-details":"","label":"All"},model:{value:(h.changeAllAbility.active),callback:function ($$v) {_vm.$set(h.changeAllAbility, "active", $$v)},expression:"h.changeAllAbility.active"}}):_vm._e(),(
                      _vm.invalidDataCounter[h.category] &&
                      _vm.invalidDataCounter[h.category].counter > 0
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){h.warning.show = !h.warning.show}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])],1)]}}],null,true),model:{value:(h.warning.show),callback:function ($$v) {_vm.$set(h.warning, "show", $$v)},expression:"h.warning.show"}},[_c('span',{staticClass:"warning-message"},[_vm._v(" "+_vm._s(_vm.invalidDataCounter[h.category].counter)+" errors. "+_vm._s(_vm.invalidDataCounter[h.category].warning)+" "+_vm._s(_vm.idIsMissing ? "Carc ID or EID are required." : "")+" ")])]):_vm._e()],1):_vm._e(),(h.value !== 'row')?_c('v-select',{staticClass:"custom-field",staticStyle:{"min-width":"130px"},attrs:{"disabled":_vm.loading,"items":_vm.categories,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"input":function($event){return _vm.updateColumnConfig(idx)}},model:{value:(h.category),callback:function ($$v) {_vm.$set(h, "category", $$v)},expression:"h.category"}}):_vm._e()],1)}),0)]}}:null,{key:"item",fn:function(ref){
                    var item = ref.item;
                    var rowIdx = ref.index;
                    var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(head,colIdx){return _c('td',{key:colIdx},[(colIdx === 0)?_c('v-checkbox',{staticClass:"custom-field",attrs:{"dense":"","hide-details":""},model:{value:(item.isSelected),callback:function ($$v) {_vm.$set(item, "isSelected", $$v)},expression:"item.isSelected"}}):(head.category === 'eid')?_c('div',[_c('v-text-field',{staticClass:"custom-field",attrs:{"error":!head.isValid(item[head.value]),"dense":"","hide-details":"","outlined":""},model:{value:(item[head.value]),callback:function ($$v) {_vm.$set(item, head.value, $$v)},expression:"item[head.value]"}})],1):(head.category === 'carcId')?_c('div',[_c('v-text-field',{staticClass:"custom-field",attrs:{"error":!head.isValid(item[head.value]),"dense":"","hide-details":"","outlined":""},model:{value:(item[head.value]),callback:function ($$v) {_vm.$set(item, head.value, $$v)},expression:"item[head.value]"}})],1):_c('div',[(head.field.type == 'text')?_c('v-text-field',{staticClass:"custom-field",attrs:{"error":!head.isValid(item[head.value]),"dense":"","hide-details":"","outlined":""},model:{value:(item[head.value]),callback:function ($$v) {_vm.$set(item, head.value, $$v)},expression:"item[head.value]"}}):(head.field.type == 'calendar')?_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":"","min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-field",attrs:{"error":!head.isValid(item[head.value]),"append-icon":"mdi-calendar","dense":"","hide-details":"","outlined":"","readonly":""},on:{"click:append":function($event){head.field.cell[rowIdx][colIdx].show = true}},model:{value:(item[head.value]),callback:function ($$v) {_vm.$set(item, head.value, $$v)},expression:"item[head.value]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(head.field.cell[rowIdx][colIdx].show),callback:function ($$v) {_vm.$set(head.field.cell[rowIdx][colIdx], "show", $$v)},expression:"head.field.cell[rowIdx][colIdx].show"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.updateDateByColumn(
                          head.field.cell[rowIdx][colIdx],
                          head.value,
                          item[head.value]
                        )}},model:{value:(item[head.value]),callback:function ($$v) {_vm.$set(item, head.value, $$v)},expression:"item[head.value]"}})],1):_vm._e()],1)],1)}),0)]}}],null,true)})],1)])]),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.dialogs.deleteRows.show),callback:function ($$v) {_vm.$set(_vm.dialogs.deleteRows, "show", $$v)},expression:"dialogs.deleteRows.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Delete")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.dialogs.deleteRows.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.rowsSelected.length)+" rows? ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"loading":_vm.dialogs.deleteRows.loading,"color":"error"},on:{"click":_vm.deleteRows}},[_vm._v(" Delete ")])],1)],1)],1),_c('success-dialog',{model:{value:(_vm.showSuccessDialog),callback:function ($$v) {_vm.showSuccessDialog=$$v},expression:"showSuccessDialog"}}),_c('error-dialog',{model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }