<template>
  <div>
    <v-card
      class="dashboard-background rounded-lg mx-2 pa-2 relative"
      elevation="1"
    >
      <div class="copy-button">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="$emit('copy')"
              @mouseleave="$emit('mouseleave-from-icon')"
              icon
            >
              <v-icon v-bind="attrs" v-on="on"> mdi-content-copy </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltipText }}</span>
        </v-tooltip>
      </div>
      <code>
        <v-chip :color="variant" outlined>
          {{ env }}
        </v-chip>
        <div>
          <span style="font-weight: bold">Organization ID:</span>
          <br />
          {{ getOrganizationID }}
        </div>
        <div>
          <span style="font-weight: bold">User ID:</span>
          <br />
          {{ getUserID }}
        </div>
      </code>
    </v-card>
    <div class="ml-2 mt-3">
      <a
        :href="`/couchdb/_utils/#database/organization-${getOrganizationID}/_all_docs`"
      >
        CouchDB <v-icon>mdi-open-in-new</v-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DevToolsContent",
  props: ["env", "tooltipText", "variant"],
  computed: {
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
      getUserID: "User/getUserID",
    }),
  },
};
</script>

<style scoped>
code {
  background: transparent !important;
  display: block;
  font-size: 0.7rem;
  white-space: pre-wrap;
}
.copy-button {
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
