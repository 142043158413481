<template>
  <div
    :class="[
      $vuetify.theme.dark ? 'dark-background-color' : 'light-background-color',
      'height-calc',
      'px-4',
      'py-3',
    ]"
  >
    <v-dialog
      max-width="900px"
      scrollable
      transition="dialog-transition"
      v-model="transfersDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Transfer History</h4>
          <v-icon class="buttons" @click="transfersDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <transfers-table :animalID="guid" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="g-row">
      <div
        class="g-col g-col-1 justify-center align-center"
        style="min-width: 150px"
      >
        <v-icon size="75" color="#3465a1" class="w-100">
          {{ animalType.icon }}
        </v-icon>
        <v-chip class="blue lighten-3 white--text w-fit-content">
          {{ animalType.type }}
        </v-chip>
        <v-chip
          class="w-fit-content mt-2"
          color="error"
          v-if="animalWasDeleted"
        >
          {{ getTranslation("Deleted") }}
        </v-chip>
      </div>

      <div
        class="g-col g-col-2 justify-center allow-grow-4 mobile-row mobile-justify-center mobile-align-center mobile-my-4"
      >
        <h5 class="mb-0">
          {{ tagSummary }}
        </h5>
        <p class="mb-0">{{ animalAge }}</p>
        <template v-if="getFinalTransferStatement">
          <p class="m-0">
            {{ getFinalTransferStatement }}
            <button
              @click="transfersDialog.show = true"
              class="btn btn-link link"
            >
              {{ getLabelTranslation("History") }}
            </button>
          </p>
        </template>
        <p class="mb-0">
          {{ currentComment }}
        </p>
        <div class="danger" v-if="animalWasMerged">
          Merged
          <v-icon>mdi-arrow-right</v-icon>
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: {
                id: animal.doc.mergedTo[0].otherGUID,
              },
            }"
            class="text-none ml-1"
          >
            {{ animal.doc.mergedTo[0].otherTagValues }}
          </router-link>
        </div>
      </div>
      <div class="g-col g-col-1 align-center justify-center">
        <status-history :animalID="guid" />
      </div>
    </div>
    <div class="g-row mt-5">
      <template v-if="animal">
        <v-btn
          @click="confirmDelete()"
          class="mr-2"
          color="error"
          v-if="!animalWasDeleted"
        >
          {{ getLabelTranslation("Delete") }}
        </v-btn>
        <v-btn
          :disabled="animalWasMerged"
          @click="animal.restoreAnimal()"
          class="mr-2"
          color="error"
          v-else
        >
          {{ getLabelTranslation("Undelete") }}
        </v-btn>
      </template>
      <router-link
        :to="{
          name: 'AnimalDetailsDeprecated',
          query: { id: guid },
        }"
        class="mb-4"
        style="text-decoration: none"
        v-if="isSuperAdmin && guid"
      >
        <v-btn class="mr-2" color="default">
          <div style="color: #1976d2">Classic View</div>
        </v-btn>
      </router-link>
      <v-btn v-if="isSuperAdmin && guid">
        <a
          :href="`/couchdb/_utils/#database/organization-${$organizationID}/${guid}`"
          target="_blank"
          >CouchDB</a
        >
      </v-btn>
    </div>
    <v-row class="ma-0">
      <v-tabs grow show-arrows v-model="tabSelected">
        <v-tab :key="idx" v-for="(tab, idx) in tabs">
          <template v-if="tab.badge">
            <v-badge color="#EBF1F9" :content="tab.badge">
              {{ tab.title }}
            </v-badge>
          </template>
          <template v-else>
            {{ tab.title }}
          </template>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSelected">
        <!-- Comment -->
        <v-tab-item>
          <v-progress-circular indeterminate size="50" v-if="loading" />
          <comment-history-table
            :animal="animal"
            :tableData="comments"
            @data-editted="getAnimalSummaries"
            v-else
          />
        </v-tab-item>

        <!-- General -->
        <v-tab-item>
          <general-details :animalID="guid" showExtraInformation />
        </v-tab-item>

        <!-- Birth -->
        <v-tab-item>
          <v-progress-circular indeterminate size="50" v-if="loading" />
          <div class="g-row" v-else>
            <birth-details
              :animalID="guid"
              :class="[
                ['sm', 'xs', 'md'].includes($vuetify.breakpoint.name)
                  ? 'force-w-100'
                  : '',
                'g-col-2',
              ]"
              showExtraInformation
            />
            <family-details :animalID="guid" class="g-col" />
          </div>
        </v-tab-item>

        <!-- Tags -->
        <v-tab-item>
          <tag-history :animalID="guid" showExtraInformation />
        </v-tab-item>

        <!-- Financials -->
        <v-tab-item>
          <v-progress-circular indeterminate size="50" v-if="loading" />
          <financials-details
            :animal="animal"
            :data="financialsDetails"
            v-else
          />
        </v-tab-item>

        <!-- Weight -->
        <v-tab-item>
          <weight-history-table
            :animalID="guid"
            @data-editted="getAnimalSummaries"
            showExtraInformation
          />
        </v-tab-item>

        <!-- Treatments -->
        <v-tab-item>
          <v-progress-circular indeterminate size="50" v-if="loading" />
          <treatment-history-table
            :animalID="guid"
            showExtraInformation
            v-else
          />
        </v-tab-item>

        <!-- Breedings -->
        <v-tab-item>
          <v-progress-circular indeterminate size="50" v-if="loading" />
          <div v-else>
            <bull-test-history-table
              :animalID="guid"
              class="mb-5"
              showTitle
              v-if="gender === 'Male' || bullTestHistory.length > 0"
            />
            <heifer-test-history-table
              :animalID="guid"
              class="mb-5"
              showTitle
              v-if="gender === 'Female' || heiferTestHistory.length > 0"
            />
            <preg-check-history-table
              :animalID="guid"
              class="mb-8"
              showTitle
              v-if="gender === 'Female' || pregCheckHistory.length > 0"
            />
            <calving-history-table :animalID="guid" showTitle />
          </div>
        </v-tab-item>

        <!-- Purchase Sale History -->
        <v-tab-item>
          <purchase-sale-history :animalID="guid" />
        </v-tab-item>

        <!-- Harvest History -->
        <v-tab-item>
          <harvest :animal="animal" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </div>
</template>
<script>
import { IsSuperAdmin } from "../utils/functions/auth";
import BirthDetails from "../components/animalDetails/birthDetails.vue";
import BullTestHistoryTable from "../components/animalDetails/bullTestHistory.vue";
import CalvingHistoryTable from "../components/animalDetails/calvingHistory.vue";
import CommentHistoryTable from "../components/animalDetails/commentHistory.vue";
import FamilyDetails from "../components/animalDetails/familyDetails.vue";
import FinancialsDetails from "../components/animalDetails/financialsDetails.vue";
import HeiferTestHistoryTable from "../components/animalDetails/heiferTestHistory.vue";
import GeneralDetails from "../components/animalDetails/generalDetails.vue";
import Harvest from "../components/animalDetails/harvest.vue";
import PregCheckHistoryTable from "../components/animalDetails/pregCheckHistory.vue";
import PurchaseSaleHistory from "../components/animalDetails/purchaseSaleHistory.vue";
import StatusHistory from "../components/animalDetails/statusHistory.vue";
import TagHistory from "../components/animalDetails/tagHistory.vue";
import TransfersTable from "../components/animalDetails/transfersTable.vue";
import TranslationMixin from "../mixins/Translations";
import TreatmentHistoryTable from "../components/animalDetails/treatmentHistory.vue";
import WeightHistoryTable from "../components/animalDetails/weightHistoryTable.vue";

export default {
  name: "AnimalDetailsBeta",
  metaInfo: {
    title: "Animal Details",
  },
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: false,
      type: String,
    },
  },
  components: {
    BirthDetails,
    BullTestHistoryTable,
    CalvingHistoryTable,
    CommentHistoryTable,
    FamilyDetails,
    FinancialsDetails,
    GeneralDetails,
    Harvest,
    HeiferTestHistoryTable,
    PregCheckHistoryTable,
    PurchaseSaleHistory,
    StatusHistory,
    TagHistory,
    TransfersTable,
    TreatmentHistoryTable,
    WeightHistoryTable,
  },
  data() {
    return {
      animal: {},
      animalAge: null,
      broadcast: null,
      bullTestHistory: [],
      heiferTestHistory: [],
      comments: [],
      financialsDetails: [],
      herdMeta: null,
      loading: true,
      pouches: null,
      pregCheckHistory: [],
      tabSelected: 1,
      transfersDialog: {
        show: false,
      },
      tags: [],
      weights: [],
    };
  },
  computed: {
    animalDerivationIsReady: function () {
      return (
        this.animal &&
        this.animal.derived &&
        this.animal.derived.summaries &&
        // gender is a good sentinel to detect whether full summaries exist
        this.animal.derived.summaries.main.gender
      );
    },
    animalWasDeleted: function () {
      return this.animal && this.animal.doc && this.animal.doc.deleted;
    },
    animalWasMerged: function () {
      return (
        this.animal && this.animal.doc && Animal.wasMerged(this.animal.doc)
      );
    },
    getFinalTransferStatement: function () {
      if (!this.animal || !this.animal.doc) {
        return null;
      }
      return Animal.getFinalTransferStatement(this.animal.doc);
    },
    guid: function () {
      return this.animalID || this.$route.query.id;
    },
    animalType: function () {
      if (!this.animalDerivationIsReady) return { icon: "$calfCow", type: "?" };

      if (this.animal.derived.summaries.main.status.toLowerCase() === "semen")
        return { icon: "mdi-needle", type: "Semen" };

      const date =
        this.animal.derived.birth.birthDate.birthDate ||
        this.animal.doc.createdOn;

      if (this.animal.derived.summaries.pregCheck.daysBred)
        return { icon: "$dam", type: "Cow" };

      if (this.animal.derived.summaries.main.lastBullTest)
        return { icon: "$bull", type: "Bull" };

      if (date > moment().subtract(1, "year").toISOString())
        return { icon: "$calf", type: "Calf" };
      else if (
        this.animal.derived.summaries.main.gender.toLowerCase() === "female"
      ) {
        return { icon: "$heifer", type: "Heifer" };
      } else {
        return { icon: "$steer", type: "Steer" };
      }
    },
    currentComment: function () {
      if (!this.comments || this.comments.length === 0) return null;

      const currentComment = this.comments[0].comment;

      if (currentComment && currentComment.length > 80)
        return currentComment.substr(0, 80) + "...";

      return currentComment;
    },
    isSuperAdmin: function () {
      return IsSuperAdmin();
    },
    gender: function () {
      if (!this.animalDerivationIsReady) return null;
      return this.animal.doc.derived.summaries.main.gender;
    },
    tabs: function () {
      return [
        {
          disabled: false,
          title: this.getTranslation("comment"),
          badge: this.comments.length,
        },
        { disabled: false, title: this.getTranslation("General"), badge: null },
        { disabled: false, title: this.getTranslation("Birth"), badge: null },
        {
          disabled: false,
          title: this.getLabelTranslation("animalIds"),
          badge: null,
        },
        {
          disabled: false,
          title: this.getTranslation("Financials"),
          badge: null,
        },
        {
          disabled: false,
          title: this.getTranslation("weight"),
          badge: this.weights.length,
        },
        {
          disabled: false,
          title: this.getTranslation("vaccinationsList"),
        },
        {
          disabled: false,
          title: this.getTranslation("Breedings"),
        },
        {
          disabled: false,
          title: this.getTranslation("purchaseSaleHistory"),
        },
        {
          disabled:
            this.animal &&
            this.animal.doc &&
            this.animal.doc.carcassData &&
            this.animal.doc.carcassData.length == 0,
          title: this.getTranslation("Harvest"),
        },
      ].filter((item) => !item.disabled);
    },
    tagSummary: function () {
      const active = this.tags
        .filter(({ deleted }) => !deleted)
        .filter(({ status }) => !status || status.toLowerCase() === "active")
        .filter(({ tagValue }) => tagValue);
      const eids = [
        ...new Set(
          active
            .filter(({ type }) => (type || "").toLowerCase() === "eid")
            .map(({ tagValue }) => tagValue)
        ),
      ].sort();
      const visuals = [
        ...new Set(
          active
            .filter(({ type }) => (type || "").toLowerCase() === "visual")
            .map(({ tagValue }) => tagValue)
        ),
      ].sort();
      return [eids.join(", ") || "?", visuals.join(", ") || "?"].join(" / ");
    },
  },
  created: function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = (e) => {
      this.$emit("animal-changed");
      this.init();
    };
    this.init();
  },
  beforeDestroy: function () {
    this.broadcast.close();
    // Unlatch from update stream
    if (!this.animal.poll) return;
    this.animal.poll(false);
  },
  methods: {
    confirmDelete: function () {
      if (!confirm(this.getLabelTranslation("confirmDelete"))) {
        return;
      }

      this.animal.deleteAnimal();
    },
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.$herdMeta.pouches;
      this.herdMeta.loaded.done(async () => {
        const doc = await this.pouches.organization.get(this.guid);
        this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
        this.animal.poll(true);
        this.getAnimalSummaries();
        this.animalAge = this.getAnimalAge();
      });
    },
    getAnimalAge: function () {
      const date =
        this.animal.derived.birth.birthDate.birthDate ||
        this.animal.doc.createdOn;

      let age = this.$utils.getAgeOfTimestamp(date, {
        ignoreDays: true,
        ignoreHours: true,
      });
      const hasBirthDate = !!this.animal.derived.birth.birthDate;
      if (!hasBirthDate) {
        age = "~" + age;
      }
      return age;
    },
    getAnimalSummaries: function () {
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.guid,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.bullTestHistory = summaries.bullTestHistory || [];
          this.comments = summaries.commentHistory || [];
          this.financialsDetails = summaries.financials || [];
          this.heiferTestHistory = summaries.heiferTestHistory || [];
          this.pregCheckHistory = summaries.pregCheckHistory || [];
          this.tags = summaries.tags || [];
          this.weights = summaries.weightHistory || [];
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.height-calc {
  height: fit-content;
}
.light-background-color {
  background-color: #fafafb;
}

.dark-background-color {
  background-color: #121212;
}
.v-slide-group__content > .v-tab {
  background-color: #fafafb;
  border-bottom: 1.2px solid rgba(216, 213, 210, 1);
}
.theme--dark > .v-slide-group__wrapper > .v-slide-group__content > .v-tab {
  background-color: #1e1e1e;
  border-bottom: 1.2px solid rgba(255, 255, 255, 0.1);
}
.v-item-group {
  height: calc(100vh - 272px);
  overflow-y: auto;
  padding: 30px 40px;
  width: 100%;
}
</style>
<style lang="scss">
.v-window__container {
  height: 100%;
  width: 100%;
}
thead.v-data-table-header > tr > th[role="columnheader"] {
  background-color: #ebf1f9;
}

.theme--dark
  > .v-data-table__wrapper
  > table
  > thead.v-data-table-header
  > tr
  > th[role="columnheader"] {
  background-color: #1e1e1e;
}
.v-badge__wrapper > span {
  color: #3565a1;
}
</style>
