<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div>
          <table
            id="packersDisplay"
            class="table table-striped table-bordered w-100"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <tr>
                <th>{{ t.packers.id }}</th>
                <th>{{ t.packers.name }}</th>
                <th>{{ t.packers.archived }}</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>{{ t.packers.id }}</th>
                <th>{{ t.packers.name }}</th>
                <th>{{ t.packers.archived }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TranslationMixin from "@/mixins/Translations";
import Vue from "vue";

export default {
  name: "Packers",
  metaInfo: {
    title: "Packers",
  },
  mixins: [TranslationMixin],
  data: () => ({
    disposables: [],
    editor: null,
    herdMeta: null,
    table: null,
    tableData: null,
  }),
  computed: {
    ...mapGetters({
      organizationItems: "Organization/getOrganizationItems",
    }),
    packerOrganizations: function () {
      return this.organizations.filter(({ is_packer }) => is_packer);
    },
    organizations: function () {
      return this.organizationItems
        .filter(({ id }) => id !== this.$organizationID)
        .map(({ id, is_packer, name }) => {
          return { value: id, label: name, is_packer };
        });
    },
    t: function () {
      return {
        packers: {
          archived: this.translate.archived[this.localeLang],
          id: this.translate._id[this.localeLang],
          name: this.translate.name[this.localeLang],
        },
      };
    },
  },
  created: function () {
    this.init();
    this.setDisposables();
  },
  beforeDestroy: function () {
    $(this.disposables.join(", ")).off();
    if (this.editor != null) this.editor.destroy();
    if (this.table != null) this.table.destroy();
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.herdMeta.loaded.done(async () => {
        await this.prepareAndMakeTable();
      });
    },
    setDisposables: function () {
      this.disposables.push("#packersDisplay");
      this.disposables.push("input");
    },
    prepareAndMakeTable: async function () {
      const self = this;

      let editor = new $.fn.dataTable.Editor({
        ajax: function (method, url, d, successCallback, errorCallback) {
          var output = { data: [] };
          let promises = [];

          if (d.action === "create") {
            $.each(d.data, function (_key, fields) {
              const value = {
                name: self.organizations.find(
                  ({ value }) => value === fields.id
                ).label,
                organizationId: fields.id,
              };
              promises.push(self.herdMeta.create("packers", value, "packer"));
            });
          }

          $.when
            .apply($, promises)
            .fail((e) => {
              console.error(e);
              Vue.notify({
                group: "forms",
                type: "error",
                title: "Error",
                text: "An error occurred. Please refresh the page and try again.",
              });
              console.info(
                "Notification: An error occurred. Please refresh the page and try again."
              );
              errorCallback();
            })
            .done(() => {
              // Show Editor what has changed
              successCallback(output);
              setTimeout(self.makeTable, 1000);
            });
        },
        table: "#packersDisplay",
        fields: [
          {
            label: "Organization",
            name: "id",
            type: "select2",
            options: this.packerOrganizations,
          },
        ],
        formOptions: {
          main: {
            focus: "name",
          },
        },
      });
      self.editor = editor;
      await this.makeTable();
    },
    makeTable: async function () {
      const self = this;

      $("#packersDisplay tfoot th")
        .not(".responsive-control")
        .not(".details-control")
        .each(function () {
          var title = $(this).text();
          $(this).html(
            '<input  type="text" id="search' +
              this.id +
              '" class="form-control form-control-sm w-75 mx-auto" placeholder="' +
              title +
              '" />'
          );
        });
      // Object that will contain the local state
      var responsiveToggle = {};

      // Create or update the todo localStorage entry
      if (localStorage.getItem("packersDisplay.responsiveToggle")) {
        responsiveToggle = JSON.parse(
          localStorage.getItem("packersDisplay.responsiveToggle")
        );
      } else {
        responsiveToggle = true;
      }
      this.tableData = await this.getValues();
      let table = $("#packersDisplay").DataTable({
        data: self.tableData,
        destroy: true,
        dom: "BRlfrtip",
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        stateSave: true,
        stateLoadParams: function (settings, data) {
          for (var i = 1; i < data.columns.length; i++) {
            $("th:nth-child(" + i + ') > input[type="text"]').val(
              data.columns[i - 1].search.search
            );
          }
        },
        mark: true,
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"],
        ],
        fixedHeader: {
          header: true,
          headerOffset: $(".v-toolbar__content").outerHeight(),
        },
        select: true,
        colReorder: true,
        responsive: responsiveToggle,
        processing: true,
        autoWidth: false,
        initComplete: function () {
          self.$utils.applySearch(this);
        },
        columns: [
          {
            data: "id",
            orderable: false,
            visible: false,
            searchable: true,
          },
          { data: "name" },
          {
            data: "archived",
            render: function (archived, _type, _row, _meta) {
              return archived == true || archived == "true" ? "Yes" : "No";
            },
          },
        ],

        buttons: [
          {
            extend: "colvis",
            text: "Toggle",
            className: "btn mb-2 text-white",
          },
          {
            extend: "collection",
            text: "Select",
            autoClose: true,
            className: "btn mb-2 text-white",
            buttons: ["selectAll", "selectNone"],
          },
          {
            text: this.translate.tabletMode[responsiveToggle][this.localeLang],
            className: "btn mb-2 text-white",
            action: function (e, dt, node, config) {
              responsiveToggle = !responsiveToggle;
              localStorage.setItem(
                "packersDisplay.responsiveToggle",
                JSON.stringify(responsiveToggle)
              );
              window.location = window.location;
            },
          },
          {
            className: "btn mb-2 text-white",
            extend: "create",
            editor: this.editor,
            key: { altKey: true, key: "1" },
          },
          {
            className: "btn mb-2 text-white",
            text: "Archive",
            action: function (_e, dt, _button, _config) {
              if (!window.confirm("Archive record(s)?")) return;

              const promises = [];
              dt.rows({ selected: true })
                .data()
                .each((data) => {
                  promises.push(
                    self.herdMeta.update("packers", {
                      archived: true,
                      id: data.id,
                    })
                  );
                });

              $.when.apply($, promises).done(() => {
                setTimeout(self.makeTable, 1000);
              });
            },
            key: { altKey: true, key: "3" },
          },
          {
            className: "btn mb-2 text-white",

            text: "De-archive",
            action: function (_e, dt, _button, _config) {
              const promises = [];
              dt.rows({ selected: true })
                .data()
                .each((data) => {
                  promises.push(
                    self.herdMeta.update("packers", {
                      archived: false,
                      id: data.id,
                    })
                  );
                });

              $.when.apply($, promises).done(() => {
                setTimeout(self.makeTable, 1000);
              });
            },
            key: { altKey: true, key: "4" },
          },
          {
            className: "btn mb-2 text-white",
            text: "Reset Search",
            action: function (e, dt, node, config) {
              // eslint-disable-next-line no-alert
              if (window.confirm("Reset search?")) {
                table.search("").columns().search("").draw();

                $("#packersDisplay tfoot th input").each(function () {
                  this.value = "";
                });
              }
            },
          },
          {
            className: "btn mb-2 text-white",
            extend: "collection",
            text: "Export",
            autoClose: true,
            buttons: [
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
                text: "Copy",
                title: self.t.title,
              },
              {
                extend: "excelHtml5",
                exportOptions: {
                  columns: ":visible",
                },
                text: "Excel",
                title: self.t.title,
              },
              {
                extend: "csvHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
                text: "Csv",
                title: self.t.title,
              },
            ],
          },
        ],
      });
      table.on("column-reorder", function () {
        self.$utils.applySearch(table, true);
      });
      self.table = table;
    },
    getValues: function () {
      return new Promise((resolve, reject) => {
        this.herdMeta
          .getMetaPackersAsync(true, false, false)
          .done((packers) => {
            packers = packers
              .map((row) => {
                ["archived", "id", "name", "organizationId"].forEach(
                  (column) => {
                    // This is only necessary to make datatables happier. It does not like to have undefined Values.
                    if (row[column]) return;
                    row[column] = null;
                  }
                );

                return row;
              })
              .map((row) => {
                const matchingOrg = this.organizations.find(
                  ({ value }) => value === row.organizationId
                );

                // Upon load, update name of orgs.
                if (matchingOrg && matchingOrg.label !== row.name) {
                  row.name = matchingOrg.label;

                  // Persist change
                  this.herdMeta.update("packers", row);
                }
                return row;
              })
              .map((row) => {
                // This is only necessary to make datatables happier. It wants a unique identifier.
                row.DT_RowId = row.id;
                return row;
              });

            resolve(packers);
          });
      });
    },
  },
};
</script>
