<template>
  <div>
    <in-page-menu :activeBadge="activeBadge" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col>
        <div class="d-flex align-left">
          <v-menu :close-on-content-click="false" max-height="500" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Toggle Visibility
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="index"
                v-for="(field, index) in Object.keys(visibilityState)"
              >
                <v-checkbox
                  @change="
                    $utils.setObjectValues(visibilityState, !toggleVisibility)
                  "
                  :value="toggleVisibility"
                  class="ma-0"
                  hide-details
                  v-if="field == 'All'"
                ></v-checkbox>
                <v-checkbox
                  class="ma-0"
                  hide-details
                  v-else
                  v-model="visibilityState[field]"
                ></v-checkbox>
                <v-subheader>
                  {{ visibilityStateTranslation[field] }}
                </v-subheader>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Reorder -->
          <v-checkbox
            class="mt-0"
            hide-details
            v-model="form.reorderIsActivated"
          >
            <template #label>
              <div class="mt-2">Reorder</div>
            </template>
          </v-checkbox>
        </div>
        <v-row>
          <!-- Form -->
          <v-col cols="12" md="6">
            <v-form @submit.prevent="submitBullTestForm" v-model="form.isValid">
              <draggable draggable=".draggable">
                <search-animal
                  :allowedStatuses="['alive', 'cull', 'missing', 'sick']"
                  :class="['draggable' ? form.reorderIsActivated : '', 'mb-3']"
                  :isDraggable="form.reorderIsActivated"
                  :resetFields="herdConnect.resetFields"
                  @search-result="getAnimalResult"
                  allowedGender="male"
                  cardStyle
                  dense
                  includeTagsSummary
                />
                <v-text-field
                  :append-icon="
                    herdConnect.results.eid === form.fields.eid
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New EID')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :error="formErrorState.eid"
                  :rules="[rules.EID]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.eid"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        disabled
                        hide-details
                        v-model="lockedState.eid"
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :append-icon="
                    herdConnect.results.visual === form.fields.visual
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New Visual')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :error="formErrorState.visual"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.visual"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        disabled
                        hide-details
                        v-model="lockedState.visual"
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.bullTest)"
                  :items="options.bullTests"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Bull Test"
                  outlined
                  return-object
                  v-model="form.fields.bullTest"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.bullTest"
                      ></v-checkbox>
                      <v-icon disabled class="mt-1">mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.result)"
                  :items="options.results"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Result"
                  outlined
                  return-object
                  v-model="form.fields.result"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.result"
                      ></v-checkbox>
                      <v-icon disabled class="mt-1">mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.location)"
                  :items="options.locations"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  label="Pasture or Pen"
                  outlined
                  return-object
                  v-model="form.fields.location"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.location"
                      ></v-checkbox>
                      <v-icon disabled class="mt-1">mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.status)"
                  :items="options.statuses"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Status"
                  outlined
                  return-object
                  v-model="form.fields.status"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.status"
                      ></v-checkbox>
                      <v-icon disabled class="mt-1">mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-select>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Test Method"
                  outlined
                  v-if="visibilityState.testMethod"
                  v-model="form.fields.testMethod"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.testMethod"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.testMethod = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('bullTestTestedBy')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-if="visibilityState.vetTech"
                  v-model="form.fields.vetTech"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.vetTech"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.vetTech = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-textarea
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Comment"
                  outlined
                  rows="2"
                  v-if="visibilityState.comment"
                  v-model="form.fields.comment"
                >
                  >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.comment"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.comment = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-textarea>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Group"
                  outlined
                  v-if="visibilityState.group"
                  v-model="form.fields.group"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.group"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.group = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-row
                  :class="{ draggable: form.reorderIsActivated }"
                  class="ma-0"
                  v-if="visibilityState.treatment"
                >
                  <v-col class="pa-0" v-if="form.reorderIsActivated" cols="1">
                    <v-icon class="mt-2"> mdi-drag </v-icon>
                  </v-col>
                  <v-col class="pa-0" :cols="form.reorderIsActivated ? 5 : 6">
                    <v-select
                      :error="
                        form.fields.doseGiven &&
                        $utils.isWhitespace(form.fields.treatment)
                      "
                      :items="options.treatments"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mb-3"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      label="Treatment"
                      outlined
                      return-object
                      v-model="form.fields.treatment"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.treatment"
                          ></v-checkbox>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="pa-0" cols="5">
                    <v-text-field
                      :class="{ draggable: form.reorderIsActivated }"
                      :error="
                        (form.fields.treatment &&
                          $utils.isWhitespace(form.fields.doseGiven)) ||
                        (form.fields.doseGiven &&
                          !$utils.checkIfIsCorrectNumericValue(
                            form.fields.doseGiven
                          ))
                      "
                      class="mb-3"
                      clearable
                      dense
                      hide-details="auto"
                      label="Dose Administered"
                      outlined
                      v-model="form.fields.doseGiven"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.doseGiven"
                          ></v-checkbox>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="pa-0" cols="1">
                    <v-icon
                      @click="visibilityState.treatment = false"
                      class="mt-3"
                      >mdi-eye-minus</v-icon
                    >
                  </v-col>
                </v-row>
                <v-menu
                  :class="{ draggable: form.reorderIsActivated }"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-if="visibilityState.date"
                  v-model="form.showDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('date')"
                      :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                      append-icon="mdi-calendar"
                      class="mb-3"
                      dense
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="form.fields.date"
                      v-on:click:append="form.showDateCalendar = true"
                      v-on="on"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.date"
                          ></v-checkbox>
                          <v-icon
                            @click="visibilityState.date = false"
                            class="mt-1"
                            >mdi-eye-minus</v-icon
                          >
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="form.showDateCalendar = false"
                    v-model="form.fields.date"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  :append-icon="
                    herdConnect.results.weight != null &&
                    herdConnect.results.weight == form.fields.weight
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="
                    form.fields.weight &&
                    !$utils.checkIfIsCorrectNumericValue(form.fields.weight)
                  "
                  :label="getTranslation('weight')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  outlined
                  v-if="visibilityState.weight"
                  v-model="form.fields.weight"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.weight"
                      ></v-checkbox>
                      <v-icon
                        @click="visibilityState.weight = false"
                        class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('calfWeanPage.form.tsu')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  outlined
                  v-if="visibilityState.tsu"
                  v-model="form.fields.tsu"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.tsu"
                      ></v-checkbox>
                      <v-icon @click="visibilityState.tsu = false" class="mt-1"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
              </draggable>
              <div class="mt-4">
                <div class="d-flex flex-wrap">
                  <v-btn
                    :disabled="!form.isValid"
                    class="light-blue darken-4 white--text flex-grow-1"
                    large
                    type="submit"
                  >
                    <template v-if="!animalIsNew">
                      {{ getLabelTranslation("save") }}
                    </template>
                    <template v-else>
                      {{ getTranslation("Save New") }}
                    </template>
                  </v-btn>
                  <v-btn
                    @click="clearFields(false)"
                    color="primary"
                    large
                    outlined
                  >
                    {{ getTranslation("Reset") }}
                  </v-btn>
                </div>

                <div class="d-flex flex-wrap">
                  <herd-connect
                    :streamLF="streamEID"
                    :streamTSU="streamTSU"
                    :streamUHF="streamEID"
                    :streamVisual="streamVisual"
                    :streamWeight="streamWeight"
                  />
                </div>
              </div>
            </v-form>
          </v-col>
          <!-- Animal Information -->
          <v-col cols="12" md="6">
            <animal-information
              :animal="animal"
              :items="animalInformation"
              :maxHeight="
                ['sm', 'xs'].includes($vuetify.breakpoint.name) ? 800 : 400
              "
            />
          </v-col>
          <!-- Summary Table -->
          <v-col class="mb-5">
            <h3>Tested Today</h3>
            <!-- Table Controls -->
            <div class="d-flex">
              <export-button
                :changeValueOf="[
                  { prop: 'testCheckTime', value: 'exportDate' },
                ]"
                :disabled="!summaryTable.selectedRows.length"
                :headers="summaryTableHeaders"
                :items="summaryTable.selectedRows"
                filename="bullchecks"
              />
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                class="mb-2"
                clearable
                dense
                hide-details
                label="Search"
                outlined
                style="max-width: 300px"
                v-model="summaryTable.search"
              ></v-text-field>
            </div>
            <v-data-table
              :headers="summaryTableHeaders"
              :items="summaryTable.data"
              :search="summaryTable.search"
              class="d-flex flex-column"
              mobile-breakpoint="0"
              show-select
              v-model="summaryTable.selectedRows"
            >
              <template #item.tagValues="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.animalId },
                  }"
                  class="subtitle-2 text-none"
                  target="_blank"
                >
                  {{ item.tagValues }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <cull-alert :status="cullAlertStatus" />
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import TranslationMixin from "../mixins/Translations";
export default {
  mixins: [TranslationMixin],
  metaInfo: {
    title: "Bull Test",
  },
  name: "BullTest",
  data() {
    const formFields = {
      bullTest: null,
      comment: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      doseGiven: null,
      eid: null,
      group: null,
      location: null,
      result: null,
      status: null,
      testMethod: null,
      treatment: null,
      tsu: null,
      vetTech: null,
      visual: null,
      weight: null,
    };

    const copyFormFields = () => JSON.parse(JSON.stringify(formFields));
    const setValueToObject = (obj, value) =>
      Object.fromEntries(Object.keys(obj).map((key) => [key, value]));
    return {
      activeBadge: "?",
      animal: null,
      animalInformation: [
        { labelType: "Last Weight", value: "lastWeight" },
        { labelType: "sex", value: "sex" },
        { labelType: "color", value: "color" },
        { labelType: "comment", value: "comment" },
        { labelType: "status", value: "status" },
        { labelType: "headDays", value: "headDays" },
        { labelType: "totalEventIncome", value: "totalEventIncome" },
        { labelType: "group", value: "group" },
        { labelType: "pastureSlashPen", value: "location" },
        { labelType: "adgTotal", value: "adgTotal" },
        { labelType: "adg2", value: "adg2" },
        { labelType: "origin", value: "origin" },
        { labelType: "breed", value: "breed" },
        { labelType: "gender", value: "gender" },
      ],
      cullAlertStatus: "alive",
      form: {
        fields: copyFormFields(),
        isValid: false,
        reorderIsActivated: false,
        showDateCalendar: false,
      },
      herdConnect: {
        resetFields: false,
        results: {
          eid: null,
          tsu: null,
          visual: null,
          weight: null,
        },
      },
      herdMeta: null,
      lockedState: setValueToObject(copyFormFields(), false),
      options: {
        bullTests: [],
        eids: [],
        locations: [],
        results: [],
        statuses: [],
        treatments: [],
        visuals: [],
      },
      rules: {
        EID: (value) =>
          // convert null to string and numbers to string and strings to string
          /^$|^\d{15}$/.test(
            (value == null ? "" : value + "")
              .replace(/-/g, "")
              .replace(/ /g, "")
          ) || "Invalid ID",
      },
      summaryTable: {
        data: [],
        search: null,
        selectedRows: [],
      },
      title: "bull-test-entry",
      visibilityState: {
        All: true, // Stub reference to all others
        comment: true,
        date: true,
        group: true,
        testMethod: true,
        treatment: true,
        tsu: true,
        vetTech: true,
        weight: true,
      },
    };
  },
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("eid-not-pre-existing");
    EventBus.$off("eid-pre-existing");
    EventBus.$off("visual-not-pre-existing");
    EventBus.$off("visual-pre-existing");
  },
  created: function () {
    this.init();
    this.getPreviousData();
    this.setUpEventWatches();
  },
  computed: {
    animalIsNew: function () {
      return !this.animal || this.animal.animalIsNew;
    },
    formErrorState: function () {
      const eidEmpty =
        this.form.fields.eid == null || this.form.fields.eid.trim() == "";
      const visualEmpty =
        this.form.fields.visual == null || this.form.fields.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.fields.eid);

      // NOTE: Add more fields with similar logic if you need it
      // Error state does not expect an error message instead only expects a boolean value
      return {
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? true
            : false,
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? true
            : false,
      };
    },
    summaryTableHeaders: function () {
      return [
        {
          text: this.getTranslation("row"),
          value: "row",
        },
        {
          text: this.getTranslation("Tag IDs"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("timeRecorded"),
          value: "testCheckTime",
        },
        {
          text: this.getTranslation("bullTest"),
          value: "test",
        },
        {
          text: this.getTranslation("result"),
          value: "result",
        },
        {
          text: this.getTranslation("testMethod"),
          value: "testMethod",
        },
        {
          text: this.getTranslation("vetTech"),
          value: "testedBy",
        },
        {
          text: this.getTranslation("comment"),
          value: "comment",
        },
        {
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
        },
        {
          text: this.getTranslation("status"),
          value: "status",
        },
      ];
    },
    toggleVisibility: function () {
      return this.$utils.evaluateObjectValues(this.visibilityState, true);
    },
    visibilityStateTranslation: function () {
      return {
        All: this.getTranslation("All"),
        bullTest: this.getTranslation("bullTest"),
        comment: this.getTranslation("comment"),
        date: this.getTranslation("date"),
        eid: this.getTranslation("New EID"),
        group: this.getTranslation("group"),
        location: this.getLabelTranslation("pastureSlashPen"),
        result: this.getTranslation("result"),
        status: this.getTranslation("status"),
        testMethod: this.getTranslation("testMethod"),
        treatment: this.getTranslation("treatment"),
        vetTech: this.getTranslation("vetTech"),
        visual: this.getTranslation("New Visual"),
        weight: this.getTranslation("weight"),
      };
    },
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.herdMeta.loaded.then(async () => {
        this.fillSelectsOptions();
        this.summaryTable.data = await this.getSummaryTableData();
      });
    },
    addDataAsync: async function (animal, isNew = false) {
      const promises = [];

      const userId = this.$userID;
      const comment = (this.form.fields.comment || "").trim();
      const commentId = comment.length ? this.$utils.guid() : null;
      let timeRecordedISO = moment(
        moment(this.form.fields.date).format("MM/DD/YYYY")
      ).toISOString();
      const dateIsToday = moment().diff(moment(timeRecordedISO), "days") === 0;
      if (dateIsToday) {
        // Re-init with time
        timeRecordedISO = new Date().toISOString();

        const coords = await this.$utils.getGeoLocation();
        if (coords.latitude || coords.longitude)
          promises.push(
            animal.modify("geopoints", null, "id", coords.id, false, true, {
              ...coords,
              reason: "Bull Test",
              timeRecorded: timeRecordedISO,
              userId,
            })
          );
      }

      promises.push(
        animal.modify("genders", null, null, null, false, true, {
          gender: "male",
          timeRecorded: timeRecordedISO,
          userId,
        })
      );

      const groupNumber = this.form.fields.group;
      const locationId = this.form.fields.location.id;
      if (groupNumber || locationId)
        promises.push(
          animal.modify(
            "movements",
            null,
            "locationId",
            locationId,
            false,
            true,
            {
              location: this.form.fields.location.name,
              groupNumber,
              timeRecorded: timeRecordedISO,
              userId,
            }
          )
        );

      if (this.form.fields.eid && this.form.fields.eid.trim()) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.fields.eid.trim(),
          timeRecorded: timeRecordedISO,
          type: "eid",
          userId,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      if (this.form.fields.visual && this.form.fields.visual.trim()) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.fields.visual.trim(),
          timeRecorded: timeRecordedISO,
          type: "visual",
          userId,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      const status = this.form.fields.status.value;
      if (status)
        promises.push(
          animal.modify("status", null, "status", status, false, true, {
            commentId,
            timeRecorded: timeRecordedISO,
            userId,
          })
        );

      if (commentId)
        promises.push(
          animal.modify("comments", null, "comment", comment, false, true, {
            id: commentId,
            timeRecorded: timeRecordedISO,
            type: "eid",
            userId,
          })
        );

      const dose = this.form.fields.doseGiven;
      const treatmentId =
        this.form.fields.treatment && this.form.fields.treatment.id;
      let animalVaccinationsId = dose ? this.$utils.guid() : null;
      if (dose && treatmentId)
        promises.push(
          animal.modify(
            "treatments",
            null,
            "vaccinationsId",
            treatmentId,
            false,
            true,
            {
              actualDose: dose,
              commentId,
              id: animalVaccinationsId,
              vaccinationTime: timeRecordedISO,
              userId,
            }
          )
        );
      const weight = this.form.fields.weight;
      if (weight)
        promises.push(
          animal.modify("weights", null, null, null, false, true, {
            commentId,
            timeRecorded: timeRecordedISO,
            userId,
            weight,
          })
        );

      const tsu = this.form.fields.tsu;
      if (tsu)
        promises.push(
          animal.modify("dnaNumbers", null, "dnaNumber", tsu, false, true, {
            commentId,
            timeRecorded: timeRecordedISO,
            userId,
          })
        );

      const bullCheckTests = this.form.fields.bullTest.value;
      if (bullCheckTests) {
        const result = this.form.fields.result.value;
        const testedBy = this.form.fields.vetTech;
        const testMethod = this.form.fields.testMethod;
        promises.push(
          animal.modify(
            "bullTests",
            null,
            "test",
            bullCheckTests,
            false,
            true,
            {
              animalVaccinationsId,
              commentId,
              dnaNumber: tsu,
              result,
              testCheckTime: timeRecordedISO,
              testedBy,
              testMethod,
              userId,
              weight,
            }
          )
        );
      }
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then(() => {
            $.when(animal.save())
              .fail(reject)
              .done(() => resolve(animal));
          })
          .catch((e) => reject(new Error(`addDataAsync - Error: ${e}`)));
      });
    },
    clearFields: function (force) {
      if (force) {
        this.resetFormAndAnimalInformation(force);
        return;
      }
      if (confirm("Are you sure you want to clear the fields?"))
        this.resetFormAndAnimalInformation();
    },
    fillSelectsOptions: async function () {
      this.options.bullTests = this.getEnumOptions("bullCheckTests");
      this.options.results = this.getEnumOptions("testResultsBull");
      this.options.locations =
        (await this.herdMeta.getMetaLocationsAsync(true, true, true, false)) ||
        [];
      this.options.statuses = this.getEnumOptions("statuses");
      this.options.treatments =
        (await this.herdMeta.getMetaVaccinationsAsync(true, true, true)) || [];
    },
    getAnimalResult: function ({ animal }) {
      this.animal = animal;

      this.cullAlertStatus =
        !!this.animal &&
        this.animal.doc &&
        this.animal.doc.derived &&
        this.animal.doc.derived.summaries &&
        this.animal.doc.derived.summaries.main.status;
    },
    getPreviousData: function () {
      const previousLockedState = JSON.parse(
        localStorage.getItem(this.orgUserScoping("form"))
      );
      if (previousLockedState) {
        const missingProp = this.$utils.checkObjectProps(
          this.lockedState,
          previousLockedState
        );
        if (!missingProp) {
          this.lockedState = previousLockedState;
        }
      }

      const previousFormFields = JSON.parse(
        localStorage.getItem(this.orgUserScoping("formValues"))
      );
      if (previousFormFields) {
        const missingProp = this.$utils.checkObjectProps(
          this.form.fields,
          previousFormFields
        );
        if (!missingProp) {
          this.form.fields = previousFormFields;
        }
      }

      const previousVisibilityState = JSON.parse(
        localStorage.getItem(this.orgUserScoping("visibility"))
      );
      if (previousVisibilityState) {
        const missingProp = this.$utils.checkObjectProps(
          this.visibilityState,
          previousVisibilityState
        );
        if (!missingProp) {
          this.visibilityState = previousVisibilityState;
        }
      }
    },
    getSummaryTableData: function () {
      return new Promise((resolve, reject) => {
        let activeBadge = 0;
        const today = this.$moment().startOf("day").toISOString();

        this.herdMeta.getDailyBullChecksAsync().then((results) => {
          const tableData = results
            .map((animal) => animal.value.summary)
            .map((row, idx) => {
              row.id = row.animalId;
              row.row = idx + 1;
              row.exportDate =
                row.testCheckTime &&
                row.testCheckTime.substr(0, row.testCheckTime.indexOf("(") - 1);

              if (today <= this.$moment(row.exportDate).toISOString()) {
                activeBadge++;
              }

              return row;
            });

          this.activeBadge = activeBadge;

          resolve(tableData || []);
        });
      });
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    resetAnimalInformation: function () {
      this.animalInformation = [
        { labelType: "Last Weight", value: "lastWeight" },
        { labelType: "sex", value: "sex" },
        { labelType: "color", value: "color" },
        { labelType: "comment", value: "comment" },
        { labelType: "status", value: "status" },
        { labelType: "headDays", value: "headDays" },
        { labelType: "totalEventIncome", value: "totalEventIncome" },
        { labelType: "group", value: "group" },
        { labelType: "pastureSlashPen", value: "location" },
        { labelType: "adgTotal", value: "adgTotal" },
        { labelType: "adg2", value: "adg2" },
        { labelType: "origin", value: "origin" },
        { labelType: "breed", value: "breed" },
        { labelType: "gender", value: "gender" },
      ];
    },
    resetFormAndAnimalInformation: function (programmatic) {
      if (programmatic) {
        if (this.form.fields.eid)
          EventBus.$tabEvent.emit("consumed", {
            type: "eid",
            value: this.form.fields.eid,
          });
        if (this.form.fields.tsu)
          EventBus.$tabEvent.emit("consumed", {
            type: "tsu",
            value: this.form.fields.tsu,
          });
        if (this.form.fields.visual)
          EventBus.$tabEvent.emit("consumed", {
            type: "visual",
            value: this.form.fields.visual,
          });
        if (this.form.fields.weight)
          EventBus.$tabEvent.emit("consumed", {
            type: "weight",
            value: this.form.fields.weight,
          });
      }

      const fieldsCleared = Object.fromEntries(
        Object.entries(
          this.$utils.filterObjectByValue(this.lockedState, false)
        ).map((pair) => {
          if (pair[0] == "date")
            return [
              pair[0],
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            ];
          return [pair[0], null];
        })
      );
      const formCleared = { ...this.form.fields, ...fieldsCleared };

      this.form.fields = formCleared || {
        bullTest: null,
        comment: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        doseGiven: null,
        eid: null,
        group: null,
        location: null,
        result: null,
        status: null,
        testMethod: null,
        treatment: null,
        tsu: null,
        vetTech: null,
        visual: null,
        weight: null,
      };

      this.herdConnect.resetFields = !this.herdConnect.resetFields;

      this.herdConnect.results.eid =
        this.herdConnect.results.tsu =
        this.herdConnect.results.visual =
        this.herdConnect.results.weight =
          null;

      this.resetAnimalInformation();
      this.animal = null;
    },
    setUpEventWatches: function () {
      EventBus.$tabEvent.on("consumed", ({ type, value }) => {
        if (type === "eid" && this.form.fields.eid === value)
          this.form.fields.eid = null;
        if (type === "tsu" && this.form.fields.tsu === value)
          this.form.fields.tsu = null;
        if (type === "visual" && this.form.fields.visual === value)
          this.form.fields.visual = null;
        if (type === "weight" && this.form.fields.weight === value)
          this.form.fields.weight = null;

        if (type === "eid" && this.herdConnect.results.eid === value)
          this.herdConnect.results.eid = null;
        if (type === "tsu" && this.herdConnect.results.tsu === value)
          this.herdConnect.results.tsu = null;
        if (type === "visual" && this.herdConnect.results.visual === value)
          this.herdConnect.results.visual = null;
        if (type === "weight" && this.herdConnect.results.weight === value)
          this.herdConnect.results.weight = null;
      });

      EventBus.$on(
        "eid-not-pre-existing",
        (eid) => (this.form.fields.eid = eid)
      );
      EventBus.$on(
        "eid-pre-existing",
        (eid) =>
          (this.form.fields.eid =
            this.form.fields.eid === eid ? "" : this.form.fields.eid)
      );
      EventBus.$on(
        "visual-not-pre-existing",
        (visual) => (this.form.fields.visual = visual)
      );
      EventBus.$on(
        "visual-pre-existing",
        (visual) =>
          (this.form.fields.visual =
            this.form.fields.visual === visual ? "" : this.form.fields.visual)
      );
    },
    storeFormValues: function () {
      const keysToStore = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, true)
      );
      const keysToOmit = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, false)
      );
      let formValues = {};
      keysToStore.forEach((key) => {
        formValues[key] = this.form.fields[key];
      });
      keysToOmit.forEach((key) => {
        if (key == "date")
          formValues[key] = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
        else formValues[key] = null;
      });

      localStorage.setItem(
        this.orgUserScoping("formValues"),
        JSON.stringify(formValues)
      );
    },
    streamEID: function (eid) {
      this.herdConnect.results.eid = eid;
    },
    streamTSU: function (tsu) {
      this.herdConnect.results.tsu = tsu;
    },
    streamVisual: function (visual) {
      this.herdConnect.results.visual = visual;
    },
    streamWeight: function (weight) {
      this.herdConnect.results.weight = weight;
    },
    submitBullTestForm: async function () {
      let animalIsNew = false;
      if (!this.animal) {
        this.animal = HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);
        animalIsNew = true;
      }
      await this.addDataAsync(this.animal, animalIsNew)
        .catch((e) => {
          this.$notify({
            group: "forms",
            text: e.message,
            title: "Error",
            type: "error",
          });

          console.error(e);
        })
        .then(async (animal) => {
          this.$notify({
            duration: 10000,
            group: "forms",
            text: [
              animalIsNew
                ? `Animal created (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`
                : `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
            ].join("<br />"),
            title: "Success",
            type: "success",
          });

          this.clearFields(true);
          this.summaryTable.data = await this.getSummaryTableData();
        });
    },
  },
  watch: {
    "form.fields": {
      deep: true,
      handler: "storeFormValues",
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect"),
          JSON.stringify(newVal)
        );

        if (newVal.eid) EventBus.$emit("eid-from-herd-connect", newVal.eid);
        if (newVal.tsu) this.form.fields.tsu = "" + newVal.tsu;
        if (newVal.visual)
          EventBus.$emit("visual-from-herd-connect", newVal.visual);
        if (+newVal.weight) this.form.fields.weight = "" + newVal.weight;
      },
    },
    lockedState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(this.orgUserScoping("form"), JSON.stringify(val));
        this.storeFormValues();
      },
    },
    visibilityState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          this.orgUserScoping("visibility"),
          JSON.stringify(val)
        );
      },
    },
  },
};
</script>
<style>
.v-data-table__wrapper {
  order: 2;
}
.v-data-footer {
  order: 1;
}
</style>