<template>
  <svg viewBox="0 0 20 20">
    <svg
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :fill="fill"
        d="M3.23535 4.23438C3.23535 3.6562 3.70405 3.1875 4.28223 3.1875H16.8447C17.4229 3.1875 17.8916 3.6562 17.8916 4.23438V6.32813C17.8916 6.9063 17.4229 7.375 16.8447 7.375H4.28223C3.70405 7.375 3.23535 6.9063 3.23535 6.32813V4.23438Z"
      />
      <path
        :fill="fill"
        d="M3.23535 10.5156C3.23535 9.93745 3.70405 9.46875 4.28223 9.46875H10.5635C11.1417 9.46875 11.6104 9.93745 11.6104 10.5156V16.7969C11.6104 17.375 11.1417 17.8438 10.5635 17.8438H4.28223C3.70405 17.8438 3.23535 17.375 3.23535 16.7969V10.5156Z"
      />
      <path
        :fill="fill"
        d="M14.751 9.46875C14.1728 9.46875 13.7041 9.93745 13.7041 10.5156V16.7969C13.7041 17.375 14.1728 17.8438 14.751 17.8438H16.8447C17.4229 17.8438 17.8916 17.375 17.8916 16.7969V10.5156C17.8916 9.93745 17.4229 9.46875 16.8447 9.46875H14.751Z"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        const dark =
          this.$parent.themeableProvide.isDark || this.$vuetify.theme.dark;
        return dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
