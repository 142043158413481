<template>
  <div>
    <!--
      Shortcut suite
      // Page navigations
      alt + a: animals
      alt + b: bull-test
      alt + c: comment
      alt + d: disposition
      alt + i: intake
      alt + g: animals-groups
      alt + h: animals-herds
      alt + l: animals-lists
      alt + m: comment
      alt + o: offspring
      alt + p: preg check
      alt + r: report-calf-birth-summary
      alt + s: closeout
      alt + u: organizations
      alt + w: wean

      // Non-page shortcuts
      alt + ctrl + d: open couchdb
      alt + t: toggle theme
    -->
    <span
      @shortkey="$router.push('/animals')"
      style="d-none"
      v-shortkey.once="['alt', 'a']"
    ></span>
    <span
      @shortkey="$router.push('/ai')"
      style="d-none"
      v-shortkey.once="['alt', 'ctrl', 'a']"
    ></span>
    <span
      @shortkey="$router.push('/bull-test')"
      style="d-none"
      v-shortkey.once="['alt', 'b']"
    ></span>
    <span
      @shortkey="$router.push('/comment')"
      style="d-none"
      v-shortkey.once="['alt', 'c']"
    ></span>
    <span
      @shortkey="$router.push('/cidr-implant')"
      style="d-none"
      v-shortkey.once="['alt', 'ctrl', 'c']"
    ></span>
    <span
      @shortkey="$router.push('/disposition-animals')"
      style="d-none"
      v-shortkey.once="['alt', 'd']"
    ></span>
    <span
      @shortkey="$router.push('/animals-lists')"
      style="d-none"
      v-shortkey.once="['alt', 'l']"
    ></span>
    <span
      @shortkey="$router.push('/intake')"
      style="d-none"
      v-shortkey.once="['alt', 'i']"
    ></span>
    <span
      @shortkey="$router.push('/animals-groups')"
      style="d-none"
      v-shortkey.once="['alt', 'g']"
    ></span>
    <span
      @shortkey="$router.push('/animals-herds')"
      style="d-none"
      v-shortkey.once="['alt', 'h']"
    ></span>
    <span
      @shortkey="$router.push('/offspring')"
      style="d-none"
      v-shortkey.once="['alt', 'o']"
    ></span>
    <span
      @shortkey="$router.push('/preg-check')"
      style="d-none"
      v-shortkey.once="['alt', 'p']"
    ></span>
    <span
      @shortkey="$router.push('/report-calf-birth-summary')"
      style="d-none"
      v-shortkey.once="['alt', 'r']"
    ></span>
    <span
      @shortkey="$router.push('/closeout')"
      style="d-none"
      v-shortkey.once="['alt', 's']"
    ></span>
    <span
      @shortkey="$router.push('/calf-wean')"
      style="d-none"
      v-shortkey.once="['alt', 'w']"
    ></span>
    <span
      @shortkey="$router.push('/offspring')"
      style="d-none"
      v-shortkey.once="['alt', 'o']"
    ></span>
    <span
      @shortkey="$router.push('/users')"
      style="d-none"
      v-shortkey.once="['alt', 'u']"
    ></span>

    <span
      @shortkey="openCouchDB()"
      style="d-none"
      v-shortkey.once="['alt', 'ctrl', 'd']"
    ></span>
    <span
      @shortkey="setDarkMode({ darkMode: !$vuetify.theme.dark })"
      style="d-none"
      v-shortkey.once="['alt', 't']"
    ></span>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "KeystrokeNavigation",
  methods: {
    ...mapActions({
      setDarkMode: "User/setDarkMode",
      setRowsCount: "Rows/setRowsCount",
    }),
    openCouchDB: function () {
      window.open(
        `/couchdb/_utils/#database/organization-${this.$organizationID}/_all_docs`,
        "_blank"
      );
    },
  },
};
</script>