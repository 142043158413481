<template>
  <div v-if="duplicatesInStock">
    <v-badge
      :content="potentialDuplicatesLength"
      bordered
      color="warning"
      left
      overlap
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="white--text text-decoration-none"
            color="warning"
            depressed
            min-width="40"
            target="_blank"
            to="/review-duplicate-animals"
            v-bind="attrs"
            v-on="on"
          >
            D
          </v-btn>
        </template>
        <span>
          {{ getLabelTranslation("animalDetails.someMightBeDuplicates") }}
        </span>
      </v-tooltip>
    </v-badge>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "CheckDuplicates",
  mixins: [TranslationMixin],
  data: () => ({
    potentialDuplicatesLength: 0,
  }),
  computed: {
    duplicatesInStock: function () {
      return this.potentialDuplicatesLength > 0;
    },
  },
  created: function () {
    console.info("Checking for duplicate animals");
    const _add = new AnimalDuplicationDetector(this.$herdMeta);
    _add.initCache();

    // Check for duplicate animals
    _add.getPotentialDuplicatePairsAsync().done((results) => {
      this.potentialDuplicatesLength = results.length;
    });
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>