var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-card',{staticClass:"card",scopedSlots:_vm._u([{key:"title-container",fn:function(){return [_c('v-row',{staticClass:"d-flex align-center mb-10 flex-initial"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","lg":"7"}},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"primary":!_vm.$vuetify.theme.dark,"large":""}},[_vm._v(_vm._s("$cow"))])],1),_c('div',{staticClass:"text-h5 font-weight-medium dashboard-card-title"},[_vm._v(" Animals ")])]),_c('v-col',{staticClass:"ml-auto d-flex align-center justify-end gap-x-4",attrs:{"cols":"12","lg":"5"}},[_c('div',{staticClass:"align-center d-md-flex gap-x-4 w-full"},[_c('span',{staticClass:"mr-2"},[_vm._v(" Filter By ")]),_c('v-select',{staticClass:"dashboard-select rounded-lg w-32",attrs:{"items":_vm.items,"return-object":true,"dense":"","elevation":"0","flat":"","hide-details":"true","item-text":"description","label":"","solo":""},model:{value:(_vm.criteria),callback:function ($$v) {_vm.criteria=$$v},expression:"criteria"}}),(_vm.criteria.countMissingBirthdates > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex warning justify-center lighten-4 rounded-circle",attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,false,1165518985)},[_c('span',[_vm._v(" Some animals are missing a birth date. ("+_vm._s(_vm.criteria.countMissingBirthdates)+") ")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 rounded-circle success",attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}])},[_c('span',[_vm._v("No errors/warning detected during computation.")])]),_c('a',{attrs:{"href":"/animals"}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-open-in-new")])],1)],1)])],1)]},proxy:true},{key:"content",fn:function(){return _vm._l((_vm.criteria.datasets),function(dataset,$index){return _c('div',{key:$index},[_c('div',{staticClass:"d-flex font-weight-bold text-md-h6"},[_c('span',[_vm._v(" "+_vm._s(dataset.name)+" "),(dataset.breakout)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){dataset.showDetails = !dataset.showDetails}}},[(dataset.showDetails)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),_c('span',{staticClass:"ml-auto"},[_vm._v(_vm._s(dataset.total))])]),(dataset.showDetails)?_c('breakout-bar',{key:$index,staticClass:"mb-4",attrs:{"dataset":dataset.breakout},scopedSlots:_vm._u([{key:"item-prepend",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v("|")])])]}}],null,true)}):_vm._e()],1)})},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }