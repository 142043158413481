<template>
  <div
    :style="{ width: '100%', height: `${height}px` }"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular indeterminate size="50" v-if="loading" />
    <div v-else-if="!google">Google Map not available</div>
    <div :ref="mapRefName" class="w-100 h-100" v-else></div>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "AnimalLocation",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    destination: {
      type: Object,
    },
    height: {
      default: 300,
      type: Number,
    },
    origin: {
      type: Object,
    },
  },
  data() {
    return {
      animal: null,
      google: null,
      herdMeta: null,
      loading: false,
      pouches: null,
    };
  },
  computed: {
    mapRefName: function () {
      return `googleMap${this.$utils.guid()}`;
    },
  },
  created: async function () {
    try {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      // Verify if animalID is not empty
      if (this.animalID.length > 0) {
        const doc = await this.pouches.organization.get(this.animalID);
        this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      }
      await this.initializeGoogleMapApi();
      // If origin and destination exist display route
      // else if only origin or only destination exist display geopoints
      // else if only animal exist display animal geopoints
      // else map not available
      if (!!this.origin && !!this.destination) {
        await this.displayRoute();
      } else {
        const customGeopoints = !!this.origin
          ? [this.origin]
          : !!this.destination
          ? [this.destination]
          : null;
        await this.displayGeopoints(customGeopoints);
      }
    } catch (error) {
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  methods: {
    initializeGoogleMapApi: async function () {
      this.loading = true;
      try {
        if (!window.google) {
          const googleMapApi = new Loader({
            apiKey: "AIzaSyDTFTTJGbg02sid2CsYocV11bSFvEY5hDg",
          });
          await googleMapApi.load();
        }
        if (!this.google) this.google = window.google;
      } catch (error) {
        console.log("Error initializing google maps api: ", error);
        this.google = null;
      } finally {
        this.loading = false;
      }
    },
    displayRoute: function () {
      try {
        const directionService = new this.google.maps.DirectionsService();
        const directionsRenderer = new this.google.maps.DirectionsRenderer();
        const mapContainer = this.$refs[`${this.mapRefName}`];
        const middlePoint = {
          lat: (+this.origin.lat + +this.destination.lat) / 2,
          lng: (+this.destination.lng + +this.destination.lng) / 2,
        };
        const mainMapOption = {
          center: {
            lat: middlePoint.lat,
            lng: middlePoint.lng,
          },
          zoom: 8,
          fullscreenControl: true,
        };
        const map = new this.google.maps.Map(mapContainer, mainMapOption);
        directionsRenderer.setMap(map);
        const request = {
          avoidFerries: true,
          avoidTolls: true,
          destination: this.destination,
          drivingOptions: {
            departureTime: new Date(Date.now()),
            trafficModel: "pessimistic",
          },
          origin: this.origin,
          travelMode: "DRIVING",
        };
        directionService.route(request, function (result, status) {
          console.log("Google Route status: ", status);
          if (status == "OK") {
            directionsRenderer.setDirections(result);
          }
        });
      } catch (error) {
        console.log("Google Route error", error);
      }
    },
    displayGeopoints: async function (customGeopoints) {
      let locations;
      // Get the animal geopoints
      // Sometimes animalID is empty but origin or destination exists
      // In those cases use customGeopoints(origin geopoints or destionation geopoints)
      // customGeopoints must be an array
      const animalLocations =
        customGeopoints || this.animal ? this.animal.getGeopoints() : [];
      // Use existing animal geopoints if exist
      if (animalLocations.length) locations = animalLocations;
      // Otherwise use the current user location
      else {
        locations = [await this.$utils.getGeoLocation()];
        locations[0].lat = locations[0].GPSLat;
        locations[0].lng = locations[0].GPSLong;
      }
      const mapContainer = this.$refs[`${this.mapRefName}`];
      const mainMapOption = {
        center: {
          lat: +locations[0].lat,
          lng: +locations[0].lng,
        },
        zoom: 9,
        fullscreenControl: true,
      };
      const map = new this.google.maps.Map(mapContainer, mainMapOption);
      const now = this.$moment();
      locations.forEach((location) => {
        let fillColor = "yellow"; // not yet used
        let hoursSince = "?";
        if (location.timeRecorded) {
          hoursSince = now.diff(moment(location.timeRecorded), "hours");
          let unit = "h";
          if (hoursSince <= 1) {
            fillColor = "green";
            now.diff(moment(location.timeRecorded), "minutes");
            unit = "min";
          } else if (hoursSince <= 24) {
            fillColor = "yellow";
            hoursSince = Utils.round(hoursSince, 0);
            unit = "hr";
          } else if (hoursSince < 24 * 30) {
            fillColor = "red";
            hoursSince = Utils.round(hoursSince / 24, 0);
            unit = "d";
          } else {
            fillColor = "red";
            hoursSince = Utils.round(hoursSince / (24 * 30), 0);
            unit = "mo.";
          }

          hoursSince += unit;
        }
        const icon = {
          origin: new google.maps.Point(0, -10),
          scaledSize: new google.maps.Size(100, 60),
          url: `http://maps.google.com/mapfiles/ms/icons/${fillColor}.png`,
        };
        new this.google.maps.Marker({
          icon,
          label: { text: hoursSince, color: "black" },
          map,
          position: location,
        });
      });
    },
  },
};
</script>
