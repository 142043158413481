<template>
  <div>
    <in-page-menu />
    <div class="g-row pt-20 px-4">
      <div class="g-col">
        <div class="g-row mb-4">
          <div
            @dragleave.prevent
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleDrop"
            class="dropzone d-flex flex-wrap justify-center"
            draggable="true"
            id="dropzone"
          >
            <div class="dropzone-text mb-2">
              {{ getTranslation("fileETL.dropFile") }}
            </div>
            <input
              @change="getSelectedFile"
              accept=".csv, .xls, .xlsx"
              class="file-input"
              id="fileInput"
              ref="fileInput"
              type="file"
            />
            <v-btn @click="$refs.fileInput.click()" color="info" small>
              {{ getTranslation("fileETL.useFile") }}
            </v-btn>
            <p class="mb-0 mt-2" v-if="selectedFile && selectedFile.name">
              {{ selectedFile.name }}
            </p>
          </div>
        </div>
        <div class="g-row">
          <!-- Controls -->
          <div class="g-row">
            <!-- Toggle column -->
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="controls.toggleColumn.show"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :disabled="!toggleCategories.length || loading"
                  class="mr-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  Toggle Column
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list class="overflow-y-auto" style="max-height: 400px" dense>
                <v-list-item
                  :key="idx"
                  style="max-height: 40px"
                  v-for="(category, idx) in toggleCategories"
                >
                  <v-checkbox
                    :label="category.label"
                    @change="toggleColumn(category)"
                    class="custom-field"
                    dense
                    hide-details
                    v-model="category.isVisible"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- Delete -->
            <v-btn
              :disabled="!rowsSelected.length || loading"
              @click="dialogs.deleteRows.show = true"
              class="py-0 mr-3"
              color="red darken-3"
            >
              {{ getTranslation("delete") }}
              <span class="v-btn__content" v-if="rowsSelected.length > 0">
                ({{ rowsSelected.length }})
              </span>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <!-- Import -->
            <v-btn
              :disabled="invalidImport"
              @click="importCarcassData"
              class="py-0"
            >
              {{ getTranslation("import") }}
              <span class="v-btn__content" v-if="validData.length > 0">
                ({{ validData.length }})
              </span>
            </v-btn>
            <!-- InfoTip to guide user why a number is next to Import (N) -->
            <v-tooltip
              right
              v-if="table.data.length > 0"
              v-model="infoTips.import.show"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="infoTips.import.show = !infoTips.import.show"
                  color="info"
                  icon
                >
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span class="warning-message">
                <span v-if="!groupNumberValidated">
                  Group Number is required.
                </span>
                <span v-else-if="duplicatedCategories.length > 0">
                  Duplicated categories. Table must have only 1 column per
                  category.
                </span>
                <span v-else-if="idIsMissing">
                  CarcID or EID are required.
                </span>
                <span v-else>
                  {{ validData.length }} rows are validated correctly.
                </span>
              </span>
            </v-tooltip>
          </div>
          <!-- Extra Fields -->
          <div class="g-row">
            <v-text-field
              :disabled="!table.data.length"
              :error="
                !extraFields.groupNumber &&
                invalidDataCounter.groupNumber.counter == table.data.length
              "
              :label="getTranslation('Group Number')"
              class="custom-field"
              dense
              hide-details
              outlined
              style="max-width: 150px"
              v-model="extraFields.groupNumber"
            />
            <v-tooltip
              bottom
              v-if="table.data.length > 0"
              v-model="infoTips.groupNumber.show"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="
                    infoTips.groupNumber.show = !infoTips.groupNumber.show
                  "
                  color="info"
                  icon
                >
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span class="warning-message" style="max-width: 200px">
                This group number will replace all the group number column
              </span>
            </v-tooltip>
          </div>
          <v-data-table
            :headers="table.headers"
            :items="table.data"
            :loading="loading"
            class="w-100 dashboard-data-table sticky-header custom-padding"
            height="560"
            mobile-breakpoint="0"
            ref="importerTable"
          >
            <template #progress>
              <v-progress-linear
                color="primary"
                height="20"
                v-model="table.progressIndicator"
              >
                <template #default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </template>
            <template #header="{ props }" v-if="table.data.length > 0">
              <tr ref="categories">
                <th :key="idx" v-for="(h, idx) in props.headers">
                  <div
                    class="d-flex justify-center align-center my-2"
                    style="height: 36px"
                    v-if="showTableTopIndicators"
                  >
                    <!-- Checkbox for change all ability -->
                    <!-- Some columns needs this checkbox to allow change all rows -->
                    <v-checkbox
                      class="custom-field"
                      dense
                      hide-details
                      label="All"
                      v-if="h.changeAllAbility.checkboxEnabled"
                      v-model="h.changeAllAbility.active"
                    />
                    <!-- Column warnings -->
                    <!-- Tooltip -->
                    <v-tooltip
                      top
                      v-model="h.warning.show"
                      v-if="
                        invalidDataCounter[h.category] &&
                        invalidDataCounter[h.category].counter > 0
                      "
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          @click="h.warning.show = !h.warning.show"
                          color="error"
                          icon
                        >
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="warning-message">
                        {{ invalidDataCounter[h.category].counter }} errors.
                        {{ invalidDataCounter[h.category].warning }}
                        {{ idIsMissing ? "Carc ID or EID are required." : "" }}
                      </span>
                    </v-tooltip>
                  </div>
                  <v-select
                    :disabled="loading"
                    :items="categories"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    @input="updateColumnConfig(idx)"
                    class="custom-field"
                    dense
                    hide-details
                    item-text="label"
                    outlined
                    style="min-width: 130px"
                    v-if="h.value !== 'row'"
                    v-model="h.category"
                  />
                </th>
              </tr>
            </template>
            <template #item="{ item, index: rowIdx, headers }">
              <tr>
                <td :key="colIdx" v-for="(head, colIdx) in headers">
                  <!-- Only the first column should have the checkbox option to select the row -->
                  <v-checkbox
                    class="custom-field"
                    dense
                    hide-details
                    v-if="colIdx === 0"
                    v-model="item.isSelected"
                  />
                  <!-- Segregate EID and CARCID for a better control -->
                  <!-- EID field -->
                  <div v-else-if="head.category === 'eid'">
                    <v-text-field
                      :error="!head.isValid(item[head.value])"
                      class="custom-field"
                      dense
                      hide-details
                      outlined
                      v-model="item[head.value]"
                    />
                  </div>
                  <!-- CarcID -->
                  <div v-else-if="head.category === 'carcId'">
                    <v-text-field
                      :error="!head.isValid(item[head.value])"
                      class="custom-field"
                      dense
                      hide-details
                      outlined
                      v-model="item[head.value]"
                    />
                  </div>
                  <!-- Field type can be text, calendar, select -->
                  <!-- Text -->
                  <div v-else>
                    <v-text-field
                      :error="!head.isValid(item[head.value])"
                      class="custom-field"
                      dense
                      hide-details
                      outlined
                      v-if="head.field.type == 'text'"
                      v-model="item[head.value]"
                    />
                    <!-- Calendar -->
                    <v-menu
                      :close-on-content-click="false"
                      close-on-click
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-else-if="head.field.type == 'calendar'"
                      v-model="head.field.cell[rowIdx][colIdx].show"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :error="!head.isValid(item[head.value])"
                          @click:append="
                            head.field.cell[rowIdx][colIdx].show = true
                          "
                          append-icon="mdi-calendar"
                          class="custom-field"
                          dense
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          v-model="item[head.value]"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        @input="
                          updateDateByColumn(
                            head.field.cell[rowIdx][colIdx],
                            head.value,
                            item[head.value]
                          )
                        "
                        v-model="item[head.value]"
                      />
                    </v-menu>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!-- Dialogs -->
    <!-- Confirm delete rows -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="dialogs.deleteRows.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Delete</h4>
          <v-icon class="buttons" @click="dialogs.deleteRows.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          Are you sure you want to delete
          {{ rowsSelected.length }} rows?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :loading="dialogs.deleteRows.loading"
            @click="deleteRows"
            color="error"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <success-dialog v-model="showSuccessDialog" />
    <error-dialog v-model="showErrorDialog" />
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "FileImportBeta",
  metaInfo: {
    title: "File Import Beta",
  },
  mixins: [TranslationMixin],
  data: () => ({
    changeAllAbilityHeaders: ["killDate"],
    controls: {
      toggleColumn: { show: false },
    },
    dialogs: {
      deleteRows: {
        loading: false,
        show: false,
      },
    },
    extraFields: {
      groupNumber: null,
    },
    herdMeta: null,
    loading: false,
    infoTips: {
      groupNumber: {
        show: false,
      },
      import: {
        show: false,
      },
    },
    selectedFile: null,
    specialFields: {
      killDate: false,
    },
    showErrorDialog: false,
    showSuccessDialog: false,
    table: {
      data: [],
      headers: [],
      progressIndicator: 0,
    },
  }),
  computed: {
    categories: function () {
      return [
        { label: this.getTranslation("EID"), value: "eid" },
        {
          label: this.getTranslation("Group Number"),
          value: "groupNumber",
        },
        { label: this.getTranslation("Kill Date"), value: "killDate" },
        { label: this.getTranslation("Kill Lot"), value: "killLot" },
        {
          label: this.getTranslation("Hot Scale Weight"),
          value: "hotScaleWeight",
        },
        { label: this.getTranslation("Plant"), value: "plant" },
        { label: this.getTranslation("Carc ID"), value: "carcId" },
        { label: this.getTranslation("Carcass Age"), value: "carcassAge" },
        { label: this.getTranslation("Carcass Type"), value: "carcassType" },
        { label: this.getTranslation("Quality Grade"), value: "qualityGrade" },
        {
          label: this.getTranslation("Quality Grade 2"),
          value: "qualityGrade2",
        },
        {
          label: "OD",
          value: "od",
        },
        {
          label: "OD2",
          value: "od2",
        },
        {
          label: this.getTranslation("Yield Grade"),
          value: "yieldGrade",
        },
        { label: this.getTranslation("Unknown"), value: "unknown" },
      ];
    },
    idIsMissing: function () {
      const eidValue = this.getHeaderValueBasedOnCategory("eid");
      const carcIdValue = this.getHeaderValueBasedOnCategory("carcId");
      return this.table.data.some((row) => !row[eidValue] && !row[carcIdValue]);
    },
    showTableTopIndicators: function () {
      return (
        this.invalidDataExists ||
        this.table.headers.some(
          ({ changeAllAbility }) => !!changeAllAbility.checkboxEnabled
        )
      );
    },
    duplicatedCategories: function () {
      return [
        ...new Set(
          this.table.headers
            .filter(({ category }) => {
              // how many items per category exists
              const itemsLength = this.table.headers.filter(
                (item) => item.category == category
              ).length;
              // return if category is duplicated
              if (category !== "unknown" && itemsLength > 1) return category;
            })
            .map(({ category }) => category)
        ),
      ];
    },
    invalidImport: function () {
      return (
        !this.validData.length ||
        this.loading ||
        !this.groupNumberValidated ||
        this.duplicatedCategories.length > 0 ||
        this.idIsMissing
      );
    },
    groupNumberValidated: function () {
      return (
        this.toggleCategories.find(({ value }) => value === "groupNumber")
          .isVisible || !!this.extraFields.groupNumber
      );
    },
    toggleCategories: function () {
      if (!this.table.headers.length) return [];
      const currentCategories = this.table.headers.map((h) => h.category);
      return this.categories
        .filter(({ value }) => value !== "unknown")
        .map((category) => ({
          ...category,
          isVisible: currentCategories.includes(category.value),
        }));
    },
    invalidDataExists: function () {
      return (
        Object.entries(this.invalidDataCounter).filter(
          (item) => item[1].counter > 0
        ).length > 0
      );
    },
    rowsSelected: function () {
      const items = (this.table && this.table.data) || [];
      return items.filter((item) => !!item.isSelected);
    },
    invalidDataCounter: function () {
      // Table data is being updated correctly, the problem
      // was the invalidDataCounter was not reacting correctly
      const counter = this.getWarningStructure();
      this.table.data.forEach((row) => {
        this.table.headers.forEach((header) => {
          if (!header.isValid(row[header.value]))
            counter[header.category].counter++;
        });
      });
      return counter;
    },
    validData: function () {
      // Evaluate each row[column] is valid and if
      // global group number exists and column == groupNumber
      // return true
      return this.table.data.filter(
        (item, rowIdx) =>
          this.table.headers.filter((header) => {
            if (
              header.category === "groupNumber" &&
              !!this.extraFields.groupNumber
            )
              return true;
            return ["eid", "carcId"].includes(header.value)
              ? !this.getIdRule(header.category, rowIdx)
              : header.isValid(item[header.value]);
          }).length == this.table.headers.length
      );
    },
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
  },
  mounted: function () {
    window.addEventListener("resize", () => {
      this.resizeTable();
    });
    this.$nextTick(() => {
      window.removeEventListener("resize", () => {
        console.info("Resize event deleted");
      });
    });

    this.resizeTable();
  },
  methods: {
    getIdRule: function (headCategory, rowIdx) {
      const row = this.table.data[rowIdx];
      return headCategory == "eid"
        ? Boolean(
            (!row.eid && !row.carcId) || (!row.carcId && row.eid.length != 15)
          )
        : Boolean(
            (!row.eid && !row.carcId) || (!row.eid && !row.carcId.length)
          );
    },
    getWarningStructure: function () {
      const categories = this.categories.filter(
        ({ value }) => value !== "unknown"
      );
      return categories.reduce((prev, current) => {
        const category = current.value;
        const warningMessage =
          category == "eid"
            ? "EID must contain 15 digits."
            : category == "killDate"
            ? "Kill Date should match date format (YYYY-MM-DD)."
            : category == "hotScaleWeight"
            ? "Hot Scale Weight should be a positive number."
            : category == "groupNumber"
            ? "Group Number can not be empty."
            : category == "qualityGrade"
            ? "Quality Grade should be a positive number."
            : category == "od"
            ? "OD should be a positive number."
            : category == "yieldGrade"
            ? "Yield Grade should be a positive number."
            : "";
        if (category !== "unknown")
          prev[category] = {
            counter: 0,
            warning: warningMessage,
          };
        return prev;
      }, {});
    },
    updateDateByColumn: function (toggleDate, headerValue, newDate) {
      // This is only to close the calendar afterwards select it
      toggleDate.show = false;
      // If 'all' feature is enabled for the regarding header
      const header = this.table.headers.find(
        ({ value }) => value === headerValue
      );
      // Iterate over table.data to update each row[column] with the new Date
      if (!!header.changeAllAbility.active) {
        this.table.data = this.table.data.map((row) => {
          row[headerValue] = newDate;
          return row;
        });
      }
    },
    toggleColumn: function (category) {
      const object = this.$utils.copyObject(this.table.data[0]);
      if (category.isVisible) {
        if (!Object.keys(object).includes(category.label)) {
          object[category.label] = null;
          const data = this.table.data.map((item) => ({
            ...item,
            [category.label]: null,
          }));
          // This is neccessary to ensure vue reactivity for new columns
          this.$set(this.table, "data", data);
        }
        this.table.headers = this.getTableHeaders([object], category.label);
      } else {
        const colIdx = this.table.headers.findIndex(
          ({ text }) => text == category.label
        );
        this.table.headers.splice(colIdx, 1);
      }

      this.controls.toggleColumn.show = false;
    },
    deleteRows: function () {
      this.dialogs.deleteRows.loading = true;
      this.table.data = this.table.data.filter((item) => !item.isSelected);
      // Update column configs
      this.table.headers.forEach((_, idx) => this.updateColumnConfig(idx));
      // Refresh dialog config
      this.dialogs.deleteRows = this.$options.data().dialogs.deleteRows;
    },
    getCategoryBasedOnHeaderValue: function (headerValue) {
      const header = this.table.headers.find(
        ({ value }) => value === headerValue
      );
      return header ? header.category : "unknown";
    },
    getHeaderValueBasedOnCategory: function (category) {
      if (category === "unknown") return "";
      const header = this.table.headers.find(
        ({ category: headerCategory }) => category === headerCategory
      );
      return header ? header.value : "";
    },
    removeEmptySpacesFromData: function () {
      // All props include not recognized props except isSelected
      const allProps = Object.keys(this.table.data[0]).filter(
        (prop) => prop !== "isSelected"
      );
      // Create a copy of the data to avoid direct mutation
      const updatedData = this.table.data.map((item) => {
        const updatedItem = { ...item }; // Create a copy of the item

        // Sometimes the file brings data as an empty space
        // This helps to remove that in order to facilitate rules validation
        allProps.forEach((prop) => {
          updatedItem[prop] = String(updatedItem[prop]).trim();
          if (
            updatedItem[prop].length === 0 ||
            updatedItem[prop] === "undefined" ||
            updatedItem[prop] === "null"
          )
            updatedItem[prop] = "";
        });
        return updatedItem;
      });

      // Assign the updated data to this.table.data
      this.table.data = updatedData;
    },
    deleteUnknownFieldsFromValidData: function () {
      const fieldsToIgnore = this.table.headers.filter(
        ({ category }) => category === "unknown"
      );
      const data = this.$utils.copyObject(this.validData);
      const rowProps = Object.keys(data[0]);
      data.forEach((row) => {
        fieldsToIgnore.forEach(({ value }) => {
          if (rowProps.includes(value)) delete row[value];
        });
      });
      return data;
    },
    replaceCustomFieldsByCategoriesAndReturnValidData: function (data) {
      // These fields have been retrieved from the custom file
      // In this stage, data is not using the categories
      const currentFields = Object.keys(data[0]);

      // Create an object to know the category that belongs to each prop
      const fieldToCategoryObject = currentFields.reduce(
        (prev, currentField, idx) => {
          const category = this.getCategoryBasedOnHeaderValue(currentField);
          if (category !== "unknown") prev[currentField] = category;
          return prev;
        },
        {}
      );
      // Iterate each row of data and update props based on categories
      // to store data correctly
      data.map((row) => {
        for (const field in fieldToCategoryObject) {
          const category = fieldToCategoryObject[field];
          row[category] = row[field];
          delete row[field];
        }
      });
      return data;
    },
    importCarcassData: async function () {
      this.loading = true;
      this.table.progressIndicator = 0;

      // We need to ignore the unknown fields and delete them from validData
      const data = this.replaceCustomFieldsByCategoriesAndReturnValidData(
        this.deleteUnknownFieldsFromValidData()
      );
      const globalGroupNumber = this.extraFields.groupNumber;
      const progressFactor = Math.max(1, Math.ceil(100 / data.length));

      for (let idx = 0; idx < data.length; idx++) {
        const row = data[idx];
        const animal = await this.getAnimal(row, idx === 0);
        const timeRecorded = new Date().toISOString();

        try {
          // If animal has an eid store it as an eid tag
          // otherwise use plant + killLot + carcId
          // EID or carcID are required

          if (row.eid) {
            const tag = {
              status: "active",
              tagColor: null,
              tagId: null,
              tagValue: row.eid,
              timeRecorded,
              type: "eid",
              userId: this.$userID,
            };
            await animal.insertIDforAnimal(
              tag,
              animal.animalIsNew,
              false,
              true
            );
          } else {
            // Store visual if row.eid does not exist
            // Posible visuals are:
            // - if(plant && killLot) => plant-killLot-carcID
            // - else if(plant) => plant-carcID
            // - else if(killLot) => killLot-carcID
            // - else => carcID
            let visual;
            if (!!row.plant && !!row.killLot)
              visual = `${row.plant}-${row.killLot}-${row.carcId}`;
            else if (!!row.plant) visual = `${row.plant}-${row.carcId}`;
            else if (!!row.killLot) visual = `${row.killLot}-${row.carcId}`;
            else visual = row.carcId;
            // This is to visualize the generated visuals because we can not see
            // them in the table
            console.log("Visual stored as: ", visual);
            const tag = {
              status: "active",
              tagColor: null,
              tagId: null,
              tagValue: visual,
              timeRecorded,
              type: "visual",
              userId: this.$userID,
            };
            await animal.insertIDforAnimal(
              tag,
              animal.animalIsNew,
              false,
              true
            );
          }

          await animal.modify(
            "carcassData",
            null,
            "groupNumber",
            globalGroupNumber || row.groupNumber,
            false,
            true,
            {
              carcassAge: row.carcassAge,
              carcassType: row.carcassType,
              carcId: row.carcId,
              eid: row.eid,
              groupNumber: globalGroupNumber || row.groupNumber,
              hotScaleWeight: row.hotScaleWeight,
              killDate: row.killDate,
              killLot: row.killLot,
              od: row.od,
              od2: row.od2,
              plant: row.plant,
              qualityGrade: row.qualityGrade,
              qualityGrade2: row.qualityGrade2,
              timeRecorded,
              userId: this.$userID,
              yieldGrade: row.yieldGrade,
            }
          );

          // Always save group number
          await animal.modify(
            "movements",
            null,
            "locationId",
            null,
            false,
            true,
            {
              groupNumber: globalGroupNumber || row.groupNumber,
              timeRecorded,
              userId: this.$userID,
            }
          );

          // Always change status to harvested
          await animal.modify(
            "statuses",
            null,
            "status",
            "harvested",
            false,
            true,
            {
              timeRecorded,
              userId: this.$userID,
            }
          );
          await animal.save();
          this.table.progressIndicator += progressFactor;
          if (idx === data.length - 1) {
            this.showSuccessDialog = true;
          }
        } catch (error) {
          this.showErrorDialog = true;
          console.log("*************ERROR*************", error);
          break; // Finish the loop in case of error
        } finally {
          this.table.progressIndicator = 100;
          setTimeout(() => {
            this.loading = false;
          }, 400);
        }
      }
    },
    getAnimal: async function (row, updateAnimals) {
      // To search an animal first use row.eid
      // If row.eid does not exist use posible visuals:
      // - plant-killLot-carcID
      // - plant-carcID
      // - killLot-carcID
      // - carcID
      const visual = !!row.carcId
        ? !!row.plant && !!row.killLot
          ? `${row.plant}-${row.killLot}-${row.carcId}`
          : !!row.plant
          ? `${row.plant}-${row.carcId}`
          : !!row.killLot
          ? `${row.killLot}-${row.carcId}`
          : row.carcId
        : null;
      const match =
        await this.herdMeta.getFirstAnimalWithActiveTagAndWhetherIsUniqueV2(
          row.eid,
          visual,
          false,
          updateAnimals
        );
      if (!match.animal)
        return HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);
      return match.animal;
    },
    resizeTable: function () {
      // Set table width to avoid oversize for larger tables
      if (this.$refs.importerTable && this.$refs.importerTable.$el) {
        const tableWrapper = this.$refs.importerTable.$el.childNodes[0];
        const reduceNumber = ["sm", "xs"].includes(
          this.$vuetify.breakpoint.name
        )
          ? 40
          : 100;
        tableWrapper.style.maxWidth = this.$utils.width() - reduceNumber + "px";
        tableWrapper.style.overflow = "auto";
      }
    },
    getSelectedFile: function (event) {
      const file = event.target.files[0];
      if (file) {
        const extension = file.name.split(".").pop().toLowerCase();
        if (["csv", "xls", "xlsx"].includes(extension)) {
          this.selectedFile = file;
          this.processFile(file, extension);
        } else {
          alert("Por favor, seleccione un archivo CSV, XLS o XLSX válido.");
          this.$refs.fileInput.value = ""; // Limpiar la selección no válida
          this.selectedFile = null;
        }
      } else {
        this.selectedFile = null;
      }
    },
    handleDragOver: function (event) {
      event.dataTransfer.dropEffect = "copy"; // Cambiar el cursor del mouse para indicar que se puede soltar
    },
    handleDrop: function (event) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        const extension = file.name.split(".").pop().toLowerCase();
        if (["csv", "xls", "xlsx"].includes(extension)) {
          this.selectedFile = file;
          this.processFile(file, extension);
        } else {
          alert("Por favor, seleccione un archivo CSV, XLS o XLSX válido.");
          this.selectedFile = null;
        }
      }
    },
    processFile: function (file, extension) {
      const reader = new FileReader();
      reader.addEventListener("loadend", (event) => {
        if (event.target.DONE) {
          // DataView just parses the binary buffer to be able
          // to read for a decoder. The buffer can be for any type
          // uint8, int8, etc but DataView parses any type in dataView type
          // so you don't worry about which is the type of the array buffer
          const dataView = new DataView(event.target.result);
          if (extension.includes("csv")) {
            // TextDecoder takes a binary buffer just like DataView and decode
            // them in a string format
            const decoder = new TextDecoder("utf-8");
            const dataText = decoder.decode(dataView);
            this.table.data = this.convertCSVToJson(dataText);
            this.table.headers = this.getTableHeaders(this.table.data);
          }
          if (["xls", "xlsx"].includes(extension)) {
            this.table.data = this.convertExcelToJson(event.target.result);
            this.table.headers = this.getTableHeaders(this.table.data);
          }
          this.removeEmptySpacesFromData();
        }

        reader.removeEventListener("loadend", () => {});
      });
      reader.readAsArrayBuffer(file);
    },
    convertCSVToJson: function (dataText) {
      const jsonData = [];
      const lines = dataText.split("\n");
      const keys = lines
        .shift()
        .split(",")
        .filter((header) => String(header).trim().length > 0);
      // Always include Group Number
      if (!headers.some((h) => ["group", "number"].includes(h.toLowerCase())))
        headers.push("Group Number");
      lines.forEach((line) => {
        const values = line.split(",");
        const obj = {};
        const rowIsEmpty =
          values.filter((val) => String(val).trim().length == 0).length ==
          values.length;
        if (!rowIsEmpty) {
          keys.forEach((key, idx) => {
            obj.isSelected = false;
            obj[key] = String(values[idx]);
          });
          jsonData.push(obj);
        }
      });
      return jsonData;
    },
    convertExcelToJson: function (arrayBufferData) {
      // Reads array buffer
      const workbook = XLSX.read(arrayBufferData, { type: "array" });

      // Get first excel document
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // This generates an array of arrays where each array represents
      // each row
      const arrayOfRows = XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        defval: "",
        header: 1,
      });

      // Process array of arrays and get a final json data just like csv
      // Get Headers
      const jsonData = [];
      const headers = arrayOfRows
        .shift()
        .filter((header) => String(header).trim().length > 0);
      // Always include Group Number
      if (!headers.some((h) => ["group", "number"].includes(h.toLowerCase())))
        headers.push("Group Number");
      arrayOfRows.forEach((row) => {
        const obj = {};
        headers.forEach((key, idx) => {
          obj.isSelected = false;
          if (key.toLowerCase().includes("date"))
            obj[key] = this.convertExcelDateToHumanDate(row[idx]);
          else obj[key] = String(row[idx]);
        });
        jsonData.push(obj);
      });

      return jsonData;
    },
    convertExcelDateToHumanDate: function (excelDate) {
      const humanDate = this.$moment("1900-01-01").add(excelDate - 2, "days");
      return humanDate.format("YYYY-MM-DD");
    },
    getTableHeaders: function (jsonArray, firstPosition) {
      let props = Object.keys(jsonArray[0] || []);
      if (firstPosition) {
        props = props.filter((p) => p != firstPosition);
        props.splice(1, 0, firstPosition);
      }
      // This is used to count how many instances of a category exists
      let categoriesDetected = [];
      const headers = props.map((header) => {
        // Find possible category field
        header = header !== "isSelected" ? header : "row";
        let category = this.detectCategory(header);
        // If category count is more than 1 then use
        // the header as a category in order to not lose
        // the data
        if ("unknown" !== category) {
          categoriesDetected.push(category);
        }
        const counter = categoriesDetected.filter(
          (item) => item === category
        ).length;
        if (counter > 1) {
          category = header;
        }
        // isValid function validates field value
        const isValid = this.getRulesDependingOfCategory(category);
        // when counter is more than 1 categories are duplicated
        category = counter > 1 ? "unknown" : category;
        return {
          category,
          changeAllAbility: {
            active: false,
            checkboxEnabled: this.changeAllAbilityHeaders.includes(category),
          },
          // then use unknown instead as a category, because only 1 instance per category is valid
          field: this.getFieldTypeDependingOfCategory(category, props),
          isValid,
          sortable: header !== "row",
          text: header,
          value: header,
          warning: {
            show: false,
          },
          width: category === "eid" && 180,
        };
      });
      return headers;
    },
    updateColumnConfig: function (columnIdx) {
      // Get selected header = column
      const header = this.table.headers[columnIdx];
      // Update field and isValid function depending of the new category
      header.field = this.getFieldTypeDependingOfCategory(header.category);
      header.isValid = this.getRulesDependingOfCategory(header.category);
      // Update change all ability regarding of the category
      header.changeAllAbility = {
        active: false,
        checkboxEnabled: !!this.changeAllAbilityHeaders.includes(
          header.category
        ),
      };
      // Update width if category is eid
      header.width = header.category === "eid" && 180;
    },
    detectCategory: function (header) {
      // If header is 'row' any category is valid
      if (header === "isSelected") return "isSelected";
      header = header.toLowerCase().trim();
      /* Detect category based on their common names */
      // Carcass Age
      const carcassAgeNames = ["ct"];
      if (carcassAgeNames.includes(header))
        return this.categories.find(({ value }) => value === "carcassAge")
          .value;
      // Carc Type
      const carcTypeNames = ["carc typ", "carcass type"];
      if (carcTypeNames.includes(header))
        return this.categories.find(({ value }) => value === "carcassType")
          .value;
      // Quality Grade
      const qualityGradeNames = ["quality grade", "qg"];
      if (qualityGradeNames.includes(header))
        return this.categories.find(({ value }) => value === "qualityGrade")
          .value;
      // Quality Grade 2
      const qualityGrade2Names = ["quality grade2", "qg2"];
      if (qualityGrade2Names.includes(header))
        return this.categories.find(({ value }) => value === "qualityGrade2")
          .value;
      // OD
      const odNames = ["od"];
      if (odNames.includes(header))
        return this.categories.find(({ value }) => value === "od").value;
      // OD2
      const od2Names = ["od2"];
      if (od2Names.includes(header))
        return this.categories.find(({ value }) => value === "od2").value;
      // Yield Grade
      const yieldGradeNames = ["yield grade", "yg"];
      if (yieldGradeNames.includes(header))
        return this.categories.find(({ value }) => value === "yieldGrade")
          .value;
      // Group Number
      const groupNumberNames = ["groupnumber", "group number"];
      if (groupNumberNames.includes(header))
        return this.categories.find(({ value }) => value === "groupNumber")
          .value;
      /* Otherwise, detect category based on its partial name */
      const partialHeader = header.split(" ").slice(0, 2).join(" ");
      let possibleCategory = this.categories.find(({ label }) => {
        label = label.toLowerCase().trim();
        return label.includes(header) || label.includes(partialHeader);
      });
      if (!possibleCategory) return "unknown";
      return possibleCategory.value;
    },
    getRulesDependingOfCategory: function (category) {
      switch (category) {
        case "eid":
          return (value) =>
            !value || (!!value && !isNaN(value) && String(value).length === 15);
        case "carcId":
          return (value) => !value || (!!value && String(value).length > 0);
        case "killDate":
          return (value) =>
            !!value &&
            value.match(/^\d{4}-\d{2}-\d{2}$/) &&
            value.match(/^\d{4}-\d{2}-\d{2}$/).length > 0;
        case "hotScaleWeight":
        case "qualityGrade":
        case "od":
        case "yieldGrade":
          return (value) => !value || (!!value && !isNaN(value) && value > 0);
        case "groupNumber":
          return (value) =>
            !!value &&
            String(value).length &&
            String(value) != "undefined" &&
            String(value).length > 0;
        default:
          return (value) => true;
      }
    },
    getFieldTypeDependingOfCategory: function (category, headers = []) {
      switch (category) {
        case "killDate":
          return {
            type: "calendar",
            cell: this.table.data.map((_) =>
              (this.table.headers.length > 0
                ? this.table.headers
                : headers
              ).map((_) => ({
                show: false,
              }))
            ),
          };
        case "isSelected":
          return { type: null };
        default:
          return { type: "text" };
      }
    },
  },
  watch: {
    "table.data": function (data) {
      if (data.length > 0) {
        // Place table Header before the vuetify header
        const tableHeader =
          this.$refs.importerTable.$el.childNodes[0].childNodes[0]
            .childNodes[1];
        this.$nextTick(() => {
          const categories = this.$refs.categories;
          tableHeader.prepend(categories);
        });
      }
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 12px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 12px;
}
.dropzone {
  border: 2px dashed #3498db;
  border-radius: 5px;
  background-color: #ffffff;
  color: #3498db;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: 230px;
}

.dropzone:hover {
  background-color: #f2f2f2;
}

.dropzone-text {
  font-size: 16px;
}

.file-input {
  display: none;
}

.warning-message {
  max-width: 150px;
  display: inline-block;
  word-wrap: break-word;
}
</style>
