<template>
  <div>
    <summary-container
      :headerTitle="getTranslation('pregCheckPage.cowInformation.name')"
      :isCull="getStatus.toLowerCase() == 'cull'"
      onlyCenterHeader
    >
      <template #bottom-header>
        <v-row class="ma-0 justify-center">
          <v-btn color="primary" disabled text v-if="!getGuid">
            <span style="font-size: 1.8rem" target="_blank">
              {{ getTagValues }}
            </span>
          </v-btn>
          <a v-else target="_blank" :href="`/animal-details?id=${getGuid}`">
            <p class="custom-main-tag pa-2 mb-0">
              {{ getTagValues }}
            </p>
          </a>
        </v-row>
      </template>
      <template #content>
        <v-form>
          <v-row>
            <v-col lg="6" cols="12" class="pb-0">
              <v-text-field
                :value="getInformation.herd"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("pregCheckPage.cowInformation.herd") }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="getInformation.age"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("pregCheckPage.cowInformation.age") }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="getInformation.breed"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("pregCheckPage.cowInformation.breed") }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="getInformation.comment"
                class="content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("pregCheckPage.cowInformation.comment") }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
              <v-text-field
                :value="getInformation.birthRanch"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{
                      getTranslation("pregCheckPage.cowInformation.birthRanch")
                    }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="getInformation.birthDate"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{
                      getTranslation("pregCheckPage.cowInformation.birthDate")
                    }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :label="
                  getTranslation('pregCheckPage.cowInformation.birthVisual')
                "
                :value="getInformation.birthVisual"
                class="content mb-2"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{
                      getTranslation("pregCheckPage.cowInformation.birthVisual")
                    }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :background-color="
                  getStatus.toLowerCase() == 'cull' ? 'error' : ''
                "
                :error="getStatus.toLowerCase() == 'cull'"
                :label="getTranslation('pregCheckPage.cowInformation.status')"
                :value="getStatus"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    :class="{
                      'black--text':
                        getStatus.toLowerCase() == 'cull' &&
                        !$vuetify.theme.dark,
                      'white--text':
                        getStatus.toLowerCase() == 'cull' &&
                        $vuetify.theme.dark,
                    }"
                    class="content"
                  >
                    {{ getTranslation("pregCheckPage.cowInformation.status") }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="getInformation.ai.timeRecorded">
            <v-col lg="12" cols="12" class="pb-0">
              <h3>AI</h3>
            </v-col>
            <v-col lg="6" cols="12" class="pb-0">
              <v-text-field
                :value="
                  $utils.renderValueAs(
                    getInformation.ai.timeRecorded,
                    'datetime',
                    false
                  )
                "
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("date") }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="getInformation.ai.sireTags"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("sire") }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col lg="6" cols="12" class="pb-0">
              <v-text-field
                :value="getInformation.ai.technician"
                class="mb-2 content"
                hide-details
                outlined
                readonly
              >
                <template #label>
                  <div
                    class="content"
                    :class="{ darkInputText: $vuetify.theme.dark }"
                  >
                    {{ getTranslation("technician") }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </summary-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SummaryContainer from "../SummaryContainer";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "cow-information",
  components: {
    "summary-container": SummaryContainer,
  },
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getGuid: "CowInformation/getGuid",
      getInformation: "CowInformation/getInformation",
      getTagValues: "CowInformation/getTagValues",
      getStatus: "CowInformation/getStatus",
    }),
  },
};
</script>
<style scoped>
.content {
  font-size: 1.6rem;
}
.custom-main-tag {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}
.custom-main-tag:hover {
  background-color: #007bff18;
  border-radius: 8px;
}
</style>