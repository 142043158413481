<template>
  <div class="my-2">
    <div class="d-flex align-center mb-2">
      <export-button
        :disabled="data.length == 0"
        :filename="`Financials_${animal.guid}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :items="data"
        buttonFormat="icon"
      />
    </div>
    <div class="g-row">
      <div class="g-col mobile-row">
        <!-- Clearance Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("clearanceDate") }}</p>
          <v-text-field
            :value="$utils.renderValueAs(details.clearanceDate, 'datetime')"
            append-icon="mdi-calendar"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Total ADG -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("ADG Total") }}</p>
          <v-text-field
            :value="details.adgTotal"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Final Head Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("Final Head Date") }}</p>
          <v-text-field
            :value="$utils.renderValueAs(details.finalHeadDate, 'datetime')"
            append-icon="mdi-calendar"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Sale Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("Sale Date") }}</p>
          <v-text-field
            :value="$utils.renderValueAs(details.saleDate, 'datetime')"
            append-icon="mdi-calendar"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Purchase Price -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("purchasePrice") }}</p>
          <v-text-field
            :value="details.purchasePrice"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Processing Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("processingDate") }}</p>
          <v-text-field
            :value="$utils.renderValueAs(details.processingDate, 'datetime')"
            append-icon="mdi-calendar"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Doctoring Cost -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("doctoringCost") }}</p>
          <v-text-field
            :value="details.doctoringCost"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>
      </div>
      <div class="g-col">
        <!-- ADG (Last Two Weights) -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("adg2") }}</p>
          <v-text-field
            :value="details.adg2"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Head Days -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("headDays") }}</p>
          <v-text-field
            :value="`${animal.getHeadDays()} (${$utils.getAgeOfDuration(
              $moment.duration(details.headDays, 'days')
            )})`"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Purchase Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("purchaseDate") }}</p>
          <v-text-field
            :value="$utils.renderValueAs(details.purchaseDate, 'datetime')"
            append-icon="mdi-calendar"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Freight Cost -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("freightCost") }}</p>
          <v-text-field
            :value="details.freightCost"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Sales Commission -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("Sales Commission") }}</p>
          <v-text-field
            :value="details.salesCommission"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Processing Cost -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("processingCost") }}</p>
          <v-text-field
            :value="details.processingCost"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            readonly
            solo
          >
          </v-text-field>
        </div>

        <!-- Total Event Income -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("totalEventIncome") }}</p>
          <v-text-field
            :value="details.totalEventIncome"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "FinancialsDetails",
  mixins: [TranslationMixin],
  props: {
    animal: {
      required: true,
    },
    data: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    details: {},
    herdMeta: null,
    pouches: null,
    options: {},
  }),
  created: function () {
    this.details = this.data[0] || {};
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
  },
};
</script>
<style scoped>
>>> .v-input {
  max-width: 280px;
}
</style>
<style lang="scss" scoped>
.v-data-table__wrapper > table {
  tbody > tr > td {
    min-width: 120px;
    max-width: 170px;
  }
}
</style>
