var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-2"},[_c('div',{staticClass:"g-row align-center mb-2"},[_c('h4',{staticClass:"mr-2 mb-0 title-style"},[_vm._v("Family")]),_c('export-button',{attrs:{"disabled":Object.values(_vm.details).some(function (v) { return !v; }),"filename":("Family_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"items":[_vm.details],"buttonFormat":"icon"}})],1),_c('div',{staticClass:"g-row"},[_c('div',{staticClass:"g-col"},[_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"subtitle-style"},[_vm._v(_vm._s(_vm.getTranslation("dam")))]),_c('v-text-field',{attrs:{"value":_vm.details.damTags,"dense":"","disabled":"","hide-details":"","prepend-inner-icon":"mdi-lock","solo":""}}),(_vm.details.damId)?_c('router-link',{staticClass:"mb-0 animal-link",attrs:{"to":{
            name: 'AnimalDetails',
            query: { id: _vm.details.damId },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("view"))+" ")]):_vm._e()],1),_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"subtitle-style"},[_vm._v(_vm._s(_vm.getTranslation("adoptDam")))]),_c('v-text-field',{attrs:{"value":_vm.details.adoptDamTags,"dense":"","disabled":"","hide-details":"","prepend-inner-icon":"mdi-lock","solo":""}}),(_vm.details.adoptDamId)?_c('router-link',{staticClass:"mb-0 animal-link",attrs:{"to":{
            name: 'AnimalDetails',
            query: { id: _vm.details.adoptDamId },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("view"))+" ")]):_vm._e()],1),_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"subtitle-style"},[_vm._v(_vm._s(_vm.getTranslation("sire")))]),_c('v-text-field',{attrs:{"value":_vm.details.sireTags,"dense":"","disabled":"","hide-details":"","prepend-inner-icon":"mdi-lock","solo":""}}),(_vm.details.sireId)?_c('router-link',{staticClass:"mb-0 animal-link",attrs:{"to":{
            name: 'AnimalDetails',
            query: { id: _vm.details.sireId },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("view"))+" ")]):_vm._e()],1),_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"subtitle-style"},[_vm._v(_vm._s(_vm.getTranslation("coverSire")))]),_c('v-text-field',{attrs:{"value":_vm.details.coverSireTags,"dense":"","disabled":"","hide-details":"","prepend-inner-icon":"mdi-lock","solo":""}}),(_vm.details.coverSireId)?_c('router-link',{staticClass:"mb-0 animal-link",attrs:{"to":{
            name: 'AnimalDetails',
            query: { id: _vm.details.coverSireId },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("view"))+" ")]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }