<template>
  <div>
    <v-sheet v-if="type === 1" class="rounded-lg mb-4" elevation="5">
      <div class="pt-4 px-2 pb-1 d-flex justify-center">
        <div class="d-flex flex-column align-center">
          <slot name="top-header"></slot>
          <slot name="header">
            <h4 class="ma-0 text-center">
              <span class="red--text mr-2" v-if="isCull">Cull</span>
              {{ title }}
            </h4>
          </slot>
          <div class="w-100 h-auto d-flex justify-center">
            <v-row class="ma-0 justify-center" style="font-size: 1.8rem">
              <v-btn color="primary" disabled text v-if="!animal">
                <span style="font-size: 1.8rem" target="_blank">
                  {{ information.tagValues }}
                </span>
              </v-btn>
              <a
                :href="`/animal-details?id=${information.guid}`"
                target="_blank"
                v-else
              >
                <p class="custom-main-tag pa-2 mb-0">
                  {{ information.tagValues }}
                </p>
              </a>
            </v-row>
            <slot name="bottom-header"></slot>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div
        :style="{ 'max-height': maxHeight + 'px' }"
        class="pa-8 pb-6 pt-4 d-flex flex-column flex-wrap"
      >
        <template v-for="(item, idx) in items">
          <v-text-field
            :key="idx"
            :value="
              !information[item.value] || !information.birthDate
                ? ' '
                : Animal.getAgeOfTimestamp(information.birthDate)
            "
            class="mb-2"
            dense
            hide-details
            outlined
            readonly
            v-if="item.value == 'age'"
          >
            <template #label>
              <div>
                {{ getTranslation(item.labelType) }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            :key="idx"
            :value="information[item.value] || ' '"
            class="mb-2"
            dense
            hide-details
            outlined
            readonly
            v-else
          >
            <template #label>
              <div>
                {{ getTranslation(item.labelType) }}
              </div>
            </template>
          </v-text-field>
        </template>
      </div>
      <template v-if="fertilizations.ai">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>AI</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.ai.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.ai.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.ai.sireTags"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("sire") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
      <template v-if="fertilizations.implant">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>CIDR Implant</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.implant.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.implant.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                fertilizations.implant.gnrhReceived
                  ? getTranslation('Yes')
                  : getTranslation('No')
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("GnRH Received") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                getTranslation(
                  `enums.fertilizationStatusImplant.${fertilizations.implant.statusId}`
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("status") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
      <template v-if="fertilizations.removal">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>CIDR Removal</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.removal.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.removal.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                fertilizations.removal.gnrhReceived
                  ? getTranslation('Yes')
                  : getTranslation('No')
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("GnRH Received") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                getTranslation(
                  `enums.fertilizationStatusRemoval.${fertilizations.removal.statusId}`
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("status") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
    </v-sheet>
    <v-card v-if="type === 2">
      <v-card-title class="pb-0" primary-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-0">
        <v-list class="py-0" dense>
          <v-list-item :key="idx" v-for="(prop, idx) in animalInformation">
            <v-row>
              <v-col class="d-flex align-center subtitle-1">
                {{ getLabelTranslation(prop.translateWord) }}
              </v-col>
              <v-col>
                <div v-if="prop.guid">
                  <router-link
                    :to="{
                      name: 'AnimalDetails',
                      query: { id: prop.guid },
                    }"
                    class="subtitle-2 text-none"
                    target="_blank"
                  >
                    {{ prop.value }}
                  </router-link>
                </div>
                <div
                  :class="{
                    'cull-status':
                      prop.translateWord == 'status' && prop.value == 'Cull',
                  }"
                  v-else
                >
                  {{ prop.value }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-sheet v-if="type === 3" class="rounded-lg mb-4" elevation="5">
      <div class="pt-4 px-2 pb-1 d-flex justify-center">
        <div class="d-flex flex-column align-center">
          <slot name="top-header"></slot>
          <slot name="header">
            <h4 class="ma-0 text-center">{{ title }}</h4>
          </slot>
          <div class="w-100 h-auto d-flex justify-center">
            <v-row class="ma-0 justify-center" style="font-size: 1.8rem">
              <v-btn
                color="primary"
                disabled
                text
                v-if="!animalInformation.guid"
              >
                <span style="font-size: 1.8rem" target="_blank"> ? / ? </span>
              </v-btn>
              <a
                :href="`/animal-details?id=${animalInformation.guid}`"
                target="_blank"
                v-else
              >
                <p class="custom-main-tag pa-2 mb-0">
                  {{ animalInformation.tagValues }}
                </p>
              </a>
            </v-row>
            <slot name="bottom-header"></slot>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div
        :style="{ 'max-height': maxHeight + 'px' }"
        class="pa-8 pb-0 pt-0 d-flex flex-column flex-wrap"
      >
        <div :key="idx" v-for="(prop, idx) in animalInformation.fields">
          <v-text-field
            :value="prop.value"
            class="mb-2"
            dense
            hide-details
            outlined
            readonly
            v-if="
              !prop.translateWord ||
              ['damTags', 'sireTags'].indexOf(prop.translateWord) === -1
            "
          >
            <template #label>
              <div>
                {{ getLabelTranslation(prop.translateWord) }}
              </div>
            </template>
          </v-text-field>
        </div>
      </div>
      <v-divider></v-divider>
      <v-row
        class="d-flex justify-space-between pt-2"
        v-if="
          animalInformation.fields &&
          (animalInformation.fields.damTags ||
            animalInformation.fields.sireTags)
        "
      >
        <v-col
          class="pa-0"
          cols="12"
          lg="6"
          v-if="animalInformation.fields.damTags"
        >
          <v-col class="d-flex justify-center align-center pa-0 pb-2">
            <h4>{{ getTranslation("calfWeanPage.information.dam") }}</h4>
          </v-col>
          <v-row class="d-flex flex-column align-center">
            <v-btn
              color="primary"
              disabled
              text
              v-if="!animalInformation.fields.damTags.guid"
            >
              <span style="font-size: 1.8rem" target="_blank">
                {{ animalInformation.fields.damTags.value || "? / ?" }}
              </span>
            </v-btn>
            <v-btn class="pa-0 px-4" color="primary" text v-else>
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: animalInformation.fields.damTags.guid },
                }"
                class="d-flex justify-center"
                style="
                  font-size: 1.8rem;
                  max-width: 410px !important;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                target="_blank"
              >
                {{ animalInformation.fields.damTags.value }}
              </router-link>
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          lg="6"
          v-if="animalInformation.fields.sireTags"
        >
          <v-col class="d-flex justify-center align-center pa-0 pb-2">
            <h4>{{ getTranslation("calfWeanPage.information.sire") }}</h4>
          </v-col>
          <v-row class="d-flex flex-column align-center">
            <v-btn
              color="primary"
              disabled
              text
              v-if="!animalInformation.fields.sireTags.guid"
            >
              <span style="font-size: 1.8rem" target="_blank">
                {{ animalInformation.fields.sireTags.value || "? / ?" }}
              </span>
            </v-btn>
            <v-btn class="pa-0 px-4" color="primary" text v-else>
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: animalInformation.fields.sireTags.guid },
                }"
                class="d-flex justify-center"
                style="
                  font-size: 1.8rem;
                  max-width: 410px !important;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                target="_blank"
              >
                {{ animalInformation.fields.sireTags.value }}
              </router-link>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div
        :style="{ 'max-height': maxHeight + 'px' }"
        class="pa-8 pb-6 pt-4 d-flex flex-column flex-wrap"
      >
        <template v-for="(item, idx) in items">
          <v-text-field
            :key="idx"
            :value="
              !information[item.value] || !information.birthDate
                ? ' '
                : Animal.getAgeOfTimestamp(information.birthDate)
            "
            class="mb-2"
            dense
            hide-details
            outlined
            readonly
            v-if="item.value == 'age'"
          >
            <template #label>
              <div>
                {{ getTranslation(item.labelType) }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            :key="idx"
            :value="information[item.value] || ' '"
            class="mb-2"
            dense
            hide-details
            outlined
            readonly
            v-else
          >
            <template #label>
              <div>
                {{ getTranslation(item.labelType) }}
              </div>
            </template>
          </v-text-field>
        </template>
      </div>
      <template v-if="fertilizations.ai">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>AI</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.ai.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.ai.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.ai.sireTags"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("sire") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
      <template v-if="fertilizations.implant">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>CIDR Implant</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.implant.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.implant.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                fertilizations.implant.gnrhReceived
                  ? getTranslation('Yes')
                  : getTranslation('No')
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("GnRH Received") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                getTranslation(
                  `enums.fertilizationStatusImplant.${fertilizations.implant.statusId}`
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("status") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
      <template v-if="fertilizations.removal">
        <v-divider> </v-divider>
        <div class="pa-8 pb-6 pt-4">
          <h3>CIDR Removal</h3>
          <v-row>
            <v-text-field
              :value="
                $utils.renderValueAs(
                  fertilizations.removal.timeRecorded,
                  'datetime',
                  false
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("timeRecorded") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="fertilizations.removal.technician"
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("technician") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                fertilizations.removal.gnrhReceived
                  ? getTranslation('Yes')
                  : getTranslation('No')
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("GnRH Received") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="
                getTranslation(
                  `enums.fertilizationStatusRemoval.${fertilizations.removal.statusId}`
                )
              "
              class="mb-2"
              dense
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div>
                  {{ getTranslation("status") }}
                </div>
              </template>
            </v-text-field>
          </v-row>
        </div>
      </template>
    </v-sheet>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "AnimalInformation",
  mixins: [TranslationMixin],
  data() {
    return {
      Animal: Animal,
      fertilizations: {},
      information: {},
    };
  },
  props: {
    animal: {
      type: Object,
    },
    animalInformation: {
      type: Object,
    },
    fertilizationsAI: {
      required: false,
      type: Boolean,
    },
    fertilizationsImplant: {
      required: false,
      type: Boolean,
    },
    fertilizationsRemoval: {
      required: false,
      type: Boolean,
    },
    items: {
      type: Array,
    },
    default: 350,
    maxHeight: {
      type: Number,
    },
    title: {
      default: "Animal Information",
      type: String,
    },
    type: {
      default: 1,
      type: Number,
    },
  },
  computed: {
    isCull: function () {
      const status =
        this.animal &&
        this.animal.doc.derived &&
        this.animal.doc.derived.summaries &&
        this.animal.doc.derived.summaries.main.status;
      return status && status.toLowerCase() === "cull";
    },
  },
  watch: {
    animal: function (animal) {
      if (
        !!animal &&
        !animal.animalIsNew &&
        animal.doc.derived &&
        animal.doc.derived.summaries &&
        animal.doc.derived.summaries.main
      ) {
        this.information = animal.doc.derived.summaries.main;

        // Some types are stored deeper than in main and need to be pulled up
        if (
          this.items
            .map((item) => item.value)
            .some((value) => value === "sireTags") &&
          animal.doc.derived.summaries.fertilizations
        ) {
          this.information.sireTags =
            (animal.doc.derived.summaries.fertilizations &&
              animal.doc.derived.summaries.fertilizations.ai.sireTags) ||
            null;
        }
        if (this.fertilizationsAI) {
          this.fertilizations.ai =
            (animal.doc.derived.summaries.fertilizations || { ai: {} }).ai ||
            {};
        }
        if (this.fertilizationsImplant) {
          this.fertilizations.implant =
            (animal.doc.derived.summaries.fertilizations || { implant: {} })
              .implant || {};
        }
        if (this.fertilizationsRemoval) {
          this.fertilizations.removal =
            (animal.doc.derived.summaries.fertilizations || { removal: {} })
              .removal || {};
        }
      } else {
        this.information = {};
        this.fertilizations = {};
      }
    },
  },
};
</script>
<style scoped>
.custom-main-tag {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}
.custom-main-tag:hover {
  background-color: #007bff18;
  border-radius: 8px;
}
.cull-status {
  background-color: red;
  color: white;
}
</style>