var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-card',{scopedSlots:_vm._u([{key:"title-container",fn:function(){return [_c('v-row',{staticClass:"d-flex align-center mb-6"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"7"}},[_c('v-icon',{staticClass:"mr-4",attrs:{"primary":!_vm.$vuetify.theme.dark,"large":""}},[_vm._v(" "+_vm._s("$headWithTag")+" ")]),_c('div',{staticClass:"text-h5 font-weight-medium dashboard-card-title"},[_vm._v(" Progress of Work ")])],1),_c('v-col',{staticClass:"ml-auto d-flex align-center justify-md-end",attrs:{"cols":"12","md":"5"}},[_c('span',{staticClass:"mr-2 whitespace-nowrap"},[_vm._v(" Filter By ")]),_c('div',{staticClass:"w-40 mr-4"},[_c('v-select',{staticClass:"dashboard-select rounded-lg",attrs:{"dense":"","elevation":"0","flat":"","hide-details":"true","items":_vm.intervals,"label":"","solo":""},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})],1),(_vm.countMissingBirthdate > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 mr-2 rounded-circle warning",attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,false,137136713)},[_c('span',[_vm._v(" Some animals are missing a birth date. ("+_vm._s(_vm.countMissingBirthdate)+") ")])]):_vm._e(),(_vm.countMissingGender > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 mr-2 rounded-circle warning",attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,false,137136713)},[_c('span',[_vm._v("Missing gender may have affected these results. ("+_vm._s(_vm.countMissingGender)+")")])]):_vm._e(),(_vm.countMissingBirthdate === 0 && _vm.countMissingGender === 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 rounded-circle success",attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,false,2527086828)},[_c('span',[_vm._v("No errors/warning detected during computation.")])]):_vm._e()],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column gap-y-4 pr-10"},_vm._l((_vm.summaries[_vm.interval]),function(item,$index){return _c('div',{key:$index},[_c('div',{staticClass:"d-flex font-weight-medium h6"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" "),(item.breakout)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){item.showDetails = !item.showDetails}}},[(item.showDetails)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),_c('span',{staticClass:"ml-auto"},[_vm._v(_vm._s(item.expected))])]),(item.breakout)?[(item.showDetails)?_c('breakout-bar',{attrs:{"dataset":item.breakout}}):_vm._e()]:_c('div',{staticClass:"grey lighten-3 rounded-lg"},[_c('div',{staticClass:"bg-dark-blue font-weight-medium h6 px-2 py-1 rounded-lg text-right text-white whitespace-nowrap",style:(_vm.getItemStyles(item))},[_vm._v(" "+_vm._s(item.value)+" ")])])],2)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }