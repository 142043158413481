<template>
  <div class="dashboard-background h-full">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-text-field
              :placeholder="getTranslation('animalsPages.search')"
              class="mb-0 rounded-lg animal-search"
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model.trim="searchTerm"
            />
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1"> mdi-open-in-new </v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-btn
            @click="toggleFilters"
            class="normal-case px-1 grey--text text--darken-2"
            text
          >
            <template v-if="showFiltersForm">
              {{ getTranslation("animalsPages.closeFilters") }}
              <v-icon class="ml-1" primary="true" small> mdi-close </v-icon>
            </template>
            <template v-else>
              {{ getTranslation("animalsPages.addFilter") }}
              <v-icon class="ml-1" primary="true" small> mdi-plus </v-icon>
            </template>
          </v-btn>
          <v-btn
            @click="resetFilters"
            class="grey--text ml-auto normal-case px-1 text--darken-2"
            text
          >
            {{ getTranslation("animalsPages.reset") }}
          </v-btn>
        </div>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
          v-if="showFiltersForm"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" lg="4">
                <v-form lazy-validation>
                  <v-select
                    :items="archivedFilterItems"
                    :label="getLabelTranslation('Enabled')"
                    v-model="filters.archived"
                  />
                  <v-text-field
                    :label="getTranslation('animalsPages.numberOfAnimals')"
                    autocomplete="off"
                    required
                    type="number"
                    v-model.number="filters.numberOfAnimals"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="filteredHerds"
        class="dashboard-data-table mb-6"
        hide-default-footer
        show-select
        v-model="selected"
      >
        <template #top="{ options, pagination, updateOptions }">
          <v-data-footer
            :items-per-page-text="getTranslation('animalsPages.itemsPerPage')"
            :options="options"
            :pagination="pagination"
            @update:options="updateOptions"
          />
        </template>
        <template v-slot:item.name="{ item }">
          <td>
            <span class="font-weight-medium">
              {{ item.name }}
            </span>
          </td>
        </template>
        <template v-slot:item.archived="{ item }">
          <td>
            <div @click="toggleArchive(item)" class="archive-switch">
              <v-switch
                :false-value="true"
                :true-value="false"
                class="mt-0 pt-0"
                color="rgb(41, 80, 143)"
                dense
                hide-details
                v-model="item.archived"
              />
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import HerdDialog from "../components/dialogs/HerdDialog.vue";
import { toBoolean } from "@/utils/functions/common";
import TranslationMixin from "../mixins/Translations";

export default {
  metaInfo: { title: "Animal Herds" },
  name: "AnimalsHerds",
  components: { HerdDialog },
  mixins: [TranslationMixin],
  data() {
    return {
      filters: { archived: null, numberOfAnimals: null },
      herdMeta: [],
      herds: [],
      loading: true,
      pouches: [],
      searchTerm: "",
      selected: [],
      showFiltersForm: false,
    };
  },
  created() {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    this.init();
  },
  computed: {
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          text: this.getTranslation("animalsPages.herdName"),
          value: "name",
        },
        {
          sortable: false,
          text: this.getTranslation("animalsPages.numberOfAnimals"),
          value: "numberOfAnimals",
        },
        {
          sortable: false,
          text: this.getLabelTranslation("Enable"),
          value: "archived",
        },
      ];
    },
    archivedFilterItems() {
      return [
        { text: this.getTranslation("enums.trueIsYes.true"), value: true },
        { text: this.getTranslation("enums.trueIsYes.false"), value: false },
        { text: this.getTranslation("animalsPages.any"), value: null },
      ];
    },
    filteredHerds() {
      return this.herds.filter((herd) => {
        let searchTermMatch = true;
        let archivedMatch = true;
        let numberOfAnimalsMatch = true;
        if (this.searchTerm) {
          searchTermMatch = herd.name.toLowerCase().includes(this.searchTerm);
        }
        if (this.filters.archived !== null) {
          archivedMatch = herd.archived === this.filters.archived;
        }
        if (
          this.filters.numberOfAnimals !== null &&
          this.filters.numberOfAnimals !== ""
        ) {
          numberOfAnimalsMatch = herd.numberOfAnimals
            .toString()
            .includes(this.filters.numberOfAnimals);
        }
        return searchTermMatch && archivedMatch && numberOfAnimalsMatch;
      });
    },
  },
  methods: {
    init() {
      this.herdMeta.loaded.done(this.fetchData);
    },
    async fetchData() {
      this.loading = true;
      const response = await this.herdMeta.getMetaHerdsAsync(
        true,
        false,
        false
      );
      this.herds = response.map((item) => ({
        archived: toBoolean(item.archived),
        id: item.id,
        name: item.name || "?",
        numberOfAnimals: "?",
      }));
      this.fetchNumberOfAnimals().finally(() => {
        this.loading = false;
      });
    },
    fetchNumberOfAnimals() {
      return new Promise((resolve, reject) => {
        this.pouches.organization
          .animalSummaries(false, false, { include_docs: false })
          .then((results) => {
            if (!results.total_rows) {
              resolve();
            }

            const herdCounts = results.rows
              .map(({ value }) => value)
              .map(({ main }) => main)
              .map(({ herd, status }) => ({
                herd: (herd || "?").toLowerCase(),
                status: (status || "alive").toLowerCase(),
              }))
              .filter(({ status }) =>
                ["alive", "cull", "marketable", "sick"].some(
                  (s) => s === status
                )
              )
              .map(({ herd }) => herd)
              .reduce((reduction, item) => {
                const count = reduction[item] || 0;
                reduction[item] = count + 1;
                return reduction;
              }, {});

            this.herds.forEach((item) => {
              item.numberOfAnimals = herdCounts[item.name.toLowerCase()] || "0";
            });

            resolve();
          });
      });
    },
    getAllowedEdition(item) {
      const diffTime = new Date() - new Date(item.createdOn);
      const diffHours = diffTime / (1000 * 60 * 60);
      return diffHours <= 1 && diffHours >= 0 && item.numberOfAnimals === 0;
    },
    async toggleArchive(item) {
      const message = !item.archived
        ? "Enable record(s)?"
        : "Disable record(s)?";

      await this.herdMeta.update("herds", {
        archived: !item.archived,
        id: item.id,
      });
      this.fetchData();
    },
    exportToCSV() {
      const headers = this.headers.filter((item) => item.text);
      const fields = headers.map((item) => item.text);
      const data = this.selected.map((herd) =>
        headers.map(({ value }) => herd[value])
      );
      this.herdMeta.exportToCSV(fields, data);
    },
    toggleFilters() {
      this.showFiltersForm = !this.showFiltersForm;
      if (!this.showFiltersForm) this.resetFilters();
    },
    resetFilters() {
      this.filters.numberOfAnimals = null;
      this.filters.archived = null;
    },
  },
};
</script>
<style scoped>
.animal-search {
  max-width: 300px;
}
>>> .theme--light.animal-search fieldset {
  border-color: rgba(0 0 0 / 0.2);
}
.enabled {
  background-color: rgb(213 253 225);
}
.disabled {
  background-color: rgb(252 218 220);
}
.theme--dark .enabled,
.theme--dark .disabled {
  color: black;
}
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
.archive-switch {
  cursor: pointer;
  display: inline-block;
}
.archive-switch .v-input {
  pointer-events: none;
}
</style>
