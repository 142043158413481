export const IsLoggedIn = () => {
  const vuex = JSON.parse(localStorage.getItem("vuex"));
  const userID = vuex && vuex.User && vuex.User.userID;
  return !!userID && userID.length > 0;
};

export const IsSuperAdmin = () => {
  const vuex = JSON.parse(localStorage.getItem("vuex"));
  const superAdmin = vuex && vuex.User && vuex.User.superAdmin;
  return !!+superAdmin;
};
