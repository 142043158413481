<template>
  <svg viewBox="0 0 20 20">
    <svg
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :fill="fill"
        d="M16.125 16.625C16.6082 16.625 17 16.2332 17 15.75L17 5.25C17 4.76675 16.6083 4.375 16.125 4.375C15.6418 4.375 15.25 4.76675 15.25 5.25L15.25 15.75C15.25 16.2332 15.6418 16.625 16.125 16.625Z"
      />
      <path
        :fill="fill"
        d="M7.11872 8.49372C7.46043 8.15201 7.46043 7.59799 7.11872 7.25628C6.77701 6.91457 6.22299 6.91457 5.88128 7.25628L3.25628 9.88128C3.09219 10.0454 3 10.2679 3 10.5C3 10.7321 3.09219 10.9546 3.25628 11.1187L5.88128 13.7437C6.22299 14.0854 6.77701 14.0854 7.11872 13.7437C7.46043 13.402 7.46043 12.848 7.11872 12.5063L5.98744 11.375L12.625 11.375C13.1083 11.375 13.5 10.9832 13.5 10.5C13.5 10.0167 13.1082 9.625 12.625 9.625L5.98744 9.625L7.11872 8.49372Z"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
