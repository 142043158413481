var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('summary-container',{attrs:{"headerTitle":_vm.getTranslation('Group Statistics')},scopedSlots:_vm._u([{key:"append-header",fn:function(){return [_c('div',[_c('v-select',{attrs:{"items":_vm.groupNumberByLastUsed,"label":_vm.getTranslation('Group'),"menu-props":{ offsetY: true },"clearable":"","dense":"","filled":"","hide-details":"","outlined":""},model:{value:(_vm.selectedGroupNumber),callback:function ($$v) {_vm.selectedGroupNumber=$$v},expression:"selectedGroupNumber"}})],1)]},proxy:true},{key:"prepend-header",fn:function(){return [_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},(_vm.selectedGroupNumber)?{key:"content",fn:function(){return [_c('v-row',{staticClass:"mb-8"},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.summaryTable.headers,"items":[_vm.summariesByGroupNumber[_vm.selectedGroupNumber]],"dense":"","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_c('thead',[_c('tr',_vm._l((slotProps.props.headers),function(ref,index){
var text = ref.text;
return _c('th',{key:index,staticStyle:{"font-size":"1.6rem"}},[_vm._v(" "+_vm._s(text)+" ")])}),0)])]}},{key:"item",fn:function(props){return [_c('tr',{staticClass:"dataFont"},[_c('td',[_vm._v(_vm._s(props.item.total))]),_c('td',[_vm._v(_vm._s(props.item.c1))]),_c('td',[_vm._v(_vm._s(props.item.c2))]),_c('td',[_vm._v(_vm._s(props.item.late))]),_c('td',[_vm._v(_vm._s(props.item.breed_up_percent))]),_c('td',[_vm._v(_vm._s(props.item.open_cull))]),_c('td',[_vm._v(_vm._s(props.item.preg_cull))]),_c('td',[_vm._v(_vm._s(props.item.cull_percent))])])]}}],null,false,4140581784)})],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.recentEntryTable.headers,"items":[
              _vm.summariesByGroupNumber[_vm.selectedGroupNumber].latestResult ],"dense":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_c('thead',[_c('tr',_vm._l((slotProps.props.headers),function(ref,index){
            var text = ref.text;
return _c('th',{key:index,staticStyle:{"font-size":"1.6rem"}},[_vm._v(" "+_vm._s(text)+" ")])}),0)])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{staticClass:"title text-h6 text-none",attrs:{"to":{
                      name: 'AnimalDetails',
                      query: { id: item.animalId },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.tagValues)+" ")])],1),_c('td',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.breed)+" ")]),_c('td',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('td',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.cycle)+" ")])])]}}],null,false,677224154)})],1)],1)]},proxy:true}:{key:"content",fn:function(){return [_c('p',[_vm._v("Select a group number.")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }