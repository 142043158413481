<template>
  <div>
    <calf-container
      :headerTitle="getTranslation('calfWeanPage.information.name')"
      :isCull="getStatus.toLowerCase() == 'cull'"
      onlyCenterHeader
    >
      <template #bottom-header>
        <v-row class="ma-0 justify-center">
          <v-btn color="primary" disabled text v-if="!getGuid">
            <span style="font-size: 1.8rem" target="_blank">
              {{ getTagValues }}
            </span>
          </v-btn>
          <a v-else target="_blank" :href="`/animal-details?id=${getGuid}`">
            <p class="custom-main-tag pa-2 mb-0">
              {{ getTagValues }}
            </p>
          </a>
        </v-row>
      </template>
      <template #content>
        <v-row>
          <v-col class="py-0" cols="12" lg="6">
            <v-text-field
              :value="getInformation.age"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("pregCheckPage.cowInformation.age") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.birthRanch"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.birthRanch") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.birthVisual"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.birthVisual") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.tsu"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.tsuNumber") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.implantNumber"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.implantNumber") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.birthDate"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.birthDate") }}
                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" lg="6">
            <v-text-field
              :value="getInformation.comment"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.comment") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.weanDate"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.weanDate") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.calvingScore"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.calvingScore") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.vigor"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.vigor") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.breed"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.breed") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :value="getInformation.birthWeight"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  class="content"
                  :class="{ darkInputText: $vuetify.theme.dark }"
                >
                  {{ getTranslation("calfWeanPage.information.birthWeight") }}
                </div>
              </template>
            </v-text-field>
            <v-text-field
              :background-color="
                getStatus.toLowerCase() == 'cull' ? 'error' : ''
              "
              :error="getStatus.toLowerCase() == 'cull'"
              :label="getTranslation('pregCheckPage.cowInformation.status')"
              :value="getStatus"
              class="mb-2 content"
              hide-details
              outlined
              readonly
            >
              <template #label>
                <div
                  :class="{
                    'black--text':
                      getStatus.toLowerCase() == 'cull' && !$vuetify.theme.dark,
                    'white--text':
                      getStatus.toLowerCase() == 'cull' && $vuetify.theme.dark,
                  }"
                  class="content"
                >
                  {{ getTranslation("pregCheckPage.cowInformation.status") }}
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
          <v-col class="pa-0 pb-3" cols="12" lg="6">
            <v-divider></v-divider>
            <v-col class="d-flex justify-center align-center pa-0 pb-2">
              <h4>{{ getTranslation("calfWeanPage.information.dam") }}</h4>
            </v-col>
            <v-row class="d-flex flex-column align-center">
              <v-btn color="primary" disabled text v-if="!getInformation.damId">
                <span style="font-size: 1.8rem" target="_blank">
                  {{ getInformation.damTags || "? / ?" }}
                </span>
              </v-btn>
              <v-btn
                class="pa-0 pl-8"
                color="primary"
                text
                v-else-if="getInformation.damTags"
              >
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: getInformation.damId },
                  }"
                  class="d-flex justify-center"
                  style="
                    font-size: 1.8rem;
                    max-width: 410px !important;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  target="_blank"
                >
                  {{ getInformation.damTags }}
                </router-link>
              </v-btn>
              <v-col class="pa-0" cols="10">
                <v-text-field
                  :value="getInformation.dam.birthDate"
                  class="mb-2 content"
                  hide-details
                  outlined
                  readonly
                >
                  <template #label>
                    <div
                      class="content"
                      :class="{ darkInputText: $vuetify.theme.dark }"
                    >
                      {{ getTranslation("calfWeanPage.information.birthDate") }}
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="10">
                <v-text-field
                  :value="getInformation.dam.breed"
                  class="mb-2 content"
                  hide-details
                  outlined
                  readonly
                >
                  <template #label>
                    <div
                      class="content"
                      :class="{ darkInputText: $vuetify.theme.dark }"
                    >
                      {{ getTranslation("calfWeanPage.information.breed") }}
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0 pb-3" cols="12" lg="6">
            <v-divider></v-divider>
            <v-col class="d-flex justify-center align-center pa-0 pb-2">
              <h4>{{ getTranslation("calfWeanPage.information.sire") }}</h4>
            </v-col>
            <v-row class="d-flex flex-column align-center">
              <v-btn
                color="primary"
                disabled
                text
                v-if="!getInformation.sireId"
              >
                <span style="font-size: 1.8rem" target="_blank">
                  {{ getInformation.sireTags || "? / ?" }}
                </span>
              </v-btn>
              <v-btn
                class="pa-0 pl-8"
                color="primary"
                text
                v-else-if="getInformation.sireTags"
              >
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: getInformation.sireId },
                  }"
                  class="d-flex justify-center text-none"
                  style="
                    font-size: 1.8rem;
                    max-width: 410px !important;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  target="_blank"
                >
                  {{ getInformation.sireTags }}
                </router-link>
              </v-btn>
              <v-col class="pa-0" cols="10">
                <v-text-field
                  :value="getInformation.sire.birthDate"
                  class="mb-2 content"
                  hide-details
                  outlined
                  readonly
                >
                  <template #label>
                    <div
                      class="content"
                      :class="{ darkInputText: $vuetify.theme.dark }"
                    >
                      {{ getTranslation("calfWeanPage.information.birthDate") }}
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="10">
                <v-text-field
                  :value="getInformation.sire.breed"
                  class="mb-2 content"
                  hide-details
                  outlined
                  readonly
                >
                  <template #label>
                    <div
                      class="content"
                      :class="{ darkInputText: $vuetify.theme.dark }"
                    >
                      {{ getTranslation("calfWeanPage.information.breed") }}
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </calf-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Container from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "calf-wean-information",
  mixins: [TranslationMixin],
  components: {
    "calf-container": Container,
  },
  computed: {
    ...mapGetters({
      getGuid: "CalfInformation/getGuid",
      getInformation: "CalfInformation/getInformation",
      getStatus: "CalfInformation/getStatus",
      getTagValues: "CalfInformation/getTagValues",
    }),
  },
};
</script>
<style scoped>
.custom-tag {
  font-size: 1.8rem;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  font-size: 1.6rem;
}

.custom-main-tag {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}
.custom-main-tag:hover {
  background-color: #007bff18;
  border-radius: 8px;
}
</style>