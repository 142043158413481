<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div class="my-6">
          <h4>
            {{ t.title }}
            <a class="btn btn-primary" @click="download">
              <v-icon>mdi-download</v-icon>
            </a>
          </h4>
          <v-divider></v-divider>
        </div>

        This page will automatically attempt to download the EULA to your
        device. You can navigate directly to it at
        <a href="assets/eula/eula.pdf"> {{ origin }}/assets/eula/eula.pdf </a>
        or download again.
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "EULA",
  metaInfo: {
    title: "EULA",
  },
  mixins: [TranslationMixin],
  methods: {
    download: function (label = "eula.pdf") {
      axios
        .get("assets/eula/eula.pdf", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  created: function () {
    this.download();
  },
  computed: {
    origin: function () {
      return window.location.origin;
    },
    t: function () {
      return {
        title: this.translate.navbar.eula[this.localeLang],
      };
    },
  },
};
</script>
