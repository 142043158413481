<template>
  <div style="z-index: 500">
    <v-dialog
      :value="value"
      @click:outside="$emit('close-logout-dialog')"
      @input="$emit('input', $event.target.value)"
      @keydown.esc="$emit('close-logout-dialog')"
      max-width="300px"
      scrollable
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title color="red" class="headline">
          {{ labels.title }}
        </v-card-title>

        <v-card-text class="pa-5"> {{ labels.message }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="red white--text" @click="logout">
            {{ labels.confirm }}
          </v-btn>
          <v-btn rounded color="primary" @click="$emit('close-logout-dialog')">
            {{ labels.deny }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import CookiesConstants from "@/utils/constants/cookies";
export default {
  data: () => ({
    labels: {
      confirm: "Yes",
      deny: "No",
      message: "Are you sure you want to logout?",
      title: "Logout",
    },
  }),
  name: "LogoutDialog",
  props: ["value"],
  computed: {
    ...mapGetters({
      getUserToken: "User/getToken",
    }),
  },
  methods: {
    ...mapActions({ resetVuexCache: "reset" }),
    logout: function () {
      // Allow db to update next time user logs in
      this.resetVuexCache();

      const token = this.getUserToken;

      // Remove legacy cookies (only necessary until a while after coldfusion-based APIs are gone)
      // TODO: Remove these lines once coldfusion-based APIs are gone
      this.$cookies.remove(CookiesConstants.CFID);
      this.$cookies.remove(CookiesConstants.CFTOKEN);

      this.$cookies.remove(CookiesConstants.AUTH_SESSION);
      this.$cookies.remove(CookiesConstants.LOCALE);
      this.$cookies.remove(CookiesConstants.ORGANIZATION_NAME);
      this.$cookies.remove(CookiesConstants.ORGANIZATIONID);
      this.$cookies.remove(CookiesConstants.POUCHPASS);
      this.$cookies.remove(CookiesConstants.SUPERADMIN);
      this.$cookies.remove(CookiesConstants.TIMEZONE);
      this.$cookies.remove(CookiesConstants.TOKEN);
      this.$cookies.remove(CookiesConstants.USERID);
      this.$cookies.remove(CookiesConstants.USERNAME);

      // Allow db to update next time user logs in
      // Attempt to destroy session on back-end
      setTimeout(() => {
        axios
          .delete("/api/auth/token", {
            params: {
              token,
              userId: this.$userID,
              organizationId: this.$organizationID,
            },
            timeout: 2000,
          })
          .finally(() => {
            this.$router.replace({ name: "SignIn" });
            this.$router.go();
          });
      }, 0);
    },
  },
};
</script>
