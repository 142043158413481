<template>
  <div>
    <!-- Bred To -->
    <div class="g-row mb-4" v-if="bredTo && bredTo.sireId">
      <h4 class="mb-0 mr-2 title-style">Bred To</h4>
      <div class="g-row">
        <router-link
          :to="{
            name: 'AnimalDetails',
            query: { id: bredTo.sireId },
          }"
          class="h4 mb-0"
          target="_blank"
        >
          {{ bredTo.sireTags }}
        </router-link>
      </div>
    </div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0 title-style" v-if="showTitle">Calving History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Calving_History ${this.animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.offspringTags="{ item }">
        <router-link
          :to="{
            name: 'AnimalDetails',
            query: { id: item.animalId },
          }"
          class="subtitle-2"
          target="_blank"
        >
          {{ item.offspringTags }}
        </router-link>
      </template>
      <template #item.birthDate="{ item }">
        {{ $utils.renderValueAs(item.birthDate, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "Weight",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      bredTo: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    await this.herdMeta.promiseLoaded();
    try {
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      if (
        this.animal &&
        this.animal.doc &&
        this.animal.doc.derived &&
        this.animal.doc.derived.summaries &&
        this.animal.doc.derived.summaries.fertilizations &&
        this.animal.doc.derived.summaries.fertilizations.ai
      ) {
        this.bredTo = {
          sireId: this.animal.doc.derived.summaries.fertilizations.ai.sireId,
          sireTags:
            this.animal.doc.derived.summaries.fertilizations.ai.sireTags,
        };
      }
      this.table.data = (await this.animal.getCalvings()) || [];
      this.table.loading = false;
    } catch (error) {
      this.table.loading = false;
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("offspringTags"),
          value: "offspringTags",
        },
        {
          text: this.getTranslation("birthWeight"),
          value: "birthWeight",
        },
        { text: this.getTranslation("birthDate"), value: "birthDate" },
        { text: this.getTranslation("calvingEase"), value: "birthCalvingEase" },
        { text: this.getTranslation("Relationship"), value: "relationship" },
        {
          text: this.getTranslation("sex"),
          value: "sex",
        },
        {
          text: this.getTranslation("status"),
          value: "status",
        },
        { text: this.getTranslation("Vigor"), value: "vigor" },
        { text: this.getTranslation("color"), value: "color" },
        { text: this.getTranslation("breed"), value: "breed" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
};
</script>
<style scoped>
.title-style {
  font-weight: 600;
  font-size: 22px;
}
</style>
