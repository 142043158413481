<template>
  <div>
    <in-page-menu :activeBadge="countForGroupNumber" />
    <div class="desktop-g-row mb-lg-3" style="margin-top: 70px">
      <div :class="['desktop-g-col', 'px-7']">
        <div class="panel panel-default force-w-100" v-if="$isMobileScreen">
          <div
            :class="{
              'bg-dark': $vuetify.theme.dark,
            }"
            class="card card-body mb-4"
          >
            <h4 class="panel-title">
              <a data-toggle="collapse" href="#entry"> Pregnancy Test Entry </a>
            </h4>
          </div>
        </div>
        <pregnancy-test
          :class="['tablet-row', $isMobileScreen && 'collapse']"
          id="entry"
        />
      </div>
      <div class="desktop-g-col px-7">
        <div class="panel panel-default force-w-100" v-if="$isMobileScreen">
          <div
            :class="{
              'bg-dark': $vuetify.theme.dark,
            }"
            class="card card-body mb-4"
          >
            <h4 class="panel-title">
              <a data-toggle="collapse" href="#group"> Group Summary </a>
            </h4>
          </div>
        </div>
        <group-summary
          :class="['tablet-row', $isMobileScreen && 'collapse']"
          id="group"
        />

        <div class="panel panel-default force-w-100" v-if="$isMobileScreen">
          <div
            :class="{
              'bg-dark': $vuetify.theme.dark,
            }"
            class="card card-body mb-4"
          >
            <h4 class="panel-title">
              <a data-toggle="collapse" href="#information">
                Cow Information
              </a>
            </h4>
          </div>
        </div>
        <cow-information
          :class="['tablet-row', $isMobileScreen && 'collapse']"
          id="information"
        />
        <div class="panel panel-default force-w-100" v-if="$isMobileScreen">
          <div
            :class="{
              'bg-dark': $vuetify.theme.dark,
            }"
            class="card card-body mb-4"
          >
            <h4 class="panel-title">
              <a data-toggle="collapse" href="#last"> Last Calvings </a>
            </h4>
          </div>
        </div>
        <last-calvings-table
          :class="['tablet-row', $isMobileScreen && 'collapse']"
          id="last"
        />
      </div>
    </div>

    <div class="panel panel-default force-w-100 px-7" v-if="$isMobileScreen">
      <div
        :class="{
          'bg-dark': $vuetify.theme.dark,
        }"
        class="card card-body mb-4"
      >
        <h4 class="panel-title">
          <a data-toggle="collapse" href="#summary"> Summary </a>
        </h4>
      </div>
    </div>

    <summary-table
      :class="['tablet-row', $isMobileScreen && 'collapse']"
      id="summary"
    />
    <v-dialog
      max-width="280"
      persistent
      scrollable
      transition="dialog-transition"
      v-model="showAlerts"
    >
      <v-card class="mx-auto">
        <v-card-title class="text-h5 warning">
          <v-icon>mdi-alert</v-icon>
          <div class="ml-2">Alert</div>
        </v-card-title>
        <v-card-text
          class="d-flex align-center justify-center pa-0"
          style="height: 70px; width: 100%"
        >
          <div class="text-h5 text--primary">Cull status detected</div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :color="$vuetify.theme.dark ? 'teal accent-2' : 'teal darken-1'"
            @click="showAlerts = false"
            text
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition v-if="getStatus.toLowerCase() == 'cull'">
      <v-btn
        :key="'showAlerts'"
        @click="showAlerts = true"
        bottom
        class="v-btn--example"
        color="warning"
        dark
        elevation="5"
        fab
        fixed
        large
        right
      >
        <v-icon>mdi-alert</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import { mapGetters } from "vuex";
import CowInformation from "@/components/pregCheck/CowInformation";
import GroupStatistics from "@/components/pregCheck/GroupStatistics";
import LastCalvings from "@/components/pregCheck/LastCalvings";
import PregCheckSummary from "@/components/pregCheck/PregCheckSummary";
import PregnancyTestEntry from "@/components/pregCheck/PregnancyTestEntry";
import TranslationMixin from "../mixins/Translations";

export default {
  metaInfo: {
    title: "Preg Check",
  },
  mixins: [TranslationMixin],
  components: {
    "cow-information": CowInformation,
    "group-summary": GroupStatistics,
    "last-calvings-table": LastCalvings,
    "pregnancy-test": PregnancyTestEntry,
    "summary-table": PregCheckSummary,
  },
  data: () => ({
    countForGroupNumber: "?",
    showAlerts: false,
    reveal: false,
  }),
  computed: {
    ...mapGetters({ getStatus: "CowInformation/getStatus" }),
  },
  beforeDestroy: function () {
    EventBus.$off("new-preg-check");
  },
  created: function () {
    EventBus.$on("preg-check-total", (count) => {
      this.countForGroupNumber = count;
    });
  },
  watch: {
    getStatus: function (status) {
      if (status.toLowerCase() == "cull") this.showAlerts = true;
      else this.showAlerts = false;
    },
  },
};
</script>