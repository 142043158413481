var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0"},[_vm._v("Tags")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Tags_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(!item.tagId)?_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.getEnumOptions('tagTypes'),"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify('ids', item.id, 'type', item.type, true, false)}},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}}):_c('div',[_vm._v(" "+_vm._s(_vm.getLabelTranslation(("enums.tagTypes." + (item.type))))+" ")])]}},{key:"item.tagTable",fn:function(ref){
        var item = ref.item;
return [(!item.tagId || !!_vm.animal.herdMeta.getMetaTagById(item.tagId))?_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.animalTags,"dense":"","hide-details":"","item-text":"name","item-value":"id","outlined":""},on:{"change":function($event){return _vm.animal.modify('ids', item.id, 'tagId', item.tagId, true, false)}},model:{value:(item.tagId),callback:function ($$v) {_vm.$set(item, "tagId", $$v)},expression:"item.tagId"}}):_c('div',[_vm._v(" "+_vm._s(item.tagTable)+" ")])]}},{key:"item.tagValue",fn:function(ref){
        var item = ref.item;
return [(_vm.statusIsActive(item.status))?_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'ids',
            item.id,
            'tagValue',
            item.tagValue,
            true,
            false
          )}},model:{value:(item.tagValue),callback:function ($$v) {_vm.$set(item, "tagValue", $$v)},expression:"item.tagValue"}}):_c('div',[_vm._v(" "+_vm._s(item.tagValue)+" ")])]}},{key:"item.location",fn:function(ref){
          var item = ref.item;
return [(_vm.statusIsActive(item.status))?_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'ids',
            item.id,
            'location',
            item.location,
            true,
            false
          )}},model:{value:(item.location),callback:function ($$v) {_vm.$set(item, "location", $$v)},expression:"item.location"}}):_c('div',[_vm._v(" "+_vm._s(item.location)+" ")])]}},{key:"item.tagColor",fn:function(ref){
          var item = ref.item;
return [(
          !item.tagId &&
          (item.type != 'association' || item.tagColor) &&
          _vm.statusIsActive(item.status)
        )?_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'ids',
            item.id,
            'tagColor',
            item.tagColor,
            true,
            false
          )}},model:{value:(item.tagColor),callback:function ($$v) {_vm.$set(item, "tagColor", $$v)},expression:"item.tagColor"}}):_c('div',[_vm._v(" "+_vm._s(item.tagColor)+" ")])]}},{key:"item.manufacturer",fn:function(ref){
          var item = ref.item;
return [(
          !item.tagId &&
          (item.type != 'association' || item.manufacturer) &&
          _vm.statusIsActive(item.status)
        )?_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'ids',
            item.id,
            'manufacturer',
            item.manufacturer,
            true,
            false
          )}},model:{value:(item.manufacturer),callback:function ($$v) {_vm.$set(item, "manufacturer", $$v)},expression:"item.manufacturer"}}):_c('div',[_vm._v(" "+_vm._s(item.tagColor)+" ")])]}},{key:"item.size",fn:function(ref){
          var item = ref.item;
return [(
          !item.tagId &&
          (item.type != 'association' || item.size) &&
          _vm.statusIsActive(item.status)
        )?_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.animal.disallowChanges(),"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify('ids', item.id, 'size', item.size, true, false)}},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}}):_c('div',[_vm._v(" "+_vm._s(item.tagColor)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"false-value":"inactive","true-value":"active","color":"rgb(41, 80, 143)","dense":"","hide-details":""},on:{"change":function($event){return _vm.animal.modify('ids', item.id, 'status', item.status, true, false)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.timeRecorded",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.timeRecorded, "datetime", true))+" ")]),(!_vm.disabled)?_c('v-btn',{staticClass:"pa-0 ml-1",attrs:{"color":"#3565A1","max-width":"32","min-width":"32","small":""},on:{"click":function($event){return _vm.openDatetimeDialog(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.createdOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.lastUpdated, "datetime", true))+" ")]}}])}),(_vm.showExtraInformation)?_c('div',[_c('v-spacer',{staticStyle:{"height":"40px"}}),(_vm.table.data[0] && _vm.table.data[0].lastUpdated)?_c('p',{staticStyle:{"color":"#989089"}},[_vm._v(" Last Updated: "+_vm._s(_vm.$utils.renderValueAs(_vm.table.data[0].lastUpdated, "datetime", true))+" ")]):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.datetimeDialog.show),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "show", $$v)},expression:"datetimeDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_c('datetime-component',{model:{value:(_vm.datetimeDialog.datetime),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "datetime", $$v)},expression:"datetimeDialog.datetime"}})],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"pb-4 pr-6 d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2 rounded-lg normal-case body-1",attrs:{"text":""},on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.cancel"))+" ")]),_c('v-btn',{staticClass:"rounded-lg primary-button normal-case body-1",attrs:{"disabled":!_vm.datetimeDialog.datetime},on:{"click":_vm.modifyTimeRecorded}},[_c('span',{staticClass:"px-6"},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.save"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }