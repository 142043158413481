const AUTH_SESSION = "AuthSession";
const CFID = "CFID";
const CFTOKEN = "CFTOKEN";
const EXPIRE_TIME = "1d";
const LOCALE = "LOCALE";
const ORGANIZATION_NAME = "ORGANIZATIONNAME";
const ORGANIZATIONID = "ORGANIZATIONID";
const PATH = "/";
const POUCHPASS = "POUCHPASS";
const SUPERADMIN = "SUPERADMIN";
const TIMEZONE = "TIMEZONE";
const TOKEN = "TOKEN";
const USERID = "USERID";
const USERNAME = "USERNAME";

export default {
  AUTH_SESSION,
  CFID,
  CFTOKEN,
  EXPIRE_TIME,
  LOCALE,
  ORGANIZATION_NAME,
  ORGANIZATIONID,
  PATH,
  POUCHPASS,
  SUPERADMIN,
  TIMEZONE,
  TOKEN,
  USERID,
  USERNAME,
};
