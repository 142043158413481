<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="200"
    :offset-y="true"
    @input="onToggle"
    class="light-elevation"
    elevation="0"
    v-model="showMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!enableSelection"
        class="rounded-lg d-flex organization-button"
        dark
        dense
        elevation="0"
        style="background-color: #3465a1 !important"
        v-bind="attrs"
        v-on="on"
      >
        <span :class="['truncate mr-2', !enableSelection && 'disabled-style']">
          {{ getOrganizationName }}
        </span>
        <div class="ml-auto">
          <v-chip
            class="chip mr-1"
            color="grey lighten-5"
            v-if="animalCount != null"
          >
            {{ animalCount }}
          </v-chip>
          <v-icon v-if="showMenu">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </div>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-list @click.native.stop class="relative light-elevation" elevation="0">
        <v-icon class="search-icon pl-5">mdi-magnify</v-icon>
        <v-text-field
          class="input-search pr-4 pl-12"
          flat
          hide-details
          placeholder="Type to search"
          ref="inputSearch"
          solo
          v-model="searchTerm"
        />
      </v-list>
      <v-list class="organization-list">
        <v-list-item
          @click="choose(organization)"
          class="list-item"
          v-for="(organization, $index) in filteredOrganizations"
          :key="$index"
        >
          <v-list-item-title class="subtitle-1 d-flex align-center">
            <span class="mr-2">
              {{ organization.name }}
            </span>
            <v-chip :color="organization.color" class="ml-auto" dark>
              {{ organization.animal_count || 0 }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { IsSuperAdmin } from "../../utils/functions/auth";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Cookies from "@/utils/constants/cookies";

export default {
  props: ["animalCount"],
  data() {
    return {
      searchTerm: "",
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
      getOrganizationItems: "Organization/getOrganizationItems",
      getOrganizationName: "Organization/getName",
      getToken: "User/getToken",
    }),
    enableSelection() {
      return IsSuperAdmin() || this.organizations.length >= 1;
    },
    organizations() {
      return this.getOrganizationItems
        .map((item) => {
          const lastEdition = new Date(item.lastAnimalEditTime);
          const diffDays = Math.ceil(
            new Date() - lastEdition / (1000 * 60 * 60 * 24)
          );
          const lessThanOneMonth = diffDays < 30;
          const lessThanSixMonth = diffDays < 30 * 6;

          let color;
          if (lessThanOneMonth || !item.animalCount) {
            color = "success";
          } else if (lessThanSixMonth) {
            color = "yellow";
          } else {
            color = "red";
          }
          return { ...item, color };
        })
        .filter((item) => item.id !== this.getOrganizationID)
        .sort((first, second) => first.name.localeCompare(second.name));
    },
    filteredOrganizations() {
      if (!this.searchTerm) return this.organizations;

      return this.organizations.filter((item) =>
        item.name
          .toLocaleLowerCase()
          .includes(this.searchTerm.toLocaleLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      setOrganizationID: "Organization/setOrganizationID",
      setOrganizationName: "Organization/setName",
    }),
    async choose(organization) {
      const config = { params: { token: this.getToken }, timeout: 4 * 1000 };
      const url = `/api/users/${this.$userID}/current-organization/${organization.id}`;
      let response;

      try {
        response = await axios.patch(url, null, config);
      } catch (e) {
        console.error("Error", e);
      }
      // Successful patch is not required but it is helpful
      if (response && response.status !== 200) {
        console.error(
          "Error",
          `Fail to save organization selection ${response.statusText} - ${response.status}`
        );
      }
      this.setOrganizationID({ organizationID: organization.id });
      this.setOrganizationName({ name: organization.name });
      this.$router.go();
    },
    async onToggle(opened) {
      if (!opened) return;

      await this.$nextTick();
      setTimeout(() => this.$refs.inputSearch.focus(), 150);
    },
  },
};
</script>

<style scoped>
.organization-list {
  max-height: 20rem;
  overflow-y: auto;
  padding-top: 0;
}
.list-item:first-child {
  border-top: none;
}
.list-item {
  border-top: 1px solid #eee;
  padding-left: 1.5rem;
}
.relative {
  position: relative;
}
.search-icon {
  pointer-events: none;
  position: absolute;
  top: 1.25rem;
}
.chip {
  color: #3465a1;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.organization-button >>> .v-btn__content {
  width: 100%;
}
.theme--light .v-menu__content {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.07),
    0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.07);
}
.v-menu__content {
  z-index: 5000 !important;
}
.elevation-0.light-elevation {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.15), 0 1px 2px -1px rgb(0 0 0 / 0.15) !important;
}
.disabled-style {
  color: white;
}
</style>
