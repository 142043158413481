import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  actions: {
    reset: ({ commit }) => {
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/RESET`);
      });
    },
  },
  plugins: [
    createPersistedState({
      paths: ["User", "Organization"],
    }),
    createMutationsSharer({
      predicate: [
        "CowInformation/setStatus",
        "Organization/setOrganizationID",
        "User/setDarkMode",
        "User/setToken",
        "User/setUserID",
      ],
    }),
  ],
});
