var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu'),_c('v-row',{staticClass:"mx-4 mx-md-6 mx-lg-8 pt-20"},[_c('online-warning'),_c('v-col',{attrs:{"cols":"12"}},[[_c('v-alert',{attrs:{"type":"error","color":"red lighten-2","dismissible":""}},[_vm._v(" This tool was created for use with fixing data on animals that have been transferred. If you do not understand why this tool was made, you probably do not want to use it. ")]),_vm._l((_vm.steps),function(step){return _c('p',{key:step.text},[_c('v-btn',{class:{
              warning:
                step.error ||
                (step.precondition && _vm.steps[step.precondition].error)
                  ? 'warning'
                  : '',
            },attrs:{"disabled":(step.precondition && !_vm.steps[step.precondition].completed) ||
              step.running ||
              (step.completed && !step.canRepeat)},on:{"click":function($event){return _vm.runstep(step)}}},[(step.completed)?_c('v-icon',[_vm._v("mdi-check-circle-outline")]):(step.running)?_c('v-icon',[_vm._v("mdi-reload")]):_c('v-icon',[_vm._v("mdi-radiobox-blank")]),_vm._v(" "+_vm._s(step.text)+" ")],1),(step.error)?[_vm._v(" "+_vm._s(step.textError)+" ")]:_vm._e()],2)}),(_vm.steps.stepSendToMichael.completed)?_c('h1',[_vm._v(" Now repeat with another organization! ")]):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }