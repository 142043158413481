var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0 title-style"},[_vm._v("Preg Check History")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Preg_Check_History " + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-dark-mode",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deletePregCheck(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.testType",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.pregCheckTests,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'testType',
            item.testType,
            true,
            false
          )}},model:{value:(item.testType),callback:function ($$v) {_vm.$set(item, "testType", $$v)},expression:"item.testType"}})]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'status',
            item.status,
            true,
            false
          )}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.daysBred",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'daysBred',
            item.daysBred,
            true,
            false,
            {
              pregCheckTime: item.pregCheckTime,
              bullTurninDate: item.bullTurninDate,
            }
          )}},model:{value:(item.daysBred),callback:function ($$v) {_vm.$set(item, "daysBred", $$v)},expression:"item.daysBred"}})]}},{key:"item.bullTurninDate",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":"","min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"append-icon":"mdi-calendar","dense":"","hide-details":"","outlined":"","readonly":""},model:{value:(item.bullTurninDate),callback:function ($$v) {_vm.$set(item, "bullTurninDate", $$v)},expression:"item.bullTurninDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showCalendar[item.id]),callback:function ($$v) {_vm.$set(_vm.showCalendar, item.id, $$v)},expression:"showCalendar[item.id]"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.animal.modify(
              'pregChecks',
              item.id,
              'bullTurninDate',
              item.bullTurninDate,
              true,
              false,
              {
                pregCheckTime: item.pregCheckTime,
                daysBred: item.daysBred,
              }
            )}},model:{value:(item.bullTurninDate),callback:function ($$v) {_vm.$set(item, "bullTurninDate", $$v)},expression:"item.bullTurninDate"}})],1)]}},{key:"item.bcs",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.bcsOptions,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify('pregChecks', item.id, 'bcs', item.bcs, true, false)}},model:{value:(item.bcs),callback:function ($$v) {_vm.$set(item, "bcs", $$v)},expression:"item.bcs"}})]}},{key:"item.fetalSex",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.fetalSexes,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'fetalSex',
            item.fetalSex,
            true,
            false
          )}},model:{value:(item.fetalSex),callback:function ($$v) {_vm.$set(item, "fetalSex", $$v)},expression:"item.fetalSex"}})]}},{key:"item.mouthScore",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.mouthScores,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"mouthScore","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'mouthScore',
            item.mouthScore,
            true,
            false
          )}},model:{value:(item.mouthScore.mouthScore),callback:function ($$v) {_vm.$set(item.mouthScore, "mouthScore", $$v)},expression:"item.mouthScore.mouthScore"}})]}},{key:"item.cycle",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"items":_vm.cycles,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","disabled":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'cycle',
            item.cycle,
            true,
            false
          )}},model:{value:(item.cycle),callback:function ($$v) {_vm.$set(item, "cycle", $$v)},expression:"item.cycle"}})]}},{key:"item.method",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"items":_vm.pregCheckTestMethods,"menu-props":{ offsetY: true, closeOnClick: true },"dense":"","hide-details":"","item-text":"label","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'method',
            item.method,
            true,
            false
          )}},model:{value:(item.method),callback:function ($$v) {_vm.$set(item, "method", $$v)},expression:"item.method"}})]}},{key:"item.result",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.result)+" ")]}},{key:"item.technician",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'technician',
            item.technician,
            true,
            false
          )}},model:{value:(item.technician),callback:function ($$v) {_vm.$set(item, "technician", $$v)},expression:"item.technician"}})]}},{key:"item.pregCheckTime",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"g-row"},[_c('div',{staticClass:"g-col-2"},[_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.pregCheckTime, "datetime", true))+" ")]),_c('div',{staticClass:"g-col-1 d-flex align-center"},[(!_vm.disabled)?_c('v-btn',{staticClass:"pa-0 ml-1",attrs:{"color":"#3565A1","max-width":"32","min-width":"32","small":""},on:{"click":function($event){return _vm.openDatetimeDialog(item, 'pregCheckTime')}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-pencil")])],1):_vm._e()],1)])]}},{key:"item.daysSinceLastPregCheck",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.getAgeOfTimestamp(item.pregCheckTime, { ignoreDays: true, ignoreHours: true, }))+" ")]}},{key:"item.groupNumber",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'pregChecks',
            item.id,
            'groupNumber',
            item.groupNumber,
            true,
            false
          )}},model:{value:(item.groupNumber),callback:function ($$v) {_vm.$set(item, "groupNumber", $$v)},expression:"item.groupNumber"}})]}},{key:"item.predictedCalvingDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.predictedCalvingDate, "datetime", true))+" ")]}},{key:"item.actualCalvingDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.actualCalvingDate, "datetime", true))+" ")]}},{key:"item.createdOn",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.lastUpdated, "datetime", true))+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.datetimeDialog.show),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "show", $$v)},expression:"datetimeDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v(" "+_vm._s(_vm.datetimeDialog.title)+" ")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_c('datetime-component',{model:{value:(_vm.datetimeDialog.datetime),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "datetime", $$v)},expression:"datetimeDialog.datetime"}})],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"pb-4 pr-6 d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2 rounded-lg normal-case body-1",attrs:{"text":""},on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.cancel"))+" ")]),_c('v-btn',{staticClass:"rounded-lg primary-button normal-case body-1",attrs:{"disabled":!_vm.datetimeDialog.datetime},on:{"click":_vm.modifyDatetime}},[_c('span',{staticClass:"px-6"},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.save"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }