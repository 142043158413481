var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-background"},[_c('in-page-menu'),_c('div',{staticClass:"mx-4 mx-lg-8 mx-md-6 pt-20"},[[_c('v-row',{staticClass:"align-center d-flex mb-6"},[_c('v-col',{staticClass:"align-center d-flex justify-md-end ml-auto",attrs:{"cols":"12","md":"9"}},[_c('v-btn',{staticClass:"normal-case rounded-lg primary-button",attrs:{"text":""},on:{"click":_vm.printTable}},[_c('v-icon',{staticClass:"mr-1",attrs:{"primary":"true"}},[_vm._v("mdi-printer")]),_vm._v(" Print ")],1),_c('v-btn',{staticClass:"normal-case rounded-lg light-blue-button ml-3",attrs:{"text":""},on:{"click":_vm.exportToCSV}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-open-in-new")]),_vm._v(" CSV ")],1)],1)],1),_c('v-card',{staticClass:"dashboard-light-border h-full light-elevation rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"h-full title-and-content-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"label":_vm.getTranslation('From'),"append-icon":"mdi-calendar","hide-details":"","readonly":""},on:{"click:append":function($event){_vm.BirthReport.showFromDate = true}},model:{value:(_vm.BirthReport.fromDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "fromDate", $$v)},expression:"BirthReport.fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.BirthReport.showFromDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "showFromDate", $$v)},expression:"BirthReport.showFromDate"}},[_c('v-date-picker',{on:{"change":_vm.generateReport,"input":function($event){_vm.BirthReport.showFromDate = false}},model:{value:(_vm.BirthReport.fromDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "fromDate", $$v)},expression:"BirthReport.fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"label":_vm.getTranslation('To'),"append-icon":"mdi-calendar","hide-details":"","readonly":""},on:{"click:append":function($event){_vm.BirthReport.showToDate = true}},model:{value:(_vm.BirthReport.toDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "toDate", $$v)},expression:"BirthReport.toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.BirthReport.showToDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "showToDate", $$v)},expression:"BirthReport.showToDate"}},[_c('v-date-picker',{attrs:{"min":_vm.BirthReport.fromDate},on:{"change":_vm.generateReport,"input":function($event){_vm.BirthReport.showToDate = false}},model:{value:(_vm.BirthReport.toDate),callback:function ($$v) {_vm.$set(_vm.BirthReport, "toDate", $$v)},expression:"BirthReport.toDate"}})],1)],1)],1)],1)],1)],(_vm.BirthReport.loading)?_c('v-data-table',{staticClass:"dashboard-data-table my-5",attrs:{"headers":[],"items":[],"loading":true,"hide-default-footer":"","loading-text":"Loading... Please wait"}}):_vm._e(),_c('div',{staticClass:"printable printable-metadata"},[_c('h5',[_vm._v(_vm._s(_vm.getOrganizationName))]),_c('h5',[_vm._v(" Generated: "+_vm._s(_vm.$moment().local().format("MM-DD-YYYY HH:mm:ss"))+" ")]),_c('h5',[_vm._v("Report Year: "+_vm._s(_vm.BirthReport.year))]),_c('h5',[_vm._v("Report: Calf birth summary")])]),_c('div',{staticClass:"printable"},_vm._l((_vm.BirthReport.tableDataByHerd),function(table,$index){return _c('div',{key:$index,staticClass:"my-5"},[_vm._m(0,true),_c('v-data-table',{staticClass:"dashboard-data-table",attrs:{"headers":_vm.BirthReportHeaders,"items":table.data,"hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var options = ref.options;
var pagination = ref.pagination;
var updateOptions = ref.updateOptions;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"h6"},[_vm._v(_vm._s(table.herd))]),_c('v-data-footer',{staticClass:"ml-auto",attrs:{"items-per-page-text":_vm.getTranslation('animalsPages.itemsPerPage'),"options":options,"pagination":pagination},on:{"update:options":updateOptions}})],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{staticClass:"body-1",attrs:{"colspan":"2"}},[_c('strong',[_vm._v(" Totals ")])]),_vm._l((_vm.BirthReportHeaders.slice(
                  1,
                  _vm.BirthReportHeaders.length
                )),function(header,$index){return _c('th',{key:$index,staticClass:"body-1"},[_c('strong',[_vm._v(_vm._s(_vm.getTotalOf(table, header.value)))])])})],2)]},proxy:true}],null,true),model:{value:(table.selected),callback:function ($$v) {_vm.$set(table, "selected", $$v)},expression:"table.selected"}})],1)}),0)],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"printable printable-metadata"},[_c('br'),_c('br'),_c('br'),_c('br')])}]

export { render, staticRenderFns }