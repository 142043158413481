<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 334 20" xmlns="http://www.w3.org/2000/svg">
      <path
        :stroke="fill"
        d="M1.5 0V24.5H161M161 24.5V51.5M161 24.5H332.5V0"
        stroke-width="20"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
