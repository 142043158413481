<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <v-dialog
          id="dialogPasswordRecovery"
          max-width="400px"
          overlay="false"
          transition="dialog-transition"
          v-if="dialogPasswordRecovery.users.length > 0"
          v-model="dialogPasswordRecovery.show"
        >
          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <h4>Password</h4>
              <v-icon
                class="buttons"
                @click="dialogPasswordRecovery.show = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider class="mt-0"></v-divider>
            <v-card-text class="pb-2">
              <table>
                <tr>
                  <th>Username</th>
                  <td style="padding-left: 10px">
                    {{ dialogPasswordRecovery.users[0].username }}
                  </td>
                </tr>
                <tr>
                  <th>Password</th>
                  <td style="padding-left: 10px">
                    {{ dialogPasswordRecovery.users[0].password }}
                  </td>
                </tr>
              </table>
            </v-card-text>
            <v-divider class="mt-0"></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                v-if="dialogPasswordRecovery.users.length > 1"
                @click="showNextPassword"
                >Next</v-btn
              >
              <v-btn v-else @click="showNextPassword">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div>
          <table
            id="users"
            class="table table-striped table-bordered w-100"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <tr>
                <th class="responsive-control"></th>
                <th v-for="({ text }, index) in t.columnNames" :key="index">
                  {{ text }}
                </th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th class="responsive-control"></th>
                <th v-for="({ text }, index) in t.columnNames" :key="index">
                  {{ text }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <online-warning></online-warning>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TranslationMixin from "@/mixins/Translations";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Users",
  metaInfo: {
    title: "Users",
  },
  mixins: [TranslationMixin],
  data: () => ({
    dialogPasswordRecovery: {
      show: true,
      users: [],
    },
    disposables: [],
    editor: null,
    jsonStructureData: {
      users: {
        active: null,
        currentOrganization: null,
        id: null,
        locale: null,
        password: null,
        timezone: null,
        username: null,
      },
      people: {
        id: null,
        firstName: null,
        lastName: null,
        userId: null,
      },
      phones: {
        id: null,
        number: null,
        peopleId: null,
      },
      userOrganizations: {
        admin: null,
        createRight: null,
        deleted: 0,
        deleteRight: null,
        id: null,
        importRight: null,
        lastUpdated: null,
        mobileRight: null,
        organizationId: [],
        readRight: null,
        updateRight: null,
        userId: null,
      },
      comment: {
        id: null,
        commentValue: null,
        userId: null,
      },
      organizations: {
        id: [],
        name: null,
      },
    },
    table: null,
    tableData: [],
  }),
  computed: {
    ...mapGetters({
      getToken: "User/getToken",
    }),
    t: function () {
      return {
        columnNames: [
          { text: this.translate.organizationId[this.localeLang] },
          { text: this.translate.currentOrganization[this.localeLang] },
          { text: this.translate.organizationName[this.localeLang] },
          { text: this.translate.name[this.localeLang] },
          { text: this.translate.locale[this.localeLang] },
          { text: this.translate.timezone[this.localeLang] },
          { text: this.translate.emailPrompt[this.localeLang] },
          { text: this.translate.cellPhone[this.localeLang] },
          { text: this.translate.active[this.localeLang] },
          { text: this.translate.create[this.localeLang] },
          { text: this.translate.read[this.localeLang] },
          { text: this.translate.update[this.localeLang] },
          { text: this.translate.delete[this.localeLang] },
          { text: this.translate.mobile[this.localeLang] },
          { text: this.translate.import[this.localeLang] },
          { text: this.translate.admin[this.localeLang] },
        ],
      };
    },
  },
  mounted: function () {
    if (this.table) {
      this.table.destroy();
      this.table = null;
    }
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
    this.init();
    this.setDisposables();
  },
  beforeDestroy: function () {
    $(this.disposables.join(", ")).off();
    if (this.table) {
      this.table.destroy();
      this.table = null;
    }
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },
  methods: {
    init: function () {
      this.makeTable();
    },
    showNextPassword: function () {
      this.dialogPasswordRecovery.users.shift();
      if (this.dialogPasswordRecovery.users.length) return;

      this.dialogPasswordRecovery.show = false;
    },
    setDisposables: function () {
      this.disposables.push("#DTE_Field_locations-id");
      this.disposables.push("#DTE_Field_organizations-id");
      this.disposables.push("#users");
      this.disposables.push("button.close");
      this.disposables.push("input");
    },
    makeTable: async function () {
      const self = this;
      const { data: orgs } = await axios.get("/api/users/self/organizations", {
        params: {
          token: this.getToken,
        },
      });

      let editor = new $.fn.dataTable.Editor({
        ajax: function (method, url, d, successCallback, errorCallback) {
          var output = { data: [] };

          if (d.action === "create") {
            $.each(d.data, function (_key, value) {
              self.createRecord(value);
            });
          } else if (d.action === "edit") {
            // Update each edited item with the data submitted
            $.each(d.data, function (_id, value) {
              self.updateRecord(value);
            });
          }

          // Show Editor what has changed
          successCallback(output);
        },
        table: "#users",
        // ************************	 Editor definitions
        fields: [
          {
            name: "users_id",
            type: "hidden",
          },
          {
            name: "people_id",
            type: "hidden",
            def: function () {
              return self.$utils.guid();
            },
          },
          {
            name: "phones_id",
            type: "hidden",
            def: function () {
              return self.$utils.guid();
            },
          },
          {
            name: "userOrganizations_id",
            type: "hidden",
            def: function () {
              return self.$utils.guid();
            },
          },
          {
            name: "comment_id",
            type: "hidden",
            def: function () {
              return self.$utils.guid();
            },
          },
          {
            name: "users_currentOrganization",
            type: "hidden",
          },
          {
            name: "organizations_name",
            type: "hidden",
          },
          {
            name: "organizations_id",
            type: "hidden",
          },
          {
            type: "select",
            label: "Organization Name",
            name: "organizations_id_selector",
            multiple: true,
            options: orgs.organizations.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          },
          {
            label: "First Name",
            name: "people_firstName",
          },
          {
            label: "Last Name",
            name: "people_lastName",
          },
          {
            label: "Email (Used as Login)",
            name: "users_username",
          },
          {
            label: "Password",
            name: "users_password",
            type: "password",
          },
          {
            label: "Locale",
            name: "users_locale",
            type: "select",
            options: [
              { label: "", value: "" },
              { label: "English", value: "en_US" },
              { label: "Español (Spanish)", value: "es_ES" },
            ],
          },
          {
            label: "Time Zone",
            name: "users_timezone",
            type: "select2",
            options: moment.tz.names(),
          },
          {
            label: "Cell Phone Number",
            name: "phones_number",
          },
          {
            label: "Active",
            name: "users_active",
            type: "checkbox",
            def: 1,
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Admin",
            name: "userOrganizations_admin",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Create Right",
            name: "userOrganizations_createRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Read Right",
            name: "userOrganizations_readRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Update Right",
            name: "userOrganizations_updateRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Delete Right",
            name: "userOrganizations_deleteRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Mobile Right",
            name: "userOrganizations_mobileRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Import Right",
            name: "userOrganizations_importRight",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: 1 }],
          },
          {
            label: "Comment",
            name: "comment_commentValue",
            type: "textarea",
            attr: {
              maxlength: 250,
            },
          },
          {
            name: "userOrganizations_deleted",
            def: 0,
            type: "hidden",
          },
          {
            name: "userOrganizations_lastUpdated",
            def: moment().format("YYYY-MM-DD HH:mm:ss"),
            type: "hidden",
          },
        ],
      });

      // Setup - add a text input to each footer cell
      $("#users tfoot th")
        .not(".responsive-control")
        .not(".details-control")
        .each(function () {
          var title = $(this).text();
          $(this).html(
            '<input class="form-control form-control-sm  w-75 mx-auto" type="text" placeholder="Search ' +
              title +
              '" />'
          );
        });

      // Object that will contain the local state
      var responsiveToggle = {};

      // Create or update the todo localStorage entry
      if (localStorage.getItem("users_responsiveToggle")) {
        responsiveToggle = JSON.parse(
          localStorage.getItem("users_responsiveToggle")
        );
      } else {
        responsiveToggle = true;
      }

      let table = $("#users").DataTable({
        destroy: true,
        dom: "BRlfrtip",
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        autoWidth: false,
        stateSave: true,
        stateLoadParams: function (settings, data) {
          for (var i = 1; i < data.columns.length; i++) {
            $("th:nth-child(" + i + ') > input[type="text"]').val(
              data.columns[i - 1].search.search
            );
          }
        },
        mark: true,
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"],
        ],
        fixedHeader: {
          header: true,
          headerOffset: $(".v-toolbar__content").outerHeight(),
        },
        responsive: responsiveToggle,
        order: [
          [6, "desc"],
          [4, "asc"],
        ],
        serverSide: false,
        ajax: {
          url: `/api/data/users?token=${this.getToken}`,
        },
        initComplete: function () {
          // Store table.data() from /api/data/users

          self.tableData = Object.entries(table.data())
            .filter((elem) => Number.isInteger(+elem[0]))
            .map((elem) => elem[1]);

          $("#users tfoot th.dt-body-center").each(function () {
            $(this).empty();
          });
          self.$utils.applySearch(this);
          if (table.data().length === 0) {
            table.buttons().disable();
            editor.destroy();
          }
        },
        columns: [
          // responsive placeholder
          {
            orderable: false,
            searchable: false,
            data: null,
            defaultContent: "",
          },
          {
            data: "organizations_id",
            visible: false,
          },
          {
            data: "users_currentOrganization",
            visible: false,
          },
          { data: "organizations_name" },
          {
            data: null,
            render: function (data, type, row) {
              // Combine the first and last names into a single table field
              return data.people_firstName + " " + data.people_lastName;
            },
            editField: ["people_firstName", "people_lastName"],
          },
          { data: "users_locale" },
          { data: "users_timezone" },
          { data: "users_username" },
          { data: "phones_number" },
          {
            data: "users_active",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights-warning active-right" name="users_active" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_createRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights create-right" name="userOrganizations_createRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_readRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights read-right" name="userOrganizations_readRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_updateRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights update-right" name="userOrganizations_updateRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_deleteRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights delete-right" name="userOrganizations_deleteRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_mobileRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights mobile-right" name="userOrganizations_mobileRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_importRight",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights import-right" name="userOrganizations_importRight" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
          {
            data: "userOrganizations_admin",
            searchable: false,
            render: function (data, type, row) {
              if (type === "display") {
                var checked = data == 1 ? "checked" : "";
                return (
                  '<input type="checkbox" class="rights-warning admin-right" name="userOrganizations_admin" ' +
                  checked +
                  ">"
                );
              }
              return data;
            },
            className: "dt-body-center",
          },
        ],
        select: {
          style: "os",
          selector: "td:not(.dt-body-center)", // no row selection on checkbox columns
        },
        buttons: [
          {
            extend: "colvis",
            text: "Toggle",
            className: "btn mb-2 text-white",
          },
          {
            extend: "collection",
            text: "Select",
            autoClose: true,
            buttons: ["selectAll", "selectNone"],
            className: "btn mb-2 text-white",
          },
          {
            className: "btn mb-2 text-white",
            extend: "create",
            editor: editor,
            key: { altKey: true, key: "1" },
          },
          {
            className: "btn mb-2 text-white",
            extend: "edit",
            editor: editor,
            key: { altKey: true, key: "2" },
          },
          {
            className: "btn mb-2 text-white",
            text: this.translate["Password Recovery"][this.localeLang],
            action: function (e, dt, node, config) {
              // This feature is the best we can do until we revamp the user-email-password recovery system.
              const rows = self.table.rows({ selected: true }).data();
              if (rows.length == 0) return;
              if (
                !window.confirm(
                  rows.length == 1
                    ? "This is going to display a password in clear text as an alert. Proceed?"
                    : "This is going to display passwords in clear text as an alert. Proceed?"
                )
              )
                return;

              self.dialogPasswordRecovery.users = [];
              for (let i = 0; i < rows.length; i++) {
                const users = Object.keys(rows[i]).reduce((prev, current) => {
                  if (current.includes("users"))
                    prev[current.slice(6)] = rows[i][current];
                  return prev;
                }, {});
                self.dialogPasswordRecovery.users.push(users);
              }
              self.dialogPasswordRecovery.show = true;
            },
          },
          {
            className: "btn mb-2 text-white",
            text: "Reset Search",
            action: function (e, dt, node, config) {
              if (window.confirm("Reset search?")) {
                table.search("").columns().search("").draw();

                $("#users tfoot th input").each(function () {
                  this.value = "";
                });
              }
            },
          },
          {
            className: "btn mb-2 text-white",
            text: this.translate.tabletMode[responsiveToggle][this.localeLang],
            action: function (e, dt, node, config) {
              responsiveToggle = !responsiveToggle;
              localStorage.setItem(
                "users_responsiveToggle",
                JSON.stringify(responsiveToggle)
              );
              window.location = window.location;
            },
          },
          {
            className: "btn mb-2 text-white",
            extend: "collection",
            text: "Export",
            autoClose: true,
            buttons: [
              {
                extend: "copyHtml5",
                exportOptions: {
                  orthogonal: "export",
                  columns: [0, ":visible"],
                },
                text: "Copy",
              },
              {
                extend: "excelHtml5",
                exportOptions: {
                  orthogonal: "export",
                  columns: [0, ":visible"],
                },
                text: "Excel",
              },
              {
                extend: "csvHtml5",
                exportOptions: {
                  orthogonal: "export",
                  columns: [0, ":visible"],
                },
                text: "CSV",
              },
            ],
          },
        ],
      });
      table.on("column-reorder", function () {
        self.$utils.applySearch(table, true);
      });
      editor.on("preSubmit", function (e, o, action) {
        if (action !== "remove") {
          var username = this.field("users_username");
          var organizationIds = this.field("organizations_id_selector");
          var firstName = this.field("people_firstName");
          var lastName = this.field("people_lastName");
          var password = this.field("users_password");
          var locale = this.field("users_locale");
          var timezone = this.field("users_timezone");

          // Only validate user input values - different values indicate that
          // the end user has not entered a value
          if (!username.isMultiValue()) {
            if (!username.val()) {
              username.error("Required field");
            } else if (!self.validateEmail(username.val())) {
              username.error("Must be a valid email address");
            }

            if (!firstName.val()) {
              firstName.error("Required field");
            }
            if (!lastName.val()) {
              lastName.error("Required field");
            }
            if (!locale.val()) {
              locale.error("Required field");
            }
            if (!timezone.val()) {
              timezone.error("Required field");
            }

            if (organizationIds.val().length === 0) {
              organizationIds.error("Required field");
              $("#DTE_Field_organizations-id").trigger("updated");
            }

            // if (!password.val()) {
            //   password.error("Required field");
            // } else if (!validatePassword(password.val())) {
            //   password.error("Must be at least 8 characters long");
            // }
          }

          // If any error was reported, cancel the submission so it can be corrected
          // if (this.inError()) {
          //   return false;
          // }
        }
      });

      editor.on("initCreate", function (e, json, data) {
        editor.hide("users_active");
      });

      editor.on("initEdit", function (e, json, data) {
        editor.show("users_active");
      });

      editor.on("open", function (e, mode, action) {
        // Disable editing organizations
        var disabled = action === "edit" ? true : false;
        $("#DTE_Field_organizations-id")
          .prop("disabled", disabled)
          .trigger("updated");

        // Simple textarea character countdown from: http://geoffmuskett.com/really-simple-jquery-character-countdown-in-textarea/
        var maxLength = $("#DTE_Field_comment-commentValue").attr("maxlength");
        $("#DTE_Field_comment-commentValue").keyup(function () {
          var length = $(this).val().length;
          length = maxLength - length;
          editor
            .field("comment_commentValue")
            .message(length + " characters remaining");
        });

        // Add events to capture changes in organizations/locations
        if (action === "edit") {
          $("#DTE_Field_locations-id").on("change", function (evt, params) {
            var data = {};
            var change = Object.keys(params)[0];
            data.locationId = params[change];
            data.userId = editor.field("users_id").val();
            data.id = self.$utils.guid();
          });

          $("#DTE_Field_organizations-id").on("change", function (evt, params) {
            var data = {};
            var change = Object.keys(params)[0];
            data.organizationId = params[change];
            data.userId = editor.field("users_id").val();
            data.id = self.$utils.guid();

            // Check that type is string so we don't submit on non-user changes
            if (
              typeof data.organizationId === "string" ||
              data.organizationId instanceof String
            ) {
              $.post(
                "/herd-api/data/submitUserChange.cfm",
                {
                  change: JSON.stringify(change),
                  data: JSON.stringify(data),
                  table: "userOrganizations",
                },
                function (returnData) {
                  table.ajax.reload(null, false);
                }
              );
            }
          });
        }
      });

      editor.dependent(
        "userOrganizations_admin",
        function (val, data, callback) {
          var json;

          if (val === "1") {
            json = {
              values: {
                userOrganizations_createRight: 1,
                userOrganizations_readRight: 1,
                userOrganizations_updateRight: 1,
                userOrganizations_deleteRight: 1,
                userOrganizations_mobileRight: 1,
                userOrganizations_importRight: 1,
              },
              disable: [
                "userOrganizations_createRight",
                "userOrganizations_readRight",
                "userOrganizations_updateRight",
                "userOrganizations_deleteRight",
                "userOrganizations_mobileRight",
                "userOrganizations_importRight",
              ],
            };
          } else {
            json = {
              enable: [
                "userOrganizations_createRight",
                "userOrganizations_readRight",
                "userOrganizations_updateRight",
                "userOrganizations_deleteRight",
                "userOrganizations_mobileRight",
                "userOrganizations_importRight",
              ],
            };
          }
          return json;
        }
      );

      editor.dependent("users_active", function (val, data, callback) {
        var json;

        if (val !== "1") {
          json = {
            hide: [
              "userOrganizations_createRight",
              "userOrganizations_readRight",
              "userOrganizations_updateRight",
              "userOrganizations_deleteRight",
              "userOrganizations_mobileRight",
              "userOrganizations_importRight",
              "userOrganizations_admin",
            ],
          };
        } else {
          json = {
            show: [
              "userOrganizations_createRight",
              "userOrganizations_readRight",
              "userOrganizations_updateRight",
              "userOrganizations_deleteRight",
              "userOrganizations_mobileRight",
              "userOrganizations_importRight",
              "userOrganizations_admin",
            ],
          };
        }
        return json;
      });

      table.on("order", function (e, settings) {
        table
          .rows()
          .eq(0)
          .each(function (idx) {
            if (table.row(idx).data().users_active !== 1) {
              $(table.row(idx).node()).children("td").addClass("warning");
              $(table.row(idx).node())
                .find("input.rights, input.admin-right")
                .attr("disabled", true);
            } else if (table.row(idx).data().userOrganizations_admin === 1) {
              $(table.row(idx).node())
                .find("input.rights")
                .attr("disabled", true);
            }
          });
      });

      table.on(
        "responsive-display",
        function (e, datatable, row, showHide, update) {
          if (table.row(row.index()).data().users_active !== 1) {
            $('ul[data-dtr-index="' + row.index() + '"]')
              .find("input.rights, input.admin-right")
              .attr("disabled", true);
          } else if (
            table.row(row.index()).data().userOrganizations_admin === 1
          ) {
            $('ul[data-dtr-index="' + row.index() + '"]')
              .find("input.rights")
              .attr("disabled", true);
          }
        }
      );

      $("#users").on("change", "input.rights", function () {
        if ($(this).closest("tr").hasClass("child")) {
          editor
            .edit(
              table.row($(this).closest("tbody").find(".parent")).index(),
              false
            )
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0)
            .submit();
        } else {
          editor
            .edit($(this).closest("tr"), false)
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0)
            .submit();
        }
      });

      $("#users").on("change", "input.admin-right", function () {
        if ($(this).closest("tr").hasClass("child")) {
          editor
            .title(
              "<p><b style='color:red'>Warning:</b> Are you sure you want to update this user's admin rights?</p><p>(Admin rights are required to view this page and modify user information)</p>"
            )
            .buttons("Update")
            .edit(
              table.row($(this).closest("tbody").find(".parent")).index(),
              true
            )
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0);
        } else {
          editor
            .title(
              "<p><b style='color:red'>Warning:</b> Are you sure you want to update this user's admin rights?</p><p>(Admin rights are required to view this page and modify user information)</p>"
            )
            .buttons("Update")
            .edit($(this).closest("tr"), true)
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0);
        }

        $("button.close").click(function () {
          table.ajax.reload(null, false);
        });
      });

      $("#users").on("change", "input.active-right", function () {
        if ($(this).closest("tr").hasClass("child")) {
          editor
            .title(
              "<p><b style='color:red'>Warning:</b> Are you sure you want to update this user's 'active' status?</p><p>(While deactivated, a user cannot log in to the site or mobile app)</p>"
            )
            .buttons("Update")
            .edit(
              table.row($(this).closest("tbody").find(".parent")).index(),
              true
            )
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0);
        } else {
          editor
            .title(
              "<p><b style='color:red'>Warning:</b> Are you sure you want to update this user's 'active' status?</p><p>(While deactivated, a user cannot log in to the site or mobile app)</p>"
            )
            .buttons("Update")
            .edit($(this).closest("tr"), true)
            .set($(this).attr("name"), $(this).prop("checked") ? 1 : 0);
        }

        $("button.close").click(function () {
          table.ajax.reload(null, false);
        });
      });

      this.table = table;
      this.editor = editor;
    },
    validateEmail: function (sEmail) {
      // For use as a simple client-side check only
      var pattern = /^.+@.+\..+$/;
      return pattern.test(sEmail);
    },
    getFormData: function (rowSelected, type) {
      // When type is "update"
      // Get user organizations
      const allUserOrganizations =
        type === "update" &&
        this.tableData
          .filter((row) => row.users_id === rowSelected.users_id)
          .map((item) => item.organizations_id);
      // Filter new organizations
      // Get new user organizations
      const newUserOrganizations =
        type === "update" &&
        rowSelected.organizations_id_selector.filter(
          (orgId) => !allUserOrganizations.includes(orgId)
        );
      // Filter current organizations
      const currentOrganizations = type === "update" && [
        rowSelected.organizations_id,
        ...rowSelected.organizations_id_selector
          .filter((orgId) => allUserOrganizations.includes(orgId))
          .filter((orgId) => rowSelected.organizations_id !== orgId),
      ];
      const data = Object.assign({}, this.jsonStructureData);
      const userId =
        type === "update" ? rowSelected.users_id : this.$utils.guid();
      // Comment table
      data.comment.commentValue = rowSelected.comment_commentValue;
      data.comment.id =
        type === "update" ? rowSelected.comment_id : this.$utils.guid();
      data.comment.userId = userId;
      // People table
      data.people.firstName = rowSelected.people_firstName;
      data.people.id =
        type === "update" ? rowSelected.people_id : this.$utils.guid();
      data.people.lastName = rowSelected.people_lastName;
      data.people.userId = userId;
      // Phones table
      data.phones.id =
        type === "update" ? rowSelected.phones_id : this.$utils.guid();
      data.phones.number = rowSelected.phones_number;
      data.phones.peopleId = data.people.id;
      // Users Table
      data.users.active = rowSelected.users_active;
      data.users.currentOrganization =
        type === "update" && !!rowSelected.users_currentOrganization
          ? rowSelected.users_currentOrganization
          : rowSelected.organizations_id_selector[0];
      data.users.id = userId;
      data.users.locale = rowSelected.users_locale;
      data.users.password = rowSelected.users_password;
      data.users.timezone = rowSelected.users_timezone;
      data.users.username = rowSelected.users_username;
      // Organizations table
      data.organizations.id =
        type === "update"
          ? newUserOrganizations
          : rowSelected.organizations_id_selector;
      data.organizations.name = "";
      // User Organizations Table
      data.userOrganizations.admin =
        rowSelected.userOrganizations_admin == 1 ? 1 : 0;
      data.userOrganizations.createRight =
        rowSelected.userOrganizations_createRight == 1 ? 1 : 0;
      data.userOrganizations.deleteRight =
        rowSelected.userOrganizations_deleteRight == 1 ? 1 : 0;
      data.userOrganizations.id =
        type === "update"
          ? rowSelected.userOrganizations_id
          : this.$utils.guid();
      data.userOrganizations.importRight =
        rowSelected.userOrganizations_importRight == 1 ? 1 : 0;
      data.userOrganizations.lastUpdated = this.$moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.userOrganizations.mobileRight =
        rowSelected.userOrganizations_mobileRight == 1 ? 1 : 0;
      data.userOrganizations.organizationId =
        type === "update"
          ? currentOrganizations
          : rowSelected.organizations_id_selector;
      data.userOrganizations.readRight =
        rowSelected.userOrganizations_readRight == 1 ? 1 : 0;
      data.userOrganizations.updateRight =
        rowSelected.userOrganizations_updateRight == 1 ? 1 : 0;
      data.userOrganizations.userId = userId;

      return data;
    },
    createRecord: function (data) {
      const body = this.getFormData(data, "create");

      axios
        .post("/api/data/create-user", body, {
          params: {
            token: this.getToken,
          },
        })
        .then(async () => {
          this.$notify({
            group: "forms",
            text: "User created",
            title: "Success",
            type: "success",
          });
          this.table.ajax.reload();
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            text: "User can not be created. Please check your data.",
            title: "Error",
            type: "error",
          });
          console.log(e);
        });
    },
    updateRecord: function (data) {
      const body = this.getFormData(data, "update");
      axios
        .post("/api/data/update-user", body, {
          params: {
            token: this.getToken,
          },
        })
        .then(async () => {
          this.$notify({
            group: "forms",
            ignoreDuplicates: true,
            text: "User updated",
            title: "Success",
            type: "success",
          });
          this.table.ajax.reload();
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            ignoreDuplicates: true,
            text: "User can not be updated. Please check your data.",
            title: "Error",
            type: "error",
          });
          console.log(e);
        });
    },
  },
};
</script>
