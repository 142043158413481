<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 35 35">
      <path
        :stroke="fill"
        d="M4.80078 22.1002L4.80078 23.5002C4.80078 25.8198 6.68119 27.7002 9.00078 27.7002L23.0008 27.7002C25.3204 27.7002 27.2008 25.8198 27.2008 23.5002L27.2008 22.1002M21.6008 10.9002L16.0008 5.3002M16.0008 5.3002L10.4008 10.9002M16.0008 5.3002L16.0008 22.1002"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
