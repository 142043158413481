<template>
  <div class="pa-4">To do</div>
</template>


<script>
export default {
  name: "DashboardFeeder",

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>


<style scoped>
</style>
