<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        :stroke="fill"
        d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 10.3995 10.3995 12.75 7.5 12.75C4.60051 12.75 2.25 10.3995 2.25 7.5C2.25 4.60051 4.60051 2.25 7.5 2.25C10.3995 2.25 12.75 4.60051 12.75 7.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
