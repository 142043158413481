<template>
  <div>
    <in-page-menu :activeBadge="animalsProcessedCountForGroup" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <cull-alert :status="cullAlertStatus"></cull-alert>
      <v-col cols="6">
        <summary-container headerTitle="CIDR Removal">
          <template #prepend-header>
            <v-menu
              :close-on-content-click="false"
              min-width="auto"
              offset-y
              transition="scale-transition"
              v-model="showCalendar"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  @click:append="showCalendar = true"
                  append-icon="mdi-calendar"
                  dense
                  hide-details
                  outlined
                  readonly
                  style="font-size: 1.3rem"
                  v-bind="attrs"
                  v-model="form.date"
                  v-on="on"
                >
                  <template #label>
                    <p class="ma-0" style="font-size: 1.7rem">
                      {{
                        getTranslation("pregCheckPage.pregnancyTestEntry.date")
                      }}
                    </p>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @input="showCalendar = false"
                v-model="form.date"
              ></v-date-picker>
            </v-menu>
          </template>
          <template #append-header>
            <v-form v-model="isValid.group">
              <v-text-field
                :error="formErrorState.group"
                :rules="[rules.required]"
                autofocus
                class="mediumFont"
                clearable
                dense
                hide-details
                outlined
                v-model="form.group"
              >
                <template #label>
                  <div style="font-size: 1.9rem">
                    {{
                      getTranslation("pregCheckPage.pregnancyTestEntry.group")
                    }}
                  </div>
                </template>
              </v-text-field>
            </v-form>
          </template>
          <template #content>
            <div>
              <search-animal
                :allowedStatuses="['alive', 'cull', 'missing', 'sick']"
                :resetFields="herdConnect.resetFields"
                :streamEidOptions="streamEidOptions"
                :streamVisualOptions="streamVisualOptions"
                @search-result="getAnimalResult"
                alloherdConnectnder="female"
                cardStyle
                class="mb-6"
                includeTagsSummary
              />
              <v-form v-model="isValid.form">
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="d-flex mb-2">
                      <v-text-field
                        :background-color="
                          form.eid &&
                          options.eids.some((eid) => eid === form.eid)
                            ? 'orange'
                            : ''
                        "
                        :error="formErrorState.eid"
                        :rules="[rules.EID]"
                        clearable
                        hide-details
                        outlined
                        style="font-size: 1.1rem; color: black"
                        v-model="form.eid"
                      >
                        <template #label>
                          <p class="ma-0 biggerFont">
                            {{ getTranslation("New EID") }}
                          </p>
                        </template>
                      </v-text-field>
                      <div
                        class="d-flex align-center"
                        v-if="form.eid != null && isValidEID(form.eid)"
                      >
                        <span
                          class="ml-2"
                          style="font-size: 1rem; font-weight: bold"
                        >
                          {{ form.eid.substr(11) }}
                        </span>
                      </div>
                    </div>
                    <v-text-field
                      :append-icon="
                        herdConnect.results.visual === form.visual
                          ? 'mdi-cellphone-link'
                          : ''
                      "
                      :error="formErrorState.visual"
                      class="mediumFont mb-2"
                      clearable
                      hide-details
                      outlined
                      v-model="form.visual"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{ getTranslation("New Visual") }}
                        </p>
                      </template>
                    </v-text-field>
                    <v-select
                      :items="options.tagColors"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mediumFont mb-2"
                      clearable
                      hide-details
                      item-text="color"
                      outlined
                      return-object
                      v-model="form.tagColor"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.tagColor"
                            )
                          }}
                        </p>
                      </template>
                    </v-select>
                    <v-select
                      :items="getOrganizationItems"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mediumFont mb-2"
                      clearable
                      hide-details
                      item-text="name"
                      outlined
                      return-object
                      v-model="form.receivingRanch"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{ getTranslation("Receiving Ranch") }}
                        </p>
                      </template>
                    </v-select>
                    <v-select
                      :background-color="
                        options.herds.length == 0 ? 'warning' : ''
                      "
                      :disabled="options.herds.length == 0"
                      :items="options.herds"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mediumFont mb-2"
                      clearable
                      hide-details
                      item-text="name"
                      outlined
                      return-object
                      v-model="form.herd"
                    >
                      <template #label>
                        <span
                          style="color: black"
                          v-if="options.herds.length == 0"
                        >
                          Herds are not defined.
                        </span>
                        <p class="ma-0 biggerFont" v-else>
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.newHerd"
                            )
                          }}
                        </p>
                      </template>
                    </v-select>
                    <v-select
                      :items="options.breeds"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mediumFont mb-2"
                      clearable
                      hide-details
                      item-text="breed"
                      outlined
                      return-object
                      v-model="form.breed"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.breed"
                            )
                          }}
                        </p>
                      </template>
                    </v-select>
                    <v-text-field
                      :error="!form.technician || !form.technician.trim()"
                      :rules="[rules.required]"
                      class="mediumFont"
                      clearable
                      hide-details
                      outlined
                      v-model="form.technician"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.technician"
                            )
                          }}
                        </p>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div>
                      <h4 class="mb-0">
                        {{
                          getTranslation(
                            "pregCheckPage.pregnancyTestEntry.cullStatus"
                          )
                        }}
                      </h4>
                      <v-radio-group
                        class="mt-0"
                        hide-details
                        v-model="form.cull"
                      >
                        <v-radio :value="false" class="w-50">
                          <template #label>
                            <h5 class="ma-0 mt-2">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.no"
                                )
                              }}
                            </h5>
                          </template>
                        </v-radio>
                        <v-radio :value="true" class="w-50">
                          <template #label>
                            <h5 class="ma-0 mt-2">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.yes"
                                )
                              }}
                            </h5>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <v-checkbox
                        :disabled="
                          !form.gnrhReceived && options.vaccinations.length == 0
                        "
                        class="mt-0 mb-4"
                        hide-details
                        v-model="form.gnrhReceived"
                      >
                        <template #label>
                          <h5 class="ma-0 mt-2">
                            {{ getTranslation("GnRH Received") }}
                          </h5>
                        </template>
                      </v-checkbox>
                      <v-text-field
                        :append-icon="
                          herdConnect.results.weight != null &&
                          herdConnect.results.weight == form.weight
                            ? 'mdi-cellphone-link'
                            : ''
                        "
                        :error="formErrorState.weight"
                        :rules="[rules.decimal]"
                        class="mediumFont mb-2"
                        clearable
                        hide-details
                        outlined
                        v-model="form.weight"
                      >
                        <template #label>
                          <p class="ma-0 biggerFont">
                            {{ getTranslation("weight") }}
                          </p>
                        </template>
                      </v-text-field>
                      <h4 class="mt-4 mb-0">
                        {{ getTranslation("CIDR Status") }}
                      </h4>
                      <v-radio-group
                        :error="!form.fertilizationStatus"
                        :rules="[rules.required]"
                        class="mt-0"
                        hide-details
                        v-model="form.fertilizationStatus"
                      >
                        <v-radio
                          :key="status.id"
                          :value="status"
                          class="w-50"
                          v-for="status in options.fertilizationStatuses"
                        >
                          <template #label>
                            <h5 class="ma-0 mt-2">
                              {{ status.name }}
                            </h5>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <template>
                      <v-row
                        :key="index"
                        class="ma-0"
                        v-for="(treatment, index) in form.treatments"
                      >
                        <v-col cols="8" class="pt-0 px-0">
                          <v-select
                            :background-color="
                              options.vaccinations.length == 0 ? 'warning' : ''
                            "
                            :disabled="options.vaccinations.length == 0"
                            :error="treatment.validation.errorVaccination"
                            :items="options.vaccinations"
                            :menu-props="{ offsetY: true, closeOnClick: true }"
                            class="mediumFont"
                            clearable
                            hide-details
                            item-text="name"
                            outlined
                            return-object
                            v-model="treatment.vaccination"
                          >
                            <template #label>
                              <span
                                v-if="options.vaccinations.length == 0"
                                style="color: black"
                              >
                                Medications are not defined.
                              </span>
                              <p class="ma-0 biggerFont">
                                {{
                                  getTranslation(
                                    "pregCheckPage.pregnancyTestEntry.vaccination"
                                  )
                                }}
                              </p>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="3" class="pt-0 px-0">
                          <v-text-field
                            :error="treatment.validation.errorDose"
                            :rules="[rules.decimal]"
                            class="mediumFont"
                            clearable
                            hide-details
                            outlined
                            v-model="treatment.dose"
                          >
                            <template #label>
                              <p class="ma-0 biggerFont">
                                {{
                                  getTranslation(
                                    "pregCheckPage.pregnancyTestEntry.dose"
                                  )
                                }}
                              </p>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          class="pt-0 px-0"
                          cols="1"
                          style="position: relative; z-index: 8"
                        >
                          <div v-if="index == 0">
                            <v-btn
                              @click="addTreatment"
                              block
                              color="success"
                              height="56"
                              small
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              @click="removeTreatment(index)"
                              block
                              color="error"
                              height="56"
                              small
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <v-textarea
                      auto-grow
                      class="mediumFont mb-2"
                      clearable
                      counter
                      dense
                      hide-details
                      maxlength="200"
                      outlined
                      rows="3"
                      v-model="form.comment"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.comments"
                            )
                          }}
                        </p>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-btn
                      :color="
                        form.fertilizationStatus &&
                        form.fertilizationStatus.id === 'skipped'
                          ? 'warning'
                          : 'primary'
                      "
                      :disabled="!validForm"
                      @click="saveAnimal"
                      block
                      large
                    >
                      <template v-if="!animalIsNew">
                        {{ getLabelTranslation("save") }}
                      </template>
                      <template v-else>
                        {{ getTranslation("Save New") }}
                      </template>
                      <span v-if="form.fertilizationStatus">
                        -
                        {{
                          getTranslation(
                            `enums.fertilizationStatusRemoval.${form.fertilizationStatus.id}`
                          )
                        }}
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col class="pt-0" style="max-width: 250px">
                    <v-btn
                      @click="resetFields"
                      block
                      color="primary"
                      large
                      outlined
                    >
                      {{
                        getTranslation("pregCheckPage.pregnancyTestEntry.reset")
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider />
                <herd-connect
                  :streamLF="streamEID"
                  :streamUHF="streamEID"
                  :streamVisual="streamVisual"
                  :streamWeight="streamWeight"
                />
              </v-form>
            </div>
          </template>
        </summary-container>
      </v-col>
      <v-col cols="6">
        <!-- By waiting for summaryTable to have data, we effectively wait for previous group number to be restored as well, which allows us to smartly select the current group where possible -->
        <group-summary
          :fertilizations="summaryTable.data"
          :groupNumber="form.group"
          v-if="summaryTable.data.length > 0"
        ></group-summary>
        <animal-information
          :animal="animal"
          :items="animalInformation"
          fertilizationsImplant
        >
        </animal-information>
      </v-col>
      <v-col>
        <summary-container headerTitle="Summary">
          <template #content>
            <v-data-table
              :headers="summaryTableHeaders"
              :items-per-page="5"
              :items="summaryTable.data"
              :loading="summaryTable.loading"
              :sort-by="['createdOn']"
              :sort-desc="[true]"
              class="elevation-1"
            >
              <template #item.tagValues="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.guid },
                  }"
                  class="text-h6 text-none"
                  target="_blank"
                >
                  {{ item.tagValues }}
                </router-link>
              </template>
              <template #item.gnrhReceived="{ item }">
                {{
                  item.gnrhReceived
                    ? getTranslation("Yes")
                    : getTranslation("No")
                }}
              </template>
              <template #item.statusId="{ item }">
                {{
                  item.statusId
                    ? getTranslation(
                        `enums.fertilizationStatusRemoval.${item.statusId}`
                      )
                    : "?"
                }}
              </template>
              <template #item.createdOn="{ item }">
                {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
              </template>
            </v-data-table>
          </template>
        </summary-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import { mapGetters } from "vuex";
import GroupStatistics from "@/components/fertilization/GroupStatistics.vue";
import SummaryContainer from "@/components/SummaryContainer.vue";
import TranslationMixin from "../mixins/Translations";
export default {
  name: "CIDR",
  metaInfo: {
    title: "CIDR Removal",
  },
  components: {
    "group-summary": GroupStatistics,
    "summary-container": SummaryContainer,
  },
  mixins: [TranslationMixin],
  data() {
    return {
      animal: null,
      animalInformation: [
        { labelType: "Receiving Ranch", value: "receivingRanch" },
        { labelType: "Herd", value: "herd" },
        { labelType: "visual", value: "visualTagValues" },
        { labelType: "color", value: "color" },
        { labelType: "breed", value: "breed" },
        { labelType: "birthDate", value: "birthDate" },
        { labelType: "Age", value: "age" },
        { labelType: "birthWeight", value: "birthWeight" },
        { labelType: "weight", value: "currentWeight" },
        { labelType: "sire", value: "sireTags" },
      ],
      cullAlertStatus: "alive",
      form: {
        breed: null,
        comment: null,
        cull: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        eid: null,
        fertilizationStatus: null,
        gnrhReceived: false,
        group: null,
        herd: null,
        receivingRanch: null,
        tagColor: null,
        technician: null,
        treatments: [],
        visual: null,
        weight: null,
      },
      herdConnect: {
        resetFields: false,
        results: {
          eid: null,
          visual: null,
          weight: null,
        },
      },
      herdMeta: null,
      isValid: {
        form: false,
        group: false,
      },
      options: {
        fertilizationStatuses: [],
        eids: [],
        herds: [],
        tagColors: [],
        vaccinations: [],
        visuals: [],
      },
      rules: {
        decimal: (number) => {
          if (number == null || number == "") return true;
          // Decimal number with point or just number
          return /^\d+$|^\d+\.\d+$/gm.test(number);
        },
        EID: (value) =>
          // convert null to string and numbers to string and strings to string
          /^$|^\d{15}$/.test(
            (value == null ? "" : value + "")
              .replace(/-/g, "")
              .replace(/ /g, "")
          ) || "Invalid ID",
        required: (value) => !!value || "Required",
      },
      showCalendar: false,
      summaryTable: {
        data: [],
        loading: false,
      },
      title: "cidr-removal",
    };
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
    }),
    animalIsNew: function () {
      return !this.animal || this.animal.animalIsNew;
    },
    animalsProcessedCountForGroup: function () {
      return this.summaryTable.data.filter(
        ({ groupNumber }) => groupNumber === this.form.group
      ).length;
    },
    formErrorState: function () {
      const eidEmpty = this.form.eid == null || this.form.eid.trim() == "";
      const visualEmpty =
        this.form.visual == null || this.form.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.eid);

      // NOTE: Add more fields with similar logic if you need it
      // Error state does not expect an error message instead only expects a boolean value
      return {
        cull: this.form.cull != undefined ? null : "Cull is required.",
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? true
            : false,
        fertilizationStatus: !this.form.fertilizationStatus,
        group: this.form.group == null || this.form.group == "",
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? true
            : false,
        weight:
          this.form.weight != null && !this.rules.decimal(this.form.weight)
            ? "Weight must be empty or numeric"
            : null,
      };
    },
    summaryTableHeaders: function () {
      return [
        {
          text: this.getTranslation("calfWeanPage.summary.tagValues"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("technician"),
          value: "technician",
        },
        {
          text: this.getTranslation("fields.receivingRanch"),
          value: "receivingRanch",
        },
        {
          text: this.getTranslation("GnRH Received"),
          value: "gnrhReceived",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.weight"),
          value: "weight",
        },
        {
          text: this.getTranslation("CIDR Status"),
          value: "statusId",
        },
        {
          text: this.getTranslation("fields.groupNumber"),
          value: "groupNumber",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.createdOn"),
          value: "createdOn",
        },
      ];
    },
    validForm: function () {
      return this.isValid.form && this.isValid.group;
    },
  },
  watch: {
    "form.group": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("form.group"),
          JSON.stringify(newVal)
        );

        // Ensures that data used by animalsProcessedCountForGroup is correct
        this.updateSummaryTable();
      },
    },
    "form.technician": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("form.technician"),
          JSON.stringify(newVal)
        );
      },
    },
    "form.treatments": {
      deep: true,
      handler: function (arr) {
        arr.forEach((treatment, index) => {
          if (
            treatment.vaccination != null &&
            treatment.vaccination != "" &&
            (treatment.dose == null || treatment.dose == "")
          )
            this.form.treatments[index].validation.errorDose = true;
          else if (
            treatment.dose != null &&
            treatment.dose != "" &&
            (treatment.vaccination == null || treatment.vaccination == "")
          )
            this.form.treatments[index].validation.errorVaccination = true;
          else {
            this.form.treatments[index].validation.errorVaccination = false;
            this.form.treatments[index].validation.errorDose = false;
          }
        });
      },
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect.results"),
          JSON.stringify(newVal)
        );

        if (newVal.eid) EventBus.$emit("eid-from-herd-connect", newVal.eid);
        if (newVal.visual)
          EventBus.$emit("visual-from-herd-connect", newVal.visual);
        if (+newVal.weight) this.form.weight = "" + newVal.weight;
      },
    },
  },
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("eid-not-pre-existing");
    EventBus.$off("eid-pre-existing");
    EventBus.$off("visual-not-pre-existing");
    EventBus.$off("visual-pre-existing");
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    await this.fillSelectOptions();
    this.updateSummaryTable();

    EventBus.$tabEvent.on("consumed", ({ type, value }) => {
      if (type === "eid" && this.form.eid === value) this.form.eid = null;
      if (type === "visual" && this.form.visual === value)
        this.form.visual = null;
      if (type === "weight" && this.form.weight === value)
        this.form.weight = null;

      if (type === "eid" && this.herdConnect.results.eid === value)
        this.herdConnect.results.eid = null;
      if (type === "visual" && this.herdConnect.results.visual === value)
        this.herdConnect.results.visual = null;
      if (type === "weight" && this.herdConnect.results.weight === value)
        this.herdConnect.results.weight = null;
    });

    EventBus.$on("eid-not-pre-existing", (newEID) => (this.form.eid = newEID));
    EventBus.$on(
      "eid-pre-existing",
      (eid) => (this.form.eid = this.form.eid === eid ? "" : this.form.eid)
    );
    EventBus.$on(
      "visual-not-pre-existing",
      (visual) => (this.form.visual = visual)
    );
    EventBus.$on(
      "visual-pre-existing",
      (visual) =>
        (this.form.visual = this.form.visual === visual ? "" : this.form.visual)
    );

    this.options.tagColors = this.getEnumOptions("tagColors").map((item) => {
      return {
        id: item.value,
        color: item.label,
      };
    });
    if (
      this.form.tagColor &&
      !this.options.tagColors.some((item) => item.id == this.form.tagColor.id)
    ) {
      // Clear out current setting because it is no longer valid
      this.form.tagColor = null;
    }

    // Restore previous valus
    {
      const previousFormGroup = JSON.parse(
        localStorage.getItem(this.orgUserScoping("form.group"))
      );

      if (previousFormGroup && this.form.group !== previousFormGroup)
        this.form.group = previousFormGroup;

      const previousFormTechnician = JSON.parse(
        localStorage.getItem(this.orgUserScoping("form.technician"))
      );

      if (
        previousFormTechnician &&
        this.form.technician !== previousFormTechnician
      )
        this.form.technician = previousFormTechnician;
    }
  },
  methods: {
    isValidTreatment: function (vaccination, dose) {
      return (
        vaccination != null && vaccination != "" && dose != null && dose != ""
      );
    },
    fillSelectOptions: async function () {
      const herds = await this.herdMeta.getMetaHerdsAsync(true, true, true);
      if (herds instanceof Array) this.options.herds = herds;

      this.options.breeds = this.getEnumOptions("breeds").map((item) => {
        return {
          id: item.value,
          breed: item.label,
        };
      });

      this.options.fertilizationStatuses = this.getEnumOptions(
        "fertilizationStatusRemoval"
      ).map((item) => {
        return {
          id: item.value,
          name: item.label,
        };
      });

      const vaccinations = await this.herdMeta.getMetaVaccinationsAsync(
        true,
        false,
        false
      );
      if (vaccinations instanceof Array)
        this.options.vaccinations = vaccinations;
      else this.options.vaccinations = [];

      if (this.options.vaccinations.length > 0) this.addTreatment();
    },
    getDefaultTreatment: function () {
      return {
        dose: null,
        vaccination: null,
        validation: {
          errorDose: false,
          errorVaccination: false,
        },
      };
    },
    addTreatment: function () {
      this.form.treatments.push(this.getDefaultTreatment());
    },
    removeTreatment: function (index) {
      this.form.treatments = this.form.treatments.filter(
        (treatment, idx) => idx != index
      );
    },
    saveAnimal: function () {
      let animalIsNew = false;
      if (!this.animal) {
        this.animal = HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);
        animalIsNew = true;
      }
      this.addDataAsync(this.animal, animalIsNew)
        .catch((e) => {
          this.$notify({
            group: "forms",
            text: e.message,
            title: "Error",
            type: "error",
          });

          console.error(e);
        })
        .then((animal) => {
          this.$notify({
            duration: 10000,
            group: "forms",
            text: [
              animalIsNew
                ? `Animal created (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`
                : `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
            ].join("<br />"),
            title: "Success",
            type: "success",
          });
          this.resetFields(null, true);
          this.updateSummaryTable();
        });
    },
    updateSummaryTable: async function () {
      this.summaryTable.loading = true;
      this.summaryTable.data =
        await this.herdMeta.pouches.organization.fertilizationsAfterCutoff(
          "removal",
          7
        );
      this.summaryTable.loading = false;
    },
    resetFields: function (_e, followsSave) {
      if (!followsSave && !confirm("Reset?")) return;

      if (followsSave) {
        if (this.form.eid)
          EventBus.$tabEvent.emit("consumed", {
            type: "eid",
            value: this.form.eid,
          });
        if (this.form.visual)
          EventBus.$tabEvent.emit("consumed", {
            type: "visual",
            value: this.form.visual,
          });
        if (this.form.weight)
          EventBus.$tabEvent.emit("consumed", {
            type: "weight",
            value: this.form.weight,
          });

        if (this.herdConnect.results.eid)
          EventBus.$tabEvent.emit("consumed", {
            type: "eid",
            value: this.herdConnect.results.eid,
          });
        if (this.herdConnect.results.visual)
          EventBus.$tabEvent.emit("consumed", {
            type: "visual",
            value: this.herdConnect.results.visual,
          });
        if (this.herdConnect.results.weight)
          EventBus.$tabEvent.emit("consumed", {
            type: "weight",
            value: this.herdConnect.results.weight,
          });
      }

      this.form = {
        breed: followsSave ? this.form.breed : null,
        comment: null,
        cull: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        eid: null,
        fertilizationStatus: followsSave ? this.form.fertilizationStatus : null,
        gnrhReceived: false,
        group: this.form.group,
        herd: followsSave ? this.form.herd : null,
        receivingRanch: followsSave ? this.form.receivingRanch : null,
        tagColor: null,
        technician: this.form.technician,
        treatments: followsSave
          ? this.form.treatments
          : this.form.treatments.map((_) => this.getDefaultTreatment()),
        visual: null,
        weight: null,
      };

      this.animal = null;

      this.herdConnect.resetFields = !this.herdConnect.resetFields;
      this.herdConnect.results = {
        eid: null,
        visual: null,
        weight: null,
      };
    },
    addDataAsync: async function (animal, isNew = false) {
      const promises = [];
      const timeRecordedISO = this.getTimeRecorded();
      const dateIsToday = this.determineIfDateIsToday();

      if (dateIsToday) {
        const coords = await this.$utils.getGeoLocation();
        if (coords.latitude || coords.longitude)
          promises.push(
            animal.modify("geopoints", null, "id", coords.id, false, true, {
              ...coords,
              reason: "CIDR Removal",
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            })
          );
      }

      if (isNew) {
        promises.push(
          animal.modify("genders", null, null, null, false, true, {
            gender: "female",
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );
      }

      if (this.form.breed)
        promises.push(
          animal.modify("breeds", null, null, null, false, true, {
            breed: this.form.breed.breed,
            breedId: this.form.breed.id,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      const commentId = this.form.comment ? this.$utils.guid() : null;

      if (commentId)
        promises.push(
          animal.modify("comments", null, "id", commentId, false, true, {
            comment: this.form.comment.trim(),
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      if (this.form.cull)
        promises.push(
          animal.modify("status", null, "status", "cull", false, true, {
            userId: this.$userID,
          })
        );

      // Store eid
      if (this.form.eid) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.eid.trim(),
          timeRecorded: timeRecordedISO,
          type: "eid",
          userId: this.$userID,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      // Store visual
      if ((this.form.visual || "").trim()) {
        const tag = {
          status: "active",
          tagColor: this.form.tagColor ? this.form.tagColor.color : null,
          tagId: null,
          tagValue: this.form.visual.trim(),
          timeRecorded: timeRecordedISO,
          type: "visual",
          userId: this.$userID,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      if (this.form.herd) {
        promises.push(
          animal.modify("herds", null, null, null, false, true, {
            herd: this.form.herd.name,
            herdId: this.form.herd.id,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );
      }

      if (this.form.group)
        promises.push(
          animal.modify("movements", null, null, null, false, true, {
            groupNumber: this.form.group,
            locationId: null,
            reason: "CIDR Removal",
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      if (this.form.receivingRanch)
        promises.push(
          animal.modify("receivingRanches", null, null, null, false, true, {
            organizationId: this.form.receivingRanch.id,
            receivingRanch: this.form.receivingRanch.name,
            receivingRanchId: this.form.receivingRanch.id,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      // Store treatment
      const treatmentId = this.$utils.guid();
      this.form.treatments
        .filter((treatment) =>
          this.isValidTreatment(treatment.vaccination, treatment.dose)
        )
        .forEach(({ vaccination, dose }) => {
          promises.push(
            animal.modify("treatments", null, null, null, false, true, {
              actualDose: Number.isNaN(dose) ? +dose : dose,
              commentId,
              id: treatmentId,
              userId: this.$userID,
              vaccinationsId: vaccination.id,
              vaccinationTime: timeRecordedISO,
            })
          );
        });

      // Store weight
      if (this.form.weight)
        promises.push(
          animal.modify("weights", null, null, null, false, true, {
            commentId,
            groupNumber: this.form.group,
            source: "fertilizations",
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
            weight: this.form.weight.trim(),
          })
        );

      promises.push(
        animal.modify("fertilizations", null, null, null, false, true, {
          commentId,
          cull: this.form.cull,
          gnrhReceived: !!this.form.gnrhReceived,
          groupNumber: this.form.group,
          status: this.form.fertilizationStatus.name,
          statusId: this.form.fertilizationStatus.id,
          technician: this.form.technician,
          timeRecorded: timeRecordedISO,
          type: "removal",
          userId: this.$userID,
          weight: this.form.weight,
        })
      );
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then(() => {
            $.when(animal.save())
              .fail(reject)
              .done(() => resolve(animal));
          })
          .catch((e) => reject(new Error(`addDataAsync - Error: ${e}`)));
      });
    },
    getTimeRecorded: function () {
      let timeRecordedISO = this.getDate();
      const dateIsToday = this.determineIfDateIsToday();
      if (dateIsToday) {
        timeRecordedISO = new Date().toISOString();
      }
      return timeRecordedISO;
    },
    determineIfDateIsToday: function () {
      return moment().diff(this.getDate(), "days") === 0;
    },
    getDate: function () {
      return moment(this.form.date).toISOString();
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    getAnimalResult: function ({ animal }) {
      this.animal = animal;

      this.cullAlertStatus =
        !!this.animal &&
        this.animal.doc &&
        this.animal.doc.derived &&
        this.animal.doc.derived.summaries &&
        this.animal.doc.derived.summaries.main.status;
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    streamEID(eid) {
      this.herdConnect.results = {
        eid,
        visual: this.herdConnect.results.visual,
        weight: this.herdConnect.results.weight,
      };
    },
    streamEidOptions(eids) {
      this.options.eids = eids;
    },
    streamVisual(visual) {
      this.herdConnect.results = {
        eid: this.herdConnect.results.eid,
        visual,
        weight: this.herdConnect.results.weight,
      };
    },
    streamVisualOptions(visuals) {
      this.options.visuals = visuals;
    },
    streamWeight(weight) {
      this.herdConnect.results = {
        eid: this.herdConnect.results.eid,
        visual: this.herdConnect.results.visual,
        weight,
      };
    },
  },
};
</script>
<style scoped>
.custom-main-tag {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}
.custom-main-tag:hover {
  background-color: #007bff18;
  border-radius: 8px;
}
.darkInputText {
  color: white;
}
.biggerFont {
  font-size: 1.7rem;
}
.mediumFont {
  font-size: 1.5rem;
  color: black;
}
</style>