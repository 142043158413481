<template>
  <svg viewBox="0 0 20 20">
    <image :href="img" :height="dimension" :width="dimension" />
  </svg>
</template>
<script>
export default {
  computed: {
    img() {
      const dark =
        this.$parent.themeableProvide.isDark || this.$vuetify.theme.dark;
      return dark
        ? require("@/assets/icons/comment.png")
        : require("@/assets/icons/commentDark.png");
    },
    dimension() {
      return 20;
    },
  },
};
</script>