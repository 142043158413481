<template>
  <div class="my-2">
    <div class="g-row align-center mb-2">
      <h4 class="mr-2 mb-0 title-style">Family</h4>
      <export-button
        :disabled="Object.values(details).some((v) => !v)"
        :filename="`Family_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :items="[details]"
        buttonFormat="icon"
      />
    </div>
    <div class="g-row">
      <div class="g-col">
        <!-- Dam -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("dam") }}</p>
          <v-text-field
            :value="details.damTags"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: { id: details.damId },
            }"
            class="mb-0 animal-link"
            target="_blank"
            v-if="details.damId"
          >
            {{ getLabelTranslation("view") }}
          </router-link>
        </div>

        <!-- Adopted Dam -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("adoptDam") }}</p>
          <v-text-field
            :value="details.adoptDamTags"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: { id: details.adoptDamId },
            }"
            class="mb-0 animal-link"
            target="_blank"
            v-if="details.adoptDamId"
          >
            {{ getLabelTranslation("view") }}
          </router-link>
        </div>

        <!-- Sire -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("sire") }}</p>
          <v-text-field
            :value="details.sireTags"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: { id: details.sireId },
            }"
            class="mb-0 animal-link"
            target="_blank"
            v-if="details.sireId"
          >
            {{ getLabelTranslation("view") }}
          </router-link>
        </div>

        <!-- Cover Sire -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("coverSire") }}</p>
          <v-text-field
            :value="details.coverSireTags"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: { id: details.coverSireId },
            }"
            class="mb-0 animal-link"
            target="_blank"
            v-if="details.coverSireId"
          >
            {{ getLabelTranslation("view") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "FamilyDetails",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    animal: null,
    broadcast: null,
    details: {},
    herdMeta: null,
    pouches: null,
  }),
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    try {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getFamilyDetails();
    },
    getFamilyDetails: function () {
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then((result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.details = { ...summaries.family[0] } || {};
        })
        .catch((e) => {
          throw e;
        });
    },
  },
};
</script>
<style scoped>
>>> .v-input {
  max-width: 280px;
}
</style>
<style lang="scss" scoped>
.v-data-table__wrapper > table {
  tbody > tr > td {
    min-width: 120px;
    max-width: 170px;
  }
}
.subtitle-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px !important;
}

.animal-link {
  color: #877e76 !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  width: fit-content;
}

.animal-link:hover {
  text-decoration: underline;
}
</style>