<template>
  <div>
    <!-- Error -->
    <v-dialog
      max-width="200px"
      persistent
      scrollable
      transition="dialog-transition"
      v-model="showErrorDialog"
    >
      <v-card>
        <v-card-title
          class="d-flex justify-center align-center"
          style="background-color: #ef5350"
        >
          <h4 class="ma-0 white--text">Error</h4>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="$emit('input', false)" color="primary" text>
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ErrorDialog",
  props: {
    showErrorDialog: Boolean,
  },
  model: {
    prop: "showErrorDialog",
    event: "input",
  },
};
</script>
