<template>
  <div id="closeoutPage">
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div v-if="!!penReportsList">
          <table style="margin-top: 20px">
            <tr>
              <td>
                <h5 :title="`~${new Date().toISOString()}`">
                  Date:
                  {{
                    $utils.renderValueAs(
                      new Date().toISOString(),
                      "datetime",
                      false
                    )
                  }}
                </h5>
              </td>
              <td rowspan="2">
                <a
                  class="btn btn-primary white--text"
                  tabindex="0"
                  style="margin-left: 20px"
                  :class="{
                    disabled: !penReportsList,
                    'btn-danger': !penReportsList,
                    'btn-success': !!penReportsList,
                  }"
                  @click="exportToCSVprep"
                >
                  {{ t.closeout.exportToCSV }}
                </a>
              </td>
            </tr>
          </table>

          <!--- Animals still in a location --->
          <div
            :key="penReport.guid"
            v-for="penReport in penReportsList.penReports"
            class="modal fade"
            :id="penReport.guid"
            tabindex="-1"
            role="dialog"
            aria-labelledby="attributesLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-scrollable"
              role="document"
              style="min-width: 1500px"
            >
              <div
                class="modal-content"
                :class="{ 'bg-secondary': $vuetify.theme.dark }"
              >
                <div class="modal-header">
                  <h4 class="modal-title">
                    {{ penReport.location.name }}
                  </h4>
                </div>
                <div class="modal-body">
                  <table
                    class="table table-striped table-bordered dataTable"
                    :class="{
                      'table-dark-mode': $vuetify.theme.dark,
                      'table-dark': $vuetify.theme.dark,
                    }"
                    :id="`${penReport.guid}-animalsTable`"
                  >
                    <thead>
                      <tr>
                        <th>View</th>
                        <th>Tag Values</th>
                        <th>Head Days</th>
                        <th>Birth Date</th>
                        <th>Final Status</th>
                        <th>First Weight Date</th>
                        <th>Transaction Date</th>
                        <th>Purchase Price</th>
                        <th>Purchase Weight</th>
                        <th>Pay Weight</th>
                        <th>$ Meds Total (Processing)</th>
                        <th>$ Meds (Doctoring)</th>
                        <th>Income</th>
                        <th>Dates Shipped</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="index"
                        v-for="(
                          penReportAnimal, index
                        ) in penReport.penReportAnimals"
                      >
                        <td>
                          <router-link
                            :to="{
                              name: 'AnimalDetails',
                              query: {
                                id: penReportAnimal.guid,
                              },
                            }"
                            class="text-h6 text-none"
                            target="_blank"
                          >
                            View Animal
                          </router-link>
                        </td>
                        <td>{{ penReportAnimal.flatSummary.tagValues }}</td>
                        <td>{{ penReportAnimal.headDays }}</td>
                        <td>
                          {{
                            penReportAnimal.flatSummary.birthDate
                              ? momento(penReportAnimal.flatSummary.birthDate)
                              : ""
                          }}
                        </td>
                        <td>
                          {{ penReportAnimal.finalStatus
                          }}<span v-if="penReportAnimal.isDead()">
                            ({{
                              penReportAnimal.deathDate
                                ? penReportAnimal.flatSummary.deathDate.format(
                                    "YYYY/MM/DD"
                                  )
                                : "?"
                            }})</span
                          >
                        </td>
                        <td>
                          {{
                            penReportAnimal.flatSummary.firstWeightDate
                              ? momento(
                                  penReportAnimal.flatSummary.firstWeightDate
                                )
                              : null
                          }}<span
                            v-if="penReportAnimal.flatSummary.firstWeightDate"
                          >
                            ({{
                              penReportAnimal.flatSummary.firstWeight
                            }})</span
                          >
                        </td>
                        <td>
                          {{
                            penReportAnimal.purchaseTransactionDate
                              ? momento(penReportAnimal.purchaseTransactionDate)
                              : null
                          }}
                        </td>
                        <td>{{ penReportAnimal.purchasePrice.toFixed(2) }}</td>
                        <td>{{ penReportAnimal.purchaseWeight }}</td>
                        <td>{{ penReportAnimal.payWeight }}</td>
                        <td>
                          {{ penReportAnimal.totalMedsProcessing().toFixed(2) }}
                        </td>
                        <td>
                          {{ penReportAnimal.totalMedsDoctoring().toFixed(2) }}
                        </td>
                        <td>
                          {{ (+penReportAnimal.totalEventIncome).toFixed(2) }}
                        </td>

                        <td>
                          <div
                            :key="index"
                            v-for="(saleOrPurchaseDates, index) in [
                              {
                                transactionType: 'Purchase',
                                dates: penReportAnimal.purchaseDates,
                              },
                              {
                                transactionType: 'Sale',
                                dates: penReportAnimal.saleDates,
                              },
                            ]"
                            v-if="saleOrPurchaseDates.dates.length"
                          >
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: {
                                  id: penReportAnimal.guid,
                                },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                              ><strong>{{
                                saleOrPurchaseDates.transactionType
                              }}</strong></router-link
                            >
                            <div v-if="saleOrPurchaseDates.dates.length > 1">
                              <ul>
                                <li
                                  :key="index"
                                  v-for="(
                                    date, index
                                  ) in saleOrPurchaseDates.dates"
                                >
                                  {{ date.format("YYYY-DD-MM") }}
                                </li>
                              </ul>
                            </div>
                            <div v-else>
                              {{
                                saleOrPurchaseDates.dates[0].format(
                                  "YYYY-DD-MM"
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary white--text"
                    data-dismiss="modal"
                  >
                    {{ t.closeButton }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--- Full Report --->
          <div
            :key="index"
            v-for="(penReport, index) in penReportsList.penReports"
            v-if="penReport.fullReport"
            class="modal fade printable"
            :id="`${penReport.guid}-fullReport`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="attributesLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-scrollable"
              role="document"
              style="width: 800px"
            >
              <div
                class="modal-content"
                :class="{ 'bg-secondary': $vuetify.theme.dark }"
              >
                <div class="modal-header">
                  <h4 class="modal-title">
                    {{ penReport.location.name }}
                    <a
                      class="btn btn-success white--text"
                      @click="penReport.exportToCSVfullReport()"
                    >
                      {{ t.closeout.exportToCSV }}
                    </a>
                  </h4>
                </div>
                <div class="modal-body">
                  <table
                    class="table table-striped table-bordered dataTable"
                    :class="{
                      'table-dark-mode': $vuetify.theme.dark,
                      'table-dark': $vuetify.theme.dark,
                    }"
                  >
                    <thead>
                      <tr>
                        <th colspan="3">Criteria</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Pen</th>
                        <td>{{ penReport.statistics.criteria.pen() }}</td>
                      </tr>
                      <tr>
                        <th>Purchase/Sale Date(s)</th>
                        <td>
                          <table
                            class="table-striped table-bordered dataTable"
                            :class="{
                              'table-dark-mode': $vuetify.theme.dark,
                              'table-dark': $vuetify.theme.dark,
                            }"
                          >
                            <thead>
                              <th>Date</th>
                              <th>Purchases</th>
                              <th>Sales</th>
                            </thead>
                            <tbody>
                              <tr
                                :key="index"
                                v-for="(
                                  pasDates, index
                                ) in penReport.statistics.criteria.purchaseAndSaleDates()"
                                v-if="
                                  index <= 2 ||
                                  penReport.showMore('purchaseAndSaleDates') ||
                                  penReport.statistics.criteria.purchaseAndSaleDates()
                                    .length < 2
                                "
                              >
                                <td>{{ pasDates.date }}</td>
                                <td>{{ pasDates.purchases }}</td>
                                <td>{{ pasDates.sales }}</td>
                              </tr>
                            </tbody>
                            <tfoot
                              v-if="
                                penReport.statistics.criteria.purchaseAndSaleDates()
                                  .length > 3
                              "
                            >
                              <tr>
                                <td colspan="3">
                                  <a
                                    v-if="
                                      penReport.showMore('purchaseAndSaleDates')
                                    "
                                    @click="
                                      penReport.showMore(
                                        'purchaseAndSaleDates',
                                        false
                                      )
                                    "
                                    class="clickable"
                                  >
                                    less
                                  </a>
                                  <a
                                    v-else
                                    @click="
                                      penReport.showMore(
                                        'purchaseAndSaleDates',
                                        true
                                      )
                                    "
                                    class="clickable"
                                  >
                                    more
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          <span
                            v-if="
                              penReport.statistics.criteria.animalsWithMultiplePurchases()
                                .length
                            "
                            class="badge danger"
                            >{{
                              penReport.statistics.criteria.animalsWithMultiplePurchases()
                                .length
                            }}
                            animals have more than 1 sale transaction
                            recorded!</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <th>Sex</th>
                        <td>
                          <div
                            v-if="penReport.statistics.criteria.sex().length"
                          >
                            <div
                              v-if="
                                penReport.statistics.criteria.sex().length > 1
                              "
                            >
                              <ul>
                                <li
                                  :key="index"
                                  v-for="(
                                    sex, index
                                  ) in penReport.statistics.criteria.sex()"
                                >
                                  {{ sex }}
                                </li>
                              </ul>
                            </div>
                            <div v-else>
                              {{ penReport.statistics.criteria.sex()[0] }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <table
                    class="table table-striped table-bordered dataTable"
                    :class="{
                      'table-dark-mode': $vuetify.theme.dark,
                      'table-dark': $vuetify.theme.dark,
                    }"
                  >
                    <thead>
                      <tr>
                        <th colspan="5">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Head Count</th>
                        <td></td>
                        <td></td>
                        <td>{{ penReport.statistics.values.headCount() }}</td>
                        <td>per lot</td>
                      </tr>
                      <tr>
                        <th>Avg. Hd Days</th>
                        <td>{{ Math.floor(penReport.averageHeadDays()) }}</td>
                        <td>per hd.</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Pay Weight</th>
                        <td>
                          {{
                            penReport.statistics.values.payWeight().toFixed(2)
                          }}
                        </td>
                        <td>per hd.</td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.values.payWeight()",
                              2
                            )
                          }}
                        </td>
                        <td>per lot</td>
                      </tr>
                      <tr>
                        <th>Gain</th>
                        <td>
                          {{
                            penReport.statistics.perHead(
                              "statistics.values.gain()",
                              2
                            )
                          }}
                        </td>
                        <td>per hd.</td>
                        <td>
                          {{ penReport.statistics.values.gain().toFixed(2) }}
                        </td>
                        <td>per lot</td>
                      </tr>
                      <tr>
                        <th>ADG</th>
                        <td>
                          {{
                            penReport.statistics.perHead(
                              "statistics.values.averageDailyGain()",
                              2
                            )
                          }}
                        </td>
                        <td>per hd.</td>
                        <td>
                          {{
                            penReport.statistics.values
                              .averageDailyGain()
                              .toFixed(2)
                          }}
                        </td>
                        <td>per lot</td>
                      </tr>
                      <tr>
                        <th>Alives</th>
                        <td>
                          {{
                            penReport.statistics.percent(
                              "statistics.values.liveCount()",
                              2
                            )
                          }}
                        </td>
                        <td>%</td>
                        <td>{{ penReport.statistics.values.liveCount() }}</td>
                        <td>per lot</td>
                      </tr>
                      <tr>
                        <th>
                          Deads<br /><span style="font-size: smaller">
                            Includes dead / stolen / sold / inactive / eater /
                            harvested
                          </span>
                        </th>
                        <td>
                          {{
                            penReport.statistics.percent(
                              "statistics.values.deadCount()",
                              2
                            )
                          }}
                        </td>
                        <td>%</td>
                        <td>{{ penReport.statistics.values.deadCount() }}</td>
                        <td>per lot</td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <table
                    class="table table-striped table-bordered dataTable"
                    :class="{
                      'table-dark-mode': $vuetify.theme.dark,
                      'table-dark': $vuetify.theme.dark,
                    }"
                  >
                    <thead>
                      <tr>
                        <th colspan="5">Incomes</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th>Avg. Event Income</th>
                        <td>
                          {{
                            penReport.statistics.incomes
                              .averageEventIncome()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.incomes.averageEventIncome()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Hedge Profit</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.incomes.hedgeProfit !==
                                null &&
                              !(
                                penReport.statistics.incomes.hedgeProfit + ''
                              ).match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.incomes.hedgeProfit
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.incomes.hedgeProfit",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Misc</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.incomes.miscellaneous !==
                                null &&
                              !(penReport.statistics.incomes.miscellaneous + '')
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.incomes.miscellaneous
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.incomes.miscellaneous",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Total</th>
                        <td>
                          {{
                            penReport.statistics.incomes
                              .totalIncome()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.incomes.totalIncome()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                    </tfoot>
                  </table>

                  <br />
                  <table
                    class="table table-striped table-bordered dataTable"
                    :class="{
                      'table-dark-mode': $vuetify.theme.dark,
                      'table-dark': $vuetify.theme.dark,
                    }"
                  >
                    <thead>
                      <tr>
                        <th colspan="5">Expenses</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          @click="
                            toggles.showTurnoutBreakdown =
                              !toggles.showTurnoutBreakdown
                          "
                          style="cursor: pointer"
                        >
                          Turnout
                          <a v-if="!toggles.showTurnoutBreakdown">&#xBB;</a>
                          <a v-else>&#8628;</a>
                        </th>
                        <td v-if="!toggles.showTurnoutBreakdown">
                          {{
                            penReport.statistics.expenses.turnout().toFixed(2)
                          }}
                        </td>
                        <td v-else style="background-color: lightgray">
                          <table
                            class="table table-striped table-bordered dataTable"
                            :class="{
                              'table-dark-mode': $vuetify.theme.dark,
                              'table-dark': $vuetify.theme.dark,
                            }"
                          >
                            <tbody>
                              <tr>
                                <th>Purchase Price</th>
                                <td>
                                  {{
                                    penReport
                                      .totalAveragePurchasePricePerHead()
                                      .toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Processing Cost</th>
                                <td>
                                  {{
                                    (
                                      penReport.totalMedsProcessing() /
                                      penReport.totalAnimals()
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Doctoring Cost</th>
                                <td>
                                  {{
                                    (
                                      penReport.totalMedsDoctoring() /
                                      penReport.totalAnimals()
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Commission</th>
                                <td>
                                  {{
                                    (
                                      penReport.statistics.expenses.totalCommission() /
                                      penReport.totalAnimals()
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>In-bound Freight</th>
                                <td>
                                  {{
                                    (
                                      penReport.statistics.expenses.totalInBoundFreight() /
                                      penReport.totalAnimals()
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th>
                                  {{
                                    penReport.statistics.expenses
                                      .turnout()
                                      .toFixed(2)
                                  }}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </td>
                        <td><i>per hd.</i></td>
                        <td v-if="!toggles.showTurnoutBreakdown">
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.turnout()",
                              2
                            )
                          }}
                        </td>
                        <td v-else style="background-color: lightgray">
                          <table
                            class="table table-striped table-bordered dataTable"
                            :class="{
                              'table-dark-mode': $vuetify.theme.dark,
                              'table-dark': $vuetify.theme.dark,
                            }"
                          >
                            <tbody>
                              <tr>
                                <th>Purchase Price</th>
                                <td>
                                  {{
                                    penReport.statistics.perLot(
                                      "statistics.values.totalAveragePurchasePricePerHead()",
                                      2
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Processing Cost</th>
                                <td>
                                  {{
                                    penReport.totalMedsProcessing().toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Doctoring Cost</th>
                                <td>
                                  {{
                                    penReport.totalMedsDoctoring().toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Commission</th>
                                <td>
                                  {{
                                    penReport.statistics.expenses
                                      .totalCommission()
                                      .toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>In-bound Freight</th>
                                <td>
                                  {{
                                    penReport.statistics.expenses
                                      .totalInBoundFreight()
                                      .toFixed(2)
                                  }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th>
                                  {{
                                    penReport.statistics.perLot(
                                      "statistics.expenses.turnout()",
                                      2
                                    )
                                  }}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Overhead per day</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.expenses.overheadPerDay !==
                                null &&
                              !(
                                penReport.statistics.expenses.overheadPerDay +
                                ''
                              )
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.expenses.overheadPerDay
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.overheadPerDay",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Overhead</th>
                        <td>
                          {{
                            penReport.statistics.expenses.overhead().toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.overhead()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Death Loss</th>
                        <td>
                          {{
                            penReport.statistics.expenses.deathLoss().toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.deathLoss()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Doctoring Cost</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .doctoringCost()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.doctoringCost()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Feed Cost</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.expenses.feedCost !== null &&
                              !(penReport.statistics.expenses.feedCost + '')
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.expenses.feedCost
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.feedCost",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Cost of Gain</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .costOfGain()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.costOfGain()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Cost of Gain CWT</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .costOfGainCWT()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per CWT</i></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Hedge Loss</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.expenses.hedgeLoss !==
                                null &&
                              !(penReport.statistics.expenses.hedgeLoss + '')
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.expenses.hedgeLoss
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.hedgeLoss",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Total Cost @ Ranch</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .totalCostAtRanch()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.totalCostAtRanch()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Freight Rate Adjust</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.expenses
                                .freightRateAdjust !== null &&
                              !(
                                penReport.statistics.expenses
                                  .freightRateAdjust + ''
                              )
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.expenses.freightRateAdjust
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per CWT</i></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Freight to Yard</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .freightRateToYard()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.freightRateToYard()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>Miscellaneous</th>
                        <td
                          :style="{
                            'background-color':
                              penReport.statistics.expenses.miscellaneous !==
                                null &&
                              !(
                                penReport.statistics.expenses.miscellaneous + ''
                              )
                                .trim()
                                .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                                ? 'red'
                                : 'inherit',
                          }"
                        >
                          <input
                            type="text"
                            :class="{
                              'bg-dark': $vuetify.theme.dark,
                              'dark-input': $vuetify.theme.dark,
                            }"
                            v-model.trim="
                              penReport.statistics.expenses.miscellaneous
                            "
                            placeholder="0"
                          />
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.miscellaneous",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Total Delivered Cost</th>
                        <td>
                          {{
                            penReport.statistics.expenses
                              .totalDeliveredCost()
                              .toFixed(2)
                          }}
                        </td>
                        <td><i>per hd.</i></td>
                        <td>
                          {{
                            penReport.statistics.perLot(
                              "statistics.expenses.totalDeliveredCost()",
                              2
                            )
                          }}
                        </td>
                        <td><i>per lot</i></td>
                      </tr>
                      <tr>
                        <th>CWT Cost</th>
                        <td>
                          {{
                            penReport.statistics.expenses.CWTcost().toFixed(2)
                          }}
                        </td>
                        <td><i>per CWT</i></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary white--text"
                    data-dismiss="modal"
                  >
                    {{ t.closeButton }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <table
            class="table table-striped table-bordered dataTable"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
            id="closeoutTable"
          >
            <thead>
              <tr>
                <th colspan="2">Pen</th>
                <th>Total Animals</th>
                <th>Alive</th>
                <th>Dead</th>
                <th>Sold</th>
                <th>Avg. Head Days</th>
                <th>Purchase<br />Price <i>Per CWT</i></th>
                <th>Avg. Purchase<br />Price <i>Per Head</i></th>
                <th>Purchase<br />Wt. <i>Per Head</i></th>
                <th>$ Meds<br />(Processing)</th>
                <th>$ Meds<br />(Doctoring)</th>
                <th>Cost of Feed</th>
              </tr>

              <tr>
                <td colspan="2"><strong>Total</strong></td>
                <td>
                  <strong>{{ penReportsList.sum("totalAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalAliveAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalDeadAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalSoldAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{
                    Math.floor(penReportsList.sum("averageHeadDays"))
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalPurchasePricePerCWT").toFixed(2)
                  }}</strong>
                  <span class="badge badge-danger">{{
                    penReportsList.sum(
                      "countOfAnimalsWithNonPositivePrice",
                      null
                    )
                  }}</span>
                </td>
                <td>
                  <strong>
                    {{
                      penReportsList
                        .sum("totalAveragePurchasePricePerHead")
                        .toFixed(2)
                    }}</strong
                  >
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalPurchaseWeightPerHead").toFixed(2)
                  }}</strong>
                  <span class="badge badge-danger">{{
                    penReportsList.sum(
                      "countOfAnimalsWithNonPositiveWeight",
                      null
                    )
                  }}</span>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalMedsProcessing").toFixed(2)
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalMedsDoctoring").toFixed(2)
                  }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalFeedCost") }}</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(penReport, index) in penReportsList.penReports"
                :key="index"
              >
                <td>
                  <i>
                    {{ penReport.location.name }}
                    <span v-if="exposeGuids"
                      >({{ penReport.location.id }})</span
                    >
                  </i>
                </td>
                <td>
                  <a
                    @click="penReport.showFullReport()"
                    class="btn btn-primary white--text"
                    :class="{ disabled: !penReport.totalAnimals() }"
                    style="white-space: nowrap"
                  >
                    {{ t.closeout.fullReport }}
                  </a>
                </td>
                <td>
                  <span v-if="penReport.totalAnimals()">
                    <a
                      @click="penReport.showAnimals()"
                      class="blue--text"
                      style="cursor: pointer; text-decoration: underline"
                      >{{ penReport.totalAnimals() }}</a
                    >
                  </span>
                  <span v-else> 0 </span>
                </td>
                <td>
                  <span v-if="penReport.totalAliveAnimals()">
                    <a
                      @click="penReport.showAnimals()"
                      class="blue--text"
                      style="cursor: pointer; text-decoration: underline"
                      >{{ penReport.totalAliveAnimals() }}</a
                    >
                  </span>
                  <span v-else> 0 </span>
                </td>
                <td>
                  <span v-if="penReport.totalDeadAnimals()">
                    <a
                      @click="penReport.showAnimals()"
                      class="blue--text"
                      style="cursor: pointer; text-decoration: underline"
                      >{{ penReport.totalDeadAnimals() }}</a
                    >
                  </span>
                  <span v-else> 0 </span>
                </td>
                <td>
                  <span v-if="penReport.totalSoldAnimals()">
                    <a
                      @click="penReport.showAnimals()"
                      class="blue--text"
                      style="cursor: pointer; text-decoration: underline"
                      >{{ penReport.totalSoldAnimals() }}</a
                    >
                  </span>
                  <span v-else> 0 </span>
                </td>
                <td>{{ Math.floor(penReport.averageHeadDays()) }}</td>
                <td>
                  {{ penReport.totalPurchasePricePerCWT().toFixed(2) }}
                  <span class="badge badge-danger">{{
                    penReport.countOfAnimalsWithNonPositivePrice()
                  }}</span>
                </td>
                <td>
                  {{ penReport.totalAveragePurchasePricePerHead().toFixed(2) }}
                </td>
                <td>
                  {{ penReport.totalPurchaseWeightPerHead().toFixed(2) }}
                  <span class="badge badge-danger">{{
                    penReport.countOfAnimalsWithNonPositiveWeight()
                  }}</span>
                </td>
                <td>{{ penReport.totalMedsProcessing().toFixed(2) }}</td>
                <td>{{ penReport.totalMedsDoctoring().toFixed(2) }}</td>
                <td
                  :style="{
                    'background-color':
                      penReport.statistics.expenses.feedCost !== null &&
                      !(penReport.statistics.expenses.feedCost + '')
                        .trim()
                        .match(/(^$)|(^[-+]?\s*\d+(\.\d+)?$)/gi)
                        ? 'red'
                        : 'inherit',
                  }"
                >
                  <input
                    type="text"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                      'dark-input': $vuetify.theme.dark,
                    }"
                    v-model.trim="penReport.statistics.expenses.feedCost"
                    placeholder="0"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"><strong>Total</strong></td>
                <td>
                  <strong>{{ penReportsList.sum("totalAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalAliveAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalDeadAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalSoldAnimals") }}</strong>
                </td>
                <td>
                  <strong>{{
                    Math.floor(penReportsList.sum("averageHeadDays"))
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalPurchasePricePerCWT").toFixed(2)
                  }}</strong>
                  <span class="badge badge-danger">{{
                    penReportsList.sum(
                      "countOfAnimalsWithNonPositivePrice",
                      null
                    )
                  }}</span>
                </td>
                <td>
                  <strong>{{
                    penReportsList
                      .sum("totalAveragePurchasePricePerHead")
                      .toFixed(2)
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalPurchaseWeightPerHead").toFixed(2)
                  }}</strong>
                  <span class="badge badge-danger">{{
                    penReportsList.sum(
                      "countOfAnimalsWithNonPositiveWeight",
                      null
                    )
                  }}</span>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalMedsProcessing").toFixed(2)
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    penReportsList.sum("totalMedsDoctoring").toFixed(2)
                  }}</strong>
                </td>
                <td>
                  <strong>{{ penReportsList.sum("totalFeedCost") }}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
          <v-col cols="12">
            <h5>{{ t.legend }}</h5>
            <v-divider></v-divider>
          </v-col>
          <table
            class="table table-striped table-bordered dataTable"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <th>{{ t.closeout.symbol }}</th>
              <th>{{ t.closeout.definition }}</th>
            </thead>
            <tbody>
              <tr>
                <td><span class="badge badge-danger">1</span></td>
                <td>{{ t.closeout.sampleDefinition }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
input {
  border: 1px solid #ccc;
}
</style>
<script>
import Vue from "vue";
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "Closeout",
  metaInfo: {
    title: "Closeout",
  },
  mixins: [TranslationMixin],
  data: () => ({
    table: null,
    dates: {
      end: moment().format("YYYY-MM-DD"),
      start: moment().startOf("month").format("YYYY-MM-DD"),
    },
    herdMeta: null,
    moment: moment(),
    penReportsList: null,
    pouches: null,
    toggles: {
      showExperimental: false, // Not currently in use
      showTurnoutBreakdown: true,
    },
  }),
  created: function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    this.herdMeta.loaded
      .always(() => {
        console.log("Loaded");
      })
      .fail(() => {
        Vue.notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "There was a opening the database. Are you still logged in?",
        });
      })
      .done(() => {
        this.generateReport();
      });
  },
  beforeDestroy: function () {
    $("#closeoutTable").off();
    if (this.table != null) this.table.destroy();
  },
  computed: {
    sales: function () {
      return [
        { transactionType: "Purchase", dates: penReportAnimal.purchaseDates },
        { transactionType: "Sale", dates: penReportAnimal.saleDates },
      ];
    },
    exposeGuids: function () {
      // Must be manually set
      return localStorage.getItem("exposeGuids") == "true";
    },
    t: function () {
      return {
        closeButton: this.translate.closeButton[this.localeLang],
        closeout: {
          definition: this.translate.closeout.definition[this.localeLang],
          endDate: this.translate.closeout.endDate[this.localeLang],
          exportToCSV: this.translate.closeout.exportToCSV[this.localeLang],
          fullReport: this.translate.closeout.fullReport[this.localeLang],
          sampleDefinition:
            this.translate.closeout.sampleDefinition[this.localeLang],
          startDate: this.translate.closeout.startDate[this.localeLang],
          symbol: this.translate.closeout.symbol[this.localeLang],
        },
        legend: this.translate.fileETL.legend[this.localeLang],
      };
    },
  },
  methods: {
    momento: function (param) {
      return moment(param).format("YYYY-DD-MM");
    },
    showFullReport: function (report) {
      setTimeout(() => {
        $(`#${report.guid}-fullReport.modal`).modal();
        // .on("hidden.bs.modal", function () {
        //   $("#app").removeClass("non-printable");
        // });
      }, 200);
      $("#app").addClass("non-printable");
    },
    exportToCSVprep: function () {
      if (!this.penReportsList) return;
      this.penReportsList.exportToCSV();
    },
    generateReport: function () {
      /*
       * In the case that the user chooses to generate a new report while
       * one is already running, we can just reopen the database
       * Assumption(s): This page continues to generate reports using
       * read-only transactions
       */
      // !FIXME: When using websql, we had a way to stop generating previous reports. Should we do the same with pouchdb version?

      this.penReportsList = null;

      // Needs to wait a tick to allow Vue to refresh to display loading indicator
      this.pouches.organization
        .query("local_views/penSummariesAndSightings")
        .then((results) => {
          const penSummariesAndSightings = results.rows
            .map((row) => row.value)
            .filter((item) => {
              // Sightings are ordered from greater timeRecorded to the lower
              // timeRecorded
              let lastSighting;
              const currentDate = moment().format("YYYY-MM-DD");
              if (!!item.sightings.length) lastSighting = item.sightings[0];
              if (!lastSighting || !lastSighting.timeRecorded)
                lastSighting = { timeRecorded: item.createdOn };

              // Movements
              let lastMovement;
              if (!!item.movements.length)
                lastMovement = item.movements[item.movements.length - 1];
              if (!lastMovement || !lastMovement.timeRecorded)
                lastMovement = { timeRecorded: item.createdOn };
              return (
                moment(lastSighting.timeRecorded).format("YYYY-MM-DD") <=
                  currentDate ||
                moment(lastMovement.timeRecorded).format("YYYY-MM-DD") <=
                  currentDate
              );
            });
          this.penReportsList = new PenReportsList(
            penSummariesAndSightings,
            {
              end: moment().format("YYYY-MM-DD"),
              start: moment().format("YYYY-MM-DD"),
            },
            this.herdMeta
          );

          this.penReportsList.loaded
            .always(() => {
              console.log("Pen Reports Loaded");
            })
            .fail(() => {
              Vue.notify({
                group: "forms",
                type: "danger",
                title: "Error",
                text: "There was a problem crunching numbers. Are you still logged in?",
              });
              console.log("There was a problem crunching numbers.");
            })
            .done(() => {
              $("#closeoutTable table").dataTable().fnDestroy();

              setTimeout(() => {
                // Needs to wait a tick to allow Vue to refresh first
                this.table = $("#closeoutTable").DataTable({
                  destroy: true,
                  language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search",
                  },
                  paging: false,
                  columns: [
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    { searchable: true, orderable: true },
                    // Cost of feed is not sortable (datables has trouble seeing it)
                    { searchable: false, orderable: false },
                  ],
                });

                Vue.notify({
                  group: "forms",
                  type: "success",
                  title: "Success",
                  text: "Report is complete.",
                });
              }, 1);
            });
        })
        .catch((e) => {
          console.error(e);
          this.$notify({
            group: "forms",
            text: "There was a problem identifying locations. Are you still logged in?",
            title: "Error",
            type: "error",
          });
          console.log(
            "There was a problem identifying locations. Are you still logged in?"
          );
        });
    },
  },
};
</script>
