<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div style="margin-top: 2rem">
          <h1>Tutorial 1: Fast Herd Setup</h1>

          <video width="640" height="480" controls="">
            <source
              src="/videos/02082021 fast herd setup.mp4"
              type="video/mp4"
            />

            Your browser does not support the video tag.
          </video>

          <h1>Tutorial 2: Import Setup</h1>
          <video width="640" height="480" controls="">
            <source src="/videos/Import Setup - Part 1.mp4" type="video/mp4" />

            Your browser does not support the video tag.
          </video>

          <h1>Tutorial 3: Importing</h1>
          <video width="640" height="480" controls="">
            <source src="/videos/Importing - Part 2.mp4" type="video/mp4" />

            Your browser does not support the video tag.
          </video>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "FAQ",
  },
};
</script>