var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('summary-container',{attrs:{"headerTitle":_vm.getTranslation('Group Statistics')},scopedSlots:_vm._u([{key:"append-header",fn:function(){return [_c('div',[_c('v-select',{attrs:{"items":_vm.groupKeyListInWindow,"label":_vm.getTranslation('Group'),"menu-props":{ offsetY: true },"clearable":"","dense":"","filled":"","hide-details":"","outlined":""},model:{value:(_vm.selectedGroupNumber),callback:function ($$v) {_vm.selectedGroupNumber=$$v},expression:"selectedGroupNumber"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"dashboard-data-table",attrs:{"headers":_vm.headers.summaryTableHeader,"items":_vm.summaryTableItems,"hide-default-footer":"","mobile-breakpoint":"0"}}),_c('v-subheader',{staticClass:"pa-0 mt-5",staticStyle:{"font-size":"16px"}},[_vm._v("Previous Entry")]),_c('v-data-table',{staticClass:"dashboard-data-table",attrs:{"headers":_vm.headers.previousEntry,"items":_vm.previousEntryItems,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.tagValues",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"subtitle-2",attrs:{"to":{
                  name: 'AnimalDetails',
                  query: { id: item.guid },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(item.tagValues)+" ")])]}},{key:"item.gnrhReceived",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.gnrhReceived ? _vm.getTranslation("Yes") : _vm.getTranslation("No"))+" ")]}},{key:"item.statusId",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.statusId ? _vm.getTranslation( ("enums.fertilizationStatusImplant." + (item.statusId)) ) : "?")+" ")]}}])})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }