var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0"},[_vm._v("Sex History")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Sex_History_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.animal.disallowChanges(),
        },attrs:{"disabled":_vm.animal.disallowChanges(),"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteSex(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.gender",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.gender ? _vm.getLabelTranslation(("enums.genders." + (item.gender))) : null)+" ")])]}},{key:"item.timeRecorded",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.timeRecorded, "datetime", true))+" ")]}},{key:"item.createdOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }