import Vue from "vue";
const requireComponents = require.context(".", false, /\w+\.vue/);

const createComponentName = (filename) =>
  filename.substring(2, filename.indexOf(".vue"));

requireComponents.keys().forEach((filename) => {
  // Get component config
  const componentConfig = requireComponents(filename);

  // Get name of component
  const componentName = createComponentName(filename);

  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});
