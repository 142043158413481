<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div
          class="modal fade"
          id="moveModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <form data-toggle="validator">
            <!--- Populated before modal is opened --->
            <input type="hidden" id="alleyName" name="alleyName" />
            <input type="hidden" id="moveCombined" name="moveCombined" />

            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t.dispositionLabel }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="moveGroupWeight">{{ t.groupWeight }}</label>
                      <input
                        type="number"
                        class="form-control"
                        id="moveGroupWeight"
                        name="moveGroupWeight"
                        placeholder="0"
                        value="0"
                      />
                      <span class="input-group-addon">-</span>
                      <label for="moveGroupUnits">{{ t.units }}</label>
                      <select
                        class="form-control"
                        id="moveGroupUnits"
                        name="moveGroupUnits"
                      >
                        <option value="lbs">
                          {{ t.lbs }}
                        </option>
                        <option value="kg">{{ t.kg }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="moveIndividualWeight">{{
                        t.individualWeight
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        id="moveIndividualWeight"
                        name="moveIndividualWeight"
                        placeholder="0"
                        value="0"
                      />
                      <span class="input-group-addon">-</span>
                      <label for="moveIndividualUnits">{{ t.units }}</label>
                      <select
                        class="form-control"
                        id="moveIndividualUnits"
                        name="moveIndividualUnits"
                      >
                        <option value="lbs">
                          {{ t.lbs }}
                        </option>
                        <option value="kg">{{ t.kg }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="eventOrganization">{{
                      t.eventOrganization
                    }}</label>
                    <input
                      class="form-control"
                      id="eventOrganization"
                      name="eventOrganization"
                      type="text"
                    />
                  </div>
                  <div class="form-group">
                    <label for="income">{{ t.income }}</label>
                    <input
                      class="form-control"
                      id="income"
                      name="income"
                      placeholder=""
                      value=""
                    />
                  </div>
                  <div class="form-group">
                    <label for="moveReason">{{ t.reason }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="moveReason"
                      name="moveReason"
                      :placeholder="t.reason"
                    />
                  </div>
                  <div class="form-group">
                    <label for="moveBreed">{{ t.breed }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="moveBreed"
                      name="moveBreed"
                      :placeholder="t.breed"
                    />
                  </div>
                  <div class="form-group">
                    <label for="sex">{{ t.sex }}</label>
                    <select id="sex" name="sex" class="form-control">
                      <option value=""></option>
                      <!--- automatically filled via JS --->
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="moveOrigin">{{ t.origin }}</label>
                    <select id="origin" name="origin" class="form-control">
                      <option value=""></option>
                      <!--- automatically filled via JS --->
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="status">{{ t.status }}</label>
                    <select id="status" name="status" class="form-control">
                      <option value=""></option>
                      <!--- automatically filled via JS --->
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="moveComment">{{ t.comment }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="moveComment"
                      name="moveComment"
                      :placeholder="t.comment"
                      data-error="Comment is required."
                      required
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group">
                    <label for="locationId">{{ t.location }}</label>
                    <select
                      class="form-control"
                      id="locationId"
                      name="locationId"
                      data-error="Location is required."
                      required
                    >
                      <option value=""></option>
                      <!--- automatically filled via JS --->
                    </select>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    {{ t.close }}
                  </button>
                  <!--- Submit type causes validation to run. Dismiss causes the modal to go away. JS that captures the click sends the submission. --->
                  <button
                    type="submit"
                    class="btn btn-primary dispositionMove"
                    data-dismiss="modal"
                  >
                    {{ t.title }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <table
          id="dispositionTable"
          class="table table-striped table-bordered w-100"
          :class="{
            'table-dark-mode': $vuetify.theme.dark,
            'table-dark': $vuetify.theme.dark,
          }"
        >
          <thead>
            <tr>
              <th></th>
              <th>{{ t.reader }}</th>
              <th>{{ t.date }}</th>
              <th>{{ t.headcount }}</th>
              <th>{{ t.alleyName }}</th>
              <th>{{ t.combined }}</th>
              <th>{{ t.movedate }}</th>
              <th>{{ t.movetime }}</th>
              <th>{{ t.actions }}</th>
            </tr>
          </thead>
        </table>
        <online-warning></online-warning>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";

export default {
  name: "DispositionAnimals",
  mixins: [TranslationMixin],
  metaInfo: {
    title: "Disposition Animals",
  },
  data: () => ({
    disposables: [],
    herdMeta: null,
    options: {
      sexes: [],
    },
    pouches: null,
    table: null,
    tableData: null,
    interval: null,
  }),
  computed: {
    t: function () {
      return {
        actions: this.translate.actions[this.localeLang],
        alleyName: this.translate.alleyName[this.localeLang],
        breed: this.translate.breed[this.localeLang],
        close: this.translate.close[this.localeLang],
        combined: this.translate.combined[this.localeLang],
        comment: this.translate.comment[this.localeLang],
        date: this.translate.date[this.localeLang],
        dispositionLabel: this.translate.dispositionLabel[this.localeLang],
        eventOrganization: this.translate.eventOrganization[this.localeLang],
        groupWeight: this.translate.groupWeight[this.localeLang],
        headcount: this.translate.headcount[this.localeLang],
        income: this.translate.income[this.localeLang],
        individualWeight: this.translate.individualWeight[this.localeLang],
        kg: this.translate.kg[this.localeLang],
        lbs: this.translate.lbs[this.localeLang],
        location: this.translate.location[this.localeLang],
        moveDate: this.translate.moveDate[this.localeLang],
        movetime: this.translate.moveTime[this.localeLang],
        origin: this.translate.origin[this.localeLang],
        reader: this.translate.reader[this.localeLang],
        reason: this.translate.reason[this.localeLang],
        sex: this.translate.sex[this.localeLang],
        status: this.translate.status[this.localeLang],
        units: this.translate.units[this.localeLang],
      };
    },
    removeText: function () {
      return this.getLabelTranslation("remove", "Remove");
    },
  },
  mounted: function () {
    this.init();
    this.setDisposables();
  },
  beforeDestroy: function () {
    $(this.disposables.join(", ")).off();
    if (this.table != null) this.table.destroy();
    clearInterval(this.interval);
  },
  methods: {
    init: function () {
      const self = this;
      this.herdMeta = this.$herdMeta;

      this.herdMeta.loaded.done(async () => {
        this.options.sexes = await this.herdMeta.getMetaSexesAsync(
          true,
          true,
          true,
          true
        );

        $.when
          .apply([
            this.herdMeta.populateMoveLocationList(
              "locationId",
              true,
              true,
              true,
              true
            ),
            this.herdMeta.populateOriginList("origin", true, true, true, true),
            this.herdMeta.populateSexList("sex", true, true, true, true),
            this.getAndLoadEnumOptions("statuses", "status"),
          ])
          .always(async () => {
            await self.makeTable();
            self.refreshPoll();
            self.bindEvents();
          });
      });
    },
    setDisposables: function () {
      this.disposables.push(".btn.dispositionMove");
      this.disposables.push("#dispositionTable tbody");
      this.disposables.push("#dispositionTable");
      this.disposables.push("#locationId");
      this.disposables.push("#moveComment");
    },
    getDispositionAnimals: async function () {
      try {
        const result = await axios.get("/api/fast-track/dispositionAnimals", {
          params: {
            token: this.$token,
          },
        });
        if (result && result.status && result.status == 200) {
          this.tableData = (result.data || []).map(
            ({
              alleyName,
              friendlyName,
              headCount,
              moveCombined,
              moveDate,
              moveTime,
              timeRecorded,
            }) => ({
              alleyName: alleyName || null,
              friendlyName,
              headCount: headCount || 0,
              moveCombined,
              moveDate,
              moveTime,
              timeRecorded,
            })
          );
        }
      } catch (error) {
        console.log("************AN ERROR OCCURRED**********");
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        }
        this.$notify({
          group: "forms",
          text: error.message,
          title: "",
          type: "error",
        });
        return [];
      }
    },
    makeTable: async function (onlineStatus) {
      const userIsOnline = onlineStatus;
      const self = this;
      await this.getDispositionAnimals();

      // $.fn.dataTable.ext.errMode = "throw";
      this.table = $("#dispositionTable").DataTable({
        data: self.tableData,
        autoWidth: false,
        dom: '<"top-right"lf>rt<"bottom-left"i><"bottom-right"p>',
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        columns: [
          {
            data: null,
            orderable: false,
            defaultContent: `<a class="btn btn-primary viewDetails white--text ${
              userIsOnline ? "" : "disabled"
            }">${self.getLabelTranslation("view", "View")}</a>`,
          },
          { data: "friendlyName" },
          {
            data: "timeRecorded",
            orderable: true,
            render: function (data, _type, _row, _meta) {
              return Utils.renderValueAs(data, "datetime", true);
            },
          },
          { data: "headCount" },
          { data: "alleyName", visible: false },
          { data: "moveCombined", visible: false },
          { data: "moveDate", visible: false },
          { data: "moveTime", visible: false },
          {
            data: null,
            orderable: false,
            defaultContent: `
							<a class="btn btn-primary crudAction white--text ${
                userIsOnline ? "" : "disabled"
              }">${self.getLabelTranslation("disposition", "Disposition")}</a>
							<a class="btn btn-danger crudAction white--text ${
                userIsOnline ? "" : "disabled"
              }">${self.removeText}</a>
              <div class="${userIsOnline ? "hide" : ""}">${
              userIsOnline
                ? ""
                : self.getLabelTranslation(
                    "goOnlineToPerformActions",
                    "Go online to perform actions."
                  )
            }</div>
						`,
          },
        ],
        destroy: true,
        order: [[2, "desc"]],
      });
      // Hide further details if user is offline
      $(".details-control").toggleClass("hide", !userIsOnline);
    },
    refreshPoll: function () {
      let lastState = false;
      this.interval = setInterval(() => {
        if (lastState != this.isOnline) {
          lastState = this.isOnline;
          this.makeTable(lastState);
        }
      }, 100);
    },
    bindEvents: function () {
      const self = this;
      // Add event listener for opening and closing details
      $("#dispositionTable tbody").on(
        "click",
        "a.viewDetails",
        function (event) {
          const tr = $(this).closest("tr");
          const row = self.table.row(tr);

          // Inspired by https://stackoverflow.com/a/16190632
          /*For development, the following is helpful
          Go to herd-api folder and search dispositionAnimals.cfm
          <!---Whitlock Account--->
          <!---<cfset organizationId = "..." />--->
          Search this line and set maxDays to 200 or more
          <!---<cfset maxDays = 200 />---> */
          window.open(
            `/disposition-details&moveCombined=${
              row.data().moveCombined
            }&paging=false`
          );
        }
      );

      $(".btn.dispositionMove").on("click", function (event) {
        event.preventDefault();
        if (!self.validateFormValues()) return;

        $(".loading").toggleClass("hide", false);

        const data = $("#moveModal")
          .children("form")
          .serializeArray()
          .reduce((reduction, value) => {
            reduction[value.name] = value.value;
            return reduction;
          }, {});

        $("#moveModal").modal("toggle");

        Vue.notify({
          group: "forms",
          type: "info",
          title: "Info",
          text: "Loading alley data and identifying animals",
        });
        self.herdMeta
          .getDispositionDetails(data.moveCombined, self.$organizationID)
          .fail((error) => {
            Vue.notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: "Error occurred. Page will be reloaded",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            console.error(error);
          })
          .done((details) => {
            Vue.notify({
              group: "forms",
              type: "success",
              title: "Success",
              text: "Pre-existing animal were identified",
            });

            const totalAnimals = details.length;
            const promises = details.map((detail) => {
              const dSaved = $.Deferred();

              const animal =
                detail.animal ||
                new Animal("animal_" + Utils.guid(), herdMeta, {});
              const animalPromises = [];

              let commentId = null;
              // Append data
              if (data.moveBreed)
                animalPromises.push(
                  animal.modify(
                    "breeds",
                    null,
                    "breed",
                    data.moveBreed,
                    false,
                    true,
                    null
                  )
                );
              if (data.moveComment) {
                commentId = Utils.guid();
                animalPromises.push(
                  animal.modify(
                    "comments",
                    null,
                    "comment",
                    data.moveComment,
                    true,
                    {
                      commentId: commentId,
                      userId: self.$userID,
                      reason: data.moveComment,
                    }
                  )
                );
              }
              if (data.locationId) {
                let location = null;
                $("#locationId option").each(function () {
                  if (!$(this).val() || $(this).val() !== data.locationId)
                    return;
                  location = $(this).val();
                });
                animalPromises.push(
                  animal.modify(
                    "movements",
                    null,
                    "locationId",
                    data.locationId,
                    false,
                    true,
                    {
                      location,
                      reason: data.moveReason || "Updated via disposition.",
                      timeRecorded: data.timeRecorded,
                    }
                  )
                );
              }
              if (data.origin) {
                let origin = null;
                $("#origin option").each(function () {
                  if (!$(this).val() || $(this).val() !== data.origin) return;
                  origin = $(this).val();
                });
                animalPromises.push(
                  animal.modify(
                    "origins",
                    null,
                    "originId",
                    data.origin,
                    false,
                    true,
                    { origin }
                  )
                );
              }
              if (data.sex) {
                const sex = this.options.sexes.find(
                  ({ id }) => id === data.sex
                );

                const gender = sex.gender;
                animalPromises.push(
                  animal.modify("sexes", null, "sexId", sex.id, false, true, {
                    gender,
                    sex: sex.sex,
                  })
                );

                if (gender) {
                  animalPromises.push(
                    animal.modify(
                      "genders",
                      null,
                      "gender",
                      gender,
                      false,
                      true
                    )
                  );
                }
              }
              if (data.status)
                animalPromises.push(
                  animal.modify(
                    "status",
                    null,
                    "status",
                    data.status,
                    false,
                    true
                  )
                );

              {
                // Handle weights
                let weight = null;
                let units = null;

                if (!isNaN(data.moveGroupWeight) && +data.moveGroupWeight > 0) {
                  weight = +data.moveGroupWeight / totalAnimals;
                  units = data.moveGroupUnits;
                } else if (
                  !isNaN(data.moveIndividualWeight) &&
                  data.moveIndividualWeight > 0
                ) {
                  weight = +data.moveIndividualWeight;
                  units = data.moveIndividualUnits;
                }

                if (!isNaN(weight)) {
                  const extension = {
                    timeRecorded: data.timeRecorded,
                    units: units,
                  };
                  if (commentId) extension.commentId = commentId;

                  animalPromises.push(
                    animal.modify(
                      "weights",
                      null,
                      "weight",
                      weight,
                      false,
                      true,
                      extension
                    )
                  );
                }

                {
                  // Handle event organization and income
                  let eventOrganization = null;
                  let income = null;
                  if (data.eventOrganization && data.eventOrganization.trim()) {
                    eventOrganization = data.eventOrganization.trim();
                  }
                  if (!isNaN(data.income)) {
                    income = data.income;
                  }

                  if (eventOrganization || data.income !== null) {
                    animalPromises.push(
                      animal.modify(
                        "salesPurchases",
                        null,
                        null,
                        null,
                        false,
                        true,
                        {
                          eventOrganization,
                          price: income,
                          timeRecorded: data.timeRecorded,
                          transactionDate: data.timeRecorded,
                          transactionType: "income",
                          weight: !isNaN(weight) ? weight : null,
                          weightUnits: units,
                        }
                      )
                    );
                  }
                }
              }

              const newId = {
                status: "active",
                tagId: null,
                tagValue: detail.eid,
                type: "eid",
              };
              // New animals get the tag info
              if (!detail.animal)
                animalPromises.push(
                  animal.insertIDforAnimal(newId, false, false, true)
                );

              // Save
              $.when
                .apply($, animalPromises)
                .fail((...errors) => {
                  $(".loading").toggleClass("hide", true);
                  dSaved.reject(errors);
                })
                .done(() => {
                  animal.save(true).fail(dSaved.reject).done(dSaved.resolve);
                });

              return dSaved.promise();
            });

            $.when.apply($, promises).done((..._saveResults) => {
              Vue.notify({
                group: "forms",
                type: "success",
                title: "Success",
                text: "Move and data was saved",
              });
              Vue.notify({
                group: "forms",
                type: "info",
                title: "Info",
                text: "Removing batch",
              });
              axios
                .post(
                  "/api/fast-track/dispositionDelete",
                  {
                    alleyNameToDelete: data.alleyName,
                    moveCombinedToDelete: data.moveCombined,
                  },
                  {
                    params: {
                      token: self.$token,
                    },
                  }
                )
                .catch((err) => {
                  console.log(err);
                  this.$notify({
                    group: "forms",
                    type: "error",
                    title: "Error",
                    text: "Error occurred. Please reload page and try again",
                  });

                  // New animals may exist, reload so next run of disposition will be clean
                  this.$notify({
                    group: "forms",
                    type: "info",
                    title: "Info",
                    text: "Updating table",
                  });
                })
                .then((_response) => {
                  Vue.notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    text: "Batch was removed",
                  });
                  $("#moveModal").html("");
                  Vue.notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    text: "Animals were marked as having been updated",
                  });

                  // New animals may exist, reload so next run of disposition will be clean
                  Vue.notify({
                    group: "forms",
                    type: "info",
                    title: "Info",
                    text: "Updating table",
                  });
                })
                .finally(async () => {
                  await self.getDispositionAnimals();
                  self.table.clear();
                  self.table.rows.add(self.tableData);
                  self.table.draw();
                });
            });
          });
      });

      $("#dispositionTable tbody").on("click", "a.crudAction", function () {
        let data = self.table.row($(this).parents("tr")).data();

        if ($(this)[0].innerHTML === self.removeText) {
          if (window.confirm(`${self.removeText}?`)) {
            // $(".loading").toggleClass("hide", false);

            axios
              .post(
                "/api/fast-track/dispositionDelete",
                {
                  alleyNameToDelete: data.alleyName,
                  moveCombinedToDelete: data.moveCombined,
                },
                {
                  params: {
                    token: self.$token,
                  },
                }
              )
              .catch((err) => {
                console.log(err);
                Vue.notify({
                  group: "forms",
                  type: "error",
                  title: "Error",
                  text: "Error occurred. Please reload page and try again",
                });

                Vue.notify({
                  group: "forms",
                  type: "info",
                  title: "Info",
                  text: "Updating table",
                });
              })
              .then((results) => {
                Vue.notify({
                  group: "forms",
                  type: "success",
                  title: "Success",
                  text: "Saved. Updating table",
                });
              })
              .finally(async () => {
                await self.getDispositionAnimals();
                self.table.clear();
                self.table.rows.add(self.tableData);
                self.table.draw();
              });
          }
        } else {
          document.getElementById("alleyName").value = data.alleyName;
          document.getElementById("moveCombined").value = data.moveCombined;

          $("#moveModal").modal();
        }
      });
    },
    validateFormValues: function () {
      let focusElements = [];

      // income
      {
        let income = document.getElementById("income").value;
        if (income && isNaN(income)) {
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "Income must be a number or be empty",
          });
          focusElements.push("#income");
        }
      }

      // locationId
      {
        let locationId = document.getElementById("locationId").value;
        if (!locationId) {
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "Pen or Pasture cannot be blank",
          });
          focusElements.push("#locationId");
        }
      }

      // moveComment
      {
        let moveComment = document.getElementById("moveComment").value;
        if (!moveComment) {
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "Comment is required",
          });
          focusElements.push("#moveComment");
        }
      }

      if (focusElements.length) $(focusElements[0]).focus();

      return !focusElements.length;
    },
  },
};
</script>
