<template>
  <div class="dashboard-background">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex mb-6">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-btn
              @click="printTable"
              class="normal-case rounded-lg primary-button"
              text
            >
              <v-icon class="mr-1" primary="true">mdi-printer</v-icon>
              Print
            </v-btn>
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1">mdi-open-in-new</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" md="4">
                <v-form lazy-validation>
                  <v-select
                    :items="yearsAvailable"
                    :label="getTranslation('year')"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    @input="generateReport"
                    class="mt-0"
                    hide-details
                    item-text="type"
                    item-value="id"
                    v-model="WeanReport.year"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="[]"
        :items="[]"
        :loading="true"
        class="dashboard-data-table my-5"
        hide-default-footer
        loading-text="Loading... Please wait"
        v-if="WeanReport.loading"
      />
      <div class="printable printable-metadata">
        <h5>
          {{ getOrganizationName }}
        </h5>
        <h5>
          Generated: {{ $moment().local().format("MM-DD-YYYY HH:mm:ss") }}
        </h5>
        <h5>Report Year: {{ WeanReport.year }}</h5>
        <h5>Report: Calf wean summary</h5>
      </div>
      <div class="printable">
        <div
          class="my-5"
          v-for="(table, $index) in WeanReport.tableDataByHerd"
          :key="$index"
        >
          <div class="printable printable-metadata">
            <br /><br /><br /><br />
          </div>
          <v-data-table
            :headers="WeanReportHeaders"
            :items="table.data"
            class="dashboard-data-table"
            hide-default-footer
            show-select
            v-model="table.selected"
          >
            <template #top="{ options, pagination, updateOptions }">
              <div class="d-flex align-center">
                <div class="h6">{{ table.herd }}</div>
                <v-data-footer
                  :items-per-page-text="
                    getTranslation('animalsPages.itemsPerPage')
                  "
                  :options="options"
                  :pagination="pagination"
                  @update:options="updateOptions"
                  class="ml-auto"
                />
              </div>
            </template>
            <template #body.append>
              <tr>
                <th colspan="2" class="body-1"><strong> Totals </strong></th>
                <th
                  class="body-1"
                  v-for="(header, $index) in WeanReportHeaders.slice(
                    1,
                    WeanReportHeaders.length
                  )"
                  :key="$index"
                >
                  <strong>{{ getTotalOf(table, header.value) }}</strong>
                </th>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";

export default {
  data: () => ({
    WeanReport: {
      currentPage: 1,
      data: [],
      dynamicHeaders: [],
      loading: false,
      perPage: 10,
      tableDataByHerd: [],
      year: new Date().getFullYear(),
    },
  }),
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getOrganizationName: "Organization/getName",
    }),
    WeanReportHeaders: function () {
      /**
       * We no longer need "key" and "label" fields for the data table
       * but are still used to compute or fill values
       */
      return [
        {
          align: "start",
          key: "breed",
          label: this.getTranslation("calfWeanPage.groupSummary.breed"),
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.breed"),
          value: "breed",
        },
        {
          align: "start",
          key: "totalWeaned",
          label: "Total Weaned",
          sortable: false,
          text: "Total Weaned",
          value: "totalWeaned",
        },
        {
          align: "start",
          key: "avgWt",
          label: this.getTranslation(
            "calfWeanPage.groupSummary.totalAvgWeight"
          ),
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.totalAvgWeight"),
          value: "avgWt",
        },
      ].concat(this.WeanReport.dynamicHeaders);
    },
    yearsAvailable: function () {
      const actualYear = new Date().getFullYear();
      let yearsAvailable = [];
      for (let index = -5; index <= 0; index++) {
        yearsAvailable.push(actualYear + index);
      }
      return yearsAvailable.reverse();
    },
  },
  mounted: function () {
    this.generateReport();
  },
  methods: {
    generateReport: function () {
      this.WeanReport.loading = true;
      this.$herdMeta.loaded
        .fail(() => {})
        .then(() => {
          this.$herdMeta.pouches.organization
            .query("local_views/weanWeightEntries", {
              group_level: 1,
            })
            .then((results) => {
              const allData = results.rows;
              this.setWeanReportDynamicHeaders(allData);
              // By Herd
              const dataByHerd = this.getDataByHerd(allData);
              const dataFiltered = this.filterDataByYear(
                dataByHerd,
                this.WeanReport.year
              );

              const tableDataByHerd = Object.keys(dataFiltered)
                .map((herd) => {
                  const summary = this.getFullSummaryWithoutFilters(
                    dataFiltered[herd]
                  );
                  return { herd, data: summary, selected: [] };
                })
                .sort((a, b) => {
                  if (a.herd.toLowerCase() > b.herd.toLowerCase()) return 1;
                  if (a.herd.toLowerCase() < b.herd.toLowerCase()) return -1;
                  return 0;
                });

              this.WeanReport.tableDataByHerd = tableDataByHerd;
              this.WeanReport.loading = false;
            });
        });
    },
    getDataByGroupNumber: function (data) {
      const groupNumbersAvailable = data.reduce((previous, { value }) => {
        if (
          value.groupNumber &&
          value.groupNumber != "" &&
          !previous.includes(value.groupNumber)
        )
          previous.push(value.groupNumber);
        return previous;
      }, []);
      const dataByGroupNumber = data.reduce((previous, { value }) => {
        const groupNumber = value.groupNumber;
        const notAssigned = "Not Assigned";
        if (groupNumbersAvailable.includes(groupNumber)) {
          if (!previous[groupNumber]) previous[groupNumber] = [value];
          else previous[groupNumber].push(value);
        } else {
          if (!previous[notAssigned]) previous[notAssigned] = [value];
          else previous[notAssigned].push(value);
        }
        return previous;
      }, {});
      return dataByGroupNumber;
    },
    getDataByHerd: function (data) {
      const herdsAvailable = data.reduce((previous, { value }) => {
        if (value.herd && value.herd != "" && !previous.includes(value.herd))
          previous.push(value.herd);
        return previous;
      }, []);
      const dataByHerd = data.reduce((previous, { value }) => {
        const herd = value.herd;
        const notAssigned = "Not Assigned";
        if (herdsAvailable.includes(herd)) {
          if (!previous[herd]) previous[herd] = [value];
          else previous[herd].push(value);
        } else {
          if (!previous[notAssigned]) previous[notAssigned] = [value];
          else previous[notAssigned].push(value);
        }
        return previous;
      }, {});
      return dataByHerd;
    },
    getDataByReceivingRanch: function (data) {
      const ranchesAvailable = data.reduce((previous, { value }) => {
        if (
          value.receivingRanch &&
          value.receivingRanch != "" &&
          !previous.includes(value.receivingRanch)
        )
          previous.push(value.receivingRanch);
        return previous;
      }, []);
      const dataByRanches = data.reduce((previous, { value }) => {
        const receivingRanch = value.receivingRanch;
        const notAssigned = "Not Assigned";
        if (ranchesAvailable.includes(receivingRanch)) {
          if (!previous[receivingRanch]) previous[receivingRanch] = [value];
          else previous[receivingRanch].push(value);
        } else {
          if (!previous[notAssigned]) previous[notAssigned] = [value];
          else previous[notAssigned].push(value);
        }
        return previous;
      }, {});
      return dataByRanches;
    },
    getBreedsAvailable: function (data) {
      const breedsAvailable = data.reduce((previous, { breed }) => {
        if (breed && breed != "" && !previous.includes(breed))
          previous.push(breed);
        return previous;
      }, []);
      breedsAvailable.push("Not Assigned");
      return breedsAvailable;
    },
    getSexesAvailable: function (data) {
      const sexesAvailable = data.reduce((previous, { sex }) => {
        if (sex && sex != "" && !previous.includes(sex)) previous.push(sex);
        return previous;
      }, []);
      sexesAvailable.push("Not Assigned");
      return sexesAvailable;
    },
    getRecordsByBreed: function (data) {
      const breedsAvailable = this.getBreedsAvailable(data);
      return data.reduce((previous, current) => {
        const breed = current.breed;
        const notAssigned = "Not Assigned";
        if (breedsAvailable.includes(breed)) {
          if (!previous[breed]) previous[breed] = [current];
          else previous[breed].push(current);
        } else {
          if (!previous[notAssigned]) previous[notAssigned] = [current];
          else previous[notAssigned].push(current);
        }
        return previous;
      }, {});
    },
    getRecordsBySexForEachBreed: function (data, recordsByBreed) {
      const breedsAvailable = this.getBreedsAvailable(data);
      const sexesAvailable = this.getSexesAvailable(data);

      const records = breedsAvailable.map((breed) => {
        let record = {
          [breed]: {
            "Not Assigned": [],
          },
        };
        if (recordsByBreed[breed]) {
          record[breed] = recordsByBreed[breed].reduce((previous, current) => {
            const sex = current.sex || "Not Assigned";
            const notAssigned = "Not Assigned";
            if (sexesAvailable.includes(sex)) {
              if (!previous[sex]) previous[sex] = [current];
              else previous[sex].push(current);
            } else {
              if (!previous[notAssigned]) previous[notAssigned] = [current];
              else previous[notAssigned].push(current);
            }
            return previous;
          }, {});
        }
        return record;
      });
      const tableData = this.getSummaryData(data, records);
      return tableData;
    },
    getSummaryData: function (data, records) {
      const sexesAvailable = this.getSexesAvailable(data);
      // Get Summary
      const tableData = records.reduce((previous, current) => {
        const currentBreed = Object.keys(current)[0];
        // Total Weight of the current breed
        let totalWeight = 0;
        const sexAndAvgWeightCollection = sexesAvailable.map((sex) => {
          const weanWeightArray = current[currentBreed][sex];
          // Total amount of the current sex
          const amountOfTheCurrentSex = weanWeightArray
            ? weanWeightArray.length
            : 0;
          // Total weight of the current sex
          const totalWeightOfTheCurrentSex = (weanWeightArray || []).reduce(
            (previousWeight, { weight }) => {
              previousWeight += Number(weight);
              return previousWeight;
            },
            0
          );
          totalWeight += totalWeightOfTheCurrentSex;
          return {
            [sex]: amountOfTheCurrentSex,
            [`${sex}TotalWt`]: totalWeightOfTheCurrentSex,
            [`${sex}AvgWt`]: Math.round(
              totalWeightOfTheCurrentSex / amountOfTheCurrentSex
            ),
          };
        });

        // Array with {sex, avgWeight} called sexAndAvgWeightCollection and totalWeightForTheCurrentBreed
        const totalWeaned = sexAndAvgWeightCollection.reduce((total, wean) => {
          total += wean[Object.keys(wean)[0]];
          return total;
        }, 0);

        const totalWeightOfBreed = sexAndAvgWeightCollection.reduce(
          (total, wean) => {
            total += wean[Object.keys(wean)[1]];
            return total;
          },
          0
        );

        const sexAndAvgWeightObject = sexAndAvgWeightCollection.reduce(
          (prev, current) => {
            Object.keys(current).forEach((key) => {
              prev[key] = current[key];
            });
            return prev;
          },
          {}
        );
        previous.push({
          breed: currentBreed,
          totalWeaned,
          totalWeightOfBreed,
          avgWt: Math.round(totalWeaned > 0 ? totalWeight / totalWeaned : 0),
          ...sexAndAvgWeightObject,
        });

        return previous;
      }, []);
      tableData.forEach((record, index) => {
        this.WeanReport.dynamicHeaders.forEach(({ key }) => {
          if (!record[key]) record[key] = 0;
        });
        record.id = index;
      });
      return tableData;
    },
    getFullSummaryWithoutFilters: function (data) {
      const recordsByBreed = this.getRecordsByBreed(data);
      const recordsBySexForEachBreed = this.getRecordsBySexForEachBreed(
        data,
        recordsByBreed
      );
      return recordsBySexForEachBreed;
    },
    setWeanReportDynamicHeaders: function (data) {
      /**
       * We no longer need "key" and "label" fields for the data table
       * but are still used to compute or fill values
       */
      const values = data.map(({ value }) => value);
      const sexes = this.getSexesAvailable(values);
      this.WeanReport.dynamicHeaders = [];
      sexes.forEach((sex) => {
        this.WeanReport.dynamicHeaders.push({
          align: "start",
          key: sex,
          label: sex,
          sortable: false,
          text: sex,
          value: sex,
        });
        const key = sex + "AvgWt";
        const label = sex + " AvgWt";
        this.WeanReport.dynamicHeaders.push({
          align: "start",
          key,
          label,
          sortable: false,
          text: label,
          value: key,
        });
      });
    },
    filterDataByYear: function (data, year) {
      const dataFiltered = this.$utils.copyObject(data);
      Object.keys(dataFiltered).forEach((key) => {
        dataFiltered[key] = dataFiltered[key].filter(
          ({ weanDate }) => moment(weanDate).year() == year
        );
      });
      return dataFiltered;
    },
    getSumOfColumn: function (data, column) {
      return data.reduce((previous, current) => {
        previous += current[column];
        return previous;
      }, 0);
    },
    getTotalAverageWeightOfTable: function (data) {
      const totalWeaned = data.reduce((previous, current) => {
        previous += current.totalWeaned;
        return previous;
      }, 0);
      const totalWeight = data.reduce((previous, current) => {
        previous += current.totalWeightOfBreed;
        return previous;
      }, 0);
      return Math.round(
        totalWeaned > 0 ? (totalWeight / totalWeaned).toFixed(1) : 0
      );
    },
    getAverageWeightOfColumn: function (data, column) {
      let totalAmount = 0;
      let totalWeight = 0;
      const sex = column.slice(0, column.indexOf("AvgWt"));
      data.forEach((breed) => {
        totalAmount += breed[sex];
        totalWeight += breed[`${sex}TotalWt`];
      });
      return Math.round(
        totalAmount > 0 ? (totalWeight / totalAmount).toFixed(1) : 0
      );
    },
    printTable: function () {
      window.print();
    },
    exportToCSV: function () {
      const selected = this.WeanReport.tableDataByHerd.filter(
        (table) => table.selected.length
      );
      if (!selected.length) {
        return this.$notify({
          group: "forms",
          text: "Please select at least 1 row.",
          title: "",
          type: "info",
        });
      }
      this.WeanReport.tableDataByHerd.forEach((table) => {
        if (!table.selected.length) return;

        const headers = this.WeanReportHeaders.filter((header) => header.text);
        const fields = headers.map((header) => header.text);
        const data = table.selected.map((row) =>
          headers.map(({ value }) => row[value])
        );
        this.$herdMeta.exportToCSV(fields, data, table.herd);
      });
    },
    getTotalOf: function (table, field) {
      return table.data.reduce((result, item) => {
        return (Number(item[field]) || 0) + result;
      }, 0);
    },
  },
};
</script>


<style scoped>
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
