<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 17 23" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M0.281608 0.539544L0.116211 0.6696V4.80268C0.116211 7.07586 0.133347 8.98039 0.154311 9.035C0.188771 9.12489 0.987568 9.1343 8.558 9.1343H16.9237L16.9615 8.99307C16.9823 8.91537 16.9997 7.01083 17.0002 4.76075L17.0011 0.6696L16.8357 0.539544C16.6703 0.409424 16.6699 0.409424 8.55863 0.409424C0.447317 0.409424 0.447004 0.409424 0.281608 0.539544ZM0.492824 10.0992C0.492824 10.2517 1.0976 11.1173 1.45131 11.471C1.89552 11.9152 2.59182 12.345 3.22327 12.5648C3.70339 12.732 3.78662 12.7406 5.12027 12.7625L6.51506 12.7853L6.53257 16.7816L6.55003 20.7779L6.75032 21.1611C7.29911 22.211 8.52913 22.6781 9.6257 22.2531C10.139 22.0541 10.7086 21.4869 10.918 20.9662L11.0694 20.5896L11.0878 16.6823L11.1061 12.7749H12.0977C12.8718 12.7749 13.1932 12.7484 13.5629 12.6538C14.4177 12.4352 15.3868 11.8488 15.8838 11.2496C16.1729 10.9011 16.6231 10.1966 16.6238 10.0915C16.6243 10.0291 14.9697 10.0131 8.55863 10.0131C1.76616 10.0131 0.492824 10.0267 0.492824 10.0992ZM9.09217 18.4241C9.20176 18.5337 9.2177 18.6333 9.2177 19.2087C9.2177 19.7841 9.20176 19.8837 9.09217 19.9933C8.92784 20.1577 8.66578 20.1529 8.48419 19.9824C8.35112 19.8574 8.33894 19.7932 8.33894 19.2175C8.33894 18.6587 8.35407 18.5731 8.4754 18.4438C8.64469 18.2637 8.92282 18.2548 9.09217 18.4241Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
