var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showTitle)?_c('h4',{staticClass:"mb-4"},[_vm._v("Weight History")]):_vm._e(),(_vm.showExtraInformation)?_c('div',{staticClass:"d-flex mb-2"},[(_vm.table.data[0] && _vm.table.data[0].timeRecorded)?_c('div',{staticClass:"d-flex flex-wrap justify-center align-center",staticStyle:{"max-width":"230px"}},[_c('p',{class:[
          'subtitle-weight',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" Last Recorded Weight "+_vm._s(_vm.$utils.renderValueAs(_vm.table.data[0].timeRecorded, "date"))+" ")]),_c('p',{class:[
          'weight-value',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" "+_vm._s(_vm.table.data[0].weight)+" lbs. ")])]):_vm._e(),(_vm.table.data[0] && _vm.table.data[0].timeRecorded)?_c('div',{staticClass:"d-flex flex-wrap justify-center align-center",staticStyle:{"max-width":"230px"}},[_c('p',{class:[
          'subtitle-weight',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" Average Daily Weight gain ")]),_c('p',{class:[
          'weight-value',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" "+_vm._s(_vm.animal ? _vm.animal.calculateAverageDailyWeightGain().toFixed(2) : 0)+" lbs ")])]):_vm._e()]):_vm._e(),_c('export-button',{staticClass:"mb-2 mr-2",attrs:{"disabled":_vm.table.data.length == 0,"filename":("Weight_History_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
      new Date().toISOString(),
      'datetime'
    ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}}),_c('v-btn',{staticClass:"pa-0",attrs:{"disabled":_vm.table.data.length == 0 || _vm.disabled,"color":"success","max-width":"40","min-width":"40"}},[_c('v-icon',{staticClass:"white--text",on:{"click":_vm.openCreateDialog}},[_vm._v("mdi-plus")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled || _vm.disallowChanges(item),"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteWeight(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.weight",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.modifyWeight(item)}},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})]}},{key:"item.timeRecorded",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.timeRecorded, "datetime", true))+" ")]}},{key:"item.createdOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.lastUpdated, "datetime", true))+" ")]}}])}),(_vm.showExtraInformation)?_c('div',[_c('v-spacer',{staticStyle:{"height":"40px"}}),(_vm.table.data[0] && _vm.table.data[0].lastUpdated)?_c('p',{staticStyle:{"color":"#989089"}},[_vm._v(" Last Updated: "+_vm._s(_vm.$utils.renderValueAs(_vm.table.data[0].lastUpdated, "datetime", true))+" ")]):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"400px","transition":"dialog-transition"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("New Weight")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_c('v-text-field',{staticClass:"custom-field mb-3",attrs:{"label":_vm.getTranslation('weight'),"clearable":"","dense":"","hide-details":"","outlined":""},model:{value:(_vm.dialog.form.weight),callback:function ($$v) {_vm.$set(_vm.dialog.form, "weight", $$v)},expression:"dialog.form.weight"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-3 custom-field",attrs:{"label":_vm.getTranslation('dateRecorded'),"append-icon":"mdi-calendar","dense":"","hide-details":"","outlined":"","readonly":""},on:{"click:append":function($event){_vm.dialog.showCalendar = true}},model:{value:(_vm.dialog.form.date),callback:function ($$v) {_vm.$set(_vm.dialog.form, "date", $$v)},expression:"dialog.form.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.showCalendar),callback:function ($$v) {_vm.$set(_vm.dialog, "showCalendar", $$v)},expression:"dialog.showCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialog.showCalendar = false}},model:{value:(_vm.dialog.form.date),callback:function ($$v) {_vm.$set(_vm.dialog.form, "date", $$v)},expression:"dialog.form.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-field",attrs:{"label":_vm.getTranslation('time'),"append-icon":"mdi-clock-time-four","dense":"","hide-details":"","outlined":"","readonly":""},on:{"click:append":function($event){_vm.dialog.showTimepicker = true}},model:{value:(_vm.dialog.form.time),callback:function ($$v) {_vm.$set(_vm.dialog.form, "time", $$v)},expression:"dialog.form.time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.showTimepicker),callback:function ($$v) {_vm.$set(_vm.dialog, "showTimepicker", $$v)},expression:"dialog.showTimepicker"}},[_c('v-time-picker',{model:{value:(_vm.dialog.form.time),callback:function ($$v) {_vm.$set(_vm.dialog.form, "time", $$v)},expression:"dialog.form.time"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"disabled":_vm.disabled || !_vm.dialog.form.weight,"color":"success"},on:{"click":_vm.createNewWeight}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }