<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M10.3933 3.56275V6.73683H11.6241H12.8549V9.19837V11.6599H7.99658H3.13829V14.8988V18.1376H3.91562H4.69294V15.6761V13.2146H8.7739H12.8549V15.6761V18.1376H13.6322H14.4095V15.6761V13.2146H18.5553H22.701V15.6761V18.1376H23.4135H24.1261V14.8988V11.6599H19.2678H14.4095V9.19837V6.73683H15.6403H16.871V3.56275V0.388672H13.6322H10.3933V3.56275ZM0.676758 22.9959V26.17H3.91562H7.15447V22.9959V19.8218H3.91562H0.676758V22.9959ZM10.3933 22.9959V26.17H13.6322H16.871V22.9959V19.8218H13.6322H10.3933V22.9959ZM20.2395 22.9959V26.17H23.4135H26.5876V22.9959V19.8218H23.4135H20.2395V22.9959Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        const dark =
          this.$parent.themeableProvide.isDark || this.$vuetify.theme.dark;
        return dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
