<template>
  <div>
    <in-page-menu />

    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <!-- move modal -->
      <div
        class="modal fade"
        id="moveModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dispositionLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="dispositionLabel">
                {{ getTranslation("dispositionLabel") }}
              </h4>
            </div>

            <div class="modal-body">
              <label for="moveLocationList"
                >{{ getTranslation("locationList") }}:</label
              >
              <select class="form-control" id="moveLocationList"></select>
            </div>

            <div class="modal-body">
              <label for="statusList">{{ getTranslation("statusList") }}</label>
              <select id="statusList" name="statusList" class="form-control">
                <option value="alive">{{ getTranslation("alive") }}</option>
                <option value="dead">{{ getTranslation("dead") }}</option>
                <option value="stolen">{{ getTranslation("stolen") }}</option>
                <option value="missing">{{ getTranslation("missing") }}</option>
                <option value="sick">{{ getTranslation("sick") }}</option>
                <option value="lost">{{ getTranslation("lost") }}</option>
                <option value="sold">{{ getTranslation("sold") }}</option>
                <option value="embryo">{{ getTranslation("embryo") }}</option>
                <option value="semen">{{ getTranslation("semen") }}</option>
              </select>
            </div>

            <div class="modal-body">
              <label for="moveReason">{{ getTranslation("reason") }}</label>
              <input
                type="text"
                class="form-control"
                id="moveReason"
                name="moveReason"
                placeholder="Reason"
              />
            </div>

            <div class="modal-body">
              <label for="timeRecorded">{{
                getTranslation("timeRecorded")
              }}</label>
              <input
                type="text"
                class="form-control datepicker"
                id="timeRecorded"
                name="timeRecorded"
                placeholder="getTranslation('timeRecorded')"
              />
            </div>

            <div class="modal-body">
              <div class="col-md-4"></div>
              <div class="col-md-4 text-center">
                <label class="btn btn-default btn-file">
                  <span class="glyphicon glyphicon-camera"></span>
                  {{ getTranslation("browse") }}
                  <input
                    type="file"
                    id="imageLoader"
                    name="imageLoader"
                    class="d-none"
                  />
                </label>
              </div>
            </div>

            <div class="modal-body">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <img
                  id="imageCanvas"
                  src=""
                  height="50"
                  width="50"
                  class="d-none"
                />
                <input type="hidden" id="imageCanvasBase64" />
                <input type="hidden" id="imageCanvasType" />
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ getTranslation("closeButton") }}
              </button>
              <button type="button" class="btn btn-primary" id="moveButton">
                {{ getTranslation("moveButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- sick modal -->
      <form id="sickListForm">
        <div
          class="modal fade"
          id="sickModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="sickAnimalsLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="sickAnimalsLabel">
                  {{ getTranslation("timeRecorded") }}
                </h4>
              </div>
              <div class="modal-body">
                <input type="hidden" id="sickEID" name="sickEID" />
                <input
                  type="hidden"
                  id="sickLocationId"
                  name="sickLocationId"
                />
                <input
                  type="hidden"
                  id="sickOrganizationId"
                  name="sickOrganizationId"
                />
                <input type="hidden" id="sickVisual" name="sickVisual" />
                <ul id="sickAnimals"></ul>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  data-dismiss="modal"
                  id="sendToArcherButton"
                >
                  {{ getTranslation("sendToArcher") }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  {{ getTranslation("close") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- stats modal -->
      <div
        class="modal fade"
        id="statsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="statsLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="statsLabel">
                {{ getTranslation("stats") }}
              </h4>
            </div>
            <div class="modal-body">
              <iframe
                style="width: 90vw; height: 90vh"
                id="statsFrame"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                {{ getTranslation("close") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--
      <cfinclude template="breakdownModal.cfm" />
      <cfinclude template="moveModal.cfm" />
      <cfinclude template="sickModal.cfm" />
      <cfinclude template="statsModal.cfm" />

      <cfset qGetUserFeedlots = request.app.util.db.user.getUserFeedlots(cookie.userId) />

      <cfif StructKeyExists(cookie, "feedlotLocationId") and Len(cookie.feedlotLocationId)>
        <cfset local.locationId = cookie.feedlotLocationId />
      <cfelse>
        <cfset local.locationId = qGetUserFeedlots.locationId />
        <cfset cookie.feedlotLocationId = qGetUserFeedlots.locationId />
      </cfif>

      <cfset sensorDates = request.app.util.db.sensor.getSensorDates(local.locationId) />

      <cfset sickValuesSuffix = LCase( Hash(local.locationId) ) />
      <cfset sickValuesTable = "sick_values_#sickValuesSuffix#" />

      <cfoutput>
      <input type="hidden" id="endDate" value="#DateFormat(sensorDates[ArrayLen(sensorDates)], "mm/dd/Y")#" />
      <input type="hidden" id="feedlotLocationId" value="#local.locationId#" />
      <input type="hidden" id="sTableName" value="#sickValuesTable#" />
      <input type="hidden" id="startDate" value="#DateFormat(sensorDates[1], "mm/dd/Y")#" />
      </cfoutput>
      -->

      <div class="container">
        <div class="row">
          <div class="form-group col-xs-6">
            <label for="locationList"
              >{{ getTranslation("locationList") }}:</label
            >
            <select class="form-control" id="locationList"></select>
          </div>
          <div class="form-group col-xs-6">
            <label for="sickList">{{ getTranslation("sickList") }}:</label>
            <select class="form-control" id="sickList">
              <option>{{ getTranslation("chooseOption") }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xs-6">
            <label for="sicknessThreshold"
              >{{ getTranslation("sicknessThreshold") }}:</label
            >
            <input
              type="numeric"
              class="form-control"
              id="sicknessThreshold"
              value="1"
            />
          </div>
          <div class="form-group col-xs-4">
            <label for="dateRange">{{ getTranslation("dateRange") }}:</label>
            <input
              type="text"
              id="dateRange"
              name="dateRange"
              value="01/01/2015 - 01/31/2015"
            />
            <input type="text" id="dateRange" name="dateRange" />
          </div>
          <div class="form-group col-xs-2">
            <label for="completeTagList"
              >{{ getTranslation("completeTagList") }}:</label
            >
            <input
              type="checkbox"
              id="completeTagList"
              name="completeTagList"
            />
          </div>
          <div class="form-group col-xs-2">
            <button
              type="button"
              class="btn btn-primary"
              id="updateFeedlotData"
            >
              {{ getTranslation("update") }}
            </button>
          </div>
        </div>

        <!--
        <cfoutput>
          <cfset var formattedDates = [] />
          <cfloop index="i" from="1" to="#ArrayLen(sensorDates)#">
            <cfset ArrayAppend(formattedDates, JSStringFormat(DateFormat(sensorDates[i], "mm/dd/Y")) ) />
          </cfloop>

          <script type="text/javascript">
            var formattedDates = [
              #ListQualify( ArrayToList(formattedDates), "'")#
            ];
          </script>
        </cfoutput>
        -->

        <table
          id="feedlotTable"
          class="table table-striped table-bordered"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th>{{ getTranslation("visualTagName") }}</th>
              <th>{{ getTranslation("EIDlastFour") }}</th>
              <th>{{ getTranslation("EID") }}</th>
              <th>{{ getTranslation("guid") }}</th>
              <th>{{ getTranslation("location") }}</th>
              <!-- <cfoutput>
              <cfloop index="i" from="1" to="#ArrayLen(sensorDates)#">
                <th>#DateFormat(sensorDates[i], "mm/dd/Y")#</th>
              </cfloop>
              </cfoutput> -->
            </tr>
          </thead>
        </table>
      </div>
    </v-row>
  </div>
</template>
<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "Feedlot",
  metaInfo: {
    title: "Feedlot",
  },
  mixins: [TranslationMixin],
  data: () => ({
    feedlotColumns: [],
    herdMeta: null,
    localFeedlotDB: null,
    localOrgDB: null,
    table: null,
    tableData: null,
  }),
  created: function () {
    return;
    // TODO: Finish converting
    // Note: In order for this to work, pouchdb-quick-search must be included at index.html
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    $('input[name="dateRange"]').daterangepicker({
      startDate: startDate,
      endDate: endDate,
    });

    $("#feedlotTable").on("draw.dt", function (_e, _settings) {
      this._colorCoding();
    });

    $("#breakdownModal").on("show.bs.modal", function () {
      $(this)
        .find(".modal-dialog")
        .css({ width: "auto", height: "100%", "max-height": "100%" });
    });

    $("#sickModal").on("show.bs.modal", function () {
      $(this)
        .find(".modal-dialog")
        .css({ width: "auto", height: "auto", "max-height": "100%" });
    });

    $("#statsModal").on("show.bs.modal", function () {
      $(this)
        .find(".modal-dialog")
        .css({ width: "auto", height: "auto", "max-height": "100%" });
    });

    this.herdMeta = this.$HerdMeta;

    this.herdMeta.loaded.then(() => {
      if (localStorage.getItem("feedlot.completeTagListChecked") === "true") {
        document.getElementById("completeTagList").checked = true;
      }
      this.bindEvents();

      const feedlotName = `feedlot-${$cookies.get("ORGANIZATIONID")}`;

      this.localFeedlotDB = new PouchDB(feedlotName);

      this.localFeedlotDB
        .destroy()
        .then(function (_response) {
          this._setupPouch();
        })
        .catch(function (err) {
          console.log(err);
        });
    });
  },
  computed: {},
  methods: {
    _getValues: function () {
      const tableName = this._getTableName();
      const selector = {
        $and: [
          {
            tableName,
          },
        ],
      };

      // console.log( JSON.stringify(selector, null, 2) );
      // console.log( JSON.stringify(selector) );

      this._getTotalRecords(this.tableName).then(function (_recordsTotal) {
        this.localFeedlotDB
          .find({
            selector,
          })
          .then(function (result) {
            this.tableData = this._formatJSON(result);
            this._makeTable();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    _getTotalRecords: function (tableName) {
      console.log(this.tableName);
      console.log(this.localFeedlotDB.name);
      console.log(localStorage.getItem("feedlot.feedlotLocationId"));

      const promise = this.localFeedlotDB
        .find({
          selector: {
            tableName,
          },
        })
        .then(function (result) {
          return result.docs.length;
        })
        .catch((err) => {
          console.log(err);
        });

      return promise;
    },
    _formatJSON: function (result) {
      let data = [];
      let rowData = [];

      for (let i = 0; i < result.docs.length; i++) {
        for (let key in result.docs[i]) {
          for (let j = 0; j < this.feedlotColumns.length; j++) {
            if (this.feedlotColumns[j].name === key) {
              rowData.push(result.docs[i][key]);
            }
          }
        }

        data.push(rowData);
        rowData = [];
      }

      return data;
    },
    _makeTableSetup: function (results) {
      this._generateFeedlotColumns(results);
    },
    _getDayCount: function (rs) {
      if (rs.docs.length === 0) return 0;

      let count = 0;
      for (const property in rs.docs[0]) {
        if (property.indexOf("day_") !== -1) {
          count++;
        }
      }

      return count;
    },
    _generateFeedlotColumns: function (rs) {
      const count = this._getDayCount(rs);

      this.feedlotColumns = [
        { name: "visual" },
        { name: "eidShort" },
        { name: "tagValue" },
        { name: "animalId" },
        { name: "locationId" },
      ];

      for (let i = 1; i <= count; i++) {
        this.feedlotColumns.push({ name: `day_${i}` });
      }

      // this._makeTable();
      this._getValues();
    },
    _makeTable: function () {
      // Object that will contain the local state
      let responsiveToggle;
      let responsiveObj;

      // save the toggle
      if (localStorage.getItem("feedlot.responsiveToggle")) {
        responsiveToggle = JSON.parse(
          localStorage.getItem("feedlot.responsiveToggle")
        );
      } else {
        responsiveToggle = true;
      }

      if (responsiveToggle) {
        responsiveObj = {
          details: {
            renderer: this._renderResponsive,
          },
        };
      } else {
        responsiveObj = false;
      }

      this.table = $("#feedlotTable").DataTable({
        dom: "Blfrtip",
        mark: true,
        lengthMenu: [
          [-1, 10, 25, 50],
          ["All", 10, 25, 50],
        ],
        // serverSide: true,
        data: this.tableData,
        // ajax: this.tableData,
        responsive: responsiveObj,
        // stateSave: true,
        select: true,
        buttons: [
          {
            extend: "colvis",
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.colvis", "Toggle");
            },
          },
          {
            extend: "collection",
            text: "Select",
            autoClose: true,
            buttons: ["selectAll", "selectNone"],
          },
          {
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.move", "Move");
            },
            action: function (_e, _dt, _node, _config) {
              $("#moveModal").modal();
            },
          },
          {
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.stats", "Stats");
            },
            action: function (_e, _dt, _node, _config) {
              this._updateStatsFrame();
              $("#statsModal").modal();
            },
          },
          {
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.scatterPlot", "Plot");
            },
            action: function (_e, _dt, _node, _config) {
              const locationId = $("#locationList").val();

              if (locationId === "Choose" || locationId == null) {
                // When we use this I will convert it to notify. Currently
                // I can not convert this anything because there is no code
                // that calls this.
                // $.notify(
                //   { message: "You must choose a location to plot first." },
                //   { type: "info" }
                // );
              } else {
                window.location = `index.cfm?go=scatterPlot&plotLocation=${locationId}`;
              }
            },
          },
          {
            extend: "collection",
            text: "Export",
            buttons: [
              {
                extend: "copyHtml5",
              },
              "excelHtml5",
              "csvHtml5",
            ],
          },
          {
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.responsive", "Responsive");
            },
            action: function (_e, _dt, _node, _config) {
              responsiveToggle = !responsiveToggle;
              localStorage.setItem(
                "feedlot.responsiveToggle",
                JSON.stringify(responsiveToggle)
              );
              // When we use this I will convert it to notify. Currently
              // I can not convert this anything because there is no code
              // that calls this.
              // $.notify({ message: "Responsive toggled" }, { type: "success" });
              window.location.reload();
            },
          },
          {
            extend: "colvisGroup",
            text: function (dt, _button, _config) {
              return dt.i18n("buttons.showAll", "Show all");
            },
            show: ":hidden",
          },
          // This is the "Last 5" button. It is meant to display only the last 5 columns of data.
          // It was disabled because it wasn't working.
          // https://fortsupply.atlassian.net/browse/HERD-726 can to fix it or remove it entirely.
          // {
          //   text: function(dt, button, config) {
          //     return dt.i18n("buttons.lastFive", "lastFive");
          //   },
          //   action: function(e, dt, node, config) {
          //     for (var i = 0; i < 30; i++) {
          //       if (i === 0 || i > 25) {
          //         this.table.column(i).visible(true);
          //       } else {
          //         this.table.column(i).visible(false);
          //       }
          //     }
          //     this.table.draw();
          //   }
          // }
        ],
        columns: this.feedlotColumns,
        columnDefs: [
          // { targets: [0, 1], visible: true},
          {
            targets: [1],
            // The `data` parameter refers to the data for the cell (defined by the
            // `data` option, which defaults to the column being worked with, in
            // this case `data: 0`.
            render: function (data, _type, row) {
              return (
                '<a class="visual-tag-label" href="javascript:this._openBreakdown(\'' +
                row.tagValue +
                "');\">" +
                data +
                "</a>"
              );
            },
          },
          {
            targets: [2, 3, 4],
            visible: false,
          },
          {
            targets: "_all",
            render: function (data, type, _row) {
              // If display or filter data is requested, format the date
              if (type === "display" || type === "filter") {
                if (Utils.isGUID(data)) {
                  return data;
                } else if ($.isNumeric(data)) {
                  return Math.abs(data);
                } else {
                  return data;
                }
              }

              // Otherwise the data type requested (`type`) is type detection or
              // sorting data, for which we want to use the integer, so just return
              // that, unaltered
              return data;
            },
          },
        ],
        language: {
          url: Utils.getLabelTranslation(
            "datathis.tablesi18nPath",
            "templates/English.json"
          ),
        },
      });
    },
    _renderResponsive: function (_api, _rowIdx, columns) {
      let data = $.map(columns, function (col, _i) {
        let sClass = "";
        if (col.data <= 0) {
          sClass = "warning";
        }

        return col.hidden
          ? '<tr data-dt-row="' +
              col.rowIndex +
              '" data-dt-column="' +
              col.columnIndex +
              '">' +
              "<td>" +
              col.title +
              ":" +
              "</td> " +
              '<td class="' +
              sClass +
              '">' +
              col.data +
              "</td>" +
              "</tr>"
          : "";
      }).join("");

      return data ? $("<this.table/>").append(data) : false;
    },
    _openBreakdown: function (tagValue) {
      $("#breakdownFrame").attr("src", `?go=breakdown&tagValue=${tagValue}`);
      $("#breakdownModal").modal();
    },
    _startSetup: function () {
      // TODO: remove upon login or not at all
      // localStorage.removeItem("feedlot.feedlotLocationId");

      if (localStorage.getItem("feedlot.feedlotLocationId")) {
        this._finishSetup();
      } else {
        this.localOrgDB
          .find({
            selector: {
              tableName,
            },
            limit: 1,
          })
          .then(function (result) {
            if (result.docs.length > 0)
              localStorage.setItem(
                "feedlot.feedlotLocationId",
                result.docs[0].id
              );
            this._finishSetup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    _finishSetup: function () {
      this.localOrgDB
        .find({
          selector: {
            tableName,
          },
        })
        .then(function (result) {
          this._populateLocationList(result);
        })
        .catch((err) => {
          console.log(err);
        });

      this.localFeedlotDB
        .find({
          selector: {
            tableName: this._getTableName(),
          },
        })
        .then(function (result) {
          this._makeTableSetup(result);
          this._getSickList(result);
          this._getSicknessThreshold();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    _getTableName: function () {
      // deepcode ignore InsecureHash: <Not used for security. Used as replacement for base64encode minus the ability to decode. Also to be backwards-compatible.>
      const md5part = md5(localStorage.getItem("feedlot.feedlotLocationId"));

      if (document.getElementById("completeTagList").checked) {
        return `sick_values_complete_${md5part}`;
      }
      return `sick_values_${md5part}`;
    },
    _setupPouch: function () {
      const orgName = `organization-${this.$organizationID}`;
      const feedlotName = `feedlot-${this.$organizationID}`;

      this.localFeedlotDB = new PouchDB(feedlotName);
      this.localOrgDB = new PouchDB(orgName);

      const couchURL = `/couchdb/`;

      let remoteFeedlotDB = new PouchDB(couchURL + feedlotName, {
        skip_setup: true,
      });
      let remoteOrgDB = new PouchDB(couchURL + orgName, { skip_setup: true });

      remoteFeedlotDB
        .login("admin", this.$pouchPass)
        .then(function () {
          // logged in
        });

      remoteOrgDB.login("admin", this.$pouchPass).then(function () {
        // logged in
      });

      this.localOrgDB
        .sync(remoteOrgDB)
        .on("complete", function () {
          this.localFeedlotDB.replicate
            .from(remoteFeedlotDB)
            .on("complete", function (_info) {
              this._startSetup();
            })
            .on("error", function (err) {
              console.log(err);
            });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    _colorCoding: function () {
      this.table.rows().every(function (rowIdx, tableLoop, _rowLoop) {
        for (let i = 0; i < this.table.columns().count(); i++) {
          const d = this.table.cell({ row: rowIdx, column: i }).data();
          if (d <= 0) {
            const cell = this.table.cell({ row: rowIdx, column: i }).node();
            $(cell).addClass("warning");
          }
        }
      });
    },
    _updateStatsFrame: function () {
      $("#statsFrame").attr(
        "src",
        "index.cfm?go=stats" +
          "&locationId=" +
          $("#locationList").val() +
          "&sicknessThreshold=" +
          $("#sicknessThreshold").val()
      );

      const selected = $("#locationList option:selected").text();
      if (selected === "Choose") {
        $("#statsLabel").text("Stats");
      } else {
        $("#statsLabel").text("Stats for " + selected);
      }
    },
    _getSickList: function (result) {
      let count = this._getDayCount(result);
      for (let i = 1; i <= count; i++) {
        this._getSickCount(i);
      }
    },
    _getSickCount: function (day) {
      const selector = {
        selector: {
          tableName: this._getTableName(),
        },
      };
      selector.selector["day_" + day] = { $lte: 0 };

      this.localFeedlotDB
        .find(selector)
        .then(function (result) {
          this._populateSickList(day, result.docs.length);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    _populateSickList: function (day, sickCount) {
      const selector = {
        selector: {
          tableName: "locations",
          id: document.getElementById("locationList").value,
        },
      };

      this.localOrgDB
        .find(selector)
        .then(function (result) {
          startDate = result.docs[0].startDate;
          this._addSickListItem(startDate, day, sickCount);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    _addSickListItem: function (startDate, day, sickCount) {
      let dateFormatted = moment(startDate, "MMM, D YYYY hh:mm:ss");
      dateFormatted.add(day, "day");
      const dayAttr = `day_${day}`;
      const selectText = `${dateFormatted.format("M/D/YYYY")} - ${sickCount}`;
      $("#sickList").append(
        $("<option></option>").attr("value", dayAttr).text(selectText)
      );
    },
    _populateLocationList: function (rs) {
      for (let i = 0; i < rs.docs.length; i++) {
        const key = rs.docs[i].id;
        const value = rs.docs[i].name;

        $("#locationList").append(
          $("<option></option>").attr("value", key).text(value)
        );
      }

      document.getElementById("locationList").value = localStorage.getItem(
        "feedlot.feedlotLocationId"
      );
    },
    _moveAnimal: function (data, destinationId) {
      let deferreds = [];
      for (let i = 0; i < data.length; i++) {
        deferreds.push(
          $.post("templates/moveAnimal.cfm", {
            animalId: data[i].animalId,
            destinationId: destinationId,
            locationId: data[i].locationId,
          })
        );
      }

      return deferreds;
    },
    _getSicknessThreshold: function () {
      this.localOrgDB
        .find({
          selector: {
            tableName: "locations",
            id: localStorage.getItem("feedlot.feedlotLocationId"),
          },
        })
        .then(function (result) {
          if (result.docs.length === 0) {
            document.getElementById("sicknessThreshold").value = "N/A";
            document
              .getElementById("sicknessThreshold")
              .setAttribute("disabled", true);
            return;
          }
          document.getElementById("sicknessThreshold").value =
            result.docs[0].sicknessThreshold;
          document
            .getElementById("sicknessThreshold")
            .setAttribute("disabled", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bindEvents: function () {
      $("#sickList").on("change", function () {
        let selector = {
          selector: {
            tableName: this._getTableName(),
          },
        };
        selector.selector[this.value] = { $gte: 0 };

        this.localFeedlotDB
          .find({
            selector: selector.selector,
          })
          .then(function (result) {
            console.log(result);
            this._displaySickAnimals(result, this.value);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      $("#locationList").on("change", function () {
        const feedlotLocationId = document.getElementById("locationList").value;
        localStorage.setItem("feedlot.feedlotLocationId", feedlotLocationId);
        window.location.reload();
      });

      $("#sicknessThreshold").on("change", function () {
        const locationId = $("#locationList").val();
        const sicknessThreshold = $("#sicknessThreshold").val();

        this.localFeedlotDB
          .get(locationId)
          .then(function (doc) {
            doc.sicknessThreshold = sicknessThreshold;
            return this.localFeedlotDB.put(doc);
          })
          .then(function () {
            return this.localFeedlotDB.get(locationId);
          })
          .then(function (_doc) {
            // done
          });

        this._updateFeedlot();
      });

      $("#completeTagList").on("change", function () {
        const completeTagListChecked =
          document.getElementById("completeTagList").checked;
        localStorage.setItem(
          "feedlot.completeTagListChecked",
          completeTagListChecked
        );
        window.location.reload();
      });

      $('input[name="dateRange"]').on(
        "apply.daterangepicker",
        function (_ev, _picker) {
          this._updateFeedlot();
        }
      );

      $("#moveButton").on("click", function () {
        let data = this.table.rows({ selected: true }).data();
        let destinationId = document.getElementById("moveLocationList").value;

        let deferreds = this._moveAnimal(data, destinationId);

        $.when.apply($, deferreds).done(function () {
          this.table.destroy();
          this._makeTable();

          $("#moveModal").modal("hide");
        });
      });

      $("#sendToArcherButton").on("click", function () {
        const data = $("#sickListForm").serialize();
        $.post("api/fast-track/sendToArcher.cfm", data);
      });
    },
    _updateFeedlot: function () {
      let locationId = $("#locationList").val();
      document.getElementById("sTableName").value =
        // deepcode ignore InsecureHash: <Not used for security. Used as replacement for base64encode minus the ability to decode. Also to be backwards-compatible.>
        "sick_values_" + md5(locationId);
      this._updateSickValues();
    },
    _updateSickValues: function () {
      const urlVars = {
        endDate: $("#dateRange").data("daterangepicker").endDate.toISOString(),
        locationId: $("#locationList").val(),
        sicknessThreshold: $("#sicknessThreshold").val(),
        startDate: $("#dateRange")
          .data("daterangepicker")
          .startDate.toISOString(),
      };

      $.get("cron/updateLocation.cfm", urlVars, function (_data) {
        $cookies.set(
          "FEEDLOTLOCATIONID",
          document.getElementById("locationList").value
        );
        window.location.reload();
      });
    },
    _displaySickAnimals: function (rs, colIdx) {
      const title = this.table.columns(colIdx).header();

      const sickVisualList = [];
      const sickEIDlist = [];

      $("#sickModal").modal();
      $("#sickAnimals").empty();

      for (let i = 0; i < rs.docs.length; i++) {
        $("#sickAnimals").append(
          "<li>" + rs.docs[i].visual + ", " + rs.docs[i].eid + "</li>"
        );
        sickVisualList.push(rs.docs[i].visual);
        sickEIDlist.push(rs.docs[i].eid);
      }

      document.getElementById("sickEID").value = JSON.stringify(sickEIDlist);
      document.getElementById("sickLocationId").value =
        document.getElementById("locationList").value;
      document.getElementById("sickOrganizationId").value =
        this.$organizationID;
      document.getElementById("sickVisual").value =
        JSON.stringify(sickVisualList);

      $("#sickAnimalsLabel").text(
        `${rs.docs.length} sick animal(s) on ${$(title).html()}`
      );
    },
  },
};
</script>
