<template>
  <v-dialog v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="herd-form"
            ref="herdForm"
            v-model="formValid"
          >
            <v-text-field
              :label="getTranslation('animalsPages.herdName')"
              :rules="[required]"
              autocomplete="off"
              required
              v-model.trim="herd.name"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="herd-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "HerdDialog",
  props: ["herdMeta"],
  mixins: [TranslationMixin],
  data() {
    return {
      herd: { id: "", archived: "", name: "", rank: "" },
      formValid: false,
      submitting: false,
      showDialog: false,
    };
  },
  computed: {
    title() {
      return this.herd.id
        ? this.getTranslation("animalsPages.updateHerd")
        : this.getTranslation("animalsPages.createNewHerd");
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.herdForm.resetValidation();
      }
    },
  },
  methods: {
    open(herdToEdit = {}) {
      Object.assign(this.herd, herdToEdit);
      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    async save() {
      if (!this.$refs.herdForm.validate() || this.submitting) return;

      this.submitting = true;
      try {
        if (this.herd.id) {
          await this.herdMeta.update("herds", {
            name: this.herd.name,
            id: this.herd.id,
          });
        } else {
          await this.herdMeta.create("herds", this.herd, "herd");
        }
      } catch (e) {
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again."
        );
        this.submitting = false;
        return;
      }

      this.$emit("saved");
      this.showDialog = false;
    },
  },
};
</script>
