<template>
  <v-dialog max-width="500" scrollable v-model="showDialog">
    <v-card>
      <v-card-title>
        <div>
          <div class="text-h5">{{ title }}</div>
          <div class="body-2">{{ organization.name }}</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="transaction-form"
            ref="transactionForm"
            v-model="formValid"
          >
            <v-menu
              :close-on-content-click="false"
              min-width="auto"
              offset-y
              transition="scale-transition"
              v-model="calendar"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mb-6"
                  hide-details
                  label="Date"
                  readonly
                  v-bind="attrs"
                  v-model="form.date"
                  v-on="on"
                />
              </template>
              <v-date-picker
                :max="finalDayOfMonth"
                :min="initialDayOfMonth"
                @input="calendar = false"
                v-model="form.date"
              />
            </v-menu>
            <v-select
              :items="categories"
              :rules="[required]"
              autofocus
              item-text="name"
              item-value="value"
              label="Category"
              required
              v-model="form.transaction_name"
            />
            <v-textarea
              :rules="[required]"
              auto-grow
              autocomplete="off"
              label="Description"
              required
              rows="0"
              v-model="form.description"
            />
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Calves"
                type="number"
                v-model="form.calves"
              />
              <v-text-field label="RPH" type="number" v-model="form.rph" />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="TWO"
                type="number"
                v-model="form.two"
              />
              <v-text-field label="Cows" type="number" v-model="form.cows" />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Bulls"
                type="number"
                v-model="form.bulls"
              />
              <v-text-field
                label="Steers"
                type="number"
                v-model="form.steers"
              />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Heifers"
                type="number"
                v-model="form.heifers"
              />
              <v-text-field
                :disabled="true"
                :value="totalNewCattle"
                label="Total cattle"
                type="number"
              />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Hay"
                type="number"
                v-model="form.hay"
              />
              <v-text-field label="Cake" type="number" v-model="form.cake" />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Salt"
                type="number"
                v-model="form.salt"
              />
              <v-text-field
                label="Mineral"
                type="number"
                v-model="form.mineral"
              />
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mr-2"
                label="Calf pellets"
                type="number"
                v-model="form.calf_pellets"
              />
              <v-text-field
                label="Protein"
                type="number"
                v-model="form.protein"
              />
            </div>
            <v-text-field
              label="Other Feed"
              type="number"
              v-model="form.other_feed"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="transaction-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import TranslationMixin from "@/mixins/Translations";

export default {
  props: ["monthValue", "yearValue"],
  mixins: [TranslationMixin],
  data: () => ({
    calendar: false,
    formValid: false,
    organization: {},
    showDialog: false,
    submitting: false,
    categories: [
      { name: "Beginning", value: "BEGINNING" },
      { name: "Balance", value: "BALANCE" },
      { name: "Sales", value: "SALES" },
      { name: "Transfers In", value: "TRANSFERS IN" },
      { name: "Transfers Out", value: "TRANSFERS OUT" },
      { name: "Consumption", value: "CONSUMPTION" },
      { name: "Death Loss", value: "DEATH LOSS" },
      { name: "In Adjustment", value: "IN ADJUSTMENT" },
      { name: "Moves In", value: "MOVES IN" },
      { name: "Moves Out", value: "MOVES OUT" },
      { name: "Out Adjusments", value: "OUT ADJUSTMENTS" },
      { name: "Purchases", value: "PURCHASES" },
      { name: "Raised", value: "RAISED" },
      { name: "Undefined", value: "UNDEFINED" },
    ],
    form: {
      bulls: 0,
      cake: 0,
      calf_pellets: 0,
      calves: 0,
      cows: 0,
      date: "",
      description: "",
      hay: 0,
      heifers: 0,
      id: undefined,
      mineral: 0,
      other_feed: 0,
      protein: 0,
      rph: 0,
      salt: 0,
      steers: 0,
      total_cattle: 0,
      transaction_name: "",
      two: 0,
    },
    insert: "I",
    update: "U",
    delete: "D",
  }),
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.transactionForm.resetValidation();
      }
    },
  },
  computed: {
    ...mapGetters({ getUserID: "User/getUserID" }),
    title: function () {
      const action = this.form.id ? "Update" : "Create";
      return action + " transaction";
    },
    inventoryDate: function () {
      const year = this.yearValue;
      const month = this.monthValue;
      return `${year}-${month}`;
    },
    initialDayOfMonth: function () {
      const day = moment(`${this.inventoryDate}-01`).startOf("month");

      if (day.unix() == moment().startOf("month").unix()) {
        // If it is for this month, default to today
        this.form.date = moment().format("YYYY-MM-DD");
      } else {
        // default to first day of that month
        this.form.date = day.format("YYYY-MM-DD");
      }
      return day.format("YYYY-MM-DD");
    },
    finalDayOfMonth: function () {
      return moment(`${this.inventoryDate}-01`)
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    totalNewCattle: function () {
      this.form.total_cattle =
        +this.form.bulls +
        +this.form.calves +
        +this.form.cows +
        +this.form.heifers +
        +this.form.rph +
        +this.form.steers +
        +this.form.two +
        0;
      return this.form.total_cattle;
    },
  },
  methods: {
    open: function (organization, transactionToEdit = {}) {
      Object.assign(this.organization, organization);
      Object.assign(this.form, transactionToEdit);
      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    performSave: function () {
      const action = this.form.id ? this.update : this.insert;
      const data = { ...this.form, userId: this.getUserID };
      return this.newPostRequest(action, data, this.organization.id);
    },
    newPostRequest: function (action, data, organizationId) {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const params = { action, organizationId };
      const url = "/herd-api/api/organization/inventoryAction.cfm";
      return new Promise((resolve, reject) => {
        axios
          .post(url, data, { headers, params })
          .then((res) => resolve(res))
          .catch((err) => resolve(null));
      });
    },
    save: async function () {
      if (!this.$refs.transactionForm.validate() || this.submitting) return;

      this.submitting = true;
      let response;
      try {
        response = await this.performSave();
      } catch (e) {
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again.",
          e
        );
        this.submitting = false;
        return;
      }

      if (response === null) {
        return this.$notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: this.form.id
            ? "Error updating item"
            : "Error creating transaction. You miss to fill some field.",
        });
      }

      this.$emit("saved");
      this.showDialog = false;
      this.$notify({
        group: "forms",
        type: "success",
        title: "Success",
        text: this.form.id
          ? "Item updated"
          : "New transaction added successfully",
      });
    },
  },
};
</script>
