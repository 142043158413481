<template>
  <div>
    <online-warning></online-warning>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col>
        <div>
          <h4>{{ getTranslation("Readers And Groups") }}</h4>
          <v-divider class="mt-0"></v-divider>
        </div>
        <!-- Controls -->
        <div class="d-flex">
          <export-button
            :filename="`Readers_and_Groups_${new Date().toISOString()}`"
            :headers="tableHeaders"
            :items="table.data"
          />
          <v-btn
            @click="openCreateDialog"
            color="primary"
            depressed
            text
            v-if="superAdmin"
            >Create</v-btn
          >
          <v-btn
            :disabled="!table.selected.length"
            @click="openEditDialog"
            color="primary"
            depressed
            text
            v-if="superAdmin || organizationAdmin"
            >Edit</v-btn
          >
          <v-btn
            :disabled="!table.selected.length"
            @click="deleteDialog.show = true"
            color="primary"
            depressed
            text
            v-if="superAdmin"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            :label="getTranslation('search')"
            class="mb-2"
            clearable
            dense
            hide-details
            outlined
            style="max-width: 300px"
            v-model="table.search"
          ></v-text-field>
        </div>
        <!-- Table -->
        <v-data-table
          :headers="tableHeaders"
          :items="table.data"
          :loading="table.loading"
          :search="table.search"
          class="elevation-1"
          show-select
          v-model="table.selected"
        >
          <template #item.createdOn="{ item }">
            <div>
              {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
            </div>
          </template>
          <template #item.lastUpdated="{ item }">
            <div>
              {{ $utils.renderValueAs(item.lastUpdated, "datetime", true) }}
            </div>
          </template>
          <template #item.summary.latest.datetime="{ item }">
            <div v-if="item.summary.latest.datetime">
              {{
                $utils.renderValueAs(
                  item.summary.latest.datetime,
                  "datetime",
                  true
                )
              }}
            </div>
          </template>
          <template #item.summary.latest.eid="{ item }">
            <div v-if="item.summary.latest.eid">
              {{ String(item.summary.latest.eid).substr(0, 11)
              }}<span class="font-weight-bold"
                >{{ String(item.summary.latest.eid).substr(11, 4) }}
              </span>
            </div>
          </template>
          <template #item.summary="{ item }">
            <v-btn
              v-if="item.summary.aggregates.length > 0"
              @click="showAggregates(item.id)"
            >
              View
            </v-btn>
          </template>
          <template #item.isDestination="{ item }">
            <v-checkbox
              :disabled="!!item.isOrigin"
              @change="updateReaderStatus(item)"
              dense
              v-model="item.isDestination"
            />
          </template>
          <template #item.isOrigin="{ item }">
            <v-checkbox
              :disabled="!!item.isDestination"
              @change="updateReaderStatus(item)"
              dense
              v-model="item.isOrigin"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Aggregates Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="aggregatesDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>{{ getTranslation("Recent Data") }}</h4>
          <v-icon class="buttons" @click="aggregatesDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-data-table
            :headers="[
              {
                text: getTranslation('EID'),
                value: 'eid',
              },
              { text: getTranslation('time'), value: 'datetime' },
            ]"
            :items="getSummaryAggreggatesForSelectedReader"
            :loading="table.loading"
            :search="table.search"
            class="elevation-1"
            sort-by="datetime"
            sort-desc
          >
            <template #item.datetime="{ item }">
              <div v-if="item.datetime">
                {{ $utils.renderValueAs(item.datetime, "datetime", true) }}
              </div>
            </template>
            <template #item.eid="{ item }">
              <div v-if="item.eid">
                {{ String(item.eid).substr(0, 11)
                }}<span class="font-weight-bold"
                  >{{ String(item.eid).substr(11, 4) }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="aggregatesDialog.show = false" color="error">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Create Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="createDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Create New Reader</h4>
          <v-icon class="buttons" @click="createDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-form v-model="createDialog.formIsValid">
            <v-text-field
              :label="getTranslation('Group')"
              class="mb-2"
              clearable
              hide-details
              outlined
              v-model="createDialog.fields.group"
            ></v-text-field>
            <v-select
              :error="$utils.isWhitespace(createDialog.fields.reader_box)"
              :items="items.reader_boxes"
              :label="getTranslation('Reader Box')"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              class="mb-2"
              hide-details
              outlined
              v-model="createDialog.fields.reader_box"
            ></v-select>
            <v-text-field
              :label="getTranslation('description')"
              class="mb-2"
              clearable
              hide-details
              outlined
              v-model="createDialog.fields.friendlyName"
            ></v-text-field>
            <v-select
              :error="$utils.isWhitespace(createDialog.fields.antenna)"
              :items="[1, 2, 3, 4, 5, 6, 7, 8]"
              :label="getTranslation('antenna')"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              class="mb-2"
              hide-details
              multiple
              outlined
              v-model="createDialog.fields.antenna"
            ></v-select>
            <v-text-field
              :error="!+createDialog.fields.latitude"
              :label="getTranslation('latitude')"
              @input="
                updatePosition({
                  lat: +createDialog.fields.latitude,
                  lng: +createDialog.fields.longitude,
                })
              "
              class="mb-2"
              clearable
              hide-details
              outlined
              v-model="createDialog.fields.latitude"
            ></v-text-field>
            <v-text-field
              :error="!+createDialog.fields.longitude"
              :label="getTranslation('longitude')"
              @input="
                updatePosition({
                  lat: +createDialog.fields.latitude,
                  lng: +createDialog.fields.longitude,
                })
              "
              class="mb-2"
              clearable
              hide-details
              outlined
              v-model="createDialog.fields.longitude"
            ></v-text-field>
          </v-form>
          <div v-if="googleMapIsNotAvailable">
            <p>Map API is not available right now.</p>
          </div>
          <div
            :class="{ hide: !googleMapIsLoaded }"
            class="mb-2"
            ref="createDialog"
            style="width: 100%; height: 500px"
            v-else
          ></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="!createDialog.formIsValid"
            :loading="createDialog.loading"
            @click="createNewReader"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="editDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Edit Reader</h4>
          <v-icon class="buttons" @click="editDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-form v-model="editDialog.formIsValid">
            <v-text-field
              :label="getTranslation('description')"
              class="mb-2"
              clearable
              hide-details
              outlined
              v-if="
                (superAdmin || organizationAdmin) && table.selected.length == 1
              "
              v-model="editDialog.fields.friendlyName"
            ></v-text-field>
            <v-text-field
              :label="getTranslation('Group')"
              class="mb-2"
              clearable
              hide-details
              outlined
              v-model="editDialog.fields.group_name"
            ></v-text-field>
            <div
              v-if="
                (superAdmin || organizationAdmin) && !googleMapIsNotAvailable
              "
            >
              <v-switch
                @change="toggleGPSoverride"
                class="mt-0 py-2 mr-4"
                color="primary"
                hide-details
                label="Override location"
                v-if="editDialog.override !== null"
                v-model="editDialog.override"
              >
              </v-switch>
              <template
                v-if="editDialog.override || editDialog.override === null"
              >
                <v-text-field
                  :error="!+editDialog.fields.latitude"
                  :label="getTranslation('latitude')"
                  @input="
                    updatePosition({
                      lat: +editDialog.fields.latitude,
                      lng: +editDialog.fields.longitude,
                    })
                  "
                  class="mb-2"
                  clearable
                  hide-details
                  outlined
                  v-model="editDialog.fields.latitude"
                ></v-text-field>
                <v-text-field
                  :error="!+editDialog.fields.longitude"
                  :label="getTranslation('longitude')"
                  @input="
                    updatePosition({
                      lat: +editDialog.fields.latitude,
                      lng: +editDialog.fields.longitude,
                    })
                  "
                  class="mb-2"
                  clearable
                  hide-details
                  outlined
                  v-model="editDialog.fields.longitude"
                ></v-text-field>
              </template>
              <div v-if="googleMapIsNotAvailable">
                <p>Map API is not available right now.</p>
              </div>
              <div
                :class="{
                  hide: !googleMapIsLoaded || editDialog.override === false,
                }"
                class="mb-2"
                ref="editDialog"
                style="width: 100%; height: 500px"
                v-else
              ></div>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="!editDialog.formIsValid"
            @click="editReader"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="deleteDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Delete Reader</h4>
          <v-icon class="buttons" @click="deleteDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          Are you sure you want to delete {{ table.selected.length }} records?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="deleteReader" color="error"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import { mapGetters } from "vuex";
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
export default {
  name: "Readers",
  metaInfo: {
    title: "Readers And Groups",
  },
  mixins: [TranslationMixin],
  data() {
    return {
      aggregatesDialog: {
        selected: null,
        show: false,
      },
      createDialog: {
        fields: {
          antenna: [],
          friendlyName: null,
          group: null,
          latitude: null,
          longitude: null,
          reader_box: null,
        },
        formIsValid: true,
        loading: false,
        show: false,
      },
      deleteDialog: {
        show: false,
      },
      editDialog: {
        fields: {
          friendlyName: null,
          group_name: null,
          latitude: null,
          longitude: null,
          reader_box: null,
        },
        formIsValid: true,
        override: false,
        show: false,
      },
      googleMapIsLoaded: false,
      googleMapIsNotAvailable: false,
      intervals: [],
      items: {
        reader_boxes: [],
      },
      map: null,
      marker: null,
      organizationAdmin: false,
      showGoogleMaps: false,
      superAdmin: false,
      table: {
        data: [],
        loading: false,
        search: null,
        selected: [],
      },
    };
  },
  computed: {
    getSummaryAggreggatesForSelectedReader: function () {
      if (!this.aggregatesDialog.selected) return [];
      return this.table.data
        .filter(({ id }) => id === this.aggregatesDialog.selected)
        .map(({ summary }) => summary.aggregates)
        .find((item) => item);
    },
    tableHeaders: function () {
      return [
        // { text: this.getTranslation("id"), value: "id" },
        { text: this.getTranslation("Group"), value: "group_name" },
        { text: this.getTranslation("Reader Box"), value: "reader_box" },
        { text: this.getTranslation("description"), value: "friendlyName" },
        { text: this.getTranslation("antenna"), value: "antenna" },
        { text: this.getTranslation("latitude"), value: "latitude" },
        { text: this.getTranslation("longitude"), value: "longitude" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
        { text: this.getTranslation("Last Updated"), value: "lastUpdated" },
        { text: this.getTranslation("archived"), value: "deleted" },
        {
          text: this.getTranslation("Last Read Time"),
          value: "summary.latest.datetime",
        },
        {
          text: this.getTranslation("Last EID"),
          value: "summary.latest.eid",
        },
        { text: this.getTranslation("Recent Data"), value: "summary" },
        { text: this.getTranslation("Is Destination"), value: "isDestination" },
        { text: this.getTranslation("Is Origin"), value: "isOrigin" },
      ];
    },
  },
  beforeDestroy: function () {
    this.intervals.forEach((interval) => clearInterval(interval));
  },
  created: async function () {
    this.fillSelectsOptions();
    this.getTableData();
    try {
      const { organizationAdmin, superAdmin } = await this.isUserAdmin();
      this.superAdmin = superAdmin;
      this.organizationAdmin = organizationAdmin;
    } catch (error) {
      this.$notify({
        group: "forms",
        text: "An error occurred when trying to ask permissions.",
        title: "Error",
        type: "error",
      });
    }

    if (this.intervals.length > 0) {
      this.intervals.forEach((interval) => clearInterval(interval));
      this.intervals = []
    }
  },
  methods: {
    ...mapGetters({
      getToken: "User/getToken",
    }),
    updateReaderStatus: async function (reader) {
      try {
        this.table.loading = true;
        if (reader.isDestination == true && reader.isOrigin == true)
          throw new Error(
            "A reader can not be destination and origin at the same time"
          );
        const readers = this.table.data.filter(
          (row) => row.reader_box == reader.reader_box
        );
        const ids = readers.reduce((prev, current) => {
          current.isOrigin = reader.isOrigin;
          current.isDestination = reader.isDestination;
          prev.push(current.id);
          return prev;
        }, []);
        const body = {
          ids,
          isDestination: Number(reader.isDestination),
          isOrigin: Number(reader.isOrigin),
        };
        await axios.patch("/api/readers/update-status", body, {
          params: {
            token: this.getToken(),
          },
        });
      } catch (error) {
        this.$notify({
          group: "forms",
          ignoreDuplicates: true,
          text: error.message || "An error occurred",
          title: "Error",
          type: "error",
        });
      } finally {
        this.table.loading = false;
      }
    },
    fillSelectsOptions: function () {
      axios
        .get("/api/reader_boxes", {
          params: { token: this.getToken() },
        })
        .then(({ data }) => {
          this.items.reader_boxes = data;
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "We can not get the reader boxes.",
          });
          console.log(e);
        });
    },
    getTableData: async function () {
      const route = this.$superAdmin
        ? "/api/readers"
        : `/api/readers/${this.$organizationID}`;
      axios
        .get(route, {
          params: { token: this.getToken() },
          timeout: 10 * 1000,
        })
        .then(({ data }) => {
          this.table.data = data.map((data) => {
            // These will be populated in interval loops to follow
            data.summary = {
              aggregates: [],
              latest: {
                datetime: null,
                eid: null,
              },
              polling: false,
            };

            return data;
          });

          this.setupNewIntervals(this.table.data);
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "An error occurred. We can not get the reader list.",
          });
        });
    },
    createNewReader: async function () {
      this.createDialog.loading = true;
      try {
        await Promise.all(
          this.createDialog.fields.antenna.map(async (antenna) => {
            await axios.post(
              "/api/readers",
              {
                antenna: antenna,
                friendlyName: this.createDialog.fields.friendlyName
                  ? this.createDialog.fields.friendlyName.trim()
                  : "",
                group: this.createDialog.fields.group
                  ? this.createDialog.fields.group.trim()
                  : this.createDialog.fields.group,
                latitude: +this.createDialog.fields.latitude,
                longitude: +this.createDialog.fields.longitude,
                organizationId: this.$organizationID,
                reader_box: this.createDialog.fields.reader_box,
              },
              {
                params: { token: this.getToken() },
              }
            );
          })
        );
        await this.getTableData();
        this.$notify({
          group: "forms",
          type: "success",
          title: "Success",
          text: "Reader created",
        });
      } catch (error) {
        console.log((e && e.response && e.response.data) || e);
        this.$notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "An error occurred. We can not create the reader",
        });
      } finally {
        this.createDialog.loading = false;
      }
      this.createDialog.show = false;
    },
    openCreateDialog: async function () {
      this.createDialog.show = true;
      this.createDialog.fields.reader_box = null;
      this.createDialog.fields.antenna = null;
      this.createDialog.fields.friendlyName = null;
      this.createDialog.fields.group = null;
      const geoLocation = await this.$utils.getGeoLocation();
      this.createDialog.fields.latitude = geoLocation.latitude;

      this.createDialog.fields.longitude = geoLocation.longitude;
      this.createDialog.loading = false;
      await this.initializeMaps("createDialog");
    },
    openEditDialog: async function () {
      this.editDialog.show = true;

      if (this.table.selected.length > 1) {
        this.editDialog.fields.group_name = Object.keys(
          this.table.selected.reduce((reduction, record) => {
            if (record.group_name) reduction[record.group_name] = true;
            return reduction;
          }, {})
        ).join(", ");

        const geoLocation = await this.$utils.getGeoLocation();
        this.editDialog.fields.latitude = geoLocation.latitude;
        this.editDialog.fields.longitude = geoLocation.longitude;
      } else {
        this.editDialog.override = null;
        const firstItem = this.table.selected[0];
        this.editDialog.fields.friendlyName = firstItem.friendlyName;
        this.editDialog.fields.group_name = firstItem.group_name;

        this.editDialog.fields.latitude = +firstItem.latitude;
        this.editDialog.fields.longitude = +firstItem.longitude;
      }

      if (this.superAdmin || this.organizationAdmin)
        await this.initializeMaps("editDialog");
    },
    deleteReader: function () {
      const promises = this.table.selected.map((reader) => {
        return axios.delete(`/api/readers/${reader.id}`, {
          params: { token: this.getToken() },
        });
      });

      Promise.all(promises)
        .then(() => {
          this.getTableData();
          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Records deleted",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "We can not delete.",
          });
          console.log(e.response.data);
        });

      this.deleteDialog.show = false;
      this.table.selected = [];
    },
    editReader: function () {
      const promises = this.table.selected.map((reader) => {
        if (this.table.selected.length == 1)
          reader.friendlyName = this.editDialog.fields.friendlyName;
        reader.group_name = this.editDialog.fields.group_name;

        // Override latitude and longitude only if provided
        if (
          (this.table.selected.length === 1 || this.editDialog.override) &&
          (this.editDialog.fields.latitude || this.editDialog.fields.longitude)
        ) {
          reader.latitude = this.editDialog.fields.latitude;
          reader.longitude = this.editDialog.fields.longitude;
        }

        return axios.patch(
          `/api/readers`,
          {
            alley: reader.alley,
            friendlyName: reader.friendlyName,
            group_name: reader.group_name,
            id: reader.id,
            latitude: reader.latitude,
            longitude: reader.longitude,
            reader_box: reader.reader_box,
          },
          {
            params: { token: this.getToken() },
          }
        );
      });

      Promise.all(promises)
        .then(() => {
          this.getTableData();
          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Records edited",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "We can not edit.",
          });
          console.log(e.response.data);
        });
      this.editDialog.show = false;
      this.table.selected = [];
    },
    setupNewIntervals: function (readers) {
      this.intervals.forEach((interval) => clearInterval(interval));

      const intervalTime = 30 * 1000;
      const firstTime = new Date();
      firstTime.setHours(0, 0, 0, 0);
      firstTime.setDate(firstTime.getDate() - 300);

      let since;
      this.intervals = this.intervals.concat(
        readers.map((reader) => {
          if (reader.polling) return;
          reader.polling = true;

          since = since || firstTime;
          const getSummary = () => {
            const callStart = new Date();

            axios
              .get(
                `/api/fast-track/reader/${
                  reader.id
                }/reads-summary/${since.toISOString()}`,
                {
                  params: { token: this.getToken() },
                  timeout: 20 * 1000,
                }
              )
              .then(({ data }) => {
                reader.summary.aggregates = data.aggregates.concat(
                  reader.summary.aggregates
                );
                reader.summary.latest =
                  data.latest.find((first) => first) || reader.summary.latest;

                // console.debug("old since", since);
                if (
                  reader.summary.aggregates.length &&
                  new Date(reader.summary.latest.datetime) > new Date(since)
                ) {
                  since = new Date(reader.summary.latest.datetime);
                  console.debug("since: using new value", since);
                } else {
                  // No data came through, therefore no change is necessary
                  console.debug("since: continuing with old value", since);
                }
                console.debug("new since", since);

                reader.polling = false;
              })
              .catch((e) => {
                reader.polling = false;
                // console.log(e);
              });
          };

          getSummary();

          return setInterval(getSummary, intervalTime);
        })
      );
    },
    showAggregates: function (reader_id) {
      this.aggregatesDialog.selected = reader_id;
      this.aggregatesDialog.show = true;
    },
    toggleGPSoverride: function () {
      editDialog.override = !editDialog.override;
    },
    updatePosition: async function (position) {
      if (!+position.lat || !+position.lng) {
        const geoLocation = await this.$utils.getGeoLocation();
        if (!position.lat) position.lat = geoLocation.latitude;
        if (!position.lng) position.lng = geoLocation.longitude;
        this.$notify({
          group: "forms",
          text: "You must select a valid position.",
          title: "Error",
          type: "error",
        });
      }
      this.marker.setPosition(position);
      this.marker.setTitle(`Lat: ${position.lat} \nLng: ${position.lng}`);
      this.map.setCenter(position);
    },
    initializeMaps: async function (dialogType) {
      if (!this.$google) {
        const googleMapApi = new Loader({
          apiKey: "AIzaSyDTFTTJGbg02sid2CsYocV11bSFvEY5hDg",
        });
        try {
          await googleMapApi.load();
          this.$google = window.google;
        } catch (error) {
          this.googleMapIsLoaded = false;
          this.googleMapIsNotAvailable = true;
          return;
        }
      }
      this.googleMapIsNotAvailable = false;
      this.$nextTick(() => {
        this.googleMapIsLoaded = true;
        const mapContainer = this.$refs[dialogType];
        const currentPosition = {
          lat: this[dialogType].fields.latitude,
          lng: this[dialogType].fields.longitude,
        };
        const mainMapOption = {
          center: currentPosition,
          zoom: 12,
        };
        this.map = new this.$google.maps.Map(mapContainer, mainMapOption);

        this.marker = new this.$google.maps.Marker({
          map: this.map,
          position: currentPosition,
          title: `Lat: ${this[dialogType].fields.latitude} \nLng: ${this[dialogType].fields.longitude}`,
        });

        this.map.addListener("click", async (e) => {
          const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          await this.updatePosition(position);
          this[dialogType].fields.latitude = position.lat;
          this[dialogType].fields.longitude = position.lng;
        });
      });
    },
  },
  watch: {
    "createDialog.show": function (show) {
      if (!show) this.$google.maps.event.clearInstanceListeners(this.map);
    },
  },
};
</script>
