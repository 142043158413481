<template>
  <v-dialog max-width="500" scrollable v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="tag-form"
            ref="tagForm"
            v-model="formValid"
          >
            <v-text-field
              :label="getTranslation('tags.name')"
              :rules="[required]"
              autocomplete="off"
              autofocus
              required
              v-model.trim="tag.alias"
            />
            <v-select
              :items="getTagTypes"
              :label="getTranslation('tags.type')"
              :rules="[required]"
              item-text="label"
              item-value="value"
              required
              v-model="tag.type"
            />
            <v-text-field
              :label="getTranslation('tags.bagCost')"
              autocomplete="off"
              v-model.trim="tag.bagCost"
            />
            <v-text-field
              :label="getTranslation('tags.bagQuantity')"
              autocomplete="off"
              v-model.trim="tag.bagQuantity"
            />
            <v-select
              :items="getEnumOptions('activeInactiveAllCaps')"
              item-text="label"
              item-value="value"
              label="Order Status"
              v-model="tag.status"
            />
            <v-select
              :items="getEnumOptions('tagColors')"
              :label="getTranslation('tags.color')"
              item-text="label"
              item-value="value"
              v-model="tag.color"
            />
            <v-text-field
              :label="getTranslation('tags.manufacturer')"
              autocomplete="off"
              v-model.trim="tag.manufacturer"
            />
            <v-text-field
              :label="getTranslation('tags.size')"
              autocomplete="off"
              v-model.trim="tag.size"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="tag-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "TagDialog",
  props: {
    herdMeta: {
      required: true,
      type: Object,
    },
    onCreate: {
      required: true,
      type: Function,
    },
  },
  mixins: [TranslationMixin],
  data() {
    return {
      formValid: false,
      showDialog: false,
      submitting: false,
      tag: { id: undefined },
    };
  },
  computed: {
    title() {
      return this.tag.id ? "Update tag" : "Create tag";
    },
    getTagTypes() {
      return [{ label: "", value: "" }].concat(
        Object.keys(this.translate.enums.tagTypes)
          .filter((tagType) => tagType != "association")
          .map((key) => {
            return {
              label: this.getLabelTranslation(`enums.tagTypes.${key}`, key),
              value: key,
            };
          })
      );
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.tagForm.resetValidation();
      }
    },
  },
  methods: {
    open(tagToEdit = {}) {
      Object.assign(this.tag, tagToEdit);
      if (!this.tag.status) this.tag.status = "ACTIVE";

      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    getData() {
      let cost = this.tag.bagCost / this.tag.bagQuantity;
      if (Number.isNaN(cost)) cost = null;

      return {
        bagCost: this.tag.bagCost || "",
        bagQuantity: this.tag.bagQuantity || "",
        color: this.tag.color || "",
        cost,
        id: this.tag.id,
        manufacturer: this.tag.manufacturer || "",
        name: this.tag.alias || "",
        size: this.tag.size || "",
        status: this.tag.status,
        type: this.tag.type || "",
      };
    },
    async save() {
      if (!this.$refs.tagForm.validate() || this.submitting) return;

      this.submitting = true;
      const data = this.getData();
      try {
        if (this.tag.id) {
          await this.herdMeta.update("tags", data);
        } else {
          await this.herdMeta.create("tags", data, "tag");
          if (this.onCreate) {
            this.onCreate();
          }
        }
      } catch (e) {
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again."
        );
        this.submitting = false;
        return;
      }

      this.$emit("saved");
      this.showDialog = false;
    },
  },
};
</script>
