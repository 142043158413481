<template>
  <v-dialog max-width="500" v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="animal-edit-form"
            ref="animalEditForm"
            v-model="formValid"
          >
            <v-text-field
              :label="label"
              :rules="[required]"
              autocomplete="off"
              autofocus
              required
              v-model.trim="object.alias"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="animal-edit-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "EditAnimalDialog",
  props: ["dataType", "headers", "herdMeta"],
  mixins: [TranslationMixin],
  data() {
    return {
      formValid: false,
      object: { id: undefined, alias: undefined },
      showDialog: false,
      submitting: false,
    };
  },
  computed: {
    title() {
      return this.object.id
        ? `Update ${this.dataType.title}`
        : `Create ${this.dataType.title}`;
    },
    label() {
      return (this.headers[0] && this.headers[0].text) || "";
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        this.$refs.animalEditForm.resetValidation();
        Object.assign(this.$data, this.$options.data());
      }
    },
  },
  methods: {
    open(objectToEdit = {}) {
      Object.assign(this.object, objectToEdit);
      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    async save() {
      if (!this.$refs.animalEditForm.validate() || this.submitting) return;

      this.submitting = true;
      try {
        await this.dataType.saveFunction(this.object);
      } catch (e) {
        console.log(e);
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again."
        );
        this.submitting = false;
        return;
      }
      this.showDialog = false;
      this.$emit("saved");
    },
  },
};
</script>
