<template>
  <div class="panel panel-default">
    <div
      :class="['card', 'card-body', 'mb-4', $vuetify.theme.dark && 'bg-dark']"
    >
      <h4 class="panel-title">
        <a data-toggle="collapse" :href="`#${name}`">
          {{ title }}
          <slot name="append-title">(0)</slot>
        </a>
      </h4>
    </div>
    <div :id="name" class="panel-collapse collapse">
      <div class="panel-body">
        <slot name="content"> Nothing to show </slot>
      </div>
      <div class="panel-footer">
        &nbsp;
        <!--- Panel footer, blank space --->
      </div>
    </div>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "collapsable",
  mixins: [TranslationMixin],
  props: {
    name: {
      required: true,
      type: String,
    },
    title: {
      default: "Title",
      type: String,
    },
  },
};
</script>