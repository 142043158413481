<template>
  <div class="mr-lg-2 pa-2">
    <v-row>
      <v-col cols="12" class="pa-lg-8 mt-lg-10">
        <div v-if="!compareMode">
          <div :key="animal_idx" v-for="(animal, animal_idx) in animals">
            <v-alert
              v-if="
                animals.length > 0 && animals[animal_idx].derived.alerts.length
              "
              color="red lighten-2"
              type="error"
              border="left"
            >
              <p>Errors Exist</p>
              <ul>
                <li
                  :key="index"
                  v-for="(alert, index) in animals[animal_idx].derived.alerts"
                >
                  {{ alert }}
                </li>
              </ul>
            </v-alert>
          </div>
          <h2>
            {{ t.animalDetails }}
            <v-btn class="mr-1" color="primary" text v-if="animals.length">
              <router-link
                :to="{
                  name: 'AnimalDetails',
                  query: { id: animals[0].guid },
                }"
                target="_blank"
                class="text-h6 text-none"
              >
                {{ animals.length ? animals[0].getTagSummary() : null }}
              </router-link>
            </v-btn>
            <span
              :class="{
                badge: true,
                'badge-dark': !$vuetify.theme.dark,
                'badge-light': $vuetify.theme.dark,
              }"
              class="ml-3 mr-3"
              v-if="
                animals.length &&
                animals[0].doc.transfers &&
                animals[0].doc.transfers.length
              "
            >
              {{ Animal.getFinalTransferStatement(animals[0].doc) }}
            </span>
            <router-link
              :to="{ name: 'AnimalDetails', query: { id: animalAguid } }"
              style="text-decoration: none"
              v-if="animalAguid"
            >
              <v-btn class="mx-2" color="default">
                <div style="color: #1976d2">New View</div>
              </v-btn>
            </router-link>
            <v-btn v-if="isSuperAdmin && animalAguid">
              <a
                :href="`/couchdb/_utils/#database/organization-${organizationId}/${animalAguid}`"
                target="_blank"
                >CouchDB</a
              >
            </v-btn>
          </h2>
          <v-divider></v-divider>
          <div
            class="progress mb-3"
            style="background-color: inherit; height: 30px"
            v-if="animals.length"
          >
            <span
              style="
                position: absolute;
                left: 45%;
                font-size: 1rem;
                margin-top: 15px;
              "
              :style="
                (animals[0].initialLoadComplete &&
                  animals[0].completedPercent() > 55) ||
                animals[0].completedPercent() > 75
                  ? 'color: white'
                  : ''
              "
            >
              {{ animals[0].statusMessage() }}
            </span>
            <div
              class="progress-bar"
              role="progressbar"
              :aria-valuenow="animals[0].completedPercent()"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{ width: animals[0].completedPercent() + '%' }"
            >
              <span class="sr-only"
                >{{ animals[0].completedPercent() }}% {{ t.complete }}</span
              >
            </div>
          </div>
        </div>
        <div v-if="animals.length">
          <div>
            <div style="display: inline-block" class="individualAnimal">
              <div class="d-flex flex-wrap justify-space-between">
                <!-- Birth -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'birth-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h3>
                      {{ t.birth }}
                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation('animalDetails.Birth'),
                            animal.derived.birth,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text">
                          <v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          >
                        </span>
                      </button>
                    </h3>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(value, index) in animal.derived.birth"
                          v-if="!getLabelConfig(`fields.${index}`).hide"
                        >
                          <th>
                            {{ getLabelTranslation(`fields.${index}`, index) }}
                          </th>
                          <td
                            v-if="
                              [
                                'breed',
                                'birthDate',
                                'birthVisual',
                                'deathDate',
                                'birthWeight',
                                'gender',
                                'origin',
                                'species',
                                'percentCross',
                                'birthCalvingEase',
                                'vigor',
                              ].indexOf(index) === -1
                            "
                          >
                            {{
                              $utils.renderValueAs(
                                animal.derived.birth[index][index],
                                getLabelConfig(`fields.${index}`).type,
                                true
                              )
                            }}
                          </td>
                          <td v-else-if="index === 'birthVisual'">
                            {{ animal.derived.birth.birthVisual.tagValue }}
                          </td>
                          <td v-else-if="index === 'birthDate'">
                            <input
                              type="datetime-local"
                              @change="
                                modify(
                                  animal,
                                  'birthDates',
                                  animal.derived.birth[index].id,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              v-model="animal.derived.birth[index][index]"
                              :style="{
                                'background-color':
                                  !animal.derived.birth[index][index] ||
                                  animal.derived.birth[index][index].trim() ==
                                    ''
                                    ? 'yellow'
                                    : '',
                              }"
                              :disabled="disallowChanges(animal)"
                            />
                            <div v-if="animal.derived.birth[index][index]">
                              <div
                                v-if="
                                  moment(animal.derived.birth[index][index])
                                "
                              >
                                <div
                                  v-if="
                                    $utils.momentIsFuture(
                                      animal.derived.birth[index][index]
                                    )
                                  "
                                  class="alert-warning"
                                >
                                  Time appears to be in the future.
                                </div>
                              </div>
                              <div v-else>
                                <div>Time is not proper.</div>
                              </div>
                            </div>
                          </td>
                          <td v-else-if="index === 'deathDate'">
                            <input
                              type="datetime-local"
                              v-if="animal.isDead()"
                              @change="
                                modify(
                                  animal,
                                  'deathDates',
                                  animal.derived.birth[index].id,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              v-model="animal.derived.birth[index][index]"
                              :style="{
                                'background-color':
                                  !animal.derived.birth[index][index] ||
                                  animal.derived.birth[index][index].trim() ==
                                    ''
                                    ? 'yellow'
                                    : '',
                              }"
                              :disabled="disallowChanges(animal)"
                            />
                            <div v-if="animal.derived.birth[index][index]">
                              <div
                                v-if="
                                  moment(animal.derived.birth[index][index])
                                "
                              >
                                <div
                                  v-if="
                                    $utils.momentIsFuture(
                                      animal.derived.birth[index][index]
                                    )
                                  "
                                  class="alert-warning"
                                >
                                  Time appears to be in the future.
                                </div>
                              </div>
                              <div v-else>
                                <div>Time is not proper.</div>
                              </div>
                            </div>
                          </td>
                          <td v-else-if="index === 'gender'">
                            <select
                              v-model="animal.derived.birth[index][index]"
                              :class="{
                                'dark-input': $vuetify.theme.dark,
                                'bg-dark': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  'genders',
                                  animal.derived.birth[index].id,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              :disabled="disallowChanges(animal)"
                            >
                              <option
                                v-if="!labels.enums.genders[value.gender]"
                                :value="value.gender"
                              >
                                {{ value.gender }}
                              </option>
                              <option
                                :key="index"
                                v-for="(value, index) in labels.enums.genders"
                                :value="index"
                              >
                                {{
                                  getLabelTranslation(
                                    `enums.genders.${index}`,
                                    index
                                  )
                                }}
                              </option>
                            </select>
                          </td>
                          <td v-else-if="index === 'origin'">
                            <span v-if="!animal.herdMeta.origins.length">
                              <input
                                type="textarea"
                                :class="{
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    index,
                                    animal.derived.birth[index][index],
                                    false
                                  )
                                "
                                v-model="animal.derived.birth[index][index]"
                                :disabled="disallowChanges(animal)"
                              />
                            </span>
                            <span v-else>
                              <select
                                v-model="
                                  animal.derived.birth[index]['originId']
                                "
                                :class="{
                                  'bg-dark': $vuetify.theme.dark,
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    'originId',
                                    animal.derived.birth[index]['originId'],
                                    false,
                                    false,
                                    {
                                      origin: animal.derived.birth[index][
                                        'originId'
                                      ]
                                        ? animal.herdMeta.originsById[
                                            animal.derived.birth[index][
                                              'originId'
                                            ]
                                          ].origin
                                        : null,
                                    }
                                  )
                                "
                                :disabled="disallowChanges(animal)"
                              >
                                <option></option>
                                <option
                                  :key="originIndex"
                                  v-for="(origin, originIndex) in animal
                                    .herdMeta.origins"
                                  v-if="!origin.deleted"
                                  :value="origin.id"
                                >
                                  {{ origin.origin }}
                                </option>
                                <option
                                  :key="originIndex"
                                  v-for="(origin, originIndex) in animal
                                    .herdMeta.origins"
                                  v-if="
                                    origin.deleted &&
                                    origin.id ===
                                      animal.derived.birth[index]['originId']
                                  "
                                  :value="origin.id"
                                >
                                  {{ origin.origin }} ({{
                                    getLabelTranslation(
                                      "animalDetails['deleted-meta']"
                                    )
                                  }})
                                </option>
                                <option
                                  v-if="
                                    !animal.derived.birth[index]['originId'] &&
                                    animal.derived.birth[index]['origin'] &&
                                    !animal.herdMeta.originsById[
                                      animal.derived.birth[index][index]
                                    ]
                                  "
                                  :value="
                                    animal.derived.birth[index]['originId']
                                  "
                                >
                                  {{ animal.derived.birth[index][index] }}
                                  (non-indexed)
                                </option>
                              </select>
                            </span>
                          </td>
                          <td v-else-if="index === 'species'">
                            <select
                              v-model="animal.derived.birth[index][index]"
                              :class="{
                                'bg-dark': $vuetify.theme.dark,
                                'dark-input': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  'species',
                                  animal.derived.birth[index].id,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              :disabled="disallowChanges(animal)"
                            >
                              <option
                                v-if="!labels.enums.species[value.species]"
                                :value="value.species"
                              >
                                {{ value.species }}
                              </option>
                              <option
                                :key="index"
                                v-for="(value, index) in labels.enums.species"
                                :value="index"
                              >
                                {{
                                  getLabelTranslation(
                                    `enums.species.${index}`,
                                    index
                                  )
                                }}
                              </option>
                            </select>
                          </td>
                          <td v-else-if="index === 'birthCalvingEase'">
                            <select
                              v-model="animal.derived.birth[index][index]"
                              :class="{
                                'bg-dark': $vuetify.theme.dark,
                                'dark-input': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  'birthCalvingEases',
                                  null,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              :disabled="disallowChanges(animal)"
                            >
                              <option :value="null"></option>
                              <option
                                v-if="
                                  !labels.enums.birthCalvingEases[
                                    value.birthCalvingEase
                                  ]
                                "
                                :value="value.birthCalvingEase"
                              >
                                {{ value.birthCalvingEase }} ({{
                                  getLabelTranslation(
                                    "animalDetails['non-indexed']"
                                  )
                                }})
                              </option>
                              <option
                                :key="index"
                                v-for="(value, index) in labels.enums
                                  .birthCalvingEases"
                                :value="index"
                              >
                                {{
                                  getLabelTranslation(
                                    `enums.birthCalvingEases.${index}`,
                                    index
                                  )
                                }}
                              </option>
                            </select>
                          </td>
                          <td v-else-if="index === 'vigor'">
                            <select
                              v-model="animal.derived.birth[index][index]"
                              :class="{
                                'bg-dark': $vuetify.theme.dark,
                                'dark-input': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  'vigors',
                                  null,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              :disabled="disallowChanges(animal)"
                            >
                              <option :value="null"></option>
                              <option
                                v-if="!labels.enums.vigors[value.vigor]"
                                :value="value.vigor"
                              >
                                {{ value.vigor }}
                              </option>
                              <option
                                :key="index"
                                v-for="(value, index) in labels.enums.vigors"
                                :value="index"
                              >
                                {{
                                  getLabelTranslation(
                                    `enums.vigors.${index}`,
                                    index
                                  )
                                }}
                              </option>
                            </select>
                          </td>
                          <td v-else>
                            <!--- Free-form inputs such as birthWeight, breed, percentCross --->
                            <input
                              type="textarea"
                              :class="{
                                'dark-input': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  index,
                                  animal.derived.birth[index].id,
                                  index,
                                  animal.derived.birth[index][index],
                                  false,
                                  false,
                                  { timeRecorded: new Date().toISOString() }
                                )
                              "
                              v-model="animal.derived.birth[index][index]"
                              :disabled="disallowChanges(animal)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Financials -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'financials-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h2>
                      {{ labels.animalDetails.Financials[local] }}
                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation('animalDetails.Financials'),
                            animal.derived.financials,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text"
                          ><v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          ></span
                        >
                      </button>
                    </h2>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(value, index) in animal.derived.financials"
                          v-if="!getLabelConfig(`fields.${index}`).hide"
                        >
                          <th>
                            {{ getLabelTranslation(`fields.${index}`, index) }}
                          </th>
                          <td v-if="index !== 'headDays'">
                            {{
                              $utils.renderValueAs(
                                value,
                                getLabelConfig(`fields.${index}`).type,
                                true
                              )
                            }}
                          </td>
                          <td v-else>
                            {{ animal.getHeadDays() }} ({{
                              getAgeOfDuration(
                                moment().duration(value, "days")
                              )
                            }})
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Current -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'current-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h2>
                      {{ labels.animalDetails.Currents[local] }}
                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation('animalDetails.Currents'),
                            animal.derived.currents,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text">
                          <v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          >
                        </span>
                      </button>
                    </h2>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(value, index) in animal.derived.currents"
                          v-if="
                            index !== 'locationId' && index !== 'lastMoveDate'
                          "
                        >
                          <th>
                            {{ getLabelTranslation(`fields.${index}`, index) }}
                          </th>
                          <td v-if="index == 'herd'">
                            <div
                              class="d-flex align-center justify-space-between"
                            >
                              <span>
                                {{ animal.derived.summaries.main.herd }}
                              </span>

                              <v-btn
                                :disabled="animal.disallowChanges()"
                                @click="showHerdDialog(animal)"
                                class="px-0"
                                color="grey"
                                elevation="3"
                                style="min-width: 36px; max-width: 36px"
                              >
                                <v-icon color="white"> mdi-pencil </v-icon>
                              </v-btn>
                              <v-btn
                                :disabled="
                                  !animal.derived.currents.herd ||
                                  animal.disallowChanges()
                                "
                                @click="
                                  modify(
                                    animal,
                                    'herds',
                                    null,
                                    'herdId',
                                    '',
                                    false,
                                    false,
                                    {
                                      herd: null,
                                      createdOn: new Date().toISOString(),
                                      timeRecorded: new Date().toISOString(),
                                      userId: $userID,
                                    }
                                  )
                                "
                                class="ma-1 px-0"
                                color="red"
                                elevation="3"
                                style="max-width: 36px; min-width: 36px"
                              >
                                <v-icon color="white">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </td>
                          <td v-else-if="index == 'receivingRanch'">
                            {{ animal.derived.summaries.main.receivingRanch }}
                          </td>
                          <td v-else-if="index !== 'daysSinceLastMove'">
                            {{
                              $utils.renderValueAs(
                                value,
                                getLabelConfig(`fields.${index}`).type,
                                true
                              )
                            }}
                          </td>
                          <td v-else>
                            <!--- daysSinceLastMove uses lastMoveDate --->
                            {{ animal.getDaysSinceLastMove() }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            animal.doc.mergedTo &&
                            animal.doc.mergedTo.length > 0
                          "
                        >
                          <th class="table-danger">Merged To</th>
                          <td class="table-danger">
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: {
                                  id: animal.doc.mergedTo[0].otherGUID,
                                },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                            >
                              {{ animal.doc.mergedTo[0].otherTagValues }}
                            </router-link>
                          </td>
                        </tr>
                        <tr v-if="animal.doc && animal.doc.deleted">
                          <th class="table-danger">Deleted</th>
                          <td class="table-danger">Yes</td>
                        </tr>
                        <tr v-if="!animal.doc">
                          <th class="table-danger">Does not exist</th>
                          <td class="table-danger">Yes</td>
                        </tr>
                        <tr
                          v-if="
                            ((animal.doc && animal.doc.deleted) ||
                              !animal.doc) &&
                            animal.derived.nestedTables.duplicates.length
                          "
                        >
                          <th class="table-danger">Deduplicated</th>
                          <td class="table-danger">
                            <div
                              :key="index"
                              v-for="(duplicate, index) in animal.derived
                                .nestedTables.duplicates"
                            >
                              <router-link
                                :to="{
                                  name: 'AnimalDetails',
                                  query: { id: duplicate.animalA },
                                }"
                                class="text-h6 text-none"
                                target="_blank"
                              >
                                {{ duplicate.tagValues }}
                              </router-link>
                              <br />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Characteristics -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'characteristics-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h2>
                      {{ labels.animalDetails.Characteristics[local] }}
                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation(
                              'animalDetails.Characteristics'
                            ),
                            animal.derived.characteristics,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text">
                          <v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          >
                        </span>
                      </button>
                    </h2>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(value, index) in animal.derived
                            .characteristics"
                          v-if="!getLabelConfig(`fields.${index}`).hide"
                        >
                          <th>
                            {{ getLabelTranslation(`fields.${index}`, index) }}
                          </th>
                          <td
                            v-if="
                              ['status', 'color', 'sex', 'dnaNumber'].indexOf(
                                index
                              ) === -1
                            "
                          >
                            <!--- Assume strings --->
                            {{
                              $utils.renderValueAs(
                                animal.derived.characteristics[index],
                                getLabelConfig(`fields.${index}`).type,
                                true
                              )
                            }}
                          </td>
                          <td v-else-if="index === 'dnaNumber'">
                            <div
                              class="d-flex align-center justify-space-between"
                            >
                              <div
                                style="width: 85px; height: 52px"
                                class="d-flex align-center flex-grow-1 flex-shrink-0"
                              >
                                <p class="ma-0" style="word-break: break-word">
                                  {{
                                    $utils.renderValueAs(
                                      animal.derived.characteristics[index][
                                        index
                                      ],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="d-flex flex-grow-0 flex-shrink-1">
                                <v-btn
                                  :disabled="animal.disallowChanges()"
                                  @click="displayTsuDialog(animal)"
                                  class="ma-1 px-0"
                                  color="grey"
                                  elevation="3"
                                  style="max-width: 36px; min-width: 36px"
                                >
                                  <v-icon color="white">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                  :disabled="
                                    (animal.doc.dnaNumbers || []).some(
                                      ({ deleted }) => !deleted
                                    ) == null || animal.disallowChanges()
                                  "
                                  @click="
                                    animal.deleteRecord(
                                      'dnaNumbers',
                                      animal.derived.characteristics.dnaNumber
                                        .id
                                    )
                                  "
                                  class="ma-1 px-0"
                                  color="red"
                                  elevation="3"
                                  style="max-width: 36px; min-width: 36px"
                                >
                                  <v-icon color="white">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </td>
                          <td v-else-if="index === 'status'">
                            <select
                              v-model.trim.toLowerCase="
                                animal.derived.characteristics[index][index]
                              "
                              :class="{
                                'bg-dark': $vuetify.theme.dark,
                                'dark-input': $vuetify.theme.dark,
                              }"
                              @change="
                                modify(
                                  animal,
                                  index,
                                  null,
                                  index,
                                  animal.derived.characteristics[index][index],
                                  false
                                )
                              "
                              :disabled="disallowChanges(animal)"
                            >
                              <option v-if="value == null" :value="value">
                                {{ value }}
                              </option>
                              <option
                                v-else-if="!labels.enums.statuses[value.status]"
                                :value="value.status"
                              >
                                {{ value.status }} ({{
                                  getLabelTranslation(
                                    "animalDetails['non-indexed']"
                                  )
                                }})
                              </option>
                              <option
                                :key="index"
                                v-for="(value, index) in labels.enums.statuses"
                                :value="index"
                              >
                                {{
                                  getLabelTranslation(
                                    `enums.statuses.${index}`,
                                    index
                                  )
                                }}
                              </option>
                            </select>
                          </td>
                          <td v-else-if="index === 'color'">
                            <span v-if="!animal.herdMeta.colors.length">
                              <input
                                type="textarea"
                                :class="{
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    index,
                                    animal.derived.characteristics[index][
                                      index
                                    ],
                                    false
                                  )
                                "
                                v-model="
                                  animal.derived.characteristics[index][index]
                                "
                                :disabled="disallowChanges(animal)"
                              />
                            </span>
                            <span v-else>
                              <select
                                v-model="
                                  animal.derived.characteristics[index][
                                    'colorId'
                                  ]
                                "
                                :class="{
                                  'bg-dark': $vuetify.theme.dark,
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    'colorId',
                                    animal.derived.characteristics[index][
                                      'colorId'
                                    ],
                                    false,
                                    false,
                                    {
                                      color: animal.derived.characteristics[
                                        index
                                      ]['colorId']
                                        ? animal.herdMeta.colorsById[
                                            animal.derived.characteristics[
                                              index
                                            ]['colorId']
                                          ].color
                                        : null,
                                    }
                                  )
                                "
                                :disabled="disallowChanges(animal)"
                              >
                                <option></option>
                                <option
                                  :key="colorIndex"
                                  v-for="(color, colorIndex) in animal.herdMeta
                                    .colors"
                                  v-if="!color.deleted"
                                  :value="color.id"
                                >
                                  {{ color.color }}
                                </option>
                                <option
                                  :key="colorIndex"
                                  v-for="(color, colorIndex) in animal.herdMeta
                                    .colors"
                                  v-if="
                                    color.deleted &&
                                    color.id ===
                                      animal.derived.characteristics[index][
                                        'colorId'
                                      ]
                                  "
                                  :value="color.id"
                                >
                                  {{ color.color }} ({{
                                    getLabelTranslation(
                                      "animalDetails['deleted-meta']"
                                    )
                                  }})
                                </option>
                                <option
                                  v-if="
                                    !animal.derived.characteristics[index][
                                      'colorId'
                                    ] &&
                                    animal.derived.characteristics[index][
                                      'color'
                                    ] &&
                                    !animal.herdMeta.colorsById[
                                      animal.derived.characteristics[index][
                                        index
                                      ]
                                    ]
                                  "
                                  :value="
                                    animal.derived.characteristics[index][
                                      'colorId'
                                    ]
                                  "
                                >
                                  {{
                                    animal.derived.characteristics[index][index]
                                  }}
                                  (non-indexed)
                                </option>
                              </select>
                            </span>
                          </td>
                          <td v-else-if="index === 'sex'">
                            <span v-if="!animal.herdMeta.sexes.length">
                              <input
                                type="textarea"
                                :class="{
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    index,
                                    animal.derived.characteristics[index][
                                      index
                                    ],
                                    false
                                  )
                                "
                                v-model="
                                  animal.derived.characteristics[index][index]
                                "
                                :disabled="disallowChanges(animal)"
                              />
                            </span>
                            <span v-else>
                              <select
                                v-model="
                                  animal.derived.characteristics[index]['sexId']
                                "
                                :class="{
                                  'bg-dark': $vuetify.theme.dark,
                                  'dark-input': $vuetify.theme.dark,
                                }"
                                @change="
                                  modify(
                                    animal,
                                    index,
                                    null,
                                    'sexId',
                                    animal.derived.characteristics[index][
                                      'sexId'
                                    ],
                                    false,
                                    false,
                                    {
                                      sex: animal.derived.characteristics[
                                        index
                                      ]['sexId']
                                        ? animal.herdMeta.sexesById[
                                            animal.derived.characteristics[
                                              index
                                            ]['sexId']
                                          ].sex
                                        : null,
                                    }
                                  )
                                "
                                :disabled="disallowChanges(animal)"
                              >
                                <option></option>
                                <option
                                  :key="sexIndex"
                                  v-for="(sex, sexIndex) in animal.herdMeta
                                    .sexes"
                                  v-if="!sex.deleted"
                                  :value="sex.id"
                                >
                                  {{ sex.sex }}
                                </option>
                                <option
                                  v-for="(sex, sexIndex) in animal.herdMeta
                                    .sexes"
                                  :key="sexIndex"
                                  v-if="
                                    sex.deleted &&
                                    sex.id ===
                                      animal.derived.characteristics[index][
                                        'sexId'
                                      ]
                                  "
                                  :value="sex.id"
                                >
                                  {{ sex.sex }} ({{
                                    getLabelTranslation(
                                      "animalDetails['deleted-meta']"
                                    )
                                  }})
                                </option>
                                <option
                                  v-if="
                                    !animal.derived.characteristics[index][
                                      'sexId'
                                    ] &&
                                    animal.derived.characteristics[index][
                                      'sex'
                                    ] &&
                                    !animal.herdMeta.sexesById[
                                      animal.derived.characteristics[index][
                                        index
                                      ]
                                    ]
                                  "
                                  :value="
                                    animal.derived.characteristics[index][
                                      'sexId'
                                    ]
                                  "
                                >
                                  {{
                                    animal.derived.characteristics[index][index]
                                  }}
                                  (non-indexed)
                                </option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Family -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'family-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h2>
                      {{ labels.animalDetails.Family[local] }}
                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation('animalDetails.Family'),
                            animal.derived.family,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text">
                          <v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          >
                        </span>
                      </button>
                    </h2>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr>
                          <th>{{ getLabelTranslation("fields.dam") }}</th>
                          <td v-if="animal.derived.family.damId">
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: { id: animal.derived.family.damId },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                            >
                              {{ animal.derived.family.damTags }}
                            </router-link>
                          </td>
                          <td v-else></td>
                        </tr>
                        <tr>
                          <th>
                            {{ getLabelTranslation("fields.adoptDam") }}
                          </th>
                          <td v-if="animal.derived.family.adoptDamId">
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: {
                                  id: animal.derived.family.adoptDamId,
                                },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                            >
                              {{ animal.derived.family.adoptDamTags }}
                            </router-link>
                          </td>
                          <td v-else></td>
                        </tr>
                        <tr>
                          <th>
                            {{ getLabelTranslation("fields.sire") }}
                          </th>
                          <td v-if="animal.derived.family.sireId">
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: { id: animal.derived.family.sireId },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                            >
                              {{ animal.derived.family.sireTags }}
                            </router-link>
                          </td>
                          <td v-else></td>
                        </tr>
                        <tr>
                          <th>
                            {{ getLabelTranslation("fields.coverSire") }}
                          </th>
                          <td v-if="animal.derived.family.coverSireId">
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: {
                                  id: animal.derived.family.coverSireId,
                                },
                              }"
                              class="text-h6 text-none"
                              target="_blank"
                            >
                              {{ animal.derived.family.coverSireTags }}
                            </router-link>
                          </td>
                          <td v-else></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Weight -->
                <div class="d-flex flex-wrap">
                  <div
                    :key="'weight-' + animal.guid"
                    v-for="(animal, index) in animals"
                    class="mb-2 mx-2"
                  >
                    <h3 v-if="animals.length > 1">Animal {{ index + 1 }}</h3>
                    <h2>
                      {{ labels.animalDetails.Weight[local] }}

                      <button
                        class="btn btn-success btn-xs"
                        type="button"
                        @click="
                          exportToCSV(
                            animal,
                            'non-tabular',
                            getLabelTranslation('animalDetails.Weight'),
                            animal.derived.weight,
                            true,
                            true
                          )
                        "
                      >
                        <span class="white--text">
                          <v-icon class="white--text"
                            >mdi-file-export-outline</v-icon
                          >
                        </span>
                      </button>
                    </h2>
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                      style="width: 300px"
                    >
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(value, index) in animal.derived.weight"
                          v-if="!getLabelConfig(`fields.${index}`).hide"
                        >
                          <th>
                            {{ getLabelTranslation(`fields.${index}`, index) }}
                          </th>
                          <td>
                            {{
                              $utils.renderValueAs(
                                value,
                                getLabelConfig(`fields.${index}`).type,
                                true
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="panel-group">
                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#transfers">
                        {{ labels.animalDetails.panels.transfers[local] }}
                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction + (animal.doc.transfers || []).length,
                            0
                          )
                        }})
                        <span
                          v-if="
                            animals[0].doc.transfers &&
                            animals[0].doc.transfers.length
                          "
                        >
                          -
                          <span
                            :class="{
                              badge: true,
                              'badge-dark': !$vuetify.theme.dark,
                              'badge-light': $vuetify.theme.dark,
                            }"
                          >
                            <span>
                              {{
                                Animal.getFinalTransferStatement(animals[0].doc)
                              }}
                            </span>
                          </span>
                        </span>
                        <span
                          v-if="
                            animals.length > 1 &&
                            animals[1].doc.transfers &&
                            animals[1].doc.transfers.length
                          "
                        >
                          -
                          <span
                            :class="{
                              badge: true,
                              'badge-dark': !$vuetify.theme.dark,
                              'badge-light': $vuetify.theme.dark,
                            }"
                          >
                            <span>
                              {{
                                Animal.getFinalTransferStatement(animals[1].doc)
                              }}
                            </span>
                          </span>
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="transfers"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction + (animal.doc.transfers || []).length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div
                        v-if="
                          animal.doc.transfers && animal.doc.transfers.length
                        "
                      >
                        <table
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                          class="table table-striped table-bordered dataTable"
                          style="overflow-x: auto !important"
                        >
                          <thead>
                            <th>Transfer Out Time</th>
                            <th>Data Transfer Time</th>
                            <th>From</th>
                            <th>To</th>
                            <th>By</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="transfer of animal.doc.transfers"
                              :key="transfer.timeRecorded"
                            >
                              <td>
                                {{
                                  $utils.renderValueAs(
                                    transfer.timeRecorded,
                                    "datetime",
                                    true
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  $utils.renderValueAs(
                                    transfer.createdOn,
                                    "datetime",
                                    true
                                  )
                                }}
                              </td>
                              <td>{{ transfer.from.name }}</td>
                              <td>{{ transfer.to.name }}</td>
                              <td>
                                {{
                                  transfer.from.id == $organizationID
                                    ? transfer.userName
                                    : "?"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#ids">
                        {{ labels.animalDetails.panels.ids[local] }}
                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.ids.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="ids"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction + animal.derived.nestedTables.ids.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.ids.length">
                        <table
                          style="overflow-x: auto !important"
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.ids'
                                    ),
                                    animal.derived.nestedTables.ids,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span>
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable(
                                'ids'
                              ).filter((field) => field !== 'tagTable')"
                            >
                              <span v-if="index === 'tagTable'"></span>
                              <span v-else-if="index === 'tagId'">
                                {{
                                  getLabelTranslation(
                                    "fields.tagTable",
                                    "tagTable"
                                  )
                                }}
                              </span>
                              <span v-else>
                                {{
                                  getLabelTranslation(`fields.${index}`, index)
                                }}
                              </span>
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .ids"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  @click="animal.deleteRecord('ids', row.id)"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                >
                                  <span>
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'ids'
                                ).filter((field) => field !== 'tagTable')"
                              >
                                <div v-if="index === 'tagId'">
                                  <div
                                    v-if="
                                      !row[index] ||
                                      !!animal.herdMeta.getMetaTagById(
                                        row[index]
                                      )
                                    "
                                  >
                                    <select
                                      v-model="row[index]"
                                      :class="{
                                        'bg-dark': $vuetify.theme.dark,
                                        'dark-input': $vuetify.theme.dark,
                                      }"
                                      @change="
                                        modify(
                                          animal,
                                          'ids',
                                          row.id,
                                          'tagId',
                                          row[index],
                                          true
                                        )
                                      "
                                      :disabled="disallowChanges(animal)"
                                    >
                                      <option :value="null"></option>
                                      <option
                                        :key="tagIndex"
                                        v-for="(tag, tagIndex) in animal
                                          .herdMeta.tags"
                                        :value="tag.id"
                                      >
                                        {{
                                          animal.herdMeta.getMetaTagName(tag)
                                        }}
                                      </option>
                                    </select>
                                  </div>
                                  <div
                                    v-else-if="
                                      !!animal.herdMeta.getMetaTagById(
                                        row[index]
                                      )
                                    "
                                  >
                                    {{
                                      animal.herdMeta.getMetaTagName(
                                        animal.herdMeta.getMetaTagById(
                                          row[index]
                                        )
                                      )
                                    }}
                                  </div>
                                  <div v-else>
                                    {{ row[index] }}
                                  </div>
                                </div>
                                <div v-else-if="index === 'type'">
                                  <div v-if="!row.tagId">
                                    <select
                                      v-model="row[index]"
                                      :class="{
                                        'bg-dark': $vuetify.theme.dark,
                                        'dark-input': $vuetify.theme.dark,
                                      }"
                                      @change="
                                        modify(
                                          animal,
                                          'ids',
                                          row.id,
                                          index,
                                          row[index],
                                          true
                                        )
                                      "
                                      :disabled="disallowChanges(animal)"
                                    >
                                      <option
                                        v-if="
                                          !labels.enums.tagTypes[row[index]]
                                        "
                                        :value="row[index]"
                                      >
                                        {{ row[index] }}
                                      </option>
                                      <option
                                        :key="index"
                                        v-for="(value, index) in labels.enums
                                          .tagTypes"
                                        :value="index"
                                      >
                                        {{
                                          getLabelTranslation(
                                            `enums.tagTypes.${index}`,
                                            index
                                          )
                                        }}
                                      </option>
                                    </select>
                                  </div>
                                  <div v-else>
                                    {{
                                      $utils.renderValueAs(
                                        row[index],
                                        getLabelConfig(`fields.${index}`).type,
                                        true
                                      )
                                    }}
                                  </div>
                                </div>
                                <div v-else-if="index === 'status'">
                                  <select
                                    v-model="row[index]"
                                    :class="{
                                      'bg-dark': $vuetify.theme.dark,
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'ids',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="
                                        !labels.enums.recordStatus[row[index]]
                                      "
                                      :value="row[index]"
                                    >
                                      {{ row[index] }}
                                    </option>
                                    <option
                                      :key="index"
                                      v-for="(value, index) in labels.enums
                                        .recordStatus"
                                      :value="index"
                                    >
                                      {{
                                        getLabelTranslation(
                                          `enums.recordStatus.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  v-else-if="
                                    (row.status || 'active') === 'active' &&
                                    ['tagValue', 'location'].indexOf(index) > -1
                                  "
                                >
                                  <input
                                    type="textarea"
                                    style="width: 145px"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      disabled: animal.disallowChanges(),
                                    }"
                                    v-model="row[index]"
                                    @change="
                                      modify(
                                        animal,
                                        'ids',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    !row.tagId &&
                                    (row.type != 'association' || row[index]) &&
                                    (row.status || 'active') === 'active' &&
                                    [
                                      'tagColor',
                                      'manufacturer',
                                      'size',
                                    ].indexOf(index) > -1
                                  "
                                >
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    v-model="row[index]"
                                    style="width: 100px"
                                    @change="
                                      modify(
                                        animal,
                                        'ids',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                                <div v-else>
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#weights">
                        {{ labels.animalDetails.panels.weights[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.weights.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="weights"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.weights.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.weights.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.weights'
                                    ),
                                    animal.derived.nestedTables.weights,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('weights')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .weights"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                  v-if="
                                    row.source &&
                                    (row.source.toLowerCase() == 'preg' ||
                                      row.source.toLowerCase() == 'scale' ||
                                      row.source.toLowerCase() == 'wean')
                                  "
                                  @click="
                                    animal.deleteRecord('weights', row.id)
                                  "
                                >
                                  <span>
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable('weights')"
                              >
                                <div v-if="index !== 'weight'">
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    v-if="
                                      row.source &&
                                      row.source.toLowerCase() === 'birth'
                                    "
                                    v-model="row[index]"
                                    @change="
                                      modify(
                                        animal,
                                        'birthWeights',
                                        row.id,
                                        'birthWeight',
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  />
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    v-else-if="
                                      row.source &&
                                      ['purchase', 'income', 'sale'].indexOf(
                                        row.source.toLowerCase()
                                      ) > -1
                                    "
                                    v-model="row[index]"
                                    @change="
                                      modify(
                                        animal,
                                        'salesPurchases',
                                        row.id,
                                        'weight',
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  />
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    v-else-if="
                                      row.source &&
                                      (row.source.toLowerCase() == 'preg' ||
                                        row.source.toLowerCase() == 'scale' ||
                                        row.source.toLowerCase() == 'wean')
                                    "
                                    v-model="row[index]"
                                    @change="
                                      modify(
                                        animal,
                                        'weights',
                                        row.id,
                                        'weight',
                                        row[index],
                                        true
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  />
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    v-else
                                    class="disabled"
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>

                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#sightings">
                        {{ labels.animalDetails.panels.sightings[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.sightings.length,
                            0
                          )
                        }})</a
                      >
                      <span v-if="animals[0].derived.currents.location">
                        - {{ animals[0].derived.currents.location }}</span
                      >
                      <span
                        v-if="
                          animals.length > 1 &&
                          animals[1].derived.currents.location
                        "
                      >
                        - {{ animals[1].derived.currents.location }}</span
                      >
                    </h4>
                  </div>
                  <div
                    id="sightings"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.sightings.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.sightings.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.sightings'
                                    ),
                                    animal.derived.nestedTables.sightings,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('sightings')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .sightings"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  type="button"
                                  class="btn btn-danger btn-xs"
                                  v-if="row.type && row.type === 'movement'"
                                  @click="
                                    animal.deleteRecord('movements', row.id)
                                  "
                                >
                                  <span>
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  type="button"
                                  class="btn btn-danger btn-xs"
                                  v-else-if="
                                    row.type && row.type === 'geopoint'
                                  "
                                  @click="
                                    animal.deleteRecord('geopoints', row.id)
                                  "
                                >
                                  <span>
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-danger btn-xs disable-cursor"
                                  v-else
                                  disabled="true"
                                >
                                  <span>
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'sightings'
                                )"
                              >
                                <div v-if="[].indexOf(index) === -1">
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'movements',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                        <v-btn
                          :disabled="
                            getGeopoints(animal.doc.geopoints).length == 0
                          "
                          @click="showMaps"
                          v-if="!googleMapIsLoaded"
                          >Load Map
                          <v-icon>mdi-map</v-icon>
                        </v-btn>
                        <div
                          v-if="getGeopoints(animal.doc.geopoints).length == 0"
                        >
                          <p>
                            {{ getTranslation("Geo data is not present") }}
                          </p>
                        </div>
                        <div v-else-if="googleMapIsNotAvailable">
                          <p>Map API is not available right now.</p>
                        </div>
                        <div
                          :id="`map${animal_idx}`"
                          :ref="`googleMap${animal_idx}`"
                          style="width: 100%; height: 600px"
                          class="mb-2"
                          :class="{ hide: !googleMapIsLoaded }"
                        ></div>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#treatments">
                        {{ labels.animalDetails.panels.treatments[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.treatments.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="treatments"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.treatments.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.treatments.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.treatments'
                                    ),
                                    animal.derived.nestedTables.treatments,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable(
                                'treatments'
                              )"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .treatments"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                  @click="
                                    animal.deleteRecord('treatments', row.id)
                                  "
                                >
                                  <span class="glyphicon glyphicon-minus">
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'treatments'
                                )"
                              >
                                <div v-if="index === 'vaccinationTime'">
                                  <input
                                    type="datetime-local"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'treatments',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                                <div v-if="index === 'rectalTemperature'">
                                  <input
                                    type="text"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'treatments',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>

                                <div
                                  v-else-if="
                                    ['actualDose'].indexOf(index) === -1
                                  "
                                >
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'treatments',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>

                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <!--- This feature is not feature complete. We only expose it for customers who happen to have breeding data. --->
                <div
                  class="panel panel-default"
                  v-if="
                    (animals.length &&
                      animals[0].derived.nestedTables.breedings.length) ||
                    (animals.length > 1 &&
                      animals[1].derived.nestedTables.breedings.length)
                  "
                >
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#breedings">
                        {{ labels.animalDetails.panels.breedings[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.breedings.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="breedings"
                    class="panel-collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.breedings.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.breedings.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.breedings'
                                    ),
                                    animal.derived.nestedTables.breedings,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('breedings')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .breedings"
                            >
                              <td></td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'breedings'
                                )"
                              >
                                {{
                                  $utils.renderValueAs(
                                    row[index],
                                    getLabelConfig(`fields.${index}`).type,
                                    true
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>

                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div
                  :key="index"
                  v-for="(animal, index) in animals"
                  v-if="!animal.calvings"
                >
                  <div v-if="animal.getCalvings()"></div>
                </div>
                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#calvings">
                        {{ labels.animalDetails.panels.calvings[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction + animal.calvings.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="calvings"
                    class="panel-collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction + animal.calvings.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.calvings.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.calvings'
                                    ),
                                    animal.calvings,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('calvings')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.calvings"
                            >
                              <td></td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'calvings'
                                )"
                              >
                                <router-link
                                  :to="{
                                    name: 'AnimalDetails',
                                    query: { id: row.animalId },
                                  }"
                                  class="text-h6 text-none"
                                  target="_blank"
                                  v-if="index === 'offspringTags'"
                                >
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </router-link>
                                <span
                                  v-else-if="
                                    index === 'birthCalvingEase' && !!row[index]
                                  "
                                >
                                  {{
                                    getLabelTranslation(
                                      `enums.birthCalvingEases.${row[index]}`,
                                      row[index]
                                    )
                                  }}
                                </span>
                                <span
                                  v-else-if="index === 'vigor' && !!row[index]"
                                >
                                  {{
                                    getLabelTranslation(
                                      `enums.vigors.${row[index]}`,
                                      row[index]
                                    )
                                  }}
                                </span>
                                <span v-else>
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#comments">
                        {{ labels.animalDetails.panels.comments[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.comments.length,
                            0
                          )
                        }})</a
                      >
                      <span v-if="animals[0].derived.currents.comment">
                        - {{ animals[0].derived.currents.comment }}</span
                      >
                      <span
                        v-if="
                          animals.length > 1 &&
                          animals[1].derived.currents.comment
                        "
                      >
                        - {{ animals[1].derived.currents.comment }}</span
                      >
                    </h4>
                  </div>
                  <div
                    id="comments"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.comments.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.comments.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.comments'
                                    ),
                                    animal.derived.nestedTables.comments,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>

                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('comments')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .comments"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                  @click="
                                    animal.deleteRecord('comments', row.id)
                                  "
                                >
                                  <span class="glyphicon glyphicon-minus">
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'comments'
                                )"
                              >
                                <div v-if="[].indexOf(index) === -1">
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'comments',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#salesPurchases">
                        {{ labels.animalDetails.panels.salesPurchases[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.salesPurchases.length,
                            0
                          )
                        }})</a
                      >
                      <span
                        class="ml-1"
                        :key="index"
                        v-for="(animal, index) in animals"
                      >
                        <span
                          v-if="animal.derived.salesPurchasesMeta.isPurchased"
                          class="badge"
                          :class="{
                            'badge-dark': !$vuetify.theme.dark,
                            'badge-light': $vuetify.theme.dark,
                          }"
                          style="margin-right: 5px"
                          >{{ getLabelTranslation("purchased") }}</span
                        >
                        <span
                          v-if="animal.derived.salesPurchasesMeta.isSold"
                          class="badge"
                          :class="{
                            'badge-dark': !$vuetify.theme.dark,
                            'badge-light': $vuetify.theme.dark,
                          }"
                          style="margin-right: 5px"
                          >{{ getLabelTranslation("sold") }}</span
                        >
                      </span>
                    </h4>
                  </div>
                  <div
                    id="salesPurchases"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.salesPurchases.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div
                        v-if="animal.derived.nestedTables.salesPurchases.length"
                      >
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.salesPurchases'
                                    ),
                                    animal.derived.nestedTables.salesPurchases,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable(
                                'salesPurchases'
                              )"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .salesPurchases"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                  @click="
                                    animal.deleteRecord(
                                      'salesPurchases',
                                      row.id
                                    )
                                  "
                                >
                                  <span class="glyphicon glyphicon-minus">
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'salesPurchases'
                                )"
                              >
                                <div
                                  v-if="
                                    [
                                      'price',
                                      'purchaseCommissionRate',
                                      'salesCommissionRate',
                                      'eventOrganization',
                                      'freightCost',
                                      'weight',
                                      'freightRage',
                                    ].indexOf(index) === -1
                                  "
                                >
                                  {{
                                    $utils.renderValueAs(
                                      row[index],
                                      getLabelConfig(`fields.${index}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'salesPurchases',
                                        row.id,
                                        index,
                                        row[index],
                                        true
                                      )
                                    "
                                    v-model="row[index]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div
                  class="panel panel-default"
                  v-if="
                    animals.reduce((reduction, animal) => {
                      return reduction || animal.showPregChecks();
                    }, false)
                  "
                >
                  <div
                    class="card card-body mb-4"
                    :class="{
                      'bg-dark': $vuetify.theme.dark,
                    }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#pregChecks">
                        {{ labels.animalDetails.panels.pregChecks[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.pregChecks.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="pregChecks"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.pregChecks.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.pregChecks.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.pregChecks'
                                    ),
                                    animal.derived.nestedTables.pregChecks,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable(
                                'pregChecks'
                              )"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .pregChecks"
                            >
                              <td>
                                <button
                                  :class="{
                                    'disable-cursor': animal.disallowChanges(),
                                  }"
                                  :disabled="animal.disallowChanges()"
                                  class="btn btn-danger btn-xs"
                                  type="button"
                                  @click="
                                    animal.deleteRecord('pregChecks', row.id)
                                  "
                                >
                                  <span class="glyphicon glyphicon-minus">
                                    <v-icon class="white--text"
                                      >mdi-delete</v-icon
                                    >
                                  </span>
                                </button>
                              </td>
                              <td
                                :key="_i"
                                v-for="(indexSub, _i) in fieldsForTable(
                                  'pregChecks'
                                )"
                              >
                                <div
                                  v-if="
                                    [
                                      // some values are disabled until we can support edit
                                      'actualCalvingDate',
                                      'bcs',
                                      'bullTurninDate',
                                      'cycle',
                                      'daysBred',
                                      'daysSinceLastPregCheck',
                                      'fetalSex',
                                      'method',
                                      'mouthScore',
                                      //'predictedCalvingDate',
                                      //'pregCheckTime',
                                      'result',
                                      'status',
                                      'technician',
                                      'testType',
                                    ].indexOf(indexSub) === -1
                                  "
                                >
                                  {{
                                    $utils.renderValueAs(
                                      row[indexSub],
                                      getLabelConfig(`fields.${indexSub}`).type,
                                      true
                                    )
                                  }}
                                </div>
                                <div
                                  v-else-if="indexSub === 'actualCalvingDate'"
                                >
                                  <input
                                    type="datetime-local"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    v-model="row[indexSub]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                                <div v-else-if="indexSub === 'bullTurninDate'">
                                  <input
                                    type="date"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    v-model="row[indexSub]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                                <div v-else-if="indexSub === 'bcs'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="!labels.enums.bcs[row.bcs]"
                                      :value="row.bcs"
                                    >
                                      {{ row.bcs }}
                                    </option>
                                    <option
                                      :key="index"
                                      v-for="(value, index) in labels.enums.bcs"
                                      :value="index"
                                    >
                                      {{
                                        getLabelTranslation(
                                          `enums.bcs.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div v-else-if="indexSub === 'cycle'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      :key="index"
                                      v-for="(_value, index) in labels.enums
                                        .cycles"
                                      :value="
                                        index == 1 || index == 2
                                          ? `Cycle ${index}`
                                          : index
                                      "
                                    >
                                      {{
                                        (index == 1 || index == 2
                                          ? "Cycle "
                                          : "") +
                                        getLabelTranslation(
                                          `enums.cycles.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div v-else-if="indexSub === 'fetalSex'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="
                                        !labels.enums.fetalSexes[row.fetalSex]
                                      "
                                      :value="row.fetalSex"
                                    >
                                      {{ row.fetalSex }}
                                    </option>
                                    <option value=""></option>
                                    <option
                                      :key="index"
                                      v-for="(value, index) in labels.enums
                                        .fetalSexes"
                                      :value="index"
                                    >
                                      {{
                                        getLabelTranslation(
                                          `enums.fetalSexes.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div v-else-if="indexSub === 'method'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="
                                        !labels.enums.pregCheckTestMethods[
                                          row.method
                                        ]
                                      "
                                      :value="row.method"
                                    >
                                      {{ row.method }}
                                    </option>
                                    <option
                                      :key="index"
                                      v-for="(value, index) in labels.enums
                                        .pregCheckTestMethods"
                                      :value="index"
                                    >
                                      {{
                                        getLabelTranslation(
                                          `enums.pregCheckTestMethods.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <!-- Does not work until we localize or trim datetime first -->
                                <!-- <div
                                  v-else-if="
                                    indexSub === 'predictedCalvingDate'
                                  "
                                >
                                  <input
                                    type="datetime-local"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded: new Date().toISOString(),
                                        }
                                      )
                                    "
                                    v-model="row[indexSub]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div> -->
                                <!-- Does not work until we localize time first -->
                                <!-- <div v-else-if="indexSub === 'pregCheckTime'">
                                  <input
                                    type="datetime-local"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded: new Date().toISOString(),
                                        }
                                      )
                                    "
                                    v-model="row[indexSub]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div> -->
                                <div v-else-if="indexSub === 'result'">
                                  <template v-if="!row.cycle">
                                    <select
                                      v-model="row[indexSub]"
                                      :class="{
                                        'dark-input': $vuetify.theme.dark,
                                        'bg-dark': $vuetify.theme.dark,
                                      }"
                                      @change="
                                        modify(
                                          animal,
                                          'pregChecks',
                                          row.id,
                                          indexSub,
                                          row[indexSub],
                                          true,
                                          false,
                                          {
                                            timeRecorded:
                                              new Date().toISOString(),
                                          }
                                        )
                                      "
                                      :disabled="disallowChanges(animal)"
                                    >
                                      <option
                                        v-if="
                                          !labels.enums.pregResults[row.result]
                                        "
                                        :value="row.result"
                                      >
                                        {{ row.result }}
                                      </option>
                                      <option
                                        :key="index"
                                        v-for="(value, index) in labels.enums
                                          .pregResults"
                                        :value="index"
                                      >
                                        {{
                                          getLabelTranslation(
                                            `enums.pregResults.${index}`,
                                            index
                                          )
                                        }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-else>
                                    {{
                                      !labels.enums.pregResults[row.result]
                                        ? row.result
                                        : getLabelTranslation(
                                            `enums.pregResults.${row.result}`
                                          )
                                    }}
                                  </template>
                                </div>
                                <div v-else-if="indexSub === 'testType'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="
                                        !labels.enums.pregCheckTests[
                                          row.testType
                                        ]
                                      "
                                      :value="row.testType"
                                    >
                                      {{ row.testType }}
                                    </option>
                                    <option
                                      :key="index"
                                      v-for="(value, index) in labels.enums
                                        .pregCheckTests"
                                      :value="index"
                                    >
                                      {{
                                        getLabelTranslation(
                                          `enums.pregCheckTests.${index}`,
                                          index
                                        )
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  v-else-if="
                                    indexSub == 'daysSinceLastPregCheck'
                                  "
                                >
                                  {{
                                    Animal.getAgeOfTimestamp(
                                      row.pregCheckTime,
                                      {
                                        ignoreDays: true,
                                        ignoreHours: true,
                                      }
                                    )
                                  }}
                                </div>
                                <div v-else-if="indexSub == 'mouthScore'">
                                  <select
                                    v-model="row[indexSub]"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                      'bg-dark': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true,
                                        false,
                                        {
                                          timeRecorded:
                                            new Date().toISOString(),
                                        }
                                      )
                                    "
                                    :disabled="disallowChanges(animal)"
                                  >
                                    <option
                                      v-if="row.mouthScore != null"
                                      :value="row[indexSub]"
                                    >
                                      {{
                                        row.mouthScore.mouthScore ||
                                        row.mouthScore
                                      }}
                                    </option>
                                    <option
                                      v-if="
                                        herdMeta.mouthscores.length &&
                                        ((!item.deleted && item.pregCheck) ||
                                          (row.mouthScore &&
                                            row.mouthScore.id &&
                                            item.id == row.mouthScore.id))
                                      "
                                      :key="index"
                                      v-for="(
                                        item, index
                                      ) in herdMeta.mouthscores"
                                      :value="item"
                                    >
                                      {{ item.mouthScore }}
                                    </option>
                                  </select>
                                </div>
                                <div v-else>
                                  <input
                                    type="textarea"
                                    :class="{
                                      'dark-input': $vuetify.theme.dark,
                                    }"
                                    @change="
                                      modify(
                                        animal,
                                        'pregChecks',
                                        row.id,
                                        indexSub,
                                        row[indexSub],
                                        true
                                      )
                                    "
                                    v-model="row[indexSub]"
                                    :disabled="disallowChanges(animal)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>

                <div
                  class="panel panel-default"
                  v-if="
                    animals.reduce((reduction, animal) => {
                      return reduction || animal.showBullTests();
                    }, false)
                  "
                >
                  <div
                    class="card card-body mb-4"
                    :class="{ 'bg-dark': $vuetify.theme.dark }"
                  >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#bullTests">
                        {{ labels.animalDetails.panels.bullTests[local] }}

                        ({{
                          animals.reduce(
                            (reduction, animal) =>
                              reduction +
                              animal.derived.nestedTables.bullTests.length,
                            0
                          )
                        }})</a
                      >
                    </h4>
                  </div>
                  <div
                    id="bullTests"
                    class="panel-collapse collapse"
                    :class="
                      animals.length === 1 ||
                      !animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.bullTests.length,
                        0
                      )
                        ? 'collapse'
                        : ''
                    "
                  >
                    <div
                      :key="animal_idx"
                      v-for="(animal, animal_idx) in animals"
                      class="panel-body individualAnimal"
                    >
                      <h2 v-if="animals.length > 1">
                        Animal {{ animal_idx + 1 }}
                      </h2>
                      <div v-if="animal.derived.nestedTables.bullTests.length">
                        <table
                          class="table table-striped table-bordered dataTable"
                          :class="{
                            'table-dark-mode': $vuetify.theme.dark,
                            'table-dark': $vuetify.theme.dark,
                          }"
                        >
                          <thead>
                            <th>
                              <button
                                class="btn btn-success btn-xs"
                                type="button"
                                @click="
                                  exportToCSV(
                                    animal,
                                    'tabular',
                                    getLabelTranslation(
                                      'animalDetails.panels.bullTests'
                                    ),
                                    animal.derived.nestedTables.bullTests,
                                    true,
                                    true
                                  )
                                "
                              >
                                <span class="glyphicon glyphicon-export">
                                  <v-icon class="white--text"
                                    >mdi-file-export-outline</v-icon
                                  >
                                </span>
                              </button>
                            </th>
                            <th
                              :key="_i"
                              v-for="(index, _i) in fieldsForTable('bullTests')"
                            >
                              {{
                                getLabelTranslation(`fields.${index}`, index)
                              }}
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              :key="index"
                              v-for="(row, index) in animal.derived.nestedTables
                                .bullTests"
                            >
                              <td></td>
                              <td
                                :key="_i"
                                v-for="(index, _i) in fieldsForTable(
                                  'bullTests'
                                )"
                              >
                                {{
                                  $utils.renderValueAs(
                                    row[index],
                                    getLabelConfig(`fields.${index}`).type,
                                    true
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                      <div v-else>Nothing to show</div>
                    </div>
                    <div class="panel-footer">
                      &nbsp;
                      <!--- Panel footer, blank space --->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#colors">
                    {{ labels.animalDetails.panels.colors[local] }}

                    ({{
                      animals.reduce(
                        (reduction, animal) =>
                          reduction + animal.derived.nestedTables.colors.length,
                        0
                      )
                    }})</a
                  >
                  <span
                    class="ml-1"
                    :key="index"
                    v-for="(animal, index) in animals"
                  >
                    <template
                      v-if="
                        animal.doc &&
                        animal.doc.derived &&
                        animal.doc.derived.summaries &&
                        animal.doc.derived.summaries.main &&
                        animal.doc.derived.summaries.main.color
                      "
                    >
                      <span> - </span>
                      <span
                        class="badge"
                        :class="{
                          'badge-dark': !$vuetify.theme.dark,
                          'badge-light': $vuetify.theme.dark,
                        }"
                        style="margin-right: 5px"
                      >
                        {{ animal.doc.derived.summaries.main.color }}
                      </span>
                    </template>
                  </span>
                </h4>
              </div>
              <div
                id="colors"
                class="panel-collapse collapse"
                :class="
                  animals.length === 1 ||
                  !animals.reduce(
                    (reduction, animal) =>
                      reduction + animal.derived.nestedTables.colors.length,
                    0
                  )
                    ? 'collapse'
                    : ''
                "
              >
                <div
                  :key="animal_idx"
                  v-for="(animal, animal_idx) in animals"
                  class="panel-body individualAnimal"
                >
                  <h2 v-if="animals.length > 1">Animal {{ animal_idx + 1 }}</h2>
                  <div v-if="animal.derived.nestedTables.colors.length">
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                    >
                      <thead>
                        <th></th>
                        <th
                          :key="_i"
                          v-for="(index, _i) in fieldsForTable('colors')"
                        >
                          {{ getLabelTranslation(`fields.${index}`, index) }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(row, index) in animal.derived.nestedTables
                            .colors"
                        >
                          <td>
                            <button
                              :class="{
                                'disable-cursor': animal.disallowChanges(),
                              }"
                              :disabled="animal.disallowChanges()"
                              class="btn btn-danger btn-xs"
                              type="button"
                              @click="animal.deleteRecord('colors', row.id)"
                            >
                              <span>
                                <v-icon class="white--text">mdi-delete</v-icon>
                              </span>
                            </button>
                          </td>
                          <td
                            :key="_i"
                            v-for="(index, _i) in fieldsForTable('colors')"
                          >
                            <template v-if="index == 'color'">
                              {{
                                animal.herdMeta.getNameById(
                                  row.colorId,
                                  herdMeta.colors,
                                  "color"
                                )
                              }}
                            </template>
                            <template v-if="index == 'createdBy'">
                              {{
                                animal.herdMeta.getNameById(
                                  row.createdBy,
                                  herdMeta.users,
                                  "Unknown"
                                )
                              }}
                            </template>
                            <template v-else>
                              {{
                                $utils.renderValueAs(
                                  row[index],
                                  getLabelConfig(`fields.${index}`).type,
                                  true
                                )
                              }}
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                  <div v-else>Nothing to show</div>
                </div>
                <div class="panel-footer">
                  &nbsp;
                  <!--- Panel footer, blank space --->
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#sexes">
                    {{ labels.animalDetails.panels.sexes[local] }}

                    ({{
                      animals.reduce(
                        (reduction, animal) =>
                          reduction + animal.derived.nestedTables.sexes.length,
                        0
                      )
                    }})</a
                  >
                  <span
                    class="ml-1"
                    :key="index"
                    v-for="(animal, index) in animals"
                  >
                    <template
                      v-if="
                        animal.doc &&
                        animal.doc.derived &&
                        animal.doc.derived.summaries &&
                        animal.doc.derived.summaries.main &&
                        animal.doc.derived.summaries.main.sex
                      "
                    >
                      <span> - </span>
                      <span
                        class="badge"
                        :class="{
                          'badge-dark': !$vuetify.theme.dark,
                          'badge-light': $vuetify.theme.dark,
                        }"
                        style="margin-right: 5px"
                      >
                        {{ animal.doc.derived.summaries.main.sex }}
                      </span>
                    </template>
                  </span>
                </h4>
              </div>

              <div
                id="sexes"
                class="panel-collapse collapse"
                :class="
                  animals.length === 1 ||
                  !animals.reduce(
                    (reduction, animal) =>
                      reduction + animal.derived.nestedTables.sexes.length,
                    0
                  )
                    ? 'collapse'
                    : ''
                "
              >
                <div
                  :key="animal_idx"
                  v-for="(animal, animal_idx) in animals"
                  class="panel-body individualAnimal"
                >
                  <h2 v-if="animals.length > 1">Animal {{ animal_idx + 1 }}</h2>
                  <div v-if="animal.derived.nestedTables.sexes.length">
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                    >
                      <thead>
                        <th></th>
                        <th
                          :key="_i"
                          v-for="(index, _i) in fieldsForTable('sexes')"
                        >
                          {{ getLabelTranslation(`fields.${index}`, index) }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(row, index) in animal.derived.nestedTables
                            .sexes"
                        >
                          <td>
                            <button
                              :class="{
                                'disable-cursor': animal.disallowChanges(),
                              }"
                              :disabled="animal.disallowChanges()"
                              class="btn btn-danger btn-xs"
                              type="button"
                              @click="animal.deleteRecord('sexes', row.id)"
                            >
                              <span>
                                <v-icon class="white--text">mdi-delete</v-icon>
                              </span>
                            </button>
                          </td>
                          <td
                            :key="_i"
                            v-for="(index, _i) in fieldsForTable('sexes')"
                          >
                            <template v-if="index == 'sex'">
                              {{
                                animal.herdMeta.getNameById(
                                  row.sexId,
                                  herdMeta.sexes,
                                  "sex"
                                )
                              }}
                            </template>
                            <template v-if="index == 'createdBy'">
                              {{
                                animal.herdMeta.getNameById(
                                  row.createdBy,
                                  herdMeta.users,
                                  "Unknown"
                                )
                              }}
                            </template>
                            <template v-else>
                              {{
                                $utils.renderValueAs(
                                  row[index],
                                  getLabelConfig(`fields.${index}`).type,
                                  true
                                )
                              }}
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                  <div v-else>Nothing to show</div>
                </div>
                <div class="panel-footer">
                  &nbsp;
                  <!--- Panel footer, blank space --->
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#statuses">
                    {{ labels.animalDetails.panels.statuses[local] }}

                    ({{
                      animals.reduce(
                        (reduction, animal) =>
                          reduction +
                          animal.derived.nestedTables.statuses.length,
                        0
                      )
                    }})</a
                  >
                  <span
                    class="ml-1"
                    :key="index"
                    v-for="(animal, index) in animals"
                  >
                    <span> - </span>
                    <span
                      class="badge"
                      :class="{
                        'badge-dark': !$vuetify.theme.dark,
                        'badge-light': $vuetify.theme.dark,
                      }"
                      style="margin-right: 5px"
                    >
                      <template
                        v-if="
                          !animal.doc ||
                          !animal.doc.derived ||
                          !animal.doc.derived.summaries ||
                          !animal.doc.derived.summaries.main ||
                          !animal.doc.derived.summaries.main.status
                        "
                      >
                        Alive
                      </template>
                      <template v-else>
                        {{ animal.doc.derived.summaries.main.status }}
                      </template>
                    </span>
                  </span>
                </h4>
              </div>
              <div
                id="statuses"
                class="panel-collapse collapse"
                :class="
                  animals.length === 1 ||
                  !animals.reduce(
                    (reduction, animal) =>
                      reduction + animal.derived.nestedTables.statuses.length,
                    0
                  )
                    ? 'collapse'
                    : ''
                "
              >
                <div
                  :key="animal_idx"
                  v-for="(animal, animal_idx) in animals"
                  class="panel-body individualAnimal"
                >
                  <h2 v-if="animals.length > 1">Animal {{ animal_idx + 1 }}</h2>
                  <div v-if="animal.derived.nestedTables.statuses.length">
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                    >
                      <thead>
                        <th></th>
                        <th
                          :key="_i"
                          v-for="(index, _i) in fieldsForTable('statuses')"
                        >
                          {{ getLabelTranslation(`fields.${index}`, index) }}
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(row, index) in animal.derived.nestedTables
                            .statuses"
                        >
                          <td>
                            <button
                              :class="{
                                'disable-cursor': animal.disallowChanges(),
                              }"
                              :disabled="animal.disallowChanges()"
                              class="btn btn-danger btn-xs"
                              type="button"
                              @click="animal.deleteRecord('statuses', row.id)"
                            >
                              <span>
                                <v-icon class="white--text">mdi-delete</v-icon>
                              </span>
                            </button>
                          </td>
                          <td
                            :key="_i"
                            v-for="(index, _i) in fieldsForTable('statuses')"
                          >
                            <template v-if="index == 'status'">
                              {{
                                getTranslation(`enums.statuses.${row.status}`)
                              }}
                            </template>
                            <template v-else-if="index == 'createdBy'">
                              {{
                                animal.herdMeta.getNameById(
                                  row.createdBy,
                                  herdMeta.users,
                                  "Unknown"
                                )
                              }}
                            </template>
                            <template v-else>
                              {{
                                $utils.renderValueAs(
                                  row[index],
                                  getLabelConfig(`fields.${index}`).type,
                                  true
                                )
                              }}
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                  <div v-else>Nothing to show</div>
                </div>
                <div class="panel-footer">
                  &nbsp;
                  <!--- Panel footer, blank space --->
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#parents">
                    Parents ({{
                      animals.reduce(
                        (acumulator, actualAnimal) =>
                          acumulator +
                          actualAnimal.getParentCollection().length,
                        0
                      )
                    }})</a
                  >
                </h4>
              </div>
              <div id="parents" class="panel-collapse collapse">
                <div :key="animal_idx" v-for="(animal, animal_idx) in animals">
                  <h2 v-if="animals.length > 1">Animal {{ animal_idx + 1 }}</h2>
                  <div
                    v-if="animal.doc && animal.getParentCollection().length > 0"
                  >
                    <table
                      class="table table-striped table-bordered dataTable"
                      :class="{
                        'table-dark-mode': $vuetify.theme.dark,
                        'table-dark': $vuetify.theme.dark,
                      }"
                    >
                      <thead>
                        <th style="width: 40px"></th>
                        <th>Time Recorded</th>
                        <th>Tag Summary</th>
                        <th>Relationship</th>
                        <th>Created On</th>
                      </thead>
                      <tbody>
                        <tr
                          :key="index"
                          v-for="(
                            relationship, index
                          ) in animal.getParentCollection()"
                        >
                          <td>
                            <button
                              :class="{
                                'disable-cursor': animal.disallowChanges(),
                              }"
                              :disabled="animal.disallowChanges()"
                              @click="
                                animal.deleteRecord(
                                  relationship.tableName,
                                  relationship.id
                                )
                              "
                              class="btn btn-danger btn-xs"
                              type="button"
                            >
                              <v-icon class="white--text">mdi-delete</v-icon>
                            </button>
                          </td>
                          <td>
                            {{
                              $utils.renderValueAs(
                                relationship.timeRecorded,
                                "datetime",
                                true
                              )
                            }}
                          </td>
                          <td>
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: { id: relationship.parentId },
                              }"
                              class="text-none"
                              style="font-size: 1.4rem"
                              target="_blank"
                            >
                              {{ relationship.tagSummary }}
                            </router-link>
                          </td>
                          <td>{{ relationship.relationship }}</td>
                          <td>
                            {{
                              $utils.renderValueAs(
                                relationship.createdOn,
                                "datetime",
                                true
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>Nothing to show</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-dialog
        v-model="dialogs.tsu.show"
        max-width="350px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <h4 class="ma-0">{{ getTranslation("editTsu") }}</h4>
            <v-icon @click="dialogs.tsu.show = false"> mdi-close </v-icon>
          </v-card-title>
          <v-divider class="mt-0"></v-divider>
          <v-card-text class="pb-2">
            <v-row class="pa-2 mb-3">
              <v-col class="pa-0 d-flex flex-column" cols="12">
                <div class="mb-2">
                  <v-menu
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="dialogs.tsu.showCalendar"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-calendar"
                        class="mediumFont"
                        hide-details
                        outlined
                        readonly
                        style="font-size: 1.3rem"
                        v-bind="attrs"
                        v-model="dialogs.tsu.form.date"
                        v-on:click:append="dialogs.tsu.showCalendar = true"
                        v-on="on"
                      >
                        <template #label>
                          <p class="ma-0 biggerFont">
                            {{ getTranslation("date") }}
                          </p>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      @input="dialogs.tsu.showCalendar = false"
                      v-model="dialogs.tsu.form.date"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <v-text-field
                  class="mediumFont"
                  clearable
                  hide-details
                  outlined
                  v-model="dialogs.tsu.form.tsu"
                >
                  <template #label>
                    <p class="ma-0 biggerFont">
                      {{ getTranslation("fields.dnaNumber") }}
                    </p>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-0"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              @click="editTsuNumber"
              :disabled="
                !dialogs.tsu.form.tsu || dialogs.tsu.form.tsu.trim() == ''
              "
              color="success"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      max-width="350px"
      transition="dialog-transition"
      v-model="dialogs.herd.show"
    >
      <v-card>
        <v-card-title class="px-6 pb-0 d-flex justify-space-between">
          <h4 class="ma-0">{{ getTranslation("Edit Herd") }}</h4>
          <v-icon @click="dialogs.herd.show = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 135px" class="py-2 px-6">
          <div class="mb-2">
            <v-menu
              :close-on-content-click="false"
              min-width="auto"
              offset-y
              transition="scale-transition"
              v-model="dialogs.herd.showCalendar"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  append-icon="mdi-calendar"
                  class="mediumFont"
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-model="dialogs.herd.form.date"
                  v-on:click:append="dialogs.herd.showCalendar = true"
                  v-on="on"
                >
                  <template #label>
                    <p class="ma-0 biggerFont">
                      {{ getTranslation("date") }}
                    </p>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @input="dialogs.herd.showCalendar = false"
                v-model="dialogs.herd.form.date"
              ></v-date-picker>
            </v-menu>
          </div>
          <v-select
            :items="options.herds"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            clearable
            hide-details
            item-text="name"
            outlined
            return-object
            style="font-size: 1.3rem"
            v-model="dialogs.herd.form.herdSelected"
          >
            <template #label>
              <p style="font-size: 1.5rem; font-weight: 'bold'" class="ma-0">
                {{ getTranslation("Herd") }}
              </p>
            </template>
          </v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="!dialogs.herd.form.herdSelected"
            @click="editHerd"
            color="success"
          >
            {{ getLabelTranslation("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { EventBus } from "../mixins/Config";
import { IsSuperAdmin } from "../utils/functions/auth";
import { Loader } from "@googlemaps/js-api-loader";
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "animal-details",
  metaInfo: {
    title: "Animal Details",
  },
  props: {
    animalsToCompare: {
      type: Array,
    },
  },
  mixins: [TranslationMixin],
  data: () => ({
    Animal: Animal,
    animalAguid: null,
    animals: [],
    dialogs: {
      herd: {
        animal: null,
        form: {
          herdSelected: null,
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        },
        show: false,
        showCalendar: false,
      },
      tsu: {
        animal: null,
        form: {
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          tsu: null,
        },
        show: false,
        showCalendar: false,
      },
    },
    googleMapIsLoaded: false,
    googleMapIsNotAvailable: false,
    herdMeta: null,
    operations: [],
    options: {
      herds: [],
    },
    organizationId: null,
    pouches: null,
    showGoogleMaps: false,
    userId: null,
    loading: true,
  }),
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
    }),
    isSuperAdmin: function () {
      return IsSuperAdmin();
    },
    compareMode: function () {
      return this.animals.length > 1;
    },
    lastOperation: function () {
      if (!this.operations.length) return null;
      return this.operations[this.operations.length - 1];
    },
    labels: function () {
      return this.translate;
    },
    local: function () {
      return this.localeLang;
    },
    t: function () {
      return {
        animalDetails: this.translate.navbar.animalDetails[this.localeLang],
        birth: this.translate.animalDetails.Birth[this.localeLang],
        complete: this.translate.complete[this.localeLang],
      };
    },
    DAO: function () {
      return DAO;
    },
  },
  created: async function () {
    EventBus.$on("reverse-animals", () => {
      this.animals = [this.animals[1], this.animals[0]];
    });
    this.herdMeta = this.$herdMeta;
    // FIXME: Find a way to avoid needing to do this
    this.herdMeta.setItems(this.getOrganizationItems, {
      as: "receivingRanches",
      id: "id",
    });

    this.pouches = this.herdMeta.pouches;
    this.organizationId = this.$organizationID;
    this.userId = this.$userID;
    this.animalAguid = this.animalGuid ? this.animalGuid : this.$route.query.id;
    if (!this.animalsToCompare) {
      try {
        await this.herdMeta.promiseLoaded();
        const herdOptions = await this.herdMeta.getMetaHerdsAsync(
          true,
          true,
          false
        );
        if (herdOptions instanceof Error) this.options.herds = [];
        else this.options.herds = herdOptions;
        return this.pouches.organization
          .get(this.animalAguid)
          .then((doc) => {
            this.animals = [
              new Animal(doc._id, this.herdMeta, doc, this.userId),
            ];

            this.animals.forEach((animal) => animal.latchToUpdateStream());
          })
          .catch((e) => {
            throw e;
          });
      } catch (error) {
        Vue.notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: this.getLabelTranslation("animalDetails.animalNoExist"),
          duration: 10000,
        });
      }
    } else {
      this.animals = this.animalsToCompare;
    }
  },
  beforeDestroy: function () {
    EventBus.$off("reverse-animals");
  },
  methods: {
    // Exports data according to orientation
    // Will automatically convert headers to more friendly versions
    // Will hide entire columns if autoHide === true and autoTranslate === true and if translation file indicates that data is for a hidden column
    exportToCSV: function (
      animal,
      orientation,
      title,
      d,
      prependId,
      prependTag
    ) {
      let data = d;
      if (orientation !== "tabular") {
        data = [data];
      }

      // Deep copy
      data = Utils.copyObject(data);

      data = data.reduce((arr, row) => {
        Object.keys(row).forEach((key) => {
          // ids
          if ("tagId" === key)
            row.tagTable = this.herdMeta.getTagName(row.tagId);
          // birth stuff
          else if ("birthDate" === key)
            row.birthDate =
              row.birthDate && row.birthDate.birthDate !== undefined
                ? row.birthDate.birthDate
                : row.birthDate || null;
          else if ("deathDate" === key)
            row.deathDate =
              row.deathDate && row.deathDate.deathDate !== undefined
                ? row.deathDate.deathDate
                : row.deathDate || null;
          else if ("birthWeight" === key)
            row.birthWeight =
              row.birthWeight &&
              (row.birthWeight.birthWeight !== undefined ||
                row.birthWeight.weight !== undefined)
                ? row.birthWeight.birthWeight || row.birthWeight.weight
                : row.birthWeight || row.weight || null;
          else if ("breed" === key)
            row.breed =
              row.breed && row.breed.breed !== undefined
                ? row.breed.breed
                : row.breed || null;
          else if ("gender" === key)
            row.gender =
              row.gender && row.gender.gender !== undefined
                ? row.gender.gender
                : row.gender || null;
          else if ("origin" === key) {
            // We have to do this because we currently allow users to store arbitrary origins (fixing this would require updating data and making import smarter)
            if (row.origin && row.origin.originId)
              row.origin = this.herdMeta.getNameById(
                row.origin.originId,
                this.herdMeta.origins,
                "origin"
              );
            else if (row.origin && row.origin.origin)
              row.origin =
                row.origin.origin +
                ` (${this.herdMeta.translationMixin.methods.getLabelTranslation(
                  "animalDetails['non-indexed']"
                )})`;
            else row.origin = "";
          } else if ("species" === key) {
            row.species =
              row.species && row.species.species !== undefined
                ? this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.species.${row.species.species}`,
                    row.species.species || " "
                  )
                : this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.species.${row.species}`,
                    row.species || " "
                  );
          } else if ("percentCross" === key)
            row.percentCross =
              row.percentCross && row.percentCross.percentCross !== undefined
                ? row.percentCross.percentCross
                : row.percentCross || null;
          else if ("birthCalvingEase" === key)
            row.birthCalvingEase =
              row.birthCalvingEase &&
              row.birthCalvingEase.birthCalvingEase !== undefined
                ? this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.birthCalvingEases.${row.birthCalvingEase.birthCalvingEase}`,
                    row.birthCalvingEase.birthCalvingEase || " "
                  )
                : this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.birthCalvingEases.${row.birthCalvingEase}`,
                    row.birthCalvingEase || " "
                  );
          else if ("vigor" === key)
            row.vigor =
              row.vigor && row.vigor.vigor
                ? this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.vigors.${row.vigor.vigor}`,
                    row.vigor.vigor || " "
                  )
                : this.herdMeta.translationMixin.methods.getLabelTranslation(
                    `enums.vigors.${row.vigor}`,
                    row.vigor || " "
                  );
          // characteristics
          else if ("dnaNumber" === key)
            row.dnaNumber =
              row.dnaNumber && row.dnaNumber.dnaNumber !== undefined
                ? row.dnaNumber.dnaNumber
                : row.dnaNumber || null;
          else if (
            "status" === key &&
            d != animal.derived.nestedTables.pregChecks
          )
            // Pregchecks has a status field of its own
            row.status =
              row.status && row.status.status !== undefined
                ? row.status.status
                : row.status || null;
          else if ("color" === key) {
            // We have to do this because we currently allow users to store arbitrary colors (fixing this would require updating data and making import smarter)
            if (row.color && row.color.colorId)
              row.color = this.herdMeta.getNameById(
                row.color.colorId,
                this.herdMeta.colors,
                "color"
              );
            else if (row.color && row.color.color)
              row.color =
                row.color.color +
                ` (${this.herdMeta.translationMixin.methods.getLabelTranslation(
                  "animalDetails['non-indexed']"
                )})`;
            else row.color = "";
          } else if ("herd" === key) {
            // We have to do this because we currently allow users to store arbitrary herd (fixing this would require updating data and making import smarter)
            if (row.herd && row.herd.herd) row.herd = row.herd.herd;
            else if (row.herd && row.herd.herdId)
              row.herd = this.herdMeta.getNameById(
                row.herd.herdId,
                this.herdMeta.herds,
                "name"
              );
            else row.herd = "";
          } else if ("receivingRanch" === key) {
            row.receivingRanch = row.receivingRanch.receivingRanch;
          } else if ("sex" === key) {
            // We have to do this because we currently allow users to store arbitrary sexes (fixing this would require updating data and making import smarter)
            if (row.sex && row.sex.sexId)
              row.sex = this.herdMeta.getNameById(
                row.sex.sexId,
                this.herdMeta.sexes,
                "sex"
              );
            else if (row.sex && row.sex.sex)
              row.sex =
                row.sex.sex +
                ` (${this.herdMeta.translationMixin.methods.getLabelTranslation(
                  "animalDetails['non-indexed']"
                )})`;
            else row.sex = "";
          }

          const isAnimalId = prependId && "animalid" === key.toLowerCase();
          const isTag =
            prependTag && !isAnimalId && "tagvalue" === key.toLowerCase();

          const config = this.herdMeta.translationMixin.methods.getLabelConfig(
            `fields.${key}`
          );
          if (config.hide && !isAnimalId && !isTag) delete row[key];
        });

        // Ensure that uniquely identifying information is included on each row, preferring the per-row information over that of the animal for which the data was generated
        let newRow = {};
        if (prependId) {
          newRow["uniqueAnimalId"] =
            row["uniqueAnimalId"] || row["animalId"] || animal.guid;
        }
        // Remove now-transferred/unwanted data
        delete row["backtagColors"];
        delete row["backtagValues"];
        delete row["uniqueAnimalId"];
        delete row["animalId"];
        if (prependTag) {
          newRow["EIDtagValues"] =
            row["EIDtagValues"] || animal.derived.tagSummary.EIDtagValues;
          newRow["visualTagValues"] =
            row["visualTagValues"] || animal.derived.tagSummary.visualTagValues;
        }
        delete row["nuesTagValues"];
        delete row["visualTagColors"];
        delete row["visualTagValues"];
        delete row["EIDtagValues"];
        delete row["EIDcolors"];

        Object.keys(row).forEach((key) => {
          newRow[key] = row[key];
        });

        arr.push(newRow);
        return arr;
      }, []);

      data = data.map((row) => {
        let newRow = {};

        Object.keys(row).forEach((key) => {
          const translation =
            this.herdMeta.translationMixin.methods.getLabelTranslation(
              `fields.${key}`,
              key
            );
          newRow[translation] = Utils.renderValueAs(
            row[key],
            this.herdMeta.translationMixin.methods.getLabelConfig(
              `fields.${key}`
            ).type,
            false
          );
        });

        return newRow;
      });

      // Adaptation from Papa Parse documentation at https://www.papaparse.com/docs#json-to-csv
      const csv = Papa.unparse(
        {
          fields: data.length ? Object.keys(data[0]) : [],
          data: data,
        },
        {
          worker: true,
        }
      );

      // https://stackoverflow.com/a/23922475
      let pom = document.createElement("a");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute(
        "download",
        `Animal - ${
          animal.getTagSummary() || animal.guid
        } - ${title} ${moment().format("YYYY-MM-DD")}.csv`
      );
      pom.click();
    },
    fieldsForTable: function (table) {
      switch (table) {
        case "sexes":
          return ["sex", "timeRecorded", "createdOn"];
        case "colors":
          return ["color", "timeRecorded", "createdOn"];
        case "statuses":
          return ["status", "timeRecorded", "createdOn"];
        case "breedings":
          return [
            "breedDate",
            "sire",
            "weatherId",
            "location",
            "comment",
            "timeRecorded",
            "createdOn",
            "lastUpdated",
          ]; // Perhaps over time expose: "straw", "aiTechnician", "estimateCalvingDate", "coverSire", "firstAIServiceDate", "secondAIServiceDate", "servicedateIn", "servicedateOut", "serviceDays", "pregResult", "pregStatus", "pregCheckTime", "pregCheckMethod2", "comment", "weatherWhenPregChecked"
        case "bullTests":
          // Only deletions are allowed, therefore we do not expose lastUpdated
          return [
            "testCheckTime",
            "test",
            "result",
            "testedBy",
            "testMethod",
            "comment",
            "createdOn",
          ];
        case "calvings":
          // Only deletions are allowed, therefore we do not expose lastUpdated
          return [
            "offspringTags",
            "birthWeight",
            "birthDate",
            "birthCalvingEase",
            "relationship",
            "sex",
            "status",
            "vigor",
            "hoof",
            "milk",
            "createdOn",
          ];
        case "comments":
          // Only deletions are allowed, therefore we do not expose lastUpdated
          return ["timeRecorded", "comment", "createdOn"];
        case "ids":
          return [
            "tagId",
            "tagTable",
            "tagValue",
            "type",
            "status",
            "location",
            "tagColor",
            "manufacturer",
            "size",
            "timeRecorded",
            "createdOn",
            "lastUpdated",
          ];
        case "pregChecks":
          return [
            "pregCheckTime",
            "testType",
            "status",
            "daysBred",
            "daysSinceLastPregCheck",
            "bcs",
            "bullTurninDate",
            "fetalSex",
            "mouthScore",
            "cycle",
            "method",
            "result",
            "technician",
            "predictedCalvingDate",
            "actualCalvingDate",
            "timeRecorded",
            "createdOn",
            "lastUpdated",
          ];
        case "salesPurchases":
          return [
            "price",
            "priceUnits",
            "eventOrganization",
            "purchaseCommissionRate",
            "commissionType",
            "salesCommissionRate",
            "transactionType",
            "weight",
            "weightUnits",
            "freightCost",
            "freightRage",
            "transactionDate",
            "timeRecorded",
            "createdOn",
            "lastUpdated",
          ];
        case "sightings":
          // Only deletions are allowed, therefore we do not expose lastUpdated
          return [
            "timeRecorded",
            "location",
            "groupNumber",
            "reason",
            "type",
            "previousLocation",
            "timeInField",
            "createdOn",
          ];
        case "treatments":
          // Edits are allowed, but # of columns is so large we hide many of them
          return [
            "vaccinationName",
            "vaccinationTime",
            "actualDose",
            "requiredDose",
            "cost",
            "actualWithdrawlDate",
            "comment",
            "rectalTemperature",
            "manufacturer",
            "purchaseDate",
            "expirationDate",
            "lotNumber",
          ]; //, "createdByUserName", "createdOn", "lastUpdatedByUserName", "lastUpdated"];
        case "weights":
          return [
            "timeRecorded",
            "weight",
            "adg2",
            "adgTotal",
            "comment",
            "source",
            "daysSinceLastWeight",
            "createdOn",
            "lastUpdated",
          ];
      }
    },
    showMaps: function () {
      this.$nextTick(() => {
        const geopointsExist = this.animals.some(({ doc }) => !!doc.geopoints);
        if (geopointsExist && !this.showGoogleMaps) {
          this.showGoogleMaps = true;
          this.initializeMaps();
        }
      });
    },
    initializeMaps: async function () {
      if (!this.$google) {
        const googleMapApi = new Loader({
          apiKey: "AIzaSyDTFTTJGbg02sid2CsYocV11bSFvEY5hDg",
        });
        try {
          await googleMapApi.load();
          this.$google = window.google;
        } catch (error) {
          this.googleMapIsLoaded = false;
          this.googleMapIsNotAvailable = true;
          return;
        }
      }
      this.googleMapIsNotAvailable = false;
      this.$nextTick(() => {
        this.googleMapIsLoaded = true;
        this.animals.forEach((animal, idx) => {
          const locations = this.getGeopoints(animal.doc.geopoints);
          const mapContainer = this.$refs[`googleMap${idx}`][0];
          const mainMapOption = {
            center: {
              lat: locations[0].lat,
              lng: locations[0].lng,
            },
            zoom: 9,
          };
          const map = new this.$google.maps.Map(mapContainer, mainMapOption);

          const now = moment();
          locations.forEach((location) => {
            let fillColor = "yellow"; // not yet used
            let hoursSince = "?";
            if (location.timeRecorded) {
              hoursSince = now.diff(moment(location.timeRecorded), "hours");
              let unit = "h";
              if (hoursSince <= 1) {
                fillColor = "green";
                now.diff(moment(location.timeRecorded), "minutes");
                unit = "min";
              } else if (hoursSince <= 24) {
                fillColor = "yellow";
                hoursSince = Utils.round(hoursSince, 0);
                unit = "hr";
              } else if (hoursSince < 24 * 30) {
                fillColor = "red";
                hoursSince = Utils.round(hoursSince / 24, 0);
                unit = "d";
              } else {
                fillColor = "red";
                hoursSince = Utils.round(hoursSince / (24 * 30), 0);
                unit = "mo.";
              }

              hoursSince += unit;
            }
            const icon = {
              origin: new google.maps.Point(0, -10),
              scaledSize: new google.maps.Size(100, 60),
              url: `http://maps.google.com/mapfiles/ms/icons/${fillColor}.png`,
            };
            new this.$google.maps.Marker({
              icon,
              label: { text: hoursSince, color: "black" },
              map,
              position: location,
            });
          });
        });
      });
    },
    getGeopoints: function (geopoints) {
      return (geopoints || [])
        .filter(({ deleted }) => !deleted)
        .map(({ GPSLat, GPSLong, GPSSampleTime, timeRecorded, z }) => ({
          lat: GPSLat ? Number(GPSLat) : null,
          lng: GPSLong ? Number(GPSLong) : null,
          timeRecorded: timeRecorded || GPSSampleTime,
        }))
        .sort(({ timeRecorded: a }, { timeRecorded: b }) => b.localeCompare(a));
    },
    displayTsuDialog: function (animal) {
      this.dialogs.tsu.animal = animal;
      this.dialogs.tsu.form.tsu = null;
      this.dialogs.tsu.show = true;
    },
    editTsuNumber: function () {
      let timeRecordedISO = moment(this.dialogs.tsu.form.date).toISOString();
      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        // Re-init with time
        timeRecordedISO = new Date().toISOString();
        // Consider: Add geopoint data here
      }
      $.when(
        this.modify(
          this.dialogs.tsu.animal,
          "dnaNumbers",
          null,
          "dnaNumber",
          this.dialogs.tsu.form.tsu.trim(),
          false,
          false,
          {
            createdOn: new Date().toISOString(),
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          }
        )
      )
        .done(() => {})
        .always(() => {
          this.dialogs.tsu.form.date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.dialogs.tsu.show = false;
        });
    },
    modify: function (
      animal,
      tableName,
      guid,
      field,
      val,
      deleteBaseRecord,
      skipSave,
      extended
    ) {
      animal
        .modify(
          tableName,
          guid,
          field,
          val,
          deleteBaseRecord,
          skipSave,
          extended
        )
        .fail((e) => {
          console.log(e);
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "Saving change failed. Please reload and retry.",
            duration: 1000,
          });
        })
        .done(() => {
          Vue.notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Saved",
            duration: 1000,
          });
        });
    },
    receiveMessage: function (message) {
      message.moment = moment();
      this.operations.push(message);
      setTimeout(() => {
        this.operations = this.operations.splice(1);
      }, 5000);
    },
    resetHerdDialogFields: function () {
      this.dialogs.herd.form.herdSelected = null;
      this.dialogs.herd.form.date = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dialogs.herd.show = false;
    },
    showHerdDialog: function (animal) {
      this.dialogs.herd.animal = animal;
      this.dialogs.herd.form.herdSelected = null;
      this.dialogs.herd.show = true;
    },
    editHerd: function () {
      let timeRecordedISO = moment(this.dialogs.herd.form.date).toISOString();
      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        // Re-init with time
        timeRecordedISO = new Date().toISOString();
        // Consider: Add geopoint data here
      }
      $.when(
        this.modify(
          this.dialogs.herd.animal,
          "herds",
          null,
          "herdId",
          this.dialogs.herd.form.herdSelected.id,
          false,
          false,
          {
            createdOn: new Date().toISOString(),
            herd: this.dialogs.herd.form.herdSelected.name,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          }
        )
      )
        .done(() => {})
        .always(() => {
          this.resetHerdDialogFields();
        });
    },
    disallowChanges: function (animal) {
      return animal.disallowChanges();
    },
    getAgeOfDuration: function (duration) {
      if (!duration || !moment(duration).isValid()) return null;

      return `${this.$utils.padLeft(
        duration.years(),
        2
      )}y ${this.$utils.padLeft(duration.months(), 2)}m ${this.$utils.padLeft(
        duration.days(),
        2
      )}d ${this.$utils.padLeft(duration.hours(), 2)}h`;
    },
    moment: function () {
      return moment;
    },
  },
};
</script>
<style scoped>
input,
select {
  border: 1px solid #ccc;
}
.biggerFont {
  font-size: 1.7rem;
}
.mediumFont {
  font-size: 1.5rem;
}
.disable-cursor {
  cursor: default;
}
</style>
