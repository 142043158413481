<template>
  <svg viewBox="0 0 22 22">
    <image :href="img" :height="dimension" :width="dimension" />
  </svg>
</template>
<script>
export default {
  computed: {
    img: function () {
      return this.$vuetify.theme.dark
        ? require("@/assets/icons/animals.png")
        : require("@/assets/icons/animalsDark.png");
    },
    dimension: function () {
      return this.$vuetify.theme.dark ? 20 : 25;
    },
  },
};
</script>