<template>
  <div class="dashboard-background h-full">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-text-field
              :placeholder="getTranslation('animalsPages.search')"
              class="mb-0 rounded-lg animal-search"
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model.trim="searchTerm"
            />
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1"> mdi-open-in-new </v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-btn
            @click="toggleFilters"
            class="normal-case px-1 grey--text text--darken-2"
            text
          >
            <template v-if="showFiltersForm">
              {{ getTranslation("animalsPages.closeFilters") }}
              <v-icon class="ml-1" primary="true" small> mdi-close </v-icon>
            </template>
            <template v-else>
              {{ getTranslation("animalsPages.addFilter") }}
              <v-icon class="ml-1" primary="true" small> mdi-plus </v-icon>
            </template>
          </v-btn>
          <v-btn
            @click="resetFilters"
            class="grey--text ml-auto normal-case px-1 text--darken-2"
            text
          >
            {{ getTranslation("animalsPages.reset") }}
          </v-btn>
        </div>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
          v-if="showFiltersForm"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" lg="4">
                <v-form lazy-validation>
                  <v-text-field
                    :label="getTranslation('animalsPages.numberOfAnimals')"
                    autocomplete="off"
                    required
                    type="number"
                    v-model.number="filters.numberOfAnimals"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="filteredGroups"
        class="dashboard-data-table mb-6"
        hide-default-footer
        must-sort
        sort-by="name"
        sort-direction="asc"
        show-select
        v-model="selected"
      >
        <template #top="{ options, pagination, updateOptions }">
          <v-data-footer
            :items-per-page-text="getTranslation('animalsPages.itemsPerPage')"
            :options="options"
            :pagination="pagination"
            @update:options="updateOptions"
            class="ml-auto"
          />
        </template>
        <template v-slot:header.numberOfAnimals="{ header }">
          <div class="d-flex align-center">
            <span>
              {{ header.text }}
            </span>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <td>
            <v-btn
              :disabled="item.name === null"
              @click="openGroupDialog(item, 'delete')"
              class="mr-1 normal-case py-0"
              text
            >
              <v-icon class="red--text">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              :disabled="item.name === null"
              @click="openGroupDialog(item, 'update')"
              class="mr-1 primary-button normal-case py-0 rounded-lg"
              text
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="openGroupDialog(item, 'create')"
              class="mr-1 primary-button normal-case py-0 rounded-lg"
              text
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="font-italic" v-if="item.name === null">
              {{ getTranslation("animalsPages.withoutGroup") }}
            </span>
            <span class="font-weight-medium" v-else>
              {{ item.name }}
            </span>
          </td>
        </template>
      </v-data-table>
    </div>
    <GroupDialog
      :herd-meta="herdMeta"
      :pouches="pouches"
      @saved="fetchData"
      ref="groupDialog"
    />
  </div>
</template>

<script>
import GroupDialog from "../components/dialogs/GroupDialog.vue";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "AnimalsGroups",
  metaInfo: { title: "Animal Groups" },
  components: { GroupDialog },
  mixins: [TranslationMixin],
  data() {
    return {
      filters: { numberOfAnimals: null },
      groups: [],
      loading: true,
      searchTerm: "",
      selected: [],
      showFiltersForm: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          text: this.getTranslation("animalsPages.name"),
          value: "name",
        },
        {
          sortable: true,
          text: this.getTranslation("animalsPages.numberOfAnimals"),
          value: "numberOfAnimals",
        },
      ];
    },
    filteredGroups() {
      return this.groups.filter((group) => {
        let searchTermMatch = true;

        let numberOfAnimalsMatch = true;
        if (this.searchTerm) {
          searchTermMatch = group.name.toLowerCase().includes(this.searchTerm);
        }
        if (
          this.filters.numberOfAnimals !== null &&
          this.filters.numberOfAnimals !== ""
        ) {
          numberOfAnimalsMatch = group.numberOfAnimals
            .toString()
            .includes(this.filters.numberOfAnimals);
        }
        return searchTermMatch && numberOfAnimalsMatch;
      });
    },
  },
  methods: {
    init() {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      this.herdMeta.loaded.done(() => {
        this.pouches = this.herdMeta.pouches;
        this.fetchData();
      });
    },
    async fetchData() {
      this.loading = true;
      let rows = (
        await this.pouches.organization.query("local_views/animalsGroups")
      ).rows
        .map(({ value }) => value)
        .map(({ groupNumber, id, status }) => ({
          groupNumber: groupNumber ? groupNumber.toLowerCase() : null,
          id,
          status: (status || "alive").toLowerCase(),
        }))
        .filter(({ status }) =>
          ["alive", "cull", "marketable", "sick"].some((s) => s === status)
        )
        .map(({ groupNumber, id }) => ({ groupNumber, id }));

      const groups = {};
      let index = 0;
      for (const row of Object.values(rows)) {
        if (groups[row.groupNumber]) {
          groups[row.groupNumber].numberOfAnimals += 1;
          groups[row.groupNumber].animalIds.push(row.id);
        } else {
          groups[row.groupNumber] = {
            animalIds: [row.id],
            id: index,
            name: row.groupNumber,
            numberOfAnimals: 1,
          };
          index++;
        }
      }
      this.groups = Object.values(groups);
      this.loading = false;
    },
    openGroupDialog(groupToEdit, crudOperation) {
      this.$refs.groupDialog.open(
        groupToEdit.animalIds,
        groupToEdit.name,
        crudOperation
      );
    },
    exportToCSV() {
      const headers = this.headers.filter((item) => item.text);
      const fields = headers.map((item) => item.text);
      const data = this.selected.map((herd) =>
        headers.map(({ value }) => herd[value])
      );
      this.herdMeta.exportToCSV(fields, data, "groups.csv");
    },
    toggleFilters() {
      this.showFiltersForm = !this.showFiltersForm;
      if (!this.showFiltersForm) this.resetFilters();
    },
    resetFilters() {
      this.filters.numberOfAnimals = null;
    },
  },
};
</script>
<style scoped>
.animal-search {
  max-width: 300px;
}
>>> .theme--light.animal-search fieldset {
  border-color: rgba(0 0 0 / 0.2);
}
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
