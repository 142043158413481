<template>
  <div>
    <div class="w-full d-none d-md-flex bars-container">
      <v-tooltip top v-for="(item, $index) in dataset" :key="$index">
        <template v-slot:activator="{ on, attrs }">
          <div
            :style="{
              ...getItemStyles(item, $index),
              background:
                (item.style && item.style.background) ||
                getItemStyles(item, $index).background,
              color:
                (item.style && item.style.color) ||
                getItemStyles(item, $index).color,
            }"
            class="item py-2 truncate"
            v-bind="attrs"
            v-on="on"
          >
            <div class="px-2 text-right">
              <span class="truncate font-weight-bold">
                {{ item.value }}
              </span>
              <div class="truncate">{{ item.name }}</div>
            </div>
            <slot name="item-prepend" :item="item" />
          </div>
        </template>
        <div class="text-right tooltip-width">
          <strong>{{ item.value }}</strong> <br />
          {{ item.name }}
        </div>
      </v-tooltip>
    </div>
    <div class="d-block d-md-none">
      <table class="w-full">
        <tbody>
          <tr v-for="(item, $index) in dataset" :key="$index">
            <td class="pt-1 pb-1 pr-4" v-if="item.name">
              <div class="font-weight-bold line-clamp-3">
                {{ item.name }}
              </div>
            </td>
            <td class="pt-1 pb-1 w-full">
              <div
                :class="item.classes"
                :style="{ width: getRelativeWidth(item), ...getColors($index) }"
                class="
                  font-weight-bold
                  pb-3
                  pl-2
                  pr-2
                  pt-3
                  rounded-lg
                  truncate
                  whitespace-nowrap
                "
              >
                {{ item.value }} head
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colorPalette: [
        { background: "rgb(41, 80, 143)", color: "white" },
        { background: "rgb(115, 151, 206)", color: "black" },
        { background: "rgb(206, 203, 199)", color: "black" },
        { background: "rgb(240, 140, 15)", color: "white" },
        { background: "rgb(16, 27, 45)", color: "white" },
      ],
    };
  },
  computed: {
    maxValue() {
      return Math.max(...this.dataset.map((item) => item.value));
    },
    total() {
      return this.dataset.reduce((result, _item) => result + _item.value, 0);
    },
  },
  methods: {
    getRelativeWidth(item) {
      const width = (item.value * 100) / this.maxValue;
      const rounded = Math.round(width * 100) / 100;
      const roundedWidth = rounded < 5 ? "10%" : rounded + "%";
      return roundedWidth;
    },
    getColors(index) {
      const nextIndex = index % this.colorPalette.length;
      return this.colorPalette[nextIndex];
    },
    getItemStyles(item, index) {
      const width = (item.value * 100) / this.total;
      const rounded = Math.round(width * 100) / 100;
      const roundedWidth = rounded < 5 ? "10%" : rounded + "%";
      return {
        width: roundedWidth,
        maxWidth: roundedWidth,
        ...this.getColors(index),
      };
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  white-space: nowrap;
}
.bars-container {
  border-radius: 8px;
  overflow: hidden;
}
.theme--dark .bars-container {
  border: 1px solid rgba(255 255 255 / 0.35);
}
.tooltip-width {
  max-width: 10rem;
}
</style>
