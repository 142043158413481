<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Tags</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Tags_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled"
          @click="deleteTag(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.type="{ item }">
        <v-select
          :disabled="disabled"
          :items="getEnumOptions('tagTypes')"
          @change="
            animal.modify('ids', item.id, 'type', item.type, true, false)
          "
          class="custom-field"
          dense
          hide-details
          item-text="label"
          outlined
          v-if="!item.tagId"
          v-model="item.type"
        >
        </v-select>
        <div v-else>
          {{ getLabelTranslation(`enums.tagTypes.${item.type}`) }}
        </div>
      </template>
      <template #item.tagTable="{ item }">
        <v-select
          :disabled="disabled"
          :items="animalTags"
          @change="
            animal.modify('ids', item.id, 'tagId', item.tagId, true, false)
          "
          class="custom-field"
          dense
          hide-details
          item-text="name"
          item-value="id"
          outlined
          v-if="!item.tagId || !!animal.herdMeta.getMetaTagById(item.tagId)"
          v-model="item.tagId"
        >
        </v-select>
        <div v-else>
          {{ item.tagTable }}
        </div>
      </template>
      <template #item.tagValue="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'ids',
              item.id,
              'tagValue',
              item.tagValue,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-if="statusIsActive(item.status)"
          v-model="item.tagValue"
        >
        </v-text-field>
        <div v-else>
          {{ item.tagValue }}
        </div>
      </template>
      <template #item.location="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'ids',
              item.id,
              'location',
              item.location,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-if="statusIsActive(item.status)"
          v-model="item.location"
        >
        </v-text-field>
        <div v-else>
          {{ item.location }}
        </div>
      </template>
      <template #item.tagColor="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'ids',
              item.id,
              'tagColor',
              item.tagColor,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-if="
            !item.tagId &&
            (item.type != 'association' || item.tagColor) &&
            statusIsActive(item.status)
          "
          v-model="item.tagColor"
        >
        </v-text-field>
        <div v-else>
          {{ item.tagColor }}
        </div>
      </template>
      <template #item.manufacturer="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'ids',
              item.id,
              'manufacturer',
              item.manufacturer,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-if="
            !item.tagId &&
            (item.type != 'association' || item.manufacturer) &&
            statusIsActive(item.status)
          "
          v-model="item.manufacturer"
        >
        </v-text-field>
        <div v-else>
          {{ item.tagColor }}
        </div>
      </template>
      <template #item.size="{ item }">
        <v-text-field
          :disabled="animal.disallowChanges()"
          @change="
            animal.modify('ids', item.id, 'size', item.size, true, false)
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-if="
            !item.tagId &&
            (item.type != 'association' || item.size) &&
            statusIsActive(item.status)
          "
          v-model="item.size"
        >
        </v-text-field>
        <div v-else>
          {{ item.tagColor }}
        </div>
      </template>
      <template #item.status="{ item }">
        <v-switch
          @change="
            animal.modify('ids', item.id, 'status', item.status, true, false)
          "
          false-value="inactive"
          true-value="active"
          class="mt-0 pt-0"
          color="rgb(41, 80, 143)"
          dense
          hide-details
          v-model="item.status"
        />
      </template>
      <template #item.timeRecorded="{ item }">
        <div class="d-flex align-center">
          <div>
            {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
          </div>
          <v-btn
            @click="openDatetimeDialog(item)"
            class="pa-0 ml-1"
            color="#3565A1"
            max-width="32"
            min-width="32"
            small
            v-if="!disabled"
          >
            <v-icon class="white--text">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
      <template #item.lastUpdated="{ item }">
        {{ $utils.renderValueAs(item.lastUpdated, "datetime", true) }}
      </template>
    </v-data-table>
    <div v-if="showExtraInformation">
      <v-spacer style="height: 40px"></v-spacer>
      <p
        v-if="table.data[0] && table.data[0].lastUpdated"
        style="color: #989089"
      >
        Last Updated:
        {{ $utils.renderValueAs(table.data[0].lastUpdated, "datetime", true) }}
      </p>
    </div>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="datetimeDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>
            {{ getTranslation("timeRecorded") }}
          </h4>
          <v-icon class="buttons" @click="datetimeDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0" />
        <v-card-text class="py-2">
          <datetime-component v-model="datetimeDialog.datetime" />
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pb-4 pr-6 d-flex justify-end">
          <v-btn
            @click="datetimeDialog.show = false"
            class="mr-2 rounded-lg normal-case body-1"
            text
          >
            {{ getTranslation("animalsPages.cancel") }}
          </v-btn>
          <v-btn
            :disabled="!datetimeDialog.datetime"
            @click="modifyTimeRecorded"
            class="rounded-lg primary-button normal-case body-1"
          >
            <span class="px-6">
              {{ getTranslation("animalsPages.save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
import DatetimeComponent from "../../components/global/datetimeComponent";
import { EventBus } from "../../mixins/Config";
export default {
  name: "TagHistory",
  mixins: [TranslationMixin],
  components: {
    DatetimeComponent,
  },
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showExtraInformation: {
      default: false,
      type: Boolean,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      datetimeDialog: {
        datetime: null,
        item: null,
        show: false,
      },
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: false,
      },
    };
  },
  computed: {
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
          width: 70,
        },
        {
          text: this.getTranslation("Tag Table"),
          value: "tagTable",
          width: 210,
        },
        {
          text: this.getTranslation("tagValue"),
          value: "tagValue",
        },
        {
          text: this.getTranslation("type"),
          value: "type",
        },
        {
          text: this.getTranslation("New Tag"),
          value: "newTag",
        },
        { text: this.getLabelTranslation("Enable"), value: "status" },
        { text: this.getTranslation("location"), value: "location" },
        { text: this.getTranslation("Tag Color"), value: "tagColor" },
        { text: this.getTranslation("manufacturer"), value: "manufacturer" },
        { text: this.getTranslation("size"), value: "size" },
        {
          text: this.getTranslation("timeRecorded"),
          value: "timeRecorded",
          width: 160,
        },
        {
          text: this.getTranslation("createdOn"),
          value: "createdOn",
          width: 120,
        },
        {
          text: this.getTranslation("Last Updated"),
          value: "lastUpdated",
          width: 120,
        },
      ];
    },
    animalTags: function () {
      return this.animal
        ? [
            { id: "", name: "" },
            ...this.$utils.copyObject(this.animal.herdMeta.tags).map((tag) => {
              tag.name = this.animal.herdMeta.getMetaTagName(tag);
              return tag;
            }),
          ]
        : [];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    EventBus.$on("pouch-changed", ({ docs }) => {
      // docs is an array of docs
      // each doc object contain an "ids" array
      // each object inside "ids array" contain the following props:
      // - createdOn
      // - newTag (some objects do not contain this, assume "false" value)
      // - audit.app (the app where the id has been created, some objects do not contain this
      // so in those cases assume audit.app = 'fast-herd')
      console.log("Event from PouchDB ---> ", docs);
      this.getTagsHistory();
    });
    this.broadcast.onmessage = async (item) => {
      await this.init();
    };
    try {
      this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    EventBus.$off("pouch-changed", () => {});
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      this.table.loading = true;
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      await this.getTagsHistory();
      this.table.loading = false;
    },
    getTagsHistory: function () {
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then((result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          // This is to debug the nestedTables.ids source array
          this.table.data = (summaries.tags || []).map((tag) => {
            tag.status = tag.status ? tag.status.toLowerCase() : "active";
            tag.tagTable = tag.tagId
              ? this.animal.herdMeta.getMetaTagName(
                  this.animal.herdMeta.getMetaTagById(tag.tagId)
                )
              : null;
            // This is necessary because enums are in camelCase
            tag.type = tag.type
              ? tag.type === "EID" || tag.type === "VISUAL"
                ? tag.type.toLowerCase()
                : tag.type[0].toLowerCase() + tag.type.slice(1)
              : null;
            return tag;
          });
        })
        .catch((e) => {
          throw e;
        });
    },
    deleteTag: async function (item) {
      try {
        await this.animal.deleteRecord("ids", item.id);
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
      } catch (error) {
        if (typeof error !== "undefined") {
          console.log(error);
          this.$notify({
            group: "forms",
            text: "An error occurred",
            title: "Error",
            type: "error",
          });
        }
      }
    },
    modifyTimeRecorded: function () {
      const item = this.datetimeDialog.item;
      const dt = this.datetimeDialog.datetime;
      const timeRecorded = this.$moment(dt).format("YYYY-MM-DDTHH:mm");

      this.animal.modify(
        "ids",
        item.id,
        "timeRecorded",
        timeRecorded,
        true,
        false
      );
      this.datetimeDialog.show = false;
    },
    openDatetimeDialog: function (item) {
      const datetime = Object.assign({}, this.$options.data().datetimeDialog);
      datetime.datetime = this.$moment(
        this.$moment(item.timeRecorded).toISOString()
      ).format("YYYY-MM-DD HH:mm A");
      datetime.item = item;
      Object.assign(this.datetimeDialog, datetime);
      this.datetimeDialog.show = true;
    },
    statusIsActive: function (status) {
      return status === "active";
    },
  },
};
</script>
<style scoped>
>>> tbody > tr > td:nth-child(3) {
  min-width: 180px;
}

>>> tbody
  > tr
  > td:nth-child(3)
  > .v-input
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  width: fit-content;
}

.status-container {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-width: 78px;
  padding: 5px;
}
.active-status {
  background-color: #dcfce7;
}

.theme--dark >>> .active-status {
  background-color: #dcfce773;
}
.theme--dark >>> .inactive-status {
  background-color: #fee2e26c;
}
.inactive-status {
  background-color: #fee2e2;
}
.dark-inactive-status {
  background-color: #fee2e2;
}
</style>
