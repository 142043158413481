<template>
  <div>
    <v-list-item
      @click="showDevToolsDialog = true"
      @mouseleave="resetTooltip"
      class="px-0"
      v-if="showMiniVariantVersion"
    >
      <v-list-item-icon>
        <v-chip class="pointer-events-none" :color="variant" outlined small>
          {{ env }}
        </v-chip>
      </v-list-item-icon>
      <v-list-item-content />
    </v-list-item>
    <div v-else>
      <div class="d-none d-md-flex">
        <dev-tools-content
          :env="env"
          :tooltipText="tooltipText"
          :variant="variant"
          @copy="copy"
          @mouseleave-from-icon="resetTooltip"
        />
      </div>
      <div class="d-block d-md-none">
        <v-list-item @click="showDevToolsDialog = true" dense>
          <v-list-item-icon>
            <v-chip :color="variant" outlined small>
              {{ env }}
            </v-chip>
          </v-list-item-icon>
          <v-list-item-content>
            <strong> Dev tools </strong>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <v-dialog v-model="showDevToolsDialog" max-width="400">
      <v-card>
        <v-card-title>Dev tools</v-card-title>
        <v-card-text>
          <dev-tools-content
            :env="env"
            :tooltipText="tooltipText"
            :variant="variant"
            @copy="copy"
            @mouseleave-from-icon="resetTooltip"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDevToolsDialog = false" text> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { IsSuperAdmin } from "../utils/functions/auth";
import { mapState, mapGetters } from "vuex";
import DevToolsContent from "./utils/DevToolsContent.vue";

export default {
  name: "DevTools",
  components: { DevToolsContent },
  data() {
    return {
      tooltipText: "Copy as JSON",
      showDevToolsDialog: false,
    };
  },
  computed: {
    ...mapState("User", ["drawerMiniVariant"]),
    ...mapGetters({
      getName: "User/getName",
      getOrganizationID: "Organization/getOrganizationID",
      getOrganizationName: "Organization/getName",
      getUserID: "User/getUserID",
      getUsername: "User/getUsername",
    }),
    variant() {
      switch (this.env) {
        case "prod":
          return "warning";
        case "beta":
          return "info";
        default:
          return "success";
      }
    },
    env() {
      if (["fast-herd.com", "www.fast-herd.com"].includes(location.host)) {
        return IsSuperAdmin() ? "prod" : null;
      }
      if (["fastherd-dev.com", "www.fastherd-dev.com"].includes(location.host))
        return "beta";
      return "dev";
    },
    showMiniVariantVersion() {
      const isNotMobile = !["xs", "sm"].includes(this.$vuetify.breakpoint.name);
      return isNotMobile && this.drawerMiniVariant;
    },
  },
  methods: {
    async copy() {
      const content = JSON.stringify(
        {
          datetime: new Date().toISOString(),
          environment: this.env,
          uri: this.$route.fullPath,
          organizationId: this.getOrganizationID,
          organizationName: this.getOrganizationName,
          userId: this.getUserID,
          userName: this.getName,
          email: this.getUsername,
        },
        null,
        2
      );
      await navigator.clipboard.writeText(content);
      this.tooltipText = "Copied!";
    },
    resetTooltip() {
      setTimeout(() => (this.tooltipText = "Copy as JSON"), 200);
    },
  },
};
</script>
