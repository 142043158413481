<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <!-- Controls -->
        <div class="mb-2 d-flex">
          <!-- Toggle columns -->
          <v-menu offset-y :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <v-btn color="primary" depressed text v-bind="attrs" v-on="on">
                Toggle
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list class="overflow-y-auto" style="max-height: 400px">
              <v-list-item
                :key="idx"
                style="max-height: 40px"
                v-for="(header, idx) in summaryTableHeaders"
              >
                <v-checkbox
                  :disabled="header.disabled"
                  @change="updateTableHeaders"
                  dense
                  v-model="header.visible"
                >
                  <template #label>
                    <div class="mt-2">
                      {{ header.text }}
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- New -->
          <v-btn @click="showInsertDialog" color="primary" depressed text>
            New
          </v-btn>
          <!-- Edit -->
          <v-btn
            :disabled="!this.$superAdmin || summaryTable.selected.length != 1"
            @click="showEditDialog"
            color="primary"
            depressed
            text
          >
            Edit
          </v-btn>
          <!-- Delete -->
          <v-btn
            :disabled="!summaryTable.selected.length"
            @click="deleteDialog.show = true"
            color="error"
            depressed
            text
          >
            Delete
          </v-btn>
          <!-- Export -->
          <export-button
            :disabled="!summaryTable.selected.length"
            :headers="summaryTableHeaders.filter(({ disabled }) => !disabled)"
            :items="summaryTable.selected"
            allowIncludeID
            filename="organizations"
          />
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            clearable
            dense
            hide-details
            label="Search"
            outlined
            style="max-width: 250px"
            v-model="summaryTable.search"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="summaryTable.headers"
          :items="summaryTable.data"
          :loading="summaryTable.loading"
          :search="summaryTable.search"
          class="d-flex flex-column elevation-1"
          id="organizations"
          show-select
          v-model="summaryTable.selected"
        >
          <template #item.lastAnimalEditTime="{ item }">
            <div>
              {{
                $utils.renderValueAs(item.lastAnimalEditTime, "datetime", false)
              }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Insert Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="insertDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>New</h4>
          <v-icon class="buttons" @click="insertDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-text-field
            :error="$utils.isWhitespace(insertDialog.fields.organizationName)"
            :label="getTranslation('Organization Name')"
            class="mb-2"
            clearable
            dense
            hide-details
            outlined
            v-model="insertDialog.fields.organizationName"
          ></v-text-field>
          <v-select
            :items="billingOrganizations"
            :label="getTranslation('Billing Organization')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-2"
            clearable
            dense
            hide-details
            item-text="name"
            outlined
            return-object
            v-model="insertDialog.fields.billingOrganization"
          ></v-select>
          <v-select
            :items="billingRepresentatives"
            :label="getTranslation('Billing Representative')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-2"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            v-model="insertDialog.fields.billingRepresentative"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="
              $utils.isWhitespace(insertDialog.fields.organizationName)
            "
            @click="createRecord"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="editDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Edit</h4>
          <v-icon class="buttons" @click="editDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <v-text-field
            :error="$utils.isWhitespace(editDialog.fields.organizationName)"
            :label="getTranslation('Organization Name')"
            class="mb-2"
            clearable
            dense
            hide-details
            outlined
            v-model="editDialog.fields.organizationName"
          ></v-text-field>
          <v-select
            :items="billingOrganizations"
            :label="getTranslation('Billing Organization')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-2"
            clearable
            dense
            hide-details
            item-text="name"
            outlined
            return-object
            v-model="editDialog.fields.billingOrganization"
          ></v-select>
          <v-select
            :items="billingRepresentatives"
            :label="getTranslation('Billing Representative')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-2"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            v-model="editDialog.fields.billingRepresentative"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="$utils.isWhitespace(editDialog.fields.organizationName)"
            @click="updateRecords"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Dialog -->
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="deleteDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Delete</h4>
          <v-icon class="buttons" @click="deleteDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          Are you sure you want to delete
          {{ summaryTable.selected.length }} records?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="deleteRecords" color="error"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
import { mapGetters } from "vuex";
export default {
  name: "Organizations",
  metaInfo: {
    title: "Organizations",
  },
  mixins: [TranslationMixin],
  data() {
    return {
      billingOrganizations: [],
      billingRepresentatives: [],
      deleteDialog: {
        show: false,
      },
      editDialog: {
        fields: {
          billingOrganization: null,
          billingRepresentative: null,
          organizationName: null,
        },
        show: false,
      },
      insertDialog: {
        fields: {
          billingOrganization: null,
          billingRepresentative: null,
          organizationName: null,
        },
        show: false,
      },
      summaryTable: {
        data: [],
        headers: [],
        loading: false,
        search: null,
        selected: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserToken: "User/getToken",
    }),
    summaryTableHeaders: function () {
      return [
        {
          disabled: !+this.$superAdmin,
          text: this.getTranslation("Organization Guid"),
          value: "id",
          visible: false,
        },
        {
          disabled: !+this.$superAdmin,
          text: this.getTranslation("Billing Organization Guid"),
          value: "billingOrganizationId",
          visible: false,
        },
        {
          disabled: false,
          text: this.getTranslation("Organization Name"),
          value: "name",
          visible: true,
        },
        {
          disabled: false,
          text: this.getTranslation("Billing Organization"),
          value: "billingOrganizationName",
          visible: true,
        },
        {
          disabled: false,
          text: this.getTranslation("Billing Representative"),
          value: "billingRepresentative",
          visible: true,
        },
        {
          disabled: false,
          text: this.getTranslation("Animal Count"),
          value: "animalCount",
          visible: true,
        },
        {
          disabled: false,
          text: this.getTranslation("lastAnimalEditTime"),
          value: "lastAnimalEditTime",
          visible: true,
        },
      ];
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: async function () {
      this.summaryTable.loading = true;
      this.summaryTable.data = await this.getSummaryData();
      console.log(this.summaryTable.data);
      this.billingOrganizations = this.summaryTable.data
        .map(({ billingOrganizationId, billingOrganizationName }) => ({
          id: billingOrganizationId,
          name: billingOrganizationName,
        }))
        .filter(({ id, name }) => id && id.length && name && name.length)
        .sort((a, b) => a.name.localeCompare(b.name));
      this.billingRepresentatives = this.summaryTable.data
        .map(({ billingPersonId, billingRepresentative }) => ({
          id: billingPersonId,
          name: billingRepresentative,
        }))
        .filter(({ id, name }) => id && id.length && name && name.length)
        .sort((a, b) => a.name.localeCompare(b.name));
      this.summaryTable.loading = false;
      this.updateTableHeaders();
    },
    createRecord: function () {
      const data = {
        billingOrganizationId:
          this.insertDialog.fields.billingOrganization &&
          this.insertDialog.fields.billingOrganization.id,
        billingPersonId: this.insertDialog.fields.billingRepresentative,
        id: this.$utils.guid(),
        name: this.insertDialog.fields.organizationName,
      };
      const form = new URLSearchParams();
      form.append("table", "organizations");
      form.append("data", JSON.stringify(data));
      axios
        .post(
          "/api/data/createRecord",
          { table: "organizations", data },
          {
            params: {
              token: this.$token,
            },
          }
        )
        .then(async ({ data: returnData }) => {
          if (returnData) {
            const uoData = {};
            uoData.id = this.$utils.guid();
            uoData.userId = this.$userID;
            uoData.organizationId = data.id;
            uoData.admin = 1;
            const userData = new URLSearchParams();
            userData.append("table", "userOrganizations");
            userData.append("data", JSON.stringify(uoData));
            try {
              await axios.post(
                "/api/data/createRecord",
                { table: "userOrganizations", data: uoData },
                {
                  params: { token: this.$token },
                }
              );
              this.summaryTable.data = await this.getSummaryData();
              this.insertDialog.show = false;
              await axios.get("/herd-api/data/updateSession.cfm");
            } catch (e) {
              console.log("Error adding user organization data");
              throw e;
            }
          }
        })
        .catch((e) => {
          // this.$notify({
          //   group: "forms",
          //   text: "An error ocurred",
          //   title: "Error",
          //   type: "error",
          // });
          console.error(e.message);
        });
    },
    deleteRecords: function () {
      const promises = this.summaryTable.selected.map((item) => {
        const data = { id: item.id };
        const form = new URLSearchParams();
        form.append("table", "organizations");
        form.append("data", JSON.stringify(data));
        return axios
          .post("/herd-api/data/deleteRelatedRecords.cfm", form)
          .then(() => {
            return axios.post("/herd-api/data/deleteRecord.cfm", form);
          })
          .catch((e) => {
            throw e;
          });
      });

      Promise.all(promises)
        .then(async () => {
          this.deleteDialog.show = false;
          this.summaryTable.data = await this.getSummaryData();
          this.summaryTable.selected = [];
        })
        .catch((e) => {
          // this.$notify({
          //   group: "forms",
          //   text: "An error ocurred",
          //   title: "Error",
          //   type: "error",
          // });
          console.error(e);
        });
    },
    getSummaryData: async function () {
      try {
        const { data } = await axios.get("/api/users/self/organizations", {
          params: {
            token: this.getUserToken,
          },
        });
        return data.organizations.map((item) => ({
          animalCount: item.animal_count,
          billingRepresentative:
            item.firstName &&
            item.lastName &&
            item.firstName + " " + item.lastName,
          ...item,
        }));
      } catch (e) {
        this.$notify({
          group: "forms",
          text: "An error occurred trying to get organizations.",
          title: "Error",
          type: "error",
        });
        console.error(e);
      }
    },
    updateTableHeaders: function () {
      const headers = this.$utils.copyObject(this.summaryTableHeaders);
      this.summaryTable.headers = headers.filter(({ visible }) => !!visible);
    },
    showInsertDialog: function () {
      this.insertDialog.show = true;
      this.insertDialog.fields.billingOrganization = null;
      this.insertDialog.fields.billingRepresentative = null;
      this.insertDialog.fields.organizationName = null;
    },
    showEditDialog: function () {
      this.editDialog.show = true;
      if (this.summaryTable.selected.length == 1) {
        const item = this.summaryTable.selected[0];
        this.editDialog.fields.billingOrganization = {
          id: item.billingOrganizationId,
          name: item.billingOrganizationName,
        };
        this.editDialog.fields.billingRepresentative = item.billingPersonId;
        this.editDialog.fields.organizationName = item.name;
      } else {
        this.editDialog.fields.billingOrganization = null;
        this.editDialog.fields.billingRepresentative = null;
        this.editDialog.fields.organizationName = null;
      }
    },
    updateRecords: function () {
      const promises = this.summaryTable.selected.map((item) => {
        const data = {};
        data.id = item.id;
        data.name = this.editDialog.fields.organizationName;
        data.billingOrganizationId =
          this.editDialog.fields.billingOrganization &&
          this.editDialog.fields.billingOrganization.id;
        data.billingPersonId = this.editDialog.fields.billingRepresentative;
        const form = new URLSearchParams();
        form.append("table", "organizations");
        form.append("data", JSON.stringify(data));
        return axios.post(
          "/api/data/updateRecord",
          { table: "organizations", data },
          {
            params: {
              token: this.$token,
            },
          }
        );
      });

      Promise.all(promises)
        .then(async () => {
          this.summaryTable.data = await this.getSummaryData();
          this.summaryTable.selected = [];
          this.editDialog.show = false;
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            text: "An error occurred",
            title: "Error",
            type: "error",
          });
          console.error(e);
        });
    },
  },
};
</script>
<style>
#organizations .v-data-footer {
  order: 2;
}

#organizations:first-child {
  order: 1;
}

#organizations .v-data-table__wrapper {
  order: 3;
}
</style>
