<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 27 28" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M21.25 12.2278C16.9702 7.948 10.0313 7.948 5.75154 12.2278C5.36247 12.6169 4.73166 12.6169 4.34259 12.2278C3.95351 11.8387 3.95351 11.2079 4.34259 10.8188C9.40052 5.76091 17.601 5.76091 22.659 10.8188C23.048 11.2079 23.048 11.8387 22.659 12.2278C22.2699 12.6169 21.6391 12.6169 21.25 12.2278ZM18.4321 15.0457C15.7086 12.3222 11.2929 12.3222 8.56945 15.0457C8.18037 15.4348 7.54956 15.4348 7.16049 15.0457C6.77142 14.6566 6.77142 14.0258 7.16049 13.6368C10.6621 10.1351 16.3394 10.1351 19.8411 13.6368C20.2301 14.0258 20.2301 14.6566 19.8411 15.0457C19.452 15.4348 18.8212 15.4348 18.4321 15.0457ZM15.6142 17.8636C14.447 16.6964 12.5546 16.6964 11.3874 17.8636C10.9983 18.2527 10.3675 18.2527 9.9784 17.8636C9.58933 17.4745 9.58933 16.8437 9.9784 16.4547C11.9238 14.5093 15.0778 14.5093 17.0232 16.4547C17.4122 16.8437 17.4122 17.4745 17.0232 17.8636C16.6341 18.2527 16.0033 18.2527 15.6142 17.8636ZM12.5045 19.9771C12.5045 19.4268 12.9506 18.9808 13.5008 18.9808H13.5107C14.061 18.9808 14.507 19.4268 14.507 19.9771C14.507 20.5273 14.061 20.9733 13.5107 20.9733H13.5008C12.9506 20.9733 12.5045 20.5273 12.5045 19.9771Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
