<template>
  <div class="pa-4">
    <div class="summary-container mb-3 ml-n1 mr-n1 pa-1">
      <total-head-widget :syncTime="sync.syncTime" v-bind="staticProps" />
      <births-widget :syncTime="sync.syncTime" v-bind="staticProps" />
      <marketable-animals-widget
        v-bind="staticProps"
        :syncTime="sync.syncTime"
      />
    </div>
    <v-row>
      <v-col cols="12" md="12">
        <animals-widget :syncTime="sync.syncTime" v-bind="staticProps" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <diversity-widget :syncTime="sync.syncTime" v-bind="staticProps" />
      </v-col>
      <v-col cols="12" md="8">
        <progress-of-work-widget
          v-bind="staticProps"
          :syncTime="sync.syncTime"
        />
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { EventBus } from "../../mixins/Config";
import AnimalsWidget from "./AnimalsWidget.vue";
import BirthsWidget from "./BirthsWidget.vue";
import DiversityWidget from "./DiversityWidget.vue";
import MarketableAnimalsWidget from "./MarketableAnimalsWidget.vue";
import ProgressOfWorkWidget from "./ProgressOfWorkWidget.vue";
import TotalHeadWidget from "./TotalHeadWidget.vue";

export default {
  name: "DashboardProducer",
  components: {
    AnimalsWidget,
    BirthsWidget,
    DiversityWidget,
    MarketableAnimalsWidget,
    ProgressOfWorkWidget,
    TotalHeadWidget,
  },
  data: () => ({
    sync: {
      syncTime: new Date().toISOString(),
    },
  }),
  props: ["herdMeta", "pouches", "syncTime"],
  beforeDestroy: function () {
    EventBus.$off("pouch-paused");
  },
  created: function () {
    EventBus.$on("pouch-paused", () => {
      this.sync.syncTime = new Date().toISOString();
    });
  },
  computed: {
    staticProps() {
      return {
        "herd-meta": this.herdMeta,
        pouches: this.pouches,
      };
    },
  },
};
</script>


<style scoped>
.summary-container {
  column-gap: 2rem;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
}
@media only screen and (max-width: 960px) {
  .summary-container {
    column-gap: 1rem;
  }
}
</style>
