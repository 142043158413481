<template>
  <div>
    <v-dialog
      max-width="350px"
      :persistent="persistDialog"
      scrollable
      transition="dialog-transition"
      v-model="multipleResults.showDialog"
    >
      <v-card>
        <v-card-title class="px-6 pb-0 d-flex justify-space-between">
          <h4 class="ma-0">Multiple Results</h4>

          <v-icon
            v-if="!persistDialog"
            @click="multipleResults.showDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text style="height: 120px" class="py-2 px-6">
          <v-select
            :items="multipleResults.options"
            :menu-props="{ offsetY: true }"
            clearable
            hide-details
            item-text="tagValues"
            label="Results"
            outlined
            return-object
            style="font-size: 1.3rem"
            v-model="multipleResults.resultSelected"
          >
          </v-select>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="persistDialog"
            @click="multipleResults.showDialog = false"
            color="primary"
            >Select</v-btn
          >

          <v-btn
            @click="forceUseNewAnimal"
            :disabled="!persistDialog"
            color="warning"
            >New Animal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <calf-container
      :headerTitle="getTranslation('calfWeanPage.form.name')"
      style="min-height: 400px"
    >
      <template #prepend-header>
        <div>
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="showCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :background-color="
                  dateWarning.danger
                    ? 'error'
                    : dateWarning.warning
                    ? 'warning'
                    : ''
                "
                append-icon="mdi-calendar"
                dense
                hide-details
                outlined
                readonly
                style="font-size: 1.3rem"
                v-bind="attrs"
                v-model="form.date"
                v-on="on"
              >
                <template #label>
                  <p
                    :class="{ darkInputText: $vuetify.theme.dark }"
                    class="ma-0 biggerFont"
                    style="font-size: 1.7rem"
                  >
                    {{ getTranslation("calfWeanPage.form.date") }}
                  </p>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :max="maxDateAllowed"
              :min="minDateAllowed"
              @input="showCalendar = false"
              v-model="form.date"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
      <template #append-header>
        <v-form v-model="validGroup">
          <div>
            <v-text-field
              :error="formErrorState.group"
              :rules="[rules.required]"
              class="mediumFont"
              clearable
              dense
              hide-details
              outlined
              v-model="form.group"
            >
              <template #label>
                <p class="ma-0 biggerFont" style="font-size: 1.7rem">
                  {{ getTranslation("calfWeanPage.form.group") }}
                </p>
              </template>
            </v-text-field>
          </div>
        </v-form>
      </template>
      <template #content>
        <v-form>
          <v-row class="d-flex flex-wrap">
            <v-col class="pb-0 pt-lg-0 pt-1" cols="12" lg="7">
              <v-autocomplete
                :append-icon="
                  herdConnect.results.eid === search.eid
                    ? 'mdi-cellphone-link'
                    : ''
                "
                :input="runSearch"
                :items="options.eids"
                :rules="[rules.EID]"
                auto-select-first
                class="mediumFont"
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search.eid"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("calfWeanPage.form.findByEID") }}
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" lg="5">
              <v-autocomplete
                :items="options.visuals"
                :input="runSearch"
                auto-select-first
                class="mediumFont"
                clearable
                hide-details
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search.visual"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("calfWeanPage.form.findByTag") }}
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pb-0 pt-lg-0 pt-1 title" cols="12" lg="6">
              <strong style="font-size: 1.9rem">Confirm</strong>
              <span
                class="ml-2"
                style="font-size: 1.9rem"
                v-if="search.eid != null && isValidEID(search.eid)"
              >
                {{ search.eid.substr(11) }}
              </span>
            </v-col>
            <v-col class="pb-0 pt-lg-0 pt-1 title" cols="2" lg="1">
              <v-btn
                :disabled="multipleResults.options.length <= 1"
                @click="
                  displayOptionsIfAvailable(multipleResults.options, false)
                "
                class="px-1 title"
                color="primary"
                elevation="3"
                style="height: 30px; min-width: 50px"
              >
                {{ multipleResults.options.length }}

                <v-icon color="white">mdi-magnify-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-form v-model="validFields">
          <v-row class="d-flex flex-wrap">
            <v-col cols="6" class="pb-0 pr-4 pl-0">
              <v-row>
                <v-col class="pr-0" cols="12" lg="10">
                  <v-text-field
                    :append-icon="
                      herdConnect.results.eid === form.eid
                        ? 'mdi-cellphone-link'
                        : ''
                    "
                    :background-color="
                      form.eid && options.eidToAnimalShortSummaries[form.eid]
                        ? 'orange'
                        : ''
                    "
                    :error="formErrorState.eid"
                    class="mediumFont"
                    clearable
                    hide-details
                    outlined
                    v-model="form.eid"
                  >
                    <template #label>
                      <p class="ma-0 biggerFont">
                        {{ getTranslation("New EID") }}
                      </p>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  class="pt-0 px-0 d-flex align-center"
                  cols="2"
                  v-if="form.eid != null && isValidEID(form.eid)"
                >
                  <span class="ml-1" style="font-size: 1.9rem">
                    {{ form.eid.substr(11) }}
                  </span>
                </v-col>
              </v-row>
              <v-text-field
                :error="formErrorState.visual"
                class="mediumFont mb-4 mt-8 mt-lg-4"
                clearable
                hide-details
                outlined
                v-model="form.visual"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("New Visual") }}
                  </p>
                </template>
              </v-text-field>
              <v-text-field
                :append-icon="
                  herdConnect.results.weight != null &&
                  herdConnect.results.weight == form.weanWeight
                    ? 'mdi-cellphone-link'
                    : ''
                "
                :error="!!formErrorState.weanWeight"
                :rules="[rules.decimal, rules.required]"
                class="mediumFont mb-4"
                clearable
                hide-details
                outlined
                type="decim"
                v-model="form.weanWeight"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("calfWeanPage.form.weanWeight") }}
                  </p>
                </template>
              </v-text-field>
              <v-select
                :background-color="options.origins.length == 0 ? 'warning' : ''"
                :disabled="options.origins.length == 0"
                :items="options.origins"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                class="mediumFont mb-4"
                clearable
                hide-details
                item-text="origin"
                outlined
                return-object
                v-model="form.origin"
              >
                <template #label>
                  <span v-if="options.origins.length == 0" style="color: black">
                    Wean origins are not defined.
                  </span>
                  <p class="ma-0 biggerFont" v-else>
                    {{ getTranslation("calfWeanPage.form.origin") }}
                  </p>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" class="pb-0 pl-4 pr-2">
              <v-text-field
                class="mediumFont mb-4"
                clearable
                hide-details
                outlined
                v-model="form.tsu"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("calfWeanPage.form.tsu") }}
                  </p>
                </template>
              </v-text-field>
              <span
                class="ma-0 mb-3 btn btn-danger"
                v-if="options.sexes.length == 0"
              >
                Error: Wean sexes must be configured.
              </span>
              <v-radio-group
                :error="formErrorState.sex"
                class="ma-0 mb-1"
                hide-details
                row
                v-model="form.sex"
              >
                <template #label>
                  <p class="ma-0 biggerFont">
                    {{ getTranslation("calfWeanPage.form.sex") }}
                  </p>
                </template>
                <v-radio
                  :key="sex.id"
                  :value="sex"
                  v-for="sex in options.sexes"
                >
                  <template #label>
                    <p class="ma-0 mt-2 biggerFont">{{ sex.sex }}</p>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-row>
                <v-col>
                  <v-radio-group
                    class="ma-0"
                    hide-details
                    v-model="form.status"
                  >
                    <template #label>
                      <p class="ma-0 biggerFont">
                        {{ getTranslation("status") }}
                      </p>
                    </template>
                    <v-radio value="marketable">
                      <template #label>
                        <p class="ma-0 mt-2 biggerFont">
                          {{ getTranslation("marketable") }}
                        </p>
                      </template>
                    </v-radio>
                    <v-radio value="alive">
                      <template #label>
                        <p class="ma-0 mt-2 biggerFont">
                          {{ getTranslation("alive") }}
                        </p>
                      </template>
                    </v-radio>
                    <v-radio value="cull">
                      <template #label>
                        <p class="ma-0 mt-2 biggerFont">
                          {{ getTranslation("Cull") }}
                        </p>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <div class="col">
              <!-- Treatments Field -->
              <template>
                <v-row
                  :key="index"
                  v-for="(treatment, index) in form.treatments"
                >
                  <v-col class="col-8 pt-0 px-0">
                    <v-select
                      :background-color="
                        options.vaccinations.length == 0 ? 'warning' : ''
                      "
                      :disabled="options.vaccinations.length == 0"
                      :error="treatment.validation.errorVaccination"
                      :items="options.vaccinations"
                      :menu-props="{ offsetY: true, closeOnClick: true }"
                      class="mediumFont"
                      clearable
                      hide-details
                      item-text="name"
                      return-object
                      outlined
                      v-model="treatment.vaccination"
                    >
                      <template #label>
                        <span
                          v-if="options.vaccinations.length == 0"
                          style="color: black"
                        >
                          Wean medications are not defined.
                        </span>
                        <p class="ma-0 biggerFont" v-else>
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.vaccination"
                            )
                          }}
                        </p>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pt-0 px-0"
                    v-if="options.vaccinations.length > 0"
                  >
                    <v-text-field
                      :error="treatment.validation.errorDose"
                      :rules="[rules.decimal]"
                      class="mediumFont"
                      clearable
                      hide-details
                      outlined
                      v-model="treatment.dose"
                    >
                      <template #label>
                        <p class="ma-0 biggerFont">
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.dose"
                            )
                          }}
                        </p>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pt-0 px-0"
                    cols="1"
                    style="position: relative; z-index: 10"
                  >
                    <div v-if="index == 0">
                      <v-btn
                        :disabled="options.vaccinations.length == 0"
                        @click="addTreatment()"
                        block
                        color="success"
                        height="56"
                        small
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn
                        @click="removeTreatment(index)"
                        block
                        color="error"
                        height="56"
                        small
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </template>
              <v-row class="mt-4 mb-2">
                <v-textarea
                  auto-grow
                  class="mediumFont"
                  clearable
                  counter
                  dense
                  hide-details
                  maxlength="200"
                  outlined
                  rows="3"
                  v-model="form.comment"
                >
                  <template #label>
                    <p class="ma-0 biggerFont">
                      {{ getTranslation("calfWeanPage.form.comments") }}
                    </p>
                  </template>
                </v-textarea>
              </v-row>
            </div>
          </v-row>
        </v-form>
      </template>
      <template #footer>
        <v-row class="px-5">
          <v-col class="pt-0">
            <v-btn
              :disabled="!validForm"
              @click="saveAnimal"
              block
              color="success"
              large
            >
              <template v-if="!animalIsNew">
                {{ getLabelTranslation("save") }}
              </template>
              <template v-else>
                {{ getTranslation("Save New") }}
              </template>
            </v-btn>
          </v-col>
          <v-col class="pt-0" style="max-width: 250px">
            <v-btn @click="resetFields" block color="primary" large outlined>
              {{ getTranslation("calfWeanPage.form.reset") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mx-5"></v-divider>
        <herd-connect
          :streamLF="streamEID"
          :streamUHF="streamEID"
          :streamTSU="streamTSU"
          :streamVisual="streamVisual"
          :streamWeight="streamWeight"
          class="mx-5"
        />
      </template>
    </calf-container>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import { mapActions, mapGetters } from "vuex";
import Container from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "calf-wean-form",
  mixins: [TranslationMixin],
  components: {
    "calf-container": Container,
  },
  data: () => ({
    form: {
      comment: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      eid: null,
      group: null,
      origin: null,
      sex: null,
      status: "marketable",
      treatments: [], // This will be set from the cache or from the default value
      tsu: null,
      visual: null,
      weanWeight: null,
    },
    herdConnect: {
      resetFields: false,
      results: {
        eid: null,
        tsu: null,
        visual: null,
        weight: null,
      },
    },
    herdMeta: null,
    multipleResults: {
      options: [],
      resultSelected: null,
      showDialog: false,
    },
    options: {
      eids: [],
      eidToAnimalShortSummaries: {},
      origins: [],
      sexes: [],
      vaccinations: [],
      visuals: [],
      visualToAnimalShortSummaries: {},
    },
    rules: {
      decimal: (number) => {
        if (number == null || number == "") return true;
        // Decimal number with point or just number
        return /^\d+$|^\d+\.\d+$/gm.test(number);
      },
      EID: (value) =>
        // convert null to string and numbers to string and strings to string
        /^$|^\d{15}$/.test(
          (value == null ? "" : value + "").replace(/-/g, "").replace(/ /g, "")
        ) || "Invalid ID",
      required: (value) => !!value || "Required",
    },
    search: {
      eid: "",
      visual: "",
    },
    searchMeta: {
      active: false,
      animal: null,
      animalIsNew: true,
    },
    showCalendar: false,
    title: "calf-wean-entry",
    validFields: false,
    validGroup: false,
  }),
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("new-search");
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
      getOrganizationName: "Organization/getName",
    }),
    animalIsNew: function () {
      return this.searchMeta.animalIsNew;
    },
    dateWarning: function () {
      const date = moment(this.form.date).startOf("day");
      const isFuture = date.diff(moment().startOf("day"), "days") > 0;
      const isToday = date.diff(moment().startOf("day"), "days") == 0;
      const oneOrMoreMonthsBefore =
        date.diff(moment().startOf("day"), "months") < 0 ? true : false;
      return {
        danger: isFuture || oneOrMoreMonthsBefore,
        warning: !isToday,
      };
    },
    maxDateAllowed: function () {
      return moment().add(4, "weeks").toISOString();
    },
    minDateAllowed: function () {
      return moment().subtract(4, "weeks").toISOString();
    },
    formErrorState: function () {
      const eidEmpty = this.form.eid == null || this.form.eid.trim() == "";
      const visualEmpty =
        this.form.visual == null || this.form.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.eid);

      // NOTE: Add more fields with similar logic if you need it
      // Error state does not expect an error message instead only expects a boolean value
      return {
        cull: this.form.status != undefined ? null : "Cull is required.",
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? true
            : false,
        group: this.form.group == null || this.form.group == "",
        sex:
          this.options.sexes.length === 0 ||
          this.form.sex == null ||
          this.form.sex == "",
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? true
            : false,
        weanWeight:
          this.form.weanWeight == null ||
          !this.rules.decimal(this.form.weanWeight)
            ? "Wean weight must be empty or numeric"
            : null,
      };
    },
    persistDialog() {
      return this.multipleResults.resultSelected === null;
    },
    runSearch: function () {
      this.searchMeta.active = true;

      return this.searchAnimal(this.search.eid, this.search.visual)
        .then(() => {
          this.searchMeta.active = false;
        })
        .catch(() => {
          this.searchMeta.active = false;
        });
    },
    validForm: function () {
      return this.validFields && this.validGroup;
    },
  },
  watch: {
    "form.group": {
      handler: function (group) {
        EventBus.$emit("set-current-group-number", group);
      },
    },
    "form.treatments": {
      deep: true,
      handler: function (arr) {
        arr.forEach((treatment, index) => {
          if (
            treatment.vaccination != null &&
            treatment.vaccination != "" &&
            (treatment.dose == null || treatment.dose == "")
          )
            this.form.treatments[index].validation.errorDose = true;
          else if (
            treatment.dose != null &&
            treatment.dose != "" &&
            (treatment.vaccination == null || treatment.vaccination == "")
          )
            this.form.treatments[index].validation.errorVaccination = true;
          else {
            this.form.treatments[index].validation.errorVaccination = false;
            this.form.treatments[index].validation.errorDose = false;
          }
        });
      },
    },
    "multipleResults.options": function (results) {
      this.searchMeta.animal = this.multipleResults.resultSelected = null;
      this.displayOptionsIfAvailable(results, true);
    },
    "multipleResults.resultSelected": async function (result) {
      this.searchMeta.animal = new Animal(
        result.doc._id,
        this.herdMeta,
        result.doc,
        this.$userID
      );
    },
    "searchMeta.animal": async function (animal) {
      if (animal) {
        EventBus.$emit("new-search", animal.guid);
      }

      this.searchMeta.animalIsNew = !animal || animal.animalIsNew;
      if (this.animalIsNew) {
        this.resetCalfInformation();
        return;
      }
      if (animal) {
        const weanDates = (animal.doc.weights || []).filter(
          (weight) => !weight.deleted && weight.source === "wean"
        );
        const dam = await this.getParentInformation(
          animal.doc.derived.summaries.main.damId
        );
        const sire = await this.getParentInformation(
          animal.doc.derived.summaries.main.sireId
        );
        this.setCalfInformation({
          birthDate: animal.doc.derived.summaries.main.birthDate,
          birthRanch: animal.derived.summaries.main.receivingRanch,
          birthVisual: animal.doc.derived.summaries.main.birthVisual,
          birthWeight: animal.doc.derived.summaries.main.birthWeight || " ",
          breed: animal.doc.derived.summaries.main.breed,
          calvingScore: animal.doc.derived.summaries.main.birthCalvingEase,
          comment: animal.doc.derived.summaries.main.comment,
          dam,
          damId: animal.doc.derived.summaries.main.damId,
          damTags: animal.doc.derived.summaries.main.damTags,
          guid: animal.guid,
          implantNumber: "TBD",
          sire,
          sireId: animal.doc.derived.summaries.main.sireId,
          sireTags: animal.doc.derived.summaries.main.sireTags,
          status: animal.doc.derived.summaries.main.status,
          tagValues: animal.doc.derived.summaries.main.tagValues,
          tsu: animal.doc.derived.summaries.main.dnaNumber,
          vigor: animal.doc.derived.summaries.main.vigor,
          weanDate:
            weanDates.length && weanDates[0].timeRecorded
              ? moment(weanDates[0].timeRecorded).format("YYYY-MM-DD HH:mm")
              : null,
        });
      }
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect.results"),
          JSON.stringify(newVal)
        );

        if (+newVal.weight) this.form.weanWeight = "" + newVal.weight;

        if (newVal.tsu && newVal.tsu.trim()) this.form.tsu = newVal.tsu.trim();

        if (newVal.eid)
          if (this.options.eidToAnimalShortSummaries[newVal.eid]) {
            this.search.eid = newVal.eid;
          } else {
            this.form.eid = newVal.eid;
          }

        if (newVal.visual)
          if (this.options.visualToAnimalShortSummaries[newVal.visual]) {
            this.search.visual = newVal.visual;
          } else {
            this.form.visual = newVal.visual;
          }
      },
    },
    form: {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("form"),
          JSON.stringify(newVal)
        );
      },
    },
    search: {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("search"),
          JSON.stringify(newVal)
        );
      },
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;

    EventBus.$tabEvent.on("consumed", ({ type, value }) => {
      if (type === "eid" && this.form.eid === value) this.form.eid = null;
      if (type === "tsu" && this.form.tsu === value) this.form.tsu = null;
      if (type === "visual" && this.form.visual === value)
        this.form.visual = null;
      if (type === "weight" && this.form.weight === value)
        this.form.weight = null;

      if (type === "eid" && this.herdConnect.results.eid === value)
        this.herdConnect.results.eid = null;
      if (type === "tsu" && this.herdConnect.results.tsu === value)
        this.herdConnect.results.tsu = null;
      if (type === "visual" && this.herdConnect.results.visual === value)
        this.herdConnect.results.visual = null;
      if (type === "weight" && this.herdConnect.results.weight === value)
        this.herdConnect.results.weight = null;

      // watch should handle this, but we were seeing that it was not sufficient
      if (type === "eid" && this.search.eid === value) this.search.eid = null;
      if (type === "visual" && this.search.visual === value)
        this.search.visual = null;

      this.getIDoptions();
    });

    EventBus.$on("count-for-group-number", (result) => {
      if (result.groupNumber !== this.form.group) return;
      // forward on to parent
      EventBus.$emit("wean-total", result.count);
    });

    const vaccinations = await this.herdMeta.getMetaVaccinationsAsync(
      true,
      false,
      false
    );
    if (vaccinations instanceof Array)
      this.options.vaccinations = vaccinations.filter(
        (vaccination) => vaccination.pregOrWeanMedication == true
      );
    else this.options.vaccinations = [];

    const IDOptionsAreLoaded = this.getIDoptions();

    const previousForm = JSON.parse(
      localStorage.getItem(this.orgUserScoping("form"))
    );

    // Handle treatments specially
    if (!previousForm) this.form.treatments[0] = this.getDefaultTreatment();
    else if (
      previousForm &&
      (!previousForm.treatments ||
        (previousForm.treatments && previousForm.treatments.length == 0))
    )
      previousForm.treatments = [this.getDefaultTreatment()];

    const previousSearch = JSON.parse(
      localStorage.getItem(this.orgUserScoping("search"))
    );
    const previousHerdConnectResults = JSON.parse(
      localStorage.getItem(this.orgUserScoping("herdConnect.results"))
    );

    if (previousForm != null && this.form != previousForm) {
      this.form = previousForm;
    }
    // Always default to marketable after page reload
    this.form.status = "marketable";

    if (
      previousHerdConnectResults != null &&
      this.herdConnect.results != previousHerdConnectResults
    ) {
      // We need IDs to be loaded before we can reconstitute herd connect values
      // If we do not wait, the values will always appear to be new.
      setTimeout(async () => {
        await IDOptionsAreLoaded;

        if (previousSearch != null && this.search != previousSearch) {
          this.search = previousSearch;

          // Clear out previous search values if they are no longer valid
          if (!this.options.eidToAnimalShortSummaries[eid])
            this.search.eid = null;
          if (!this.options.visualToAnimalShortSummaries[visual])
            this.search.visual = null;
        }

        this.herdConnect.results = previousHerdConnectResults;
      }, 0);
    }

    const origins = await this.herdMeta.getMetaOriginsAsync(true, true, true);
    if (origins instanceof Array)
      this.options.origins = origins.filter(
        (origin) => origin.weanOrigin == true
      );

    if (this.options.origins.length == 1) {
      // Select the only option
      this.form.origin = this.options.origins[0];
    }

    const sexes = await this.herdMeta.getMetaSexesAsync(true, true, true);
    if (sexes instanceof Array)
      this.options.sexes = sexes.filter(
        (item) => item.weanSex == true || item.weanSex == "true"
      );
    if (this.form.sex && this.options.sexes.length > 0) {
      this.form.sex = this.options.sexes.find(
        (sex) => sex.id == this.form.sex.id
      );
    }
    if (
      this.form.sex &&
      !this.options.sexes.some((sex) => sex.id == this.form.sex.id)
    ) {
      // Clear out current setting because it is no longer valid
      this.form.sex = null;
    }
  },
  methods: {
    ...mapActions({
      resetCalfInformation: "CalfInformation/reset",
      setCalfInformation: "CalfInformation/setInformation",
    }),
    displayOptionsIfAvailable: function (results, displayNofication) {
      this.multipleResults.showDialog = false;
      if (results.length === 0) {
        this.searchMeta.animal = null;
      } else if (results.length === 1) {
        const doc = results[0].doc;
        this.searchMeta.animal = new Animal(doc._id, this.herdMeta, doc, null);
      } else if (results.length > 1) {
        this.multipleResults.showDialog = true;

        if (!displayNofication) return;

        this.$notify({
          duration: 10000,
          group: "forms",
          text: "There are more results available. Select the correct animal.",
          title: "Multiple Results",
          type: "info",
        });
      }
    },
    forceUseNewAnimal: function () {
      this.searchMeta.animal = null;
      this.multipleResults.showDialog = false;
    },
    getDefaultTreatment: function () {
      return {
        dose: null,
        vaccination: null,
        validation: {
          errorDose: false,
          errorVaccination: false,
        },
      };
    },
    addDataAsync: async function (animal, isNew, reason) {
      const promises = [];

      let timeRecordedISO = moment(this.form.date).toISOString();
      const dateIsToday = moment().diff(timeRecordedISO, "days") === 0;
      if (dateIsToday) {
        // Re-init with time
        timeRecordedISO = new Date().toISOString();

        const coords = await this.$utils.getGeoLocation();
        if (coords.latitude || coords.longitude)
          promises.push(
            animal.modify("geopoints", null, "id", coords.id, false, true, {
              ...coords,
              reason: isNew ? "New Calf wean" : "Calf wean",
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            })
          );
      }

      const commentId = (this.form.comment || "").trim()
        ? this.$utils.guid()
        : null;

      // Store group
      if (this.form.group)
        promises.push(
          animal.modify("movements", null, "locationId", null, false, true, {
            groupNumber: this.form.group,
            reason,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      // Store eid
      if ((this.form.eid || "").trim()) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.eid.trim(),
          timeRecorded: timeRecordedISO,
          type: "eid",
          userId: this.$userID,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      // Store visual
      if ((this.form.visual || "").trim()) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.visual.trim(),
          timeRecorded: timeRecordedISO,
          type: "visual",
          userId: this.$userID,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      // Store tsu
      if ((this.form.tsu || "").trim())
        promises.push(
          animal.modify(
            "dnaNumbers",
            null,
            "dnaNumber",
            this.form.tsu.trim(),
            false,
            true,
            {
              commentId,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      // Store wean weight
      if ((this.form.weanWeight || "").trim())
        promises.push(
          animal.modify(
            "weights",
            null,
            "weight",
            this.form.weanWeight.trim(),
            false,
            true,
            {
              commentId,
              source: "wean",
              groupNumber: this.form.group,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      // Store origin
      if (this.form.origin)
        promises.push(
          animal.modify(
            "origins",
            null,
            "originId",
            this.form.origin.id,
            false,
            true,
            {
              origin: this.form.origin.origin,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      // Store treatment
      const treatmentId = this.$utils.guid();
      this.form.treatments
        .filter((treatment) =>
          this.isValidTreatment(treatment.vaccination, treatment.dose)
        )
        .forEach(({ vaccination, dose }) => {
          promises.push(
            animal.modify(
              "treatments",
              null,
              "vaccinationsId",
              vaccination.id,
              false,
              true,
              {
                actualDose: Number.isNaN(dose) ? +dose : dose,
                commentId: commentId,
                id: treatmentId,
                userId: this.$userID,
                vaccinationTime: timeRecordedISO,
              }
            )
          );
        });

      // Store sex
      if (this.form.sex) {
        promises.push(
          animal.modify("sexes", null, "sexId", this.form.sex.id, false, true, {
            commentId,
            gender: this.form.sex.gender,
            sex: this.form.sex.sex,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

        const gender = this.form.sex.gender;
        if (gender)
          promises.push(
            animal.modify("genders", null, "gender", gender, false, true, {
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            })
          );
      }

      // Store comment
      if (commentId)
        promises.push(
          animal.modify(
            "comments",
            null,
            "comment",
            this.form.comment.trim(),
            false,
            true,
            {
              id: commentId,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      if (this.form.status)
        promises.push(
          animal.modify(
            "statuses",
            null,
            "status",
            this.form.status,
            false,
            true,
            {
              userId: this.$userID,
            }
          )
        );

      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then(() => {
            $.when(animal.save())
              .fail((e) => reject(new Error(`addDataAsync - Error: ${e}`)))
              .done(() => {
                if (isNew)
                  this.$notify({
                    duration: 10000,
                    group: "forms",
                    text: [
                      `Animal created (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                    ].join("<br />"),
                    title: "Success",
                    type: "success",
                  });
                else
                  this.$notify({
                    duration: 10000,
                    group: "forms",
                    text: [
                      `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                    ].join("<br />"),
                    title: "Success",
                    type: "success",
                  });

                this.resetCalfInformation();

                resolve("AddDataAsync - Success");
              });
          })
          .catch((e) => {
            this.$notify({
              group: "forms",
              text: "An error occurred.",
              title: "Error",
              type: "error",
            });
            reject(new Error(`AddDataAsync - Error: ${e}`));
          });
      });
    },
    addTreatment: function () {
      this.form.treatments.push(this.getDefaultTreatment());
    },
    getParentInformation: function (parentId) {
      return new Promise((resolve, reject) => {
        if (parentId)
          this.herdMeta.pouches.organization
            .animalSummaries(false, false, { group_level: 1 })
            .then((results) => {
              const { value } = results.rows.find(
                (result) => result.id == parentId
              );
              const parent = {
                birthDate: value.main.birthDate,
                breed: value.main.breed,
              };
              resolve(parent);
            });
        else resolve({ birthDate: null, breed: null });
      });
    },
    getIDoptions: async function () {
      const options =
        await this.herdMeta.pouches.organization.tagToAnimalShortSummaryBeyondThresholdAndMatchingStatuses(
          null,
          ["marketable"],
          [],
          null,
          false,
          null
        );

      this.options.eids = Object.keys(options.eid).sort();
      this.options.eidToAnimalShortSummaries = options.eid;
      this.options.visuals = Object.keys(options.visual).sort();
      this.options.visualToAnimalShortSummaries = options.visual;
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    isValidTreatment: function (vaccination, dose) {
      return (
        vaccination != null && vaccination != "" && dose != null && dose != ""
      );
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    removeTreatment: function (index) {
      this.form.treatments = this.form.treatments.filter(
        (treatment, idx) => idx != index
      );
    },
    resetFields: function () {
      if (!confirm("Reset?")) return;
      this.form = {
        comment: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        eid: null,
        group: this.form.group,
        origin: null,
        sex: null,
        status: "marketable",
        treatments: this.form.treatments.map((_) => this.getDefaultTreatment()),
        tsu: null,
        visual: null,
        weanWeight: null,
      };
      this.search = {
        eid: null,
        visual: null,
      };

      this.herdConnect.resetFields = !this.herdConnect.resetFields;
      this.herdConnect.results.eid =
        this.herdConnect.results.tsu =
        this.herdConnect.results.visual =
        this.herdConnect.results.weight =
          null;

      this.resetCalfInformation();
    },
    saveAnimal: async function () {
      const p = new Promise((resolve, reject) => {
        if (!this.searchMeta.animal)
          this.searchMeta.animal = HerdMeta.makeNewAnimal(
            this.herdMeta,
            this.$userID
          );

        if (this.animalIsNew) {
          // Tags are new
          this.addDataAsync(this.searchMeta.animal, true, "New calf wean")
            .catch((e) => {
              reject(new Error(`Failed to save animal: ${e}`));
            })
            .then(() => {
              resolve(this.searchMeta.animal);
            });
        } else {
          // Animal was located
          this.addDataAsync(this.searchMeta.animal, false, null)
            .catch((e) => {
              reject(new Error(`Failed to update animal: ${e}`));
            })
            .then(() => {
              resolve(this.searchMeta.animal);
            });
        }
      });

      await p
        .then((_animal) => {
          this.getIDoptions();
          EventBus.$emit("summary-refresh-start");

          if (this.form.eid)
            EventBus.$tabEvent.emit("consumed", {
              type: "eid",
              value: this.form.eid,
            });
          if (this.form.tsu)
            EventBus.$tabEvent.emit("consumed", {
              type: "tsu",
              value: this.form.tsu,
            });
          if (this.form.visual)
            EventBus.$tabEvent.emit("consumed", {
              type: "visual",
              value: this.form.visual,
            });
          if (this.form.weight)
            EventBus.$tabEvent.emit("consumed", {
              type: "weight",
              value: this.form.weight,
            });

          if (this.herdConnect.results.eid)
            EventBus.$tabEvent.emit("consumed", {
              type: "eid",
              value: this.herdConnect.results.eid,
            });
          if (this.herdConnect.results.tsu)
            EventBus.$tabEvent.emit("consumed", {
              type: "tsu",
              value: this.herdConnect.results.tsu,
            });
          if (this.herdConnect.results.visual)
            EventBus.$tabEvent.emit("consumed", {
              type: "visual",
              value: this.herdConnect.results.visual,
            });
          if (this.herdConnect.results.weight)
            EventBus.$tabEvent.emit("consumed", {
              type: "weight",
              value: this.herdConnect.results.weight,
            });

          this.form.eid =
            this.form.comment =
            this.form.tsu =
            this.form.visual =
            this.form.weanWeight =
            this.search.eid =
            this.search.visual =
            this.searchMeta.animal =
            this.herdConnect.results.eid =
            this.herdConnect.results.tsu =
            this.herdConnect.results.visual =
            this.herdConnect.results.weight =
              null;

          this.form.status = "marketable";
          EventBus.$emit("new-calf-entry");
          return _animal;
        })
        .catch((error) => {
          this.$notify({
            group: "forms",
            text: error,
            title: "Error",
            type: "error",
          });
        });

      return p;
    },
    searchAnimal: function (eid, visual) {
      return new Promise(async (resolve, reject) => {
        const matches = [
          ...new Set([
            ...(this.options.eidToAnimalShortSummaries[eid] || []).map(
              ({ animalId }) => animalId
            ),
            ...(this.options.visualToAnimalShortSummaries[visual] || []).map(
              ({ animalId }) => animalId
            ),
          ]),
        ];

        this.searchAnimal.animal =
          matches.length > 1
            ? await this.pouches.organization.get(matches[0])
            : HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);

        if (matches.length === 0) {
          this.multipleResults.options = [];
        } else {
          const docs = matches.map(
            async (id) => await this.pouches.organization.get(id)
          );

          this.multipleResults.options = await Promise.all(docs)
            .then((docs) =>
              docs
                .filter((doc) => doc !== null)
                .map((doc) => {
                  doc.derived ||= {
                    summaries: Animal.defaultSummaries(doc._id),
                  };
                  return doc;
                })
                .map((doc) => ({
                  doc: doc,
                  tagValues: doc.derived.summaries.main.tagValues || "",
                }))
                .sort((a, b) => a.tagValues.localeCompare(b.tagValues))
                .map((result, idx) => ({
                  doc: result.doc,
                  tagValues: `${idx + 1} - ${result.tagValues}`,
                }))
            )
            .catch((e) => {
              console.error(e);
              reject(e);
            });
        }

        resolve();
      });
    },
    streamEID(eid) {
      this.herdConnect.results.eid = eid;
    },
    streamTSU(tsu) {
      this.herdConnect.results.tsu = tsu;
    },
    streamVisual(visual) {
      this.herdConnect.results.visual = visual;
    },
    streamWeight(weight) {
      this.herdConnect.results.weight = weight;
    },
  },
};
</script>
<style scoped>
.darkInputText {
  color: white;
}
.biggerFont {
  font-size: 1.7rem;
}
.mediumFont {
  font-size: 1.5rem;
  color: black;
}
</style>
<style lang="scss">
.custom-text-color.theme--dark.v-input {
  input {
    color: black;
  }
}
</style>