var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0"},[_vm._v("Treatment History")]):_vm._e(),(_vm.showExtraInformation)?_c('div',{staticClass:"mb-2"},[(
        _vm.table.data[0] &&
        _vm.table.data[0].timeRecorded &&
        _vm.table.data[0].rectalTemperature
      )?_c('div',{staticClass:"d-flex flex-wrap justify-center align-center",staticStyle:{"max-width":"230px"}},[_c('p',{class:[
          'subtitle-treatment',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" Last Recorded Rectal Temperature "+_vm._s(_vm.$utils.renderValueAs(_vm.table.data[0].timeRecorded, "date"))+" ")]),_c('p',{class:[
          'treatment-value',
          'mb-0',
          _vm.$vuetify.theme.dark ? 'white--text' : '' ]},[_vm._v(" "+_vm._s(_vm.table.data[0].rectalTemperature)+" ")])]):_vm._e()]):_vm._e(),_c('export-button',{staticClass:"mb-2",attrs:{"disabled":_vm.table.data.length == 0,"filename":("Treatment_History_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
      new Date().toISOString(),
      'datetime'
    ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteTreament(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.actualDose",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'treatments',
            item.id,
            'actualDose',
            item.actualDose,
            true,
            false
          )}},model:{value:(item.actualDose),callback:function ($$v) {_vm.$set(item, "actualDose", $$v)},expression:"item.actualDose"}})]}},{key:"item.rectalTemperature",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'treatments',
            item.id,
            'rectalTemperature',
            item.rectalTemperature,
            true,
            false
          )}},model:{value:(item.rectalTemperature),callback:function ($$v) {_vm.$set(item, "rectalTemperature", $$v)},expression:"item.rectalTemperature"}})]}},{key:"item.timeRecorded",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.timeRecorded, "datetime", true))+" ")]}},{key:"item.actualWithdrawlDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.actualWithdrawlDate, "datetime", true))+" ")]}},{key:"item.vaccinationTime",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.vaccinationTime, "datetime", true))+" ")]),(!_vm.disabled)?_c('v-btn',{staticClass:"pa-0 ml-1",attrs:{"color":"#3565A1","max-width":"32","min-width":"32","small":""},on:{"click":function($event){return _vm.openDatetimeDialog(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.expirationDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.expirationDate, "datetime", true))+" ")]}},{key:"item.purchaseDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.purchaseDate, "datetime", true))+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.datetimeDialog.show),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "show", $$v)},expression:"datetimeDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v(" "+_vm._s(_vm.getTranslation("Treatment Time"))+" ")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_c('datetime-component',{model:{value:(_vm.datetimeDialog.datetime),callback:function ($$v) {_vm.$set(_vm.datetimeDialog, "datetime", $$v)},expression:"datetimeDialog.datetime"}})],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"pb-4 pr-6 d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2 rounded-lg normal-case body-1",attrs:{"text":""},on:{"click":function($event){_vm.datetimeDialog.show = false}}},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.cancel"))+" ")]),_c('v-btn',{staticClass:"rounded-lg primary-button normal-case body-1",attrs:{"disabled":!_vm.datetimeDialog.datetime},on:{"click":_vm.modifyTreatmentTime}},[_c('span',{staticClass:"px-6"},[_vm._v(" "+_vm._s(_vm.getTranslation("animalsPages.save"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }