<template>
  <div>
    <KeystrokeNavigation></KeystrokeNavigation>
    <component :is="currentHeader"></component>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import MobileHeader from "./Mobile";
import DesktopHeader from "./Desktop";
import KeystrokeNavigation from "./KeystrokeNavigation.vue";

export default {
  name: "Header",
  components: {
    Mobile: MobileHeader,
    Desktop: DesktopHeader,
    KeystrokeNavigation: KeystrokeNavigation,
  },

  computed: {
    currentHeader: function () {
      // On mobile, always use Mobile menu
      if (isMobile) return "Mobile";

      // On desktop, be responsive
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "Mobile";
        case "md":
        case "lg":
        case "xl":
          return "Desktop";
      }
    },
  },
};
</script>
