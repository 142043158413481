<template>
  <div :key="$utils.guid()">
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Comment History</h4>
      <export-button
        :disabled="tableData.length == 0"
        :filename="`Comment History ${animal.guid}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="tableData"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="tableData"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': animal.disallowChanges(),
          }"
          :disabled="animal.disallowChanges()"
          @click="deleteComment(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
  <script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "Weight",
  mixins: [TranslationMixin],
  props: {
    animal: {
      required: true,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
    tableData: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
        },
        { text: this.getTranslation("comment"), value: "comment" },
        { text: this.getTranslation("timeRecorded"), value: "timeRecorded" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
  methods: {
    deleteComment: async function (item) {
      await this.animal.deleteRecord("comments", item.id);
      this.$emit("data-editted");
    },
  },
};
</script>
<style scoped>
.disable-cursor {
  cursor: default;
}
</style>