const initialState = () => ({
  rowsCount: null,
});

// state
const state = initialState();

// getters
const getters = {
  getRowsCount: (state) => state.rowsCount,
};

// actions
const actions = {
  reset: ({ commit }) => {
    commit("RESET");
  },

  setRowsCount: ({ commit }, { rowsCount }) => {
    commit("setRowsCount", rowsCount);
  },
};

// mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setRowsCount: (state, rowsCount) => {
    state.rowsCount = rowsCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
