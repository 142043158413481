var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('summary-container',{style:({ height: _vm.customHeight + 'px', width: _vm.customWidth + 'px' }),attrs:{"headerHeight":86,"headerTitle":_vm.getTranslation('pregCheckPage.summary.name')},scopedSlots:_vm._u([{key:"bottom-header",fn:function(){return [_c('v-row',{staticClass:"ma-0"},[_c('v-subheader',[_vm._v(_vm._s(_vm.getTranslation("pregCheckPage.summary.description")))])],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{attrs:{"clearable":"","hide-details":"","label":"Search","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.summaryTableHeaders,"items-per-page":5,"items":_vm.summaryTableData,"loading":_vm.loading,"search":_vm.search,"sort-by":['pregCheckTime'],"sort-desc":[true],"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.tagValues",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                    name: 'AnimalDetails',
                    query: { id: item.animalId },
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(item.tagValues)+" ")])]}},{key:"item.pregCheckTime",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.pregCheckTime, "datetime", true))+" ")]}}])})],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }