<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        d="M7.88164 19.8253C5.68286 19.8253 3.90039 18.0428 3.90039 15.844C3.90039 13.787 5.46043 12.0944 7.46192 11.8847C7.36468 11.5145 7.31289 11.126 7.31289 10.7253C7.31289 8.2124 9.35 6.17529 11.8629 6.17529C14.0001 6.17529 15.7931 7.64878 16.2815 9.63528C16.5104 9.60397 16.7441 9.58779 16.9816 9.58779C19.8086 9.58779 22.1004 11.8795 22.1004 14.7065C22.1004 17.5336 19.8086 19.8253 16.9816 19.8253H7.88164Z"
      />
      <path
        :fill="fillCheck"
        clip-rule="evenodd"
        d="M16.3536 12.6464C16.5488 12.8417 16.5488 13.1583 16.3536 13.3536L12.3536 17.3536C12.1583 17.5488 11.8417 17.5488 11.6464 17.3536L9.64645 15.3536C9.45118 15.1583 9.45118 14.8417 9.64645 14.6464C9.84171 14.4512 10.1583 14.4512 10.3536 14.6464L12 16.2929L15.6464 12.6464C15.8417 12.4512 16.1583 12.4512 16.3536 12.6464Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
    fillCheck() {
      if (this.$parent.$attrs.primary) {
        return "white";
      } else {
        return this.$vuetify.theme.dark ? "#877E76" : "white";
      }
    },
  },
};
</script>
