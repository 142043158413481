<template>
  <dashboard-card class="card">
    <template #title-container>
      <v-row class="d-flex align-center mb-10 flex-initial">
        <v-col class="d-flex" cols="12" lg="7">
          <div class="mr-4">
            <v-icon :primary="!$vuetify.theme.dark" large>{{ "$cow" }}</v-icon>
          </div>
          <div class="text-h5 font-weight-medium dashboard-card-title">
            Animals
          </div>
        </v-col>
        <v-col
          class="ml-auto d-flex align-center justify-end gap-x-4"
          cols="12"
          lg="5"
        >
          <div class="align-center d-md-flex gap-x-4 w-full">
            <span class="mr-2"> Filter By </span>
            <v-select
              :items="items"
              :return-object="true"
              class="dashboard-select rounded-lg w-32"
              dense
              elevation="0"
              flat
              hide-details="true"
              item-text="description"
              label=""
              solo
              v-model="criteria"
            />
            <v-tooltip left v-if="criteria.countMissingBirthdates > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="align-center d-inline-flex warning justify-center lighten-4 rounded-circle"
                  color="warning"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-exclamation
                </v-icon>
              </template>
              <span>
                Some animals are missing a birth date. ({{
                  criteria.countMissingBirthdates
                }})
              </span>
            </v-tooltip>
            <v-tooltip left v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="align-center d-inline-flex justify-center lighten-4 rounded-circle success"
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>No errors/warning detected during computation.</span>
            </v-tooltip>
            <a href="/animals">
              <v-icon class="ml-1">mdi-open-in-new</v-icon>
            </a>
          </div>
        </v-col>
      </v-row>
    </template>
    <template #content>
      <div v-for="(dataset, $index) in criteria.datasets" :key="$index">
        <div class="d-flex font-weight-bold text-md-h6">
          <span>
            {{ dataset.name }}
            <v-btn
              @click="dataset.showDetails = !dataset.showDetails"
              icon
              v-if="dataset.breakout"
            >
              <v-icon v-if="dataset.showDetails">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </span>
          <span class="ml-auto">{{ dataset.total }}</span>
        </div>
        <breakout-bar
          :dataset="dataset.breakout"
          :key="$index"
          class="mb-4"
          v-if="dataset.showDetails"
        >
          <template #item-prepend="{ item }">
            <div class="name">
              <div>{{ item.name }}</div>
              <div>|</div>
            </div>
          </template>
        </breakout-bar>
      </div>
    </template>
  </dashboard-card>
</template>

<script>
import BreakoutBar from "./BreakoutBar.vue";

export default {
  components: { BreakoutBar },
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      criteria: {
        datasets: [],
      },
      items: [],
      styleCache: {},
      stylePalette: [
        { background: "rgb(41, 80, 143)", color: "white" },
        { background: "rgb(115, 151, 206)", color: "black" },
        { background: "rgb(206, 203, 199)", color: "black" },
        { background: "rgb(240, 140, 15)", color: "white" },
        { background: "rgb(16, 27, 45)", color: "white" },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    cachedStyleForKey(key) {
      const lowerCaseKey = key.toLowerCase();
      if (this.styleCache[lowerCaseKey]) {
        return this.styleCache[lowerCaseKey];
      }

      return (this.styleCache[lowerCaseKey] =
        this.stylePalette[
          Object.keys(this.styleCache).length % this.stylePalette.length
        ]);
    },
    async init() {
      const results = await this.pouches.organization.animalSummaries(
        false,
        false,
        {
          include_docs: false,
        }
      );

      if (results.rows.length == 0) return;
      const animals = results.rows
        .map(({ value }) => value)
        .map((value) => {
          const lastSighting = (value.sightingHistory || []).find(
            (item) => item.type !== "geopoint" && !!item.locationId
          );
          // Get last movement: greater to lower
          const lastMovement = (value.movementsHistory || []).find(
            (item) => item.type !== "geopoint" && !!item.locationId
          );
          const lastPasture =
            !!lastSighting && !!lastMovement
              ? moment(
                  lastSighting.timeRecorded || lastSighting.createdOn
                ).isAfter(
                  moment(lastMovement.timeRecorded || lastMovement.createdOn)
                )
                ? lastSighting
                : lastMovement
              : lastSighting || lastMovement;
          value.main.location = lastPasture && lastPasture.location;
          return value;
        })
        .map(({ main }) => main)
        .map(
          ({
            birthDate,
            color,
            herd,
            location,
            sex,
            status,
            tagValues,
            visualTagValues,
            EIDtagValues,
          }) => ({
            birthDate,
            color: color || "?",
            EIDtagValues,
            hasBirthDate: !!birthDate,
            herd: herd || "?",
            location: location || "?",
            sex: sex || "?",
            status: (status || "alive").toLowerCase(),
            tagValues,
            visualTagValues,
          })
        );

      const sortByFn = (by, item) => {
        // Age requires special treatment while other value types do not
        if (by !== "age") {
          return item[by];
        }

        // only age requires special treatment
        /* New segregation for age
          - 10+ years
          - 4 - 10 years
          - 2 - 3 years
          - 1 - 2 years
          - Birth - 1 year
          - Unknown - for animals that do not have a birthdate
        */
        // Calculate age based on birth date
        // Check if birth date is known
        if (item.hasBirthDate) {
          const ageInYears = moment().diff(moment(item.birthDate), "years");
          // Determine age category
          if (ageInYears >= 10) return "10+ years";
          if (ageInYears >= 4) return "4 - 10 years";
          if (ageInYears >= 2) return "2 - 3 years";
          if (ageInYears >= 1) return "1 - 2 years";
          if (ageInYears < 1) return "Birth - 1 year";
        } else return "Unknown";
      };

      const ageRanks = [
        "?",
        "0-3 months",
        "3-6 months",
        "6-12 months",
        "12+ months",
      ];

      this.items = [
        { datasets: [], description: "Age bracket", primary: "age" },
        { datasets: [], description: "Color", primary: "color" },
        { datasets: [], description: "Herd", primary: "herd" },
        { datasets: [], description: "Pasture", primary: "location" },
      ].map((mainItem) => {
        if (mainItem.primary === "age") {
          mainItem.countMissingBirthdates = animals.filter(
            ({ hasBirthDate }) => !hasBirthDate
          ).length;
        }

        const byMap = animals.reduce((reduction, item) => {
          const by = sortByFn(mainItem.primary, item);
          if (!reduction[by]) reduction[by] = [];
          reduction[by].push(item);

          return reduction;
        }, {});
        mainItem.datasets = Object.keys(byMap)
          .sort((a, b) => {
            if (mainItem.primary === "age") {
              return (ageRanks.indexOf(a) + "").localeCompare(
                ageRanks.indexOf(b) + ""
              );
            }

            // Keep ? to last
            if (a === b) return 0;
            if (a === "?") return 1;
            if (b === "?") return -1;
            return a.localeCompare(b);
          })
          .map((key) => {
            const animalListForBy = byMap[key];

            const bySex = animalListForBy.reduce((reduction, { sex }) => {
              if (reduction[sex] == undefined) {
                reduction[sex] = 0;
              }
              reduction[sex]++;

              return reduction;
            }, {});

            // TODO: Sort according to rank defined in metas instead of alphabetically
            const sortedSexKeys = Object.keys(bySex).sort((a, b) => {
              // Keep ? to last
              if (a === b) return 0;
              if (a === "?") return 1;
              if (b === "?") return -1;
              return a.localeCompare(b);
            });

            return {
              breakout: sortedSexKeys.map((key, idx) => ({
                classes: ["text-white"],
                name: key,
                style: this.cachedStyleForKey(key),
                value: bySex[key],
              })),
              name: key,
              total: sortedSexKeys.reduce(
                (reduction, key) => reduction + bySex[key],
                0
              ),
              showDetails: true,
            };
          });

        return mainItem;
      });

      if (this.items.length) this.criteria = this.items[3];
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>

<style scoped>
.name {
  position: absolute;
  top: -3rem;
}
.card >>> .title-and-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.breakout-bars-wrapper {
  max-height: 300px;
  overflow-y: auto;
}
</style>
