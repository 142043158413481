<template>
  <v-dialog max-width="500" scrollable v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form @submit.prevent="save" ref="sexForm" v-model="form.valid">
            <v-text-field
              :autofocus="type == 'create'"
              :label="getLabelTranslation('sex')"
              :rules="[formRules.required]"
              autocomplete="off"
              class="mb-2"
              clearable
              v-model.trim="form.fields.sex"
            />
            <v-select
              :autofocus="type == 'edit'"
              :error-messages="
                (type == 'create' && !!form.fields.gender) || type == 'edit'
                  ? ''
                  : getTranslation('animalsPages.requiredField')
              "
              :items="options.genders"
              :label="getLabelTranslation('gender')"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              item-text="label"
              v-model.trim="form.fields.gender"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!form.valid"
          @click="save"
          class="rounded-lg primary-button normal-case body-1"
          form="medication-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "SexDialog",
  props: {
    herdMeta: {
      required: true,
      type: Object,
    },
    onCreate: {
      required: true,
      type: Function,
    },
  },
  mixins: [TranslationMixin],
  data() {
    return {
      form: {
        fields: {
          gender: null,
          sex: null,
        },
        valid: false,
      },
      options: {
        genders: [],
      },
      sex: null,
      type: "create",
      showDialog: false,
    };
  },
  created() {
    this.options.genders = this.getEnumOptions("genders")
      .map((item) => ({
        label: item.label,
        value: item.value,
      }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  },
  computed: {
    formRules: function () {
      return {
        required: (value) =>
          !!value || this.getTranslation("animalsPages.requiredField"),
      };
    },
    title: function () {
      return this.type == "edit"
        ? this.getTranslation("Edit Sex")
        : this.getTranslation("Create Sex");
    },
  },
  methods: {
    open: function (objectToEdit = {}) {
      Object.assign(this.form.fields, this.$options.data().form.fields);
      this.type = "create";
      this.showDialog = true;
      if (Object.keys(objectToEdit).length) {
        this.type = "edit";
        this.sex = objectToEdit;
        this.form.fields.sex = this.sex.sex;
        this.form.fields.gender = this.sex.gender;
      }
    },
    getSexData: function () {
      if (this.type == "create")
        return {
          female: this.form.fields.gender === "female",
          gender: this.form.fields.gender,
          male: this.form.fields.gender === "male",
          sex: this.form.fields.sex,
        };
      this.sex.female = this.form.fields.gender === "female";
      this.sex.gender = this.form.fields.gender;
      this.sex.male = this.form.fields.gender === "male";
      this.sex.sex = this.form.fields.sex;
      return Object.assign({}, this.sex);
    },
    save: async function () {
      try {
        const data = this.getSexData();
        if (this.type == "create") {
          await this.herdMeta.create("sexes", data, "sex");
          if (this.onCreate) {
            this.onCreate();
          }
        } else if (this.type == "edit")
          await this.herdMeta.update("sexes", data);
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
        this.$emit("saved");
      } catch (error) {
        console.info(error);
        this.$notify({
          group: "forms",
          text: "Invalid Sex",
          title: "Error",
          type: "error",
        });
      } finally {
        this.showDialog = false;
      }
    },
  },
};
</script>
  