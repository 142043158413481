<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div class="my-6">
          <h4>{{ t.title }}</h4>
          <v-divider></v-divider>
        </div>
        <div>
          <table
            id="feedtrucksDisplay"
            class="table table-striped table-bordered w-100"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <tr>
                <th>{{ t.feedTrucks.guid }}</th>
                <th>{{ t.feedTrucks.name }}</th>
                <th>{{ t.feedTrucks.friendlyName }}</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>{{ t.feedTrucks.guid }}</th>
                <th>{{ t.feedTrucks.name }}</th>
                <th>{{ t.feedTrucks.friendlyName }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "FeedTrucks",
  metaInfo: {
    title: "Feed Trucks",
  },
  mixins: [TranslationMixin],
  data: () => ({
    editor: null,
    herdMeta: null,
    table: null,
  }),
  created: function () {
    this.init();
  },
  beforeDestroy: function () {
    $("input").off();
    if (this.editor != null) this.editor.destroy();
    if (this.table != null) this.table.destroy();
  },
  computed: {
    t: function () {
      return {
        feedTrucks: {
          friendlyName: this.translate.feedtrucks.friendlyName[this.localeLang],
          guid: this.translate.guid[this.localeLang],
          name: this.translate.feedtrucks.name[this.localeLang],
        },
        title: this.translate.navbar.feedtrucks[this.localeLang],
      };
    },
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.herdMeta.loaded.done(this.makeTable);
    },
    makeTable: async function () {
      const self = this;
      let editor = new $.fn.dataTable.Editor({
        ajax: function (method, url, d, successCallback, errorCallback) {
          var output = { data: [] };
          let promises = [];

          if (d.action === "create") {
            $.each(d.data, function (_key, value) {
              promises.push(
                self.herdMeta.create("feedtrucks", value, "feedtruck")
              );
            });
          } else if (d.action === "edit") {
            // Update each edited item with the data submitted
            $.each(d.data, function (_id, value) {
              promises.push(self.herdMeta.update("feedtrucks", value));
            });
          } else if (d.action === "remove") {
            // Remove items from the object
            $.each(d.data, function (_id, value) {
              promises.push(self.herdMeta.delete("feedtrucks", value.id));
            });
          }

          $.when
            .apply($, promises)
            .fail((_e) => {
              Vue.notify({
                group: "forms",
                type: "error",
                title: "Error",
                text: "An error occurred. Please refresh the page and try again.",
              });
              console.info(
                "An error occurred. Please refresh the page and try again."
              );
              errorCallback();
            })
            .done(() => {
              // Show Editor what has changed
              successCallback(output);
              setTimeout(self.makeTable, 1000);
            });
        },
        table: "#feedtrucksDisplay",
        fields: [
          {
            name: "id",
            type: "hidden",
          },
          {
            label: "Name",
            name: "name",
          },
          {
            label: "Friendly Name(s)",
            name: "friendlyName",
          },
        ],
        formOptions: {
          main: {
            focus: "name",
          },
        },
      });
      self.editor = editor;

      $("#feedtrucksDisplay tfoot th")
        .not(".responsive-control")
        .not(".details-control")
        .each(function () {
          var title = $(this).text();
          $(this).html(
            '<input type="text" id="search ' +
              title +
              '" class="form-control form-control-sm mx-auto w-75" placeholder="' +
              title +
              '" />'
          );
        });

      // Object that will contain the local state
      var responsiveToggle = {};

      // Create or update the todo localStorage entry
      if (localStorage.getItem("feedtrucksDisplay.responsiveToggle")) {
        responsiveToggle = JSON.parse(
          localStorage.getItem("feedtrucksDisplay.responsiveToggle")
        );
      } else {
        responsiveToggle = true;
      }
      this.tableData = await this.getValues();
      let table = $("#feedtrucksDisplay").DataTable({
        data: self.tableData,
        destroy: true,
        dom: "BRlfrtip",
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        stateSave: true,
        stateLoadParams: function (settings, data) {
          for (var i = 1; i < data.columns.length; i++) {
            $("th:nth-child(" + i + ') > input[type="text"]').val(
              data.columns[i - 1].search.search
            );
          }
        },
        mark: true,
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"],
        ],
        fixedHeader: {
          header: true,
          headerOffset: $(".v-toolbar__content").outerHeight(),
        },
        select: true,
        colReorder: true,
        responsive: responsiveToggle,
        processing: true,
        autoWidth: false,
        initComplete: function () {
          self.$utils.applySearch(this);
        },
        columns: [
          {
            data: "id",
            orderable: false,
            visible: false,
            searchable: true,
          },
          { data: "name" },
          { data: "friendlyName" },
        ],

        buttons: [
          {
            className: "btn mb-2 text-white",
            extend: "colvis",
            text: "Toggle",
          },
          {
            className: "btn mb-2 text-white",
            extend: "collection",
            text: "Select",
            autoClose: true,
            buttons: ["selectAll", "selectNone"],
          },
          {
            className: "btn mb-2 text-white",
            text: this.translate.tabletMode[responsiveToggle][this.localeLang],
            action: function (e, dt, node, config) {
              responsiveToggle = !responsiveToggle;
              localStorage.setItem(
                "feedtrucksDisplay.responsiveToggle",
                JSON.stringify(responsiveToggle)
              );
              window.location = window.location;
            },
          },
          {
            className: "btn mb-2 text-white",
            extend: "create",
            editor: editor,
            key: { altKey: true, key: "1" },
          },
          {
            className: "btn mb-2 text-white",
            extend: "edit",
            editor: editor,
            key: { altKey: true, key: "2" },
          },
          {
            className: "btn mb-2 text-white",
            extend: "remove",
            editor: editor,
            key: { altKey: true, key: "3" },
          },
          {
            className: "btn mb-2 text-white",

            text: "Reset Search",
            action: function (e, dt, node, config) {
              // eslint-disable-next-line no-alert
              if (window.confirm("Reset search?")) {
                table.search("").columns().search("").draw();

                $("#feedtrucksDisplay tfoot th input").each(function () {
                  this.value = "";
                });
              }
            },
          },
          {
            className: "btn mb-2 text-white",
            extend: "collection",
            text: "Export",
            autoClose: true,
            buttons: [
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
                text: "Copy",
                title: self.t.title,
              },
              {
                extend: "excelHtml5",
                exportOptions: {
                  columns: ":visible",
                },
                text: "Excel",
                title: self.t.title,
              },
              {
                extend: "csvHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
                text: "CSV",
                title: self.t.title,
              },
            ],
          },
        ],
      });
      table.on("column-reorder", function () {
        self.$utils.applySearch(table, true);
      });
      self.table = table;
    },
    getValues: function () {
      return new Promise((resolve, reject) => {
        this.herdMeta
          .getMetaFeedtrucksAsync(true, false, false)
          .done((feedtrucks) => {
            feedtrucks = feedtrucks
              .map((row) => {
                ["id", "friendlyName", "name"].forEach((column) => {
                  // This is only necessary to make datatables happier. It does not like to have undefined Values.
                  if (row[column]) return;
                  row[column] = null;
                });

                return row;
              })
              .map((row) => {
                // This is only necessary to make datatables happier. It wants a unique identifier.
                row.DT_RowId = row.id;
                return row;
              });
            resolve(feedtrucks);
          });
      });
    },
  },
};
</script>
