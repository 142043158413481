<template>
  <v-dialog
    :class="{ 'margin-top': headerHeight }"
    v-model="show"
    scrollable
    persistent
    max-width="300px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title> Online Warning </v-card-title>
      <v-divider class="mt-0"></v-divider>
      <v-card-text>
        This page is only available with internet connection. Please go to
        another available page.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "OnlineWarning",
  computed: {
    headerHeight: function () {
      return $(".v-toolbar__content").outerHeight();
    },
    show: function () {
      if (this.isOffline) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>