<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12" class="mt-6" id="app" v-cloak>
        <div v-if="isLoading">
          <v-progress-circular
            size="40"
            width="5"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div id="body">
          <template v-if="errors.length == 0">
            <div
              class="alert alert-danger"
              role="alert"
              v-if="DAO.isSyncRecommended()"
            >
              {{ getLabelTranslation("animalDetails.mergeDanger") }}
            </div>
            <div
              class="alert alert-warning"
              role="alert"
              v-if="!DAO.isSyncRecommended()"
            >
              {{ getLabelTranslation("animalDetails.mergeWarning") }}
            </div>
            <div
              class="alert alert-info"
              role="alert"
              v-if="DAO.isSyncRecommended()"
            >
              {{ getLabelTranslation("animalDetails.mergeInstructions") }}
            </div>
          </template>
          <template v-else="errors.length == 0">
            <div class="alert alert-danger" role="alert">
              Some errors were encountered for the selected pair. The merge
              cannot be processed. Please consider reloading your source and
              retry.
              <ul>
                <li v-for="error in errors" :key="error">
                  {{ error.message }}
                </li>
              </ul>
            </div>
          </template>

          <h1>History</h1>
          <div class="panel-group">
            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4>
                  <a data-toggle="collapse" href="#potentialDuplicatePairs">
                    Potential Duplicates
                  </a>
                  -
                  <span style="font-size: smaller">
                    {{ table.data.length }} potential duplicates remain
                  </span>
                </h4>
              </div>
              <div id="potentialDuplicatePairs" class="collapse">
                <v-data-table
                  :headers="headers"
                  :items="table.data"
                  :loading="isLoading"
                  :search="table.search"
                  class="dashboard-data-table d-flex flex-column pb-6"
                  loading-text="Loading..."
                >
                  <template #top>
                    <div class="d-flex flex-wrap">
                      <div class="ml-auto d-flex flex-wrap justify-center">
                        <!-- Global Search -->
                        <v-text-field
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          hide-details
                          label="Search"
                          outlined
                          style="max-width: 250px"
                          v-model="table.search"
                        ></v-text-field>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.tagValues="{ item }">
                    <td>
                      <span class="font-weight-medium">
                        {{ item.tagValues }}
                      </span>
                    </td>
                  </template>
                  <template v-slot:item.animalATags="{ item }">
                    <router-link
                      :to="{
                        name: 'AnimalDetails',
                        query: { id: item.animalA },
                      }"
                      class="text-h6 text-none"
                      target="_blank"
                    >
                      {{ item.animalATags }}
                    </router-link>
                  </template>
                  <template v-slot:item.animalBTags="{ item }">
                    <router-link
                      :to="{
                        name: 'AnimalDetails',
                        query: { id: item.animalB },
                      }"
                      class="text-h6 text-none"
                      target="_blank"
                    >
                      {{ item.animalBTags }}
                    </router-link>
                  </template>
                  <template v-slot:item.details="{ item }">
                    <a
                      class="btn btn-warning white--text mr-2"
                      @click="compare(item.animalA, item.animalB)"
                      >Compare</a
                    >
                    <a
                      class="btn btn-success white--text"
                      @click="
                        resolveDuplicate(true, item.animalA, item.animalB, true)
                      "
                      >Merge Now</a
                    >
                    <!--- <a class="btn btn-danger" @click="resolveDuplicate(false, potentialDuplicatePair.animalA, potentialDuplicatePair.animalB, true)">Not a match</a> --->
                  </template>
                </v-data-table>
              </div>
            </div>

            <div class="panel panel-default hide">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#nonDuplicatePairs"
                    >Non-duplicates</a
                  >
                </h4>
              </div>

              <div id="nonDuplicatePairs" class="panel-collapse collapse">
                <table
                  class="table table-striped table-bordered dataTable"
                  :class="{
                    'table-dark-mode': $vuetify.theme.dark,
                    'table-dark': $vuetify.theme.dark,
                  }"
                >
                  <thead>
                    <tr>
                      <th class="animalA">Animal A</th>
                      <th class="animalB">Animal B</th>
                      <th>{{ getLabelTranslation("user") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="nonDuplicatePairs.length === 0">
                      <td colspan="3">Nothing to show</td>
                    </tr>
                    <tr
                      v-else
                      :key="index"
                      v-for="(nonDuplicatePair, index) in nonDuplicatePairs"
                    >
                      <td>
                        <router-link
                          :to="{
                            name: 'AnimalDetails',
                            query: { id: nonDuplicatePair.animalA },
                          }"
                          class="text-h6 text-none"
                          target="_blank"
                        >
                          {{ nonDuplicatePair.tagValuesA }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AnimalDetails',
                            query: { id: nonDuplicatePair.animalB },
                          }"
                          class="text-h6 text-none"
                          target="_blank"
                        >
                          {{ nonDuplicatePair.tagValuesB }}
                        </router-link>
                      </td>
                      <td>{{ nonDuplicatePair.username }}</td>
                      <td>
                        <a
                          class="btn btn-primary"
                          @click="
                            compare(
                              nonDuplicatePair.animalA,
                              nonDuplicatePair.animalB
                            )
                          "
                          >Revisit</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="panel-footer">
                  &nbsp;
                  <!--- Panel footer, blank space --->
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div
                class="card card-body mb-4"
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#deduplicated"
                    >Deduplicated</a
                  >
                </h4>
              </div>
              <div id="deduplicated" class="panel-collapse collapse">
                <table
                  class="table table-striped table-bordered dataTable"
                  :class="{
                    'table-dark-mode': $vuetify.theme.dark,
                    'table-dark': $vuetify.theme.dark,
                  }"
                >
                  <thead>
                    <tr>
                      <th>{{ getLabelTranslation("animalIds") }}</th>
                      <th>{{ getLabelTranslation("user") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="deduplicateds.length === 0">
                      <td colspan="3">Nothing to show</td>
                    </tr>
                    <tr
                      v-else
                      :key="index"
                      v-for="(deduplicated, index) in deduplicateds"
                    >
                      <td class="individualAnimal">
                        <router-link
                          :to="{
                            name: 'AnimalDetails',
                            query: { id: deduplicated.animalA },
                          }"
                          class="text-h6 text-none"
                          target="_blank"
                        >
                          {{ deduplicated.tagValues }}
                        </router-link>
                      </td>
                      <td>{{ deduplicated.username }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AnimalDetails',
                            query: { id: deduplicated.animalA },
                          }"
                          class="text-h6 text-none"
                          target="_blank"
                        >
                          View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="panel-footer">
                  &nbsp;
                  <!--- Panel footer, blank space --->
                </div>
              </div>
            </div>
          </div>

          <!-- Compare function result -->
          <div v-if="!!pair[0]">
            <!-- compare-headers -->
            <div>
              <div>
                <v-btn
                  :disabled="mergeIsBlocked"
                  @click="resolveDuplicate(true, pair[0], pair[1])"
                  color="primary"
                  >Merge match</v-btn
                >
              </div>
            </div>

            <div>
              <animal-details
                @animal-changed="animalChanged"
                :animalID="pair[0]"
              ></animal-details>
              <animal-details
                @animal-changed="animalChanged"
                :animalID="pair[1]"
                v-if="pair[1]"
              ></animal-details>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AnimalDetails from "./AnimalDetailsBeta.vue";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";

export default {
  name: "ReviewDuplicateAnimals",
  metaInfo: {
    title: "Merge Duplicate Animals",
  },
  components: { AnimalDetails },
  mixins: [TranslationMixin],
  data: () => ({
    add: null,
    deduplicateds: [],
    errors: [],
    herdMeta: null,
    loading: 0,
    nonDuplicatePairs: [],
    operations: [],
    pair: [],
    table: {
      data: [],
      search: null,
    },
    userId: null, // need this for tying comments to user who performs a merge/updates/deletions
  }),
  computed: {
    DAO: function () {
      return DAO;
    },
    headers: function () {
      return [
        {
          align: "start",
          searchable: true,
          sortable: true,
          text: "Tags In Common",
          value: "tagValues",
        },
        {
          searchable: true,
          sortable: false,
          text: "Animal A Tag Summary",
          value: "animalATags",
        },
        {
          searchable: true,
          sortable: false,
          text: "Animal B Tag Summary",
          value: "animalBTags",
        },
        {
          class: this.lastOperation
            ? this.lastOperation.type == "danger"
              ? "alert-danger"
              : this.lastOperation.type == "warning"
              ? "alert-warn"
              : this.lastOperation.type == "info"
              ? "alert-info"
              : this.lastOperation.type == "success"
              ? "alert-success"
              : ""
            : "",
          sortable: false,
          text: this.lastOperation ? this.lastOperation.message : "",
          value: "details",
        },
      ];
    },
    mergeIsBlocked: function () {
      return (
        !this.pairIsSelected || this.errors.some(({ blockMerge }) => blockMerge)
      );
    },
    isLoading: function () {
      return this.loading > 0;
    },
    labels: function () {
      return this.translate;
    },
    lastOperation: function () {
      if (!this.operations.length) return null;
      return this.operations[this.operations.length - 1];
    },
    local: function () {
      return this.localeLang;
    },
    pairIsSelected: function () {
      return this.pair.length > 1;
    },
    t: function () {
      return {
        animalDetails: this.translate.navbar.animalDetails[this.localeLang],
        birth: this.translate.animalDetails.Birth[this.localeLang],
        complete: this.translate.complete[this.localeLang],
      };
    },
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
    // Must init after document is ready
    this.userId = this.$userID;

    this.herdMeta.loaded.done(() => {
      /* This code is which do the magic of compare */
      this.add = new AnimalDuplicationDetector(this.herdMeta);
      this.loadDuplicatePairLists().then(() => {
        try {
          const id = this.$utils.getQueryVariable("id", true);
          const id2 = this.$utils.getQueryVariable("id2", true);
          if (id && id2) {
            this.compare(id, id2);
          }
        } catch (e) {
          console.error(e);
        }
      });
    });
  },
  methods: {
    log: console.log, //For use when debugging
    debug: console.debug, //For use when debugging
    error: console.error, //For use when debugging
    animalChanged: function () {
      this.compare(this.pair[0], this.pair[1]);
    },
    renderValueAs: function (value, type, includeRelativity) {
      this.$utils.renderValueAs(value, type, includeRelativity);
    },
    getAgeOfDuration: function () {
      return Animal.getAgeOfDuration;
    },
    moment: function () {
      return moment;
    },
    // There are some cases where manual merging is not allowed:
    // * Either animal is deleted
    // * Either animal has already been merged
    // * Recoverable: If the animalB has transfers and animalA does not, they must swap positions.
    checkComparePairForBlockingErrors: async function (
      animalAGuid,
      animalBGuid
    ) {
      this.loading++;

      let docA, docB;

      const errors = [];
      let guids = [];

      try {
        docA = await this.herdMeta.pouches.organization.get(animalAGuid);
        guids.push(animalAGuid);
      } catch (e) {
        errors.push({
          message: "Error was encountered when loading first animal.",
          blockMerge: true,
        });
      }

      try {
        docB = await this.herdMeta.pouches.organization.get(animalBGuid);
        guids.push(animalBGuid);
      } catch (e) {
        errors.push({
          message: "Error was encountered when loading first animal.",
          blockMerge: true,
        });
      }

      if (errors.length) {
        return { errors, guids };
      }

      if (
        docA &&
        docB &&
        Animal.wasTransferredOut(docB) &&
        !Animal.wasTransferredOut(docA)
      ) {
        // Swap positions
        guids = [animalBGuid, animalAGuid];

        const temp = docA;
        docA = docB;
        docB = temp;
      }

      if (docA) {
        if (Animal.wasMerged(docA)) {
          errors.push({
            message:
              "First animal of pair was already merged into another animal.",
            blockMerge: true,
          });
        } else if (docA.deleted) {
          errors.push({
            message: "First animal of pair was already deleted.",
            blockMerge: true,
          });
        }
      }

      if (docB) {
        if (Animal.wasMerged(docB)) {
          errors.push({
            message:
              "Second animal of pair was already merged into another animal.",
            blockMerge: true,
          });
        } else if (docB.deleted) {
          errors.push({
            message: "Second animal of pair was already deleted.",
            blockMerge: true,
          });
        }
      }

      this.loading--;

      return { errors, guids };
    },
    compare: function (animalAGuid, animalBGuid) {
      this.pair = [];

      Vue.nextTick(async () => {
        this.operationTime = new Date().toISOString();
        const { errors, guids } = await this.checkComparePairForBlockingErrors(
          animalAGuid,
          animalBGuid
        );
        this.pair = guids;

        this.errors = errors;
      });
    },
    loadDuplicatePairLists: function () {
      return new Promise((resolve, reject) => {
        const start = moment();
        $.when(this.add.initCache())
          .fail(reject)
          .done(() => {
            this.loading++;
            this.add
              .getNonDuplicatePairsAsync(moment().add(-4, "weeks"))
              .always(() => {
                this.loading--;
              })
              .done((pairs) => {
                this.nonDuplicatePairs = pairs;
              });

            this.loading++;
            this.add
              .getMergedAnimalsAsync(moment().add(-4, "weeks"))
              .always(() => {
                this.loading--;
              })
              .done((animals) => {
                this.deduplicateds = animals;
              });

            this.loading++;
            $.when(this.add.getPotentialDuplicatePairsAsync())
              .always(() => {
                this.loading--;
                console.log("Total time", moment() - start);
                resolve();
              })
              .fail((_errorList) => {
                Vue.notify({
                  group: "forms",
                  type: "danger",
                  title: "Error",
                  text: this.getLabelTranslation(
                    "animalDetails.unableToQueryMainDatabase"
                  ),
                });
              })
              .done((potentialDuplicates) => {
                this.debug("Located potential duplicates.");

                this.table.data = potentialDuplicates;

                if (!this.table.data.length) {
                  Vue.notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    text: this.getLabelTranslation(
                      "animalDetails.noPotentialDuplicatesRemain"
                    ),
                  });
                }
              });
          });
      });
    },
    receiveMessage: function (message) {
      message.moment = moment();

      this.operations.push(message);

      // Remove messages regularly
      setTimeout(() => {
        this.operations = this.operations.splice(1);
      }, 5000);
    },
    resolveDuplicate: function (merge, animalAGuid, animalBGuid, isQuick) {
      if (
        merge &&
        !confirm(this.getLabelTranslation("animalDetails.mergeIsPermanent"))
      )
        return;

      this.loading++;
      this.add
        .removePotentialDuplicate(
          merge,
          animalAGuid,
          animalBGuid,
          this.userId,
          this.receiveMessage
        )
        .always(() => {
          this.loading--;
        })
        .fail(() => {
          if (merge)
            Vue.notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: this.getLabelTranslation("animalDetails.failedToMerge"),
            });
          if (!merge)
            Vue.notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: this.getLabelTranslation(
                "animalDetails.failedToMarkAsNotDuplicates"
              ),
            });
        })
        .done((messages) => {
          this.loadDuplicatePairLists().then(() => {
            const guid = this.pair[0];
            this.pair = [];
            if (!isQuick && false)
              // disable for now
              this.loadFirstPairFromDuplicatesListForAnimal(guid);
          });

          if (messages)
            Vue.notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: messages.join("<br />"),
            });
        });
    },
    loadFirstPairFromDuplicatesListForAnimal: function (guid) {
      // animal.reloadData();
      if (!this.table.data.length) {
        // No need to alert user that no duplicates remain since that is done upon re-crunching the numbers
        this.pair = [guid];
        return;
      }

      this.possiblesForA = this.table.data.filter(
        (potentialDuplicate) =>
          potentialDuplicate.animalA == guid ||
          potentialDuplicate.animalB == guid
      );

      if (!this.possiblesForA.length) {
        Vue.notify({
          group: "forms",
          type: "success",
          title: "Success",
          text: this.getLabelTranslation(
            "animalDetails.noPotentialDuplicatesRemainForThisAnimal"
          ),
        });
        this.pair = [guid];
        return;
      }

      Vue.notify({
        group: "forms",
        type: "info",
        title: "Info",
        text: this.getLabelTranslation(
          "animalDetails.proceedToNextDuplicateForThisAnimal"
        ),
      });

      this.pair = [docA, docB];
    },
  },
};
</script>
<style scoped>
input {
  border: 1px solid #ccc;
}
</style>