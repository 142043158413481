/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "Utils" }]*/
/* global moment:false */
class Utils {
  static makeVisualHumanSortFriendly(largestDigitLength, visualTag) {
    const matches = visualTag.match(/\d+/g);
    if (matches == null) {
      // string had no numbers
      return visualTag;
    }

    // From left to right, replace each match with a padded match
    let currentIndex = 0;
    let converted = "";
    let post = visualTag;
    matches.forEach((match) => {
      const index = post.indexOf(match);
      const pre = post.substring(currentIndex, index);
      post = post.substring(index + match.length);
      converted += pre + this.padLeft(match, largestDigitLength);
      currentIndex += match.length;
    });
    converted += post;

    return converted;
  }

  static getAcceptableFileTypes() {
    return ".txt, .xlsx, .xlsm, .xlsb, .xls, .xml, .tsv, .csv, .dif, .sylk, .xlw, .xltx, .xltm, .xlt, .xlam, .xla, .ods, .dbf";
  }

  // Align the tables and sort the keys (since PouchDB doesn't store null values)
  static alignTable(table) {
    // Gather all possible keys
    const allKeys = {};
    table.forEach((record) => {
      $.each(record, (index, _value) => {
        allKeys[index] = true;
      });
    });

    // Ensure the keys exist in each record
    $.each(allKeys, (key, _value) => {
      table.forEach((record) => {
        record[key] = record[key] !== undefined ? record[key] : null;
      });
    });
    return table;
  }

  static getItemFromLocalStorage(key) {
    const item = localStorage.getItem(key) || "";
    return this.stringIsEmpty(item) ? {} : JSON.parse(item);
  }

  static stringIsEmpty(str) {
    return str.trim().length == 0;
  }

  static filterObjectByArrayOfKeys(obj, arr) {
    const newObj = {};
    arr.forEach((key) => {
      Object.keys(obj).forEach((prop) => {
        if (key == prop) newObj[prop] = obj[prop];
      });
    });
    return newObj;
  }

  // This promise is used to convert list of File objects into Buffers in order
  // to express will be able to process the data and send it via email.
  /* Example
    this.files = [new File(), ...]
    const attachments = await this.$utils.convertFilesToBuffers(this.files);
    axios
      .post(
        "/api/email/test",
        {
          attachments,
          body: "Testing attachments",
          destination: {
            id: this.$organizationID,
          },
          subject: "Test Email",
          to: ["michael.bean@fort-supply.com", ...],
        },
        {
          params: {
            token: this.getToken(),
          },
        }
      )
      .then(({ data }) => console.log(data))
      .catch((e) => {
        console.log(e.response.data);
      });
  */
  static async convertFilesToBuffers(filesArray) {
    return await Promise.all(
      filesArray.map(async (file) => {
        const buffer = await file.arrayBuffer().then((arrBuffer) => {
          return Buffer.from(arrBuffer);
        });

        return {
          content: JSON.stringify(buffer),
          contentType: file.type,
          filename: file.name,
        };
      })
    );
  }

  static setObjectValues(obj, value) {
    Object.keys(obj).forEach((key) => {
      obj[key] = value;
    });
  }

  static evaluateObjectValues(obj, value) {
    return Object.keys(obj).every((key) => obj[key] == value);
  }

  // This promise is used to convert list of File objects into Buffers in order
  // to express will be able to process the data and send it via email.
  /* Example
    this.files = [new File(), ...]
    const attachments = await this.$utils.convertFilesToBuffers(this.files);
    axios
      .post(
        "/api/email/test",
        {
          attachments,
          body: "Testing attachments",
          destination: {
            id: this.$organizationID,
          },
          subject: "Test Email",
          to: ["michael.bean@fort-supply.com", ...],
        },
        {
          params: {
            token: this.getToken(),
          },
        }
      )
      .then(({ data }) => console.log(data))
      .catch((e) => {
        console.log(e.response.data);
      });
  */
  static async convertFilesToBuffers(filesArray) {
    return await Promise.all(
      filesArray.map(async (file) => {
        const buffer = await file.arrayBuffer().then((arrBuffer) => {
          return Buffer.from(arrBuffer);
        });

        return {
          content: JSON.stringify(buffer),
          contentType: file.type,
          filename: file.name,
        };
      })
    );
  }

  static localToGMT(fechaLocalStr) {
    const fechaLocalObj = new Date(fechaLocalStr);
    const offsetMinutes = fechaLocalObj.getTimezoneOffset();
    const fechaGMT = new Date(fechaLocalObj.getTime() + offsetMinutes * 60000);
    return fechaGMT.toISOString().slice(0, 23).replace("T", " ");
  }

  static GMTToLocal(fechaGMTStr) {
    try {
      const fechaGMTObj = new Date(fechaGMTStr);
      const offsetMinutes = fechaGMTObj.getTimezoneOffset();
      const fechaLocal = new Date(
        fechaGMTObj.getTime() - offsetMinutes * 60000
      );
      return fechaLocal.toISOString().slice(0, 23).replace("T", " ");
    } catch (error) {
      return "";
    }
  }

  static applySearch(datatable, columnReorder = false) {
    if (!columnReorder) {
      datatable
        .api()
        .columns()
        .every(function () {
          $("input", this.footer()).off("keyup");
        });
      // deepcode ignore UseArrowFunction: Michael was unable to find a way to handle it without function()
      datatable
        .api()
        .columns()
        .every(function () {
          var that = this;

          $("input", this.footer()).on("keyup", function () {
            if (that.search() !== this.value) {
              that.search(this.value).draw();
            }
          });
        });
    } else {
      console.log("Column reorder applySearch");
      // remove any old listeners
      datatable.columns().every(() => {
        // Here "this" is the vue component.
        // P.S.: I do not know why but is working in that way
        $("input", datatable.footer()).off("keyup");
      });
      datatable.columns().every(function () {
        // Here "this" is the datatable object
        $("input", this.footer()).on("keyup", (e) => {
          const value = e.currentTarget.value;
          if (this.search() !== value) {
            this.search(value).draw();
          }
        });
      });
    }
  }

  static exportToCSV({
    data = [{ text: "test" }],
    config = {},
    filename = "Data",
  }) {
    const csv = Papa.unparse(data);
    const link = document.createElement("a");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
      ...config,
    });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${filename}.csv`);
    link.click();
    link.remove();
    return "Exported";
  }

  static capitalizeString(str, separator) {
    const arr_strs = str.split(separator);
    return arr_strs
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join(" ");
  }

  static convertCSVtoJson(csv, sliceContent = 0) {
    const contents = [];
    return new Promise((resolve, reject) => {
      try {
        Papa.parse(csv, {
          skipEmptyLines: true,
          worker: true,
          step: function (results) {
            contents.push(results.data);
          },
          complete: function () {
            const content_sliced = contents.slice(sliceContent);
            const content_json = Utils.convertMatrixToJson(content_sliced);
            resolve(content_json);
          },
        });
      } catch (e) {
        reject([]);
      }
    });
  }

  static convertMatrixToJson(matrix) {
    const fields = matrix[0];
    return matrix.reduce((previous, current, idx) => {
      if (idx == 0) {
        current.forEach((item) => {
          previous[item] = [];
        });
      } else {
        current.forEach((item, field_idx) => {
          previous[fields[field_idx]].push(item);
        });
      }
      return previous;
    }, {});
  }

  static getAllPouchDBFields() {
    return [
      {
        value: "ID(animalID)",
        definition: "Generated unique identifier",
      },
      {
        definition: "What user created the animal",
        value: "createdBy",
      },
      {
        definition: "When the animal was created",
        value: "createdOn",
      },
      {
        definition: "Id of any offspring of the animal",
        category: "birthings",
        value: "childId",
      },
      {
        category: "birthings",
        value: "hoof",
        definition: "Hoof Score 1 to 10 score on how good the animals feet are",
      },
      {
        category: "birthings",
        value: "milk",
        definition: "How good was the Cows milk",
      },
      {
        definition: "Difficulty of delivering calf",
        category: "birthCalvingEases",
        value: "birthCalvingEase",
      },
      {
        category: "birthDates",
        value: "birthDate",
        definition: "Date of Birth for the animal",
      },
      {
        category: "birthWeights",
        definition: "Birth Weight of the Animal",
        value: "birthWeight",
      },
      {
        definition: "Not Currently Used",
        category: "breedings",
        value: "1stAiServiceDate",
      },
      {
        definition: "Not Currently Used",
        category: "breedings",
        value: "2ndAiServiceDate",
      },
      {
        definition: "Person who performed the AI insemenation",
        category: "breedings",
        value: "aiTechnician",
      },
      {
        definition: "When the animal was breed",
        category: "breedings",
        value: "breedDate",
      },
      {
        definition: "Not Currently Used",
        category: "breedings",
        value: "serviceDateIn",
      },
      {
        definition: "Not Currently Used",
        category: "breedings",
        value: "serviceDateOut",
      },
      {
        definition: "Name of the Sire",
        category: "breedings",
        value: "sire",
      },
      {
        category: "breeds",
        definition: "Breed of the Animal",
        value: "breed",
      },
      {
        definition: "Result of Bull Test Positive or Negative",
        category: "bullTests",
        value: "result",
      },
      {
        definition: "Summaries entry used for summary data",
        category: "bullTests",
        value: "test",
      },
      {
        category: "bullTests",
        value: "testCheckTime",
        definition: "Not Used",
      },
      {
        definition: "Summaries entry used for summary data",
        category: "bullTests",
        value: "testedBy",
      },
      {
        definition: "Not used",
        category: "bullTests",
        value: "testImageId",
      },
      {
        definition: "Summaries entry used for summary data",
        category: "bullTests",
        value: "testMethod",
      },
      {
        definition:
          "Id for the Vaccination record that was given to the animal",
        category: "bullTests",
        value: "vaccinationId",
      },
      {
        definition: "ID for the Carcass",
        category: "carcassData",
        value: "carcId",
      },
      {
        definition: "EID tag number",
        category: "carcassData",
        value: "eid",
      },
      {
        category: "carcassData",
        definition:
          "A name or number assigned to the animal for grouping purposes",
        value: "groupNumber",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "hotScaleWeight",
      },
      {
        definition: "Age of Carcass",
        category: "carcassData",
        value: "carcassAge",
      },
      {
        definition: "Type of carcass",
        category: "carcassData",
        value: "carcassType",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "qualityGrade",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "qualityGrade2",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "od",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "od2",
      },
      {
        definition: "Not Currently Used",
        category: "carcassData",
        value: "yieldGrade",
      },
      {
        definition: "Date the animal was Killed",
        category: "carcassData",
        value: "killDate",
      },
      {
        definition: "Lot the animal was in when it was killed",
        category: "carcassData",
        value: "killLot",
      },
      {
        definition: "Kill plant the animal was killed at",
        category: "carcassData",
        value: "plant",
      },
      {
        definition: "Measurement of Pelvis",
        category: "heiferTests",
        value: "pelvicMeasure",
      },
      {
        definition: "Ranch where animal was born",
        category: "calfWean",
        value: "birthRanch",
      },
      {
        category: "calfWean",
        definition: "Sex of the animal",
        value: "sex",
      },
      {
        definition: "The ranch the animal was on when it was weaned",
        category: "calfWean",
        value: "weanRanch",
      },
      {
        definition: "Weight of the animal when it was weaned",
        category: "calfWean",
        value: "weanWeight",
      },
      {
        category: "clearanceDates",
        definition: "Not used",
        value: "clearanceDate",
      },
      {
        definition:
          "ID for the Color given to animal. ID refers to a sperate record",
        category: "colors",
        value: "colorId",
      },
      {
        definition: "General comment made for the animal",
        category: "comments",
        value: "comment",
      },
      {
        definition: "ID for the animals Dam",
        category: "damIds",
        value: "damId",
      },
      {
        category: "damIds",
        value: "damTags",
        definition: "Tag of Dam",
      },
      {
        category: "damIds",
        value: "timeRecorded",
        definition:
          "The time when the record creation or modification happened",
      },
      {
        definition: "ID for the animals Adopted Dam",
        category: "adoptDamIds",
        value: "adoptDamId",
      },
      {
        definition: "Tag of Adopted Dam",
        category: "adoptDamIds",
        value: "adoptDamTags",
      },
      {
        definition: "ID for the animals Sire",
        category: "sireIds",
        value: "sireId",
      },
      {
        definition: "Tag of Sire",
        category: "sireIds",
        value: "sireTags",
      },
      {
        definition: "Not Currently Used",
        category: "coverSireIds",
        value: "coverSireId",
      },
      {
        definition: "Not Currently Used",
        category: "coverSireIds",
        value: "coverSireTags",
      },
      {
        definition: "Date of death for the animal",
        category: "deathDates",
        value: "deathDate",
      },
      {
        category: "dnaNumbers",
        definition:
          "DNA or TSU number. Usually scanned in from a vile or barcode",
        value: "dnaNumber",
      },
      {
        definition: "For AI YES or NO",
        category: "fertilizations",
        value: "gnrhReceived",
      },
      {
        definition: "Person who performed the AI insemenation",
        category: "fertilizations",
        value: "technician",
      },
      {
        definition: "When the insementation occurred AM / PM",
        category: "fertilizations",
        value: "timeOfDay",
      },
      {
        definition: "Gender of the animal",
        category: "genders",
        value: "gender",
      },
      {
        definition: "GPS Lat of recored event",
        category: "geopoints",
        value: "GPSLat",
      },
      {
        definition: "GPS Long of recored event",
        category: "geopoints",
        value: "GPSLong",
      },
      {
        category: "herds",
        definition: "The Herd that the Animal belongs to",
        value: "herd",
      },
      {
        definition: "ID for the Herd record created that the animal is in",
        category: "herds",
        value: "herdId",
      },
      {
        category: "ids",
        definition: "Not Currently Used",
        value: "friendlyName",
      },
      {
        category: "ids",
        definition: "Manfacture of the Tags",
        value: "manufacturer",
      },
      {
        category: "ids",
        definition: "ID for Picture. Not used",
        value: "pictureId",
      },
      {
        category: "ids",
        definition: "Not Set ?",
        value: "primaryTag",
      },
      {
        category: "ids",
        definition: "Size of the Tags",
        value: "size",
      },
      {
        category: "ids",
        definition: "Color of Tag",
        value: "tagColor",
      },
      {
        category: "ids",
        definition: "Legacy Not Used",
        value: "tagId",
      },
      {
        category: "ids",
        definition: "Value of the Tag",
        value: "tagValue",
      },
      {
        category: "ids",
        definition: "Legacy Not Used",
        value: "tagValueDouble",
      },
      {
        category: "ids",
        value: "type",
        definition: "Type of Tag - Visual, EID, Backtag etc….",
      },
      {
        definition:
          "Location ID. The Record ID for the Location record that is associated with it",
        category: "movements",
        value: "locationId",
      },
      {
        category: "movements",
        definition: "Reason for moving animal",
        value: "reason",
      },
      {
        category: "origins",
        definition: "ID for the Orgin record created that the animal is in",
        value: "originId",
      },
      {
        definition: "Animals breed percentage cross",
        category: "percentCrosses",
        value: "percentCross",
      },
      {
        definition: "Not Currently Used",
        category: "pregChecks",
        value: "animalVaccinationsId",
      },
      {
        category: "pregChecks",
        value: "bcs",
        definition: "",
      },
      {
        category: "pregChecks",
        value: "bullTurninDate",
        definition:
          "Date the Bull was turned in with the Cows for that breeding season",
      },
      {
        category: "pregChecks",
        definition: "Number of days Animal has been bred when preg checked",
        value: "daysBred",
      },
      {
        category: "pregChecks",
        value: "fetalSex",
        definition: "Sex of the Fetus",
      },
      {
        category: "pregChecks",
        definition: "Method used to perform the Preg Check",
        value: "method",
      },
      {
        category: "pregChecks",
        value: "mouthScore",
        definition: "",
      },
      {
        category: "pregChecks",
        definition: "Preg Check Section",
        value: "pregCheck",
      },
      {
        category: "pregChecks",
        definition: "Technician that performend the Preg Check",
        value: "pregCheckedBy",
      },
      {
        category: "pregChecks",
        definition: "Not used",
        value: "pregCheckImage",
      },
      {
        category: "pregChecks",
        definition: "Date the Preg check was recorded",
        value: "pregCheckTime",
      },
      {
        definition: "ID for the Weather. Not used",
        category: "pregChecks",
        value: "weatherId",
      },
      {
        category: "receivingRanches",
        definition: "ID of the Organization",
        value: "organizationId",
      },
      {
        category: "receivingRanches",
        value: "receivingRanch",
        definition: "Name of the Ranch the Animal will be transferred to.",
      },
      {
        category: "receivingRanches",
        definition: "ID of the Ranch the animal is to be transferred to",
        value: "receivingRanchId",
      },
      {
        category: "salesPurchases",
        definition:
          "Commision Rate charged to purchase the animal per head or Percentage",
        value: "commissionType",
      },
      {
        category: "salesPurchases",
        definition: "Not Currently Used",
        value: "eventOrganization",
      },
      {
        category: "salesPurchases",
        value: "freightCost",
        definition: "Amount it cost to ship the animal",
      },
      {
        category: "salesPurchases",
        definition: "Not Currently Used",
        value: "freightRage",
      },
      {
        category: "salesPurchases",
        definition: "Sale Price of animal",
        value: "price",
      },
      {
        category: "salesPurchases",
        definition: "Unit of Price - Dollars, Pounds etc….",
        value: "priceUnits",
      },
      {
        definition: "Commision Rate changed to purchase the animal",
        category: "salesPurchases",
        value: "purchaseCommissionRate",
      },
      {
        category: "salesPurchases",
        value: "salesCommissionRate",
        definition: "",
      },
      {
        category: "salesPurchases",
        definition: "Date of the Transaction",
        value: "transactionDate",
      },
      {
        category: "salesPurchases",
        value: "transactionType",
        definition: "",
      },
      {
        category: "salesPurchases",
        definition: "Units in which the weight was recored",
        value: "weightUnits",
      },
      {
        category: "sexes",
        definition: "ID givento the record that was associated with an animal",
        value: "sexId",
      },
      {
        definition: "Species of the animal",
        category: "species",
        value: "species",
      },
      {
        category: "statuses",
        definition:
          "Current Status of the Animal - Alive, Sick, Lost, Sold etc…",
        value: "status",
      },
      {
        category: "treatments",
        definition: "Dose Given to animal",
        value: "actualDose",
      },
      {
        category: "treatments",
        definition: "Not used",
        value: "actualPictureId",
      },
      {
        category: "treatments",
        definition: "Not used",
        value: "animalVaccinationPictureID",
      },
      {
        category: "treatments",
        definition: "Units in which the does was given - mL",
        value: "doseUnits",
      },
      {
        definition: "Rectal Temperature of the Animal",
        category: "treatments",
        value: "rectalTemperature",
      },
      {
        category: "treatments",
        definition:
          "ID given to the Vaccination record that was used to treat an animal",
        value: "vaccinationsId",
      },
      {
        category: "treatments",
        definition: "Date when a vaccination was given to an animal",
        value: "vaccinationTime",
      },
      {
        definition: "How Vigorous was the calf",
        category: "vigors",
        value: "vigor",
      },
      {
        category: "weights",
        value: "commentId",
        definition: "ID for comments. Not really used",
      },
      {
        category: "weights",
        value: "scale",
        definition: "",
      },
      {
        category: "weights",
        value: "source",
        definition: "",
      },
      {
        category: "weights",
        value: "units",
        definition: "",
      },
      {
        category: "weights",
        definition: "Weight of the animal",
        value: "weight",
      },
      {
        value: "lastUpdatedBy",
      },
      {
        category: "locations",
        definition: "Latest comment",
        value: "description",
      },
      {
        category: "locations",
        value: "endDate",
      },
      {
        category: "locations",
        value: "intakeLocation",
      },
      {
        category: "locations",
        value: "longitude",
      },
      {
        category: "locations",
        value: "latitude",
      },
      {
        category: "locations",
        value: "reader",
      },
      {
        category: "locations",
        value: "sicknessThreshold",
      },
      {
        category: "locations",
        value: "sizeUnits",
      },
      {
        category: "locations",
        value: "startDate",
      },
      {
        category: "origins",
        value: "weanOrigin",
      },
      {
        category: "sexes",
        value: "weanSex",
      },
      {
        category: "sexes",
        value: "isMale",
      },
      {
        category: "sexes",
        value: "isFemale",
      },
      {
        category: "tags",
        value: "bagCost",
      },
      {
        category: "tags",
        value: "bagQuantity",
      },
      {
        category: "tags",
        value: "cost",
      },
      {
        category: "vaccinations",
        value: "administrationMethod",
      },
      {
        category: "vaccinations",
        value: "batchNumber",
      },
      {
        category: "vaccinations",
        value: "bottlePrice",
      },
      {
        category: "vaccinations",
        value: "cssPerBottle",
      },
      {
        category: "vaccinations",
        value: "expirationDate",
      },
      {
        category: "vaccinations",
        value: "lotNumber",
      },
      {
        category: "vaccinations",
        value: "pregOrWeanMedication",
      },
      {
        category: "vaccinations",
        value: "serialNumber",
      },
      {
        category: "vaccinations",
        value: "vaccinationBottlePictureId",
      },
      {
        category: "vaccinations",
        value: "vaccType",
      },
      {
        category: "vaccinations",
        value: "withdrawlTime",
      },
      {
        category: "vaccinations",
        value: "withdrawlTimeUnits",
      },
      {
        dataType: "varchar",
        text: "Visual Tag",
        value: "visualTagValues",
      },
      {
        dataType: "varchar",
        text: "Visual Tag Colors",
        value: "visualTagColors",
      },
      {
        definition: "How old the animal is",
        text: "Age",
        value: "age",
      },
      {
        definition: "Predicted date when the cow is to give Birth",
        text: "Predicted Calving Date",
        value: "predictedCalvingDate",
      },
      {
        definition:
          "Cycle of the Animal when it was pregcheck. Based of Days Bred",
        text: "Cycle",
        value: "cycle",
      },
      {
        definition: "Pasture or Location of the Animal",
        text: "Pasture or Pen/Location",
        value: "location",
      },
      {
        definition: "Hide Color of the Animal",
        text: "Color",
        value: "color",
      },
      {
        definition: "Animals Current Weight",
        text: "Current Weight",
        value: "currentWeight",
      },
      {
        definition: "Date of the Animals most recent weight",
        text: "Current Weight Date",
        value: "currentWeightDate",
      },
      {
        text: "EID Tag Values",
        value: "EIDtagValues",
      },
      {
        text: "EID Colors",
        value: "EIDcolors",
      },
      {
        text: "Nues Tag Values",
        value: "nuesTagValues",
      },
      {
        text: "Association Tag Values",
        value: "associationTagValues",
      },
      {
        text: "Brand Tag Values",
        value: "brandTagValues",
      },
      {
        text: "Brisket Tag Values",
        value: "brisketTagValues",
      },
      {
        text: "Tattoo Tag Values",
        value: "tattooTagValues",
      },
      {
        text: "Backtag Values",
        value: "backtagValues",
      },
      {
        text: "Backtag Colors",
        value: "backtagColors",
      },
      {
        text: "Tag Lot",
        value: "tagLot",
      },
      {
        definition: "Warning to user that a birth date or something is missing",
        text: "Alerts",
        value: "alerts",
      },
      {
        definition: "Not Used",
        text: "Clearance Time Frame",
        value: "clearanceTimeFrame",
      },
      {
        definition:
          "Number of days since an animal was moved between Pastures/Pens",
        text: "Days Since Last Move",
        value: "daysSinceLastMove",
      },
      {
        definition: "The Date the animal was processed",
        text: "Processing Date",
        value: "processingDate",
      },
      {
        definition: "How much it cost to process the animal",
        text: "Processing Cost",
        value: "processingCost",
      },
      {
        definition: "How much it cost to treat the animal",
        text: "Doctoring Cost",
        value: "doctoringCost",
      },
      {
        text: "AI Sire Tags",
        value: "aiSireTags",
      },
      {
        text: "AI Status ID",
        value: "aiStatusId",
      },
      {
        definition:
          "Result of the Pregcheck calculated is the Animal Pregnant or Not",
        text: "Preg Check Result",
        value: "pregCheckResult",
      },
      {
        definition: "The Group of animals that were Pregcheck",
        text: "Preg Group",
        value: "pregGroup",
      },
      {
        definition: "The Method that was used to Pregcheck the animal",
        text: "Preg Method",
        value: "pregMethod",
      },
      {
        definition: "The Person who performend the check",
        text: "Preg Technician",
        value: "pregTechnician",
      },
      {
        definition: "Not Used",
        text: "Preg Check Status",
        value: "pregCheckStatus",
      },
      {
        text: "BCS",
        value: "bcs",
      },
      {
        text: "Mouth Score",
        value: "mouthScore",
      },
      {
        definition: "Animals first weight",
        text: "First Weight",
        value: "firstWeight",
      },
      {
        definition: "When that Animal was first weighed",
        text: "First Weight Date",
        value: "firstWeightDate",
      },
      {
        definition: "How many days between the first and last weight",
        text: "Days Between First And Last Weight",
        value: "daysBetweenFirstAndLastWeight",
      },
      {
        text: "Head Days",
        value: "headDays",
      },
      {
        text: "ADG Total",
        value: "adgTotal",
      },
      {
        text: "ADG 2",
        value: "adg2",
      },
      {
        definition: "Purchase Price of the animal",
        text: "Purchase Price",
        value: "purchasePrice",
      },
      {
        definition: "Rarely used not used for true data",
        text: "Total Event Income",
        value: "totalEventIncome",
      },
      {
        definition: "Date the Animal was Purchased",
        text: "Purchase Date",
        value: "purchaseDate",
      },
      {
        definition: "Visual ID of calf",
        text: "Birth Visual",
        value: "birthVisual",
      },
      {
        definition: "Test performed on the animal",
        text: "Bull Test",
        value: "bullTest",
      },
      {
        definition: "Result of the test performed on the animal",
        text: "Bull Test Result",
        value: "bullTestResult",
      },
      {
        definition: "Method used to perform the test on the animal",
        text: "Bull Test Method",
        value: "bullTestMethod",
      },
      {
        definition: "Where the animal came from",
        text: "Origin",
        value: "origin",
      },
      {
        definition: "When the record was last updated",
        text: "Last Updated",
        value: "lastUpdated",
      },
      {
        definition: "When the animal was created",
        text: "Animal Created On",
        value: "animalCreatedOn",
      },
    ];
  }

  static formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  // Other options exist, and this one isn't the fastest, but it is very easy to maintain
  // See: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
  static copyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static replaceStringsInMainString(mainStr, replacement, ...strs) {
    if (typeof mainStr != "string") return mainStr;
    strs.forEach((str) => {
      if (mainStr.includes(str)) {
        mainStr = mainStr.replaceAll(str, replacement || "");
      }
    });
    return mainStr;
  }

  static filterArrayOfObjectsByKeys(arr, keys) {
    const arrData = this.copyObject(arr);
    return arrData.map((item) => {
      Object.keys(item).forEach((prop) => {
        if (!keys.includes(prop)) delete item[prop];
      });
      return item;
    });
  }

  static width() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      0
    );
  }

  static getModelObject(obj) {
    const model = this.copyObject(obj);
    Object.keys(model).forEach((key) => (model[key] = null));
    return model;
  }

  static sortMetaData(a, b) {
    if (a.orderVT != null && b.orderVT == null) {
      return -1;
    } else if (b.orderVT == null && a.orderVT != null) {
      return 1;
    } else if (a.orderVT != null && b.orderVT != null) {
      return a.orderVT - b.orderVT;
    }

    if (a.enabledVT && !b.enabledVT) {
      return -1;
    } else if (!a.enabledVT && b.enabledVT) {
      return 1;
    }

    const aDateTime =
      a.lastUpdated ||
      a.createdOn ||
      new Date(0 - new Date().getTimezoneOffset() * 60 * 1000);
    const bDateTime =
      b.lastUpdated ||
      b.createdOn ||
      new Date(0 - new Date().getTimezoneOffset() * 60 * 1000);
    return bDateTime - aDateTime;
  }

  static getGeoLocation() {
    let timeout = 500;
    return new Promise((resolve, reject) => {
      let interval;

      const id = Utils.guid("-", "geopoint");
      if (navigator.geolocation) {
        const position = ({ coords }) => {
          clearInterval(interval);
          // For backwards-compatibility
          coords.GPSLat = coords.latitude;
          coords.GPSLong = coords.longitude;
          coords.GPSSampleTime = new Date().toISOString();
          coords.id = id;
          resolve(coords);
        };
        navigator.geolocation.getCurrentPosition(position);
      } else {
        timeout = 0;
      }

      // If we can't get it within 500 ms, the user might be offline. Chrome on desktop assumes that GPS can be located even if user is offline. That is currently incorrect.
      // Placing a timeout here means we cannot clear it if the user navigates away from the page, but this is a really important issue to fix.
      interval = setTimeout(() => {
        resolve({
          GPSLat: "",
          GPSLong: "",
          GPSSampleTime: new Date().toISOString(),
          id,
          latitude: "",
          longitude: "",
        });
      }, timeout);
    });
  }

  static checkObjectProps(obj1, obj2) {
    /* 
      This will check if there are some missing prop that obj1 have but obj2 have not.
      Finally will remove all the props on obj2 that are not the same of obj1.
    */
    const props1 = Object.keys(obj1);
    const props2 = Object.keys(obj2);
    const missingProp = props1.find((prop) => !props2.includes(prop));
    for (const prop in obj2) {
      const unnecessaryProp = !props1.includes(prop);
      if (unnecessaryProp) delete obj2[prop];
    }
    return !!missingProp;
  }

  static checkIfIsCorrectNumericValue(value) {
    const match = value && value.match(/^([1-9]*(\d?.\d+)?)$/);
    return match && !!match.length;
  }

  static padLeft(number, padLeft) {
    return Utils.pad(number, padLeft, 0);
  }

  static padRight(number, padRight) {
    return Utils.pad(number, 0, padRight);
  }

  static pad(number, padLeft, padRight) {
    padLeft = padLeft || 0;
    padRight = padRight || 0;

    if (padLeft > 0 && padRight > 0)
      console.error("This may not do what you want it to do.");

    let numberAsString = parseInt(number) + "";
    const decimal = number - parseInt(number);
    while (numberAsString.length < padLeft + padRight) {
      if (padLeft) numberAsString = "0" + numberAsString;
      if (padRight) numberAsString += "0";
    }

    return numberAsString + (decimal ? decimal : "");
  }

  static populateColors(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#color").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).name)
      );
    }
  }

  static populateLocations(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#locationId").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).name)
      );
    }
  }

  static populateLocationList(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      var key = rs.rows.item(i).id;
      var value = rs.rows.item(i).name;

      $("#locationList").append(
        $("<option></option>").attr("value", key).text(value)
      );
    }
  }

  static populateOrigins(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#origin").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).name)
      );
    }
  }

  static populateSexes(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#sex").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).name)
      );
    }
  }

  static populateTags(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#tagId").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).type)
      );
    }
  }

  static populateTreatments(_tx, rs) {
    for (var i = 0; i < rs.rows.length; i++) {
      $("#treatmentId").append(
        $("<option></option>")
          .attr("value", rs.rows.item(i).id)
          .text(rs.rows.item(i).name)
      );
    }
  }

  static scopeVariableName(name, organizationId, userId, extra, replacement) {
    // At least 1 is required
    if (!organizationId && !userId) return null;

    let parts = [];
    parts.push(name);
    if (userId !== null) parts.push(userId);
    parts.push(organizationId);
    if (extra) parts.push(extra);

    return parts
      .join("-")
      .replace(new RegExp("-", "g"), replacement ? replacement : "-");
  }

  static storeSetting(key, value) {
    localStorage.setItem(key, value);
  }

  // Firebase sorts JSON objects. That is handy. Not required, but handy. It makes for easier perusal of data and easier diffing.
  static sortHashTableKeys(hashtable, recurse) {
    if (!hashtable) return hashtable;

    return Object.keys(hashtable)
      .sort()
      .reduce((reduction, key, _index) => {
        const value = hashtable[key];
        if (recurse && value && typeof value === "object") {
          // Since it is an object, attempt to sort it (it might be an array)
          try {
            value = Utils.sortHashTableKeys(value, true);
          } catch (e) {
            // It is not a hashtable
          }
        }
        reduction[key] = value;
        return reduction;
      }, {});
  }

  // Uses i18n to obtain enums
  // Returns enums in a form that is readily used for populating select lists
  static getAndLoadEnumOptions(enumKey, target) {
    let defer = $.Deferred();
    Utils.loadEnum(DAO.getEnumOptions(enumKey), target);

    defer.resolve();
    return defer.promise();
  }

  static loadEnum(rows, target) {
    rows.forEach((row) => {
      $(`#${target}`).append(
        $("<option></option>").attr("value", row.value).text(row.label)
      );
    });
  }

  static getSetting(key, shouldDefaultValue, def, castToBool) {
    let val = localStorage.getItem(key);
    if (val === undefined && shouldDefaultValue) {
      Utils.storeSetting(key, def);
      val = def;
    }
    if (castToBool) {
      return val === "true";
    }
    return val;
  }

  static poll(fn, callback, errback, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    (function p() {
      // If the condition is met, we're done!
      if (fn()) {
        callback();
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        setTimeout(p, interval);
      }
      // Didn't match and too much time, reject!
      else {
        errback(new Error("timed out for " + fn + ": " + arguments));
      }
    })();
  }

  // hardLimit is the limit at which it is determined that no results are helpful
  // softLimit is the number of results that will be displayed
  static autocompleteJSON(rs, softLimit, hardLimit) {
    let result = [];
    let rowData = {};

    if (rs.rows.length > hardLimit) {
      return result;
    }

    for (let i = 0; i < rs.rows.length && i < softLimit; i++) {
      rowData.id = rs.rows.item(i).animalId;
      rowData.label = rs.rows.item(i).tagValue;
      rowData.value = rs.rows.item(i).tagValue;
      rowData.locationId = rs.rows.item(i).locationId;
      result.push(rowData);
      rowData = {};
    }

    return result;
  }

  // returns text * count (e.g. '*', 3 -> '***')
  static textMultiply(text, count) {
    if (!count || count < 1) {
      return "";
    }
    let ret = "";
    for (let i = 0; i < count; i++) {
      ret += text;
    }
    return ret;
  }

  static verboseFileName(fileName) {
    if (!fileName) {
      return "";
    }
    return fileName.replace(/(.+)\.(.*)/, "$1 ($2)");
  }

  static setValueToObject(obj, value) {
    return Object.fromEntries(
      Object.entries(obj).map((pair) => {
        pair[1] = value;
        return pair;
      })
    );
  }

  static filterObjectByValue(obj, value) {
    return Object.fromEntries(
      Object.entries(obj).filter((pair) => pair[1] === value)
    );
  }

  static isWhitespace(val) {
    return val === null || val === undefined || val.toString().trim() === "";
  }

  static isValInArray(arr, val) {
    return arr.includes(val);
  }

  // Ex: Preg Check 1 -> preg_check_1
  static stringToID(str) {
    return str
      .replace(/\s/g, "_")
      .replace(/[^a-zA-Z0-9_]/g, "_")
      .toLowerCase();
  }

  /* Date functions */
  /* A convenience function which allows us to convert a non-moment toISOString
   *	We can't just call toISOString on moment directly because it would handle bad dates differently. While we want poorly formatted dates to come out with "Invalid Date", we don't care for nulls or empty strings to be complained about.
   */
  static momentize(str) {
    const ret = str == null || str == "" ? str : moment(str).toISOString();
    return ret;
  }
  // Converts to a format that is compatible with <input type="datetime-local">...</input>
  static datetimeLocal(str) {
    const ret =
      str == null || str == ""
        ? str
        : moment(str).format("YYYY-MM-DDTHH:mm:ss");
    return ret;
  }

  // Given an array, returns a struct containing a moment representing the first and last of the dates
  static calcDateRange(arr) {
    const range = {
      first: null,
      last: null,
    };
    const moments = arr
      .filter((el) => el)
      .map((el) => moment(el))
      .sort((a, b) => a - b);
    if (!moments.length) return range;

    range.first = moments[0];
    range.last = moments[moments.length - 1];
    return range;
  }

  static containsRegexCharactersAndIsValidRegex(str) {
    return !!str.match(/[.*+?^${}()|[\]\\]/g) && Utils.isValidRegex(str);
  }

  // Same as what Papaparse does, it turns out
  static escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  static isValidRegex(str) {
    try {
      let r = RegExp(str);
    } catch (error) {
      return false;
    }
    return true;
  }

  // Supports "'a'" as a strict match and "a or b" as "a|b"
  static containsUserFriendlyRegex(str) {
    return str.match(/^".*"$/g) || str.match(/^'.*'$/) || str.match(/ or /gi);
  }

  // Maps '"something"' -> ^something$ and 'something OR somethingElse' -> 'something|somethingElse'
  static convertUserFriendlyRegexToFullRegex(str) {
    return str
      .replace(/"(.*?)"/g, "^$1$")
      .replace(/'(.*?)'/g, "^$1$")
      .replace(/ or /gi, "|")
      .replace(/([^^])(.*?)([^$])\|/g, ".*$1$2$3.*|")
      .replace(/\|([^^])(.*?)([^$])/g, "|.*$1$2$3.*");
  }

  // Empty string counts as ambiguous
  static momentIsNotAmbiguous(value) {
    const dashes = Utils.consistifyMoment(value);
    const isNotAmbiguous =
      // Starting with year always followed by month
      moment(dashes, "YYYY MMM DD hh:mm A", true).isValid() ||
      moment(dashes, "YYYY MMM DD h:mm A", true).isValid() ||
      moment(dashes, "YYYY MMMM DD hh:mm A", true).isValid() ||
      moment(dashes, "YYYY MMMM DD h:mm A", true).isValid() ||
      moment(dashes, "YYYY-MM-DDTHH:mm:ss", true).isValid() ||
      moment(dashes, "YYYY-MM-DD HH:mm:ss", true).isValid() ||
      moment(dashes, "YYYY-M-D", true).isValid() ||
      moment(dashes, "M-D-YYYY", true).isValid() ||
      (moment(value).isValid() &&
        !(!dashes.match(/-/g) || dashes.match(/-/g).length < 2));
    return isNotAmbiguous;
  }

  // Returns true iff date appears to be in the future based on Now() (plus 1 minute)
  static momentIsFuture(value) {
    return moment(value).diff(moment().add("1", "minute"), "minutes") > 0;
  }

  // conflates slashes with dashes (probably so fewer rules need to be written)
  static consistifyMoment(value) {
    return value.replace(/\//g, "-");
  }

  // WARNING: Changes here should be reflected in FileImport.vue
  static isFileTypeSupported(fileName, tool) {
    if (tool == "sheetjs") {
      return !!fileName.match(
        /\.(txt|xlsx|xlsm|xlsb|xls|xml|tsv|csv|dif|sylk|xlw|xltx|xltm|xlt|xlam|xla|ods|dbf)$/g
      );
    }
    return false;
  }

  // While other browsers can just do 'Array.from(rs.rows)', Safari cannot
  static arrayFromSQLResultSetList(rows) {
    let rowArray = [];
    for (let i = 0; i < rows.length; i++) {
      try {
        rowArray.push(rows.item(i));
      } catch (e) {
        // Appears to already be converted, so just return
        return rows;
      }
    }
    return rowArray;
  }

  static getQueryVariable(variable, caseInsensitive) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (
        (caseInsensitive && pair[0].toLowerCase() == variable.toLowerCase()) ||
        pair[0] == variable
      ) {
        return pair[1];
      }
    }
    return null;
  }

  static getFileExtension(fileName) {
    var vars = fileName.split(".");
    return vars[vars.length - 1];
  }

  static getAnimalIdFromDocumentOrURL(elementId) {
    const animalId =
      parent.document !== document
        ? JSON.stringify(parent.document.getElementById(elementId).value)
        : `'${Utils.getQueryVariable("animalId", true)}'`;
    return animalId;
  }

  // This is a convenience function that logs to console if a config is not found
  static getLabelConfig(key) {
    const label = Utils.getLabelByKey(key, {
      type: "string",
      format: null,
      hide: false,
      defaulted: true,
    });

    if (label.config.defaulted) {
      delete label.config.defaulted;
      console.debug(
        `Key ${key} exists in labels, but no config exists for it. Defaulting to`,
        label.config
      );
    }
    return label.config;
  }

  static fromNow(datetime) {
    return moment(datetime).fromNow();
  }

  static renderValueAs(value, type, includeRelativity) {
    if (
      value &&
      (value + "").trim() &&
      ["datetime", "date", "time"].includes(type.toLowerCase())
    ) {
      const m = moment(value);
      if (m.isValid()) {
        let format = "YYYY-MM-DD";
        if (type === "datetime") {
          format = "YYYY-MM-DD hh:mm A";
        } else if (type === "time") {
          format = "hh:mm A";
        } else if (type === "date") {
          format = "YYYY-MM-DD";
        }
        if (!includeRelativity) return m.format(format);
        return `${m.format(format)} (${Utils.fromNow(m)})`;
      }
    } else if (type === "image" && value && value.trim()) {
      return `<a href="${value}">${Utils.getLabelsForLocale()["View"]}</a>`;
    }
    return value;
  }

  static getAgeOfTimestamp(datetime, settings) {
    if (!datetime || !moment(datetime).isValid()) return null;

    const m = moment(datetime);
    const age = moment.duration(moment().diff(m));

    return Utils.getAgeOfDuration(age, settings);
  }
  // Flags: ignoreHour: will not print anything smaller than a day
  static getAgeOfDuration(duration, settings) {
    if (!duration || !moment(duration).isValid()) return null;
    let ssettings = $.extend({}, settings);

    return (
      `${Utils.padLeft(duration.years(), 2)}y` +
      ` ${Utils.padLeft(duration.months(), 2)}m` +
      (ssettings.ignoreDays ? "" : ` ${Utils.padLeft(duration.days(), 2)}d`) +
      (ssettings.ignoreHours ? "" : ` ${Utils.padLeft(duration.hours(), 2)}h`)
    );
  }

  static round(value, decimals) {
    if (!decimals) return Math.round(value);
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  static updateProgress(barId, completed, total) {
    const percentComplete = Math.round((completed / total) * 100);
    $(`#${barId}.progress-bar`)
      .css("width", percentComplete + "%")
      .attr("aria-valuenow", percentComplete);
  }

  // http://stackoverflow.com/questions/5129624/convert-js-date-time-to-mysql-datetime

  /**
   * You first need to create a formatting function to pad numbers to two digits…
   **/
  static twoDigits(d) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
    return d.toString();
  }

  // http://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try

  static isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // Inspired by http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  static guid(glue, prefix) {
    glue = glue ? glue : "-";
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      (prefix ? prefix + "_" : "") +
      (s4() +
        s4() +
        glue +
        s4() +
        glue +
        s4() +
        glue +
        s4() +
        glue +
        s4() +
        s4() +
        s4())
    );
  }

  static isGUID(guid) {
    var re =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return re.test(guid);
  }

  static makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  static toHashMapKey(records, keyName) {
    return records.reduce((reduction, record) => {
      reduction[record[keyName]] = record;
      return reduction;
    }, {});
  }
}

export default Utils;
