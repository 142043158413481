import { i18n } from "@/i18n/index";
import { mapActions, mapGetters } from "vuex";

const TranslationMixin = {
  data: () => ({
    languages: [
      { label: "English", key: "en_US" },
      { label: "Spanish", key: "es_ES" },
    ],
    navbar: i18n.navbar,
    routes: i18n.routes,
    signInForm: i18n.signInForm,
    translate: { ...i18n },
  }),
  computed: {
    ...mapGetters({ getUserType: "User/getType" }),
    localeLang: {
      set: function (newLang) {
        this.setLocale({ locale: newLang });
      },
      get: function () {
        return this.$locale || "en_US";
      },
    },
  },
  methods: {
    ...mapActions({ setLocale: "User/setLocale" }),
    changeLanguage: function (language) {
      if (this.localeLang == language.key) return;
      this.localeLang = language.key;
      this.$router.go();
    },
    getTranslation: function (word) {
      if (typeof word != "string") {
        return "Invalid word";
      }
      if (word.includes(".")) {
        const subKeys = word.split(".");
        let value = null;
        subKeys.forEach((subKey) => {
          if (value != null && typeof value == "object") {
            value = value[subKey];
          } else {
            value = this.translate[subKey];
          }
        });
        if (typeof value != "undefined") return value[this.localeLang];
        return "No translation";
      } else {
        const request = this.translate[word];
        if (typeof request == "undefined") return "No translation";
        const keys = Object.keys(request);
        if (
          typeof request == "object" &&
          keys.includes("en_US") &&
          keys.includes("es_ES")
        )
          return this.translate[word][this.localeLang];
        return "No translation";
      }
    },
    getAndLoadEnumOptions: function (enumKey, target) {
      let defer = $.Deferred();
      Utils.loadEnum(this.getEnumOptions(enumKey), target);

      defer.resolve();
      return defer.promise();
    },
    getEnumOptions: function (enumKey) {
      const items = Object.keys(this.translate.enums[enumKey]).map((key) => {
        return {
          value: key,
          label: this.getLabelTranslation(`enums['${enumKey}']['${key}']`, key),
        };
      });
      return items;
    },
    // Default to "en_US" if locale is not set or is not present in labels
    getLabelTranslation: function (key, def) {
      let locale = this.$locale;
      // console.log(key);
      if (typeof i18n === "undefined" && !def) {
        return "";
      }
      const label = this.getLabelByKey(key);

      if (label[locale]) return label[locale];
      console.debug(
        `Key ${key} exists in labels, but no translation exists for locale (${locale}). Will try en_us.`,
        label
      );

      locale = "en_US";
      if (label[locale]) {
        return label[locale];
      }

      const ret = def || key;
      // console.debug(
      //   `Key ${key} exists in labels, but no translation exists for locale (${locale}). Defaulting to`,
      //   ret
      // );
      return ret;
    },
    /*
     * This is case-sensitive
     * Supports keys in the following format:
     *	 tags.id
     *	 tags['id']
     */
    getLabelByKey: function (key, fallbackDef) {
      // key can be multiple levels deep, so we accomodate by using eval (although recursion would avoid use of eval)
      let label;

      const translate = { ...i18n };
      try {
        label = eval(`translate.${key}`);
      } catch (e) {}

      const defaultConfig = fallbackDef
        ? fallbackDef
        : {
            type: "string",
            format: "",
            hide: false,
          };

      if (label) {
        if (!label.config) {
          label.config = defaultConfig;
          console.debug(`No config found for ${key}. Defaulting to`, label);
        }
        if (label.type === undefined) label.type = defaultConfig.type;
        if (label.format === undefined) defaultConfig.format;
        if (label.hide === undefined) label.hide = defaultConfig.hide;
        return label;
      } else {
        const ret = {
          en_us: key,
          config: defaultConfig,
        };

        // console.debug(`Key does not exist in labels: ${key}. Defaulting to`, ret);
        return ret;
      }
    },
    // This is a convenience function that logs to console if a config is not found
    getLabelConfig: function (key) {
      const label = this.getLabelByKey(key, {
        type: "string",
        format: null,
        hide: false,
        defaulted: true,
      });

      if (label.config.defaulted) {
        delete label.config.defaulted;
        console.debug(
          `Key ${key} exists in labels, but no config exists for it. Defaulting to`,
          label.config
        );
      }
      return label.config;
    },
    // TODO: Factor out to another utility
    userHasRightAsync: function (action) {
      console.error(
        "FIXME: Actually check user permissions rather than allowing everything."
      );
      return true;
      if (action !== "create" && action !== "edit" && action !== "remove")
        return false;
      if (this.getUserType === "1") return true;
    },
  },
};

export default TranslationMixin;
