<template>
  <div>
    <summary-container
      :headerTitle="getTranslation('pregCheckPage.lastCalvings.name')"
    >
      <template #content>
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template #item.offspringTags="{ item }">
            <router-link
              :to="{
                name: 'AnimalDetails',
                query: { id: item.animalId },
              }"
              class="text-h6 text-none"
              target="_blank"
            >
              <p>{{ item.offspringTags }}</p>
            </router-link>
          </template>
        </v-data-table>
      </template>
    </summary-container>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import SummaryContainer from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "last-calvings-table",
  components: {
    "summary-container": SummaryContainer,
  },
  mixins: [TranslationMixin],
  data: () => ({
    table: {
      headers: [
        { text: "Offspring Tags", value: "offspringTags" },
        { text: "Sex", value: "sex" },
        { text: "Birth Date", value: "birthDate" },
        { text: "Birth Weight", value: "birthWeight" },
        { text: "Status", value: "status" },
        { text: "Wean Weight", value: "weanWeight" },
      ],
      items: [],
    },
  }),
  created: function () {
    EventBus.$on("new-search", (animalID) => {
      this.getAnimalCalvings(animalID)
        .then((calvings) => {
          if (calvings.length > 0) {
            this.table.items = calvings.splice(0, 2);
          } else this.table.items = [];
        })
        .catch(() => (this.table.items = []));
    });
  },
  beforeDestroy: function () {
    EventBus.$off("new-search");
  },
  methods: {
    getAnimalCalvings: function (animalID) {
      return new Promise((resolve, reject) => {
        this.$herdMeta.loaded.done(() => {
          new Animal(animalID, this.$herdMeta, null, this.$userID)
            .getCalvings()
            .catch((e) => {
              console.error(e);
              reject(new Error("Animal not found or new"));
            })
            .then(resolve);
        });
      });
    },
  },
};
</script>