<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <v-alert type="error" color="red lighten-2" dismissible v-if="false">
          You should only purge animals that are not referenced by other animals
          unless you are okay with losing that relationship. For example, child
          C references parent P1. Parent P1 was merged to parent P2. Although a
          merge occurred, child C still references parent P1. P1 still exists
          but is marked as deleted. If you open it, it says it has been merged
          to P2. By deleting parent P1, child C will now reference a purged,
          non-existent record even though the real parent, P2, exists. Ideally,
          child C and all siblings need to be updated to reference P2 instead of
          referencing P1 before you can safely purge P1.
        </v-alert>
        <v-alert type="error" color="red lighten-2" dismissible>
          Purge is not reversible. You should only run it after you KNOW you
          have completely synced the organization's database.
        </v-alert>
        <v-alert
          color="info accent-4"
          dismissible
          type="info"
          v-if="purgeCount > 100"
        >
          Page reload is suggested after purging so many animals. You can do
          this after you finish purging animals.
          <v-btn @click="$router.go()"> Reload </v-btn>
        </v-alert>
        <v-alert type="info" color="info accent-4" dismissible>
          Purge is a feature which helps keep the database small. This can
          decrease latency on some pages.
        </v-alert>
        <v-alert type="info" color="info accent-4" dismissible>
          Purge is not allowed for animals that are referenced as parent of
          non-deleted animals.
        </v-alert>
        <div class="d-flex justify-end mb-2">
          <v-text-field
            clearable
            dense
            hide-details
            label="Search"
            outlined
            style="max-width: 300px"
            v-model="summaryTable.searchDeleted"
          ></v-text-field>
        </div>
        <v-data-table
          :custom-sort="customSort"
          :headers="summaryTableHeaders"
          :items="summaryTable.deletedAnimals"
          :loading="summaryTable.loading"
          :search="summaryTable.searchDeleted"
          class="elevation-1"
          group-by="date"
          group-desc
          mobile-breakpoint="0"
          must-sort
        >
          <template #group.header="{ group, items }">
            <td>Total: {{ items[0].total }}</td>
            <td class="subtitle-1 font-weight-bold">{{ group }}</td>
            <td></td>
            <td>
              <v-btn
                :color="
                  items.some(
                    ({ date }) =>
                      $moment().startOf('day').diff($moment(date), 'months') <=
                      1
                  )
                    ? 'primary'
                    : 'error'
                "
                :disabled="
                  items.some(
                    ({ date }) =>
                      $moment().startOf('day').diff($moment(date), 'months') > 1
                  )
                "
                @click="restoreGroup(group)"
                class="white--text"
                elevation="2"
                style="min-width: 36px"
              >
                <v-icon>mdi-arrow-up-bold</v-icon>{{ items[0].total }}
              </v-btn>
            </td>
            <td>
              <v-btn
                :color="
                  items.some(
                    ({ date }) =>
                      $moment().startOf('day').diff($moment(date), 'months') >=
                      1
                  )
                    ? 'primary'
                    : 'error'
                "
                :disabled="!groupHasPurgeableAnimals(group)"
                @click="purgeGroup(group)"
                class="white--text"
                elevation="2"
                style="min-width: 36px"
              >
                <v-icon>mdi-delete-sweep</v-icon>{{ items[0].total }}
              </v-btn>
            </td>
          </template>
          <template #item.tagValues="{ item }">
            <router-link
              :to="{ name: 'AnimalDetails', query: { id: item.guid } }"
              style="text-decoration: none"
              target="_blank"
            >
              {{ item.tagValues }}
            </router-link>
          </template>
          <template #item.deletedOn="{ item }">
            <div>
              {{ $utils.renderValueAs(item.deletedOn, "datetime", true) }}
            </div>
          </template>
          <template #item.children="{ item }">
            {{ item.children.length }}
          </template>
          <template #item.deleted="{ item }">
            <v-btn
              :color="
                $moment().startOf('day').diff($moment(item.date), 'months') >= 1
                  ? 'error'
                  : 'primary'
              "
              :disabled="
                $moment().startOf('day').diff($moment(item.date), 'months') > 1
              "
              @click="restoreAnimal(item.guid)"
              class="white--text"
              elevation="2"
              style="min-width: 36px"
            >
              <v-icon>mdi-delete-restore</v-icon>
            </v-btn>
          </template>
          <template #item.purgeable="{ item }">
            <v-btn
              :color="
                $moment().startOf('day').diff($moment(item.date), 'months') <= 1
                  ? 'error'
                  : 'primary'
              "
              :disabled="item.children.length > 0"
              @click="purgeAnimal(item.guid)"
              class="white--text"
              elevation="2"
              style="min-width: 36px"
            >
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TranslationMixin from "../mixins/Translations";
export default {
  name: "DbCleanup",
  metaInfo: {
    title: "DB Cleanup",
  },
  mixins: [TranslationMixin],
  data() {
    return {
      dataGroupedByDate: [],
      herdMeta: null,
      pouches: null,
      purgeCount: 0,
      summaryTable: {
        deletedAnimals: [],
        loading: false,
        searchDeleted: null,
      },
    };
  },
  computed: {
    summaryTableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.summary.tagValues"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("Deleted On"),
          value: "deletedOn",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          sortable: false,
          text: "Linked Animals",
          value: "children",
        },
        {
          sortable: false,
          text: "Restore",
          value: "deleted",
        },
        {
          sortable: false,
          text: "Purge",
          value: "purgeable",
        },
      ];
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    customSort: function (items, _, sortDesc) {
      const deletedSortState = sortDesc[1];
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (deletedSortState) return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
        return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
      });
    },
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      this.getPurgablesAndPopulateTable();
    },
    getCalvingsPerParent: async function (keys) {
      const childrenPerParentById = {};

      await this.herdMeta.pouches.organization
        .query("local_views/calvingsPerParent", { group_level: 1, keys })
        .then((results) => {
          results.rows.forEach((item) => {
            if (!childrenPerParentById[item.key])
              childrenPerParentById[item.key] = [];

            childrenPerParentById[item.key] = childrenPerParentById[
              item.key
            ].concat(item.value);
          });
        })
        .catch(console.error);

      return childrenPerParentById;
    },
    getPurgablesAndPopulateTable: function () {
      this.summaryTable.loading = true;

      this.pouches.organization
        .query("local_views/purgeables", { conflicts: true })
        .then(async (results) => {
          this.dataGroupedByDate = this.sortAndGroupByDay(
            results.rows.map(({ value }) => ({ ...value }))
          );
          const childrenPerParentById = await this.getCalvingsPerParent(
            results.rows.map(({ key }) => key)
          );

          this.summaryTable.deletedAnimals = await this.getSummaryTableData(
            childrenPerParentById
          );
          this.summaryTable.loading = false;
        });
    },
    getSummaryTableData: function (childrenPerParentById) {
      return this.dataGroupedByDate
        .reduce((previous, { date, items }) => {
          items.forEach((item) => {
            item.date = date;
            item.children = childrenPerParentById[item.guid] || [];
            item.total = items.length;

            previous.push(item);
          });
          return previous;
        }, [])
        .sort((a, b) => {
          const dateA = new Date(a.deletedOn);
          const dateB = new Date(b.deletedOn);
          return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
        });
    },
    groupHasPurgeableAnimals: function (groupDate) {
      const { items } = this.dataGroupedByDate.find(
        ({ date }) => date == groupDate
      );
      const exists = items.some((item) => item.children.length === 0);
      return exists;
    },
    purgeAnimal: async function (guid) {
      const userPermission = confirm(
        "Are you sure you want to purge this animal? This action is not reversible."
      );
      if (!userPermission) return;
      const doc = await this.pouches.organization.get(guid);
      await this.pouches.organization.remove(doc);
      this.purgeCount++;

      this.getPurgablesAndPopulateTable();
    },
    purgeGroup: function (groupDate) {
      const userPermission = confirm(
        "Are you sure you want to purge this group? This action is not reversible."
      );
      if (!userPermission) return;
      const { items } = this.dataGroupedByDate.find(
        ({ date }) => date == groupDate
      );
      const itemsPurge = items
        .filter((item) => item.children.length === 0)
        .map((item) => {
          return {
            _deleted: true,
            _id: item.guid,
            _rev: item._rev,
          };
        });
      this.purgeCount += itemsPurge.length;

      this.pouches.organization
        .bulkDocs(itemsPurge)
        .then(() => {
          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Group purged",
          });
          this.getPurgablesAndPopulateTable();
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "An error occurred",
          });
          console.error(e);
        });
    },
    restoreAnimal: async function (guid) {
      const userPermission = confirm(
        "Are you sure you want to restore this animal?"
      );
      if (!userPermission) return;
      const doc = await this.pouches.organization.get(guid);
      doc.deleted = false;

      await this.pouches.organization.put(doc);

      this.getPurgablesAndPopulateTable();
    },
    restoreGroup: async function (groupDate) {
      const userPermission = confirm(
        "Are you sure you want to restore this group?"
      );
      if (!userPermission) return;
      const { items } = this.dataGroupedByDate.find(
        ({ date }) => date == groupDate
      );

      const keys = items.map(({ guid }) => guid);
      const docs = (
        await this.pouches.organization.allDocs({
          keys: keys,
          include_docs: true,
        })
      ).rows.map((row) => {
        row.doc.deleted = false;
        return row.doc;
      });

      this.pouches.organization
        .bulkDocs(docs)
        .then(() => {
          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Group restored",
          });
          this.getPurgablesAndPopulateTable();
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "An error occurred",
          });
          console.error(e);
        });
    },
    sortAndGroupByDay: function (rows) {
      return rows
        .sort((a, b) => {
          const dateA = new Date(a.deletedOn);
          const dateB = new Date(b.deletedOn);
          return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
        })
        .reduce((previous, current) => {
          const currentDate = new Date(current.deletedOn)
            .toISOString()
            .split("T")[0];
          if (!previous.some(({ date }) => date == currentDate)) {
            previous.push({
              date: currentDate,
              items: [current],
            });
          } else {
            const actualItem = previous[previous.length - 1];
            actualItem.items.push(current);
          }
          return previous;
        }, []);
    },
  },
};
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>