<template>
  <summary-card :total="total" link="/animals" title="Marketable Animals">
    <template #append-title>
      <div class="d-flex ml-2">
        <div class="w-8 d-flex align-center justify-end">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  justify-center
                  lighten-4
                  mr-1
                  rounded-circle
                  success
                "
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>No errors/warning detected during computation.</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  grey
                  justify-center
                  lighten-4
                  mr-1
                  rounded-circle
                "
                color="grey"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span> Data shows head with statuses: cull, marketable </span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </summary-card>
</template>

<script>
export default {
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      total: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const thresholdTotal = moment()
        .startOf("day")
        .subtract(1, "year")
        .toISOString();
      const thresholdNew = moment()
        .startOf("day")
        .subtract(1, "year")
        .subtract(30, "days")
        .toISOString();

      const matches = (
        await this.pouches.organization.animalSummaries(false, false, {
          group_level: 1,
          include_docs: false,
        })
      ).rows
        .map(({ value }) => value)
        .map(({ main }) => main)
        .map(({ status }) => ({ status: (status || "alive").toLowerCase() }))
        .map(({ status }) => ({
          isCull: status === "cull",
          isStatusPresent: ["cull", "marketable"].some((s) => s === status),
          status,
        }))
        .filter(({ isCull, isStatusPresent }) => isCull || isStatusPresent);

      const countCull = matches.filter(({ isCull }) => isCull).length;
      const countNonCull = matches.filter(({ isCull }) => !isCull).length;

      this.total = `${countNonCull} + ${countCull} culls`;
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>

<style scoped>
.w-8 {
  width: 2.5rem;
}
.w-32 {
  width: 8rem;
}
</style>