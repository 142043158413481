<template>
  <div style="z-index: 203">
    <v-app-bar app class="white-header" fixed height="64" style="z-index: 203">
      <v-app-bar-title class="flex-shrink-1 flex-grow-0">
        <v-img
          :src="require('@/assets/logos/horizontal-logo.svg')"
          max-height="100"
          max-width="120"
        >
        </v-img>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <check-duplicates
        v-if="
          $vuetify.breakpoint.mdAndUp && totalAnimals && totalAnimals < 1000
        "
      />
      <div class="m-2" v-if="devicesConnected">
        <v-badge
          :color="
            devicesConnected === 'not valid'
              ? 'error'
              : devicesConnected.length === 0
              ? 'secondary'
              : 'primary'
          "
          :content="
            devicesConnected === 'not valid' || !devicesConnected.length
              ? '0'
              : devicesConnected.length
          "
          class="m-2"
          bordered
          left
          overlap
        >
          <v-btn
            :color="
              devicesConnected === 'not valid'
                ? 'error'
                : devicesConnected.length === 0
                ? 'default'
                : 'primary'
            "
            class="white--text text-decoration-none"
            depressed
          >
            <v-icon
              v-if="
                devicesConnected === 'not valid' || devicesConnected.length == 0
              "
              >mdi-cloud-alert</v-icon
            >
            <v-icon v-else>mdi-cloud-check</v-icon>
          </v-btn>
        </v-badge>
      </div>
      <v-btn
        class="pa-0 mr-2"
        color="#9b9b9b"
        max-width="36"
        min-width="36"
        @click="openSearchAnimalDialog"
      >
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
      <organization-switcher :animal-count="totalAnimals" />
      <template v-if="colorLed === 'error' || pouchSyncInfo === null">
        <v-icon
          :color="colorLed"
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mr-4 ml-6"
        >
          {{ statusConnection }}
        </v-icon>
      </template>
      <template v-if="pouchSyncInfo !== null">
        <v-icon
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mr-4 ml-6"
          color="error"
          v-if="speed === 'error'"
        >
          mdi-close
        </v-icon>
        <v-icon
          :color="pouchSyncInfoColor"
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mr-4 ml-6"
          v-else-if="pouchSyncInfo.direction === 'push'"
        >
          mdi-arrow-up
        </v-icon>
        <v-icon
          :color="pouchSyncInfoColor"
          @click="$herdMeta.notifyDBInfo($notify, speed)"
          class="mr-4 ml-6"
          v-else-if="pouchSyncInfo.direction === 'pull'"
        >
          mdi-arrow-down
        </v-icon>
      </template>
      <user-menu />
    </v-app-bar>
    <shared-drawer
      :show-organization-switcher="false"
      :show-user-menu="false"
    />
    <v-dialog
      max-width="400px"
      scrollable
      transition="dialog-transition"
      v-model="searchDialog.show"
    >
      <v-card>
        <v-card-title class="px-6 pb-0 d-flex justify-space-between">
          <h4 class="ma-0">Search Animal</h4>
          <v-icon @click="searchDialog.show = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider class="mb-0"></v-divider>
        <v-card-text class="pa-2 pt-0 pr-0" style="max-height: 500px">
          <search-animal
            :allowedStatuses="[
              'alive',
              'cull',
              'harvested',
              'inTransit',
              'lost',
              'marketable',
              'missing',
              'semen',
              'sick',
              'sold',
              'stolen',
            ]"
            :resetFields="searchDialog.resetFields"
            quickSearchMode
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VueOfflineMixin } from "vue-offline";
import HeaderMixin from "@/mixins/Header";
import OrganizationSwitcher from "@/components/headers/OrganizationSwitcher.vue";
import SharedDrawer from "@/components/headers/SharedDrawer.vue";
import TranslationMixin from "../../mixins/Translations";
import UserMenu from "@/components/headers/UserMenu.vue";

export default {
  name: "HorizontalHeader",
  mixins: [HeaderMixin, VueOfflineMixin, TranslationMixin],
  components: {
    OrganizationSwitcher,
    SharedDrawer,
    UserMenu,
  },
  data: () => ({
    colorLed: "",
  }),
  computed: {
    pouchSyncInfoColor: function () {
      switch (this.speed) {
        case "fast":
          return "success";
        case "medium":
          return "yellow";
        case "slow":
          return "orange";
        case "super slow":
          return "error";
        default:
          return "success";
      }
    },
    statusConnection: function () {
      if (this.isOnline) {
        this.colorLed = "success";
        return "mdi-wifi";
      } else {
        this.colorLed = "error";
        return "mdi-wifi-off";
      }
    },
  },
};
</script>
<style scoped>
.HoverButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.disable-button {
  color: #ffffff9a;
  pointer-events: none;
}
>>> .drawer.v-navigation-drawer.v-navigation-drawer--fixed {
  z-index: 4 !important;
}
.theme--dark .drawer {
  background: none;
}
>>> .drawer.v-navigation-drawer.v-navigation-drawer--fixed {
  z-index: 4 !important;
}
.theme--dark .drawer {
  background: none;
}
.animal-search {
  max-width: 450px;
}
>>> .theme--light.animal-search fieldset {
  border-color: rgba(0 0 0 / 0.2);
}
</style>
