var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu'),_c('v-row',{staticClass:"mx-4 mx-md-6 mx-lg-8 pt-20"},[_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","id":"app"}},[(_vm.isLoading)?_c('div',[_c('v-progress-circular',{attrs:{"size":"40","width":"5","color":"primary","indeterminate":""}})],1):_vm._e(),_c('div',{attrs:{"id":"body"}},[(_vm.errors.length == 0)?[(_vm.DAO.isSyncRecommended())?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("animalDetails.mergeDanger"))+" ")]):_vm._e(),(!_vm.DAO.isSyncRecommended())?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("animalDetails.mergeWarning"))+" ")]):_vm._e(),(_vm.DAO.isSyncRecommended())?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("animalDetails.mergeInstructions"))+" ")]):_vm._e()]:[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Some errors were encountered for the selected pair. The merge cannot be processed. Please consider reloading your source and retry. "),_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error.message)+" ")])}),0)])],_c('h1',[_vm._v("History")]),_c('div',{staticClass:"panel-group"},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body mb-4",class:{
                'bg-dark': _vm.$vuetify.theme.dark,
              }},[_c('h4',[_c('a',{attrs:{"data-toggle":"collapse","href":"#potentialDuplicatePairs"}},[_vm._v(" Potential Duplicates ")]),_vm._v(" - "),_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(_vm.table.data.length)+" potential duplicates remain ")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"potentialDuplicatePairs"}},[_c('v-data-table',{staticClass:"dashboard-data-table d-flex flex-column pb-6",attrs:{"headers":_vm.headers,"items":_vm.table.data,"loading":_vm.isLoading,"search":_vm.table.search,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"ml-auto d-flex flex-wrap justify-center"},[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","clearable":"","dense":"","hide-details":"","label":"Search","outlined":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1)])]},proxy:true},{key:"item.tagValues",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.tagValues)+" ")])])]}},{key:"item.animalATags",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                      name: 'AnimalDetails',
                      query: { id: item.animalA },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.animalATags)+" ")])]}},{key:"item.animalBTags",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                      name: 'AnimalDetails',
                      query: { id: item.animalB },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.animalBTags)+" ")])]}},{key:"item.details",fn:function(ref){
                    var item = ref.item;
return [_c('a',{staticClass:"btn btn-warning white--text mr-2",on:{"click":function($event){return _vm.compare(item.animalA, item.animalB)}}},[_vm._v("Compare")]),_c('a',{staticClass:"btn btn-success white--text",on:{"click":function($event){return _vm.resolveDuplicate(true, item.animalA, item.animalB, true)}}},[_vm._v("Merge Now")])]}}])})],1)]),_c('div',{staticClass:"panel panel-default hide"},[_c('div',{staticClass:"card card-body mb-4",class:{
                'bg-dark': _vm.$vuetify.theme.dark,
              }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#nonDuplicatePairs"}},[_vm._v("Non-duplicates")])])]),_c('div',{staticClass:"panel-collapse collapse",attrs:{"id":"nonDuplicatePairs"}},[_c('table',{staticClass:"table table-striped table-bordered dataTable",class:{
                  'table-dark-mode': _vm.$vuetify.theme.dark,
                  'table-dark': _vm.$vuetify.theme.dark,
                }},[_c('thead',[_c('tr',[_c('th',{staticClass:"animalA"},[_vm._v("Animal A")]),_c('th',{staticClass:"animalB"},[_vm._v("Animal B")]),_c('th',[_vm._v(_vm._s(_vm.getLabelTranslation("user")))]),_c('th')])]),_c('tbody',[(_vm.nonDuplicatePairs.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Nothing to show")])]):_vm._l((_vm.nonDuplicatePairs),function(nonDuplicatePair,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                          name: 'AnimalDetails',
                          query: { id: nonDuplicatePair.animalA },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(nonDuplicatePair.tagValuesA)+" ")])],1),_c('td',[_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                          name: 'AnimalDetails',
                          query: { id: nonDuplicatePair.animalB },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(nonDuplicatePair.tagValuesB)+" ")])],1),_c('td',[_vm._v(_vm._s(nonDuplicatePair.username))]),_c('td',[_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.compare(
                            nonDuplicatePair.animalA,
                            nonDuplicatePair.animalB
                          )}}},[_vm._v("Revisit")])])])})],2)]),_c('div',{staticClass:"panel-footer"},[_vm._v("   ")])])]),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body mb-4",class:{
                'bg-dark': _vm.$vuetify.theme.dark,
              }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#deduplicated"}},[_vm._v("Deduplicated")])])]),_c('div',{staticClass:"panel-collapse collapse",attrs:{"id":"deduplicated"}},[_c('table',{staticClass:"table table-striped table-bordered dataTable",class:{
                  'table-dark-mode': _vm.$vuetify.theme.dark,
                  'table-dark': _vm.$vuetify.theme.dark,
                }},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.getLabelTranslation("animalIds")))]),_c('th',[_vm._v(_vm._s(_vm.getLabelTranslation("user")))]),_c('th')])]),_c('tbody',[(_vm.deduplicateds.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Nothing to show")])]):_vm._l((_vm.deduplicateds),function(deduplicated,index){return _c('tr',{key:index},[_c('td',{staticClass:"individualAnimal"},[_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                          name: 'AnimalDetails',
                          query: { id: deduplicated.animalA },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(deduplicated.tagValues)+" ")])],1),_c('td',[_vm._v(_vm._s(deduplicated.username))]),_c('td',[_c('router-link',{staticClass:"text-h6 text-none",attrs:{"to":{
                          name: 'AnimalDetails',
                          query: { id: deduplicated.animalA },
                        },"target":"_blank"}},[_vm._v(" View ")])],1)])})],2)]),_c('div',{staticClass:"panel-footer"},[_vm._v("   ")])])])]),(!!_vm.pair[0])?_c('div',[_c('div',[_c('div',[_c('v-btn',{attrs:{"disabled":_vm.mergeIsBlocked,"color":"primary"},on:{"click":function($event){return _vm.resolveDuplicate(true, _vm.pair[0], _vm.pair[1])}}},[_vm._v("Merge match")])],1)]),_c('div',[_c('animal-details',{attrs:{"animalID":_vm.pair[0]},on:{"animal-changed":_vm.animalChanged}}),(_vm.pair[1])?_c('animal-details',{attrs:{"animalID":_vm.pair[1]},on:{"animal-changed":_vm.animalChanged}}):_vm._e()],1)]):_vm._e()],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }