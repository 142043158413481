<template>
  <div>
    <v-row class="d-flex flex-wrap mt-4">
      <v-col class="pb-0 pt-lg-0 pt-1" cols="12" lg="7">
        <v-text-field
          :background-color="statusBackgroundColor"
          class="mediumFont"
          clearable
          maxlength="15"
          outlined
          placeholder="127.0.0.1"
          prefix="http://"
          v-model="ipService.ip"
        >
          <template #label>
            <p class="ma-0 biggerFont">IP</p>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="pb-0 pt-0" cols="12" lg="5">
        <v-text-field
          :background-color="statusBackgroundColor"
          class="mediumFont"
          clearable
          max="55559"
          maxlength="5"
          min="55555"
          outlined
          placeholder="55555"
          prefix=":"
          type="number"
          v-model="ipService.port"
        >
          <template #label>
            <p class="ma-0 biggerFont">Port</p>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import axios from "axios";

export default {
  name: "HerdConnect",
  props: {
    streamDevices: {
      required: false,
      type: Function,
    },
    streamLF: {
      required: false,
      type: Function,
    },
    streamTSU: {
      required: false,
      type: Function,
    },
    streamUHF: {
      required: false,
      type: Function,
    },
    streamVisual: {
      required: false,
      type: Function,
    },
    streamWeight: {
      required: false,
      type: Function,
    },
  },
  data: () => ({
    apiReturn: {
      barcodes: [],
      data: {
        bufferCounts: {
          barcode: 0,
          id: 0,
          weight: 0,
        },
        status: "up",
      },
      devices: [],
      ids: [],
      status: null,
      weights: [],
    },
    intervals: [],
    ipService: {
      ip: "127.0.0.1",
      port: "55555",
    },
    polling: {
      barcodes: {
        active: false,
        polltime: null,
      },
      devices: {
        active: false,
        polltime: null,
      },
      ids: {
        active: false,
        polltime: null,
      },
      status: {
        active: false,
        polltime: null,
      },
      weights: {
        active: false,
        polltime: null,
      },
    },
    simulatedData: {
      enable: false, // set to true when testing without readers
      bypassWatches: false,
    },
  }),
  beforeDestroy: function () {
    EventBus.$emit("herd-connect-destroyed");
    this.intervals.forEach((interval) => clearInterval(interval));
  },
  created: function () {
    if (this.simulatedData.enable) {
      this.intervals.push(setInterval(this.createSimulatedData, 1000));
    }

    this.intervals.push(
      setInterval(() => {
        if (
          this.ipIsOutsideAllowedRange ||
          this.polling.status.active === true
        ) {
          return;
        }

        this.polling.status.active = true;
        axios
          .get(`http://${this.ipServiceFriendly}/status`, { timeout: 3000 })
          .then(({ status, data }) => {
            this.polling.status.active = false;
            this.apiReturn.status = status;
            this.apiReturn.data = data;

            // Force data watch to trigger on every call
            this.polling.status.polltime = new Date().toISOString();
          })
          .catch((e) => {
            this.polling.status.active = false;
            this.apiReturn.status = null;
          });
      }, 563)
    );

    const previousIPserviceSettings = JSON.parse(
      localStorage.getItem("ipService")
    );

    if (
      previousIPserviceSettings !== null &&
      this.ipService !== previousIPserviceSettings
    ) {
      this.ipService = previousIPserviceSettings;
    }
  },
  computed: {
    wasSuccessAtLastCall: function () {
      return this.apiReturn.status === 200;
    },
    statusBackgroundColor: function () {
      return !this.wasSuccessAtLastCall || this.ipIsOutsideAllowedRange
        ? "red"
        : "";
    },
    ipIsOutsideAllowedRange: function () {
      return (
        this.ipService.port &&
        (this.ipService.port < 55555 || this.ipService.port > 55559)
      );
    },
    ipServiceFriendly: function () {
      return `${this.ipService.ip || "127.0.0.1"}:${
        this.ipService.port || 55555
      }`;
    },
  },
  watch: {
    "apiReturn.barcodes": {
      deep: true,
      handler: function (barcodes) {
        if (barcodes.length === 0) return;

        const latest = this.latestByScannedAt(barcodes, "tsu");
        if (this.streamTSU) {
          this.streamTSU("" + latest.value);
        }
      },
    },
    "apiReturn.devices": function (devices) {
      const mock = [
        {
          battery: 40,
          beep: false, // Whether the device beep is enabled
          connected: true,
          connectedDevice: {
            address: "00:00:00:00:00:00", // MAC Address
            name: "TSL Scanner 1", // Name
            type: "tsl", // mock, tsl, csl, srs2, allflex, fasttrack, or id5000
          },
          power: 300.0, // 0 - 300
          vibrate: true, // Whether the device vibration is enabled
        },
        {
          battery: 20,
          beep: true, // Whether the device beep is enabled
          connected: false,
          connectedDevice: {
            address: "00:00:00:00:00:01", // MAC Address
            name: "TSL Scanner 2", // Name
            type: "tsl", // mock, tsl, csl, srs2, allflex, fasttrack, or id5000
          },
          power: 100.0, // 0 - 300
          vibrate: true, // Whether the device vibration is enabled
        },
      ];
      EventBus.$emit("devices", this.simulatedData.enable ? mock : devices);
      if (!this.streamDevices) return;
      // TODO: Compare current devices with new ones. If a device was connected or disconnected, alert.
      this.streamDevices(devices);
    },
    "apiReturn.ids": {
      deep: true,
      handler: function (ids) {
        if (ids.length === 0) return;

        {
          const latest = this.latestByScannedAt(ids, "lf");
          if (latest && latest.type === "lf" && this.streamLF) {
            this.streamLF("" + latest.value);
          }
        }

        {
          const latest = this.latestByScannedAt(ids, "uhf");
          if (latest && latest.type === "uhf" && this.streamUHF) {
            this.streamUHF("" + latest.value);
          }
        }

        {
          const latest = this.latestByScannedAt(ids, "visual");
          if (latest && latest.type === "visual" && this.streamVisual) {
            this.streamVisual("" + latest.value);
          }
        }
      },
    },
    "apiReturn.status": function (status) {
      // Nothing to do here for now.
    },
    "apiReturn.weights": {
      deep: true,
      handler: function (weights) {
        if (weights.length === 0) return;

        const latest = this.latestByScannedAt(weights, null);
        if (this.streamWeight) {
          this.streamWeight("" + latest.value);
        }
      },
    },
    ipService: {
      deep: true,
      handler: function (ipService) {
        localStorage.setItem("ipService", JSON.stringify(ipService));
      },
    },
    "polling.status.polltime": {
      deep: true,
      handler: function () {
        if (!this.wasSuccessAtLastCall || this.ipIsOutsideAllowedRange) {
          return;
        }

        const endpoints = [
          // We always monitor devices so components such as the ones in the header work even if no subscriptions exist
          {
            endpoint: "devices",
            includeAfter: false,
          },
        ];
        if (
          !this.streamLF &&
          !this.streamTSU &&
          !this.streamUHF &&
          !this.streamVisual &&
          !this.streamWeight
        ) {
          console.error(
            "Herd Connect is called, but caller is not subscribed to any streams. If the page is being developed, then this is expected."
          );
        }

        if (this.apiReturn.data.bufferCounts.barcode > 0) {
          if (!this.streamTSU) {
            console.log(
              `VT queue for /barcodes has ${this.apiReturn.data.bufferCounts.barcode} values. The current page is not subscribed to stream TSU data, therefore, Herd Connect will not poll to retrieve them.`
            );
          } else {
            endpoints.push({
              endpoint: "barcodes",
              includeAfter: true,
            });
          }
        }
        if (this.apiReturn.data.bufferCounts.id > 0) {
          if (!this.streamLF && !this.streamUHF && !this.streamVisual) {
            console.log(
              `VT queue for /id has ${this.apiReturn.data.bufferCounts.id} values. The current page is not subscribed to stream ID data, therefore, Herd Connect will not poll to retrieve them.`
            );
          } else {
            endpoints.push({
              endpoint: "ids",
              includeAfter: true,
            });
          }
        }
        if (this.apiReturn.data.bufferCounts.weight > 0) {
          if (!this.streamWeight) {
            console.log(
              `VT queue for /weights has ${this.apiReturn.data.bufferCounts.weight} values. The current page is not subscribed to stream weight data, therefore, Herd Connect will not poll to retrieve them.`
            );
          } else {
            endpoints.push({
              endpoint: "weights",
              includeAfter: true,
            });
          }
        }

        endpoints.forEach(({ endpoint, includeAfter }) => {
          if (this.polling[endpoint].active === true) {
            return;
          }

          this.polling[endpoint].active = true;
          axios
            .get(`http://${this.ipServiceFriendly}/${endpoint}`, {
              params:
                !includeAfter || this.polling[endpoint].polltime === null
                  ? null
                  : {
                      after: this.polling[endpoint].polltime.toISOString(),
                    },
              timeout: 3000,
            })
            .then(({ status, data }) => {
              this.polling[endpoint].active = false;

              if (status !== 200) {
                this.apiReturn[endpoint] = [];
                return;
              }

              if (includeAfter) {
                // update polltime
                const latest = this.latestByScannedAt(data, null);
                if (latest) {
                  this.polling[endpoint].polltime = moment(latest.scannedAt)
                    // VT API is inclusive, therefore we add 1 millisecond
                    .add(1, "millisecond");
                }
              }

              this.apiReturn[endpoint] = data;
            })
            .catch((e) => {
              this.polling[endpoint].active = false;
              console.error("failed to poll for data from Herd Connect");
              console.error(e);
            });
        });
      },
    },
  },
  methods: {
    // This is safe in isolation, but it is the case that some users connect to a single VT device with multiple tabs or devices and therefore it is not safe to clear this regularly the Herd Connect API can accept a `before` datetime for filtering
    _clearDataForType: function (type, before) {
      if (this.simulatedData.enable) return;

      axios
        .delete(`http://${this.ipServiceFriendly}/${type}`, null, {
          before: before,
        })
        .then(() => {})
        .catch((e) => {
          if (type === "ids")
            console.error(
              "Failed to delete for data from Herd Connect for type. We filter to consume only the latest, but failure here means that if the user tries to re-read in a tag again, it will not be selected by fast herd for use.",
              type
            );
          else console.warn("Failed to delete for data from apiReturn.", type);
          console.error(e);
        });
    },
    createSimulatedData: function () {
      if (this.simulatedData.bypassWatches) {
        // alternate method using hard-coded values
        // To simulate herdConnect processes
        setTimeout(() => {
          if (this.streamLF || this.streamUHF) {
            this.streamLF("840003011027745");
            this.streamUHF("840003011027745");
          }
          if (this.streamVisual) this.streamVisual("016 Yellow");
        }, 20);

        setTimeout(() => {
          if (this.streamLF || this.streamUHF) {
            this.streamLF("840003003471765");
            this.streamUHF("840003003471765");
          }
          if (this.streamVisual) this.streamVisual("unseen");
          if (this.streamWeight) this.streamWeight("200");
        }, 600);

        return;
      }

      this.apiReturn.barcodes = [
        {
          scannedAt: new Date().toISOString(),
          type: "tsu",
          value: "NE01" + Math.floor(Math.random() * 100000),
        },
      ];

      this.apiReturn.ids = [
        {
          scannedAt: new Date().toISOString(),
          type: "uhf",
          value: "840" + Math.floor(Math.random() * 1000000000000),
        },
        {
          scannedAt: new Date().toISOString(),
          type: "lf",
          value: "999" + Math.floor(Math.random() * 1000000000000),
        },
        {
          scannedAt: new Date().toISOString(),
          type: "flex",
          value: "3" + Math.floor(Math.random() * 1000),
        },
        {
          scannedAt: new Date().toISOString(),
          type: "visual",
          value:
            Math.random() > 0.5
              ? "asdf"
              : "dummyViz-" + new Date().toISOString(),
        },
      ];

      this.apiReturn.weights = [
        {
          scannedAt: new Date().toISOString(),
          value: 300 + Math.floor(Math.random() * 100),
        },
      ];
    },
    latestByScannedAt: function (array, type) {
      return (
        array
          .map((item) => {
            item.scannedAtUnix = moment(item.scannedAt).unix();
            return item;
          })
          .filter((item) => type === null || item.type === type)
          .sort((a, b) => b.scannedAtUnix - a.scannedAtUnix)
          // select first
          .find((item) => item)
      );
    },
  },
};
</script>