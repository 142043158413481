var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu',{attrs:{"activeBadge":_vm.countForGroupNumber}}),_c('div',{staticClass:"desktop-g-row mb-lg-3",staticStyle:{"margin-top":"70px"}},[_c('div',{class:['desktop-g-col', 'px-7']},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default force-w-100"},[_c('div',{staticClass:"card card-body mb-4",class:{
            'bg-dark': _vm.$vuetify.theme.dark,
          }},[_vm._m(0)])]):_vm._e(),_c('pregnancy-test',{class:['tablet-row', _vm.$isMobileScreen && 'collapse'],attrs:{"id":"entry"}})],1),_c('div',{staticClass:"desktop-g-col px-7"},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default force-w-100"},[_c('div',{staticClass:"card card-body mb-4",class:{
            'bg-dark': _vm.$vuetify.theme.dark,
          }},[_vm._m(1)])]):_vm._e(),_c('group-summary',{class:['tablet-row', _vm.$isMobileScreen && 'collapse'],attrs:{"id":"group"}}),(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default force-w-100"},[_c('div',{staticClass:"card card-body mb-4",class:{
            'bg-dark': _vm.$vuetify.theme.dark,
          }},[_vm._m(2)])]):_vm._e(),_c('cow-information',{class:['tablet-row', _vm.$isMobileScreen && 'collapse'],attrs:{"id":"information"}}),(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default force-w-100"},[_c('div',{staticClass:"card card-body mb-4",class:{
            'bg-dark': _vm.$vuetify.theme.dark,
          }},[_vm._m(3)])]):_vm._e(),_c('last-calvings-table',{class:['tablet-row', _vm.$isMobileScreen && 'collapse'],attrs:{"id":"last"}})],1)]),(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default force-w-100 px-7"},[_c('div',{staticClass:"card card-body mb-4",class:{
        'bg-dark': _vm.$vuetify.theme.dark,
      }},[_vm._m(4)])]):_vm._e(),_c('summary-table',{class:['tablet-row', _vm.$isMobileScreen && 'collapse'],attrs:{"id":"summary"}}),_c('v-dialog',{attrs:{"max-width":"280","persistent":"","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.showAlerts),callback:function ($$v) {_vm.showAlerts=$$v},expression:"showAlerts"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',{staticClass:"text-h5 warning"},[_c('v-icon',[_vm._v("mdi-alert")]),_c('div',{staticClass:"ml-2"},[_vm._v("Alert")])],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center pa-0",staticStyle:{"height":"70px","width":"100%"}},[_c('div',{staticClass:"text-h5 text--primary"},[_vm._v("Cull status detected")])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.dark ? 'teal accent-2' : 'teal darken-1',"text":""},on:{"click":function($event){_vm.showAlerts = false}}},[_vm._v(" Okay ")])],1)],1)],1),(_vm.getStatus.toLowerCase() == 'cull')?_c('v-fab-transition',[_c('v-btn',{key:'showAlerts',staticClass:"v-btn--example",attrs:{"bottom":"","color":"warning","dark":"","elevation":"5","fab":"","fixed":"","large":"","right":""},on:{"click":function($event){_vm.showAlerts = true}}},[_c('v-icon',[_vm._v("mdi-alert")])],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#entry"}},[_vm._v(" Pregnancy Test Entry ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#group"}},[_vm._v(" Group Summary ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#information"}},[_vm._v(" Cow Information ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#last"}},[_vm._v(" Last Calvings ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#summary"}},[_vm._v(" Summary ")])])}]

export { render, staticRenderFns }