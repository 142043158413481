<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M10.1594 17.9756C14.0509 17.9756 17.2056 14.8209 17.2056 10.9294C17.2056 7.03797 14.0509 3.8833 10.1594 3.8833C6.26795 3.8833 3.11328 7.03797 3.11328 10.9294C3.11328 14.8209 6.26795 17.9756 10.1594 17.9756ZM11.4172 7.40674C11.4172 6.71183 10.8538 6.1485 10.1589 6.1485C9.46403 6.1485 8.90069 6.71183 8.90069 7.40674V10.9298C8.90069 11.2635 9.03326 11.5836 9.26922 11.8195L11.7604 14.3107C12.2518 14.8021 13.0485 14.8021 13.5398 14.3107C14.0312 13.8193 14.0312 13.0227 13.5398 12.5313L11.4172 10.4086V7.40674Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
