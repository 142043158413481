<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
      <path
        :stroke="fill"
        d="M15.6253 18.2083H20.8337L19.3702 16.7449C18.9733 16.348 18.7503 15.8097 18.7503 15.2484V11.9583C18.7503 9.23705 17.0111 6.92197 14.5837 6.06397V5.70833C14.5837 4.55774 13.6509 3.625 12.5003 3.625C11.3497 3.625 10.417 4.55774 10.417 5.70833V6.06397C7.98951 6.92197 6.25033 9.23705 6.25033 11.9583V15.2484C6.25033 15.8097 6.02735 16.348 5.63046 16.7449L4.16699 18.2083H9.37533M15.6253 18.2083V19.25C15.6253 20.9759 14.2262 22.375 12.5003 22.375C10.7744 22.375 9.37533 20.9759 9.37533 19.25V18.2083M15.6253 18.2083H9.37533"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
