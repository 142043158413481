import { mapActions } from "vuex";
import axios from "axios";
import Cookies from "@/utils/constants/cookies";
import UsersConstants from "@/utils/constants/users";

const AuthenticationExtension = {
  methods: {
    ...mapActions({
      resetVuexCache: "reset",
      setDarkMode: "User/setDarkMode",
      setInitialOrganizationID: "Organization/setInitialOrganizationID",
      setInitialUserID: "User/setInitialUserID",
      setOrganizationID: "Organization/setOrganizationID",
      setOrganizationName: "Organization/setName",
      setPouchPass: "User/setPouchPass",
      setUserEmail: "User/setUserEmail",
      setUserID: "User/setUserID",
      setUserLocale: "User/setLocale",
      setUserName: "User/setName",
      setUserSuperAdmin: "User/setSuperAdmin",
      setUserTimezone: "User/setTimezone",
      setUserToken: "User/setToken",
      setUserType: "User/setType",
    }),
    signIn: async function () {
      try {
        const { status, data } = await axios.post("/api/auth/login", null, {
          params: {
            username: this.username,
            password: this.password,
          },
        });
        if (status == 200) {
          // Remove legacy cookies (only necessary until a while after coldfusion-based APIs are gone)
          // TODO: Remove these lines once coldfusion-based APIs are gone
          this.$cookies.remove(Cookies.AUTH_SESSION);
          this.$cookies.remove(Cookies.CFID);
          this.$cookies.remove(Cookies.CFTOKEN);

          // this.$cookies.remove(Cookies.LOCALE);
          this.$cookies.remove(Cookies.ORGANIZATION_NAME);
          // this.$cookies.remove(Cookies.ORGANIZATIONID);
          // this.$cookies.remove(Cookies.POUCHPASS);
          // this.$cookies.remove(Cookies.SUPERADMIN);
          // this.$cookies.remove(Cookies.TIMEZONE);
          // this.$cookies.remove(Cookies.TOKEN);
          // this.$cookies.remove(Cookies.USERID);
          this.$cookies.remove(Cookies.USERNAME);
          this.resetVuexCache();

          const { email, locale, pouchPass, superadmin, timezone, token } =
            data;

          this.setUserType({
            type: superadmin ? UsersConstants.ADMIN : UsersConstants.COMMON,
          });
          this.setPouchPass({ pouchPass });

          const sortedPages = [
            "bullTest",
            "commentInField",
            "intake",
            "offspring",
            "pregCheck",
          ];
          sortedPages.forEach((page) =>
            localStorage.setItem(`${page}.sorted`, null)
          );
          const darkMode =
            localStorage.getItem(`darkmode_${data.userID || data.userId}`) ==
            "true"
              ? true
              : false;
          this.$vuetify.theme.dark = darkMode;
          this.setUserName({
            name:
              data.aliasName ||
              [data.firstName, data.lastName].join(" ") ||
              email.split("@")[0],
          });
          this.setDarkMode({ darkMode });
          this.setInitialOrganizationID({
            organizationID: data.organizationId,
          });
          this.setOrganizationID({ organizationID: data.organizationId });
          this.setOrganizationName({ name: data.organizationName });
          this.setInitialUserID({ userID: data.userID || data.userId });
          await this.setUserID({ userID: data.userID || data.userId });
          this.setUserToken({ token });
          this.setUserEmail({ email });
          this.setUserLocale({ locale });
          // Get User Timezone before to set stored timezone
          // Because the user can change of location but the stored timezone
          // is the same
          // In case that user blocks timezone information or mysql does not have
          // any timezone stored use "America/Denver" (Utah) by default
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          this.setUserTimezone({
            timezone: userTimezone || timezone || "America/Denver",
          });
          await this.setUserSuperAdmin({ superAdmin: superadmin });
          this.$router.replace({ path: "/dashboard" }).catch(console.log);
        } else {
          this.showToast = true;
        }
      } catch (err) {
        if (err && err.response && err.response.data)
          console.log(err.response.data);
        console.log(err);
        this.showToast = true;
      }
    },
  },
};

export default AuthenticationExtension;
