<template>
  <v-dialog max-width="500" scrollable v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="pasture-and-pen-form"
            ref="pastureAndPenForm"
            v-model="formValid"
          >
            <v-text-field
              :rules="[required]"
              autocomplete="off"
              autofocus
              label="Name"
              required
              v-model.trim="pastureAndPen.alias"
            />
            <v-text-field
              autocomplete="off"
              label="Description"
              v-model.trim="pastureAndPen.description"
            />
            <v-text-field
              autocomplete="off"
              label="Size"
              v-model.trim="pastureAndPen.size"
            />
            <v-text-field
              autocomplete="off"
              label="Size Units"
              v-model.trim="pastureAndPen.sizeUnits"
            />
            <v-text-field
              autocomplete="off"
              label="Latitude"
              v-model.trim="pastureAndPen.latitude"
            />
            <v-text-field
              autocomplete="off"
              label="Longitude"
              v-model.trim="pastureAndPen.longitude"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="pasture-and-pen-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "PastureAndPenDialog",
  props: {
    herdMeta: {
      required: true,
      type: Object,
    },
    onCreate: {
      required: true,
      type: Function,
    },
  },
  mixins: [TranslationMixin],
  data() {
    return {
      formValid: false,
      pastureAndPen: {},
      showDialog: false,
      submitting: false,
    };
  },
  computed: {
    title() {
      return this.pastureAndPen.id
        ? "Update pasture and pen"
        : "Create pasture and pen";
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.pastureAndPenForm.resetValidation();
      }
    },
  },
  methods: {
    open(pastureAndPenToEdit = {}) {
      Object.assign(this.pastureAndPen, pastureAndPenToEdit);
      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    getData() {
      return {
        archived: this.pastureAndPen.archived || false,
        description: this.pastureAndPen.description || "",
        feedAlias: this.pastureAndPen.feedAlias || "",
        friendlyName: this.pastureAndPen.friendlyName || "",
        id: this.pastureAndPen.id,
        latitude: this.pastureAndPen.latitude || "",
        longitude: this.pastureAndPen.longitude || "",
        name: this.pastureAndPen.alias,
        rank: this.pastureAndPen.rank || "",
        size: this.pastureAndPen.size || "",
        sizeUnits: this.pastureAndPen.sizeUnits || "",
      };
    },
    async save() {
      if (!this.$refs.pastureAndPenForm.validate() || this.submitting) return;

      this.submitting = true;
      try {
        if (this.pastureAndPen.id) {
          await this.herdMeta.update("locations", this.getData(), "location");
        } else {
          await this.herdMeta.create("locations", this.getData(), "location");
          if (this.onCreate) {
            this.onCreate();
          }
        }
      } catch (e) {
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again."
        );
        this.submitting = false;
        return;
      }

      this.$emit("saved");
      this.showDialog = false;
    },
  },
};
</script>
