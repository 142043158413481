<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Harvest History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Harvest_History_${animal.guid}_${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
        v-if="animal.guid"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1 dashboard-data-table"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': animal.disallowChanges(),
          }"
          :disabled="animal.disallowChanges()"
          @click="deleteRecord(item)"
          class="pa-0"
          color="error"
          max-width="36"
          min-width="36"
          small
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "Harvest",
  mixins: [TranslationMixin],
  props: {
    animal: null,
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      broadcast: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("actions"),
          value: "actions",
          sortable: false,
        },
        { text: this.getTranslation("Carc ID"), value: "carcId" },
        { text: this.getTranslation("Group Number"), value: "groupNumber" },
        { text: this.getTranslation("Plant"), value: "plant" },
        { text: this.getTranslation("Kill Date"), value: "killDate" },
        { text: this.getTranslation("Kill Lot"), value: "killLot" },
        {
          text: this.getTranslation("Hot Scale Weight"),
          value: "hotScaleWeight",
        },
        { text: this.getTranslation("Carcass Age"), value: "carcassAge" },
        { text: this.getTranslation("Carcass Type"), value: "carcassType" },
        { text: this.getTranslation("Quality Grade"), value: "qualityGrade" },
        {
          text: this.getTranslation("Quality Grade 2"),
          value: "qualityGrade2",
        },
        { text: "OD", value: "od" },
        { text: "OD2", value: "od2" },
        { text: this.getTranslation("Yield Grade"), value: "yieldGrade" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      this.getHarvestHistory();
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      this.getHarvestHistory();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    getHarvestHistory: function () {
      this.table.loading = true;
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animal.guid,
        })
        .then(async (result) => {
          if (result.rows.length === 0) {
            this.table.data = [];
            return;
          }
          this.table.data = result.rows[0].value.carcassData || [];
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            group: "forms",
            text: "Error",
            title: "",
            type: "error",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteRecord: async function (item) {
      try {
        await this.animal.deleteRecord("carcassData", item.id);
        this.getHarvestHistory();
        this.$emit("data-editted");
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "Error",
          title: "",
          type: "error",
        });
      }
    },
  },
};
</script>
