var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('summary-card',{attrs:{"total":_vm.summaries[_vm.interval].total,"link":"/report-calf-wean-summary","title":"Births"},scopedSlots:_vm._u([{key:"append-title",fn:function(){return [_c('div',{staticClass:"d-flex ml-2"},[_c('v-select',{staticClass:"dashboard-select mr-4 rounded-lg w-32",attrs:{"items":_vm.intervals,"dense":"","elevation":"0","flat":"","hide-details":"true","label":"","solo":""},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}}),_c('div',{staticClass:"align-center d-flex justify-end w-12"},[(_vm.summaries[_vm.interval].countFemalesMissingBirthdates > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 mr-1 rounded-circle warning",attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,false,4110646250)},[_c('span',[_vm._v(" Some animals are missing a birth date. ("+_vm._s(_vm.summaries[_vm.interval].countFemalesMissingBirthdates)+") ")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex justify-center lighten-4 mr-1 rounded-circle success",attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}])},[_c('span',[_vm._v("No errors/warning detected during computation.")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"align-center d-inline-flex grey justify-center lighten-4 mr-1 rounded-circle",attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Data includes births, newly created records, and transfers. Maximum is defined as number of females head over 20 months old. ")])])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }