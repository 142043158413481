<template>
  <calf-container :headerTitle="getTranslation('calfWeanPage.summary.name')">
    <template #bottom-header>
      <v-subheader style="height: 20px" class="ma-0">
        {{ getTranslation("calfWeanPage.summary.description") }}
      </v-subheader>
    </template>
    <template #content>
      <v-row>
        <v-col>
          <v-row class="justify-end">
            <v-col cols="12" lg="3">
              <v-text-field
                clearable
                hide-details
                label="Search"
                prepend-icon="mdi-magnify"
                single-line
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="summaryTableHeaders"
                :items-per-page="5"
                :items="summaryTableData"
                :loading="loading"
                :search="search"
                :sort-by="['createdOn']"
                :sort-desc="[true]"
              >
                <template #item.tagValues="{ item }">
                  <router-link
                    :to="{
                      name: 'AnimalDetails',
                      query: { id: item.guid },
                    }"
                    class="text-h6 text-none"
                    target="_blank"
                  >
                    {{ item.tagValues }}
                  </router-link>
                </template>
                <template #item.weanDate="{ item }">
                  {{ $utils.renderValueAs(item.weanDate, "datetime", true) }}
                </template>
                <template #item.createdOn="{ item }">
                  {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </calf-container>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import ContainerVue from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "calf-wean-summary",
  mixins: [TranslationMixin],
  components: {
    "calf-container": ContainerVue,
  },
  data: () => ({
    loading: false,
    search: "",
    summaryTableData: [],
  }),
  computed: {
    summaryTableHeaders: function () {
      return [
        {
          text: this.getTranslation("calfWeanPage.summary.tagValues"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.tsuNumber"),
          value: "tsu",
        },
        {
          text: this.getTranslation("fields.receivingRanch"),
          value: "receivingRanch",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.breed"),
          value: "breed",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.sex"),
          value: "sex",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.weight"),
          value: "weight",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.weanDate"),
          value: "weanDate",
        },
        {
          text: this.getTranslation("calfWeanPage.summary.createdOn"),
          value: "createdOn",
        },
      ];
    },
    totalRows: function () {
      return this.summaryTableData.length;
    },
  },
  created: function () {
    this.getSummaryTable();
    EventBus.$on("new-calf-entry", () => {
      this.$herdMeta.reload().then(this.getSummaryTable).fail();
    });
  },
  methods: {
    getSummaryTable: function () {
      this.loading = true;
      this.$herdMeta.pouches.organization
        .query("local_views/weanWeightEntries", {
          group_level: 1,
        })
        .then((results) => {
          this.summaryTableData = results.rows.map((item) => item.value);
          this.loading = false;
        });
    },
  },
};
</script>