<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <template>
          <v-alert type="error" color="red lighten-2" dismissible>
            This tool was created for use with fixing data on animals that have
            been transferred. If you do not understand why this tool was made,
            you probably do not want to use it.
          </v-alert>
          <p>
            <span v-if="progress">
              <strong>Progress</strong>
              {{ progress }}
            </span>
            <span v-if="stats" class="ml-10">
              <strong>Stats</strong>
              {{ stats }}
            </span>
          </p>
          <p v-for="step in steps" :key="step.text">
            <v-btn
              :class="{
                warning:
                  step.error ||
                  (step.precondition && steps[step.precondition].error)
                    ? 'warning'
                    : '',
              }"
              :disabled="
                (step.precondition && !steps[step.precondition].completed) ||
                step.running ||
                (step.completed && !step.canRepeat)
              "
              @click="runstep(step)"
            >
              <v-icon v-if="step.completed">mdi-check-circle-outline</v-icon>
              <v-icon v-else-if="step.running">mdi-reload</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
              {{ step.text }}
            </v-btn>
            <template v-if="step.error">
              {{ step.textError }}
            </template>
          </p>

          <h1 v-if="steps.stepConfirmSyncComplete.completed">
            Now repeat with another organization!
          </h1>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DbFix",
  metaInfo: {
    title: "DB Fix",
  },
  data() {
    return {
      animals: [],
      herdMeta: null,
      pouches: null,
      steps: {
        stepInitialMetaLoad: {
          cb: null,
          completed: false,
          fn: async () => {
            return new Promise((resolve, reject) => {
              this.herdMeta.loaded.fail(reject).then(resolve);
            });
          },
          precondition: null,
          running: false,
          text: "Load metadata",
        },
        stepConfirmSyncIsComplete: {
          canRepeat: true,
          cb: "stepReloadMetaData",
          completed: false,
          fn: null,
          precondition: "stepInitialMetaLoad",
          running: false,
          text: "Confirm sync is complete",
        },
        stepReloadMetaData: {
          canRepeat: false,
          cb: "stepIdentifyBrokenRecords",
          completed: false,
          fn: async () => {
            return new Promise((resolve, reject) => {
              this.herdMeta.reload().fail(reject).then(resolve);
            });
          },
          precondition: "stepConfirmSyncIsComplete",
          running: false,
          text: "Reload metadata",
        },
        stepIdentifyBrokenRecords: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: async () => {
            // get all docs
            return new Promise((resolve, reject) => {
              this.herdMeta.pouches.organization
                .query("local_views/animalsWithoutSummary")
                .then((results) => {
                  this.animals = results.rows.map((row) => ({
                    deleted: row.value.deleted,
                    fixed: false,
                    id: row.key,
                  }));
                  resolve();
                });
            });
          },
          precondition: "stepReloadMetaData",
          running: false,
          text: "Find broken records",
        },
        stepTurnOffWifi: {
          canRepeat: true,
          cb: "stepFixRecords",
          completed: false,
          fn: null,
          precondition: "stepIdentifyBrokenRecords",
          running: false,
          text: "Confirm: Turn off wifi",
        },
        stepFixRecords: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: async () => {
            const userID = this.$userID;
            for (let i = 0; i < this.animals.length; i++) {
              await new Promise((resolve, reject) => {
                const guid = this.animals[i].id;

                this.herdMeta.pouches.organization.get(guid).then((doc) => {
                  new Animal(guid, this.herdMeta, doc, userID)
                    .save(true)
                    .then(() => {
                      this.animals[i].fixed = true;
                      resolve();
                    });
                });
              });
            }
          },
          precondition: "stepTurnOffWifi",
          running: false,
          text: "Fix records",
        },
        stepInspectRecords: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: async () => {
            if (confirm("Everything look okay?")) return false;
            return true;
          },
          precondition: "stepFixRecords",
          running: false,
          text: "Inspect records",
          textError: "Please report oddities to Michael",
        },
        stepAcknowledgeOnline: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: null,
          precondition: "stepInspectRecords",
          running: false,
          text: "Confirm: Enable wifi",
        },
        stepConfirmSyncComplete: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: null,
          precondition: "stepAcknowledgeOnline",
          running: false,
          text: "Confirm: Sync completed",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
    }),
    totalBroken() {
      return this.animals.length;
    },
    totalFixed() {
      return this.animals.filter(({ fixed }) => fixed).length;
    },
    progress() {
      return this.totalBroken ? this.totalFixed + " / " + this.totalBroken : "";
    },
    totalDeleted() {
      return this.animals.filter(({ deleted }) => deleted).length;
    },
    totalNotDeleted() {
      return this.animals.filter(({ deleted }) => !deleted).length;
    },
    stats() {
      return this.totalBroken
        ? "deleted: " +
            this.totalDeleted +
            " / not deleted:" +
            this.totalNotDeleted
        : "";
    },
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    // FIXME: Find a way to avoid needing to do this
    this.herdMeta.setItems(this.getOrganizationItems, {
      as: "receivingRanches",
      id: "id",
    });

    this.runstep(this.steps.stepInitialMetaLoad);
  },
  methods: {
    runstep: async function (step) {
      step.running = true;
      if (step.fn) step.error = await step.fn();
      step.running = false;
      step.completed = true;

      if (!step.cb) {
        return;
      }

      this.runstep(this.steps[step.cb]);
    },
  },
};
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>