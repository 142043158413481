<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div>
          <div class="g-row mb-2">
            <!-- Controls -->
            <!-- <div class="g-col">
              <div class="g-row">
                <v-btn
                  @click="openDialog('create')"
                  color="primary"
                  depressed
                  text
                >
                  {{ getTranslation("New") }}
                </v-btn>
                <v-btn
                  :disabled="!table.selected.length"
                  @click="openDialog('edit')"
                  color="primary"
                  depressed
                  text
                >
                  {{ getTranslation("edit") }}
                </v-btn>
                <v-btn
                  :disabled="!table.selected.length"
                  color="primary"
                  depressed
                  text
                >
                  {{ getTranslation("delete") }}
                </v-btn>
              </div>
            </div> -->
            <!-- Search -->
            <!-- <div class="g-col align-end">
              <v-text-field
                class="custom-field"
                style="max-width: 200px"
                hide-details
                label="Search"
                outlined
                v-model="table.search"
              />
            </div> -->
          </div>
          <!--        <v-data-table
            :headers="tableHeaders"
            :items="table.data"
            :search="table.search"
            class="dashboard-data-table"
            hide-default-footer
            mobile-breakpoint="0"
            show-select
            v-model="table.selected"
          />-->

          <table
            id="sickListTable"
            class="table table-striped table-bordered w-100"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>User</th>
                <th>Location</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>User</th>
                <th>Location</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <online-warning></online-warning>
      </v-col>
    </v-row>
    <v-dialog
      max-width="400px"
      scrollable
      transition="dialog-transition"
      v-model="dialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Create New Entry</h4>
          <v-icon class="buttons" @click="dialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-1 px-4">
          <v-select
            :items="users"
            :label="getTranslation('user')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="custom-field mb-3"
            dense
            hide-details
            item-text="username"
            outlined
            v-model="dialog.form.user"
          />
          <v-select
            :items="locations"
            :label="getTranslation('location')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="custom-field mb-3"
            dense
            hide-details
            item-text="name"
            outlined
            v-model="dialog.form.location"
          />
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="updateRecord" color="success">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
export default {
  name: "SickListEmail",
  metaInfo: {
    title: "Sick List Email",
  },
  mixins: [TranslationMixin],
  data: () => ({
    dialog: {
      form: {
        user: null,
        location: null,
      },
      show: false,
    },
    herdMeta: null,
    locations: [],
    users: [],
    table: {
      data: [],
      search: null,
      selected: [],
    },
    editor: null,
  }),
  computed: {
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("user"),
          value: "username",
        },
        {
          text: this.getTranslation("location"),
          value: "name",
        },
      ];
    },
  },
  created: function () {
    this.init();
  },
  beforeDestroy: function () {
    $("#sickListTable").off();
    this.editor.destroy();
  },
  methods: {
    openDialog: function (action) {
      this.dialog.show = true;
      this.dialog.action = action;
    },
    updateRecord: function () {
      try {
        const action = this.dialog.action;
        const form = new FormData();
      } catch (error) {}
    },
    getUsers: async function () {
      try {
        const { data } = await axios.get(
          "/api/organization/users?includeCSRs=true",
          {
            params: {
              token: this.$token,
            },
          }
        );
        return data.users.map((user) => {
          if (!user.isSupport) return user;
          user.username += " (CSR)";
          return user;
        });
      } catch (error) {
        return [];
      }
    },
    getLocations: async function () {
      try {
        return await this.herdMeta.getMetaLocationsAsync(true, true, true);
      } catch (error) {
        return [];
      }
    },
    init: async function () {
      this.herdMeta = this.$herdMeta;
      this.users = await this.getUsers();
      this.locations = await this.getLocations();
      const self = this;

      let editor = new $.fn.dataTable.Editor({
        ajax: "/herd-api/api/sickListEmailsEdit.cfm",
        table: "#sickListTable",
        fields: [
          {
            label: "User",
            name: "username",
            type: "select",
            options: self.users.map((user) => {
              return {
                value: user.id,
                label: user.username,
              };
            }),
          },
          {
            label: "Location",
            name: "name",
            type: "select",
            options: self.locations.map((location) => {
              return {
                value: location.id,
                label: location.name,
              };
            }),
          },
        ],
      });
      editor.on("preCreate", function (e, json, data) {
        const locationOptions = self.locations.filter(
          (location) => location.id === data.locationId
        );

        const userOptions = self.users.filter(
          (user) => user.id === data.userId
        );
        data.username = userOptions[0].username || "empty";
        data.name = locationOptions[0].name || "empty";
      });

      editor.on("preEdit", function (e, json, data) {
        const locationOptions = self.locations.filter(
          (location) => location.id === data.locationId
        );

        const userOptions = self.users.filter(
          (user) => user.id === data.userId
        );
        data.username = userOptions[0].username || "empty";
        data.name = locationOptions[0].name || "empty";
      });
      this.editor = editor;
      this._makeTable(editor);
    },
    _makeTable: async function (editor) {
      const self = this;
      this.table.data = await this._getTableData();
      let table = $("#sickListTable").DataTable({
        data: self.table.data,
        destroy: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        autoWidth: false,
        buttons: [
          {
            className: "btn mb-2 text-white",
            extend: "create",
            editor: editor,
          },
          {
            className: "btn mb-2 text-white",
            extend: "edit",
            editor: editor,
          },
          {
            className: "btn mb-2 text-white",
            extend: "remove",
            editor: editor,
          },
        ],
        columns: [
          { data: "id", visible: false, searchable: false },
          { data: "username" },
          { data: "name" },
        ],
        colReorder: false,
        dom: "Bfrtip",
        processing: true,
        select: true,
        stateSave: true,
      });
    },
    _getTableData: async function () {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/organization/sickListEmails", {
            params: {
              token: this.$token,
            },
          })
          .then(({ data: results }) => {
            let tableData = results.sickListEmails
              .map((row) => {
                ["id", "name", "username"].forEach((column) => {
                  // This is only necessary to make datatables happier. It does not like to have undefined Values.
                  if (row[column]) return;
                  row[column] = null;
                });

                return row;
              })
              .map((row) => {
                // This is only necessary to make datatables happier. It wants a unique identifier.
                row.DT_RowId = row.id;
                return row;
              });

            tableData.forEach((sickListEmail) => {
              sickListEmail.name = "?";
              const locationOptions = this.locations.filter(
                (location) => location.id === sickListEmail.locationId
              );
              if (locationOptions.length === 0) return;
              sickListEmail.name = locationOptions[0].name;
            });
            tableData.forEach((sickListEmail) => {
              sickListEmail.username = "?";

              const userOptions = this.users.filter(
                (user) => user.id === sickListEmail.userId
              );
              if (userOptions.length === 0) {
                return;
              }
              sickListEmail.username = userOptions[0].username || null;
            });
            tableData = tableData.filter(
              (sickListEmail) => sickListEmail.name != "?"
            );
            resolve(tableData);
          })
          .catch((e) => {
            console.log("Error getting sick list emails", e);
            this.$notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: "Error occurred. Please try again.",
            });
            resolve([]);
          });
      });
    },
  },
};
</script>
