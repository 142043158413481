<template>
  <div>
    <v-dialog
      max-width="350px"
      :persistent="persistDialog"
      scrollable
      transition="dialog-transition"
      v-model="multipleResults.showDialog"
    >
      <v-card>
        <v-card-title class="px-6 pb-0 d-flex justify-space-between">
          <h4 class="ma-0">Multiple Results</h4>
          <v-icon
            v-if="!persistDialog"
            @click="multipleResults.showDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 120px" class="py-2 px-6">
          <v-select
            :items="multipleResults.options"
            :menu-props="{ offsetY: true }"
            clearable
            hide-details
            item-text="tagValues"
            label="Results"
            outlined
            return-object
            style="font-size: 1.3rem"
            v-model="multipleResults.resultSelected"
          >
          </v-select>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="persistDialog"
            @click="multipleResults.showDialog = false"
            color="primary"
            >Select</v-btn
          >
          <v-btn
            @click="forceUseNewAnimal"
            :disabled="!persistDialog"
            color="warning"
            >New Animal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <summary-container>
      <template #prepend-header>
        <div>
          <v-menu
            close-on-click
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="showCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :background-color="
                  dateValidation.danger
                    ? 'error'
                    : dateValidation.warning
                    ? 'warning'
                    : ''
                "
                append-icon="mdi-calendar"
                dense
                hide-details
                outlined
                readonly
                style="font-size: 1.3rem"
                v-bind="attrs"
                v-model="form.date"
                v-on="on"
              >
                <template #label>
                  <p
                    :class="{ darkInputText: $vuetify.theme.dark }"
                    class="ma-0"
                    style="font-size: 1.7rem"
                  >
                    {{
                      getTranslation("pregCheckPage.pregnancyTestEntry.date")
                    }}
                  </p>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @input="showCalendar = false"
              v-model="form.date"
              :max="maxPregDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
      <template #header>
        <div>
          <v-menu
            close-on-click
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="showBullCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :background-color="
                  dateBullTurninWarning.danger
                    ? 'error'
                    : dateBullTurninWarning.warning
                    ? 'warning'
                    : ''
                "
                append-icon="mdi-calendar"
                clearable
                dense
                hide-details
                outlined
                readonly
                style="font-size: 1.3rem"
                v-bind="attrs"
                v-model="form.bullTurninDate"
                v-on="on"
              >
                <template #label>
                  <p
                    :class="{ darkInputText: $vuetify.theme.dark }"
                    class="ma-0"
                    style="font-size: 1.7rem"
                  >
                    {{
                      getTranslation(
                        "pregCheckPage.pregnancyTestEntry.bullTurninDate"
                      )
                    }}
                  </p>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @input="showBullCalendar = false"
              v-model="form.bullTurninDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
      <template #append-header>
        <div>
          <v-text-field
            :disabled="lock.group"
            :error="!!formErrorState.group"
            :rules="[rules.required]"
            autofocus
            class="mediumFont"
            clearable
            dense
            hide-details
            outlined
            v-model="form.group"
          >
            <template #label>
              <div
                :class="{ darkInputText: $vuetify.theme.dark }"
                style="font-size: 1.9rem"
              >
                {{ getTranslation("pregCheckPage.pregnancyTestEntry.group") }}
              </div>
            </template>
            <template #append-outer>
              <v-icon @click="lock.group = !lock.group">
                {{ lock.group ? "mdi-lock" : "mdi-lock-open" }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </template>
      <template #content>
        <v-row>
          <v-col cols="12">
            <div>
              <v-form>
                <v-row class="d-flex flex-wrap">
                  <v-col class="pb-0 pt-lg-0 pt-1" cols="12" lg="7">
                    <v-autocomplete
                      :append-icon="
                        herdConnect.results.eid === search.eid
                          ? 'mdi-cellphone-link'
                          : ''
                      "
                      :input="runSearch"
                      :items="options.eids"
                      :rules="[rules.EID]"
                      auto-select-first
                      clear-icon="mdi-close-circle"
                      clearable
                      outlined
                      prepend-inner-icon="mdi-magnify"
                      style="font-size: 1.9rem"
                      v-model="search.eid"
                    >
                      <template #label>
                        <div
                          :class="{ darkInputText: $vuetify.theme.dark }"
                          style="font-size: 1.9rem"
                        >
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.findByEID"
                            )
                          }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="12" lg="5">
                    <v-autocomplete
                      :items="options.visuals"
                      :input="runSearch"
                      :label="
                        getTranslation(
                          'pregCheckPage.pregnancyTestEntry.findByTag'
                        )
                      "
                      auto-select-first
                      clear-icon="mdi-close-circle"
                      clearable
                      outlined
                      prepend-inner-icon="mdi-magnify"
                      style="font-size: 1.9rem"
                      v-model="search.visual"
                    >
                      <template #label>
                        <div
                          :class="{ darkInputText: $vuetify.theme.dark }"
                          style="font-size: 1.9rem"
                        >
                          {{
                            getTranslation(
                              "pregCheckPage.pregnancyTestEntry.findByTag"
                            )
                          }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="pb-0 pt-lg-0 pt-1 title" cols="10" lg="6">
                    <strong style="font-size: 1.9rem">Confirm</strong>
                    <span
                      class="ml-2"
                      style="font-size: 1.9rem"
                      v-if="search.eid != null && isValidEID(search.eid)"
                    >
                      {{ search.eid.substr(11) }}
                    </span>
                  </v-col>
                  <v-col class="pb-0 pt-lg-0 pt-1 title" cols="2" lg="1">
                    <v-btn
                      :disabled="multipleResults.options.length <= 1"
                      @click="
                        displayOptionsIfAvailable(
                          multipleResults.options,
                          false
                        )
                      "
                      class="px-1 title"
                      color="primary"
                      elevation="3"
                      style="height: 30px; min-width: 50px"
                    >
                      {{ multipleResults.options.length }}
                      <v-icon color="white">mdi-magnify-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pb-0 pt-lg-0 pt-1 title" cols="12" lg="5">
                    <strong style="font-size: 1.9rem"> Viz </strong>
                    <span style="font-size: 1.9rem">
                      <span v-if="animalIsNew"> NEW </span>
                      <span v-else>
                        {{
                          searchMeta.animal &&
                          (searchMeta.animal.doc.derived.summaries.main
                            .visualTagValues ||
                            searchMeta.animal.doc.derived.summaries.main
                              .EIDtagValues ||
                            searchMeta.animal.doc.derived.summaries.main
                              .tagValues)
                        }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="mt-4 mb-0">
                  <v-col cols="6">
                    <v-row class="mb-2">
                      <v-col class="pt-0 px-0" lg="10" cols="12">
                        <v-text-field
                          :append-icon="
                            herdConnect.results.eid === form.eid
                              ? 'mdi-cellphone-link'
                              : ''
                          "
                          :background-color="
                            form.eid &&
                            options.eidToAnimalShortSummaries[form.eid]
                              ? 'orange'
                              : ''
                          "
                          :error="!!formErrorState.eid"
                          :rules="[rules.EID]"
                          class="mediumFont"
                          clearable
                          hide-details
                          outlined
                          v-model="form.eid"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{ getTranslation("New EID") }}
                            </p>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="pt-0 px-0 d-flex align-center"
                        cols="2"
                        v-if="form.eid != null && isValidEID(form.eid)"
                      >
                        <span class="ml-2" style="font-size: 1.9rem">
                          {{ form.eid.substr(11) }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-text-field
                          :error="!!formErrorState.visual"
                          class="mediumFont"
                          clearable
                          hide-details
                          outlined
                          v-model="form.visual"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{ getTranslation("New Visual") }}
                            </p>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-select
                          :items="options.bcs"
                          :menu-props="{ offsetY: true, closeOnClick: true }"
                          class="mediumFont"
                          clearable
                          hide-details
                          item-text="label"
                          outlined
                          v-model="form.bcs"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.bcs"
                                )
                              }}
                            </p>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-select
                          :background-color="
                            options.mouthScores.length == 0 ? 'warning' : ''
                          "
                          :disabled="options.mouthScores.length == 0"
                          :items="options.mouthScores"
                          :menu-props="{ offsetY: true, closeOnClick: true }"
                          class="mediumFont"
                          clearable
                          hide-details
                          item-text="mouthScore"
                          outlined
                          return-object
                          v-model="form.mouthScore"
                        >
                          <template #label>
                            <span
                              v-if="options.mouthScores.length == 0"
                              style="color: black"
                            >
                              Mouth Scores are not defined.
                            </span>
                            <p class="ma-0 biggerFont" v-else>
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.mouthScore"
                                )
                              }}
                            </p>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-select
                          :items="[
                            getTranslation('enums.fetalSexes.female'),
                            getTranslation('enums.fetalSexes.male'),
                            getTranslation('enums.fetalSexes.twins'),
                          ]"
                          :menu-props="{ offsetY: true, closeOnClick: true }"
                          class="mediumFont"
                          clearable
                          hide-details
                          outlined
                          v-model="form.fetalSex"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.fetalSex"
                                )
                              }}
                            </p>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-text-field
                          :error="!!formErrorState.technician"
                          class="mediumFont"
                          clearable
                          hide-details
                          outlined
                          v-model="form.technician"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.technician"
                                )
                              }}
                            </p>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-select
                          :error="!!formErrorState.testMethod"
                          :items="options.testMethods"
                          :menu-props="{ offsetY: true, closeOnClick: true }"
                          class="mediumFont"
                          clearable
                          hide-details
                          item-text="name"
                          outlined
                          return-object
                          v-model="form.testMethod"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.pregTestMethod"
                                )
                              }}
                            </p>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="pl-8" cols="6">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col>
                            <h4>{{ computedCycle.label }}</h4>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <v-text-field
                          class="mediumFont"
                          clearable
                          hide-details
                          min="0"
                          outlined
                          type="number"
                          v-model="form.daysBred"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.daysBred"
                                )
                              }}
                            </p>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <div class="g-row">
                          <h4 class="mb-0 mr-2">
                            {{
                              getTranslation(
                                "pregCheckPage.pregnancyTestEntry.cullStatus"
                              )
                            }}
                          </h4>
                          <v-icon @click="lock.cull = !lock.cull">
                            {{ lock.cull ? "mdi-lock" : "mdi-lock-open" }}
                          </v-icon>
                        </div>
                        <v-radio-group
                          :disabled="lock.cull"
                          class="mt-0"
                          hide-details
                          v-model="form.cull"
                        >
                          <v-radio :value="false" class="w-50">
                            <template #label>
                              <h5 class="ma-0 mt-2">
                                {{
                                  getTranslation(
                                    "pregCheckPage.pregnancyTestEntry.no"
                                  )
                                }}
                              </h5>
                            </template>
                          </v-radio>
                          <v-radio :value="true" class="w-50">
                            <template #label>
                              <h5 class="ma-0 mt-2">
                                {{
                                  getTranslation(
                                    "pregCheckPage.pregnancyTestEntry.yes"
                                  )
                                }}
                              </h5>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-select
                          :items="options.colors"
                          :menu-props="{
                            offsetY: true,
                            closeOnClick: true,
                          }"
                          class="mediumFont"
                          clearable
                          hide-details
                          item-text="color"
                          outlined
                          return-object
                          v-model="form.color"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.color"
                                )
                              }}
                            </p>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 px-0">
                        <v-text-field
                          :append-icon="
                            herdConnect.results.weight != null &&
                            herdConnect.results.weight == form.weight
                              ? 'mdi-cellphone-link'
                              : ''
                          "
                          :error="!!formErrorState.weight"
                          :rules="[rules.decimal]"
                          class="mediumFont mb-3"
                          clearable
                          hide-details
                          outlined
                          type="decim"
                          v-model="form.weight"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{
                                getTranslation(
                                  "pregCheckPage.pregnancyTestEntry.weight"
                                )
                              }}
                            </p>
                          </template>
                        </v-text-field>
                        <v-text-field
                          :append-icon="
                            herdConnect.results.tsu != null &&
                            herdConnect.results.tsu == form.tsu
                              ? 'mdi-cellphone-link'
                              : ''
                          "
                          :label="getTranslation('enums.tagTypes.tsu')"
                          class="mediumFont"
                          clearable
                          hide-details
                          outlined
                          v-model="form.tsu"
                        >
                          <template #label>
                            <p class="ma-0 biggerFont">
                              {{ getTranslation("enums.tagTypes.tsu") }}
                            </p>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- Treatments Field -->
                <template>
                  <v-row
                    :key="index"
                    v-for="(treatment, index) in form.treatments"
                  >
                    <v-col class="col-8 pt-0 px-0">
                      <v-select
                        :background-color="
                          options.vaccinations.length == 0 ? 'warning' : ''
                        "
                        :disabled="options.vaccinations.length == 0"
                        :error="treatment.validation.errorVaccination"
                        :items="options.vaccinations"
                        :menu-props="{ offsetY: true, closeOnClick: true }"
                        class="mediumFont"
                        clearable
                        hide-details
                        item-text="name"
                        return-object
                        outlined
                        v-model="treatment.vaccination"
                      >
                        <template #label>
                          <span
                            v-if="options.vaccinations.length == 0"
                            style="color: black"
                          >
                            Preg medications are not defined.
                          </span>
                          <p class="ma-0 biggerFont" v-else>
                            {{
                              getTranslation(
                                "pregCheckPage.pregnancyTestEntry.vaccination"
                              )
                            }}
                          </p>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="3"
                      class="pt-0 px-0"
                      v-if="options.vaccinations.length > 0"
                    >
                      <v-text-field
                        :error="treatment.validation.errorDose"
                        :rules="[rules.decimal]"
                        class="mediumFont"
                        clearable
                        hide-details
                        outlined
                        v-model="treatment.dose"
                      >
                        <template #label>
                          <p class="ma-0 biggerFont">
                            {{
                              getTranslation(
                                "pregCheckPage.pregnancyTestEntry.dose"
                              )
                            }}
                          </p>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      class="pt-0 px-0"
                      cols="1"
                      style="position: relative; z-index: 10"
                    >
                      <div v-if="index == 0">
                        <v-btn
                          :disabled="options.vaccinations.length == 0"
                          @click="addTreatment()"
                          block
                          color="success"
                          height="56"
                          small
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          @click="removeTreatment(index)"
                          block
                          color="error"
                          height="56"
                          small
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <v-row class="mt-4 mb-2">
                  <v-textarea
                    :label="
                      getTranslation(
                        'pregCheckPage.pregnancyTestEntry.comments'
                      )
                    "
                    auto-grow
                    class="mediumFont"
                    clearable
                    counter
                    dense
                    hide-details
                    maxlength="200"
                    outlined
                    rows="3"
                    v-model="form.comment"
                  >
                    <template #label>
                      <p class="ma-0 biggerFont">
                        {{
                          getTranslation(
                            "pregCheckPage.pregnancyTestEntry.comments"
                          )
                        }}
                      </p>
                    </template>
                  </v-textarea>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <v-row class="pa-10 pt-0 pb-6 d-flex flex-wrap">
          <v-col class="pt-0" cols="12" lg="9">
            <v-row class="d-flex justify-center">
              <v-col cols="9" lg="6">
                <v-btn
                  :disabled="searchMeta.active || blockSave"
                  @click="saveAnimal"
                  block
                  color="success"
                  large
                >
                  <template v-if="!animalIsNew">
                    {{ getLabelTranslation("save") }}
                  </template>
                  <template v-else>
                    {{ getTranslation("Save New") }}
                  </template>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  :disabled="!blockSave"
                  @click="saveAnimal"
                  color="warning"
                  large
                >
                  Warnings
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3">
            <v-row class="d-flex justify-center">
              <v-col class="d-flex justify-center" cols="4" lg="12">
                <v-btn
                  @click="resetFields"
                  block
                  color="primary"
                  large
                  outlined
                  >{{
                    getTranslation("pregCheckPage.pregnancyTestEntry.reset")
                  }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mx-4"></v-divider>
        <herd-connect
          :streamLF="streamEID"
          :streamUHF="streamEID"
          :streamTSU="streamTSU"
          :streamVisual="streamVisual"
          :streamWeight="streamWeight"
          class="mx-4"
        />
      </template>
    </summary-container>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import { mapActions, mapGetters } from "vuex";
import SummaryContainer from "../SummaryContainer";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "pregnancy-test-entry",
  components: {
    "summary-container": SummaryContainer,
  },
  mixins: [TranslationMixin],
  data: () => ({
    cycle: { id: null, label: "Open" },
    form: {
      bcs: null,
      bullTurninDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      color: null,
      comment: null,
      cull: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      daysBred: null,
      eid: null,
      fetalSex: null,
      group: null,
      mouthScore: null,
      technician: null,
      testMethod: null,
      treatments: [], // This will be set from the cache or from the default value
      tsu: null,
      visual: null,
      weight: null,
    },
    herdConnect: {
      resetFields: false,
      results: {
        eid: null,
        tsu: null,
        visual: null,
        weight: null,
      },
    },
    lock: {
      group: false,
      cull: false,
    },
    multipleResults: {
      options: [],
      resultSelected: null,
      showDialog: false,
    },
    options: {
      bcs: [],
      colors: [],
      eids: [],
      eidToAnimalShortSummaries: {},
      fetalSex: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
        { name: "Twins", id: "Twins" },
      ],
      mouthScores: [],
      testMethods: [],
      vaccinations: [],
      visuals: [],
      visualToAnimalShortSummaries: {},
    },
    rules: {
      decimal: (number) => {
        if (number == null || number == "") return true;
        // Decimal number with point or just number
        return /^\d+$|^\d+\.\d+$/gm.test(number);
      },
      EID: (value) =>
        // convert null to string and numbers to string and strings to string
        /^$|^\d{15}$/.test(
          (value == null ? "" : value + "").replace(/-/g, "").replace(/ /g, "")
        ) || "Invalid ID",
      required: (value) => !!value || "Required",
    },
    search: {
      eid: "",
      visual: "",
    },
    searchMeta: {
      active: false,
      animal: null,
      animalIsNew: true,
    },
    showBullCalendar: false,
    showCalendar: false,
    title: "pregnancy-test-entry",
  }),
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("new-search");
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
    }),
    animalIsNew: function () {
      return this.searchMeta.animalIsNew;
    },
    blockSave: function () {
      return this.errorMessages.length > 0;
    },
    computedCycle: function () {
      const pregDate = moment(this.form.date);
      const btd = this.form.bullTurninDate
        ? moment(this.form.bullTurninDate)
        : 0;
      const daysBred = +this.form.daysBred > 0 ? +this.form.daysBred : 0;

      const diff =
        btd === 0
          ? pregDate.subtract(daysBred, "days")
          : pregDate.diff(btd, "days") - daysBred;

      if (!daysBred) return { id: "Open", label: "Open" };
      else if (diff >= 42) return { id: "late", label: "Late" };
      else if (diff >= 21 && diff <= 41)
        return { id: "Cycle 2", label: "Cycle 2" };
      else if (diff <= 20) return { id: "Cycle 1", label: "Cycle 1" };
    },
    maxPregDate: function () {
      return moment().add(1, "days").toISOString();
    },
    dateBullTurninWarning: function () {
      if (this.form.bullTurninDate == null) {
        return {
          danger: true,
          warning: false,
        };
      }

      const bullTurninDate = moment(this.form.bullTurninDate).startOf("day");

      const isFuture =
        bullTurninDate.diff(moment(this.form.date).startOf("day"), "days") > 0;
      const turnInSameDay =
        moment(this.form.date).startOf("day").diff(bullTurninDate, "days") == 0;
      const turnInTooLate =
        moment(this.form.date).startOf("day").diff(bullTurninDate, "days") < 0;
      return {
        danger: turnInTooLate || isFuture,
        warning: turnInSameDay,
      };
    },
    dateValidation: function () {
      const date = moment(this.form.date).startOf("day");
      const isFuture = date.diff(moment().startOf("day"), "days") > 0;
      const isPast = date.diff(moment().startOf("day"), "days") < 0;
      return {
        danger: false,
        warning: isFuture || isPast,
      };
    },
    formErrorState: function () {
      const eidEmpty = this.form.eid == null || this.form.eid.trim() == "";
      const visualEmpty =
        this.form.visual == null || this.form.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.eid);

      const fieldToErrors = {
        // Order matters
        dateBullError: this.dateBullTurninWarning.danger
          ? "Bull Turnin date must be before check date and cannot be a future date."
          : false,
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? "Valid EID is required"
            : false,
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? "Valid Visual is required"
            : false,
        group: this.form.group ? false : "Group Number is required.",
        technician: this.form.technician ? false : "Technician is required.",
        testMethod: this.form.testMethod ? false : "Test method is required.",
        cull: this.form.cull != undefined ? null : "Cull is required.",
        treatmentVaccination:
          this.form.treatments &&
          this.form.treatments.some(
            (treatment) => treatment.validation.errorVaccination == true
          )
            ? "Dose is set, but vaccination is not."
            : null,
        treatmentDose:
          this.form.treatments &&
          this.form.treatments.some(
            (treatment) => treatment.validation.errorDose == true
          )
            ? "Vaccination is set, but dose is not."
            : null,
        weight: this.rules.decimal(this.form.weight)
          ? null
          : "Weight must be empty or numeric",
      };

      return fieldToErrors;
    },
    errorMessages: function () {
      return Object.keys(this.formErrorState)
        .map((key) => {
          return this.formErrorState[key];
        })
        .filter((message) => !!message);
    },
    isPregnant: function () {
      return this.form.daysBred > 0;
    },
    persistDialog() {
      return this.multipleResults.resultSelected === null;
    },
    runSearch: async function () {
      this.searchMeta.active = true;

      return await this.searchAnimal(this.search.eid, this.search.visual)
        .then(() => {
          this.searchMeta.active = false;
        })
        .catch(() => {
          this.searchMeta.active = false;
        });
    },
    showUpperButtons: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    withBullDays: function () {
      const date = moment(this.form.date);
      const btd = this.form.bullTurninDate
        ? moment(this.form.bullTurninDate)
        : date;
      const diff = date.diff(btd, "days");
      return diff;
    },
  },
  watch: {
    "form.group": {
      deep: true,
      handler: function (group) {
        EventBus.$emit("set-current-group-number", group);
      },
    },
    "form.treatments": {
      deep: true,
      handler: function (arr) {
        arr.forEach((treatment, index) => {
          if (
            treatment.vaccination != null &&
            treatment.vaccination != "" &&
            (treatment.dose == null || treatment.dose == "")
          )
            this.form.treatments[index].validation.errorDose = true;
          else if (
            treatment.dose != null &&
            treatment.dose != "" &&
            (treatment.vaccination == null || treatment.vaccination == "")
          )
            this.form.treatments[index].validation.errorVaccination = true;
          else {
            this.form.treatments[index].validation.errorVaccination = false;
            this.form.treatments[index].validation.errorDose = false;
          }
        });
      },
    },
    lock: {
      deep: true,
      handler: function (obj) {
        localStorage.setItem("lock.pregCheck", JSON.stringify(obj));
      },
    },
    "multipleResults.options": function (results) {
      this.searchMeta.animal = this.multipleResults.resultSelected = null;
      this.displayOptionsIfAvailable(results, true);
    },
    "multipleResults.resultSelected": async function (result) {
      this.searchMeta.animal = new Animal(
        result.doc._id,
        this.herdMeta,
        result.doc,
        this.$userID
      );
    },
    "searchMeta.animal": function (animal) {
      if (
        animal &&
        animal.doc &&
        animal.doc.derived &&
        animal.doc.derived.summaries
      ) {
        EventBus.$emit("new-search", animal.guid);
        this.setCowInformation({
          ai: (animal.doc.derived.summaries.fertilizations || { ai: {} }).ai,
          birthDate: animal.doc.derived.summaries.main.birthDate,
          birthRanch: animal.doc.derived.summaries.main.origin,
          birthVisual: animal.doc.derived.summaries.main.birthVisual,
          breed: animal.doc.derived.summaries.main.breed,
          comment: animal.doc.derived.summaries.main.comment,
          guid: animal.guid,
          herd: animal.doc.derived.summaries.main.herd,
          tagValues: animal.doc.derived.summaries.main.tagValues,
          status: animal.doc.derived.summaries.main.status,
        });

        this.searchMeta.animalIsNew = animal.animalIsNew;
      } else {
        this.searchMeta.animalIsNew = true;
        return;
      }
    },
    "form.daysBred": function (newVal) {
      this.form.cull = +newVal === 0;

      this.form.daysBred = +newVal || null;
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect.results"),
          JSON.stringify(newVal)
        );

        if (newVal.tsu && newVal.tsu.trim()) this.form.tsu = newVal.tsu.trim();

        if (+newVal.weight) this.form.weight = "" + newVal.weight;

        if (newVal.eid)
          if (this.options.eidToAnimalShortSummaries[newVal.eid]) {
            this.search.eid = newVal.eid;
          } else {
            this.form.eid = newVal.eid;
          }

        if (newVal.visual)
          if (this.options.visualToAnimalShortSummaries[newVal.visual]) {
            this.search.visual = newVal.visual;
          } else {
            this.form.visual = newVal.visual;
          }
      },
    },
    form: {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("form"),
          JSON.stringify(newVal)
        );
      },
    },
    search: {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("search"),
          JSON.stringify(newVal)
        );
      },
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;

    EventBus.$tabEvent.on("consumed", ({ type, value }) => {
      if (type === "eid" && this.form.eid === value) this.form.eid = null;
      if (type === "tsu" && this.form.tsu === value) this.form.tsu = null;
      if (type === "visual" && this.form.visual === value)
        this.form.visual = null;
      if (type === "weight" && this.form.weight === value)
        this.form.weight = null;

      if (type === "eid" && this.herdConnect.results.eid === value)
        this.herdConnect.results.eid = null;
      if (type === "tsu" && this.herdConnect.results.tsu === value)
        this.herdConnect.results.tsu = null;
      if (type === "visual" && this.herdConnect.results.visual === value)
        this.herdConnect.results.visual = null;
      if (type === "weight" && this.herdConnect.results.weight === value)
        this.herdConnect.results.weight = null;

      // watch should handle this, but we were seeing that it was not sufficient
      if (type === "eid" && this.search.eid === value) this.search.eid = null;
      if (type === "visual" && this.search.visual === value)
        this.search.visual = null;

      this.getIDoptions();
    });

    EventBus.$on("count-for-group-number", (result) => {
      if (result.groupNumber !== this.form.group) return;
      // forward on to parent
      EventBus.$emit("preg-check-total", result.count);
    });

    const vaccinations = await this.herdMeta.getMetaVaccinationsAsync(
      true,
      false,
      false
    );
    if (vaccinations instanceof Array)
      this.options.vaccinations = vaccinations.filter(
        (vaccination) => vaccination.pregOrWeanMedication == true
      );
    else this.options.vaccinations = [];

    const IDOptionsAreLoaded = this.getIDoptions();

    // FIXME: Find a way to avoid needing to do this
    this.herdMeta.setItems(this.getOrganizationItems, {
      as: "receivingRanches",
      id: "id",
    });

    const previousLockState = JSON.parse(
      localStorage.getItem("lock.pregCheck")
    );
    if (!previousLockState)
      this.lock = {
        group: false,
        cull: false,
      };
    else this.lock = previousLockState;

    const previousForm = JSON.parse(
      localStorage.getItem(this.orgUserScoping("form"))
    );

    // Handle treatments specially
    if (!previousForm) this.form.treatments[0] = this.getDefaultTreatment();

    if (previousForm && previousForm.treatments) {
      // Remove treatments that are no longer available on preg check page
      previousForm.treatments = previousForm.treatments.filter((treatment) => {
        if (!treatment.vaccination) return true;
        return this.options.vaccinations.some(
          (vaccination) => vaccination.id == treatment.vaccination.id
        );
      });
      if (previousForm.treatments.length == 0)
        previousForm.treatments = [this.getDefaultTreatment()];
    }

    const previousSearch = JSON.parse(
      localStorage.getItem(this.orgUserScoping("search"))
    );
    const previousHerdConnectResults = JSON.parse(
      localStorage.getItem(this.orgUserScoping("herdConnect.results"))
    );

    if (previousForm != null && this.form != previousForm) {
      this.form = previousForm;

      // Temporary code.
      // Ensures full color data is available for storage into the animal
      if (this.form.color && typeof this.form.color == "string")
        // Needs to be a map now
        this.form.color = null;
    }
    if (previousSearch != null && this.search != previousSearch) {
      this.search = previousSearch;

      // Clear out previous if it is no longer valid
      if (!this.options.eids.some((eid) => eid == this.form.eid))
        this.search.eid = null;

      // Clear out previous if it is no longer valid
      if (!this.options.visuals.some((eid) => visual == this.form.visual))
        this.search.visual = null;
    }

    if (
      previousHerdConnectResults != null &&
      this.herdConnect.results != previousHerdConnectResults
    ) {
      // We need IDs to be loaded before we can reconstitute herd connect values
      // If we do not wait, the values will always appear to be new.
      setTimeout(async () => {
        await IDOptionsAreLoaded;

        if (previousSearch != null && this.search != previousSearch) {
          this.search = previousSearch;

          // Clear out previous search values if they are no longer valid
          if (!this.options.eidToAnimalShortSummaries[eid])
            this.search.eid = null;
          if (!this.options.visualToAnimalShortSummaries[visual])
            this.search.visual = null;
        }

        this.herdConnect.results = previousHerdConnectResults;
      }, 0);
    }

    this.options.bcs = this.getEnumOptions("bcs").map((item) => {
      return {
        id: item.value,
        label: item.label,
      };
    });

    this.options.testMethods = this.getEnumOptions("pregCheckTestMethods").map(
      (item) => {
        return {
          id: item.value,
          name: item.label,
        };
      }
    );
    if (
      this.form.testMethods &&
      !this.options.testMethods.some(
        (item) => item.id == this.form.testMethods.id
      )
    ) {
      // Clear out current setting because it is no longer valid
      this.form.testMethods = null;
    }

    const colors = await this.herdMeta.getMetaColorsAsync(true, true, true);
    if (colors instanceof Array) this.options.colors = colors;
    else this.options.colors = [];

    const mouthScores = await this.herdMeta.getMetaMouthScoresAsync(
      true,
      true,
      true
    );
    if (mouthScores instanceof Array)
      this.options.mouthScores = mouthScores.filter(
        (score) => score.pregCheck == true
      );
  },
  methods: {
    ...mapActions({
      resetCowInformation: "CowInformation/reset",
      setCowInformation: "CowInformation/setInformation",
    }),
    addDataAsync: async function (animal, isNew, reason) {
      const promises = [];

      let timeRecordedISO = moment(this.form.date).toISOString();
      const dateIsToday = moment().diff(timeRecordedISO, "days") === 0;
      if (dateIsToday) {
        // Re-init with time
        timeRecordedISO = new Date().toISOString();

        const coords = await this.$utils.getGeoLocation();
        if (coords.latitude || coords.longitude)
          promises.push(
            animal.modify("geopoints", null, "id", coords.id, false, true, {
              ...coords,
              reason: isNew ? "New preg check" : "Preg check",
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            })
          );
      }

      const commentId = (this.form.comment || "").trim()
        ? this.$utils.guid()
        : null;

      promises.push(
        animal.modify("genders", null, null, null, false, true, {
          gender: "female",
          timeRecorded: timeRecordedISO,
          userId: this.$userID,
        })
      );

      if (this.form.group)
        promises.push(
          animal.modify("movements", null, "locationId", null, false, true, {
            groupNumber: this.form.group,
            reason: reason,
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );

      const treatmentId = this.$utils.guid();
      this.form.treatments
        .filter((treatment) =>
          this.isValidTreatment(treatment.vaccination, treatment.dose)
        )
        .forEach(({ vaccination, dose }) => {
          promises.push(
            animal.modify(
              "treatments",
              null,
              "vaccinationsId",
              vaccination.id,
              false,
              true,
              {
                actualDose: Number.isNaN(dose) ? +dose : dose,
                commentId: commentId,
                id: treatmentId,
                userId: this.$userID,
                vaccinationTime: timeRecordedISO,
              }
            )
          );
        });

      if ((this.form.eid || "").trim()) {
        const tag = {
          status: "active",
          tagId: null,
          tagValue: this.form.eid.trim(),
          timeRecorded: timeRecordedISO,
          type: "eid",
          userId: this.$userID,
        };
        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      // Store tsu
      const tsu = this.form.tsu;
      if (tsu)
        promises.push(
          animal.modify(
            "dnaNumbers",
            null,
            "dnaNumber",
            tsu.trim(),
            false,
            true,
            {
              commentId,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      if ((this.form.visual || "").trim()) {
        const tag = {
          status: "active",
          tagColor: null,
          tagId: null,
          tagValue: this.form.visual.trim(),
          timeRecorded: timeRecordedISO,
          type: "visual",
          userId: this.$userID,
        };

        promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
      }

      if (commentId)
        promises.push(
          animal.modify(
            "comments",
            null,
            "comment",
            this.form.comment.trim(),
            false,
            true,
            {
              id: commentId,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );
      if (!!this.form.cull) {
        promises.push(
          animal.modify("statuses", null, "status", "cull", false, true, {
            timeRecorded: timeRecordedISO,
            userId: this.$userID,
          })
        );
      }

      if (this.form.weight && this.form.weight.trim() != "")
        promises.push(
          animal.modify(
            "weights",
            null,
            "weight",
            this.form.weight.trim(),
            false,
            true,
            {
              commentId,
              groupNumber: this.form.group,
              source: "preg",
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      if (this.form.color && this.form.color.id)
        promises.push(
          animal.modify(
            "colors",
            null,
            "colorId",
            this.form.color.id,
            false,
            true,
            {
              color: this.form.color.color,
              commentId: commentId,
              timeRecorded: timeRecordedISO,
              userId: this.$userID,
            }
          )
        );

      {
        // Must be an option from enums.pregResults
        let result = null;
        if (this.computedCycle.id == "Open") result = "Open";
        else result = "pregnant";
        promises.push(
          animal.modify("pregChecks", null, "result", result, false, true, {
            bcs: this.form.bcs,
            breedUpType: null,
            bullTurninDate: this.form.bullTurninDate,
            commentId,
            cycle: this.computedCycle.id,
            daysBred: this.form.daysBred ? +this.form.daysBred : null,
            fetalSex: this.form.fetalSex,
            groupNumber: this.form.group,
            method: this.form.testMethod ? this.form.testMethod.id : null,
            mouthScore: this.form.mouthScore,
            pregCheckTime: timeRecordedISO,
            status: null, // Not set here, but can be set on Animal Details
            technician: this.form.technician,
            testMethod: this.form.testMethod ? this.form.testMethod.id : null,
            userId: this.$userID,
            vaccinationsId: null,
          })
        );
      }

      EventBus.$emit("new-preg-check");
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then(() => {
            $.when(animal.save())
              .fail((e) => reject(new Error(`addDataAsync - Error: ${e}`)))
              .done(() => {
                if (isNew)
                  this.$notify({
                    duration: 10000,
                    group: "forms",
                    text: [
                      `Animal created (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                    ].join("<br />"),
                    title: "Success",
                    type: "success",
                  });
                else
                  this.$notify({
                    duration: 10000,
                    group: "forms",
                    text: [
                      `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                    ].join("<br />"),
                    title: "Success",
                    type: "success",
                  });
                this.setCowInformation({
                  guid: animal.guid,
                  tagValues: animal.doc.derived.summaries.main.tagValues,
                });
                resolve("AddDataAsync - Success");
              });
          })
          .catch((e) => {
            this.$notify({
              group: "forms",
              text: "An error occurred.",
              title: "Error",
              type: "error",
            });
            reject(new Error(`AddDataAsync - Error: ${e}`));
          });
      });
    },
    addTreatment: function () {
      this.form.treatments.push(this.getDefaultTreatment());
    },
    displayOptionsIfAvailable: function (results, displayNofication) {
      this.multipleResults.showDialog = false;
      if (results.length === 0) {
        this.searchMeta.animal = null;
      } else if (results.length === 1) {
        const doc = results[0].doc;
        this.searchMeta.animal = new Animal(doc._id, this.herdMeta, doc, null);
      } else if (results.length > 1) {
        this.multipleResults.showDialog = true;

        if (!displayNofication) return;

        this.$notify({
          duration: 10000,
          group: "forms",
          text: "There are more results available. Select the correct animal.",
          title: "Multiple Results",
          type: "info",
        });
      }
    },
    forceUseNewAnimal: function () {
      this.searchMeta.animal = null;
      this.multipleResults.showDialog = false;
    },
    getDefaultTreatment: function () {
      return {
        dose: null,
        vaccination: null,
        validation: {
          errorDose: false,
          errorVaccination: false,
        },
      };
    },
    getIDoptions: async function () {
      const options =
        await this.herdMeta.pouches.organization.tagToAnimalShortSummaryBeyondThresholdAndMatchingStatuses(
          "female",
          ["alive", "cull", "missing", "sick"],
          [],
          null,
          false,
          null
        );

      this.options.eids = Object.keys(options.eid).sort();
      this.options.eidToAnimalShortSummaries = options.eid;
      this.options.visuals = Object.keys(options.visual).sort();
      this.options.visualToAnimalShortSummaries = options.visual;
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    isValidTreatment: function (vaccination, dose) {
      return (
        vaccination != null && vaccination != "" && dose != null && dose != ""
      );
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    removeTreatment: function (index) {
      this.form.treatments = this.form.treatments.filter(
        (treatment, idx) => idx != index
      );
    },
    resetFields: function () {
      if (!confirm("Reset?")) return;
      this.form = {
        bullTurninDate: this.form.bullTurninDate,
        comment: null,
        cull: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        daysBred: null,
        eid: null,
        group: this.form.group,
        technician: null,
        testMethod: null,
        treatments: this.form.treatments.map((_) => this.getDefaultTreatment()),
        tsu: null,
        visual: null,
        weight: null,
      };
      this.search = {
        eid: null,
        visual: null,
      };

      this.herdConnect.resetFields = !this.herdConnect.resetFields;
      this.herdConnect.results.eid =
        this.herdConnect.results.tsu =
        this.herdConnect.results.visual =
        this.herdConnect.results.weight =
          null;

      this.resetCowInformation();
    },
    saveAnimal: async function () {
      if (this.blockSave) {
        // Display errors in logical order (notifier is stacking newest on top)
        this.errorMessages.reverse().forEach((m) => {
          this.$notify({
            group: "forms",
            text: m,
            title: "Error",
            type: "error",
          });
        });

        return;
      }

      const p = new Promise((resolve, reject) => {
        if (!this.searchMeta.animal)
          this.searchMeta.animal = HerdMeta.makeNewAnimal(
            this.herdMeta,
            this.$userID
          );

        if (this.searchMeta.animalIsNew) {
          // Tags are new
          this.addDataAsync(this.searchMeta.animal, true, "New preg check")
            .catch((e) => {
              reject(new Error(`Failed to save animal: ${e}`));
            })
            .then(() => {
              resolve(this.searchMeta.animal);
            });
        } else {
          // Animal was located
          this.addDataAsync(this.searchMeta.animal, false, null)
            .catch((e) => {
              reject(new Error(`Failed to update animal: ${e}`));
            })
            .then(() => {
              resolve(this.searchMeta.animal);
            });
        }
      });
      await p
        .then((animal) => {
          this.getIDoptions();
          EventBus.$emit("summary-refresh-start");

          if (this.form.eid)
            EventBus.$tabEvent.emit("consumed", {
              type: "eid",
              value: this.form.eid,
            });
          if (this.form.tsu)
            EventBus.$tabEvent.emit("consumed", {
              type: "tsu",
              value: this.form.tsu,
            });
          if (this.form.visual)
            EventBus.$tabEvent.emit("consumed", {
              type: "visual",
              value: this.form.visual,
            });
          if (this.form.weight)
            EventBus.$tabEvent.emit("consumed", {
              type: "weight",
              value: this.form.weight,
            });

          if (this.herdConnect.results.eid)
            EventBus.$tabEvent.emit("consumed", {
              type: "eid",
              value: this.herdConnect.results.eid,
            });
          if (this.herdConnect.results.tsu)
            EventBus.$tabEvent.emit("consumed", {
              type: "tsu",
              value: this.herdConnect.results.tsu,
            });
          if (this.herdConnect.results.visual)
            EventBus.$tabEvent.emit("consumed", {
              type: "visual",
              value: this.herdConnect.results.visual,
            });
          if (this.herdConnect.results.weight)
            EventBus.$tabEvent.emit("consumed", {
              type: "weight",
              value: this.herdConnect.results.weight,
            });

          this.form.comment =
            this.form.bcs =
            this.form.color =
            this.form.daysBred =
            this.form.eid =
            this.form.fetalSex =
            this.form.mouthScore =
            this.form.tsu =
            this.form.visual =
            this.form.weight =
            this.search.eid =
            this.search.visual =
            this.searchMeta.animal =
            this.herdConnect.results.eid =
            this.herdConnect.results.tsu =
            this.herdConnect.results.visual =
            this.herdConnect.results.weight =
              null;

          this.form.cull = false;

          return animal;
        })
        .catch((error) => {
          this.$notify({
            group: "forms",
            text: error,
            title: "Error",
            type: "error",
          });
        });

      return p;
    },
    searchAnimal: function (eid, visual) {
      return new Promise(async (resolve, reject) => {
        const result =
          await this.herdMeta.getFirstAnimalWithActiveTagAndWhetherIsUniqueV2(
            eid,
            visual,
            null,
            true,
            false
          );

        const matches = result ? result.matches : [];

        this.searchMeta.animal =
          matches.length > 1
            ? await this.pouches.organization.get(matches[0])
            : HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);

        if (matches.length === 0) {
          this.multipleResults.options = [];
        } else {
          const docs = matches.map(
            async (id) => await this.pouches.organization.get(id)
          );

          this.multipleResults.options = await Promise.all(docs)
            .then((docs) =>
              docs
                .filter((doc) => doc !== null)
                .map((doc) => {
                  doc.derived ||= {
                    summaries: Animal.defaultSummaries(doc._id),
                  };
                  return doc;
                })
                .map((doc) => ({
                  doc: doc,
                  tagValues: doc.derived.summaries.main.tagValues || "",
                }))
                .sort((a, b) => a.tagValues.localeCompare(b.tagValues))
                .map((result, idx) => ({
                  doc: result.doc,
                  tagValues: `${idx + 1} - ${result.tagValues}`,
                }))
            )
            .catch((e) => {
              console.error(e);
              reject(e);
            });
        }

        resolve();
      });
    },
    streamEID(eid) {
      this.herdConnect.results.eid = eid;
    },
    streamTSU(tsu) {
      this.herdConnect.results.tsu = tsu;
    },
    streamVisual(visual) {
      this.herdConnect.results.visual = visual;
    },
    streamWeight(weight) {
      this.herdConnect.results.weight = weight;
    },
  },
};
</script>
<style scoped>
.darkInputText {
  color: white;
}
.biggerFont {
  font-size: 1.7rem;
}
.mediumFont {
  font-size: 1.5rem;
  color: black;
}
</style>
<style lang="scss">
.custom-text-color.theme--dark.v-input {
  input {
    color: black;
  }
}
</style>
