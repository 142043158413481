<template>
  <div class="dashboard-background">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex mb-6">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-btn
              @click="printTable"
              class="normal-case rounded-lg primary-button"
              text
            >
              <v-icon class="mr-1" primary="true">mdi-printer</v-icon>
              Print
            </v-btn>
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1">mdi-open-in-new</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
        <v-card
          class="dashboard-light-border h-full light-elevation rounded-lg"
          elevation="0"
        >
          <v-card-text class="h-full title-and-content-wrapper">
            <v-row>
              <v-col cols="12" md="4">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="BirthReport.showFromDate"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('From')"
                      append-icon="mdi-calendar"
                      class="mt-0"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-model="BirthReport.fromDate"
                      v-on:click:append="BirthReport.showFromDate = true"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    @change="generateReport"
                    @input="BirthReport.showFromDate = false"
                    v-model="BirthReport.fromDate"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="BirthReport.showToDate"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('To')"
                      append-icon="mdi-calendar"
                      class="mt-0"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-model="BirthReport.toDate"
                      v-on:click:append="BirthReport.showToDate = true"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    :min="BirthReport.fromDate"
                    @change="generateReport"
                    @input="BirthReport.showToDate = false"
                    v-model="BirthReport.toDate"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-data-table
        :headers="[]"
        :items="[]"
        :loading="true"
        class="dashboard-data-table my-5"
        hide-default-footer
        loading-text="Loading... Please wait"
        v-if="BirthReport.loading"
      />
      <div class="printable printable-metadata">
        <h5>{{ getOrganizationName }}</h5>
        <h5>
          Generated: {{ $moment().local().format("MM-DD-YYYY HH:mm:ss") }}
        </h5>
        <h5>Report Year: {{ BirthReport.year }}</h5>
        <h5>Report: Calf birth summary</h5>
      </div>
      <div class="printable">
        <div
          class="my-5"
          v-for="(table, $index) in BirthReport.tableDataByHerd"
          :key="$index"
        >
          <div class="printable printable-metadata">
            <br /><br /><br /><br />
          </div>
          <v-data-table
            :headers="BirthReportHeaders"
            :items="table.data"
            class="dashboard-data-table"
            hide-default-footer
            show-select
            v-model="table.selected"
          >
            <template #top="{ options, pagination, updateOptions }">
              <div class="d-flex align-center">
                <div class="h6">{{ table.herd }}</div>
                <v-data-footer
                  :items-per-page-text="
                    getTranslation('animalsPages.itemsPerPage')
                  "
                  :options="options"
                  :pagination="pagination"
                  @update:options="updateOptions"
                  class="ml-auto"
                />
              </div>
            </template>
            <template #body.append>
              <tr>
                <th colspan="2" class="body-1"><strong> Totals </strong></th>
                <th
                  class="body-1"
                  v-for="(header, $index) in BirthReportHeaders.slice(
                    1,
                    BirthReportHeaders.length
                  )"
                  :key="$index"
                >
                  <strong>{{ getTotalOf(table, header.value) }}</strong>
                </th>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "birth-report",
  data: () => ({
    notAssigned: "Not Assigned",
    BirthReport: {
      currentPage: 1,
      data: [],
      dynamicHeaders: [],
      loading: true,
      perPage: 10,
      tableDataByHerd: [],
      year: new Date().getFullYear(),
      fromDate: moment().startOf("year").toISOString().substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      showFromDate: false,
      showToDate: false,
    },
  }),
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getOrganizationName: "Organization/getName",
    }),
    BirthReportHeaders: function () {
      /**
       * We no longer need "key" and "label" fields for the data table
       * but are still used to compute or fill values
       */
      return [
        {
          align: "start",
          key: "breed",
          label: this.getTranslation("calfWeanPage.groupSummary.breed"),
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.breed"),
          value: "breed",
        },
        {
          align: "start",
          key: "calvesBorn",
          label: this.getTranslation("Calves Born"),
          sortable: false,
          text: this.getTranslation("Calves Born"),
          value: "calvesBorn",
        },
      ]
        .concat(this.BirthReport.dynamicHeaders)
        .concat([
          {
            align: "start",
            key: "avgBirthWeight",
            label: this.getTranslation("Average Birth Weight"),
            sortable: false,
            text: this.getTranslation("Average Birth Weight"),
            value: "avgBirthWeight",
          },
          {
            align: "start",
            key: "birthEasy",
            label: this.getTranslation("Birth Easy"),
            sortable: false,
            text: this.getTranslation("Birth Easy"),
            value: "birthEasy",
          },
          {
            align: "start",
            key: "birthDifficult",
            label: this.getTranslation("Birth Difficult"),
            sortable: false,
            text: this.getTranslation("Birth Difficult"),
            value: "birthDifficult",
          },
          {
            align: "start",
            key: "vigorGood",
            label: this.getTranslation("Vigor Good"),
            sortable: false,
            text: this.getTranslation("Vigor Good"),
            value: "vigorGood",
          },
          {
            align: "start",
            key: "vigorFair",
            label: this.getTranslation("Vigor Fair"),
            sortable: false,
            text: this.getTranslation("Vigor Fair"),
            value: "vigorFair",
          },
          {
            align: "start",
            key: "calfDied",
            label: this.getTranslation("Calf Died"),
            sortable: false,
            text: this.getTranslation("Calf Died"),
            value: "calfDied",
          },
          {
            align: "start",
            key: "deathLossPercent",
            label: this.getTranslation("Death Loss Percent"),
            sortable: false,
            text: this.getTranslation("Death Loss Percent"),
            value: "deathLossPercent",
          },
          {
            align: "start",
            key: "totalLiveCalves",
            label: this.getTranslation("Total Live Calves"),
            sortable: false,
            text: this.getTranslation("Total Live Calves"),
            value: "totalLiveCalves",
          },
          {
            align: "start",
            key: "activeCalves",
            label: this.getTranslation("Active Calves"),
            sortable: false,
            text: this.getTranslation("Active Calves"),
            value: "activeCalves",
          },
        ]);
    },
  },
  mounted: function () {
    this.generateReport();
  },
  methods: {
    generateReport: function () {
      this.BirthReport.loading = true;
      this.$herdMeta.loaded
        .fail(() => {})
        .then(() => {
          this.$herdMeta.pouches.organization
            .query("local_views/calvingsBirth")
            .then((results) => {
              const mainData = this.filterAndGetMainData(results.rows);
              const dataByHerd = this.getDataByHerd(mainData);
              const dataFiltered = this.filterByDate(dataByHerd);
              this.setBirthReportDynamicHeaders(dataFiltered);

              const dataByBreed = this.getDataByBreed(dataFiltered);
              const dataByBreddWithDeathPercent = dataByBreed.map(
                (breedObj) => {
                  const calfDiedLength = this.getSumOfColumn(
                    breedObj.data,
                    "calfDied"
                  );
                  return {
                    ...breedObj,
                    deathLossPercent:
                      breedObj.total > 0
                        ? this.$utils.round(
                            (calfDiedLength * 100) / breedObj.total
                          )
                        : 0,
                  };
                }
              );

              this.BirthReport.tableDataByHerd =
                dataByBreddWithDeathPercent.sort((a, b) => {
                  if (a.herd.toLowerCase() > b.herd.toLowerCase()) return 1;
                  if (a.herd.toLowerCase() < b.herd.toLowerCase()) return -1;
                  return 0;
                });
              this.BirthReport.year = `${moment(
                this.BirthReport.fromDate
              ).year()} - ${moment(this.BirthReport.toDate).year()}`;
              this.BirthReport.loading = false;
            });
        });
    },
    filterAndGetMainData: function (data) {
      return data.map(({ value }) => ({
        birthCalvingEase: value.birthCalvingEase,
        birthDate: value.birthDate.substr(0, 10),
        birthWeight: value.birthWeight,
        breed: value.breed,
        herd: value.herd,
        sex: value.sex,
        status: value.status,
        vigor: value.vigor,
      }));
    },
    filterByDate: function (herdArray) {
      return herdArray
        .map((element) => {
          const dataFilteredByDate = element.data.filter(
            ({ birthDate }) =>
              birthDate >= this.BirthReport.fromDate &&
              birthDate <= this.BirthReport.toDate
          );
          return {
            ...element,
            total: dataFilteredByDate.length,
            avgBirthWeight: this.$utils.round(
              this.getSumOfColumn(dataFilteredByDate, "birthWeight") /
                dataFilteredByDate.length
            ),
            data: dataFilteredByDate,
          };
        })
        .filter(({ data }) => data.length > 0);
    },
    getDataByHerd: function (data) {
      const herdObject = data.reduce((previous, result) => {
        if (result.herd) {
          if (!previous[result.herd]) previous[result.herd] = [result];
          else previous[result.herd].push(result);
        } else {
          if (!previous[this.notAssigned])
            previous[this.notAssigned] = [result];
          else previous[this.notAssigned].push(result);
        }
        return previous;
      }, {});

      return Object.keys(herdObject).map((herd) => {
        return {
          data: herdObject[herd],
          herd,
        };
      });
    },
    getDataByBreed: function (dataFiltered) {
      let dataByBreed = dataFiltered.map((element) => {
        const breedObj = element.data.reduce((previous, result) => {
          if (!previous[result.breed]) previous[result.breed] = [result];
          else previous[result.breed].push(result);
          return previous;
        }, {});
        const dynamicSexes = this.BirthReport.dynamicHeaders; // [{key: "", label: ""}]
        const breedArray = Object.keys(breedObj).map((breed, index) => {
          const breedLength = breedObj[breed].length;
          let birthEasy = 0;
          let birthDifficult = 0;
          let vigorGood = 0;
          let vigorFair = 0;
          breedObj[breed]
            .filter(
              ({ birthCalvingEase }) =>
                birthCalvingEase && birthCalvingEase.trim() != ""
            )
            .forEach(({ birthCalvingEase }) => {
              const calvingScore = birthCalvingEase[0];
              if (calvingScore <= 2) birthEasy++;
              if (calvingScore > 2) birthDifficult++;
            });

          breedObj[breed]
            .filter(({ vigor }) => vigor && vigor.trim() != "")
            .forEach(({ vigor }) => {
              const vigorScore = vigor[0];
              if (vigorScore <= 2) vigorGood++;
              if (vigorScore > 2) vigorFair++;
            });

          const totalWeight = breedObj[breed].reduce(
            (previous, { birthWeight }) => {
              previous += birthWeight;
              return previous;
            },
            0
          );

          const sexObject = dynamicSexes.reduce((previous, { key }) => {
            previous[key] = breedObj[breed].filter(
              ({ sex }) => sex == key
            ).length;
            return previous;
          }, {});

          const calfDied = breedObj[breed].filter(
            ({ status }) => status == "Dead" || status == null
          ).length;

          return {
            id: index,
            breed,
            calvesBorn: breedLength,
            ...sexObject,
            avgBirthWeight:
              breedLength > 0
                ? this.$utils.round(totalWeight / breedLength)
                : 0,
            birthEasy,
            birthDifficult,
            vigorGood,
            vigorFair,
            calfDied,
            deathLossPercent:
              breedLength > 0
                ? this.$utils.round((calfDied * 100) / breedLength)
                : 0,
            totalLiveCalves: breedLength - calfDied,
            activeCalves: 0,
          };
        });
        return {
          ...element,
          data: breedArray,
        };
      });
      if (dataByBreed.length == 0) {
        dataByBreed = [
          {
            avgBirthWeight: 0,
            data: [],
            herd: this.notAssigned,
            total: 0,
          },
        ];
      }
      return dataByBreed;
    },
    getSumOfColumn: function (array, column) {
      return array.reduce((previous, result) => {
        previous += result[column];
        return previous;
      }, 0);
    },
    setBirthReportDynamicHeaders: function (data) {
      /**
       * We no longer need "key" and "label" fields for the data table
       * but are still used to compute or fill values
       */
      this.BirthReport.dynamicHeaders = [];
      data.forEach((element) => {
        const breedSexesArray = element.data.reduce((previous, { sex }) => {
          if (!previous.includes(sex)) previous.push(sex);
          return previous;
        }, []);

        breedSexesArray.forEach((breedSex) => {
          if (
            !this.BirthReport.dynamicHeaders.some(
              (elem) => elem.value === breedSex
            )
          )
            this.BirthReport.dynamicHeaders.push({
              align: "start",
              key: breedSex,
              label: breedSex,
              sortable: false,
              text: breedSex,
              value: breedSex,
            });
        });
      });
    },
    printTable: function () {
      window.print();
    },
    exportToCSV: function () {
      const selected = this.BirthReport.tableDataByHerd.filter(
        (table) => table.selected.length
      );
      if (!selected.length) {
        return this.$notify({
          group: "forms",
          text: "Please select at least 1 row.",
          title: "",
          type: "info",
        });
      }
      this.BirthReport.tableDataByHerd.forEach((table) => {
        if (!table.selected.length) return;

        const headers = this.BirthReportHeaders.filter((header) => header.text);
        const fields = headers.map((header) => header.text);
        const data = table.selected.map((row) =>
          headers.map(({ value }) => row[value])
        );
        this.$herdMeta.exportToCSV(fields, data, table.herd);
      });
    },
    getTotalOf: function (table, field) {
      return table.data.reduce((result, item) => {
        return (Number(item[field]) || 0) + result;
      }, 0);
    },
  },
};
</script>

<style scoped>
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
