const initialState = () => ({
  data: {
    // Using " " forces field to push label to top
    age: " ",
    ai: {},
    birthDate: " ",
    birthRanch: " ",
    birthVisual: " ",
    breed: " ",
    comment: " ",
    guid: null,
    herd: " ",
    number: " ",
    status: " ",
    tagValues: "? / ?",
  },
});

// state
const state = initialState();

// getters
const getters = {
  getGuid: (state) => state.data.guid,
  getInformation: (state) => state.data,
  getTagValues: (state) => state.data.tagValues,
  getStatus: (state) => state.data.status,
};

// actions
const actions = {
  reset: ({ commit }) => {
    commit("RESET");
  },
  setStatus: ({ commit }, { status }) => {
    commit("setStatus", { status });
  },
  setInformation: (
    { commit },
    {
      ai,
      birthDate,
      birthRanch,
      birthVisual,
      breed,
      comment,
      guid,
      herd,
      number,
      status,
      tagValues,
    }
  ) => {
    commit("setInformation", {
      age: Animal.getAgeOfTimestamp(birthDate, {
        ignoreDays: true,
        ignoreHours: true,
      }),
      birthDate: birthDate || " ",
      birthRanch: birthRanch || " ",
      birthVisual: birthVisual || " ",
      breed: breed || " ",
      comment: comment || " ",
      guid,
      herd: herd || " ",
      number: number || " ",
      ai: ai || {},
      status: status || " ",
      tagValues: tagValues || " ",
    });
  },
};

// mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setStatus: (state, { status }) => {
    state.data.status = status;
  },
  setInformation(
    state,
    {
      age,
      ai,
      birthDate,
      birthRanch,
      birthVisual,
      breed,
      comment,
      guid,
      herd,
      number,
      status,
      tagValues,
    }
  ) {
    state.data = {
      age,
      ai,
      birthDate,
      birthRanch,
      birthVisual,
      breed,
      comment,
      guid,
      herd,
      number,
      status,
      tagValues,
    };
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
