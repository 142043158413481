<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 13 23" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M1.14657 0.938663C0.964577 0.982007 0.804758 1.09322 0.690134 1.25627L0.582876 1.4088L0.572521 2.91834L0.562165 4.42785L5.96388 4.41885L11.3656 4.40984V2.933C11.3656 1.50431 11.3632 1.45154 11.2913 1.31382C11.2034 1.14567 10.9889 0.983964 10.7936 0.938663C10.6255 0.899695 1.31019 0.89966 1.14657 0.938663ZM2.12303 5.5747L2.1131 6.04594L1.92168 6.07344C1.21062 6.17561 0.561062 6.64696 0.241886 7.29235C-0.0221661 7.82636 -0.00547653 7.24787 0.00452329 15.5199L0.0134916 22.9504H6.0632H12.1129V15.4595C12.1129 7.08054 12.1351 7.79921 11.8596 7.2494C11.537 6.60582 10.8509 6.13572 10.1399 6.07124L9.87096 6.04682L9.85317 5.5842L9.83537 5.12157L5.98416 5.11254L2.13295 5.10346L2.12303 5.5747ZM10.814 14.4986V19.4096H6.0632H1.3124V14.4986V9.58768H6.0632H10.814V14.4986ZM4.96646 10.9632C4.95311 10.9765 4.94223 11.473 4.94223 12.0664V13.1453L3.84794 13.1547L2.75365 13.1641L2.74426 14.2762L2.73486 15.3883H3.83801H4.94119L4.95062 16.4826L4.96002 17.5769H6.0632H7.16638L7.17578 16.4831L7.18521 15.3893L8.27896 15.3799L9.37275 15.3705V14.2673V13.1641L8.27896 13.1547L7.18521 13.1453L7.17578 12.0516L7.16638 10.9578L6.07854 10.9484C5.48022 10.9432 4.97977 10.9499 4.96646 10.9632Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
