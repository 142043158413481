import "./components/global";
import "./registerServiceWorker";
import "@/components/utils/index.js";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue } from "bootstrap-vue";
import { Config } from "./mixins/Config";
import App from "./App.vue";
import CheckDuplicates from "./components/notifications/CheckDuplicates.vue";
import draggable from "vuedraggable";
import Notifications from "vue-notification";
import OnlineWarning from "./components/dialogs/OnlineWarning.vue";
import PortalVue from "portal-vue";
import router from "./router";
import Shortkey from "vue-shortkey";
import store from "./store";
import Vue from "vue";
import Vue2TouchEvents from "vue2-touch-events";
import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";
import VueOffline from "vue-offline";
import vueTabEvents from "./plugins/vueTabEvents";
import vuetify from "./plugins/vuetify";

Vue.component("draggable", draggable);
Vue.component("check-duplicates", CheckDuplicates);
Vue.component("online-warning", OnlineWarning);

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: "",
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: "tap",
});
Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(VueCookies);
Vue.use(Notifications);
Vue.use(Shortkey);
Vue.use(vueTabEvents);
Vue.use(VueOffline, {
  mixin: true,
  storage: true,
});
Vue.mixin(Config);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
