<template>
  <div>
    <v-dialog
      max-width="280"
      persistent
      scrollable
      transition="dialog-transition"
      v-model="showAlerts"
    >
      <v-card class="mx-auto">
        <v-card-title class="text-h5 warning">
          <v-icon>mdi-alert</v-icon>
          <div class="ml-2">Alert</div>
        </v-card-title>
        <v-card-text
          class="d-flex align-center justify-center pa-0"
          style="height: 70px; width: 100%"
        >
          <div class="text-h5 text--primary">Cull status detected</div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :color="$vuetify.theme.dark ? 'teal accent-2' : 'teal darken-1'"
            @click="showAlerts = false"
            text
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition v-if="(status || '').toLowerCase() == 'cull'">
      <v-btn
        :key="'showAlerts'"
        @click="showAlerts = true"
        bottom
        class="v-btn--example"
        color="warning"
        dark
        elevation="5"
        fab
        fixed
        large
        right
      >
        <v-icon>mdi-alert</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
export default {
  name: "CullAlert",
  props: {
    status: {
      type: String | Boolean,
      default: "alive",
    },
  },
  data: () => ({
    showAlerts: false,
  }),
  watch: {
    status: function (newStatus) {
      if (!!newStatus && newStatus.toLowerCase() == "cull")
        this.showAlerts = true;
      else this.showAlerts = false;
    },
  },
};
</script>