<template>
  <v-card class="rounded-lg light-elevation" elevation="0">
    <v-card-text>
      <div class="d-flex align-center mb-2">
        <div>{{ title }}</div>
        <div class="ml-auto">
          <div class="d-flex align-center">
            <slot name="append-title" />
            <a :href="link">
              <v-icon class="ml-1" v-if="link != null">mdi-open-in-new</v-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex align-center">
        <div
          class="
            text-h4 text--primary
            font-weight-medium
            mr-3
            whitespace-nowrap
          "
        >
          {{ total }}
        </div>
        <slot name="append-content" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    total: null,
  },
};
</script>


<style scoped>
.elevation-0.light-elevation {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.15), 0 1px 2px -1px rgb(0 0 0 / 0.15) !important;
}
</style>
