<template>
  <div>
    <calf-container :headerTitle="getTranslation('Group Statistics')">
      <template #prepend-header>
        <div>
          <v-btn color="primary" @click="refresh">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>
      </template>
      <template #append-header>
        <div>
          <v-select
            :items="groupNumberByLastUsed"
            :label="getTranslation('Group')"
            :menu-props="{ offsetY: true }"
            clearable
            dense
            filled
            hide-details
            outlined
            v-model="selectedGroupNumber"
          >
          </v-select>
        </div>
      </template>
      <template #content>
        <v-row>
          <v-col>
            <v-data-table
              :headers="summaryTableHeaders"
              :items="summaryTableItems"
              class="dashboard-data-table"
              hide-default-footer
              mobile-breakpoint="0"
            />
            <v-subheader class="pa-0 mt-5" style="font-size: 16px"
              >Previous Entry</v-subheader
            >
            <v-data-table
              :headers="previousEntryHeaders"
              :items="previousEntryItems"
              class="dashboard-data-table"
              hide-default-footer
              mobile-breakpoint="0"
            >
              <template #item.tagValues="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.guid },
                  }"
                  class="subtitle-2"
                  target="_blank"
                >
                  {{ item.tagValues }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </calf-container>
  </div>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import Container from "../SummaryContainer.vue";
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "calf-wean-group-statistics",
  mixins: [TranslationMixin],
  components: {
    "calf-container": Container,
  },
  data: () => ({
    currentGroupNumber: null,
    dynamicHeaders: [],
    herdMeta: null,
    initialLoad: true,
    previousEntryItems2: [
      { tag: 123123, sex: "cow", breed: "2-breed", weight: "123" },
    ],
    selectedGroupNumber: null,
    summaryTableItems2: [
      {
        bulls: 23,
        bullsAvgWt: 123,
        heifers: 123,
        heifersAvgWt: 56,
        steers: 5,
        steersAvgWt: 56,
        totalWeaned: 450,
      },
    ],
    weanWeightEngtiesLast24h: [],
  }),
  computed: {
    previousEntryItems: function () {
      if (this.summaryTableItems.length == 0) return [];
      else
        return [
          this.summariesByGroupNumber[this.selectedGroupNumber].latestResult,
        ];
    },
    summaryTableItems: function () {
      if (
        !this.selectedGroupNumber ||
        Object.keys(this.summariesByGroupNumber).length == 0
      )
        return [];
      return [this.summariesByGroupNumber[this.selectedGroupNumber]];
    },
    groupToWeanWeightEntries: function () {
      return this.weanWeightEngtiesLast24h.reduce((reduction, value) => {
        if (!reduction[value.groupNumber]) {
          reduction[value.groupNumber] = [];
        }
        reduction[value.groupNumber].push(value);
        return reduction;
      }, {});
    },
    groupNumberByLastUsed: function () {
      return Object.keys(this.groupToLastWeanWeight)
        .reduce((reduction, key) => {
          reduction.push(this.groupToLastWeanWeight[key]);
          return reduction;
        }, [])
        .sort(
          (resultA, resultB) =>
            // Sorting createdOn if possible, otherwise use time recorded
            moment(resultB.createdOn || resultB.timeRecorded).unix() -
            moment(resultA.createdOn || resultA.timeRecorded).unix()
        )
        .map((result) => result.groupNumber)
        .filter((group) => group != undefined);
    },
    groupToAverageWeanWeight: function () {
      return Object.keys(this.groupToWeanWeightEntries).reduce(
        (reduction, key) => {
          const summary = {
            averageWeight: 0,
            count: 0,
            sexSummaries: {},
            totalWeight: 0,
          };
          this.groupToWeanWeightEntries[key].forEach((weightEntry) => {
            summary.count++;
            summary.totalWeight += +weightEntry.weight;
            summary.averageWeight = Utils.round(
              summary.totalWeight / summary.count,
              2
            );

            const sex = weightEntry.sex || "?";
            if (!summary.sexSummaries[sex])
              summary.sexSummaries[sex] = {
                averageWeight: null,
                count: 0,
                totalWeight: 0,
              };
            summary.sexSummaries[sex].count++;
            summary.sexSummaries[sex].totalWeight += +weightEntry.weight;
            summary.sexSummaries[sex].averageWeight = Utils.round(
              summary.sexSummaries[sex].totalWeight /
                summary.sexSummaries[sex].count,
              2
            );
          });
          reduction[key] = summary;

          return reduction;
        },
        {}
      );
    },
    groupToLastWeanWeight: function () {
      return Object.keys(this.groupToWeanWeightEntries).reduce(
        (reduction, key) => {
          let last = null;

          this.groupToWeanWeightEntries[key].forEach((weightEntry) => {
            if (!last || last.timeRecorded < weightEntry.timeRecorded)
              last = weightEntry;
          });
          reduction[key] = last;

          return reduction;
        },
        {}
      );
    },
    summariesByGroupNumber: function () {
      const summaries = {};

      for (const [groupNumber, resultsForGroup] of Object.entries(
        this.groupToWeanWeightEntries
      )) {
        const groupStats =
          this.groupToAverageWeanWeight[this.selectedGroupNumber];

        // Need direction to fill heifers, bulls and steers
        const summary = {
          latestResult: this.groupToLastWeanWeight[this.selectedGroupNumber],
          totalAvgWeight: groupStats.averageWeight,
          totalWeaned: resultsForGroup.length,
        };

        const groupSexAverages = groupStats.sexSummaries;
        // console.debug(groupSexAverages);

        this.dynamicHeaders = [];
        Object.keys(groupSexAverages).forEach((sex) => {
          summary[sex] = groupSexAverages[sex].count;
          summary[sex + "AvgWt"] = groupSexAverages[sex].averageWeight;

          this.dynamicHeaders.push({
            sortable: false,
            text: sex,
            value: sex,
          });
          this.dynamicHeaders.push({
            sortable: false,
            text: this.getTranslation("calfWeanPage.groupSummary.avgWeight"),
            value: sex + "AvgWt",
          });
        });
        // console.debug(summary);

        if (groupNumber != "undefined") summaries[groupNumber] = summary;
      }

      return summaries;
    },
    summaryTableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.totalWeaned"),
          value: "totalWeaned",
        },
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.totalAvgWeight"),
          value: "totalAvgWeight",
        },
      ].concat(this.dynamicHeaders);
    },
    previousEntryHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.tag"),
          value: "tagValues",
        },
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.sex"),
          value: "sex",
        },
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.breed"),
          value: "breed",
        },
        {
          sortable: false,
          text: this.getTranslation("calfWeanPage.groupSummary.weight"),
          value: "weight",
        },
      ];
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    await this.init();
    this.setUpWatch();
    // Useful to debug computed values
    // console.log("Wean Weight Entries Last24h", this.weanWeightEngtiesLast24h);
    // console.log("Group To Last Wean Weight", this.groupToLastWeanWeight);
    // console.log("Group Number By Last Used", this.groupNumberByLastUsed);
    // console.log("Group To Calf Entries", this.groupToWeanWeightEntries);
    // console.log("Summaries By Group Number", this.summariesByGroupNumber);
    // console.log("Average weights for group number", this.groupToAverageWeanWeight);

    EventBus.$on("summary-refresh-start", this.init);

    EventBus.$on("summary-refresh-done", async () => {
      EventBus.$emit("set-current-group-number", this.currentGroupNumber);
    });

    EventBus.$on("set-current-group-number", (groupNumber) => {
      this.currentGroupNumber = groupNumber;
      EventBus.$emit("count-for-group-number", {
        groupNumber,
        count:
          this.summariesByGroupNumber &&
          this.summariesByGroupNumber[groupNumber]
            ? this.summariesByGroupNumber[groupNumber].totalWeaned
            : 0,
      });
    });
  },
  methods: {
    // When database changes -> update
    setUpWatch: function () {
      this.changeWatch = this.$herdMeta.pouches.organization
        .changes({
          filter: function (doc) {
            return doc.tableName === "animals";
          },
          live: true,
          return_docs: false,
          since: "now",
        })
        .on("change", (change) => {
          if (this.sequence && this.sequence > change.seq) return;
          this.sequence = change.seq;
          this.init();
        })
        .on("error", function (err) {
          // handle errors
        });
    },
    beforeDestroy() {
      // Memory leak still occurs even though we call this. It seems that it is not firing at all or not soon enough.
      // TODO: Find way to fix this memory leak
      if (this.changeWatch) this.changeWatch.cancel();
    },
    init: function () {
      return new Promise((resolve, reject) => {
        return this.herdMeta.loaded.fail(reject).then(() => {
          return this.herdMeta.pouches.organization
            .query("local_views/weanWeightEntries", {
              group_level: 1,
            })
            .then((results) => {
              const startOfToday = moment().startOf("day").unix();
              this.weanWeightEngtiesLast24h = results.rows
                .map((result) => result.value)
                .filter(
                  (result) => moment(result.createdOn).unix() >= startOfToday
                );

              if (this.groupNumberByLastUsed.length == 0) {
                this.selectedGroupNumber = null;
              } else if (
                this.initialLoad ||
                !this.selectedGroupNumber ||
                (this.groupNumberByLastUsed.length &&
                  (!this.summariesByGroupNumber[this.selectedGroupNumber] ||
                    (this.groupNumberByLastUsed.length > 1 &&
                      this.selectedGroupNumber ==
                        this.groupNumberByLastUsed[1])))
              ) {
                this.selectedGroupNumber = this.groupNumberByLastUsed[0];
              }
              this.initialLoad = false;

              EventBus.$emit("summary-refresh-done", this.currentGroupNumber);
              resolve();
            })
            .catch(reject);
        });
      });
    },
    refresh: function () {
      this.$herdMeta.reload().fail().then(this.init);
    },
  },
};
</script>
