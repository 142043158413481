var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$vuetify.theme.dark ? 'dark-background-color' : 'light-background-color',
    'height-calc',
    'px-4',
    'py-3' ]},[_c('v-dialog',{attrs:{"max-width":"900px","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.transfersDialog.show),callback:function ($$v) {_vm.$set(_vm.transfersDialog, "show", $$v)},expression:"transfersDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Transfer History")]),_c('v-icon',{staticClass:"buttons",on:{"click":function($event){_vm.transfersDialog.show = false}}},[_vm._v(" mdi-close ")])],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"py-2"},[_c('transfers-table',{attrs:{"animalID":_vm.guid}})],1)],1)],1),_c('div',{staticClass:"g-row"},[_c('div',{staticClass:"g-col g-col-1 justify-center align-center",staticStyle:{"min-width":"150px"}},[_c('v-icon',{staticClass:"w-100",attrs:{"size":"75","color":"#3465a1"}},[_vm._v(" "+_vm._s(_vm.animalType.icon)+" ")]),_c('v-chip',{staticClass:"blue lighten-3 white--text w-fit-content"},[_vm._v(" "+_vm._s(_vm.animalType.type)+" ")]),(_vm.animalWasDeleted)?_c('v-chip',{staticClass:"w-fit-content mt-2",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.getTranslation("Deleted"))+" ")]):_vm._e()],1),_c('div',{staticClass:"g-col g-col-2 justify-center allow-grow-4 mobile-row mobile-justify-center mobile-align-center mobile-my-4"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.tagSummary)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.animalAge))]),(_vm.getFinalTransferStatement)?[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.getFinalTransferStatement)+" "),_c('button',{staticClass:"btn btn-link link",on:{"click":function($event){_vm.transfersDialog.show = true}}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("History"))+" ")])])]:_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.currentComment)+" ")]),(_vm.animalWasMerged)?_c('div',{staticClass:"danger"},[_vm._v(" Merged "),_c('v-icon',[_vm._v("mdi-arrow-right")]),_c('router-link',{staticClass:"text-none ml-1",attrs:{"to":{
            name: 'AnimalDetails',
            query: {
              id: _vm.animal.doc.mergedTo[0].otherGUID,
            },
          }}},[_vm._v(" "+_vm._s(_vm.animal.doc.mergedTo[0].otherTagValues)+" ")])],1):_vm._e()],2),_c('div',{staticClass:"g-col g-col-1 align-center justify-center"},[_c('status-history',{attrs:{"animalID":_vm.guid}})],1)]),_c('div',{staticClass:"g-row mt-5"},[(_vm.animal)?[(!_vm.animalWasDeleted)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("Delete"))+" ")]):_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.animalWasMerged,"color":"error"},on:{"click":function($event){return _vm.animal.restoreAnimal()}}},[_vm._v(" "+_vm._s(_vm.getLabelTranslation("Undelete"))+" ")])]:_vm._e(),(_vm.isSuperAdmin && _vm.guid)?_c('router-link',{staticClass:"mb-4",staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'AnimalDetailsDeprecated',
        query: { id: _vm.guid },
      }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"default"}},[_c('div',{staticStyle:{"color":"#1976d2"}},[_vm._v("Classic View")])])],1):_vm._e(),(_vm.isSuperAdmin && _vm.guid)?_c('v-btn',[_c('a',{attrs:{"href":("/couchdb/_utils/#database/organization-" + _vm.$organizationID + "/" + _vm.guid),"target":"_blank"}},[_vm._v("CouchDB")])]):_vm._e()],2),_c('v-row',{staticClass:"ma-0"},[_c('v-tabs',{attrs:{"grow":"","show-arrows":""},model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},_vm._l((_vm.tabs),function(tab,idx){return _c('v-tab',{key:idx},[(tab.badge)?[_c('v-badge',{attrs:{"color":"#EBF1F9","content":tab.badge}},[_vm._v(" "+_vm._s(tab.title)+" ")])]:[_vm._v(" "+_vm._s(tab.title)+" ")]],2)}),1),_c('v-tabs-items',{model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_c('v-tab-item',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}):_c('comment-history-table',{attrs:{"animal":_vm.animal,"tableData":_vm.comments},on:{"data-editted":_vm.getAnimalSummaries}})],1),_c('v-tab-item',[_c('general-details',{attrs:{"animalID":_vm.guid,"showExtraInformation":""}})],1),_c('v-tab-item',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}):_c('div',{staticClass:"g-row"},[_c('birth-details',{class:[
              ['sm', 'xs', 'md'].includes(_vm.$vuetify.breakpoint.name)
                ? 'force-w-100'
                : '',
              'g-col-2' ],attrs:{"animalID":_vm.guid,"showExtraInformation":""}}),_c('family-details',{staticClass:"g-col",attrs:{"animalID":_vm.guid}})],1)],1),_c('v-tab-item',[_c('tag-history',{attrs:{"animalID":_vm.guid,"showExtraInformation":""}})],1),_c('v-tab-item',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}):_c('financials-details',{attrs:{"animal":_vm.animal,"data":_vm.financialsDetails}})],1),_c('v-tab-item',[_c('weight-history-table',{attrs:{"animalID":_vm.guid,"showExtraInformation":""},on:{"data-editted":_vm.getAnimalSummaries}})],1),_c('v-tab-item',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}):_c('treatment-history-table',{attrs:{"animalID":_vm.guid,"showExtraInformation":""}})],1),_c('v-tab-item',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}):_c('div',[(_vm.gender === 'Male' || _vm.bullTestHistory.length > 0)?_c('bull-test-history-table',{staticClass:"mb-5",attrs:{"animalID":_vm.guid,"showTitle":""}}):_vm._e(),(_vm.gender === 'Female' || _vm.heiferTestHistory.length > 0)?_c('heifer-test-history-table',{staticClass:"mb-5",attrs:{"animalID":_vm.guid,"showTitle":""}}):_vm._e(),(_vm.gender === 'Female' || _vm.pregCheckHistory.length > 0)?_c('preg-check-history-table',{staticClass:"mb-8",attrs:{"animalID":_vm.guid,"showTitle":""}}):_vm._e(),_c('calving-history-table',{attrs:{"animalID":_vm.guid,"showTitle":""}})],1)],1),_c('v-tab-item',[_c('purchase-sale-history',{attrs:{"animalID":_vm.guid}})],1),_c('v-tab-item',[_c('harvest',{attrs:{"animal":_vm.animal}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }