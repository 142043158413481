<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Sighting History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Sighting_History ${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': animal.disallowChanges(),
          }"
          :disabled="animal.disallowChanges() || !item.type"
          @click="deleteSighting(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "SightingHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
    tableData: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
        },
        {
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
        },
        {
          text: this.getTranslation("prevLocation"),
          value: "previousLocation",
        },
        { text: this.getTranslation("Group Number"), value: "groupNumber" },
        { text: this.getTranslation("reason"), value: "reason" },
        { text: this.getTranslation("type"), value: "type" },
        {
          text: this.getTranslation("Time in Field"),
          value: "timeInField",
        },
        {
          text: this.getTranslation("timeRecorded"),
          value: "timeRecorded",
        },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = (e) => {
      this.init();
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      await this.herdMeta.promiseLoaded();
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      this.getSightingHistory();
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
    },
    getSightingHistory: async function () {
      this.table.loading = true;
      try {
        const result = await this.pouches.organization.animalSummaries(
          true,
          true,
          {
            include_docs: true,
            key: this.animalID,
          }
        );
        if (result.rows.length === 0) return;
        const summaries = result.rows[0].value;
        this.table.data = summaries.sightingHistory || [];
      } catch (error) {
        throw error;
      } finally {
        this.table.loading = false;
      }
    },
    deleteSighting: async function (item) {
      try {
        await this.animal.deleteRecord(`${item.type}s`, item.id);
        this.getSightingHistory();
        this.$notify({
          group: "forms",
          text: "Deleted",
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
  },
};
</script>