<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8">
      <v-col cols="12">
        <div class="my-6">
          <div class="d-flex flex-wrap justify">
            <h4>
              {{ labels.fileETL.supportedFiles[locale] }}
            </h4>
            <a
              class="btn btn-secondary white--text buttons-collection mb-1 ml-1"
              data-toggle="modal"
              data-target="#legend"
              >{{ getLabelTranslation("fileETL.legend") }}</a
            >
          </div>
          <v-divider></v-divider>
        </div>
        <div>
          <div id="app" v-cloak>
            <div id="body">
              <div id="left">
                <div id="logo">
                  <img
                    :src="require('@/assets/img/fileETL/logo.png')"
                    class="logo"
                    alt="SheetJS Logo"
                    width="128px"
                    height="128px"
                  />
                </div>

                <!-- Drop target -->
                <div ref="drop" id="drop">
                  {{ labels.fileETL.dropFile[locale] }}
                  <br />
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    class="inputfile"
                    :accept="acceptableFileTypes"
                  />
                  <label for="file" id="file_label"
                    ><span class="btn"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                      >
                        <path
                          d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                        ></path></svg></span
                    >{{ getLabelTranslation("fileETL.useFile") }}</label
                  >

                  <br />
                  <a
                    v-if="
                      automated &&
                      etlFileModel &&
                      !etlFileModel.isFeed &&
                      etlFileModel.archer.loaded
                    "
                    id="cannotBeImportedBtn"
                    class="btn btn-default buttons-collection btn-sm"
                    :class="{
                      disabled:
                        !etlFileModel.archer.fileUploadsId ||
                        !etlFileModel.fileName ||
                        etlFileModel.archer.status,
                    }"
                    @click="importWorkBook.getCurrentSheet().cannotBeImported()"
                    >{{ getLabelTranslation("fileETL.cannotBeImportedBtn") }}</a
                  >
                </div>
              </div>

              <div id="right">
                <!-- Legend Modal -->
                <div
                  class="modal fade"
                  id="legend"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="attributesLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">
                          {{ labels.fileETL.legend[locale] }}
                        </h4>
                      </div>
                      <div class="modal-body">
                        <h2>{{ labels.fileETL.importResults[locale] }}</h2>
                        <table
                          class="legend table table-striped table-bordered"
                        >
                          <tr>
                            <td class="import success" style="color: black">
                              {{ labels.fileETL.importSuccess[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import info" style="color: black">
                              {{ labels.fileETL.importInfo[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import warning">
                              {{ labels.fileETL.rowNoData[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import duplicate">
                              {{ labels.fileETL.tagValueNotSufficient[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import error">
                              {{ labels.fileETL.rowCantImport[locale] }}
                            </td>
                          </tr>
                          <tr class="no-import">
                            <td>{{ labels.fileETL.ignoreImport[locale] }}</td>
                          </tr>
                        </table>
                        <h2>{{ labels.fileETL.forValues[locale] }}</h2>
                        <table class="legend">
                          <tr>
                            <td class="import warning">
                              {{ labels.fileETL.valueEmpty[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import duplicate">
                              {{ labels.fileETL.valueDuplicate[locale] }}
                            </td>
                          </tr>
                          <tr>
                            <td class="import error">
                              {{ labels.fileETL.valueInvalid[locale] }}
                            </td>
                          </tr>
                          <tr class="no-import">
                            <td>{{ labels.fileETL.ignoreImport[locale] }}</td>
                          </tr>
                        </table>
                        <strong>{{ labels.fileETL.note[locale] }}:</strong>
                        {{ labels.fileETL.colorReversed[locale] }}
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary white--text"
                          data-dismiss="modal"
                        >
                          {{ labels.closeButton[locale] }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="etlFileModel"
                  class="panel-group"
                  style="max-width: 1100px"
                >
                  <div class="card card-body pa-0 mb-4">
                    <div class="panel-heading blue darken-1">
                      <h4 class="panel-title pa-3 ma-0">
                        <a
                          class="white--text"
                          data-toggle="collapse"
                          href="#filesFromArcherPanel"
                        >
                          {{ getLabelTranslation("fileETL.filesFromArcher") }}
                        </a>
                      </h4>
                    </div>
                    <div
                      id="filesFromArcherPanel"
                      class="panel-collapse collapse"
                    >
                      <div class="panel-body pa-3">
                        <div>
                          <table
                            class="table table-striped table-bordered dataTable"
                            id="filesFromArcher"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>
                                  {{ getLabelTranslation("fileETL.fileName") }}
                                </th>
                                <th>
                                  {{ getLabelTranslation("fileETL.status") }}
                                </th>
                                <th>
                                  {{
                                    getLabelTranslation(
                                      "fileETL.approximateLineCount"
                                    )
                                  }}
                                </th>
                                <th>
                                  {{ getLabelTranslation("fileETL.uploader") }}
                                </th>
                                <th>
                                  {{ getLabelTranslation("fileETL.uploaded") }}
                                </th>
                                <th>
                                  {{
                                    getLabelTranslation("fileETL.lastUpdated")
                                  }}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="'file-' + index"
                                v-for="(row, index) in etlFileModel.archer
                                  .history"
                              >
                                <td>
                                  <a
                                    class="btn btn-primary white--text"
                                    @click="openHistoricalArcherFile(row)"
                                  >
                                    {{ getLabelTranslation("fileETL.open") }}
                                  </a>
                                </td>
                                <td>{{ row.fileName }}</td>
                                <td>{{ row.status }}</td>
                                <td>{{ row.approximateLineCount }}</td>
                                <td>{{ row.uploader }}</td>
                                <td>{{ row.createdOn }}</td>
                                <td>{{ row.lastUpdated }}</td>
                                <td>
                                  <a
                                    class="btn btn-primary white--text"
                                    @click="downloadHistoricalArcherFile(row)"
                                  >
                                    {{
                                      getLabelTranslation("fileETL.download")
                                    }}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="bg-light pa-3 panel-footer">
                        {{ labels.panelFooter[locale] }}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="sheetsList">
                  <div
                    class="dt-buttons btn-group d-flex flex-wrap"
                    v-if="importWorkBook"
                  >
                    <a
                      class="btn btn-sm dynamic-buttons mb-1"
                      :class="{
                        active: sheet.selected,
                        'bg-orange': sheet.selected,
                      }"
                      :id="'btn-sheet-select-' + indexSheet"
                      :key="'btn-' + indexSheet"
                      v-for="(sheet, indexSheet) in importWorkBook.sheets"
                      @click="importWorkBook.makeSheetCurrent(indexSheet)"
                      >{{ sheet.name }}
                      <template v-if="sheet.submissionInProgress()">
                        ({{ sheet.submitting.completed }} /
                        {{ sheet.rows.length }})
                      </template>
                      <template v-else>
                        ({{ sheet.rows.length ? sheet.rows.length : "?" }})
                      </template>
                    </a>
                  </div>
                </div>

                <div style="font-size: larger" v-if="!importWorkBook">
                  <p>
                    {{ labels.fileETL.chooseFile[locale] }}
                    <br />
                    {{ labels.fileETL.noHeader[locale] }}
                  </p>
                  <p>
                    {{ labels.fileETL.encounterIssue[locale] }}
                    <a href="https://fort-supply.com/contact-us/">{{
                      labels.fileETL.contactForm[locale]
                    }}</a
                    >.
                  </p>
                </div>
                <!-- Main Buttons -->
                <div v-else>
                  <div
                    class="dt-buttons btn-group sheetButtons flex-wrap"
                    v-for="(sheet, index) in importWorkBook.sheets"
                    v-show="sheet.selected"
                    :key="'sheetBtn-' + index"
                    v-if="sheet.rows.length && sheet.selected"
                  >
                    <div class="mb-2" style="width: 100%; height: 45px">
                      <v-progress-circular
                        v-show="sheet.loading || loading"
                        :size="50"
                        :width="5"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>

                    <!-- <a
                      class="
                        btn
                        mr-1
                        btn-secondary
                        white--text
                        buttons-collection
                      "
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.toggleAllSelected()"
                    >
                      <span v-if="!sheet.selectAll">{{
                        getLabelTranslation("fileETL.selectAll")
                      }}</span>
                      <span v-else>{{
                        getLabelTranslation("fileETL.deselectAll")
                      }}</span>
                    </a> -->

                    <!-- <a
                      class="
                        btn
                                                btn-secondary
                        white--text
                        buttons-collection
                      "
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.toggleAllIgnore()"
                    >
                      <span v-if="!sheet.ignoreAll">{{
                        getLabelTranslation("fileETL.ignoreAll")
                      }}</span>
                      <span v-else>{{
                        getLabelTranslation("fileETL.enableAll")
                      }}</span>
                    </a> -->

                    <!-- <a
                      class="
                        btn
                                                btn-secondary
                        white--text
                        buttons-collection
                      "
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.toggleAllNew()"
                    >
                      <span v-if="!sheet.newAll">{{
                        getLabelTranslation("fileETL.allAreNew")
                      }}</span>
                      <span v-else>{{
                        getLabelTranslation("fileETL.allAreOld")
                      }}</span>
                    </a> -->
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      v-if="sheet.hasHeaderRow"
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.flipWhetherFirstRowIsHeader()"
                      >{{ getLabelTranslation("fileETL.headerRowNo") }}</a
                    >
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      v-if="!sheet.hasHeaderRow"
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.flipWhetherFirstRowIsHeader()"
                      >{{ getLabelTranslation("fileETL.headerRow") }}</a
                    >
                    <!--- FIXME: Add functionality for this --->
                    <!--- <a class="btn btn-default buttons-collection" @click="sheet.editSelected()" :class="{'disabled': !sheet.hasSelected()}">{{getLabelTranslation("fileETL.edit")}}</a> --->
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      :class="{
                        disabled:
                          etlFileModel.isFeed ||
                          sheet.submissionInProgress() ||
                          !sheet.hasSelected(),
                      }"
                      @click="sheet.hasSelected() && sheet.deleteSelected()"
                      >{{ getLabelTranslation("fileETL.deleteRow") }}</a
                    >
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      @click="sheet.exportToCSV()"
                      >{{ getLabelTranslation("fileETL.exportToCSV") }}</a
                    >
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.addRow()"
                      >{{ getLabelTranslation("fileETL.addRow") }}</a
                    >
                    <!--- <a class="btn btn-default buttons-collection"
                      :class="{'disabled': etlFileModel.isFeed || sheet.submissionInProgress() || !sheet.hasSelectedColumns() || sheet.headerCells.length <= 1}"
                      @click="sheet.removeColumns()"
                    >{{getLabelTranslation("fileETL.deleteColumn")}}</a> --->
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      :class="{
                        disabled:
                          etlFileModel.isFeed || sheet.submissionInProgress(),
                      }"
                      @click="sheet.addColumn()"
                      >{{ getLabelTranslation("fileETL.addColumn") }}</a
                    >
                    <!-- <a
                      class="
                        btn
                                                btn-secondary
                        white--text
                        buttons-collection
                      "
                      data-toggle="modal"
                      data-target="#legend"
                      >{{ getLabelTranslation("fileETL.legend") }}</a
                    > -->
                    <a
                      class="btn mx-0 white--text buttons-collection"
                      id="dryRunToggleBtn"
                      :class="{
                        'btn-danger': !sheet.allowDryRun,
                        'btn-success': sheet.allowDryRun,
                      }"
                      @click="sheet.toggleAllowDryRun()"
                      >{{ getLabelTranslation("fileETL.dryRun") }} ({{
                        sheet.allowDryRun
                          ? getLabelTranslation("fileETL.on")
                          : getLabelTranslation("fileETL.off")
                      }})</a
                    >
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      @click="showColumnAndRowLabels = !showColumnAndRowLabels"
                      >{{ getLabelTranslation("fileETL.labels") }} ({{
                        showColumnAndRowLabels
                          ? getLabelTranslation("fileETL.on")
                          : getLabelTranslation("fileETL.off")
                      }})</a
                    >
                    <!--- FIXME: Add functionality for this --->
                    <!--- <a class="btn btn-default buttons-collection">Auto-Validate (on)</a> --->
                    <a
                      class="btn mx-0 white--text buttons-collection"
                      id="messagesBtn"
                      :class="sheet.getMessagesHTMLClasses()"
                      @click="
                        $notify({
                          group: 'forms',
                          type: 'info',
                          title: 'Info',
                          text: dedupeMergeAndCountMessages(
                            sheet.getMessages('validation')
                          ),
                          duration: 12000,
                        })
                      "
                      >{{ getLabelTranslation("fileETL.messages") }}</a
                    >
                    <a
                      class="btn mx-0 white--text buttons-collection"
                      id="importBtn"
                      :class="sheet.getSubmitHTMLClasses()"
                      @click="sheet.prepareSubmit()"
                      >{{ getLabelTranslation("fileETL.import") }}</a
                    >
                    <a
                      class="btn btn-secondary white--text buttons-collection"
                      :class="{ disabled: !sheet.hasImportResults() }"
                      @click="
                        $notify({
                          group: 'forms',
                          type: 'info',
                          title: 'Info',
                          text: dedupeMergeAndCountMessages(
                            sheet.getImportSummary()
                          ),
                          duration: 12000,
                        })
                      "
                      >{{ getLabelTranslation("fileETL.importSummary") }}</a
                    >
                    <a
                      class="btn ml-1 btn-secondary white--text buttons-collection"
                      :class="{ disabled: !sheet.memorizable() }"
                      @click="sheet.memorize()"
                      >{{ getLabelTranslation("fileETL.memorizeTemplate") }}</a
                    >
                    <a
                      class="btn ml-1 btn-secondary white--text buttons-collection"
                      @click="sheet.toggleMatchSet()"
                      >{{ getLabelTranslation("Match") }}:
                      {{ getLabelTranslation("fileETL." + sheet.matchSet) }}</a
                    >
                    <div max-width="200">
                      <select
                        class="custom-select"
                        v-if="['lt', 'gt'].indexOf(sheet.matchSet) !== -1"
                        v-model="sheet.threshold"
                        @change="sheet.thresholdChanged()"
                      >
                        <option value="365">
                          {{ getLabelTranslation("fileETL._1_year") }}
                        </option>
                        <option value="730">
                          {{ getLabelTranslation("fileETL._2_years") }}
                        </option>
                        <option value="1095">
                          {{ getLabelTranslation("fileETL._3_years") }}
                        </option>
                        <option value="1460">
                          {{ getLabelTranslation("fileETL._4_years") }}
                        </option>
                        <option value="1825">
                          {{ getLabelTranslation("fileETL._5_years") }}
                        </option>
                        <option value="3650">
                          {{ getLabelTranslation("fileETL._10_years") }}
                        </option>
                        <option value="5475">
                          {{ getLabelTranslation("fileETL._15_years") }}
                        </option>
                        <option value="7300">
                          {{ getLabelTranslation("fileETL._20_years") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />

                  <!-- Main Table -->
                  <table
                    class="table table-striped table-bordered"
                    v-for="(sheet, index) in importWorkBook.sheets"
                    v-show="sheet.selected"
                    :key="'main-' + index"
                    v-if="sheet.rows.length"
                  >
                    <thead>
                      <tr v-if="false">
                        <th></th>
                        <th>Select</th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="showColumnAndRowLabels"></th>
                        <th
                          class="centerAll"
                          v-for="(header, column) in sheet.headerCells"
                          :key="'headerCell-' + column"
                        >
                          <input
                            type="checkbox"
                            v-if="column !== 0"
                            v-model="header.selected"
                            :class="{
                              disabled:
                                etlFileModel.isFeed ||
                                sheet.submissionInProgress(),
                            }"
                            :disabled="
                              etlFileModel.isFeed ||
                              sheet.submissionInProgress()
                            "
                          />
                        </th>
                      </tr>
                      <tr>
                        <th>{{ getLabelTranslation("fileETL.type") }}</th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="showColumnAndRowLabels"></th>
                        <th v-for="(header, column) in sheet.headerCells">
                          <select
                            class="custom-select"
                            v-model="header.rulesForColumn.type.value"
                            :disabled="
                              etlFileModel.isFeed ||
                              sheet.submissionInProgress()
                            "
                            @change="sheet.typeChanged(header, column)"
                          >
                            <option
                              v-for="(type, index) in sheet.tableRuleModel
                                .rules"
                              :selected="
                                type.value === header.rulesForColumn.type.value
                              "
                              :value="type.value"
                              :key="'tableRule-' + index"
                            >
                              {{ type.friendly }}
                            </option>
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <th>{{ getLabelTranslation("fileETL.subType") }}</th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="showColumnAndRowLabels"></th>
                        <th
                          :key="'subType-' + column"
                          v-for="(header, column) in sheet.headerCells"
                        >
                          <select
                            class="custom-select"
                            v-if="
                              header.rulesForColumn.subType.value &&
                              header.rulesForColumn.type.rule.rules.length > 1
                            "
                            v-model="header.rulesForColumn.subType.value"
                            :disabled="
                              etlFileModel.isFeed ||
                              sheet.submissionInProgress()
                            "
                            @change="sheet.subTypeChanged(header, column)"
                          >
                            <option
                              v-for="(subType, indexSubType) in header
                                .rulesForColumn.type.rule.rules"
                              :key="'indexSubType-' + indexSubType"
                              v-if="!subType.disallowPerRow"
                              :selected="
                                subType.value ===
                                header.rulesForColumn.subType.value
                              "
                              :value="subType.value"
                            >
                              {{ subType.friendly }}
                            </option>
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <th>{{ getLabelTranslation("fileETL.attributes") }}</th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="showColumnAndRowLabels"></th>
                        <th
                          v-for="(header, column) in sheet.headerCells"
                          :key="'attributes-' + column"
                        >
                          <!-- Set Modal -->
                          <div
                            :id="header.guid"
                            class="modal fade"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="attributesLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">
                                    {{ labels.fileETL.setAttribute[locale] }}
                                  </h4>
                                </div>
                                <div class="modal-body">
                                  <table>
                                    <thead v-if="false">
                                      <tr>
                                        <th>Name</th>
                                        <th>Main Value</th>
                                        <th>Use Other</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(attribute, indexAttr) in header
                                          .rulesForColumn.attributes"
                                        :key="'rulesForColumn-' + indexAttr"
                                      >
                                        <td style="padding: 5px">
                                          {{ attribute.rule.friendly }}
                                        </td>
                                        <td>
                                          <select
                                            v-if="
                                              attribute.rule.rowAttributes
                                                .inputType === 'select'
                                            "
                                            :class="{
                                              'bg-danger':
                                                attribute.validation.error,
                                              'bg-duplicate':
                                                attribute.validation.duplicate,
                                              'bg-warning':
                                                attribute.validation.warning,
                                              'bg-info':
                                                attribute.validation.info,
                                              'bg-success':
                                                attribute.validation.success,
                                            }"
                                            :disabled="
                                              attribute.useOther ||
                                              sheet.submissionInProgress()
                                            "
                                            v-model="attribute.value"
                                            @change="
                                              sheet.attributeChanged(
                                                header,
                                                column,
                                                indexAttr
                                              )
                                            "
                                          >
                                            <option
                                              v-if="
                                                !attribute.rule.rowAttributes
                                                  .selectFirst
                                              "
                                              :value="null"
                                            ></option>
                                            <option
                                              v-if="
                                                attribute.rule.rowAttributes
                                                  .options.length
                                              "
                                              v-for="(
                                                option, indexOption
                                              ) in attribute.rule.rowAttributes
                                                .options"
                                              :key="'option-' + indexOption"
                                              :value="option.value"
                                            >
                                              {{ option.friendly }}
                                            </option>
                                            <option
                                              v-for="(
                                                option, indexOption
                                              ) in attribute.rule.rowAttributes
                                                .values"
                                              :key="'value-' + indexOption"
                                              :value="option"
                                            >
                                              {{ option }}
                                            </option>
                                          </select>
                                          <textarea
                                            v-else-if="
                                              attribute.rule.rowAttributes
                                                .inputType === 'textarea'
                                            "
                                            maxlength="255"
                                            rows="6"
                                            cols="30"
                                            :class="{
                                              disabled:
                                                sheet.submissionInProgress(),
                                              'bg-danger':
                                                attribute.validation.error,
                                              'bg-duplicate':
                                                attribute.validation.duplicate,
                                              'bg-warning':
                                                attribute.validation.warning,
                                              'bg-info':
                                                attribute.validation.info,
                                              'bg-success':
                                                attribute.validation.success,
                                            }"
                                            :disabled="
                                              sheet.submissionInProgress()
                                            "
                                            :placeholder="
                                              attribute.rule.placeholder
                                            "
                                            @change="
                                              sheet.attributeChanged(
                                                header,
                                                column,
                                                indexAttr
                                              )
                                            "
                                            v-model="attribute.value"
                                          ></textarea>
                                          <input
                                            v-else-if="
                                              attribute.rule.rowAttributes
                                                .inputType === 'text'
                                            "
                                            type="text"
                                            size="40"
                                            :class="{
                                              disabled:
                                                sheet.submissionInProgress(),
                                              'bg-danger':
                                                attribute.validation.error,
                                              'bg-duplicate':
                                                attribute.validation.duplicate,
                                              'bg-warning':
                                                attribute.validation.warning,
                                              'bg-info':
                                                attribute.validation.info,
                                              'bg-success':
                                                attribute.validation.success,
                                            }"
                                            :disabled="
                                              sheet.submissionInProgress()
                                            "
                                            :placeholder="
                                              attribute.rule.placeholder
                                            "
                                            @change="
                                              sheet.attributeChanged(
                                                header,
                                                column,
                                                indexAttr
                                              )
                                            "
                                            v-model="attribute.value"
                                          />
                                          <!--- FIXME: Support datetime type rather than text
                                                Will need CSS for it as well
                                          --->
                                          <input
                                            v-else
                                            type="text"
                                            size="40"
                                            :class="{
                                              disabled:
                                                sheet.submissionInProgress(),
                                              'bg-danger':
                                                attribute.validation.error,
                                              'bg-duplicate':
                                                attribute.validation.duplicate,
                                              'bg-warning':
                                                attribute.validation.warning,
                                              'bg-info':
                                                attribute.validation.info,
                                              'bg-success':
                                                attribute.validation.success,
                                            }"
                                            :disabled="
                                              sheet.submissionInProgress()
                                            "
                                            :placeholder="
                                              attribute.rule.placeholder
                                            "
                                            @change="
                                              sheet.attributeChanged(
                                                header,
                                                column,
                                                indexAttr
                                              )
                                            "
                                            v-model="attribute.value"
                                          />
                                        </td>
                                        <td
                                          :style="
                                            attribute.rule.rowAttributes
                                              .allowOther
                                              ? 'width: 200px'
                                              : ''
                                          "
                                        >
                                          <input
                                            v-if="
                                              attribute.rule.rowAttributes
                                                .allowOther
                                            "
                                            type="checkbox"
                                            :class="{
                                              disabled:
                                                sheet.submissionInProgress(),
                                            }"
                                            :disabled="
                                              sheet.submissionInProgress()
                                            "
                                            v-model="attribute.useOther"
                                            @change="attribute.value = null"
                                          /><span
                                            v-if="
                                              attribute.rule.rowAttributes
                                                .allowOther &&
                                              !attribute.useOther
                                            "
                                            >Use Other</span
                                          >
                                          <input
                                            v-if="attribute.useOther"
                                            :class="{
                                              disabled:
                                                sheet.submissionInProgress(),
                                            }"
                                            :disabled="
                                              sheet.submissionInProgress()
                                            "
                                            type="text"
                                            placeholder="Other"
                                            @change="
                                              sheet.attributeChanged(
                                                header,
                                                column,
                                                indexAttr
                                              )
                                            "
                                            v-model="attribute.value"
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary white--text"
                                    data-dismiss="modal"
                                  >
                                    {{ labels.closeButton[locale] }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-center">
                            <a
                              class="btn btn-default btn-attributes"
                              style="width: 3rem"
                              :class="{
                                'd-none':
                                  !header.rulesForColumn.subType.value ||
                                  header.rulesForColumn.type.rule
                                    .notSupported ||
                                  header.rulesForColumn.subType.rule
                                    .isAttribute ||
                                  etlFileModel.isFeed,
                                'white--text':
                                  header.hasMessages('error') ||
                                  header.hasMessages('warning') ||
                                  header.hasMessages('info'),
                                'bg-danger': header.hasMessages('error'),
                                'bg-warning': header.hasMessages('warning'),
                                'bg-info': header.hasMessages('info'),
                              }"
                              @click="header.showAttributesModal()"
                              >{{ getLabelTranslation("fileETL.set") }}</a
                            >
                          </div>
                          <br />&nbsp;
                        </th>
                      </tr>
                      <tr class="table-secondary">
                        <th class="centerAll metaHeader">
                          <div
                            :class="{ clickable: sheet.hasImportResults() }"
                            @click="
                              sheet.hasImportResults() && sortByMeta('status')
                            "
                          >
                            <span v-if="etlFileModel.isFeed">{{
                              getLabelTranslation("fileETL.status")
                            }}</span>
                            <span v-if="!etlFileModel.isFeed">{{
                              getLabelTranslation("fileETL.statusEdit")
                            }}</span>
                            <br />
                            <input
                              type="checkbox"
                              @input="sheet.toggleAllSelected()"
                              :class="{
                                'd-none':
                                  etlFileModel.isFeed ||
                                  sheet.submissionInProgress(),
                              }"
                              id="selectAllCheckbox"
                            />
                            <h4>
                              <span class="badge danger">{{
                                sheet.metaCountForType("status", "error", true)
                              }}</span>
                            </h4>
                            <h4>
                              <span class="badge duplicate">{{
                                sheet.metaCountForType(
                                  "status",
                                  "duplicate",
                                  true
                                )
                              }}</span>
                            </h4>
                            <h4>
                              <span class="badge warning">{{
                                sheet.metaCountForType(
                                  "status",
                                  "warning",
                                  true
                                )
                              }}</span>
                            </h4>
                            <h4>
                              <span class="badge info">{{
                                sheet.metaCountForType("status", "info", true)
                              }}</span>
                            </h4>
                            <h4>
                              <span class="badge success">{{
                                sheet.metaCountForType(
                                  "status",
                                  "success",
                                  true
                                )
                              }}</span>
                            </h4>
                          </div>
                        </th>
                        <th
                          v-if="!etlFileModel.isFeed"
                          class="centerAll metaHeader clickable"
                          @click="sortByMeta('ignore')"
                        >
                          <span>
                            {{ getLabelTranslation("fileETL.ignore") }}
                          </span>
                          <br />
                          <input
                            type="checkbox"
                            @input="sheet.toggleAllIgnore()"
                            :class="{
                              'd-none':
                                etlFileModel.isFeed ||
                                sheet.submissionInProgress(),
                            }"
                            id="ignoreAllCheckbox"
                          />
                        </th>
                        <th
                          v-if="!etlFileModel.isFeed"
                          class="centerAll metaHeader clickable"
                          @click="sortByMeta('forceNew')"
                        >
                          <span>
                            {{ getLabelTranslation("fileETL.forceNew") }}
                          </span>
                          <br />
                          <input
                            type="checkbox"
                            @input="sheet.toggleAllNew()"
                            :class="{
                              'd-none':
                                etlFileModel.isFeed ||
                                sheet.submissionInProgress(),
                            }"
                            id="allAreNewCheckbox"
                          />
                        </th>
                        <th
                          v-if="!etlFileModel.isFeed"
                          class="centerAll metaHeader clickable"
                          @click="sortByMeta('validation')"
                        >
                          {{ getLabelTranslation("fileETL.dryRun") }}<br />
                          <h4>
                            <span class="badge danger">{{
                              sheet.metaCountForType(
                                "validation",
                                "error",
                                true
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge duplicate">{{
                              sheet.metaCountForType(
                                "validation",
                                "duplicate",
                                true
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge warning">{{
                              sheet.metaCountForType(
                                "validation",
                                "warning",
                                true
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge info">{{
                              sheet.metaCountForType("validation", "info", true)
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge success">{{
                              sheet.metaCountForType(
                                "validation",
                                "success",
                                true
                              )
                            }}</span>
                          </h4>
                        </th>
                        <th
                          v-if="showColumnAndRowLabels"
                          class="centerAll metaHeader clickable"
                          style="width: 30px"
                          @click="sortByMeta('naturalRowNumber')"
                        >
                          #
                        </th>
                        <th
                          v-for="(header, column) in sheet.headerCells"
                          :key="'rowNumber-' + column"
                          class="centerAll clickable"
                          @click="sortByData(column)"
                        >
                          <span>{{ header.title }}</span>
                          <br />
                          <h4>
                            <span class="badge danger">{{
                              sheet.validationCountForType(
                                "error",
                                column,
                                false
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge duplicate">{{
                              sheet.validationCountForType(
                                "duplicate",
                                column,
                                false
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge warning">{{
                              sheet.validationCountForType(
                                "warning",
                                column,
                                false
                              )
                            }}</span>
                          </h4>
                          <h4>
                            <span class="badge info">{{
                              sheet.validationCountForType(
                                "info",
                                column,
                                false
                              )
                            }}</span>
                          </h4>
                        </th>
                      </tr>
                      <tr v-if="showColumnAndRowLabels" class="bottomBorder">
                        <th></th>
                        <th></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th v-if="!etlFileModel.isFeed"></th>
                        <th
                          :key="'column-' + column"
                          @click="sortByData(column)"
                          style="text-align: center"
                          v-for="(header, column) in sheet.headerCells"
                        >
                          {{ sheet.convertNumberToColumnName(column, false) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, indexRow) in sortedInput"
                        :key="'sortedInput-' + indexRow"
                        :class="{
                          bottomBorder: indexRow === sortedInput.length - 1,
                          'no-import': row.ignore,
                          selected: row.selected,
                        }"
                      >
                        <td
                          :class="{
                            centerAll: true,
                            'bg-danger': row.hasMessages('status', 'error'),
                            'bg-duplicate': row.hasMessages(
                              'status',
                              'duplicate'
                            ),
                            'bg-warning': row.hasMessages('status', 'warning'),
                            'bg-info': row.hasMessages('status', 'info'),
                            'bg-success': row.status,
                          }"
                          style="text-align: center; vertical-align: middle"
                        >
                          <input
                            v-if="!etlFileModel.isFeed"
                            type="checkbox"
                            :class="{ disabled: sheet.submissionInProgress() }"
                            :disabled="sheet.submissionInProgress()"
                            v-model="row.selected"
                          /><span v-if="row.submitting">&#8230;</span>
                        </td>
                        <td class="centerAll" v-if="!etlFileModel.isFeed">
                          <input
                            type="checkbox"
                            :class="{ disabled: sheet.submissionInProgress() }"
                            :disabled="sheet.submissionInProgress()"
                            @change="sheet.rowIgnoreFlipped(row)"
                            v-model="row.ignore"
                          />
                        </td>
                        <td class="centerAll" v-if="!etlFileModel.isFeed">
                          <input
                            type="checkbox"
                            :class="{ disabled: sheet.submissionInProgress() }"
                            :disabled="sheet.submissionInProgress()"
                            @change="sheet.dryRunRow(row)"
                            v-model="row.forceNew"
                          />
                        </td>
                        <td
                          class="centerAll"
                          :class="{
                            'bg-danger': row.hasMessages('validation', 'error'),
                            'bg-duplicate': row.hasMessages(
                              'validation',
                              'duplicate'
                            ),
                            'bg-warning': row.hasMessages(
                              'validation',
                              'warning'
                            ),
                            'bg-info': row.hasMessages('validation', 'info'),
                            'bg-success': !row.hasMessages('validation'),
                            clickable: row.hasMessages('validation'),
                          }"
                          v-if="!etlFileModel.isFeed"
                          @click="
                            $notify({
                              group: 'forms',
                              type: 'info',
                              title: 'Info',
                              text: dedupeMergeAndCountMessages(
                                row.getMessages('validation')
                              ),
                              duration: 12000,
                            })
                          "
                        >
                          <span v-if="row.dryRunning">&#8230;</span>
                          <img
                            v-else-if="row.hasMessages('validation')"
                            :src="
                              require('@/assets/img/messages-available.png')
                            "
                            style="width: 40px; height: 26px"
                          />
                        </td>
                        <td
                          v-if="showColumnAndRowLabels"
                          class="centerAll rowNumber"
                        >
                          {{ row.naturalRowNumber }}
                        </td>
                        <td
                          v-for="(cell, indexCell) in row.cells"
                          :key="'submission-' + indexCell"
                          class="cell"
                          :class="{
                            disabled: sheet.submissionInProgress(),
                            'bg-danger':
                              cell.validation && cell.validation.error,
                            'bg-duplicate': cell.validation && cell.duplicate,
                            'bg-warning':
                              cell.validation && cell.validation.warning,
                            'bg-info': cell.validation && cell.validation.info,
                            'bg-success':
                              cell.validation && cell.validation.success,
                          }"
                          style="padding: inherit"
                        >
                          <input
                            :class="{
                              disabled: sheet.submissionInProgress(),
                              'bg-danger':
                                cell.validation && cell.validation.error,
                              'bg-duplicate': cell.validation && cell.duplicate,
                              'bg-warning':
                                cell.validation && cell.validation.warning,
                              'bg-info':
                                cell.validation && cell.validation.info,
                              'bg-success':
                                cell.validation && cell.validation.success,
                            }"
                            :disabled="
                              etlFileModel.isFeed ||
                              sheet.submissionInProgress()
                            "
                            :id="cell.id"
                            :size="22"
                            @blur="cell.blur()"
                            @change="sheet.cellChanged(row, indexCell)"
                            @focus="cell.focus()"
                            @input="sheet.cellChanged(row, indexCell)"
                            @keyup.shift.enter.exact="
                              sheet.focusCellOfPreviousRow(indexRow, indexCell)
                            "
                            @keyup.enter.exact="
                              sheet.focusCellOfNextRow(indexRow, indexCell)
                            "
                            @keyup.escape="
                              cell.revert();
                              $event.currentTarget.blur();
                            "
                            @keyup.26="cell.revert()"
                            type="textarea"
                            v-model.trim="cell.value"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!--- This is dynamically loaded with content --->
              <div
                class="modal fade"
                id="archerModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="attributesLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">
                        {{ getLabelTranslation("fileETL.selectAnArcherFile") }}
                      </h4>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary choose"
                        data-dismiss="modal"
                      >
                        {{ labels.chooseOption[locale] }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary cancel"
                        data-dismiss="modal"
                      >
                        {{ labels.cancelButton[locale] }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";

export default {
  metaInfo: {
    title: "File Import",
  },
  mixins: [TranslationMixin],
  data: () => ({
    archerUtils: null,
    csvParseConfig: {
      skipEmptyLines: true,
      worker: false,
    },
    dbGlobals: {
      colors: null,
      commodityTypes: null,
      locations: null,
      mouthScores: null,
      origins: null,
      ranches: null,
      sexes: null,
      tags: null,
      users: null,
      vaccinations: null,
    },
    dropSheet: {
      spinner: null,
      // drop target and spinner
      /** drop target **/
      _file: null,
      _target: null,
    },
    etlFileModel: null, // currently used in various locations for .isFeed
    herdMeta: null,
    importWorkBook: null,
    loading: true,
    loadingNew: true,
    organizationId: null,
    showColumnAndRowLabels: true,
    userId: null,
  }),
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
    }),
    labels: function () {
      return this.translate;
    },
    locale: function () {
      return this.localeLang;
    },
    automated: function () {
      return this.$utils.getQueryVariable("automated") === "true";
    },
    acceptableFileTypes: function () {
      return this.$utils.getAcceptableFileTypes();
    },
    sortedInput: function () {
      const sheet = this.importWorkBook.getCurrentSheet();
      if (!sheet) return [];
      return sheet.sort();
    },
    utils: function () {
      return this.$utils;
    },
    enums: function () {
      return {
        genders: this.getEnumOptions("genders").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        breeds: this.getEnumOptions("breeds").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        birthCalvingEases: this.getEnumOptions("birthCalvingEases")
          .map((item) => {
            return {
              value: item.value.toLowerCase(),
              friendly: item.label,
            };
          })
          .reduce((reduction, calvingEase) => {
            reduction.push(calvingEase);
            // Include an option that is simply the numeric version
            reduction.push({
              ...calvingEase,
              friendly: calvingEase.friendly.match(/\d/)[0] + "",
            });
            return reduction;
          }, []),
        bullCheckTests: this.getEnumOptions("bullCheckTests").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        cyclesExtended: [
          ...this.getEnumOptions("cyclesExtensionFullText").map((item) => {
            return {
              value: item.value,
              // Convert to string for safety later
              friendly: item.label + "",
            };
          }),
          ...this.getEnumOptions("cyclesExtensionShortText").map((item) => {
            return {
              value: item.value,
              // Convert to string for safety later
              friendly: item.label + "",
            };
          }),
        ],
        fetalSexes: this.getEnumOptions("fetalSexes").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        pregCheckTests: this.getEnumOptions("pregCheckTests").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        species: this.getEnumOptions("species").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        statuses: this.getEnumOptions("statuses").map((item) => {
          return {
            value: item.value,
            friendly: item.label,
          };
        }),
        testResultsBull: this.getEnumOptions("testResultsBull").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        testResultsCow: this.getEnumOptions("testResultsCow").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
        vigor: this.getLabelTranslation("fields.vigor", "Vigor"),
        vigors: this.getEnumOptions("vigors").map((item) => {
          return {
            value: item.value.toLowerCase(),
            friendly: item.label,
          };
        }),
      };
    },
  },
  created: function () {
    if (this.automated) {
      Vue.notify({
        group: "forms",
        type: "info",
        title: "Info",
        text: this.getLabelTranslation("fileETL.urlHackNotRequired"),
      });
      this.automated = false;
    }

    this.herdMeta = this.$herdMeta;

    this.herdMeta.loaded.done(this.init);
  },
  methods: {
    init: async function () {
      this.dropSheet._target = document.getElementById("drop");
      this.dropSheet._file = document.getElementById("file");

      // FIXME: Find a way to avoid needing to do this
      this.herdMeta.setItems(this.getOrganizationItems, {
        as: "receivingRanches",
        id: "id",
      });

      const defers = [
        {
          defer: this.herdMeta.getMetaColorsAsync(true, true, true),
          after: this.map_colors,
        },
        {
          defer: this.herdMeta.getMetaCommodityTypesAsync(true, true, true),
          after: this.map_commodityTypes,
        },
        {
          defer: this.herdMeta.getMetaHerdsAsync(true, true, true),
          after: this.map_herds,
        },
        {
          defer: this.herdMeta.getMetaLocationsAsync(true, true, true),
          after: this.map_locations,
        },
        {
          defer: this.herdMeta.getMetaMouthScoresAsync(true, true, true),
          after: this.map_mouthScores,
        },
        {
          defer: this.herdMeta.getMetaOriginsAsync(true, true, true),
          after: this.map_origins,
        },
        {
          defer: this.herdMeta.getMetaAsync(
            "receivingRanches",
            true,
            true,
            true
          ),
          after: this.map_ranches,
        },
        {
          defer: this.herdMeta.getMetaSexesAsync(true, true, true),
          after: this.map_sexes,
        },
        {
          defer: this.herdMeta.getMetaTagTableAsync(true, true, true),
          after: this.map_tags,
        },
        {
          defer: this.herdMeta.getMetaUsersAsync(true, true, true),
          after: this.map_users,
        },
        {
          defer: this.herdMeta.getMetaVaccinationsAsync(true, true, true),
          after: this.map_vaccinations,
        },
      ];
      defers.forEach((deferAndAfter) => {
        deferAndAfter.defer.then(deferAndAfter.after);
      });
      $.when
        .apply(
          $,
          defers.map((deferAndAfter) => deferAndAfter.defer)
        )
        .then(() => {
          this.organizationId = this.$organizationID;
          this.userId = this.$userID;
          this.etlFileModel = new ETLfileModel(false);
          console.warn("app.organizationId", this.organizationId);

          this.setupDropSheep();
          this.archerUtils = new ArcherUtils(
            this.automated,
            this.organizationId,
            this.userId,
            this.etlFileModel
          );
          this.archerUtils.getArcherFileList();
          this.setupArcherButton();

          const test = this.$utils.getQueryVariable("test");
          if (test) this.loadDefaultContent(test);
        });
    },
    setupDropSheep: function () {
      const self = this;
      DropSheet({
        file: self.dropSheet._file,
        drop: self.dropSheet._target,
        dropsheetPath: "/templates/js/fileETL/",
        on: {
          workstart: function () {
            self.loading = self.loadingNew = true;
          },
          workend: function () {
            self.loading = false;
          },
          sheet: function (jsonXLSX, sheetNames, sheetID, selectSheetCB) {
            self.loading = true;
            if (self.loadingNew) {
              self.loadingNew = false;
              self.importWorkBook = new ImportWorkBook(
                self,
                {
                  type: "jsonXLSX",
                  jsonXLSX: jsonXLSX,
                  sheetNames: self.etlFileModel.isFeed ? ["Feed"] : sheetNames,
                  removeEmptyRowsAndColumns: true,
                },
                self.enums
              );
              self.importWorkBook.selectSheetCB = selectSheetCB;
              self.loadingNew = false;
            } else {
              self.importWorkBook.sheet(sheetID || 0, jsonXLSX, true);
            }
            self.loading = false;
          },
          revertToPrevious: function () {
            $("#file_label").html($("#file_label").data("previous"));
          },
          fileNameSelected: function (name) {
            self.etlFileModel.fileName = name;
            const ext = self.$utils.getFileExtension(name);
            self.etlFileModel.isFeed = ext == "txt";
            self.etlFileModel.archer.loaded = false;
            $("#file_label").data("previous", name);
            $("#file_label").html(self.$utils.verboseFileName(name));
          },
        },
        whitelist: function (fileName) {
          return self.$utils.isFileTypeSupported(fileName, "sheetjs");
        },
        errors: {
          badfile: function (message) {
            if (!message) {
              Vue.notify({
                group: "forms",
                type: "error",
                title: "Error",
                text: "This file does not appear to be a valid Excel file.	If we made a mistake, please send us the file so we can take a look for you.",
              });
            } else {
              Vue.notify({
                group: "forms",
                type: "error",
                title: "Error",
                text: message,
              });
            }
          },
          pending: function () {
            Vue.notify({
              group: "forms",
              type: "info",
              title: "Error",
              text: "Please wait until the current file is processed.",
            });
          },
          failed: function (e) {
            // TODO: Test whether this will work (console.log is usually not a function inside Vue)
            console.warn(e, e.stack);
            Vue.notify({
              group: "forms",
              type: "info",
              title: "Error",
              text: "We unfortunately dropped the ball here. Perhaps the file was too big to read. Please send us the file so we can take a look for you.",
            });
          },
          tooManyFiles: function (cb) {
            Vue.notify({
              group: "forms",
              type: "info",
              title: "Error",
              text: "Please drop only 1 file at a time.",
            });
            cb();
          },
          large: function (len, cb) {
            Vue.notify({
              group: "forms",
              type: "warn",
              title: "Error",
              text:
                "This file is " +
                len +
                " bytes and may take a few moments.	Your browser may lock up during this process.",
            });
            cb();
          },
          foo: "bar",
        },
      });
    },
    setupArcherButton: function () {
      const self = this;
      $("#btn-use-archer").on("click", () => {
        if (!self.etlFileModel.archer.availableFiles.length) {
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "No archer files are available.",
          });
          // In case the list is outdated, go ahead and update it for next use
          this.archerUtils.getArcherFileList();
          return;
        }

        let count = 0;
        let select = $("<select><option></option></select>").attr(
          "id",
          "archerFileOptions"
        );

        self.etlFileModel.archer.availableFiles.forEach((archerFile) => {
          let $option = $("<option></option>")
            .html(self.$utils.verboseFileName(archerFile.fileName))
            .data("fileUploadsId", archerFile.fileUploadsId)
            .data("fileName", archerFile.fileName)
            .data("status", archerFile.status)
            .toggleClass("error", archerFile.status == "error");
          // .toggleClass('warning', archerFile.status == 'warning')// leaving for parity (having it is excessive coloration for app.dbGlobals.users)
          select.append($option);
          count++;
        });

        select
          .on("change", function () {
            const $option = $(this).find(":selected");
            self.etlFileModel.archer.fileUploadsId =
              $option.data("fileUploadsId");
            self.etlFileModel.archer.fileName = $option.data("fileName");
            self.etlFileModel.archer.status = $option.data("status");
            self.etlFileModel.archer.newStatus = null;

            // For exposing to automation scripts
            $("body").prop(
              "fileUploadsId",
              self.etlFileModel.archer.fileUploadsId
            );
          })
          .trigger("change");
        if (count) {
          $("#archerModal").find(".modal-body").html(select);
          $("#archerModal").modal();
        } else {
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "No archer files are available.",
          });
        }

        // In case the list is outdated, go ahead and update it for next use
        this.archerUtils.getArcherFileList();
      });

      $("#archerModal")
        .find(".choose")
        .on("click", () => {
          this.etlFileModel.fileName = this.etlFileModel.archer.fileName;
          this.etlFileModel.archer.loaded = true;
          $("#file_label").html(
            self.$utils.verboseFileName(self.etlFileModel.fileName)
          );
          this.loading = true;

          this.archerUtils.getArcherFile(self.loadTableFromCSV, () => {
            this.loading = false;
            Vue.notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: "Error encountered while attempting to load Archer file. Please try again.",
            });
          });
        });
    },
    reDataTable: function () {
      $("#filesFromArcher").dataTable({
        destroy: true,
        searching: false,
        ordering: false,
      });
    },
    openHistoricalArcherFile: function (archerRecord) {
      this.etlFileModel.archer.fileUploadsId = archerRecord.id;
      this.etlFileModel.fileName = archerRecord.fileName;
      this.etlFileModel.archer.loaded = true;
      $("#file_label").html(this.$utils.verboseFileName(archerRecord.fileName));
      this.loading = true;

      this.archerUtils.getArcherFile(this.loadTableFromCSV, () => {
        this.loading = false;
        Vue.notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "Error encountered while attempting to load Archer file. Please try again.",
        });
      });
    },
    downloadHistoricalArcherFile: function (archerRecord) {
      // We have to do this because ArcherUtils.getArcherFile() depends on external variables instead of just accepting them as input
      const fileUploadsId = this.etlFileModel.archer.fileUploadsId;
      this.etlFileModel.archer.fileUploadsId = archerRecord.id;

      this.loading = true;
      this.archerUtils.getArcherFile(
        (file) => {
          // https://stackoverflow.com/a/23922475
          let pom = document.createElement("a");
          const blob = new Blob([file], { type: "text/csv;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute("download", archerRecord.fileName);
          pom.click();

          this.loading = false;
        },
        () => {
          this.etlFileModel.archer.fileUploadsId = fileUploadsId;
          this.loading = false;
          Vue.notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: "Error encountered while attempting to download Archer file. Please try again.",
          });
        }
      );
    },
    dedupeMergeAndCountMessages: function (messages) {
      let finalMessage = "";
      if (messages.length > 0) {
        let dedupe = {};
        messages.forEach((message) => {
          dedupe[message] = dedupe[message] ? dedupe[message] + 1 : 1;
        });
        for (let message in dedupe) {
          finalMessage += `<br /> ${message}`;
          if (dedupe[message] > 1) {
            finalMessage += ` <strong>(${
              dedupe[message]
            } ${this.getLabelTranslation("fileETL.occurrences")})</strong>`;
          } else {
            finalMessage += ` <strong>(${
              dedupe[message]
            } ${this.getLabelTranslation("fileETL.occurrence")})</strong>`;
          }
        }
      }
      return finalMessage ? finalMessage : null;
    },
    loadDefaultContent: function (test) {
      // Trigger loading of dummy data by putting #dummy in the URL
      const defaultContent = new DefaultContent(this.utils).options;
      let jsonXLSX = {};
      let sheetNames = [];
      this.etlFileModel.isFeed = false;
      if (defaultContent[test]) {
        jsonXLSX = defaultContent[test];
        sheetNames = [test];
      } else {
        jsonXLSX = defaultContent["messy"];
        sheetNames = ["messy"];
      }
      this.importWorkBook = new ImportWorkBook(
        this,
        {
          type: "jsonXLSX",
          jsonXLSX: jsonXLSX,
          sheetNames: this.etlFileModel.isFeed ? ["Feed"] : sheetNames,
          removeEmptyRowsAndColumns: false,
        },
        this.enums
      );
      this.loading = false;
      this.loadingNew = false;
    },
    map_colors: function (rows) {
      this.dbGlobals.colors = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.color,
          friendlyName: item.color,
        };
      });
    },
    map_commodityTypes: function (rows) {
      this.dbGlobals.commodityTypes = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.name,
          friendlyName: item.friendlyName,
        };
      });
    },
    map_herds: function (rows) {
      this.dbGlobals.herds = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.name,
          friendlyName: item.friendlyName || item.name,
        };
      });
    },
    map_locations: function (rows) {
      this.dbGlobals.locations = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.name,
          friendlyName: item.friendlyName,
          feedAlias: item.feedAlias,
        };
      });
    },
    map_mouthScores: function (rows) {
      this.dbGlobals.mouthScores = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.mouthScore,
        };
      });
    },
    map_origins: function (rows) {
      this.dbGlobals.origins = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.origin,
          friendlyName: item.friendlyName || item.origin,
        };
      });
    },
    map_ranches: function (rows) {
      this.dbGlobals.ranches = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.name,
          friendlyName: item.friendlyName || item.name,
        };
      });
    },
    map_sexes: function (rows) {
      this.dbGlobals.sexes = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.sex,
          friendlyName: item.friendlyName || item.sex,
        };
      });
    },
    map_users: function (rows) {
      this.dbGlobals.users = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.username,
        };
      });
    },
    map_vaccinations: function (rows) {
      this.dbGlobals.vaccinations = rows.map((item) => {
        return {
          value: item.id,
          friendly: item.name,
        };
      });
    },
    map_tags: function (rows) {
      this.dbGlobals.tags = rows.map((item) => {
        const type = (item.type || "").trim().toLowerCase().trim();
        const name = (item.name || "").trim().toLowerCase().trim();
        return {
          value: item.id,
          friendly: `${
            name || this.getLabelTranslation("Unknown")
          } (${this.getLabelTranslation(
            `enums.tagTypes.${type}`,
            this.getLabelTranslation("Unknown")
          )})`,
          name: name,
          type: type,
        };
      });
    },
    loadTableFromCSV: function (csv) {
      this.loading = false;
      if (csv == null) {
        Vue.notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "File appears to be empty. Either it really is empty or something else went wrong.",
        });
      } else {
        this.etlFileModel.isFeed = false;
        this.importWorkBook = new ImportWorkBook(
          this,
          {
            type: "csv",
            csv: csv,
            sheetNames: ["Archer"],
            removeEmptyRowsAndColumns: true,
          },
          this.enums
        );
        Vue.notify({
          group: "forms",
          type: "success",
          title: "Success",
          text: this.getLabelTranslation("fileETL.archerFileIsLoaded"),
        });
      }
    },
    sortByData: function (column) {
      let sheet = this.importWorkBook.getCurrentSheet();
      sheet.sortSettings.enable = true;

      if (
        sheet.sortSettings.columnType === "data" &&
        sheet.sortSettings.currentSort === column &&
        !sheet.sortSettings.byCellStatus
      ) {
        sheet.sortSettings.currentSortDir =
          sheet.sortSettings.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        sheet.sortSettings.currentSortDir = "asc";
      }
      sheet.sortSettings.columnType = "data";
      sheet.sortSettings.currentSort = column;
      sheet.sortSettings.byCellStatus = false;
    },
    sortByMeta: function (columnType) {
      let sheet = this.importWorkBook.getCurrentSheet();
      sheet.sortSettings.enable = true;

      if (sheet.sortSettings.columnType === columnType) {
        sheet.sortSettings.currentSortDir =
          sheet.sortSettings.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        sheet.sortSettings.currentSortDir = "asc";
      }
      sheet.sortSettings.columnType = columnType;
      sheet.sortSettings.currentSort = null;
    },
    sortByCellStatus: function (column) {
      let sheet = this.importWorkBook.getCurrentSheet();
      sheet.sortSettings.enable = true;

      sheet.sortSettings.currentSortDir = "asc";

      sheet.sortSettings.columnType = "byCellStatus";
      sheet.sortSettings.currentSort = column;
    },
  },
};
</script>
<style scoped>
input {
  border: 1px solid #ccc;
}
.buttons-collection {
  border: thin solid gray;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.dynamic-buttons:hover {
  background-color: #dddddd;
}
.dynamic-buttons {
  background-color: #eeeeee;
  border: thin solid gray;
  color: black;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}
.bg-orange {
  background-color: orange;
}

ul {
  font-size: inherit;
}
th.metaHeader {
  padding-left: 10px;
  padding-right: 10px;
}
th a.btn {
  line-height: 1 !important;
}
td label {
  margin-right: 10px;
  line-height: 2;
}
th,
td {
  padding: 0px;
}
.bg-success,
.success {
  background-color: green !important;
}
.bg-info,
.info {
  background-color: #80e284 !important;
}
strong.bg-info,
.info {
  background-color: green !important;
  color: black;
}
.bg-warning,
.warning {
  background-color: yellow !important;
}
strong.bg-warning,
strong.warning {
  background-color: yellow !important;
  color: black;
}
.bg-danger,
.danger {
  background-color: red !important;
}
strong.bg-danger {
  background-color: red !important;
}
.bg-duplicate,
.duplicate {
  background-color: orange !important;
}
.bg-duplicate strong.duplicate {
  background-color: orange !important;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9 !important;
}
tbody tr:hover {
  background-color: #f6f6f6 !important;
}

th.centerAll,
td.centerAll {
  text-align: center;
  vertical-align: middle;
}
th.centerAll {
  vertical-align: top;
}

tr.no-import {
  color: gray;
  opacity: 0.5;
}

tr.bottomBorder {
  border-bottom: thin solid black;
}

td.rowNumber {
  border-bottom: thin dotted gray;
  border-right: thin solid black;
  font-size: large;
  font-weight: bold;
  min-width: 20px;
}
td.cell {
  border-bottom: thin dotted gray;
  border-right: thin dotted gray;
}
tr td:last-child {
  border-right: thin solid black;
}
tr:last-child td {
  border-right: inherit;
}
.clickable {
  cursor: pointer;
}
span.badge.warning {
  color: black;
}
span.badge.bg-duplicate,
span.badge.duplicate {
  background-color: orange;
  color: black;
}

#legend-helptip {
  margin-top: 10px;
}
#legend table {
  font-weight: 800;
  color: black;
  font-size: medium;
}
#legend table tr td {
  padding: 5px;
}

th.noBottomBorder {
  border-bottom: none !important;
}
#sheetsList div {
  margin-bottom: 8px;
  max-width: 1500px;
  clear: both;
}
.sheetButtons {
  clear: both;
  margin-bottom: 10px;
}

#left {
  width: 188px;
  position: absolute;
  left: 0;
  /* Changes to default here */
  margin-left: 10px;
}
#right {
  position: absolute;
  left: 200px;
}
#logo {
  padding: 25px;
  display: none;
}

#drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  width: 152px;
  font: 16pt bold, "Vollkorn";
  /* Changes to default here */
  background-color: lightgray;
  color: black !important;
  padding: 10px;
  margin-left: 18px;
}

/* Inspired by https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: gray;
  display: inline-block;
  text-transform: uppercase;
  font-size: x-small;
  padding: 5px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}
.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
</style>
