<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Transfers</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Transfers_${animal.guid}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      class="elevation-1"
      hide-default-footer
    >
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "Transfers",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      this.getHistory();
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  methods: {
    getHistory: async function () {
      this.table.loading = true;
      try {
        const result = await this.pouches.organization.get(this.animalID);
        if (!result) return;
        this.table.data = (result.transfers || []).map((item) => ({
          ...item,
          // Using a flat structure makes the CSV export less confusing
          from: item.from.name,
          to: item.to.name,
          userName: item.from.id == this.$organizationID ? item.userName : "?",
        }));
      } catch (error) {
        throw error;
      } finally {
        this.table.loading = false;
      }
    },
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("Transfer Out Time"),
          value: "timeRecorded",
        },
        {
          text: this.getTranslation("Data Transfer Time"),
          value: "createdOn",
        },
        { text: this.getTranslation("From"), value: "from" },
        { text: this.getTranslation("To"), value: "to" },
        { text: this.getTranslation("By"), value: "userName" },
      ];
    },
  },
};
</script>