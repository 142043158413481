<template>
  <div>
    <v-app-bar :color="$vuetify.theme.dark ? '#454D55' : '#3465a1'" dark>
      <v-app-bar-title>
        <v-img
          :src="require('@/assets/logos/logoIcon.png')"
          contain
          max-height="50"
          max-width="50"
        >
        </v-img>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        :color="$vuetify.theme.dark ? '#454D55' : '#3465a1'"
        :to="route.path"
        class="mx-1"
        depressed
      >
        {{ route.name }}
      </v-btn>
      <v-icon class="mx-2" :color="colorLed">
        {{ statusConnection }}
      </v-icon>
      <v-btn @click="toggleDarkMode" color="white" icon small text>
        <v-icon v-if="$vuetify.theme.dark"> mdi-moon-waning-crescent </v-icon>
        <v-icon v-else> mdi-white-balance-sunny </v-icon>
      </v-btn>
    </v-app-bar>
    <div
      :class="{
        'grey darken-4': $vuetify.theme.dark,
        'grey lighten-3': !$vuetify.theme.dark,
      }"
      :style="`min-height: calc(100vh - ${headerOffset}px)`"
      class="d-flex flex-column justify-center align-center"
    >
      <v-img
        :src="require('@/assets/logos/logo.png')"
        class="mb-10"
        contain
        max-height="260"
        max-width="274"
      >
      </v-img>
      <v-form @submit.prevent="signIn" v-model="validForm" ref="form">
        <v-text-field
          :background-color="!$vuetify.theme.dark ? 'white' : ''"
          :label="labels.username"
          :rules="[usernameValidation]"
          autocomplete="email username"
          id="username"
          outlined
          prepend-inner-icon="mdi-email"
          required
          style="max-width: 281px"
          v-model="username"
        ></v-text-field>
        <v-text-field
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :background-color="!$vuetify.theme.dark ? 'white' : ''"
          :color="$vuetify.theme.dark ? 'white' : ''"
          :label="labels.password"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          autocomplete="current-password"
          id="current_password"
          outlined
          prepend-inner-icon="mdi-lock"
          required
          style="max-width: 281px"
          v-model="password"
        ></v-text-field>
        <v-btn
          :disabled="!validForm"
          class="w-100 mt-5 light-blue darken-4 white--text"
          large
          style="max-width: 281px"
          type="submit"
        >
          {{ labels.button }}
        </v-btn>
      </v-form>
      <v-snackbar v-model="showToast" color="red white--text">
        {{ unauthorizedMessage }}
        <v-btn text color="white" @click.native="showToast = false"
          >Close</v-btn
        >
      </v-snackbar>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapActions } from "vuex";
import { VueOfflineMixin } from "vue-offline";
import AuthenticationExtension from "@/mixins/Authentication";
import TranslationMixin from "../mixins/Translations";

export default {
  extends: AuthenticationExtension,
  name: "SignIn",
  metaInfo: {
    title: "Sign In",
  },
  mixins: [TranslationMixin, VueOfflineMixin],
  data: () => ({
    colorLed: "",
    headerOffset: null,
    labels: {
      // deepcode ignore NoHardcodedCredentials: <Not actually hard-coded. These are labels only.>
      username: "Username",
      // deepcode ignore NoHardcodedCredentials: <Not actually hard-coded. These are labels only.>
      password: "Password",
      button: "Sign In",
    },
    password: "",
    passwordValid: false,
    route: { name: "Sign Up", path: "/sign-up" },
    showPassword: false,
    showToast: false,
    unauthorizedMessage: "Your credentials are wrong or you are offline",
    username: "",
    usernameValid: false,
    validForm: false,
  }),
  beforeDestroy: function () {
    $("#username").off();
  },
  mounted: function () {
    $("#username").focus();
    this.headerOffset = $(".v-toolbar__content").outerHeight();
  },
  computed: {
    statusConnection: function () {
      if (this.isOnline) {
        this.colorLed = "success";
        return "mdi-wifi";
      } else {
        this.colorLed = "red";
        return "mdi-wifi-off";
      }
    },
    validUsernamePassword: function () {
      if (this.usernameValid && this.passwordValid) return true;
      else false;
    },
  },
  methods: {
    ...mapActions({
      setDarkMode: "User/setDarkMode",
    }),
    checkTypeUser: function () {
      for (const user of usersDB) {
        if (
          this.username === user.username &&
          this.password === user.password
        ) {
          return user.type;
        }
      }
    },
    passwordValidation: function (password) {
      if (password.length >= 6) {
        this.passwordValid = true;
        return true;
      } else {
        this.passwordValid = false;
        if (password.length === 0) return "Password is required";
        else if (password.length <= 6) {
          return "Password must be 6 characters at least";
        }
      }
    },
    toggleDarkMode: function () {
      this.setDarkMode({ darkMode: !this.$vuetify.theme.dark });
    },
    usernameValidation: function (username) {
      if (username.length === 0) return "Username is required";
      else {
        this.usernameValid = true;
        return true;
      }
    },
  },
  watch: {
    validUsernamePassword: function (value) {
      if (value) this.validForm = true;
      else this.validForm = false;
    },
  },
};
</script>
<style>
.theme--dark.v-input input:-webkit-autofill,
.theme--dark.v-input input:-webkit-autofill:active,
.theme--dark.v-input input:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px #212121 inset !important;
  -webkit-box-shadow: 0 0 0 30px #212121 inset;
  -webkit-text-fill-color: white;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
</style>