<template>
  <div class="my-2">
    <div class="g-row align-center mb-2">
      <export-button
        :disabled="tableData.length == 0"
        :filename="`Birth_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :items="tableData"
        buttonFormat="icon"
      />
    </div>
    <v-progress-circular indeterminate size="50" v-if="loading" />
    <div class="g-row" v-else>
      <div
        :class="[
          ['xs'].includes($vuetify.breakpoint.name) ? 'force-w-100' : '',
          'g-col',
        ]"
      >
        <!-- Birth Date -->

        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("birthDate") }}</p>
          <v-text-field
            :disabled="disabled"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @click:append-outer="openDatetimeDialog('birthDate')"
            append-outer-icon="mdi-pencil"
            dense
            hide-details
            readonly
            v-model="details.birthDate"
          >
          </v-text-field>
        </div>

        <!-- Death Date -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("deathDate") }}</p>
          <v-text-field
            :disabled="disabled"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @click:append-outer="openDatetimeDialog('deathDate')"
            append-outer-icon="mdi-pencil"
            dense
            hide-details
            readonly
            v-model="details.deathDate"
          >
          </v-text-field>
        </div>

        <!-- Birth Weight -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("birthWeight") }}</p>
          <v-text-field
            :disabled="disabled"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'birthWeight',
                details.birthWeight.id,
                'birthWeight',
                details.birthWeight.birthWeight,
                false,
                false
              )
            "
            dense
            hide-details
            v-model="details.birthWeight.birthWeight"
          >
          </v-text-field>
        </div>

        <!-- Breed -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("breed") }}</p>
          <v-select
            :disabled="disabled"
            :items="options.breeds"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'breeds',
                null,
                'breedId',
                details.breed.breed.value,
                false,
                false,
                {
                  breed: details.breed.breed.label,
                  userId: $userID,
                }
              )
            "
            dense
            hide-details
            item-text="label"
            return-object
            v-model="details.breed.breed"
          >
          </v-select>
        </div>

        <!-- Gender -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("gender") }}</p>
          <v-select
            :disabled="disabled"
            :items="options.genders"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'genders',
                null,
                'gender',
                details.gender.gender,
                false,
                false
              )
            "
            dense
            hide-details
            item-text="label"
            v-model="details.gender.gender"
          >
          </v-select>
        </div>

        <!-- Origin -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("origin") }}</p>
          <v-text-field
            :disabled="disabled"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @input="
              animal.modify(
                'origin',
                null,
                'origin',
                animal.derived.birth.origin.origin,
                false,
                false
              )
            "
            dense
            hide-details
            v-if="!options.origins.length"
            v-model="animal.derived.birth.origin.origin"
          >
          </v-text-field>
          <v-select
            :disabled="disabled"
            :items="options.origins"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @input="
              animal.modify(
                'origin',
                null,
                'originId',
                details.origin.originId || details.origin.id,
                false,
                false,
                {
                  origin:
                    details.origin.originId || details.origin.id
                      ? animal.herdMeta.originsById[
                          details.origin.originId || details.origin.id
                        ].origin || details.origin.origin
                      : null,
                }
              )
            "
            dense
            hide-details
            item-text="origin"
            item-value="originId"
            return-object
            v-else
            v-model="details.origin"
          >
          </v-select>
        </div>
      </div>
      <div class="g-col">
        <!-- Specie -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("species") }}</p>
          <v-select
            :disabled="disabled"
            :items="options.species"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'species',
                null,
                'species',
                details.species.species,
                false,
                false
              )
            "
            dense
            hide-details
            item-text="label"
            v-model="details.species.species"
          >
          </v-select>
        </div>

        <!-- Birth Visual -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("birthVisual") }}</p>
          <v-text-field
            :value="details.birthVisual"
            dense
            disabled
            hide-details
            prepend-inner-icon="mdi-lock"
            solo
          >
          </v-text-field>
        </div>

        <!-- Percent Cross -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("percentCross") }}</p>
          <v-text-field
            :disabled="disabled"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'percentCross',
                null,
                'percentCross',
                details.percentCross.percentCross,
                false,
                false
              )
            "
            dense
            hide-details
            v-model="details.percentCross.percentCross"
          >
          </v-text-field>
        </div>

        <!-- Calving Score -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("birthCalvingEase") }}</p>
          <v-select
            :disabled="disabled"
            :items="options.calvingScores"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'birthCalvingEases',
                null,
                'birthCalvingEase',
                details.calvingScore.birthCalvingEase,
                false,
                false
              )
            "
            dense
            hide-details
            item-text="label"
            v-model="details.calvingScore.birthCalvingEase"
          >
          </v-select>
        </div>

        <!-- Calf Vigor -->
        <div class="my-2">
          <p class="subtitle-style">{{ getTranslation("Vigor") }}</p>
          <v-select
            :disabled="disabled"
            :items="options.calfVigors"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            :outlined="!disabled"
            :prepend-inner-icon="disabled ? 'mdi-lock' : ''"
            :solo="disabled"
            @change="
              animal.modify(
                'vigors',
                null,
                'vigor',
                details.calfVigor.vigor,
                false,
                false
              )
            "
            dense
            hide-details
            item-text="label"
            v-model="details.calfVigor.vigor"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div v-if="showExtraInformation">
      <v-spacer style="height: 40px"></v-spacer>
      <p v-if="details && details.lastUpdated" style="color: #989089">
        Last Updated:
        {{ $utils.renderValueAs(details.lastUpdated, "datetime", true) }}
      </p>
    </div>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="datetimeDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>
            {{
              datetimeDialog.type === "birthDate"
                ? getTranslation("birthDate")
                : getTranslation("deathDate")
            }}
          </h4>
          <v-icon class="buttons" @click="datetimeDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0" />
        <v-card-text class="py-2">
          <v-stepper alt-labels non-linear v-model="datetimeDialog.step">
            <v-stepper-header>
              <v-stepper-step editable step="1"> Date </v-stepper-step>
              <v-divider />
              <v-stepper-step editable step="2"> Time </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-date-picker v-model="datetimeDialog.date" />
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-time-picker scrollable v-model="datetimeDialog.time" />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pb-4 pr-6 d-flex justify-end">
          <v-btn
            @click="datetimeDialog.show = false"
            class="mr-2 rounded-lg normal-case body-1"
            text
          >
            {{ getTranslation("animalsPages.cancel") }}
          </v-btn>
          <v-btn
            :disabled="
              !datetimeValue ||
              $moment(datetimeValue).valueOf() ===
                $moment(details[datetimeDialog.type]).valueOf()
            "
            @click="saveDatetime"
            class="rounded-lg primary-button normal-case body-1"
          >
            <span class="px-6">
              {{ getTranslation("animalsPages.save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "BirthDetails",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showExtraInformation: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    animal: null,
    broadcast: null,
    datetimeDialog: {
      date: null,
      show: false,
      step: 1,
      time: null,
      type: null,
    },
    details: {
      birthWeight: {},
      breed: {},
      calfVigor: {},
      calvingScore: {},
      gender: {},
      percentCross: {},
      species: {},
    },
    herdMeta: null,
    loading: false,
    options: {},
    pouches: null,
    tableData: [],
  }),
  computed: {
    datetimeValue: function () {
      const dt = this.datetimeDialog;
      return !!dt.date && !!dt.time && `${dt.date} ${dt.time}`;
    },
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
      this.getSelectOptions();
      this.getBirthDetails();
    };
    try {
      this.loading = true;
      await this.init();
      this.getSelectOptions();
      this.getBirthDetails();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
    },
    getBirthDetails: function () {
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.tableData = summaries.birth || [];
          this.details = this.tableData[0];
          const origin = this.details.origin;
          if (
            origin.origin &&
            !this.options.origins.some((o) => o.origin === origin.origin)
          )
            this.options.origins.splice(1, 0, origin);
          this.details.birthDate = this.details.birthDate
            ? this.$moment(this.details.birthDate).format("YYYY-MM-DD HH:mm A")
            : null;
          this.details.deathDate = this.details.deathDate
            ? this.$moment(this.details.deathDate).format("YYYY-MM-DD HH:mm A")
            : null;
        })
        .catch((e) => {
          throw e;
        });
    },
    getSelectOptions: async function () {
      this.options.breeds = [
        { label: "", value: "" },
        ...this.getEnumOptions("breeds"),
      ];
      this.options.genders = [
        { label: "", value: "" },
        ...this.getEnumOptions("genders"),
      ];
      this.options.species = [
        { label: "", value: "" },
        ...this.getEnumOptions("species"),
      ];
      this.options.calfVigors = [
        { label: "", value: "" },
        ...this.getEnumOptions("vigors"),
      ];
      this.options.calvingScores = [
        { label: "", value: "" },
        ...this.getEnumOptions("birthCalvingEases"),
      ];
      this.options.origins = [
        { id: "", origin: "" },
        ...(this.animal.herdMeta.origins || []).map((origin) => {
          if (!origin.originId) origin.originId = origin.id;
          return origin;
        }),
      ];
    },
    openDatetimeDialog: function (type) {
      const datetime = Object.assign({}, this.$options.data().datetimeDialog);
      const value = this.details[type];
      datetime.type = type;
      if (!!value && value.length) {
        const currentValue = this.$moment(value, "YYYY-MM-DD HH:mm A");
        datetime.date = currentValue.format("YYYY-MM-DD");
        datetime.time = currentValue.format("HH:mm");
      } else {
        datetime.date = this.$moment().format("YYYY-MM-DD");
        datetime.time = this.$moment().format("HH:mm");
      }
      Object.assign(this.datetimeDialog, datetime);
      this.datetimeDialog.show = true;
    },
    saveDatetime: async function () {
      const datetime = this.$moment(
        this.$moment(this.datetimeValue).toISOString()
      ).format("YYYY-MM-DDTHH:mm");
      const type = this.datetimeDialog.type;
      try {
        await this.animal.modify(
          `${type}s`,
          null,
          type,
          datetime,
          false,
          false
        );
        this.getBirthDetails();
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      } finally {
        this.datetimeDialog.show = false;
      }
    },
  },
};
</script>
<style scoped>
>>> .v-input {
  max-width: 280px;
}

>>> .v-stepper__wrapper {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.v-data-table__wrapper > table {
  tbody > tr > td {
    min-width: 120px;
    max-width: 170px;
  }
}
.subtitle-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px !important;
}
</style>
