<template>
  <div>
    <v-app-bar :color="$vuetify.theme.dark ? '#454D55' : '#3465a1'" dark>
      <v-app-bar-title>
        <v-img
          :src="require('@/assets/logos/logoIcon.png')"
          contain
          max-height="50"
          max-width="50"
        >
        </v-img>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        :color="$vuetify.theme.dark ? '#454D55' : '#3465a1'"
        :to="route.path"
        class="mx-1"
        depressed
      >
        {{ route.name }}
      </v-btn>
      <v-icon class="mx-2" :color="colorLed">
        {{ statusConnection }}
      </v-icon>
      <v-btn @click="toggleDarkMode" color="white" icon small text>
        <v-icon v-if="$vuetify.theme.dark"> mdi-moon-waning-crescent </v-icon>
        <v-icon v-else> mdi-white-balance-sunny </v-icon>
      </v-btn>
    </v-app-bar>
    <v-row
      :class="{
        'grey darken-4': $vuetify.theme.dark,
        'grey lighten-3': !$vuetify.theme.dark,
      }"
      :style="`min-height: calc(100vh - ${headerOffset}px`"
      class="d-flex flex-column justify-center align-center grey lighten-3 ma-0"
    >
      <v-col
        class="d-flex flex-column justify-center align-center"
        cols="12"
        lg="3"
      >
        <v-row class="ma-0 d-flex justify-center align-center">
          <v-img
            :src="require('@/assets/logos/logo.png')"
            class="mb-10"
            contain
            max-height="260"
            max-width="274"
          >
          </v-img>
        </v-row>
        <v-row class="d-flex justify-center ma-0">
          <v-col class="pa-2">
            We are glad that you are interested in Fast Herd!
            <br />
            <br />
            Give us a call to sign up!
            <br />
            <br />
            <a href="tel:8015441509">801-544-1509</a>
            <br />
            <a href="mailto:info@fort-supply.com">info@fort-supply.com</a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VueOfflineMixin } from "vue-offline";
import AuthenticationExtension from "@/mixins/Authentication";
import TranslationMixin from "../mixins/Translations";

export default {
  extends: AuthenticationExtension,
  name: "SignUpInstructions",
  metaInfo: {
    title: "Sign Up",
  },
  mixins: [TranslationMixin, VueOfflineMixin],
  data: () => ({
    colorLed: "",
    route: { name: "Sign In", path: "/sign-in" },
    headerOffset: null,
  }),
  mounted: function () {
    this.headerOffset = $(".v-toolbar__content").outerHeight();
  },
  computed: {
    statusConnection: function () {
      if (this.isOnline) {
        this.colorLed = "success";
        return "mdi-wifi";
      } else {
        this.colorLed = "red";
        return "mdi-wifi-off";
      }
    },
  },
  methods: {
    toggleDarkMode: function () {
      this.setDarkMode({ darkMode: !this.$vuetify.theme.dark });
    },
  },
};
</script>

<style scoped>
.theme--dark.v-input input:-webkit-autofill,
.theme--dark.v-input input:-webkit-autofill:active,
.theme--dark.v-input input:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px #212121 inset !important;
  -webkit-box-shadow: 0 0 0 30px #212121 inset;
  -webkit-text-fill-color: white;
}
.FullHeight {
  height: 100vh;
}
</style>