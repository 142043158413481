<template>
  <v-dialog max-width="500" scrollable v-model="showDialog">
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ title }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="medication-form"
            ref="medicationForm"
            v-model="formValid"
          >
            <v-text-field
              :label="getLabelTranslation('name')"
              :rules="[required]"
              autocomplete="off"
              autofocus
              required
              v-model.trim="medication.alias"
            />
            <v-text-field
              :label="getLabelTranslation('dosesInBottle')"
              autocomplete="off"
              v-model.trim="medication.ccsPerBottle"
            />
            <v-text-field
              :label="getLabelTranslation('pricePerBottle')"
              autocomplete="off"
              v-model.trim="medication.bottlePrice"
            />
            <v-text-field
              :label="getLabelTranslation('withdrawlTime')"
              autocomplete="off"
              type="number"
              v-model.trim="medication.withdrawlTime"
            />
            <v-text-field
              :label="getLabelTranslation('withdrawlTimeUnits')"
              autocomplete="off"
              v-model.trim="medication.withdrawlTimeUnits"
            />
            <v-text-field
              :label="getLabelTranslation('manufacturer')"
              autocomplete="off"
              v-model.trim="medication.manufacturer"
            />
            <v-text-field
              :label="getLabelTranslation('dose')"
              autocomplete="off"
              v-model.trim="medication.dose"
            />
            <v-text-field
              :label="getLabelTranslation('doseUnits')"
              autocomplete="off"
              v-model.trim="medication.doseUnits"
            />
            <v-text-field
              :label="getLabelTranslation('administrationMethod')"
              autocomplete="off"
              v-model.trim="medication.administrationMethod"
            />
            <v-text-field
              :label="getLabelTranslation('expirationDate')"
              autocomplete="off"
              type="date"
              v-model.trim="medication.expirationDate"
            />
            <v-text-field
              :label="getLabelTranslation('purchaseDate')"
              autocomplete="off"
              type="date"
              v-model.trim="medication.purchaseDate"
            />
            <v-text-field
              :label="getLabelTranslation('vaccType')"
              autocomplete="off"
              v-model.trim="medication.vaccType"
            />
            <v-text-field
              :label="getLabelTranslation('lotNumber')"
              autocomplete="off"
              label="Lot Number"
              v-model.trim="medication.lotNumber"
            />
            <v-text-field
              :label="getLabelTranslation('batchNumber')"
              autocomplete="off"
              v-model.trim="medication.batchNumber"
            />
            <v-text-field
              :label="getLabelTranslation('serialNumber')"
              autocomplete="off"
              v-model.trim="medication.serialNumber"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || submitting"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="medication-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "MedicationDialog",
  props: {
    herdMeta: {
      required: true,
      type: Object,
    },
    onCreate: {
      required: true,
      type: Function,
    },
  },
  mixins: [TranslationMixin],
  data() {
    return {
      formValid: false,
      medication: {},
      showDialog: false,
      submitting: false,
    };
  },
  computed: {
    title() {
      return this.medication.id ? "Update medication" : "Create medication";
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.medicationForm.resetValidation();
      }
    },
  },
  methods: {
    open(medicationToEdit = {}) {
      Object.assign(this.medication, medicationToEdit);
      this.medication.expirationDate =
        this.medication.expirationDate || new Date().toISOString();
      this.medication.purchaseDate =
        this.medication.purchaseDate || new Date().toISOString();

      if (this.medication.expirationDate) {
        this.medication.expirationDate = this.medication.expirationDate.slice(
          0,
          10
        );
      }
      if (this.medication.purchaseDate) {
        this.medication.purchaseDate = this.medication.purchaseDate.slice(
          0,
          10
        );
      }
      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    getData() {
      let expirationDate = "";
      let purchaseDate = "";
      if (this.medication.expirationDate) {
        expirationDate = new Date(this.medication.expirationDate).toISOString();
      }
      if (this.medication.expirationDate) {
        purchaseDate = new Date(this.medication.purchaseDate).toISOString();
      }
      const data = {
        administrationMethod: this.medication.administrationMethod || "",
        batchNumber: this.medication.batchNumber || "",
        bottlePrice: this.medication.bottlePrice || "",
        ccsPerBottle: this.medication.ccsPerBottle || "",
        createdOn: new Date().toISOString(),
        dose: this.medication.dose || "",
        doseUnits: this.medication.doseUnits || "",
        expirationDate,
        id: this.medication.id,
        lotNumber: this.medication.lotNumber || "",
        manufacturer: this.medication.manufacturer || "",
        name: this.medication.alias,
        purchaseDate,
        serialNumber: this.medication.serialNumber || "",
        vaccType: this.medication.vaccType || "",
        withdrawlTime: this.medication.withdrawlTime || "",
        withdrawlTimeUnits: this.medication.withdrawlTimeUnits || "",
      };
      return data;
    },
    async save() {
      if (!this.$refs.medicationForm.validate() || this.submitting) return;

      this.submitting = true;
      const data = this.getData();
      try {
        if (this.medication.id) {
          await this.herdMeta.update("vaccinations", data);
        } else {
          await this.herdMeta.create("vaccinations", data, "medication");
          if (this.onCreate) {
            this.onCreate();
          }
        }
      } catch (e) {
        this.$notify({
          group: "forms",
          type: "danger",
          title: "Error",
          text: "An error occurred. Please refresh the page and try again.",
        });
        console.info(
          "An error occurred. Please refresh the page and try again."
        );
        this.submitting = false;
        return;
      }

      this.$emit("saved");
      this.showDialog = false;
    },
  },
};
</script>
