/// sort via https://r37r0m0d3l.github.io/json_sort/
export const i18n = {
  "Treatment Note": {
    en_US: "Treatment Note",
    es_ES: "Nota del Tratamiento",
  },
  "ADG Total": {
    en_US: "Total ADG",
    es_ES: "Total ADG",
  },
  "Alive or Cull": {
    en_US: "Alive or Cull",
    es_ES: "Vivo o Sacrificado",
  },
  "Animal Locations": {
    en_US: "Animal Locations",
    es_ES: "Ubicaciones del Animal",
  },
  animalsPages: {
    active: {
      en_US: "Active",
      es_ES: "Activo",
    },
    addFilter: {
      en_US: "Add filter",
      es_ES: "Agregar filtro",
    },
    addGroupNumber: {
      en_US: "Add group number",
      es_ES: "Agregar nombre de grupo",
    },
    animals: {
      en_US: "Animals",
      es_ES: "Animales",
    },
    any: {
      en_US: "Any",
      es_ES: "Cualquiera",
    },
    disable: {
      en_US: "Disable",
      es_ES: "Deshabilitar",
    },
    enable: {
      en_US: "Enable",
      es_ES: "Habilitar",
    },
    cancel: {
      en_US: "Cancel",
      es_ES: "Cancelar",
    },
    closeFilters: {
      en_US: "Close filters",
      es_ES: "Cerrar filtros",
    },
    createNewHerd: {
      en_US: "Create new herd",
      es_ES: "Crear nueva manada",
    },
    deleteGroupNumber: {
      en_US: "Delete group number",
      es_ES: "Borrar nombre de grupo",
    },
    edit: {
      en_US: "Edit",
      es_ES: "Editar",
    },
    editGroupNumber: {
      en_US: "Edit group number",
      es_ES: "Editar nombre de grupo",
    },
    fileImport: {
      en_US: "File import",
      es_ES: "Importación de archivos",
    },
    groupNumber: {
      en_US: "Group number",
      es_ES: "Nombre de grupo",
    },
    herdName: {
      en_US: "Herd name",
      es_ES: "Nombre de la manada",
    },
    herds: {
      en_US: "Herds",
      es_ES: "Manadas",
    },
    import: {
      en_US: "Import",
      es_ES: "Importar",
    },
    importRelationships: {
      en_US: "Import Relationships",
      es_ES: "Importar relaciones",
    },
    itemsPerPage: {
      en_US: "Items per page",
      es_ES: "Elementos por página",
    },
    name: {
      en_US: "Name",
      es_ES: "Nombre",
    },
    new: {
      en_US: "New",
      es_ES: "Nuevo",
    },
    numberOfAnimals: {
      en_US: "Number of animals",
      es_ES: "Número de animales",
    },
    requiredField: {
      en_US: "This field is required",
      es_ES: "Este campo es requerido",
    },
    reset: {
      en_US: "Reset",
      es_ES: "Limpiar",
    },
    save: {
      en_US: "Save",
      es_ES: "Guardar",
    },
    search: {
      en_US: "Search",
      es_ES: "Buscar",
    },
    updateHerd: {
      en_US: "Update herd",
      es_ES: "Actualizar manada",
    },
    withoutGroup: {
      en_US: "Without group",
      es_ES: "Sin grupo",
    },
  },
  Breedings: {
    en_US: "Breedings",
    es_ES: "Crías",
  },
  Bull: {
    en_US: "Bull",
    es_ES: "Toro",
  },
  By: {
    en_US: "By",
    es_ES: "By",
  },
  Calf: {
    en_US: "Calf",
    es_ES: "Becerro",
  },
  "Created/Entered/Saved": {
    en_US: "Created/Entered/Saved",
    es_ES: "Creado/Ingresado/Guardado",
  },
  "Create Sex": {
    en_US: "Create Sex",
    es_ES: "Crear Sexo",
  },
  "Data Transfer Time": {
    en_US: "Data Transfer Time",
    es_ES: "Tiempo de transferencia de datos",
  },
  "Date Type": {
    en_US: "Date Type",
    es_ES: "Fecha de typo",
  },
  edit: {
    en_US: "Edit",
    es_ES: "Editar",
  },
  "Edit Herd": {
    en_US: "Edit Herd",
    es_ES: "Editar Manada",
  },
  "Edit Sex": {
    en_US: "Edit Sex",
    es_ES: "Editar Sexo",
  },
  "Effective Date": {
    en_US: "Effective Date",
    es_ES: "Fecha efectiva",
  },
  "Group Number": {
    en_US: "Group Number",
    es_ES: "Número grupo",
  },
  Match: {
    en_US: "Match",
    es_ES: "Conjunto",
  },
  Medications: {
    en_US: "Medications",
    es_ES: "Medicamentos",
  },
  "Mouth Scores": {
    en_US: "Mouth Scores",
    es_ES: "Puntuaciones Bucales",
  },
  "Move Column": {
    en_US: "Move Columns",
    es_ES: "Mover columnas",
  },
  "Not Alive and not Cull": {
    en_US: "Not Alive and not Cull",
    es_ES: "No Vivo ni Sacrificado",
  },
  "Not Alive": {
    en_US: "Not Alive",
    es_ES: "No Vivo",
  },
  "Password Recovery": {
    en_US: "Password Recovery",
    es_ES: "Recuperación de contraseña",
  },
  All: {
    en_US: "All",
    es_ES: "Todos",
  },
  closeout: {
    definition: {
      en_US: "Definition",
      es_ES: "Definición",
    },
    endDate: {
      en_US: "End Date",
      es_ES: "Fecha final",
    },
    exportToCSV: {
      en_US: "Export to CSV",
      es_ES: "Exportar a CSV",
    },
    fullReport: {
      en_US: "Full Report",
      es_ES: "Reporte completo",
    },
    generate: {
      en_US: "Generate",
      es_ES: "Generar",
    },
    sampleDefinition: {
      en_US: "1 animal has a non-positive value.",
      es_ES: "1 animal has a non-positive value.",
    },
    startDate: {
      en_US: "Start Date",
      es_ES: "Fecha de inicio",
    },
    symbol: {
      en_US: "Symbol",
      es_ES: "Símbolo",
    },
    tasks: {
      en_US: "Tasks",
      es_ES: "Tareas",
    },
    timeRemaining: {
      en_US: "Time Remaining",
      es_ES: "Tiempo restante",
    },
  },
  Cull: {
    en_US: "Cull",
    es_ES: "Sacrificado",
  },
  EmptyGeoData: {
    en_US: "Empty Geo Data",
    es_ES: "Datos geográficos vacíos",
  },
  Enabled: {
    en_US: "Enabled",
    es_ES: "Habilitado",
  },
  EID: {
    en_US: "EID",
    es_ES: "EID",
  },
  EIDlastFour: {
    en_US: "EID (Last 4)",
    es_ES: "EID (Last 4)",
  },
  EIDs: {
    en_US: "EIDs",
    es_ES: "EIDs",
  },
  Filters: {
    en_US: "Filters",
    es_ES: "Filtros",
  },
  group: {
    en_US: "Group",
    es_ES: "Grupo",
  },
  HelpGuideButton: {
    en_US: "Click Here for a Search Help Guide",
    es_ES: "Click Here for a Search Help Guide",
  },
  Herd: {
    en_US: "Herd",
    es_ES: "Manada",
  },
  Herds: {
    en_US: "Herds",
    es_ES: "Manadas",
  },
  Status: {
    en_US: "Status",
    es_ES: "Estado",
  },
  Unknown: {
    config: {
      type: "string",
    },
    en_US: "Unknown",
    es_ES: "Desconocido",
  },
  WeightDate: {
    en_US: "Weight Date",
    es_ES: "Fecha de peso",
  },
  _id: {
    en_US: "Document ID",
    es_ES: "ID de documento",
  },
  accept: {
    en_US: "Accept",
    es_ES: "Acceptar",
  },
  actions: {
    en_US: "Actions",
    es_ES: "Acciones",
  },
  active: {
    en_US: "Active",
    es_ES: "Activo",
  },
  "Active Calves": {
    en_US: "Active Calves",
    es_ES: "Terneros Activos",
  },
  actualCalvingDate: {
    en_US: "Actual Calving Date",
    es_ES: "Fecha real de parto",
  },
  actualWithdrawlDate: {
    en_US: "Actual Withdrawl Date",
    es_ES: "Fecha real de retiro",
  },
  additionalInfo: {
    en_US: "Additional Information",
    es_ES: "Información Adicional",
  },
  adg: {
    en_US: "ADG",
    es_ES: "ADG",
  },
  adg2: {
    en_US: "ADG (Last Two Weights)",
    es_ES: "ADG (Last Two Weights)",
  },
  adgTotal: {
    en_US: "ADG Total",
    es_ES: "ADG Total",
  },
  admin: {
    en_US: "Admin",
    es_ES: "Admin",
  },
  administrationMethod: {
    en_US: "Administration Method",
    es_ES: "Método de administración",
  },
  adoptDam: {
    en_US: "Adopted Dam",
    es_ES: "Adopted Dam",
  },
  adoptDamAnimalIds: {
    en_US: "Adopt Dam",
    es_ES: "Adopt Dam",
  },
  advanceSearch: {
    en_US: "Advanced Search",
    es_ES: "Búsqueda Avanzada",
  },
  Age: {
    en_US: "Age",
    es_ES: "Edad",
  },
  AI: {
    en_US: "AI",
    es_ES: "AI",
  },
  aiSire: {
    en_US: "AI Sire",
    es_ES: "AI Sire",
  },
  Alerts: {
    en_US: "Alerts",
    es_ES: "Avisos",
  },
  alive: {
    en_US: "Alive",
    es_ES: "Vivo",
  },
  "Alive or Cull": {
    en_US: "Alive or Cull",
    es_ES: "Vivo o Sacrificado",
  },
  All: {
    en_US: "All",
    es_ES: "Todo",
  },
  Alley: {
    en_US: "Alley",
    es_ES: "Alley",
  },
  alleyName: {
    en_US: "Alley Name",
    es_ES: "Callejón",
  },
  america: {
    en_US: "US",
    es_ES: "US",
  },
  "Animal Locations": {
    en_US: "Animal Locations",
    es_ES: "Ubicaciones del Animal",
  },
  animalCharacteristics: {
    en_US: "Animal Characteristics",
    es_ES: "Características del animal",
  },
  animalColor: {
    en_US: "Animal Color",
    es_ES: "Color del animal",
  },
  animalCount: {
    en_US: "Total Animals (Alive)",
    es_ES: "Cantidad total de vivos",
  },
  "Last Weight": {
    en_US: "Last Weight",
    es_ES: "Último peso",
  },
  animalCountSuper: {
    en_US: "Super Total Animals (Alive)",
    es_ES: "Super Total Animals (Alive)",
  },
  Arrived: {
    en_US: "Arrived",
    es_ES: "Arrived",
  },
  animalDetails: {
    age: {
      en_US: "Age",
      es_ES: "Age",
    },
    alerts: {
      en_US: "Alerts",
      es_ES: "Alerts",
    },
    animalIdRequired: {
      en_US: "Animal ID is required.",
      es_ES: "Animal ID is required.",
    },
    animalNoExist: {
      en_US:
        "This animal does not appear to exist. Are you still logged in? Is the URL correct? If so, then either the animal has been deleted, has not synced, or you do not have full access.",
      es_ES:
        "This animal does not appear to exist. Are you still logged in? Is the URL correct? If so, then either the animal has been deleted, has not synced, or you do not have full access.",
    },
    animalsDataWasMerged: {
      en_US: "Animal data was merged.",
      es_ES: "Animal data was merged.",
    },
    Birth: {
      en_US: "Birth",
      es_ES: "Nacimiento",
    },
    Characteristics: {
      en_US: "Characteristics",
      es_ES: "Características",
    },
    Currents: {
      en_US: "Currents",
      es_ES: "Currents",
    },
    deathDateRemoved: {
      en_US: "Death date was removed.",
      es_ES: "Death date was removed.",
    },
    deathDateUpdated: {
      en_US: "Death date was updated.",
      es_ES: "Death date was updated.",
    },
    deduplicationCompleted: {
      en_US: "Deduplication completed.",
      es_ES: "Deduplication completed.",
    },
    "deleted-meta": {
      en_US: "deleted meta",
      es_ES: "deleted meta",
    },
    description: {
      en_US: "Description",
      es_ES: "Description",
    },
    endDate: {
      en_US: "End Date",
      es_ES: "End Date",
    },
    failedToMarkAsNotDuplicates: {
      en_US: "Failed to mark as not duplicates.",
      es_ES: "Failed to mark as not duplicates.",
    },
    failedToMerge: {
      en_US: "Failed to merge.",
      es_ES: "Failed to merge.",
    },
    failedToSave: {
      en_US: "Save failed.",
      es_ES: "Save failed.",
    },
    Family: {
      en_US: "Family",
      es_ES: "Family",
    },
    Financials: {
      en_US: "Financials",
      es_ES: "Finanzas",
    },
    mergeCompleted: {
      en_US: "Merge completed.",
      es_ES: "Merge completed.",
    },
    mergeDanger: {
      en_US: "Please connect to internet soon after completing.",
      es_ES: "Please connect to internet soon after completing.",
    },
    mergeInstructions: {
      en_US:
        "If either of the below animals is a duplicate, please remove any incorrect information before merging. In the event that there is data mismatch, the information from animal 1 will be used if it is not empty.",
      es_ES:
        "If either of the below animals is a duplicate, please remove any incorrect information before merging. In the event that there is data mismatch, the information from animal 1 will be used if it is not empty.",
    },
    mergeIsPermanent: {
      en_US:
        "Merge is permanent. In the event that there is a conflict, information from Animal 1 will be preferred (example: birthdate differs). Are you sure you want to merge these animals?",
      es_ES:
        "Merge is permanent. In the event that there is a conflict, information from Animal 1 will be preferred (example: birthdate differs). Are you sure you want to merge these animals?",
    },
    mergeWarning: {
      en_US: "Be sure you are connected to the internet to sync.",
      es_ES: "Be sure you are connected to the internet to sync.",
    },
    "non-indexed": {
      en_US: "non-indexed",
      es_ES: "non-indexed",
    },
    noPotentialDuplicatesRemain: {
      en_US: "There are no potential duplicates remaining.",
      es_ES: "There are no potential duplicates remaining.",
    },
    noPotentialDuplicatesRemainForThisAnimal: {
      en_US: "No potential duplicates remain for this animal.",
      es_ES: "No potential duplicates remain this animal.",
    },
    Other: {
      en_US: "Other",
      es_ES: "Other",
    },
    panels: {
      breedings: {
        en_US: "Breed History",
        es_ES: "Breed History",
      },
      bullTests: {
        en_US: "Bull Test History",
        es_ES: "Bull Test History",
      },
      calvings: {
        en_US: "Calving History",
        es_ES: "Calving History",
      },
      colors: {
        en_US: "Color History",
        es_ES: "Color History",
      },
      comments: {
        en_US: "Comment History",
        es_ES: "Comment History",
      },
      ids: {
        en_US: "Tags",
        es_ES: "Etiquetas",
      },
      pregChecks: {
        en_US: "Preg Check History",
        es_ES: "Preg Check History",
      },
      salesPurchases: {
        en_US: "Purchase Sale History",
        es_ES: "Historial de compra venta",
      },
      sexes: {
        en_US: "Sex History",
        es_ES: "Sex History",
      },
      sightings: {
        en_US: "Sighting History",
        es_ES: "Sighting History",
      },
      statuses: {
        en_US: "Status History",
        es_ES: "Status History",
      },
      transfers: {
        en_US: "Transfers",
        es_ES: "Transfers",
      },
      treatments: {
        en_US: "Treatment History",
        es_ES: "Treatment History",
      },
      weights: {
        en_US: "Weight History",
        es_ES: "Weight History",
      },
    },
    pleaseUpdateBirthDate: {
      en_US: "Please update birth date.",
      es_ES: "Please update birth date.",
    },
    pleaseUpdateDeathDate: {
      en_US: "Please update death date.",
      es_ES: "Please update death date.",
    },
    proceedToNextDuplicateForThisAnimal: {
      en_US: "Proceeding to next potential duplicate for this animal.",
      es_ES: "Proceeding to next potential duplicate for this animal.",
    },
    saved: {
      en_US: "Saved",
      es_ES: "Saved",
    },
    someMightBeDuplicates: {
      en_US: "Some animals might be duplicates.",
      es_ES: "Some animals might be duplicates.",
    },
    startDate: {
      en_US: "Start Date",
      es_ES: "Start Date",
    },
    tasks: {
      en_US: "Tasks",
      es_ES: "Tasks",
    },
    unableToQueryMainDatabase: {
      en_US: "Unable query main database. Please try again later.",
      es_ES: "Unable query main database. Please try again later.",
    },
    Weight: {
      en_US: "Weight",
      es_ES: "Peso",
    },
  },
  animalId: {
    en_US: "Animal ID",
    es_ES: "Animal ID",
  },
  animalIds: {
    en_US: "Tags",
    es_ES: "Etiquetas",
  },
  animalInfo: {
    en_US: "Animal Information",
    es_ES: "Animal Information",
  },
  animals: {
    AllMax1000: {
      en_US: "All (max 1000)",
      es_ES: "All (max 1000)",
    },
  },
  antenna: {
    en_US: "Antenna",
    es_ES: "Antenna",
  },
  archived: {
    en_US: "Archived",
    es_ES: "Archived",
  },
  associationTagValues: {
    en_US: "Association Numbers",
    es_ES: "Association Numbers",
  },
  automatic: {
    en_US: "Automatic",
    es_ES: "Automatic",
  },
  Avg_: {
    en_US: "Avg",
    es_ES: "Prom.",
  },
  Average: {
    en_US: "Average",
    es_ES: "Promedio",
  },
  "Average Birth Weight": {
    en_US: "Avg Birth Weight",
    es_ES: "Promedio de Peso Natal",
  },
  "Avg Weight": {
    en_US: "Avg. Weight",
    es_ES: "Promedio de Peso",
  },
  backtag: {
    en_US: "Backtag",
    es_ES: "Backtag",
  },
  backtagColors: {
    en_US: "Backtag Colors",
    es_ES: "Backtag Colors",
  },
  backtagValue: {
    en_US: "Backtag Value",
    es_ES: "Backtag Value",
  },
  backtagValues: {
    en_US: "Backtags",
    es_ES: "Backtags",
  },
  batchNumber: {
    en_US: "Batch Number",
    es_ES: "Batch Number",
  },
  Bcs: {
    en_US: "BCS",
    es_ES: "BCS",
  },
  Beta: {
    en_US: "Beta",
    es_ES: "Beta",
  },
  "Billing Organization Guid": {
    en_US: "Billing Organization Guid",
    es_ES: "Billing Organization Guid",
  },
  billingOrganization: {
    en_US: "Billing Organization",
    es_ES: "Billing Organization",
  },
  billingRepresentative: {
    en_US: "Billing Representative",
    es_ES: "Billing Representative",
  },
  Birth: {
    en_US: "Birth",
    es_ES: "Nacimiento",
  },
  "Birth Difficult": {
    en_US: "Birth Difficult",
    es_ES: "Nacimiento Complicado",
  },
  "Birth Easy": {
    en_US: "Birth Easy",
    es_ES: "Nacimiento Fácil",
  },
  "Birth Report": {
    en_US: "Birth Report",
    es_ES: "Reporte de Nacimientos",
  },
  birthCalvingEase: {
    en_US: "Calving Score",
    es_ES: "Calving Score",
  },
  birthDate: {
    en_US: "Birth Date",
    es_ES: "Fecha de nacimiento",
  },
  birthVisual: {
    en_US: "Birth Visual",
    es_ES: "Número de Etiqueta Visual de Nacimiento",
  },
  birthWeight: {
    en_US: "Birth Weight",
    es_ES: "Birth Weight",
  },
  black: {
    en_US: "Black",
    es_ES: "Black",
  },
  blackBrockle: {
    en_US: "Black Brockle",
    es_ES: "Black Brockle",
  },
  blackWhiteFace: {
    en_US: "Black White Face",
    es_ES: "Black White Face",
  },
  blueTongue: {
    en_US: "Blue Tongue",
    es_ES: "Blue Tongue",
  },
  bovine: {
    en_US: "Bovine",
    es_ES: "Bovine",
  },
  brandTagValues: {
    en_US: "Brand Tags",
    es_ES: "Brand Tags",
  },
  Bred: {
    en_US: "Bred",
    es_ES: "Criado",
  },
  "Bred Status": {
    en_US: "Bred Status",
    es_ES: "Bred Status",
  },
  "Bred To": {
    en_US: "Bred To",
    es_ES: "Bred To",
  },
  breed: {
    en_US: "Breed",
    es_ES: "Breed",
  },
  "Breed Date": {
    en_US: "Breed Date",
    es_ES: "Breed Date",
  },
  breedHistory: {
    en_US: "Breed History",
    es_ES: "Breed History",
  },
  brisketTagValues: {
    en_US: "Brisket Numbers",
    es_ES: "Brisket Numbers",
  },
  browse: {
    en_US: "Browse",
    es_ES: "Browse",
  },
  "Bull Turnin Date": {
    en_US: "Bull Turn-in Date",
    es_ES: "Bull Turn-in Date",
  },
  bullCalf: {
    en_US: "Bull Calf",
    es_ES: "Bull Calf",
  },
  bulls: {
    en_US: "Bulls",
    es_ES: "Toros",
  },
  bullTest: {
    en_US: "Bull Test",
    es_ES: "Bull Test",
  },
  bullTestHistory: {
    en_US: "Bull Test History",
    es_ES: "Bull Test History",
  },
  bullTestMethod: {
    en_US: "Bull Test Method",
    es_ES: "Método de prueba",
  },
  bullTestResult: {
    en_US: "Bull Test Result",
    es_ES: "Resultado de prueba del toro",
  },
  bullTestTestedBy: {
    en_US: "Vet / Tech",
    es_ES: "Vet / Tech",
  },
  bump: {
    en_US: "Bump",
    es_ES: "Bump",
  },
  "Calf Birth Summary": {
    en_US: "Calf Birth Summary",
    es_ES: "Resumen de Nacimientos",
  },
  "Calf Died": {
    en_US: "Calf Died",
    es_ES: "Terneros Fallecidos",
  },
  calfComment: {
    en_US: "Calf Comment",
    es_ES: "Calf Comment",
  },
  calfEID: {
    en_US: "EID",
    es_ES: "EID",
  },
  calfVisual: {
    en_US: "Offspring Visual ID",
    es_ES: "Offspring Visual ID",
  },
  calfWeanPage: {
    form: {
      breed: {
        en_US: "Breed",
        es_ES: "Raza",
      },
      bull: {
        en_US: "Bull",
        es_ES: "Bull",
      },
      comments: {
        en_US: "Comments",
        es_ES: "Comentarios",
      },
      cullThisCalf: {
        en_US: "Cull",
        es_ES: "Sacrificar",
      },
      date: {
        en_US: "Date",
        es_ES: "Fecha",
      },
      fetalSex: {
        en_US: "Fetal Sex",
        es_ES: "Sexo Fetal",
      },
      findByEID: {
        en_US: "EID",
        es_ES: "EID",
      },
      findByTag: {
        en_US: "Visual",
        es_ES: "Visual",
      },
      group: {
        en_US: "Group",
        es_ES: "Grupo",
      },
      heifer: {
        en_US: "Heifer",
        es_ES: "Heifer",
      },
      herd: {
        en_US: "Herd",
        es_ES: "Rebaño",
      },
      name: {
        en_US: "Calf Wean",
        es_ES: "Calf Wean",
      },
      newRanchUnit: {
        en_US: "Receiving Ranch",
        es_ES: "Rancho",
      },
      origin: {
        en_US: "Origin",
        es_ES: "Origen",
      },
      preConditioned: {
        en_US: "PreConditioned",
        es_ES: "Pre-condicionado",
      },
      reset: {
        en_US: "Reset",
        es_ES: "Limpiar",
      },
      save: {
        en_US: "Save",
        es_ES: "Guardar",
      },
      sex: {
        en_US: "Sex",
        es_ES: "Sexo",
      },
      steer: {
        en_US: "Steer",
        es_ES: "Steer",
      },
      tagColor: {
        en_US: "Visual Color",
        es_ES: "Color de etiqueta",
      },
      tsu: {
        en_US: "TSU #",
        es_ES: "TSU #",
      },
      weanWeight: {
        en_US: "Wean Weight",
        es_ES: "Peso de destete",
      },
    },
    groupSummary: {
      avgWeight: {
        en_US: "Avg Wt",
        es_US: "Avg Wt",
      },
      breed: {
        en_US: "Breed",
        es_ES: "Breed",
      },
      bulls: {
        en_US: "Bulls",
        es_US: "Bulls",
      },
      fetalSex: {
        en_US: "Fetal Sex",
        es_ES: "Sexo Fetal",
      },
      heifers: {
        en_US: "Heifers",
        es_US: "Heifers",
      },
      previousEntry: {
        en_US: "Previous Entry",
        es_ES: "Previous Entry",
      },
      sex: {
        en_US: "Sex",
        es_ES: "Sexo",
      },
      steers: {
        en_US: "Steers",
        es_ES: "Novillos",
      },
      tag: {
        en_US: "Tag #",
        es_ES: "Tag #",
      },
      totalAvgWeight: {
        en_US: "Total Avg Wt",
        es_US: "Total Avg Wt",
      },
      totalWeaned: {
        en_US: "Total Weaned",
        es_US: "Total Weaned",
      },
      weight: {
        en_US: "Weight",
        es_ES: "Peso",
      },
    },
    information: {
      birthDate: {
        en_US: "Birth Date",
        es_ES: "Fecha de nacimiento",
      },
      birthRanch: {
        en_US: "Birth Ranch",
        es_ES: "Birth Ranch",
      },
      birthVisual: {
        en_US: "Birth Visual",
        es_ES: "Birth Visual",
      },
      birthWeight: {
        en_US: "Birth Weight",
        es_ES: "Birth Weight",
      },
      breed: {
        en_US: "Breed",
        es_ES: "Breed",
      },
      calvingScore: {
        en_US: "Calving Score",
        es_ES: "Calving Score",
      },
      comment: {
        en_US: "Comment",
        es_ES: "Comentario",
      },
      dam: {
        en_US: "Dam",
        es_ES: "Dam",
      },
      implantNumber: {
        en_US: "Implant Number",
        es_ES: "Implant Number",
      },
      name: {
        en_US: "Calf Information",
        es_ES: "Calf Information",
      },
      sire: {
        en_US: "Sire",
        es_ES: "Sire",
      },
      tsuNumber: {
        en_US: "TSU/DNA #",
        es_ES: "TSU/DNA #",
      },
      vigor: {
        en_US: "Vigor",
        es_ES: "Vigor",
      },
      weanDate: {
        en_US: "Wean Date",
        es_ES: "Wean Date",
      },
    },
    name: {
      en_US: "Calf Wean",
      es_ES: "Destete de Terneros",
    },
    summary: {
      breed: {
        en_US: "Breed",
        es_ES: "Breed",
      },
      createdOn: {
        en_US: "Created",
        es_ES: "Fecha de Creación",
      },
      description: {
        en_US: "Recent calf weanings",
        es_ES: "Recent calf weanings",
      },
      fetalSex: {
        en_US: "Fetal Sex",
        es_ES: "Sexo Fetal",
      },
      name: {
        en_US: "Summary",
        es_ES: "Resumen",
      },
      sex: {
        en_US: "Sex",
        es_ES: "Sexo",
      },
      tagValues: {
        en_US: "Tag Values",
        es_ES: "Tag Values",
      },
      timeRecorded: {
        en_US: "Effective Date",
        es_ES: "Fecha efectiva",
      },
      tsuNumber: {
        en_US: "TSU #",
        es_ES: "TSU #",
      },
      weanDate: {
        en_US: "Wean Date",
        es_ES: "Wean Date",
      },
      weight: {
        en_US: "Weight",
        es_ES: "Peso",
      },
    },
  },
  calfWeanSummary: {
    en_US: "Calf Wean Summary",
    es_ES: "Calf Wean Summary",
  },
  "Calves Born": {
    en_US: "Calves Born",
    es_ES: "Terneros Nacidos",
  },
  calvingEase: {
    en_US: "Calving Score",
    es_ES: "Calving Score",
  },
  calvingHistory: {
    en_US: "Calving History",
    es_ES: "Calving History",
  },
  cancelButton: {
    en_US: "Cancel",
    es_ES: "Cancel",
  },
  "Carc ID": {
    en_US: "Carc ID",
    es_ES: "Carc ID",
  },
  "Carcass Age": {
    en_US: "Carcass Age",
    es_ES: "Edad de la Carcasa",
  },
  "Carcass Type": {
    en_US: "Carcass Type",
    es_ES: "Tipo de Carcasa",
  },
  cardNumber: {
    en_US: "Card Number",
    es_ES: "Card Number",
  },
  cellPhone: {
    en_US: "Cell #",
    es_ES: "Cell #",
  },
  charolais: {
    en_US: "Charolais",
    es_ES: "Charolais",
  },
  charolaisX: {
    en_US: "Charolais X",
    es_ES: "Charolais X",
  },
  checkedBy: {
    en_US: "Checked by",
    es_ES: "Checked by",
  },
  chooseOption: {
    en_US: "Choose",
    es_ES: "Choose",
  },
  "CIDR Implant": {
    en_US: "CIDR Implant",
    es_ES: "CIDR Implant",
  },
  "CIDR Removal": {
    en_US: "CIDR Removal",
    es_ES: "CIDR Removal",
  },
  "CIDR Status": {
    en_US: "CIDR Status",
    es_ES: "Estado del CIDR",
  },
  clear: {
    en_US: "Clear",
    es_ES: "Clear",
  },
  Cleared: {
    en_US: "Cleared",
    es_ES: "Cleared",
  },
  clearanceDate: {
    en_US: "Clearance Date",
    es_ES: "Clearance Date",
  },
  clearanceTimeFrame: {
    en_US: "Clearance Timeframe",
    es_ES: "Clearance Timeframe",
  },
  close: {
    en_US: "Close",
    es_ES: "Close",
  },
  closeButton: {
    en_US: "Close",
    es_ES: "Close",
  },
  color: {
    en_US: "Color",
    es_ES: "Color",
  },
  colors: {
    en_US: "Colors",
    es_ES: "Colores",
  },
  column: {
    en_US: "Column",
    es_ES: "Column",
  },
  combined: {
    en_US: "Combined",
    es_ES: "Combined",
  },
  comment: {
    en_US: "Comment",
    es_ES: "Comentario",
  },
  commentDate: {
    en_US: "Comment Date",
    es_ES: "Comment Date",
  },
  commentHistory: {
    en_US: "Comment History",
    es_ES: "Comment History",
  },
  commentInField: {
    en_US: "Comment",
    es_ES: "Comentario",
  },
  comments: {
    en_US: "Comments",
    es_ES: "Comments",
  },
  commissionRate: {
    en_US: "Commission Rate (CWT)",
    es_ES: "Ratio de comision (CWT)",
  },
  commissionRateType: {
    en_US: "Type",
    es_ES: "Tipo",
  },
  commissionType: {
    en_US: "Commission Type",
    es_ES: "Commission Type",
  },
  commodityTypes: {
    friendlyName: {
      en_US: "Friendly Name(s)",
      es_ES: "Alias(es)",
    },
    name: {
      en_US: "Commodity Type",
      es_ES: "Commodity Type",
    },
  },
  complete: {
    en_US: "Complete",
    es_ES: "Complete",
  },
  completeTagList: {
    en_US: "Show all animals read on date",
    es_ES: "Complete",
  },
  complexSearch: {
    en_US: "Add Complex Search",
    es_ES: "Add Complex Search",
  },
  confirmDelete: {
    en_US: "Are you sure you want to delete this record?",
    es_ES: "Are you sure you want to delete this record?",
  },
  cost: {
    en_US: "cost",
    es_ES: "cost",
  },
  coverSire: {
    en_US: "Cover Sire",
    es_ES: "Cover Sire",
  },
  coverSireAnimalIds: {
    en_US: "Cover Sire",
    es_ES: "Cover Sire",
  },
  Cow: {
    en_US: "Cow",
    es_ES: "Vaca",
  },
  cows: {
    en_US: "Cows",
    es_ES: "Cows",
  },
  create: {
    en_US: "Create",
    es_ES: "Crear",
  },
  Create: {
    en_US: "Create",
    es_ES: "Crear",
  },
  "Create records for undocumented animals": {
    en_US: "Create records for undocumented animals",
    es_ES: "Create records for undocumented animals",
  },
  "Created/Entered/Saved": {
    en_US: "Created/Entered/Saved",
    es_ES: "Created/Entered/Saved",
  },
  createdOn: {
    en_US: "Creation Date",
    es_ES: "Fecha de Creación",
  },
  "Creation Date": {
    en_US: "Creation Date",
    es_ES: "Fecha de Creación",
  },
  Cull: {
    en_US: "Cull",
    es_ES: "Sacrificado",
  },
  currentOrganization: {
    en_US: "Current Organization ID",
    es_ES: "Current Organization ID",
  },
  currentWeight: {
    en_US: "Current Weight",
    es_ES: "Current Weight",
  },
  currentWeightDate: {
    en_US: "Current Weight Date",
    es_ES: "Current Weight Date",
  },
  Cycle: {
    en_US: "Cycle",
    es_ES: "Ciclo",
  },
  dailySTDEV: {
    en_US: "Daily STDEV",
    es_ES: "Daily STDEV",
  },
  dam: {
    en_US: "Dam",
    es_ES: "Dam",
  },
  damAnimalIds: {
    en_US: "Dam",
    es_ES: "Dam",
  },
  damComment: {
    en_US: "Dam Comment",
    es_ES: "Dam Comment",
  },
  damTags: {
    en_US: "Dam Tags",
    es_ES: "Dam Tags",
  },
  dataTables: {
    buttons: {
      colvis: {
        en_US: "Toggle",
        es_ES: "Mostrar columnas",
      },
      export: {
        en_US: "Export",
        es_ES: "Exportar como",
      },
      responsive: {
        en_US: "Tablet",
        es_ES: "Tablet",
      },
      select: {
        en_US: "Select",
        es_ES: "Seleccionar",
      },
    },
    emptyTable: {
      en_US: "No data available in table",
      es_ES: "No hay data disponible en la tabla",
    },
    info: {
      en_US: "Showing _START_ to _END_ of _TOTAL_ entries",
      es_ES: "Mostrando del _START_ al _END_  de un total de _TOTAL_ entradas",
    },
    infoEmpty: {
      en_US: "Showing 0 to 0 of 0 entries",
      es_ES: "Mostrando del 0 al 0 de 0 entradas",
    },
    infoFiltered: {
      en_US: "(filtered from _MAX_ total entries)",
      es_ES: "(filtrado de un total de _MAX_ entradas)",
    },
    lengthMenu: {
      en_US: "Show _MENU_ entries",
      es_ES: "Mostrar _MENU_ entradas",
    },
    loadingRecords: {
      en_US: "Loading...",
      es_ES: "Cargando...",
    },
    paginate: {
      first: {
        en_US: "First",
        es_ES: "Primero",
      },
      last: {
        en_US: "Last",
        es_ES: "Último",
      },
      next: {
        en_US: "Next",
        es_ES: "Siguiente",
      },
      previous: {
        en_US: "Previous",
        es_ES: "Anterior",
      },
    },
    processing: {
      en_US: "Processing...",
      es_ES: "Procesando...",
    },
    search: {
      en_US: "Search",
      es_ES: "Buscar",
    },
    zeroRecords: {
      en_US: "No matching records found",
      es_ES: "No se encontró ningún resultado",
    },
  },
  datatablesi18nPath: {
    en_US: "templates/English.json",
    es_ES: "templates/Spanish.json",
  },
  "Data Type": {
    en_US: "Data Type",
    es_ES: "Tipo de dato",
  },
  date: {
    en_US: "Date",
    es_ES: "Fecha",
  },
  "Date Type": {
    en_US: "Date Type",
    es_ES: "Date Type",
  },
  dateRange: {
    en_US: "Date Range",
    es_ES: "Date Range",
  },
  dateRecorded: {
    en_US: "Date Recorded",
    es_ES: "Date Recorded",
  },
  "Days Bred": {
    en_US: "Days Bred",
    es_ES: "Días criado",
  },
  daysAtLocation: {
    en_US: "Days at Current Location",
    es_ES: "Days at Current Location",
  },
  daysBetweenFirstAndLastWeight: {
    en_US: "Days between first and current weight",
    es_ES: "Days between first and current weight",
  },
  daysSinceLastMove: {
    en_US: "Days Since Last Move",
    es_ES: "Days Since Last Move",
  },
  daysSinceLastWeight: {
    en_US: "Days Since Last Weight",
    es_ES: "Days Since Last Weight",
  },
  daysSincePregCheck: {
    en_US: "Days Since Preg Check",
    es_ES: "Days Since Preg Check",
  },
  "DB Purge": {
    en_US: "DB Purge",
    es_ES: "Purga del BD",
  },
  dead: {
    en_US: "Dead",
    es_ES: "Dead",
  },
  "Death Loss Percent": {
    en_US: "Death Loss Percent",
    es_ES: "Porcentaje de Pérdidas por muerte",
  },
  deathDate: {
    en_US: "Death Date",
    es_ES: "Death Date",
  },
  Definition: {
    en_US: "Definition",
    es_ES: "Definicion",
  },
  "Deleted On": {
    en_US: "Deleted On",
    es_ES: "Borrado el:",
  },
  Deleted: {
    en_US: "Deleted",
    es_ES: "Eliminado",
  },
  delete: {
    en_US: "Delete",
    es_ES: "Delete",
  },
  description: {
    en_US: "Description",
    es_ES: "Description",
  },
  Destination: {
    en_US: "Destination",
    es_ES: "Destination",
  },
  "Destination Reader": {
    en_US: "Destination Reader",
    es_ES: "Lector de destino",
  },
  "Destination Reader Time": {
    en_US: "Destination Reader Time",
    es_ES: "Tiempo del lector de destino",
  },
  details: {
    en_US: "Details",
    es_ES: "Details",
  },
  diagnosis: {
    en_US: "Diagnosis",
    es_ES: "Diagnosis",
  },
  Discarded: {
    en_US: "Discarded",
    es_ES: "Discarded",
  },
  disclaimer: {
    en_US: "I have read and understand the above disclaimer.",
    es_ES: "I have read and understand the above disclaimer.",
  },
  displayAnimals: {
    date: {
      config: {
        type: "datetime",
      },
      en_US: "Date",
      es_ES: "Fecha",
    },
    details: {
      en_US: "Display Animals Details",
      es_ES: "Display Animals Details",
    },
    label: {
      en_US: "Display Animals",
      es_ES: "Mostrar animales",
    },
    lf: {
      en_US: "LF",
      es_ES: "LF",
    },
    navbar: {
      en_US: "Display Animals",
      es_ES: "Mostrar animales",
    },
    total: {
      en_US: "Total",
      es_ES: "Total",
    },
    uhf: {
      en_US: "UHF",
      es_ES: "UHF",
    },
  },
  disposition: {
    en_US: "Disposition",
    es_ES: "Disposition",
  },
  dispositionDetails: {
    en_US: "Disposition Animals Details",
    es_ES: "Disposition Animals Details",
  },
  dispositionLabel: {
    en_US: "Disposition Animals",
    es_ES: "Disposition Animals",
  },
  "Dna Number": {
    en_US: "TSU/DNA #",
    es_ES: "TSU/DNA #",
  },
  doctoringCost: {
    en_US: "Doctoring Cost",
    es_ES: "Doctoring Cost",
  },
  dose: {
    en_US: "Required Dose",
    es_ES: "Required Dose",
  },
  doseCost: {
    en_US: "Cost",
    es_ES: "Cost",
  },
  doseGiven: {
    en_US: "Dose Administered",
    es_ES: "Dose Administered",
  },
  dosesInBottle: {
    en_US: "cc's In Bottle",
    es_ES: "cc's In Bottle",
  },
  doseUnits: {
    en_US: "Dose Units",
    es_ES: "Dose Units",
  },
  dosingGun: {
    en_US: "Dosing Gun",
    es_ES: "Dosing Gun",
  },
  "Edit Herd": {
    en_US: "Edit Herd",
    es_ES: "Editar Manada",
  },
  editParentInformation: {
    eid: {
      en_US: "EID",
      es_ES: "EID",
    },
    results: {
      en_US: "Results",
      es_ES: "Resultados",
    },
    searchBy: {
      en_US: "Search by",
      es_ES: "Buscar por",
    },
    type: {
      en_US: "Type",
      es_ES: "Tipo",
    },
    visual: {
      en_US: "Visual",
      es_ES: "Visual",
    },
  },
  editTsu: {
    en_US: "Edit TSU/DNA",
    es_ES: "Editar TSU/DNA",
  },
  "Effective Date": {
    en_US: "Effective Date",
    es_ES: "Fecha efectiva",
  },
  EID: {
    en_US: "EID",
    es_ES: "EID",
  },
  EIDcolors: {
    en_US: "EID Colors",
    es_ES: "EID Colors",
  },
  EIDlastFour: {
    en_US: "EID (Last 4)",
    es_ES: "EID (Last 4)",
  },
  EIDs: {
    en_US: "EIDs",
    es_ES: "EIDs",
  },
  emailPrompt: {
    en_US: "Email (Used as Login)",
    es_ES: "Email (Used as Login)",
  },
  embryo: {
    en_US: "Embryo",
    es_ES: "Embryo",
  },
  endDate: {
    en_US: "End Date",
    es_ES: "End Date",
  },
  enums: {
    activateableTagTypes: {
      all: {
        en_US: "EIDs, VIDs, Backtags",
        es_ES: "EIDs, VIDs, Backtags",
      },
      BacktagOnly: {
        en_US: "Backtags only",
        es_ES: "Backtags only",
      },
      EIDonly: {
        en_US: "EIDs only",
        es_ES: "EIDs only",
      },
      VIDonly: {
        en_US: "VIDs only",
        es_ES: "VIDs only",
      },
    },
    activeInactiveAllCaps: {
      ACTIVE: {
        en_US: "ACTIVE",
        es_ES: "ACTIVO",
      },
      INACTIVE: {
        en_US: "INACTIVE",
        es_ES: "INACTIVE",
      },
    },
    aiStatusBred: {
      implanted: {
        en_US: "Bred",
        es_ES: "Bred",
      },
      skipped: {
        en_US: "Skipped",
        es_ES: "Omitido",
      },
    },
    bcs: {
      1: {
        en_US: "1",
        es_ES: "1",
      },
      2: {
        en_US: "2",
        es_ES: "2",
      },
      3: {
        en_US: "3",
        es_ES: "3",
      },
      4: {
        en_US: "4",
        es_ES: "4",
      },
      5: {
        en_US: "5",
        es_ES: "5",
      },
      6: {
        en_US: "6",
        es_ES: "6",
      },
      7: {
        en_US: "7",
        es_ES: "7",
      },
      8: {
        en_US: "8",
        es_ES: "8",
      },
      9: {
        en_US: "9",
        es_ES: "9",
      },
    },
    birthCalvingEases: {
      abnormal_presentation: {
        config: {
          type: "string",
        },
        en_US: "5-Abnormal Presentation",
        es_ES: "5-Presentación anormal",
      },
      caesarean: {
        config: {
          type: "string",
        },
        en_US: "4-Caesarean",
        es_ES: "4-Cesariano",
      },
      assisted_very_difficult: {
        config: {
          type: "string",
        },
        en_US: "3-Assisted, very difficult",
        es_ES: "3-Asistido, muy difícil(masculine)",
      },
      assisted_easy: {
        config: {
          type: "string",
        },
        en_US: "2-Assisted, easy",
        es_ES: "2-Asistido, fácil",
      },
      no_assistance: {
        config: {
          type: "string",
        },
        en_US: "1-No Assistance",
        es_ES: "1-Sin asistencia",
      },
    },
    breeds: {
      "3/4 Angus": {
        en_US: "3/4 Angus",
        es_ES: "3/4 Angus",
      },
      Angus: {
        en_US: "Angus",
        es_ES: "Angus",
      },
      "Black Baldy": {
        en_US: "Black Baldy",
        es_ES: "Black Baldy",
      },
      Charolais: {
        en_US: "Charolais",
        es_ES: "Charolais",
      },
      Hereford: {
        en_US: "Hereford",
        es_ES: "Hereford",
      },
      "Qtr Charolais": {
        en_US: "Qtr Charolais",
        es_ES: "Qtr Charolais",
      },
      "Red Angus": {
        en_US: "Red Angus",
        es_ES: "Red Angus",
      },
      "Red Baldy": {
        en_US: "Red Baldy",
        es_ES: "Red Baldy",
      },
      SimAngus: {
        en_US: "SimAngus",
        es_ES: "SimAngus",
      },
      Simmental: {
        en_US: "Simmental",
        es_ES: "Simmental",
      },
      Wagyu: {
        en_US: "Wagyu",
        es_ES: "Wagyu",
      },
    },
    bullCheckTests: {
      anaplasmosis: {
        en_ES: "Anaplasmosis",
        en_US: "Anaplasmosis",
      },
      bluetongue: {
        en_ES: "Blue Tongue",
        en_US: "Lengua azul",
      },
      blv: {
        en_ES: "BLV",
        en_US: "BLV",
      },
      bvd: {
        en_ES: "BVD",
        en_US: "BVD",
      },
      johnes: {
        en_ES: "Johne's",
        en_US: "Johne's",
      },
      mobilitysoundness: {
        en_ES: "Mobility Soundness",
        en_US: "Movilidad Solidez",
      },
      pap: {
        en_ES: "PAP",
        en_US: "PAP",
      },
      physical: {
        en_ES: "Physical",
        en_US: "Physical",
      },
      reproductivetract: {
        en_ES: "Reproductive Tract",
        en_US: "Aparato reproductor",
      },
      semenmotility: {
        en_ES: "Semen Motility",
        en_US: "Motilidad del semen",
      },
      trich: {
        en_ES: "Trich",
        en_US: "Trich",
      },
      tuberculosis: {
        en_ES: "Tuberculosis",
        en_US: "Tuberculosis",
      },
      vibrio: {
        en_ES: "Vibrio",
        en_US: "Vibrio",
      },
    },
    cycles: {
      1: {
        en_US: 1,
        es_ES: 1,
      },
      2: {
        en_US: 2,
        es_ES: 2,
      },
      late: {
        en_US: "Late",
        es_ES: "Tarde",
      },
      open: {
        en_US: "Open",
        es_ES: "Abierta",
      },
    },
    // For use on importer
    cyclesExtensionFullText: {
      "Cycle 1": {
        en_US: "Cycle 1",
        es_ES: "Ciclo 1",
      },
      "Cycle 2": {
        en_US: "Cycle 2",
        es_ES: "Ciclo 2",
      },
      late: {
        en_US: "Late",
        es_ES: "Tarde",
      },
      open: {
        en_US: "Open",
        es_ES: "Abierta",
      },
    },
    cyclesExtensionShortText: {
      "Cycle 1": {
        en_US: 1,
        es_ES: 1,
      },
      "Cycle 2": {
        en_US: 2,
        es_ES: 2,
      },
    },
    fertilizationStatusImplant: {
      implanted: {
        en_US: "Implanted",
        es_ES: "Implantado",
      },
      skipped: {
        en_US: "Skipped",
        es_ES: "Omitido",
      },
    },
    fertilizationStatusRemoval: {
      removed: {
        en_US: "Removed",
        es_ES: "Removido",
      },
      skipped: {
        en_US: "Skipped",
        es_ES: "Omitido",
      },
    },
    fetalSexes: {
      female: {
        en_US: "Female",
        es_ES: "Femenino",
      },
      male: {
        en_US: "Male",
        es_ES: "Masculino",
      },
      twins: {
        en_US: "Twins",
        es_ES: "Mellizos",
      },
    },
    genders: {
      female: {
        en_US: "Female",
        es_ES: "Femenino",
      },
      male: {
        en_US: "Male",
        es_ES: "Masculino",
      },
    },
    hoofs: {
      0: {
        config: {
          type: "numeric",
        },
        en_US: 0,
        es_ES: 0,
      },
      1: {
        config: {
          type: "numeric",
        },
        en_US: 1,
        es_ES: 1,
      },
      2: {
        config: {
          type: "numeric",
        },
        en_US: 2,
        es_ES: 2,
      },
      3: {
        config: {
          type: "numeric",
        },
        en_US: 3,
        es_ES: 3,
      },
      4: {
        config: {
          type: "numeric",
        },
        en_US: 4,
        es_ES: 4,
      },
      5: {
        config: {
          type: "numeric",
        },
        en_US: 5,
        es_ES: 5,
      },
    },
    milks: {
      0: {
        config: {
          type: "numeric",
        },
        en_US: 0,
        es_ES: 0,
      },
      1: {
        config: {
          type: "numeric",
        },
        en_US: 1,
        es_ES: 1,
      },
      2: {
        config: {
          type: "numeric",
        },
        en_US: 2,
        es_ES: 2,
      },
      3: {
        config: {
          type: "numeric",
        },
        en_US: 3,
        es_ES: 3,
      },
      4: {
        config: {
          type: "numeric",
        },
        en_US: 4,
        es_ES: 4,
      },
      5: {
        config: {
          type: "numeric",
        },
        en_US: 5,
        es_ES: 5,
      },
    },
    pregCheckTests: {
      blueTongue: {
        en_US: "Blue Tongue",
        es_ES: "Lengua azul",
      },
      palpate: {
        en_US: "Palpate",
        es_ES: "Palpar",
      },
      physical: {
        en_US: "Physical",
        es_ES: "Physical",
      },
      reproductiveTract: {
        en_US: "Reproductive Tract",
        es_ES: "Aparato reproductor",
      },
      trich: {
        en_US: "Trich",
        es_ES: "Trich",
      },
      tuberculosis: {
        en_US: "Tuberculosis",
        es_ES: "Tuberculosis",
      },
      ultrasound: {
        en_US: "Ultrasound",
        es_ES: "Ultrasonido",
      },
      vibrio: {
        en_US: "Vibrio",
        es_ES: "Vibrio",
      },
    },
    pregCheckTestMethods: {
      // Case sensitive, ordered for use in English
      Bump: {
        en_US: "Bump",
        es_ES: "Bump",
      },
      Palpate: {
        en_US: "Palpate",
        es_ES: "Palpar",
      },
      Ultrasound: {
        en_US: "Ultrasound",
        es_ES: "Ultrasonido",
      },
    },
    recordStatus: {
      active: {
        en_US: "Active",
        es_ES: "Activo",
      },
      inactive: {
        en_US: "Inactive",
        es_ES: "Inactivo",
      },
    },
    species: {
      bovine: {
        en_US: "Bovine",
        es_ES: "Bovino",
      },
      equine: {
        en_US: "Equine",
        es_ES: "Equino",
      },
      ovine: {
        en_US: "Ovine",
        es_ES: "Ovino",
      },
    },
    statuses: {
      alive: {
        en_US: "Alive",
        es_ES: "Vivo",
      },
      cull: {
        en_US: "Cull",
        es_ES: "Para sacrificar",
      },
      dead: {
        en_US: "Dead",
        es_ES: "Muerto",
      },
      embryo: {
        en_US: "Embryo",
        es_ES: "Embrión",
      },
      harvested: {
        en_US: "Harvested",
        es_ES: "Cosechado",
      },
      inTransit: {
        // VT uses this instead of in_transit
        en_US: "In transit",
        es_ES: "In transit",
      },
      lost: {
        en_US: "Lost",
        es_ES: "Perdido",
      },
      marketable: {
        en_US: "Marketable",
        es_ES: "Comerciable",
      },
      missing: {
        en_US: "Missing",
        es_ES: "Desaparecido",
      },
      onFeed: {
        en_US: "On Feed",
        es_ES: "Comiendo",
      },
      semen: {
        en_US: "Semen",
        es_ES: "Semen",
      },
      sick: {
        en_US: "Sick",
        es_ES: "Enfermo",
      },
      sold: {
        en_US: "Sold",
        es_ES: "Vendido",
      },
      stolen: {
        en_US: "Stolen",
        es_ES: "Robado",
      },
    },
    statusesOffspring: {
      marketable: {
        en_US: "Marketable",
        es_ES: "Comerciable",
      },
      missing: {
        en_US: "Missing",
        es_ES: "Desaparecido",
      },
      dead: {
        en_US: "Dead",
        es_ES: "Muerto",
      },
    },
    tagColors: {
      Black: {
        en_US: "Black",
        es_ES: "negro",
      },
      Blue: {
        en_US: "Blue",
        es_ES: "azul",
      },
      Brown: {
        en_US: "Brown",
        es_ES: "marrón",
      },
      "Dark Blue": {
        en_US: "Dark Blue",
        es_ES: "azul oscuro",
      },
      "Dark Green": {
        en_US: "Dark Green",
        es_ES: "verde oscuro",
      },
      Green: {
        en_US: "Green",
        es_ES: "verde",
      },
      "Lemon Yellow": {
        en_US: "Lemon Yellow",
        es_ES: "amarillo limón",
      },
      Orange: {
        en_US: "Orange",
        es_ES: "anaranjado",
      },
      Pink: {
        en_US: "Pink",
        es_ES: "rosa",
      },
      Purple: {
        en_US: "Purple",
        es_ES: "púrpura",
      },
      Red: {
        en_US: "Red",
        es_ES: "rojo",
      },
      Teal: {
        en_US: "Teal",
        es_ES: "verde azulado",
      },
      Turquoise: {
        en_US: "Turquoise",
        es_ES: "turquesa",
      },
      White: {
        en_US: "White",
        es_ES: "blanco",
      },
      Yellow: {
        en_US: "Yellow",
        es_ES: "amarillo",
      },
    },
    tagTypes: {
      asa: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "ASA",
        es_ES: "ASA",
      },
      association: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Association Number",
        es_ES: "Número de asociación",
      },
      backtag: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Backtag",
        es_ES: "Etiqueta trasera",
      },
      brand: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Brand",
        es_ES: "Marca",
      },
      brisket: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Brisket",
        es_ES: "Falda",
      },
      tagLot: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Lot #",
        es_ES: "# Lot",
      },
      eid: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "EID",
        es_ES: "EID",
      },
      flex: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Flex",
        es_ES: "Flex",
      },
      fly: {
        config: {
          requiresTagValue: false,
          type: "string",
        },
        en_US: "Fly",
        es_ES: "Mosca",
      },
      nues: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "NUES",
        es_ES: "NUES",
      },
      tattoo: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Tattoo",
        es_ES: "Tatuaje",
      },
      tsu: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "TSU",
        es_ES: "TSU",
      },
      visual: {
        config: {
          requiresTagValue: true,
          type: "string",
        },
        en_US: "Visual",
        es_ES: "Visual",
      },
    },
    testResultsBull: {
      negative: {
        en_US: "Negative",
        es_ES: "Negativo",
      },
      open: {
        en_US: "Open",
        es_ES: "Abierta",
      },
      pending: {
        en_US: "Pending",
        es_ES: "Pendiente",
      },
      positive: {
        en_US: "Positive",
        es_ES: "Positivo",
      },
      problem: {
        en_US: "Problem--see comment",
        es_ES: "Problema--ver comentario",
      },
    },
    pregResults: {
      Open: {
        en_US: "Open",
        es_ES: "Open",
      },
      pregnant: {
        en_US: "Pregnant",
        es_ES: "Embarazada",
      },
    },
    testResultsCow: {
      bred: {
        en_US: "Bred",
        es_ES: "Bred",
      },
      negative: {
        en_US: "Negative",
        es_ES: "Negative",
      },
      open: {
        en_US: "Open",
        es_ES: "Abierta",
      },
      pending: {
        en_US: "Pending",
        es_ES: "Pendiente",
      },
      pregnant: {
        en_US: "Pregnant",
        es_ES: "Embarazada",
      },
      problem: {
        en_US: "Problem--see comment",
        es_ES: "Problema--ver comentario",
      },
    },
    trueIsYes: {
      0: {
        config: {
          type: "boolean",
        },
        en_US: "No",
        es_ES: "No",
      },
      1: {
        config: {
          type: "boolean",
        },
        en_US: "Yes",
        es_ES: "Sí",
      },
      true: {
        en_US: "Yes",
        es_ES: "Sí",
      },
      false: {
        en_US: "No",
        es_ES: "No",
      },
    },
    vigors: {
      dead_on_arrival: {
        config: {
          type: "string",
        },
        en_US: "5-Dead on arrival",
        es_ES: "5-Muerto a la llegada",
      },
      died_shortly_after_birth: {
        config: {
          type: "string",
        },
        en_US: "4-Died shortly after birth",
        es_ES: "4-Murió poco después del nacimiento",
      },
      had_to_suckle: {
        config: {
          type: "string",
        },
        en_US: "3-Had to suckle",
        es_ES: "3-Tuvo que mamar",
      },
      nursed_but_took_time: {
        config: {
          type: "string",
        },
        en_US: "2-Nursed but took time",
        es_ES: "2-Amamantó pero tomó tiempo",
      },
      nursed_immediately: {
        config: {
          type: "string",
        },
        en_US: "1-Nursed immediately",
        es_ES: "1-Amamantó inmediatamente",
      },
    },
  },
  epd: {
    en_US: "EPD",
    es_ES: "EPD",
  },
  equine: {
    en_US: "Equine",
    es_ES: "Equino",
  },
  estimateCalvingDate: {
    en_US: "Estimated Calving Date",
    es_ES: "Fecha estimada de parto",
  },
  eula: {
    en_US: "EULA",
    es_ES: "CLUF",
  },
  eventOrganization: {
    en_US: "Event Organization",
    es_ES: "Organización del evento",
  },
  exportToCSV: {
    en_US: "Export to CSV",
    es_ES: "Exportar a CSV",
  },
  expiration: {
    en_US: "Expiration",
    es_ES: "Expiration",
  },
  expirationDate: {
    en_US: "Expiration Date",
    es_ES: "Fecha de caducidad",
  },
  extraInfo: {
    en_US: "Extra Info",
    es_ES: "Extra Info",
  },
  feedtrucks: {
    friendlyName: {
      en_US: "Friendly Name(s)",
      es_ES: "Alias(es)",
    },
    name: {
      en_US: "Feed Truck",
      es_ES: "Feed Truck",
    },
  },
  fertilizationStatusImplant: {
    implanted: {
      en_US: "Implanted",
      es_ES: "Implantado",
    },
    skipped: {
      en_US: "Skipped",
      es_ES: "Omitido",
    },
  },
  fertilizationStatusRemoval: {
    removed: {
      en_US: "Removed",
      es_ES: "Removido",
    },
    skipped: {
      en_US: "Skipped",
      es_ES: "Omitido",
    },
  },
  "Fetal Sex": {
    en_US: "Fetal Sex",
    es_ES: "Sexo Fetal",
  },
  "Last Weight": {
    en_US: "Last Weight",
    es_ES: "Último peso",
  },
  fields: {
    _id: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "Unique Identifier",
      es_ES: "Identificador único",
    },
    actualCalvingDate: {
      config: {
        type: "datetime",
      },
      en_US: "Actual Calving Date",
      es_ES: "Fecha real de parto",
    },
    actualDose: {
      config: {
        type: "numeric",
      },
      en_US: "Dose Administered",
      es_ES: "Dosis Administrada",
    },
    actualPicture: {
      config: {
        type: "img",
      },
      en_US: "Picture",
      es_ES: "Foto",
    },
    actualPictureId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Picture ID",
      es_ES: "ID Foto",
    },
    actualWithdrawlDate: {
      config: {
        type: "datetime",
      },
      en_US: "Actual Withdrawl Date",
      es_ES: "Fecha real de retiro",
    },
    adg: {
      config: {
        type: "numeric",
      },
      en_US: "ADG",
      es_ES: "ADG",
    },
    adg2: {
      config: {
        type: "numeric",
      },
      en_US: "ADG (Last Two Weights)",
      es_ES: "ADG (Last Two Weights)",
    },
    adgTotal: {
      config: {
        type: "numeric",
      },
      en_US: "Total ADG",
      es_ES: "Total ADG",
    },
    adoptDam: {
      config: {
        type: "string",
      },
      en_US: "Adopted Dam",
      es_ES: "Adopted Dam",
    },
    adoptDamId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Adopted Dam ID",
      es_ES: "Adopted Dam ID",
    },
    adoptDamTags: {
      config: {
        type: "string",
      },
      en_US: "Adopted Dam Tags",
      es_ES: "Adopted Dam Tags",
    },
    age: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Age",
      es_ES: "Edad",
    },
    aiSire: {
      config: {
        type: "string",
      },
      en_US: "AI Sire",
      es_ES: "AI Sire",
    },
    aiTechnician: {
      en_US: "Technician",
      es_ES: "Técnico",
    },
    alerts: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Alerts",
      es_ES: "Avisos",
    },
    animalCreatedOn: {
      config: {
        hide: false,
        type: "datetime",
      },
      en_US: "Animal Created On",
      es_ES: "Animal Created On",
    },
    animalId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Animal ID",
      es_ES: "Identificación de animales",
    },
    animalVaccinationPictureID: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Medication Picture ID",
      es_ES: "Identificación con foto del medicamento",
    },
    apparentTime: {
      config: {
        type: "datetime",
      },
      en_US: "Apparent Time",
      es_ES: "Tiempo aparente",
    },
    backtagColors: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Backtag Colors",
      es_ES: "Colores de etiquetas traseras",
    },
    backtagValues: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Backtags",
      es_ES: "Etiquetas traseras",
    },
    bcs: {
      config: {
        type: "string",
      },
      en_US: "BCS",
      es_ES: "BCS",
    },
    birthCalvingEase: {
      config: {
        type: "string",
      },
      en_US: "Calving Score",
      es_ES: "Puntaje de parto al nacimiento",
    },
    birthDate: {
      config: {
        type: "datetime",
      },
      en_US: "Birth Date",
      es_ES: "Fecha de nacimiento",
    },
    birthVisual: {
      config: {
        type: "string",
      },
      en_US: "Birth Visual",
      es_ES: "Número de Etiqueta Visual de Nacimiento",
    },
    birthWeight: {
      config: {
        type: "numeric",
      },
      en_US: "Birth Weight",
      es_ES: "Peso de nacimiento",
    },
    breed: {
      config: {
        type: "string",
      },
      en_US: "Breed",
      es_ES: "Breed",
    },
    breedDate: {
      config: {
        type: "datetime",
      },
      en_US: "Breed Date",
      es_ES: "Breed Date",
    },
    bullTurninDate: {
      en_US: "Bull Turn-in Date",
      es_ES: "Fecha de entrega del toro",
    },
    characteristicApparentTime: {
      config: {
        type: "datetime",
      },
      en_US: "Last Updated",
      es_ES: "Última actualización",
    },
    checkedBy: {
      config: {
        type: "string",
      },
      en_US: "Checked by",
      es_ES: "Revisado por",
    },
    clearanceDate: {
      config: {
        type: "datetime",
      },
      en_US: "Clearance Date",
      es_ES: "Fecha de liquidación",
    },
    color: {
      config: {
        type: "string",
      },
      en_US: "Color",
      es_ES: "Color",
    },
    comment: {
      config: {
        type: "numeric",
      },
      en_US: "Comment",
      es_ES: "Comentario",
    },
    commentDate: {
      config: {
        type: "datetime",
      },
      en_US: "Comment Date",
      es_ES: "Comentario Fecha",
    },
    commentId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Comment ID",
      es_ES: "ID de comentario",
    },
    commentValue: {
      config: {
        format: "as-id",
        type: "string",
      },
      en_US: "Comment",
      es_ES: "Comentario",
    },
    commissionType: {
      config: {
        type: "string",
      },
      en_US: "Commission Type",
      es_ES: "Tipo de comisión",
    },
    cost: {
      config: {
        type: "numeric",
      },
      en_US: "Cost",
      es_ES: "Costo",
    },
    coverSire: {
      config: {
        type: "string",
      },
      en_US: "Cover Sire",
      es_ES: "Padre de portada",
    },
    coverSireId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Cover Sire ID",
      es_ES: "ID de padre de portada",
    },
    coverSireTags: {
      config: {
        type: "string",
      },
      en_US: "Cover Sire Tags",
      es_ES: "Etiquetas de padre de portada",
    },
    createdBy: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "Created By",
      es_ES: "Creado por",
    },
    createdByUserName: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Created By",
      es_ES: "Creado por",
    },
    createdOn: {
      config: {
        hide: true,
        type: "datetime",
      },
      en_US: "Created On",
      es_ES: "Creado en",
    },
    currentWeight: {
      config: {
        type: "numeric",
      },
      en_US: "Current Weight",
      es_ES: "Peso actual",
    },
    currentWeightDate: {
      config: {
        type: "datetime",
      },
      en_US: "Current Weight Date",
      es_ES: "Peso actual Fecha",
    },
    cycle: {
      config: {
        type: "string",
      },
      en_US: "Cycle",
      es_ES: "Ciclo",
    },
    dam: {
      config: {
        type: "string",
      },
      en_US: "Dam",
      es_ES: "Dam",
    },
    damId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Dam ID",
      es_ES: "Dam ID",
    },
    damTags: {
      config: {
        type: "string",
      },
      en_US: "Dam Tags",
      es_ES: "Dam Tags",
    },
    date: {
      config: {
        type: "datetime",
      },
      en_US: "Date",
      es_ES: "Fecha",
    },
    daysBetweenFirstAndLastWeight: {
      config: {
        type: "numeric",
      },
      en_US: "Days between first and current weight",
      es_ES: "Días entre el primer peso y el actual",
    },
    daysBred: {
      config: {
        type: "numeric",
      },
      en_US: "Days Bred",
      es_ES: "Días criado",
    },
    daysSinceLastMove: {
      config: {
        type: "numeric",
      },
      en_US: "Days Since Last Move",
      es_ES: "Días desde el último movimiento",
    },
    daysSinceLastPregCheck: {
      config: {
        type: "numeric",
      },
      en_US: "Days Since Preg Check",
      es_ES: "Control de días desde el embarazo",
    },
    daysSinceLastWeight: {
      config: {
        type: "numeric",
      },
      en_US: "Days since last weight",
      es_ES: "Días desde el último peso",
    },
    daysUntilNewHeadLocation: {
      config: {
        type: "numeric",
      },
      en_US: "Days Until Next Head Location",
      es_ES: "Días hasta la próxima ubicación de la cabeza",
    },
    deathDate: {
      config: {
        type: "datetime",
      },
      en_US: "Death Date",
      es_ES: "Fecha de la muerte",
    },
    deleteAnimal: {
      config: {
        hide: true,
        type: "datetime",
      },
      en_US: "Delete Animal",
      es_ES: "Borrar animal",
    },
    deleted: {
      config: {
        hide: true,
        type: "datetime",
      },
      en_US: "Deleted",
      es_ES: "Borrado",
    },
    description: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Description",
      es_ES: "Descripción",
    },
    dnaNumber: {
      config: {
        type: "string",
      },
      en_US: "TSU/DNA #",
      es_ES: "TSU/DNA #",
    },
    doctoringCost: {
      config: {
        type: "numeric",
      },
      en_US: "Doctoring Cost",
      es_ES: "Costo de Doctorado",
    },
    dose: {
      config: {
        type: "numeric",
      },
      en_US: "Dose Administered",
      es_ES: "Dosis Administrada",
    },
    doseUnits: {
      config: {
        type: "numeric",
      },
      en_US: "Dose Units",
      es_ES: "Unidades de dosis",
    },
    EIDcolors: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "EID Colors",
      es_ES: "Colores EID",
    },
    EIDtagValue: {
      config: {
        type: "string",
      },
      en_US: "EID Tag ID",
      es_ES: "ID de etiqueta EID",
    },
    EIDtagValues: {
      config: {
        type: "string",
      },
      en_US: "EID Tag IDs",
      es_ES: "Identificadores de etiquetas EID",
    },
    eventOrganization: {
      config: {
        type: "string",
      },
      en_US: "Event Organization",
      es_ES: "Event Organization",
    },
    expirationDate: {
      config: {
        type: "datetime",
      },
      en_US: "Expiration Date",
      es_ES: "Fecha de caducidad",
    },
    fetalSex: {
      config: {
        type: "datetime",
      },
      en_US: "Fetal Sex",
      es_ES: "Sexo Fetal",
    },
    finalHeadDate: {
      config: {
        type: "datetime",
      },
      en_US: "Final Head Date",
      es_ES: "Fecha principal final",
    },
    firstAIServiceDate: {
      config: {
        hide: false,
        type: "datetime",
      },
      en_US: "1st AI Service Date",
      es_ES: "1st AI Service Date",
    },
    firstWeight: {
      config: {
        type: "numeric",
      },
      en_US: "First Weight",
      es_ES: "Primer Peso",
    },
    firstWeightDate: {
      config: {
        type: "datetime",
      },
      en_US: "First Weight Date",
      es_ES: "Fecha de Primer Peso",
    },
    freightage: {
      config: {
        type: "currency",
      },
      en_US: "Freightage",
      es_ES: "Freightage",
    },
    freightCost: {
      config: {
        type: "currency",
      },
      en_US: "Freight Cost",
      es_ES: "Costo del flete",
    },
    freightRage: {
      config: {
        type: "currency",
      },
      en_US: "Freight Rage",
      es_ES: "Furia de carga",
    },
    friendlyName: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Friendly Name",
      es_ES: "Nombre amigable",
    },
    gender: {
      config: {
        type: "string",
      },
      en_US: "Gender",
      es_ES: "Género",
    },
    GPSSampleTime: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "GPS Sample Time",
      es_ES: "Tiempo de muestra de GPS",
    },
    group: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Group",
      es_ES: "Grupo",
    },
    groupNumber: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Group Number",
      es_ES: "Número de grupo",
    },
    guid: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Unique ID",
      es_ES: "Identificación única",
    },
    headDays: {
      config: {
        type: "numeric",
      },
      en_US: "Head Days",
      es_ES: "Días de cabeza",
    },
    herd: {
      config: {
        type: "string",
      },
      en_US: "Herd",
      es_ES: "Manada",
    },
    herdId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Herd ID",
      es_ES: "ID de manada",
    },
    herdName: {
      config: {
        type: "string",
      },
      en_US: "Herd Name",
      es_ES: "Nombre de manada",
    },
    herds: {
      config: {
        type: "string",
      },
      en_US: "Herds",
      es_ES: "Manadas",
    },
    hoof: {
      config: {
        hide: false,
        type: "numeric",
      },
      en_US: "Dam Hoof",
      es_ES: "Pezuña de madre",
    },
    id: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "ID",
      es_ES: "ID",
    },
    image: {
      config: {
        type: "image",
      },
      en_US: "Image",
      es_ES: "Imagen",
    },
    imageURL: {
      config: {
        type: "URL",
      },
      en_US: "Image",
      es_ES: "Imagen",
    },
    income: {
      config: {
        type: "numeric",
      },
      en_US: "Income",
      es_ES: "Ingreso",
    },
    lastUpdated: {
      config: {
        hide: true,
        type: "datetime",
      },
      en_US: "Last Updated",
      es_ES: "Última actualización",
    },
    lastUpdatedBy: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "Updated By",
      es_ES: "Actualizado por",
    },
    lastUpdatedByUserName: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Updated By",
      es_ES: "Actualizado por",
    },
    lastWeightOnDate: {
      config: {
        type: "numeric",
      },
      en_US: "Last Weight On Date",
      es_ES: "Fecha del último peso",
    },
    location: {
      config: {
        type: "string",
      },
      en_US: "Location",
      es_ES: "Ubicación",
    },
    locationId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Location ID",
      es_ES: "ID de ubicación",
    },
    lotNumber: {
      config: {
        type: "string",
      },
      en_US: "Medication Lot Number",
      es_ES: "Lote número de medication",
    },
    manufacturer: {
      config: {
        type: "string",
      },
      en_US: "Manufacturer",
      es_ES: "Fabricante",
    },
    method: {
      config: {
        type: "string",
      },
      en_US: "Method",
      es_ES: "Método",
    },
    milk: {
      config: {
        hide: false,
        type: "numeric",
      },
      en_US: "Dam Milk",
      es_ES: "Dam Milk",
    },
    mimeType: {
      config: {
        type: "string",
      },
      en_US: "Mime Type",
      es_ES: "Tipo de Mimica",
    },
    mouthScore: {
      en_US: "Mouth Score",
      es_ES: "Puntuacion Bucal",
    },
    mType: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "MIME Type",
      es_ES: "Tipo de Mimica",
    },
    name: {
      config: {
        type: "string",
      },
      en_US: "Name",
      es_ES: "Nombre",
    },
    nuesTagValues: {
      config: {
        requiresTagValue: true,
        type: "string",
      },
      en_US: "NUES Tags",
      es_ES: "Etiquetas",
    },
    offspringTags: {
      config: {
        type: "string",
      },
      en_US: "Offspring Tags",
      es_ES: "Etiquetas de descendencia",
    },
    origin: {
      config: {
        type: "string",
      },
      en_US: "Origin",
      es_ES: "Origen",
    },

    parentId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Parent ID",
      es_ES: "Identificación de los padres",
    },
    percentCross: {
      config: {
        type: "string",
      },
      en_US: "Percent Cross",
      es_ES: "Cruz de porcentaje",
    },
    picture: {
      config: {
        type: "string",
      },
      en_US: "Picture",
      es_ES: "Foto",
    },
    pictureId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Picture ID",
      es_ES: "Identificación con foto",
    },
    predictedCalvingDate: {
      config: {
        type: "datetime",
      },
      en_US: "Estimated Calving Date",
      es_ES: "Fecha estimada de parto",
    },
    pregCheckedBy: {
      config: {
        type: "string",
      },
      en_US: "Vet / Tech",
      es_ES: "Veterinario / Técnico",
    },
    pregCheckTime: {
      config: {
        type: "datetime",
      },
      en_US: "Preg Check Date",
      es_ES: "Fecha de verificación de embarazo",
    },
    previousLocation: {
      config: {
        type: "string",
      },
      en_US: "Previous Pasture/Pen",
      es_ES: "Pasto/corral Previo",
    },
    price: {
      config: {
        type: "currency",
      },
      en_US: "Price",
      es_ES: "Precio",
    },
    priceUnits: {
      config: {
        type: "string",
      },
      en_US: "Price Units",
      es_ES: "Precio unidades",
    },
    primaryTag: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Is Primary Tag",
      es_ES: "Es etiqueta primaria",
    },
    processingCost: {
      config: {
        type: "numeric",
      },
      en_US: "Processing Cost",
      es_ES: "Costo de procesamiento",
    },
    processingDate: {
      config: {
        type: "datetime",
      },
      en_US: "Processing Date",
      es_ES: "Fecha de procesamiento",
    },
    purchaseCommissionRate: {
      config: {
        type: "numeric",
      },
      en_US: "Purchase Commission Rate",
      es_ES: "Tasa de comisión de compra",
    },
    purchaseDate: {
      config: {
        type: "datetime",
      },
      en_US: "Purchase Date",
      es_ES: "Fecha de compra",
    },
    purchaseDates: {
      config: {
        type: "string",
      },
      en_US: "Purchase Date(s)",
      es_ES: "Fecha de compra(s)",
    },
    purchasePrice: {
      config: {
        type: "numeric",
      },
      en_US: "Purchase Price",
      es_ES: "Precio de compra",
    },
    purchaseWeight: {
      config: {
        type: "numeric",
      },
      en_US: "Purchase Weight",
      es_ES: "Peso de compra",
    },
    reason: {
      config: {
        type: "string",
      },
      en_US: "Reason",
      es_ES: "Razón",
    },
    receivingRanch: {
      config: {
        type: "string",
      },
      en_US: "Receiving Ranch",
      es_ES: "Rancho de recepción",
    },
    rectalTemperature: {
      config: {
        hide: false,
        type: "numeric",
      },
      en_US: "Rectal Temperature",
      es_ES: "Temperatura rectal",
    },
    relationship: {
      config: {
        type: "string",
      },
      en_US: "Relationship",
      es_ES: "Relación",
    },
    requiredDose: {
      config: {
        type: "numeric",
      },
      en_US: "Required Dose",
      es_ES: "Dosis Requerida",
    },
    result: {
      config: {
        type: "string",
      },
      en_US: "Result",
      es_ES: "Resultado",
    },
    saleDate: {
      config: {
        type: "datetime",
      },
      en_US: "Sale Date",
      es_ES: "Fecha de venta",
    },
    saleDates: {
      config: {
        type: "string",
      },
      en_US: "Sale Date(s)",
      es_ES: "Fecha(s) de venta",
    },
    salesCommission: {
      config: {
        type: "numeric",
      },
      en_US: "Sales Commission",
      es_ES: "Comisión de ventas",
    },
    salesCommissionRate: {
      config: {
        type: "numeric",
      },
      en_US: "Sales Commission Rate",
      es_ES: "Tasa de comisión de ventas",
    },
    sanityCheck: {
      config: {
        hide: true,
        type: "boolean",
      },
      en_ES: "Sanity Check",
      en_US: "Prueba de cordura",
    },
    scale: {
      config: {
        type: "string",
      },
      en_US: "Scale",
      es_ES: "Scale",
    },
    secondAIServiceDate: {
      config: {
        hide: false,
        type: "datetime",
      },
      en_US: "2nd AI Service Date",
      es_ES: "2nd AI Service Date",
    },
    serviceDateIn: {
      config: {
        hide: false,
        type: "datetime",
      },
      en_US: "Service Date In",
      es_ES: "Service Date In",
    },
    serviceDateOut: {
      config: {
        hide: false,
        type: "datetime",
      },
      en_US: "Service Date Out",
      es_ES: "Service Date Out",
    },
    sex: {
      config: {
        type: "string",
      },
      en_US: "Sex",
      es_ES: "Sexo",
    },
    sire: {
      config: {
        type: "string",
      },
      en_US: "Sire",
      es_ES: "Semental",
    },
    sireId: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "Sire ID",
      es_ES: "ID del semental",
    },
    sireTags: {
      config: {
        type: "string",
      },
      en_US: "Sire Tags",
      es_ES: "Etiquetas del semental",
    },
    size: {
      config: {
        type: "string",
      },
      en_US: "Size",
      es_ES: "Tamaño",
    },
    source: {
      config: {
        type: "string",
      },
      en_US: "Source",
      es_ES: "Fuente",
    },
    species: {
      config: {
        type: "string",
      },
      en_US: "Species",
      es_ES: "Especies",
    },
    status: {
      config: {
        type: "string",
      },
      en_US: "Status",
      es_ES: "Estado",
    },
    statusTimeRecorded: {
      config: {
        type: "datetime",
      },
      en_US: "Status Time Recorded",
      es_ES: "Estado tiempo registrado",
    },
    tableSource: {
      config: {
        hide: true,
        type: "string",
      },
      en_ES: "Table Source",
      en_US: "Fuente de la tabla",
    },
    tagColor: {
      config: {
        type: "string",
      },
      en_US: "Tag Color",
      es_ES: "Color de la etiqueta",
    },
    tagId: {
      config: {
        hide: false,
        type: "guid",
      },
      en_US: "Tag ID",
      es_ES: "ID de etiqueta",
    },
    tagLot: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Lot #",
      es_ES: "# Lote",
    },
    tagTable: {
      config: {
        hide: false,
        type: "guid",
      },
      en_US: "Tag Table",
      es_ES: "Tabla de etiquetas",
    },
    tagValue: {
      config: {
        type: "string",
      },
      en_US: "Tag Value",
      es_ES: "Valor de etiqueta",
    },
    tagValueDouble: {
      config: {
        hide: true,
        type: "numeric",
      },
      en_US: "Value Tag Double",
      es_ES: "Etiqueta de valor doble",
    },
    tagValues: {
      config: {
        type: "string",
      },
      en_US: "Tag IDs",
      es_ES: "Tag IDs",
    },
    technician: {
      config: {
        type: "string",
      },
      en_US: "Technician",
      es_ES: "Técnico",
    },
    test: {
      config: {
        type: "string",
      },
      en_US: "Test",
      es_ES: "Prueba",
    },
    testCheckTime: {
      config: {
        type: "datetime",
      },
      en_US: "Date",
      es_ES: "Fecha",
    },
    testedBy: {
      config: {
        type: "string",
      },
      en_US: "Tested By",
      es_ES: "Probado por",
    },
    testMethod: {
      config: {
        type: "string",
      },
      en_US: "Test Method",
      es_ES: "Método de prueba",
    },
    testType: {
      config: {
        type: "string",
      },
      en_US: "Test",
      es_ES: "Prueba",
    },
    timeInField: {
      config: {
        type: "numeric",
      },
      en_US: "Time In Field",
      es_ES: "Tiempo en el campo",
    },
    timeRecorded: {
      config: {
        type: "datetime",
      },
      en_US: "Effective Date",
      es_ES: "Fecha efectiva",
    },
    totalEventIncome: {
      config: {
        type: "numeric",
      },
      en_US: "Total Event Income",
      es_ES: "Ingresos totales del evento",
    },
    totalGain: {
      config: {
        type: "numeric",
      },
      en_US: "Total Gain",
      es_ES: "Ganancia Total",
    },
    transactionDate: {
      config: {
        type: "datetime",
      },
      en_US: "Transaction Date",
      es_ES: "Fecha de Transacción",
    },
    transactionType: {
      config: {
        type: "string",
      },
      en_US: "Transaction Type",
      es_ES: "Tipo de transacción",
    },
    type: {
      config: {
        type: "string",
      },
      en_US: "Type",
      es_ES: "Tipo",
    },
    uniqueAnimalId: {
      config: {
        hide: true,
        type: "guid",
      },
      en_US: "Unique Animal ID",
      es_ES: "Identificación única de animales",
    },
    units: {
      config: {
        type: "numeric",
      },
      en_US: "Units",
      es_ES: "Unidades",
    },
    URL: {
      config: {
        type: "URL",
      },
      en_US: "Image",
      es_ES: "Imagen",
    },
    userId: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "User ID",
      es_ES: "ID de usuario",
    },
    username: {
      config: {
        type: "string",
      },
      en_US: "User Name",
      es_ES: "Nombre de usuario",
    },
    vaccinationName: {
      config: {
        type: "string",
      },
      en_US: "Vaccination Name",
      es_ES: "Nombre de la vacuna",
    },
    vaccinationsId: {
      config: {
        hide: true,
        type: "string",
      },
      en_US: "Treatment ID",
      es_ES: "ID de tratamiento",
    },
    vaccinationTime: {
      config: {
        type: "datetime",
      },
      en_US: "Treatment Time",
      es_ES: "Tiempo de tratamiento",
    },
    vigor: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Calf Vigor",
      es_ES: "Vigor de becerro",
    },
    visualTagColors: {
      config: {
        hide: false,
        type: "string",
      },
      en_US: "Visual Colors",
      es_ES: "Colores visuales",
    },
    visualTagValue: {
      config: {
        type: "string",
      },
      en_US: "Visual ID",
      es_ES: "Visual ID",
    },
    visualTagValues: {
      config: {
        type: "string",
      },
      en_US: "Visual IDs",
      es_ES: "Visual IDs",
    },
    weatherId: {
      config: {
        hide: false,
        type: "guid",
      },
      en_US: "Weather ID",
      es_ES: "Weather ID",
    },
    weight: {
      config: {
        type: "numeric",
      },
      en_US: "Weight",
      es_ES: "Peso",
    },
    weightUnits: {
      config: {
        type: "string",
      },
      en_US: "Weight Units",
      es_ES: "Unidades de peso",
    },
    withdrawlTime: {
      config: {
        type: "datetime",
      },
      en_US: "Withdrawl Time",
      es_ES: "Tiempo de retiro",
    },
  },
  fileETL: {
    // convenience because these are mixed with "Any" in fileETL
    lt: {
      en_US: "<",
      es_ES: ">",
    },
    gt: {
      en_US: ">",
      es_ES: ">",
    },
    _1_year: {
      en_US: "1 year",
      es_ES: "1 año",
    },
    _2_years: {
      en_US: "2 years",
      es_ES: "2 años",
    },
    _3_years: {
      en_US: "3 years",
      es_ES: "3 años",
    },
    _4_years: {
      en_US: "4 years",
      es_ES: "4 años",
    },
    _5_years: {
      en_US: "5 years",
      es_ES: "5 años",
    },
    _10_years: {
      en_US: "10 years",
      es_ES: "10 años",
    },
    _15_years: {
      en_US: "15 years",
      es_ES: "15 años",
    },
    _20_years: {
      en_US: "20 years",
      es_ES: "20 años",
    },
    Any: {
      en_US: "Any",
      es_ES: "Any",
    },
    addColumn: {
      en_US: "Add Column",
      es_ES: "Añadir columna",
    },
    addRow: {
      en_US: "Add Row",
      es_ES: "Añadir fila",
    },
    allAreNew: {
      en_US: "All Are New",
      es_ES: "Todos son nuevos",
    },
    allAreOld: {
      en_US: "All Are Old",
      es_ES: "Todos son viejos",
    },
    allRowsAreSetToBeIgnored: {
      en_US: "All rows are set to be ignored.",
      es_ES: "All rows are set to be ignored.",
    },
    anImportIsInProgressBut: {
      en_US:
        "An import is in progress, but here is a summary of current results.",
      es_ES:
        "An import is in progress, but here is a summary of current results.",
    },
    approximateLineCount: {
      en_US: "Rows",
      es_ES: "Filas",
    },
    archerFileIsLoaded: {
      en_US: "Archer file is now loaded",
      es_ES: "Archer file is now loaded",
    },
    attributes: {
      en_US: "Attributes",
      es_ES: "Atributos",
    },
    cannotBeImportedBtn: {
      en_US: "Cannot Be Imported",
      es_ES: "No se puede importar",
    },
    chooseFile: {
      en_US: "Choose a file using the filepicker to the left.",
      es_ES: "Choose a file using the filepicker to the left.",
    },
    colorReversed: {
      en_US:
        "Colors above are in reverse order of precedence. Colors that are listed last will display before ones that are listed first. For example, a row that successfully imported, but has an ambiguous tag value will display with a color of orange instead of green.",
      es_ES:
        "Colors above are in reverse order of precedence. Colors that are listed last will display before ones that are listed first. For example, a row that successfully imported, but has an ambiguous tag value will display with a color of orange instead of green.",
    },
    columnContainsDuplicatedIDs: {
      en_US: "Column contains duplicated IDs",
      es_ES: "Column contains duplicated IDs",
    },
    contactForm: {
      en_US: "contact form",
      es_ES: "contact form",
    },
    defaultingToGuesses: {
      en_US: "Defaulting to guesses.",
      es_ES: "Defaulting to guesses.",
    },
    deleteColumn: {
      en_US: "Delete Column",
      es_ES: "Borrar columna",
    },
    deleteRow: {
      en_US: "Delete Row",
      es_ES: "Borrar fila",
    },
    deselectAll: {
      en_US: "Deselect All",
      es_ES: "Deseleccionar todo",
    },
    download: {
      en_US: "Download",
      es_ES: "Descargar",
    },
    dropFile: {
      en_US: "Drop a file here or choose",
      es_ES: "Drop a file here or choose",
    },
    dryRun: {
      en_US: "Dry Run",
      es_ES: "Ejecución en seco",
    },
    dryRunIsRequired: {
      en_US: "Dry run is required.",
      es_ES: "Se reqiuere la ejecución en seco.",
    },
    dryRunResultsAreBeingUpdated: {
      en_US: "Dry run results are being updated.",
      es_ES: "Dry run results are being updated.",
    },
    Duplicate: {
      en_US: "Duplicate",
      es_ES: "Duplicado",
    },
    Duplicates: {
      en_US: "Duplicates",
      es_ES: "Duplicados",
    },
    duplicateValueWasDetected: {
      en_US: "A duplicate value was detected.",
      es_ES: "A duplicate value was detected.",
    },
    edit: {
      en_US: "Edit",
      es_ES: "Editar",
    },
    eidDataDetected: {
      en_US: "EID data detected.",
      es_ES: "EID data detected.",
    },
    enableAll: {
      en_US: "Enable All",
      es_ES: "Habilitar todo",
    },
    encounterIssue: {
      en_US: "If you encounter an issue with our import too, please use our",
      es_ES: "If you encounter an issue with our import too, please use our",
    },
    Error: {
      en_US: "Error",
      es_ES: "Error",
    },
    exportToCSV: {
      en_US: "Export to CSV",
      es_ES: "Exportar a CSV",
    },
    failedToPersist: {
      en_US: "Was not able to store results to database.",
      es_ES: "Was not able to store results to database.",
    },
    feedDataDetected: {
      en_US: "Feed data detected.",
      es_ES: "Feed data detected.",
    },
    feedDataMustBeSortedNaturally: {
      en_US: "Feed data must be sorted by row number.",
      es_ES: "Feed data must be sorted by row number.",
    },
    fileName: {
      en_US: "File Name",
      es_ES: "Nombre de archivo",
    },
    filesFromArcher: {
      en_US: "Files from archer",
      es_ES: "Files from archer",
    },
    filesFromArcherBtn: {
      en_US: "Files from archer",
      es_ES: "Files from archer",
    },
    flyQuantityIsInvalid: {
      en_US:
        "A fly quantity is invalid. Value must be a positive whole number.",
      es_ES:
        "A fly quantity is invalid. Value must be a positive whole number.",
    },
    forceNew: {
      en_US: "Force New",
      es_ES: "Force New",
    },
    forValues: {
      en_US: "For Values",
      es_ES: "For Values",
    },
    genericDateColumnIsRequiredWhenBirthDateIsPresent: {
      en_US: "A generic Date column is required when birthdate is present.",
      es_ES: "A generic Date column is required when birthdate is present.",
    },
    headerRow: {
      en_US: "Header Row",
      es_ES: "Fila de encabezado",
    },
    headerRowNo: {
      en_US: "No Header Row",
      es_ES: "Sin fila de encabezado",
    },
    idValueIsActiveForMultipleAnimals: {
      en_US:
        "One of the animal IDs is used for multiple animals in the database. It is likely the visual, please verify before proceeding.",
      es_ES:
        "One of the animal IDs is used for multiple animals in the database. It is likely the visual, please verify before proceeding.",
    },
    ignore: {
      en_US: "Ignore",
      es_ES: "Ignorar",
    },
    ignoreAll: {
      en_US: "Ignore All",
      es_ES: "Ignorar todo",
    },
    ignoreImport: {
      en_US: "Ignored (will not be imported)",
      es_ES: "Ignorado (no se importará)",
    },
    import: {
      en_US: "Import",
      es_ES: "Importar",
    },
    importCompletedSuccessfully: {
      en_US: "Import completed successfully.",
      es_ES: "Se cumplió el importaje con éxito",
    },
    importCompletedWithoutError: {
      en_US:
        "Import completed without error. See summary for further information.",
      es_ES:
        "Import completed without error. See summary for further information.",
    },
    importInfo: {
      en_US: "Non-error, row-specific information is available.",
      es_ES: "Non-error, row-specific information is available.",
    },
    importResults: {
      en_US: "For Import Results",
      es_ES: "For Import Results",
    },
    importSuccess: {
      en_US: "Successfully imported",
      es_ES: "Importado con exito",
    },
    importSummary: {
      en_US: "Import Summary",
      es_ES: "Resumen de importación",
    },
    Info: {
      en_US: "Info",
      es_ES: "Info",
    },
    invalidValueDetected: {
      en_US: "Invalid value was detected.",
      es_ES: "valor no válido se detectó",
    },
    labels: {
      en_US: "Labels",
      es_ES: "Etiquetas",
    },
    lastUpdated: {
      en_US: "Last Updated",
      es_ES: "Última actualización",
    },
    legend: {
      en_US: "Legend",
      es_ES: "Leyenda",
    },
    memorizeTemplate: {
      en_US: "Memorize Template",
      es_ES: "Memorizar plantilla",
    },
    messages: {
      en_US: "Messages",
      es_ES: "Mensajes",
    },
    minimum1IDisRequired: {
      en_US: "At least 1 valid ID is required.",
      es_ES: "At least 1 valid ID is required.",
    },
    missingAColumn: {
      en_US: "You are missing a column",
      es_ES: "Falta una columna",
    },
    moreThan1TypeAndSubType: {
      en_US: "You have more than 1 columns assigned to be type > sub-type of",
      es_ES: "You have more than 1 columns assigned to be type > sub-type of",
    },
    multipleAnimals: {
      en_US:
        "More than 1 animal matches the provided identifiers. Please verify that the import data is correct before proceeding.",
      es_ES:
        "More than 1 animal matches the provided identifiers. Please verify that the import data is correct before proceeding.",
    },
    noDataProvidedForRow: {
      en_US: "No data was provided for row.",
      es_ES: "No se proveyeron datos para la fila.",
    },
    noDataWasProvidedForAnimal: {
      en_US: "No data was provided for animal.",
      es_ES: "No se proveyeron datos para animales.",
    },
    noHeader: {
      en_US:
        'If your file does not have a header, make sure to click "No header row".',
      es_ES:
        'If your file does not have a header, make sure to click "No header row".',
    },
    noMemorizedTemplateDetected: {
      en_US: "No memorized template detected for this file type.",
      es_ES: "No memorized template detected for this file type.",
    },
    note: {
      en_US: "Note",
      es_ES: "Nota",
    },
    nothingHasChangedSinceLastSubmission: {
      en_US:
        "Nothing seems to have changed since the last submission. Are you sure you want to proceed?",
      es_ES:
        "Nothing seems to have changed since the last submission. Are you sure you want to proceed?",
    },
    NotUnique: {
      en_US: "Not Unique",
      es_ES: "No único",
    },
    occurrence: {
      en_US: "time",
      es_ES: "time",
    },
    occurrences: {
      en_US: "times",
      es_ES: "times",
    },
    off: {
      en_US: "off",
      es_ES: "apagado",
    },
    on: {
      en_US: "on",
      es_ES: "prendido",
    },
    only1IsPermitted: {
      en_US: "Only 1 is permitted.",
      es_ES: "Only 1 is permitted.",
    },
    only1WeightIsAllowedAtAtime: {
      en_US:
        'Only 1 weight is allowed at a time. For example, you can set just "Weight" or just "Birth Weight".',
      es_ES:
        'Only 1 weight is allowed at a time. For example, you can set just "Weight" or just "Birth Weight".',
    },
    open: {
      en_US: "Open",
      es_ES: "Open",
    },
    rowCantImport: {
      en_US:
        "Row couldn't/wasn't imported, possibly due to ambiguous tag value.",
      es_ES:
        "Row couldn't/wasn't imported, possibly due to ambiguous tag value.",
    },
    rowNoData: {
      en_US:
        "Row has no data to submit/No new data was written. On occassion, this also indicates that a tag value is not unique to the animal, although one of the tags is unique.",
      es_ES:
        "Row has no data to submit/No new data was written. On occassion, this also indicates that a tag value is not unique to the animal, although one of the tags is unique.",
    },
    rowSpecificInformationExists: {
      en_US: "There is row-specific information not shown here.",
      es_ES: "There is row-specific information not shown here.",
    },
    rowWasSubmittedButSetToBeIgnored: {
      en_US: "Row was submitted, but it was set to be ignored.",
      es_ES: "Row was submitted, but it was set to be ignored.",
    },
    rowWasSuccessfullyImported: {
      en_US: "Row was successfully imported.",
      es_ES: "Row was successfully imported.",
    },
    savedTemplateContainsUnsupportedSetting: {
      en_US:
        "The saved template contains a setting which is no longer supported.",
      es_ES:
        "The saved template contains a setting which is no longer supported.",
    },
    selectAll: {
      en_US: "Select All",
      es_ES: "Seleccionar todo",
    },
    selectAnArcherFile: {
      en_US: "Select an Archer file",
      es_ES: "Select an Archer file",
    },
    set: {
      en_US: "Set",
      es_ES: "Set",
    },
    setAttribute: {
      en_US: "Set Attributes",
      es_ES: "Set Attributes",
    },
    status: {
      en_US: "Status",
      es_ES: "Estado",
    },
    statusEdit: {
      en_US: "Status/Edit",
      es_ES: "Estado/Editar",
    },
    subType: {
      en_US: "Sub-Type",
      es_ES: "Subtipo",
    },
    supportedFiles: {
      en_US: "Spreadsheet/CSV",
      es_ES: "Hoja de cálculo/CSV",
    },
    tagTableNotSet: {
      en_US: "Tag table is not set for a tag.",
      es_ES: "La tabla de etiquetas no está configurada para una etiqueta.",
    },
    tagTableNotSetForTagWithQuantity: {
      en_US: "Tag table is not set for a tag with quantity",
      es_ES:
        "La tabla de etiquetas no está configurada para una etiqueta con cantidad",
    },
    tagValueNotSufficient: {
      en_US: "A tag value is not sufficiently unique.",
      es_ES: "Un valor de etiqueta no es suficientemente único.",
    },
    templateWasMemorized: {
      en_US: "Template was memorized.",
      es_ES: "La plantilla se memorizó.",
    },
    templateWasNotMemorized: {
      en_US: "Template was not memorized. An error occurred.",
      es_ES: "La plantilla no fue memorizada. Ocurrió un error.",
    },
    tooManySubTypes: {
      en_US: "You have more than 1 columns assigned to be sub-type of",
      es_ES: "Tienes más de 1 columna asignada para ser subtipo de",
    },
    tooManyTypes: {
      en_US: "You have more than 1 columns assigned to be type of",
      es_ES: "Tienes más de 1 columnas asignadas para ser tipo de",
    },
    type: {
      en_US: "Type",
      es_ES: "Tipo",
    },
    unableToStoreRowResultsToDatabase: {
      en_US: "Was not able to store row results to database for animals.",
      es_ES:
        "No fue posible almacenar los resultados de las filas en la base de datos para animales.",
    },
    uploaded: {
      en_US: "Uploaded",
      es_ES: "Subido",
    },
    uploader: {
      en_US: "Uploader",
      es_ES: "Subidor",
    },
    urlHackNotRequired: {
      en_US:
        "It looks like you have '&automated=true' in your URL. That is no longer necessary.",
      es_ES:
        "It looks like you have '&automated=true' in your URL. That is no longer necessary.",
    },
    useArcherFileBtn: {
      en_US: "Use Archer File",
      es_ES: "Use Archer File",
    },
    useFile: {
      en_US: "Use File",
      es_ES: "Usar archivo",
    },
    usingMemorizedTemplate: {
      en_US: "Using memorized template.",
      es_ES: "Usando plantilla memorizada.",
    },
    valueDuplicate: {
      en_US: "Duplicate value",
      es_ES: "Valor duplicado",
    },
    valueEmpty: {
      en_US: "Value is suspicious or empty",
      es_ES: "El valor es sospechoso o está vacío",
    },
    valueInvalid: {
      en_US: "Value is invalid",
      es_ES: "Valor no válido",
    },
    Warning: {
      en_US: "Warning",
      es_ES: "Advertencia",
    },
    wasNotAbleToStoreResults: {
      en_US: "Was not able to store results to database.",
      es_ES: "No fue posible almacenar los resultados en la base de datos.",
    },
    withCurrentSettingsTagValuePart1: {
      en_US: "With current settings, the ID with value",
      es_ES: "Con la configuración actual, el ID con valor",
    },
    withCurrentSettingsTagValuePart2: {
      en_US:
        "may be saved as a new tag. Note that setting Tag Table is recommended for new tags.",
      es_ES:
        "tal vez se guardará como una nueva etiqueta. Note que se recomienda configurar la tabla de etiquetas para etiquetas nuevas.",
    },
    youWillLoseChanges: {
      en_US:
        "You will lose any changes you have made. Do you wish to continue?",
      es_ES: "Perderá todos los cambios que haya realizado. ¿Desea continuar?",
    },
  },
  Financials: {
    en_US: "Financials",
    es_ES: "Finanzas",
  },
  "Final Head Date": {
    en_US: "Final Head Date",
    es_ES: "Final Head Date",
  },
  Filters: {
    en_US: "Filters",
    es_ES: "Filtros",
  },
  firstServiceDate: {
    en_US: "1st Service Date",
    es_ES: "1st Service Date",
  },
  firstWeight: {
    en_US: "First Weight",
    es_ES: "Primer Peso",
  },
  firstWeightDate: {
    en_US: "First Weight Date",
    es_ES: "Fecha del primer peso",
  },
  "Flagged for Cull": {
    en_US: "Flagged for Cull",
    es_ES: "Marcado para sacrificio",
  },
  freemartin: {
    en_US: "Freemartin",
    es_ES: "Freemartin",
  },
  freight: {
    en_US: "Freight",
    es_ES: "Flete",
  },
  freightCost: {
    en_US: "Freight Cost",
    es_ES: "Costo del flete",
  },
  freightCWT: {
    en_US: "Freight (CWT)",
    es_ES: "Flete (CWT)",
  },
  "Freight Rage": {
    en_US: "Freight Rage",
    es_ES: "Freight Rage",
  },
  freightRate: {
    en_US: "Freight Per Animal",
    es_ES: "Flete por animal",
  },
  "Friendly Name": {
    en_US: "Friendly Name",
    es_ES: "Apodo",
  },
  From: {
    en_US: "From",
    es_ES: "Desde",
  },
  fullName: {
    en_US: "Full name",
    es_ES: "Nombre completo",
  },
  furtherDetails: {
    en_US: "And any further details here (images etc)...",
    es_ES: "Y más detalles aquí (imágenes, etc.)...",
  },
  gender: {
    en_US: "Gender",
    es_ES: "Género",
  },
  General: {
    en_US: "General",
    es_ES: "General",
  },
  "General Information": {
    en_US: "General Information",
    es_ES: "Informacion General",
  },
  Generate: {
    en_US: "Generate",
    es_ES: "Generar",
  },
  generics: {
    datePresentOrPastOnly: {
      en_US: "Date needs to be in the present or past.",
      es_ES: "La fecha debe estar en presente o en pasado.",
    },
  },
  "Geo data is not present": {
    en_US: "Geo data is not present",
    es_ES: "Los datos geográficos no están presentes",
  },
  "GnRH Received": {
    en_US: "GnRH Received",
    es_ES: "GnRH recibido",
  },
  goOnlineToPerformActions: {
    en_US: "Go online to perform actions.",
    es_ES: "Conéctese a Internet para realizar acciones.",
  },
  group: {
    en_US: "Group",
    es_ES: "Grupo",
  },
  Group: {
    en_US: "Group",
    es_ES: "Grupo",
  },
  Groups: {
    en_US: "Groups",
    es_ES: "Grupos",
  },
  "Group Number": {
    en_US: "Group Number",
    es_ES: "Número de grupo",
  },
  "Group Statistics": {
    en_US: "Group Statistics",
    es_ES: "Estadisticas del grupo",
  },
  "Group Summary": {
    en_US: "Group Summary",
    es_ES: "Resumen del grupo",
  },
  groupWeight: {
    en_US: "Group Weight",
    es_ES: "Grupo del pesado",
  },
  guid: {
    en_US: "GUID",
    es_ES: "GUID",
  },
  Harvest: {
    en_US: "Harvest",
    es_ES: "Cosecha",
  },
  headcount: {
    en_US: "Headcount",
    es_ES: "Recuento de cabezas",
  },
  headDays: {
    en_US: "Head Days",
    es_ES: "Días de cabeza",
  },
  heavy: {
    en_US: "Heavy",
    es_ES: "Heavy",
  },
  heiferCalf: {
    en_US: "Heifer Calf",
    es_ES: "Novilla becerro",
  },
  heifers: {
    en_US: "Heifers",
    es_ES: "Novillas",
  },
  HelpGuideButton: {
    en_US: "Click Here for a Search Help Guide",
    es_ES: "Haga clic aquí para obtener una guía de búsqueda",
  },
  Herd: {
    en_US: "Herd",
    es_ES: "Manada",
  },
  hereford: {
    en_US: "Hereford",
    es_ES: "Hereford",
  },
  herefordX: {
    en_US: "Hereford X",
    es_ES: "Hereford X",
  },
  hide: {
    en_US: "Hide",
    es_ES: "Esconder",
  },
  History: {
    en_US: "History",
    es_ES: "Historia",
  },
  Hoof: {
    en_US: "Dam Hoof",
    es_ES: "Pezuña madre",
  },
  "Hot Scale Weight": {
    en_US: "Hot Scale Weight",
    es_ES: "Hot Scale Weight",
  },
  "Hot Weight": {
    en_US: "Hot Weight",
    es_ES: "Hot Weight",
  },
  id: {
    en_US: "ID",
    es_ES: "ID",
  },
  imagePreview: {
    en_US: "Image Preview",
    es_ES: "Image Preview",
  },
  import: {
    en_US: "Import",
    es_ES: "Importar",
  },
  importRelationships: {
    import: {
      en_US: "Import",
      es_ES: "Importar",
    },
    title: {
      en_US: "Import Relationships",
      es_ES: "Relaciones de importación",
    },
  },
  "Inbound Manifests": {
    en_US: "Inbound Manifests",
    es_ES: "Inbound Manifests",
  },
  income: {
    en_US: "Income",
    es_ES: "Ingreso",
  },
  individualWeight: {
    en_US: "Individual Weight",
    es_ES: "Peso Indivíduo",
  },
  intake: {
    en_US: "Intake",
    es_ES: "Intake",
  },
  intakeLocation: {
    en_US: "Use on Intake",
    es_ES: "Use on Intake",
  },
  inventoryReport: {
    bulls: {
      en_US: "Bulls",
      es_ES: "Toros",
    },
    cake: {
      en_US: "Cake",
      es_ES: "Cake",
    },
    calf_pellets: {
      en_US: "Calf Pellets",
      es_ES: "Pellets para terneros",
    },
    calves: {
      en_US: "Calves",
      es_ES: "Pantorrillas",
    },
    cows: {
      en_US: "Cows",
      es_ES: "Cows",
    },
    date: {
      en_US: "Date",
      es_ES: "Fecha",
    },
    description: {
      en_US: "Description",
      es_ES: "Descripción",
    },
    hay: {
      en_US: "Hay",
      es_ES: "Heno",
    },
    heifers: {
      en_US: "Heifers",
      es_ES: "Novillas",
    },
    mineral: {
      en_US: "Mineral",
      es_ES: "Mineral",
    },
    organizationName: {
      en_US: "Organization",
      es_ES: "Organización",
    },
    other_feed: {
      en_US: "Other Feed",
      es_ES: "Otro alimento",
    },
    protein: {
      en_US: "Protein",
      es_ES: "Proteína",
    },
    recordID: {
      en_US: "Record ID",
      es_ES: "Record ID",
    },
    rph: {
      en_US: "RPH",
      es_ES: "RPH",
    },
    salt: {
      en_US: "Salt",
      es_ES: "Sal",
    },
    steers: {
      en_US: "Steers",
      es_ES: "Novillos",
    },
    title: {
      en_US: "Inventory Report",
      es_ES: "Reporte de inventario",
    },
    total_cattle: {
      en_US: "Total Cattle",
      es_ES: "Total Cattle",
    },
    transaction: {
      en_US: "Transaction",
      es_ES: "Transacción",
    },
    two: {
      en_US: "Two",
      es_ES: "Two",
    },
  },
  "Is Destination": {
    en_US: "Is Destination",
    es_ES: "Es Destino",
  },
  "Is Feedlot": {
    en_US: "Is Feedlot",
    es_ES: "Es Corral de Engorde",
  },
  "Is Origin": {
    en_US: "Is Origin",
    es_ES: "Es Origen",
  },
  "Is Packer": {
    en_US: "Is Packer",
    es_ES: "Es Empacador",
  },
  kg: {
    en_US: "kg",
    es_ES: "kg",
  },
  "Kill Date": {
    en_US: "Kill Date",
    es_ES: "Fecha de sacrificio",
  },
  "Kill Lot": {
    en_US: "Kill Lot",
    es_ES: "Lote de sacrificio",
  },
  lastAnimalEditTime: {
    en_US: "Last Animal Edit Time",
    es_ES: "Tiempo de edición del último animal",
  },
  "Last Updated": {
    en_US: "Last Updated",
    es_ES: "Última actualización",
  },
  "Last EID": {
    en_US: "Last EID",
    es_ES: "Ultimo EID",
  },
  "Last Read Time": {
    en_US: "Last Read Time",
    es_ES: "Tiempo del ultimo escaneo",
  },
  "Last Read Time": {
    en_US: "Last Read Time",
    es_ES: "Tiempo del ultimo escaneo",
  },
  "Last Updated": {
    en_US: "Last Updated",
    es_ES: "Última actualización",
  },
  "Last Weight": {
    en_US: "Last Weight",
    es_ES: "Último peso",
  },
  Late: {
    en_US: "Late",
    es_ES: "Tarde",
  },
  latitude: {
    en_US: "Latitude",
    es_ES: "Latitud",
  },
  Late: {
    en_US: "Late",
    es_ES: "Tarde",
  },
  latitude: {
    en_US: "Latitude",
    es_ES: "Latitud",
  },
  lbs: {
    en_US: "lbs",
    es_ES: "libras",
  },
  Length: {
    en_US: "Length",
    es_ES: "Longitud",
  },
  link: {
    en_US: "Link",
    es_ES: "Enlace",
  },
  lists: {
    en_US: "Lists",
    es_ES: "Listas",
  },
  "Live Weight": {
    en_US: "Live weight",
    es_ES: "Live weight",
  },
  loading: {
    en_US: "Please wait, loading resources for offline access",
    es_ES: "Espere, cargando recursos para acceso sin conexión",
  },
  locale: {
    en_US: "Locale",
    es_ES: "Idioma local",
  },
  locationHistory: {
    en_US: "Location History",
    es_ES: "Historial de Ubicación",
  },
  locationId: {
    en_US: "Location ID",
    es_ES: "Location ID",
  },
  location: {
    en_US: "Location",
    es_ES: "Ubicación",
  },
  locationList: {
    en_US: "Pasture/Pen",
    es_ES: "Pasture/Pen",
  },
  locations: {
    en_US: "Locations",
    es_ES: "Ubicaciónes",
  },
  locationsTable: {
    feedAlias: {
      en_US: "Feed Alias",
      es_ES: "Alias Alimiento",
    },
    friendlyName: {
      en_US: "Friendly Name(s)",
      es_ES: "Alias(es)",
    },
  },
  loggedOut: {
    en_US:
      "<strong>Warning!</strong><br />You have been logged out, possibly in another tab. Please refresh the page after you have logged back in.",
    es_ES:
      "<strong>Warning!</strong><br />You have been logged out, possibly in another tab. Please refresh the page after you have logged back in.",
  },
  login: {
    changeUsers: {
      en_US: "Change User",
      es_ES: "Cambiar usario",
    },
    remain: {
      en_US: "(You may navigate to another page to remain signed in.)",
      es_ES: "(You may navigate to another page to remain signed in.)",
    },
    signin: {
      en_US: "Please Sign In",
      es_ES: "Please Sign In",
    },
  },
  longitude: {
    en_US: "Longitude",
    es_ES: "Longitud",
  },
  lost: {
    en_US: "Lost",
    es_ES: "Perdido",
  },
  lotNumber: {
    en_US: "Lot Number",
    es_ES: "Número de lote",
  },
  lungScore: {
    en_US: "Lung Score",
    es_ES: "Lung Score",
  },
  Manifest: {
    en_US: "Manifest",
    es_ES: "Manifiesto",
  },
  manufacturer: {
    en_US: "Manufacturer",
    es_ES: "Fabricante",
  },
  "Map is not available": {
    en_US: "Map is not available.",
    es_ES: "Mapa no está disponible.",
  },
  Map: {
    en_US: "Map",
    es_ES: "Mapa",
  },
  mapButton: {
    en_US: "View Coordinates",
    es_ES: "Ver Coordenadas",
  },
  Max_: {
    en_US: "Max",
    es_ES: "Máx.",
  },
  marketable: {
    en_US: "Marketable",
    es_ES: "Comerciable",
  },
  Maximum: {
    en_US: "Maximum",
    es_ES: "Máximo",
  },
  maxReads: {
    en_US: "Max Reads",
    es_ES: "Max Reads",
  },
  meanReads: {
    en_US: "Mean Reads",
    es_ES: "Media matemática de lecturas",
  },
  Med_: {
    en_US: "Med",
    es_ES: "Med.",
  },
  Median: {
    en_US: "Median",
    es_ES: "Mediana",
  },
  medianReads: {
    en_US: "Median Reads",
    es_ES: "Mediana matemática de lecturas",
  },
  "Medication Lot Number": {
    en_US: "Medication Lot Number",
    es_ES: "Lote número de medication",
  },
  "Merge Duplicate": {
    en_US: "Merge Duplicate",
    es_ES: "Unir duplicado",
  },
  method: {
    en_US: "Method",
    es_ES: "Método",
  },
  metrics: {
    en_US: "Metrics",
    es_ES: "Metrics",
  },
  Milk: {
    en_US: "Dam Milk",
    es_ES: "Dam Milk",
  },
  mimeType: {
    en_US: "Mime Type",
    es_ES: "Tipo de Mimica",
  },
  Min_: {
    en_US: "Min",
    es_ES: "Mín.",
  },
  Minimum: {
    en_US: "Minimum",
    es_ES: "Mínimo",
  },
  minReads: {
    en_US: "Min Reads",
    es_ES: "Min Reads",
  },
  missing: {
    en_US: "Missing",
    es_ES: "Missing",
  },
  mobile: {
    en_US: "Mobile",
    es_ES: "Mobile",
  },
  "Mouth Score": {
    en_US: "Mouth Score",
    es_ES: "Puntuacion Bucal",
  },
  mouthScores: {
    name: {
      en_US: "Mouth Scores",
      es_ES: "Puntuaciones Bucales",
    },
    table: {
      guid: {
        en_US: "Guid",
        es_ES: "Guid",
      },
      mouthScores: {
        en_US: "Mouth Score",
        es_ES: "Puntuacion Bucal",
      },
      pregChecks: {
        en_US: "Preg Check",
        es_ES: "Preg Check",
      },
      row: {
        en_US: "Row",
        es_ES: "Fila",
      },
    },
  },
  move: {
    en_US: "Move",
    es_ES: "Mover",
  },
  "Move Column": {
    en_US: "Move Columns",
    es_ES: "Mover columnas",
  },
  moveButton: {
    en_US: "Move",
    es_ES: "Move",
  },
  moveDate: {
    en_US: "Move Date",
    es_ES: "Fecha de movimiento",
  },
  moveInDate: {
    en_US: "Move In Date",
    es_ES: "Fecha de mudanza",
  },
  moveModal: {
    browse: {
      en_US: "Browse",
      es_ES: "Navegar",
    },
    dateRecorded: {
      en_US: "Date Recorded",
      es_ES: "Date Recorded",
    },
  },
  moveTime: {
    en_US: "Move Time",
    es_ES: "Hora de mudanza",
  },
  "Multiple Results": {
    en_US: "Multiple Results",
    es_ES: "Resultados Multiples",
  },
  name: {
    en_US: "Name",
    es_ES: "Nombre",
  },
  navbar: {
    about: {
      en_US: "About",
      es_ES: "About",
    },
    actions: {
      en_US: "Actions",
      es_ES: "Acciones",
    },
    admin: {
      en_US: "Admin",
      es_ES: "Administrador",
    },
    animalDetails: {
      en_US: "Animal Details",
      es_ES: "Animal Details",
    },
    animals: {
      en_US: "Animals",
      es_ES: "Animales",
    },
    bullTest: {
      en_US: "Bull Test",
      es_ES: "Bull Test",
    },
    calfWean: {
      en_US: "Calf Wean",
      es_ES: "Destete de Terneros",
    },
    closeout: {
      en_US: "Closeout",
      es_ES: "Closeout",
    },
    commentInField: {
      en_US: "Comment",
      es_ES: "Comentario",
    },
    commodityTypes: {
      en_US: "Commodity Type",
      es_ES: "Commodity Type",
    },
    displayAnimals: {
      en_US: "Display Animals",
      es_ES: "Mostrar animales",
    },
    disposition: {
      en_US: "Disposition Animals",
      es_ES: "Disposition Animals",
    },
    eula: {
      en_US: "EULA",
      es_ES: "EULA",
    },
    fastTrack: {
      en_US: "Asset Tracker",
      es_ES: "Asset Tracker",
    },
    faq: {
      en_US: "FAQ",
      es_ES: "FAQ",
    },
    feedlot: {
      en_US: "Fast Track Stats",
      es_ES: "Fast Track Stats",
    },
    feedtrucks: {
      en_US: "Feed Trucks",
      es_ES: "Feed Trucks",
    },
    fileETL: {
      en_US: "Import Animals",
      es_ES: "Import Animals",
    },
    healthTracker: {
      en_US: "Health Tracker",
      es_ES: "Health Tracker",
    },
    home: {
      en_US: "Home",
      es_ES: "Home",
    },
    intake: {
      en_US: "Intake",
      es_ES: "Intake",
    },
    locationHeadCounts: {
      en_US: "Head Counts",
      es_ES: "Cantidad de cabezas",
    },
    login: {
      en_US: "Login",
      es_ES: "Iniciar sesión",
    },
    logout: {
      en_US: "Logout",
      es_ES: "Cerrar sesión",
    },
    mergeDuplicates: {
      en_US: "Review Duplicate Animals",
      es_ES: "Review Duplicate Animals",
    },
    mouthScores: {
      en_US: "Mouth Scores",
      es_ES: "Puntuaciones Bucales",
    },
    myColors: {
      en_US: "Colors",
      es_ES: "Colores",
    },
    myCommodities: {
      en_US: "Commodities",
      es_ES: "Materias primas",
    },
    myFeedtrucks: {
      en_US: "Feed Trucks",
      es_ES: "Camiones de alimentación",
    },
    myGenders: {
      en_US: "Sexes",
      es_ES: "Sexos",
    },
    myHerds: {
      en_US: "Herds",
      es_ES: "Manadas",
    },
    myLocations: {
      en_US: "Pastures or Pens",
      es_ES: "Pastos o corrales",
    },
    myMedications: {
      en_US: "Medications",
      es_ES: "Medicamentos",
    },
    myOrg: {
      en_US: "Organizations",
      es_ES: "Organizaciónes",
    },
    myOrigins: {
      en_US: "Origins",
      es_ES: "Origenes",
    },
    myTags: {
      en_US: "Tags",
      es_ES: "Etiquetas",
    },
    myUsers: {
      en_US: "Users",
      es_ES: "Usarios",
    },
    offspring: {
      en_US: "Offspring",
      es_ES: "Offspring",
    },
    pregCheck: {
      en_US: "Preg Check",
      es_ES: "Preg Check",
    },
    programName: {
      en_US: "Fast Herd",
      es_ES: "Fast Herd",
    },
    readerLocations: {
      en_US: "Reader Locations",
      es_ES: "Reader Locations",
    },
    reports: {
      en_US: "Reports",
      es_ES: "Informes",
    },
    reviewDuplicateAnimals: {
      en_US: "Review Duplicate Animals",
      es_ES: "Review Duplicate Animals",
    },
    sickListEmails: {
      en_US: "Sick List Email",
      es_ES: "Sick List Email",
    },
    signUp: {
      en_US: "Sign Up",
      es_ES: "Inscribirse",
    },
  },
  negative: {
    en_US: "Negative",
    es_ES: "Negativo",
  },
  New: {
    en_US: "New",
    es_ES: "Nuevo",
  },
  "New Animals": {
    en_US: "New Animals",
    es_ES: "Nuevos Animales",
  },
  "New EID": {
    en_US: "New EID",
    es_ES: "Nuevo EID",
  },
  "New Tag": {
    en_US: "New Tag",
    es_ES: "Nueva Etiqueta",
  },
  "New Visual": {
    en_US: "New Visual",
    es_ES: "Nuevo Visual",
  },
  No: {
    en_US: "No",
    es_ES: "No",
  },
  noPermission: {
    en_US:
      "You do not have permission to access the document or program that you requested.",
    es_ES:
      "You do not have permission to access the document or program that you requested.",
  },
  "Not Alive": {
    en_US: "Not Alive",
    es_ES: "No Vivo",
  },
  "Not Alive and not Cull": {
    en_US: "Not Alive and not Cull",
    es_ES: "No Vivo ni Sacrificado",
  },
  notLoggedInRedirecting: {
    en_US:
      "<strong>Warning</strong><br />You are not logged in. You will be redirected to the login page automatically.",
    es_ES:
      "<strong>Warning</strong><br />You are not logged in. You will be redirected to the login page automatically.",
  },
  nuesTagValues: {
    en_US: "NUES Tags",
    es_ES: "NUES Tags",
  },
  "Number Tested": {
    en_US: "Number Tested",
    es_ES: "Cantidad de Pruebas",
  },
  offspring: {
    en_US: "Offspring",
    es_ES: "Offspring",
  },
  offspringPage: {
    date: {
      en_US: "Date",
      es_ES: "Fecha",
    },
    daySummaryTable: {
      en_US: "Daily Summary",
      es_ES: "Daily Summary",
    },
    formName: {
      en_US: "Offspring",
      es_ES: "Offspring",
    },
    monthSummaryTable: {
      en_US: "Monthly Summary",
      es_ES: "Monthly Summary",
    },
    offspringTable: {
      en_US: "Offspring",
      es_ES: "Offspring",
    },
    row: {
      en_US: "Row",
      es_ES: "Fila",
    },
    total: {
      en_US: "Total",
      es_ES: "Total",
    },
    totalAlive: {
      en_US: "# Alive",
      es_ES: "# Vivo",
    },
    totalDead: {
      en_US: "# Dead",
      es_ES: "# Muerto",
    },
  },
  offspringTags: {
    en_US: "Offspring Tags",
    es_ES: "Offspring Tags",
  },
  oops: {
    en_US: "Oops!",
    es_ES: "Uy!",
  },
  open: {
    en_US: "Open",
    es_ES: "Open",
  },
  organization: {
    en_US: "Organization",
    es_ES: "Organización",
  },
  organizationId: {
    en_US: "Organization ID",
    es_ES: "ID Organización",
  },
  "Billing Organization": {
    en_US: "Billing Organization",
    es_ES: "Organización de facturacion",
  },
  "Billing Representative": {
    en_US: "Billing Representative",
    es_ES: "Representante de facturacion",
  },
  "Animal Count": {
    en_US: "Animal Count",
    es_ES: "Conteo de animales",
  },
  "Organization Guid": {
    en_US: "Organization Guid",
    es_ES: "Guid Organización",
  },
  "Organization Name": {
    en_US: "Organization Name",
    es_ES: "Nombre de la organización",
  },
  organizationName: {
    en_US: "Organization Name",
    es_ES: "Organization Name",
  },
  organizations: {
    en_US: "Organizations",
    es_ES: "Organizaciones",
  },
  origin: {
    en_US: "Origin",
    es_ES: "Origen",
  },
  "Origin Reader Time": {
    en_US: "Origin Reader Time",
    es_ES: "Tiempo del lector de origen",
  },
  origins: {
    en_US: "Origins",
    es_ES: "Origines",
  },
  other: {
    en_US: "Other",
    es_ES: "Otro",
  },
  ourStory: {
    en_US: "Our Story",
    es_ES: "Nuestra historia",
  },
  ovine: {
    en_US: "Ovine",
    es_ES: "Ovine",
  },
  Packers: {
    en_US: "Packers",
    es_ES: "Packers",
  },
  palpate: {
    en_US: "Palpate",
    es_ES: "Palpar",
  },
  panelFooter: {
    en_US: "Panel Footer",
    es_ES: "Pie de página del panel",
  },
  parentInformation: {
    save: {
      en_US: "Save",
      es_ES: "Guardar",
    },
    title: {
      en_US: "Edit Parent",
      es_ES: "Editar Padre",
    },
  },
  password: {
    en_US: "Password",
    es_ES: "Password",
  },
  "Password Recovery": {
    en_US: "Password Recovery",
    es_ES: "Recuperación de contraseña",
  },
  "Pasture and Pens": {
    en_US: "Pastures & Pens",
    es_ES: "Pastos y corrales",
  },
  pasturesOrPens: {
    en_US: "Pastures or Pens",
    es_ES: "Pastos o corrales",
  },
  pastureSlashPen: {
    en_US: "Pasture/Pen",
    es_ES: "Pasto/corral",
  },
  pastureSlashPen: {
    en_US: "Pasture/Pen",
    es_ES: "Pasto/corral",
  },
  pelvicMeasure: {
    en_US: "Pelvic Measure",
    es_ES: "Medida pélvica",
  },
  pending: {
    en_US: "Pending",
    es_ES: "Pendiente",
  },
  "Percent Cull": {
    en_US: "Percent Cull",
    es_ES: "Porcentaje de sacrificados",
  },
  "Percent Cycle": {
    en_US: "Percent Cycle",
    es_ES: "Porcentaje de ciclo",
  },
  "Percent Late": {
    en_US: "Percent Late",
    es_ES: "Porcentaje de tardios",
  },
  "Percent Open": {
    en_US: "Percent Open",
    es_ES: "Porcentaje de abiertos",
  },
  "Percent Retained": {
    en_US: "Percent Retained",
    es_ES: "Porcentaje de retenidos",
  },
  percentCross: {
    en_US: "Percent Cross",
    es_ES: "Cruz de porcentaje",
  },
  physical: {
    en_US: "Physical",
    es_ES: "Physical",
  },
  picture: {
    en_US: "Picture",
    es_ES: "Foto",
  },
  positive: {
    en_US: "Positive",
    es_ES: "Positivo",
  },
  postfixEID: {
    en_US: "EID Suffix",
    es_ES: "Sufijo EID",
  },
  predictedCalvingDate: {
    en_US: "Estimated Calving Date",
    es_ES: "Fecha estimada de parto",
  },
  prefixEID: {
    en_US: "EID Prefix",
    es_ES: "Prefijo EID ",
  },
  Preg: {
    en_US: "Preg",
    es_ES: "Embarazo",
  },
  "Preg Group": {
    en_US: "Preg Group",
    es_ES: "Grupo de embarazo",
  },
  "Preg Method": {
    en_US: "Preg Method",
    es_ES: "Método de averiguar embarazo",
  },
  "Preg Report": {
    en_US: "Preg Report",
    es_ES: "Reporte de embarazo",
  },
  "Preg Technician": {
    en_US: "Preg Technician",
    es_ES: "Técnico de embarazo",
  },
  pregCheck: {
    en_US: "Preg Check",
    es_ES: "Preg Check",
  },
  pregCheckedBy: {
    en_US: "Vet / Tech",
    es_ES: "Vet / Tech",
  },
  pregCheckHistory: {
    en_US: "Preg Check History",
    es_ES: "Preg Check History",
  },
  pregCheckMethod: {
    en_US: "Test Method",
    es_ES: "Método de prueba",
  },
  pregCheckMethod2: {
    en_US: "Preg Check Method",
    es_ES: "Preg Check Method",
  },
  pregCheckPage: {
    aiInformation: {
      aiDate: {
        en_US: "AI Date",
        es_ES: "Fecha AI",
      },
      name: {
        en_US: "AI Information",
        es_ES: "Información del AI",
      },
      sire: {
        en_US: "Sire",
        es_ES: "Padre",
      },
      technician: {
        en_US: "Technician",
        es_ES: "Técnico",
      },
    },
    cowInformation: {
      age: {
        en_US: "Age",
        es_ES: "Edad",
      },
      birthDate: {
        en_US: "Birth Date",
        es_ES: "Fecha de Nacimiento",
      },
      birthRanch: {
        en_US: "Birth Ranch",
        es_ES: "Rancho de Nacimiento",
      },
      birthVisual: {
        en_US: "Birth Visual",
        es_ES: "Número de Etiqueta Visual de Nacimiento",
      },
      breed: {
        en_US: "Breed",
        es_ES: "Raza",
      },
      comment: {
        en_US: "Comment",
        es_ES: "Comentario",
      },
      herd: {
        en_US: "Herd",
        es_ES: "Manada",
      },
      name: {
        en_US: "Cow Information",
        es_ES: "Información de la vaca",
      },
      sire: {
        en_US: "Sire",
        es_ES: "Padre",
      },
      status: {
        en_US: "Status",
        es_ES: "Estado",
      },
    },
    groupSummary: {
      breed: {
        en_US: "Breed",
        es_ES: "Raza",
      },
      breedUp: {
        en_US: "Breed Up",
        es_ES: "Criar",
      },
      breedUpType: {
        en_US: "Breed Up Type",
        es_ES: "Breed Up Type",
      },
      cull: {
        en_US: "Cull",
        es_ES: "Sacrificio",
      },
      cycle: {
        en_US: "Cycle",
        es_ES: "Ciclo",
      },
      late: {
        en_US: "Late",
        es_ES: "Tardío",
      },
      openCull: {
        en_US: "Open Cull",
        es_ES: "Sacrificio Abierto",
      },
      pregCull: {
        en_US: "Preg Cull",
        es_ES: "Sacrificio Embarazo",
      },
      previousEntryTag: {
        en_US: "Previous Entry Tag",
        es_ES: "Etiqueta de Entrada Anterior",
      },
      status: {
        en_US: "Status",
        es_ES: "Estado",
      },
      totalTested: {
        en_US: "Total Tested",
        es_ES: "Total Tested",
      },
    },
    lastCalvings: {
      name: {
        en_US: "Last Calving",
        es_ES: "Últimos Partos",
      },
    },
    name: {
      en_US: "Preg Check",
      es_ES: "Preg Check",
    },
    pregnancyTestEntry: {
      aiBred: {
        en_US: "AI Bred",
        es_ES: "Crianza AI",
      },
      bcs: {
        en_US: "BCS",
        es_ES: "BCS",
      },
      breed: {
        en_US: "Breed",
        es_ES: "Raza",
      },
      breedUpType: {
        en_US: "Breed-up Type",
        es_ES: "Tipo de crianza",
      },
      bullTurninDate: {
        en_US: "Bull Turn-in Date",
        es_ES: "Bull Turn-in Date",
      },
      color: {
        en_US: "Color",
        es_ES: "Color",
      },
      comments: {
        en_US: "Comments",
        es_ES: "Comentarios",
      },
      cullStatus: {
        en_US: "Cull Status",
        es_ES: "Estado del Sacrificio",
      },
      cycle: {
        en_US: "Cycle",
        es_ES: "Ciclo",
      },
      date: {
        en_US: "Date",
        es_ES: "Fecha",
      },
      daysBred: {
        en_US: "Days Bred",
        es_ES: "Días criado",
      },
      dose: {
        en_US: "Dose",
        es_ES: "Dosis",
      },
      fetalSex: {
        en_US: "Fetal Sex",
        es_ES: "Sexo Fetal",
      },
      findByEID: {
        en_US: "EID",
        es_ES: "EID",
      },
      findByTag: {
        en_US: "Visual",
        es_ES: "Visual",
      },
      group: {
        en_US: "Group",
        es_ES: "Grupo",
      },
      late: {
        en_US: "Late",
        es_ES: "Tardío",
      },
      mouthScore: {
        en_US: "Mouth Score",
        es_ES: "Puntuacion Bucal",
      },
      name: {
        en_US: "Pregnancy Test Entry",
        es_ES: "Prueba de embarazo",
      },
      naturalBred: {
        en_US: "Natural Bred",
        es_ES: "Crianza natural",
      },
      newHerd: {
        en_US: "New Herd",
        es_ES: "Nuevo rebaño",
      },
      newRanchUnit: {
        en_US: "New Ranch Unit",
        es_ES: "Nueva Unidad de Rancho",
      },
      no: {
        en_US: "No",
        es_ES: "No",
      },
      open: {
        en_US: "Open",
        es_ES: "Abierto",
      },
      pregTestMethod: {
        en_US: "Test Method",
        es_ES: "Método de prueba",
      },
      reset: {
        en_US: "Reset",
        es_ES: "Limpiar",
      },
      sex: {
        en_US: "Sex",
        es_ES: "Sexo",
      },
      tagColor: {
        en_US: "Visual Color",
        es_ES: "Color de etiqueta",
      },
      technician: {
        en_US: "Technician",
        es_ES: "Técnico",
      },
      vaccination: {
        en_US: "Vaccination",
        es_ES: "Vacuna",
      },
      weight: {
        en_US: "Weight",
        es_ES: "Peso",
      },
      yes: {
        en_US: "Yes",
        es_ES: "Si",
      },
    },
    summary: {
      color: {
        en_US: "Color",
        es_ES: "Color",
      },
      currentWeight: {
        en_US: "Current Weight",
        es_ES: "Peso Actual",
      },
      daysBred: {
        en_US: "Days Bred",
        es_ES: "Días criado",
      },
      description: {
        en_US: "Preg Checks of today",
        es_ES: "Pruebas de embarazo actuales",
      },
      fetalSex: {
        en_US: "Fetal Sex",
        es_ES: "Sexo Fetal",
      },
      location: {
        en_US: "Location",
        es_ES: "Locación",
      },
      name: {
        en_US: "Summary",
        es_ES: "Resumen",
      },
      pregCheckComment: {
        en_US: "Preg Check Comment",
        es_ES: "Comentario",
      },
      pregCheckMethod: {
        en_US: "Preg Check Method",
        es_ES: "Método",
      },
      pregCheckResult: {
        en_US: "Preg Check Result",
        es_ES: "Resultado",
      },
      pregCheckTime: {
        en_US: "Preg Check Time",
        es_ES: "Tiempo",
      },
      status: {
        en_US: "Status",
        es_ES: "Estado",
      },
      tagValues: {
        en_US: "Tag Values",
        es_ES: "Etiquetas",
      },
      technician: {
        en_US: "Technician",
        es_ES: "Técnico",
      },
    },
  },
  pregCheckResult: {
    en_US: "Preg Result",
    es_ES: "Preg Result",
  },
  pregCheckStatus: {
    en_US: "Preg Status",
    es_ES: "Preg Status",
  },
  pregCheckTime: {
    en_US: "Preg Check Date",
    es_ES: "Preg Check Date",
  },
  PregnancyTestReport: {
    en_US: "Pregnancy Test Report",
    es_ES: "Reporte de pruebas de embarazo",
  },
  PregnancyTestSummary: {
    en_US: "Pregnancy Test Summary",
    es_ES: "Resumen de pruebas de embarazo",
  },
  pregOrWeanMedication: {
    en_US: "Preg Or Wean Medication",
    es_ES: "Preg Or Wean Medication",
  },
  pregResult: {
    en_US: "Preg Result",
    es_ES: "Preg Result",
  },
  pregStatus: {
    en_US: "Preg Status",
    es_ES: "Preg Status",
  },
  previousTreatments: {
    en_US: "Previous Treatments",
    es_ES: "Previous Treatments",
  },
  prevLocation: {
    en_US: "Previous Pasture/Pen",
    es_ES: "Pasto/corral Previo",
  },
  price: {
    en_US: "Price",
    es_ES: "Precio",
  },
  pricePerBottle: {
    en_US: "$ / bottle",
    es_ES: "$ / botella",
  },
  priceUnits: {
    en_US: "Price Units",
    es_ES: "Unidad de precio",
  },
  Processed: {
    en_US: "Processed",
    es_ES: "Procesado",
  },
  processingCost: {
    en_US: "Processing Cost",
    es_ES: "Costo de procesar",
  },
  processingDate: {
    en_US: "Processing Date",
    es_ES: "Fecha de procesar",
  },
  purchaseCommissionRate: {
    en_US: "Purchase Commission Rate",
    es_ES: "Purchase Commission Rate",
  },
  Purchase: {
    en_US: "Purchase",
    es_ES: "Compra",
  },
  Purchased: {
    en_US: "Purchased",
    es_ES: "Comprado",
  },
  purchased: {
    en_US: "Purchased",
    es_ES: "Comprado",
  },
  purchaseDate: {
    en_US: "Purchase Date",
    es_ES: "Fecha de compra",
  },
  purchasePrice: {
    en_US: "Purchase Price",
    es_ES: "Precio de compra",
  },
  purchasePriceType: {
    en_US: "Type",
    es_ES: "Tipo",
  },
  purchaseSaleHistory: {
    en_US: "Purchase Sale History",
    es_ES: "Historial de compra venta",
  },
  purchaseWeight: {
    en_US: "Purchase Weight",
    es_ES: "Peso de compra",
  },
  "Quality Grade": {
    en_US: "Quality Grade",
    es_ES: "Grado de calidad",
  },
  "Quality Grade 2": {
    en_US: "Quality Grade 2",
    es_ES: "Grado de calidad 2",
  },
  Range: {
    en_US: "Range",
    es_ES: "Rango",
  },
  rank: {
    en_US: "Rank",
    es_ES: "Rango",
  },
  read: {
    en_US: "Read",
    es_ES: "Read",
  },
  "Reader Box": {
    en_US: "Reader Box",
    es_ES: "Reader Box",
  },
  "Readers And Groups": {
    en_US: "Readers And Groups",
    es_ES: "Lectores y grupos",
  },
  readerThresholdCurrent: {
    en_US: "Reader Current Threshold",
    es_ES: "Reader Current Threshold",
  },
  reason: {
    en_US: "Reason",
    es_ES: "Razón",
  },
  reasonForMove: {
    en_US: "Reason for Move",
    es_ES: "Reason for Move",
  },
  reader: {
    en_US: "Reader",
    es_ES: "Lector",
  },
  Reader: {
    en_US: "Reader",
    es_ES: "Lector",
  },
  "Receiving Ranch": {
    en_US: "Receiving Ranch",
    es_ES: "Rancho de recepción",
  },
  "Recent Data": {
    en_US: "Recent Data",
    es_ES: "Datos Recientes",
  },
  "Rectal Temperature": {
    en_US: "Rectal Temperature",
    es_ES: "Temperatura Rectal",
  },
  rectalTemperature: {
    en_US: "Rectal Temperature",
    es_ES: "Temperatura Rectal",
  },
  receivingRanch: {
    en_US: "Receiving Ranch",
    es_ES: "Rancho de recepción",
  },
  red: {
    en_US: "Red",
    es_ES: "Red",
  },
  redBrockle: {
    en_US: "Red Brockle",
    es_ES: "Red Brockle",
  },
  redWhiteFace: {
    en_US: "Red White Face",
    es_ES: "Red White Face",
  },
  reject: {
    en_US: "Reject",
    es_ES: "Rechazar",
  },
  Relationship: {
    en_US: "Relationship",
    es_ES: "Relación",
  },
  remove: {
    en_US: "Remove",
    es_ES: "Eliminar",
  },
  reorder: {
    en_US: "Reorder",
    es_ES: "Reordenar",
  },
  reports: {
    en_US: "Reports",
    es_ES: "Informes",
  },
  reproductiveTract: {
    en_US: "Reproductive Tract",
    es_ES: "Reproductive Tract",
  },
  Reset: {
    en_US: "Reset",
    es_ES: "Limpiar",
  },
  result: {
    en_US: "Result",
    es_ES: "Resultado",
  },
  Retained: {
    en_US: "Retained",
    es_ES: "Retenidos",
  },
  routes: {
    Animales: {
      en_US: "Animals",
      es_ES: "Animales",
    },
    Animals: {
      en_US: "Animals",
      es_ES: "Animales",
    },
    Comentario: {
      en_US: "Comment",
      es_ES: "Comentario",
    },
    Comment: {
      en_US: "Comment",
      es_ES: "Comentario",
    },
    Dashboard: {
      en_US: "Dashboard",
      es_ES: "Tablero",
    },
    "Display Animals": {
      en_US: "Display Animals",
      es_ES: "Mostrar Animales",
    },
    "Disposition Animals": {
      en_US: "Disposition Animals",
      es_ES: "Disposición de animales",
    },
    "Head Counts": {
      en_US: "Head Counts",
      es_ES: "Head Counts",
    },
    Login: {
      en_US: "Login",
      es_ES: "Login",
    },
    Offspring: {
      en_US: "Offspring",
      es_ES: "Offspring",
    },
  },
  row: {
    en_US: "Row",
    es_ES: "Fila",
  },
  Row: {
    en_US: "Row",
    es_ES: "Fila",
  },
  Route: {
    en_US: "Route",
    es_ES: "Ruta",
  },
  "rows#": {
    en_US: "Rows #",
    es_ES: "# Filas",
  },
  Sale: {
    en_US: "Sale",
    es_ES: "Venta",
  },
  "Sale Date": {
    en_US: "Sale Date",
    es_ES: "Sale Date",
  },
  "Sales Commission": {
    en_US: "Sales Commission",
    es_ES: "Sales Commission",
  },
  salesCommissionRate: {
    en_US: "Sales Commission Rate",
    es_ES: "Sales Commission Rate",
  },
  save: {
    en_US: "Save",
    es_ES: "Guardar",
  },
  "Save New": {
    en_US: "Save New",
    es_ES: "Guardar Nuevo",
  },
  Saved: {
    en_US: "Saved",
    es_ES: "Guardado",
  },
  search: {
    en_US: "Search",
    es_ES: "Buscar",
  },
  Scale: {
    en_US: "Scale",
    es_ES: "Escala",
  },
  Select: {
    en_US: "Select",
    es_ES: "Seleccionar",
  },
  semen: {
    en_US: "Semen",
    es_ES: "Semen",
  },
  sendToArcher: {
    en_US: "Send to Archer",
    es_ES: "Send to Archer",
  },
  serialNumber: {
    en_US: "Serial Number",
    es_ES: "Número de serie",
  },
  serviceDateIn: {
    en_US: "Service Date In",
    es_ES: "Service Date In",
  },
  serviceDateOut: {
    en_US: "Service Date Out",
    es_ES: "Service Date Out",
  },
  serviceDays: {
    en_US: "Service Days",
    es_ES: "Días de servicio",
  },
  "Set Receiving Ranch": {
    en_US: "Set Receiving Ranch",
    es_ES: "Configurar rancho receptor",
  },
  sex: {
    en_US: "Sex",
    es_ES: "Sexo",
  },
  sexes: {
    en_US: "Sexes",
    es_ES: "Sexos",
  },
  sick: {
    en_US: "Sick",
    es_ES: "Enfermo",
  },
  sickAnimals: {
    en_US: "Sick Animals",
    es_ES: "Animales enfermos",
  },
  sickList: {
    en_US: "Sick List",
    es_ES: "Lista de enfermos",
  },
  sicknessThreshold: {
    en_US: "Threshold",
    es_ES: "Límite",
  },
  signInForm: {
    password: {
      en_US: "Password",
      es_ES: "Contraseña",
    },
    passwordRequired: {
      en_US: "Password is required",
      es_ES: "Contraseña requirida",
    },
    signIn: {
      en_US: "Sign In",
      es_ES: "Sign In",
    },
    username: {
      en_US: "Username",
      es_ES: "Nombre usario",
    },
    usernameRequired: {
      en_US: "Username is required",
      es_ES: "Nombre usario requirido",
    },
  },
  simental: {
    en_US: "Simental",
    es_ES: "Simental",
  },
  simentalX: {
    en_US: "Simental X",
    es_ES: "Simental X",
  },
  sire: {
    en_US: "Sire",
    es_ES: "Semental",
  },
  sireAnimalIds: {
    en_US: "Sire",
    es_ES: "Semental",
  },
  sireTags: {
    en_US: "Sire Tags",
    es_ES: "Etiquetas del semental",
  },
  size: {
    en_US: "Size",
    es_ES: "Tamaño",
  },
  sizeUnits: {
    config: {
      type: "string",
    },
    en_US: "Size Units",
    es_ES: "Unidad del tamaño",
  },
  Skipped: {
    en_US: "Skipped",
    es_ES: "Omitido",
  },
  sold: {
    en_US: "Sold",
    es_ES: "Vendido",
  },
  Source: {
    en_US: "Source",
    es_ES: "Source",
  },
  species: {
    en_US: "Species",
    es_ES: "Especies",
  },
  std_dev: {
    en_US: "Std. dev",
    es_ES: "Stan. dev.",
  },
  "Standard Deviation": {
    en_US: "Standard Deviation",
    es_ES: "Desviación Estándar",
  },
  standardDeviations: {
    en_US: "Standard Deviations",
    es_ES: "Desviaciones Estandares",
  },
  standardDevTripsToWater: {
    en_US: "Standard Deviations Trips to Water",
    es_ES: "Standard Deviations Trips to Water",
  },
  startDate: {
    en_US: "Start Date",
    es_ES: "Fecha de comienzo",
  },
  Statistics: {
    en_US: "Statistics",
    es_ES: "Estadística",
  },
  stats: {
    en_US: "Stats",
    es_ES: "Stats",
  },
  status: {
    en_US: "Status",
    es_ES: "Estado",
  },
  Status: {
    en_US: "Status",
    es_ES: "Estado",
  },
  "Status Filter": {
    en_US: "Status Filter",
    es_ES: "Filtrar por Estado",
  },
  statusList: {
    en_US: "Status",
    es_ES: "Estado",
  },
  steers: {
    en_US: "Steers",
    es_ES: "Novillos",
  },
  stolen: {
    en_US: "Stolen",
    es_ES: "Robado",
  },
  straw: {
    en_US: "Straw",
    es_ES: "Paja",
  },
  submit: {
    en_US: "Submit",
    es_ES: "Entregar",
  },
  submitPayment: {
    en_US: "Submit Payment",
    es_ES: "Enviar pago",
  },
  summaries: {
    en_US: "Summaries",
    es_ES: "Resúmenes",
  },
  switch: {
    en_US: "Switch",
    es_ES: "Cambiar",
  },
  switchedOrganization: {
    en_US:
      "You have changed organizations. Please refresh the page or change back before making any changes on this page.",
    es_ES:
      "You have changed organizations. Please refresh the page or change back before making any changes on this page.",
  },
  switchOrganization: {
    en_US: "Switch Organization",
    es_ES: "Cambiar organizatión",
  },
  tabletMode: {
    false: {
      en_US: "Tablet Mode (on)",
      es_ES: "Tablet Mode (on)",
    },
    true: {
      en_US: "Tablet Mode (off)",
      es_ES: "Tablet Mode (off)",
    },
  },
  tag: {
    en_US: "Tag",
    es_ES: "Etiqueta",
  },
  "Tag Color": {
    en_US: "Tag Color",
    es_ES: "Tag Color",
  },
  tagColors: {
    en_US: "Tag Colors",
    es_ES: "Colores de etiquetas",
  },
  Tags: {
    en_US: "Tags",
    es_ES: "Etiquetas",
  },
  "Tag IDs": {
    en_US: "Tag IDs",
    es_ES: "Tag IDs",
  },
  tagLocation: {
    en_US: "Tag Location",
    es_ES: "Ubicación de etiqueta",
  },
  tagLot: {
    en_US: "Lot #",
    es_ES: "# Lote",
  },
  tags: {
    bagCost: {
      en_US: "$ / Bag",
      es_ES: "$ / Bag",
    },
    bagQuantity: {
      en_US: "Qty in Bag",
      es_ES: "Qty in Bag",
    },
    color: {
      en_US: "Color",
      es_ES: "Color",
    },
    cost: {
      en_US: "$ / Tag",
      es_ES: "$ / Etiqueta",
    },
    friendlyName: {
      en_US: "Friendly Name",
      es_ES: "Nombre amigable",
    },
    id: {
      en_US: "id",
      es_ES: "id",
    },
    manufacturer: {
      en_US: "Manufacturer",
      es_ES: "Fabricante",
    },
    name: {
      en_US: "Name",
      es_ES: "Nombre",
    },
    organizationId: {
      en_US: "organizationId",
      es_ES: "organizationId",
    },
    size: {
      en_US: "Size",
      es_ES: "Tamaño",
    },
    status: {
      en_US: "Order Status",
      es_ES: "Estado del orden",
    },
    tagList: {
      en_US: "Tag List",
      es_ES: "Lista de etiqueta",
    },
    type: {
      en_US: "Type",
      es_ES: "Tipo",
    },
  },
  "Tag Summary": {
    en_US: "Tag Summary",
    es_ES: "Resumen de Tags",
  },
  "Tag Table": {
    en_US: "Tag Table",
    es_ES: "Tag Table",
  },
  "Tag IDs": {
    en_US: "Tag IDs",
    es_ES: "Tag IDs",
  },
  tagValue: {
    en_US: "Tag Value",
    es_ES: "Valor de etiqueta",
  },
  tattooTagValues: {
    en_US: "Tattoos",
    es_ES: "Tatuajes",
  },
  technician: {
    en_US: "Technician",
    es_ES: "Técnico",
  },
  temperature: {
    en_US: "Temperature",
    es_ES: "Temperatura",
  },
  test: {
    en_US: "Test",
    es_ES: "Prueba",
  },
  testedBy: {
    en_US: "Tested By",
    es_ES: "Probado Por",
  },
  testMethod: {
    en_US: "Test Method",
    es_ES: "Método de prueba",
  },
  texts: {
    close: {
      en_US: "Close",
      es_ES: "Cerrar",
    },
    emptyData: {
      en_US: "No data available",
      es_ES: "No hay data disponible",
    },
    search: {
      en_US: "Search",
      es_ES: "Buscar",
    },
  },
  "Through the Chute": {
    en_US: "Through the Chute",
    es_ES: "Través del conducto",
  },
  time: {
    en_US: "Time",
    es_ES: "Hora",
  },
  "Time Of Day": {
    en_US: "Time Of Day",
    es_ES: "Tiempo del día",
  },
  "Time in Field": {
    en_US: "Time in Field",
    es_ES: "Time in Field",
  },
  timeRecorded: {
    en_US: "Effective Date",
    es_ES: "Fecha efectiva",
  },
  timezone: {
    en_US: "Timezone",
    es_ES: "Zona horaria",
  },
  To: {
    en_US: "To",
    es_ES: "Hacia",
  },
  "Total Live Calves": {
    en_US: "Total Live Calves",
    es_ES: "Total de Terneros Vivos",
  },
  totalAnimalsInPen: {
    en_US: "Total Animals in Pen",
    es_ES: "Total de animales en corral",
  },
  totalAnimalsReadDate: {
    en_US: "Total Animals that were Read on Date",
    es_ES: "Total de animales que se leyeron en la fecha",
  },
  totalEventIncome: {
    en_US: "Total Event Income",
    es_ES: "Ingresos totales del evento",
  },
  totalHead: {
    en_US: "Total Head",
    es_ES: "Altura total",
  },
  totalReadsPerDay: {
    en_US: "Total Reads Per Day",
    es_ES: "Lecturas totales por día",
  },
  "Trait Name": {
    en_US: "Trait Name",
    es_ES: "Nombre del rasgo",
  },

  transactionDate: {
    en_US: "Transaction Date",
    es_ES: "Fecha de Transacción",
  },
  transactionType: {
    en_US: "Transaction Type",
    es_ES: "Tipo de transacción",
  },
  transferAnimals: {
    en_US: "Transfer Animals",
    es_ES: "Transferir animales",
  },
  "Transfer Out Time": {
    en_US: "Transfer Out Time",
    es_ES: "Transfer Out Time",
  },
  transferred: {
    en_US: "Transferred",
    es_ES: "Transferido",
  },
  treatment: {
    en_US: "Treatment",
    es_ES: "Tratamiento",
  },
  treatmentHistory: {
    en_US: "Treatment History",
    es_ES: "Historial de tratamiento",
  },
  treatmentInput: {
    en_US: "Treatment Input",
    es_ES: "Entrada de tratamiento",
  },
  "Treatment Time": {
    en_US: "Treatment Time",
    es_ES: "Treatment Time",
  },
  trich: {
    en_US: "Trich",
    es_ES: "Trich",
  },
  tuberculosis: {
    en_US: "Tuberculosis",
    es_ES: "Tuberculosis",
  },
  type: {
    en_US: "Type",
    es_ES: "Tipo",
  },
  ultrasound: {
    en_US: "Ultrasound",
    es_ES: "Ultrasonido",
  },
  units: {
    en_US: "Units",
    es_ES: "Unidades",
  },
  Unknown: {
    config: {
      type: "string",
    },
    en_US: "Unknown/Ignore",
    es_ES: "Desconocido",
  },
  update: {
    en_US: "Update",
    es_ES: "Actualizar",
  },
  Updated: {
    en_US: "Updated",
    es_ES: "Actualizado",
  },
  updateDatabase: {
    en_US: "Update Database",
    es_ES: "Actualizar base de datos",
  },
  "Use date": {
    en_US: "Use date",
    es_ES: "Usar fecha",
  },
  useGroupHistoryToDelete: {
    en_US: "Use group history to delete group number.",
    es_ES: "Use historia de grupos para borrar un numero de grupo.",
  },
  user: {
    en_US: "User",
    es_ES: "Usario",
  },
  users: {
    en_US: "Users",
    es_ES: "Usarios",
  },
  vacciDateTime: {
    en_US: "Vaccination Date and Time",
    es_ES: "Fecha y hora de vacunación",
  },
  vaccinationName: {
    en_US: "Vaccination Name",
    es_ES: "Nombre de la vacuna",
  },
  Pedigree: {
    en_US: "Pedigree",
    es_ES: "Pedigree",
  },
  Plant: {
    en_US: "Plant",
    es_ES: "Planta",
  },
  purchasePriceItem: {
    en_US: "Purchase Price/Type",
    es_ES: "Precio de compra/Tipo",
  },
  vaccinationsList: {
    en_US: "Treatments",
    es_ES: "Tratamientos",
  },
  vaccineLotNumber: {
    en_US: "Vaccine Lot Number",
    es_ES: "Vaccine Lot Number",
  },
  vaccType: {
    en_US: "Vaccination Type",
    es_ES: "Tipo de vacuna",
  },
  value: {
    en_US: "Value",
    es_ES: "Valor",
  },
  vetTech: {
    en_US: "Vet/Tech",
    es_ES: "Veterinario/Técnico",
  },
  vibrio: {
    en_US: "Vibrio",
    es_ES: "Vibrio",
  },
  view: {
    en_US: "View",
    es_ES: "Ver",
  },
  Vigor: {
    en_US: "Calf Vigor",
    es_ES: "Vigor de becerro",
  },
  "Vigor Fair": {
    en_US: "Vigor Fair",
    es_ES: "Poco Vigor",
  },
  "Vigor Good": {
    en_US: "Vigor Good",
    es_ES: "Buen Vigor",
  },
  visual: {
    en_US: "Visual",
    es_ES: "Visual",
  },
  Visuals: {
    en_US: "Visuals",
    es_ES: "Visuales",
  },
  "Visual Colors": {
    en_US: "Visual Colors",
    es_ES: "Colores",
  },
  visualID: {
    en_US: "Visual ID",
    es_ES: "ID Visual",
  },
  visualIDs: {
    en_US: "Visual IDs",
    es_ES: "Visual IDs",
  },
  visualTagName: {
    en_US: "Individual Tags (Visual)",
    es_ES: "Etiquetas individuas (Visuales)",
  },
  visualTagValue: {
    en_US: "Visual ID",
    es_ES: "Visual ID",
  },
  visualTagValues: {
    en_US: "Visual IDs",
    es_ES: "Visual IDs",
  },
  Wean: {
    en_US: "Wean",
    es_ES: "Destete",
  },
  "Wean Report": {
    en_US: "Wean Report",
    es_ES: "Informe de destete",
  },
  weanOrigins: {
    en_US: "Wean Origins",
    es_ES: "Orígenes del destete",
  },
  weanReport: {
    en_US: "Wean Report",
    es_ES: "Informe de destete",
  },
  weanSexes: {
    config: {
      type: "string",
    },
    en_US: "Wean Sexes",
    es_ES: "Sexos de destete",
  },
  "Weather ID": {
    en_US: "Weather ID",
    es_ES: "Weather ID",
  },
  weanWeight: {
    en_US: "Wean Weight",
    es_ES: "Peso de destete",
  },
  weather: {
    en_US: "Weather",
    es_ES: "Weather",
  },
  weatherWhenPregChecked: {
    en_US: "Weather when preg checked",
    es_ES: "Weather when preg checked",
  },
  weight: {
    en_US: "Weight",
    es_ES: "Peso",
  },
  WeightDate: {
    en_US: "Weight Date",
    es_ES: "Peso Fecha",
  },
  weightHistory: {
    en_US: "Weight History",
    es_ES: "Historial de peso",
  },
  weightUnits: {
    en_US: "Weight Units",
    es_ES: "Unidades de peso",
  },
  welcome: {
    en_US: "Welcome",
    es_ES: "Bienvenido",
  },
  welcomeFH: {
    en_US: "Welcome to Fast Herd from Fort Supply Technologies.",
    es_ES: "Bienvenido a Fast Herd de Fort Supply Technologies.",
  },
  withdrawlTime: {
    en_US: "Withdrawl Time",
    es_ES: "Tiempo de retiro",
  },
  withdrawlTimeUnits: {
    en_US: "Units",
    es_ES: "Unidad de tiempo",
  },
  year: {
    en_US: "Year",
    es_ES: "Año",
  },
  yes: {
    en_US: "Yes",
    es_ES: "Si",
  },
  Yes: {
    en_US: "Yes",
    es_ES: "Si",
  },
  "Yield Grade": {
    en_US: "Yield Grade",
    es_ES: "Grado de rendimiento",
  },
};
