<template>
  <v-sheet class="rounded-lg mb-4" elevation="5">
    <div class="pt-4 px-2 pb-1 d-flex justify-center" v-if="onlyCenterHeader">
      <div class="d-flex flex-column align-center">
        <slot name="top-header"></slot>
        <slot name="header">
          <h4 class="ma-0 text-center">
            <span class="red--text mr-2" v-if="isCull">Cull</span>
            {{ headerTitle }}
          </h4>
        </slot>
        <div class="w-100 h-auto">
          <slot name="bottom-header"></slot>
        </div>
      </div>
    </div>
    <div
      :style="{ minHeight: headerHeight + 'px' }"
      class="d-flex flex-wrap justify-center align-center px-4"
      style="max-height: 500px; height: 100px"
      v-else
    >
      <v-row>
        <v-col class="pa-0 pl-2 pr-2 pl-lg-4" cols="4">
          <slot name="prepend-header"> </slot>
        </v-col>
        <v-col class="pa-0" cols="4">
          <div class="d-flex flex-column align-center">
            <slot name="top-header"></slot>
            <slot name="header">
              <h4 class="ma-0 text-center">
                {{ headerTitle }}
                <v-btn
                  class="white--text text-decoration-none"
                  color="success"
                  depressed
                  min-width="40"
                  style="font-size: 1.3rem; font-weight: bold"
                  v-if="count !== null"
                >
                  {{ count }}
                </v-btn>
              </h4>
            </slot>
            <slot name="bottom-header"></slot>
          </div>
        </v-col>
        <v-col class="pa-0 pl-2 pr-2 pr-lg-4" cols="4">
          <slot name="append-header"> </slot>
        </v-col>
      </v-row>
    </div>
    <v-divider class="mt-0"></v-divider>
    <div class="pa-8 pb-6 pt-4">
      <slot name="content">
        <p>Here goes your content</p>
      </slot>
    </div>
    <slot name="footer"> </slot>
  </v-sheet>
</template>
<script>
export default {
  name: "summary-container",
  props: {
    count: {
      default: null,
      type: Number,
    },
    headerHeight: {
      default: 80,
      type: Number,
    },
    headerTitle: {
      default: "",
      type: String,
    },
    isCull: {
      default: false,
      type: Boolean,
    },
    onlyCenterHeader: {
      default: false,
      type: Boolean,
    },
  },
};
</script>