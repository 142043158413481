<template>
  <summary-container
    :headerHeight="86"
    :headerTitle="getTranslation('pregCheckPage.summary.name')"
    :style="{ height: customHeight + 'px', width: customWidth + 'px' }"
  >
    <template #bottom-header>
      <v-row class="ma-0">
        <v-subheader>{{
          getTranslation("pregCheckPage.summary.description")
        }}</v-subheader>
      </v-row>
    </template>
    <template #content>
      <v-row>
        <v-col>
          <v-row class="justify-end">
            <v-col cols="12" lg="3">
              <v-text-field
                clearable
                hide-details
                label="Search"
                prepend-icon="mdi-magnify"
                single-line
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="summaryTableHeaders"
                :items-per-page="5"
                :items="summaryTableData"
                :loading="loading"
                :search="search"
                :sort-by="['pregCheckTime']"
                :sort-desc="[true]"
                mobile-breakpoint="0"
              >
                <template #item.tagValues="{ item }">
                  <router-link
                    :to="{
                      name: 'AnimalDetails',
                      query: { id: item.animalId },
                    }"
                    class="text-h6 text-none"
                    target="_blank"
                  >
                    {{ item.tagValues }}
                  </router-link>
                </template>
                <template #item.pregCheckTime="{ item }">
                  {{
                    $utils.renderValueAs(item.pregCheckTime, "datetime", true)
                  }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </summary-container>
</template>
<script>
import { EventBus } from "../../mixins/Config";
import SummaryContainer from "../SummaryContainer";
import TranslationMixin from "../../mixins/Translations";

export default {
  name: "preg-check-summary",
  mixins: [TranslationMixin],
  props: {
    customHeight: {
      type: Number,
    },
    customWidth: {
      type: Number,
    },
  },
  components: {
    "summary-container": SummaryContainer,
  },
  data: () => ({
    loading: false,
    search: "",
    summaryTableData: [],
  }),
  computed: {
    summaryTableHeaders: function () {
      return [
        {
          text: this.getTranslation("pregCheckPage.summary.tagValues"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("Cycle"),
          value: "cycle",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.daysBred"),
          value: "daysBred",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.color"),
          value: "color",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.currentWeight"),
          value: "currentWeight",
        },
        {
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.technician"),
          value: "technician",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.pregCheckComment"),
          value: "comment",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.pregCheckMethod"),
          value: "method",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.pregCheckTime"),
          value: "pregCheckTime",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.fetalSex"),
          value: "fetalSex",
        },
        {
          text: this.getTranslation("pregCheckPage.summary.status"),
          value: "status",
        },
      ];
    },
  },
  created: function () {
    this.getSummaryTable();
    EventBus.$on("new-preg-check", () => {
      console.log("Event activated");
      this.$herdMeta.reload().fail().then(this.getSummaryTable);
    });
  },
  methods: {
    getSummaryTable: function () {
      this.loading = true;

      this.$herdMeta.pouches.organization
        .query("local_views/groupPregCheckResults", {
          group_level: 1,
        })
        .then((results) => {
          this.summaryTableData = results.rows
            .map((result) => result.value)
            .map((result) => {
              // Map in values that is primarily in main summary
              result.pregCheck.animalId = result.main.animalId;
              result.pregCheck.breed = result.main.breed;
              result.pregCheck.color = result.main.color;
              result.pregCheck.currentWeight = result.main.currentWeight;
              result.pregCheck.location = result.main.location;
              result.pregCheck.status = result.main.status;
              result.pregCheck.tagValues = result.main.tagValues;
              return result.pregCheck;
            });
          this.loading = false;
        })
        .catch();
    },
  },
};
</script>