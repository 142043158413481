<template>
  <summary-card
    :total="summaries[interval].total"
    link="/report-calf-wean-summary"
    title="Births"
  >
    <template #append-title>
      <div class="d-flex ml-2">
        <v-select
          :items="intervals"
          class="dashboard-select mr-4 rounded-lg w-32"
          dense
          elevation="0"
          flat
          hide-details="true"
          label=""
          solo
          v-model="interval"
        />
        <div class="align-center d-flex justify-end w-12">
          <v-tooltip
            left
            v-if="summaries[interval].countFemalesMissingBirthdates > 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex justify-center lighten-4 mr-1 rounded-circle warning"
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-exclamation
              </v-icon>
            </template>
            <span>
              Some animals are missing a birth date. ({{
                summaries[interval].countFemalesMissingBirthdates
              }})
            </span>
          </v-tooltip>
          <v-tooltip left v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex justify-center lighten-4 mr-1 rounded-circle success"
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>No errors/warning detected during computation.</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex grey justify-center lighten-4 mr-1 rounded-circle"
                color="grey"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              Data includes births, newly created records, and transfers.
              Maximum is defined as number of females head over 20 months old.
            </span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </summary-card>
</template>


<script>
const intervals = ["Today", "7 Day", "30 Days", "Year"];

export default {
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      interval: "30 Days",
      intervals,
      summaries: {
        Today: {
          countFemalesMissingBirthdates: 0,
          total: null,
        },
        "7 Day": {
          countFemalesMissingBirthdates: 0,
          total: null,
        },
        "30 Days": {
          countFemalesMissingBirthdates: 0,
          total: null,
        },
        Year: {
          countFemalesMissingBirthdates: 0,
          total: null,
        },
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      // X is animals that were born (or transferred shortly after birth)
      // Y is available females (over 20 months old, female)
      const birthDateEnd = moment()
        .startOf("day")
        .subtract(20, "months")
        .toISOString();
      const animals = (
        await this.pouches.organization.animalSummaries(false, false, {
          group_level: 1,
          include_docs: false,
        })
      ).rows
        .map(({ value }) => value)
        .map(({ main, pregCheck }) => ({
          ...main,
          pregCheck:
            pregCheck &&
            (pregCheck.pregCheckTime ||
              pregCheck.timeRecorded ||
              pregCheck.createdOn ||
              pregCheck.bullTurninDate)
              ? {
                  cycle: pregCheck.cycle,
                  predictedCalvingDate: pregCheck.predictedCalvingDate,
                  result: pregCheck.result,
                }
              : null,
        }))
        .map(({ birthDate, gender, pregCheck, status }) => ({
          birthDate,
          hasBirthDate: !!birthDate,
          hasBirthingAge: birthDate < birthDateEnd,
          isFemale: pregCheck || (gender || "").toLowerCase() === "female",
          isStatusPresent: ["alive", "cull", "marketable", "sick"].some(
            (s) => s === (status || "alive").toLowerCase()
          ),
          pregCheck,
        }));

      const thresholdStart = moment().startOf("day").toISOString();

      this.intervals.forEach((interval) => {
        const intervalType =
          interval === "7 Day" || interval === "30 Days" ? "day" : interval;
        const intervalQty =
          interval === "7 Day" ? 7 : interval === "30 Days" ? 30 : 1;

        const friendlyQtyInterval =
          (intervalType === "day" || intervalType === "Today"
            ? intervalQty + " " + "day"
            : intervalQty + " year") + (intervalQty > 1 ? "s" : "");

        const thresholdEnd = moment()
          .add(intervalQty, intervalType)
          .toISOString();

        const upcomingBirths = animals.filter(
          ({ isStatusPresent, pregCheck }) =>
            pregCheck &&
            pregCheck.result === "Pregnant" &&
            pregCheck.predictedCalvingDate &&
            pregCheck.predictedCalvingDate >= thresholdStart &&
            pregCheck.predictedCalvingDate <= thresholdEnd &&
            isStatusPresent
        );

        const countFemalesMissingBirthdates = animals.filter(
          ({ hasBirthDate, isFemale }) => !hasBirthDate && isFemale
        ).length;

        this.summaries[interval] = {
          countFemalesMissingBirthdates,
          total: `${upcomingBirths.length} expected in next ${friendlyQtyInterval}`,
        };
      });
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>

<style scoped>
.w-8 {
  width: 2.5rem;
}
.w-32 {
  width: 8rem;
}
</style>