const initialState = () => ({
  data: {
    age: " ",
    birthDate: " ",
    birthRanch: " ",
    birthVisual: " ",
    birthWeight: " ",
    breed: " ",
    calvingScore: " ",
    comment: " ",
    dam: {
      birthDate: null,
      breed: " ",
    },
    damId: null,
    damTags: null,
    guid: null,
    implantNumber: "TBD",
    sire: {
      birthDate: null,
      breed: null,
    },
    sireId: null,
    sireTags: null,
    status: " ",
    tagValues: "? / ?",
    tsu: " ",
    vigor: " ",
    weanDate: " ",
  },
});

// state
const state = initialState();

// getters
const getters = {
  getGuid: (state) => state.data.guid,
  getInformation: (state) => state.data,
  getStatus: (state) => state.data.status,
  getTagValues: (state) => state.data.tagValues,
};

// actions
const actions = {
  reset: ({ commit }) => {
    commit("RESET");
  },
  setInformation: (
    { commit },
    {
      age,
      birthDate,
      birthRanch,
      birthVisual,
      birthWeight,
      breed,
      calvingScore,
      comment,
      dam,
      damId,
      damTags,
      guid,
      implantNumber,
      sire,
      sireId,
      sireTags,
      status,
      tagValues,
      tsu,
      vigor,
      weanDate,
    }
  ) => {
    commit("setInformation", {
      age: Animal.getAgeOfTimestamp(birthDate, {
        ignoreDays: true,
        ignoreHours: true,
      }),
      birthDate: birthDate || " ",
      birthRanch: birthRanch || " ",
      birthVisual: birthVisual || " ",
      birthWeight: birthWeight || " ",
      breed: breed || " ",
      calvingScore: calvingScore || " ",
      comment: comment || " ",
      dam,
      damId,
      damTags,
      guid,
      implantNumber: implantNumber || " ",
      sire,
      sireId,
      sireTags,
      status,
      tagValues: tagValues || " ",
      tsu: tsu || " ",
      vigor: vigor || " ",
      weanDate: weanDate || " ",
    });
  },
};

// mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setInformation(
    state,
    {
      age,
      birthDate,
      birthRanch,
      birthVisual,
      birthWeight,
      breed,
      calvingScore,
      comment,
      dam,
      damId,
      damTags,
      guid,
      implantNumber,
      sire,
      sireId,
      sireTags,
      status,
      tagValues,
      tsu,
      vigor,
      weanDate,
    }
  ) {
    state.data = {
      age,
      birthDate,
      birthRanch,
      birthVisual,
      birthWeight,
      breed,
      calvingScore,
      comment,
      dam,
      damId,
      damTags,
      guid,
      implantNumber,
      sire,
      sireId,
      sireTags,
      status,
      tagValues,
      tsu,
      vigor,
      weanDate,
    };
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
