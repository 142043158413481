<template>
  <summary-card link="/animals" title="Total head" :total="total">
    <template #append-title>
      <div class="d-flex ml-2">
        <div class="align-center d-flex justify-end w-8">
          <v-tooltip left v-if="countMissingBirthdates > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  justify-center
                  lighten-4
                  mr-1
                  rounded-circle
                  warning
                "
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-exclamation
              </v-icon>
            </template>
            <span>
              Some animals are missing a birth date. ({{
                countMissingBirthdates
              }})
            </span>
          </v-tooltip>
          <v-tooltip left v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  justify-center
                  lighten-4
                  mr-1
                  rounded-circle
                  success
                "
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>No errors/warning detected during computation.</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  grey
                  justify-center
                  lighten-4
                  mr-1
                  rounded-circle
                "
                color="grey"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span
              >Data shows head with statuses: alive, cull, sick, or
              marketable.</span
            >
          </v-tooltip>
        </div>
      </div>
    </template>
    <template #append-content>
      <v-chip class="green green--text ml-auto lighten-5" v-if="delta >= 0">
        +{{ delta }} in the last 30 days
      </v-chip>
      <v-chip class="lighten-5 ml-auto red red--text" v-else>
        {{ delta }} in the last 30 days
      </v-chip>
    </template>
  </summary-card>
</template>

<script>
export default {
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      countMissingBirthdates: 0,
      delta: null,
      total: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const threshold = moment()
        .startOf("day")
        .subtract(30, "days")
        .toISOString();

      const animals = (
        await this.pouches.organization.animalSummaries(false, false, {
          group_level: 1,
          include_docs: false,
        })
      ).rows
        .map(({ value }) => value)
        .map(({ main }) => main)
        .map(({ birthDate, deathDate, status }) => ({
          isNew:
            // only count new records if birthDate is not present
            birthDate >= threshold &&
            // only count if they did not die after threshold
            !deathDate,
          hasBirthDate: !!birthDate,
          // TODO: Include other values in removed
          // recently removed include: recent death, lost, in transit, or sold
          isRecentlyRemoved: deathDate && deathDate >= threshold,
          isStatusPresent: ["alive", "cull", "marketable", "sick"].some(
            (s) => s === (status || "alive").toLowerCase()
          ),
        }));

      const animalsPresent = animals.filter(
        ({ isStatusPresent }) => isStatusPresent
      );
      this.countMissingBirthdates = animalsPresent.filter(
        ({ hasBirthDate }) => !hasBirthDate
      ).length;
      this.total = animalsPresent.length;

      this.delta =
        animals.filter(({ isNew }) => isNew).length -
        animals.filter(({ isRecentlyRemoved }) => isRecentlyRemoved).length;
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>

<style scoped>
.w-8 {
  width: 2.5rem;
}
.w-32 {
  width: 8rem;
}
</style>