<template>
  <div
    class="g-row ma-0 pr-2"
    :style="{
      'padding-left': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
        ? '0px'
        : '60px',
    }"
  >
    <div class="g-col pl-2 pt-2">
      <v-sheet class="pa-6" elevation="3">
        <h4 class="text-h5 font-weight-bold mb-4">Legend</h4>
        Use this page as a kind of legend in which the concepts covered in
        fast-herd are shown, as well as the respective data type and length of
        each one. This provides a greater understanding of how fast-herd works
        as well as a useful tool for processing data in your own database.
      </v-sheet>
      <br />
      <v-tabs grow show-arrows v-model="categorySelected">
        <v-tab :key="idx" v-for="(category, idx) in categories">
          {{ category }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="categorySelected">
        <v-tab-item
          class="pt-3"
          :key="idx"
          v-for="(category, idx) in categories"
        >
          <div class="d-flex justify-space-between">
            <export-button
              :filename="`Legend_${category}_${new Date().toISOString()}`"
              :headers="tableHeaders"
              :items="table[category]"
            />
            <v-text-field
              class="custom-field"
              clearable
              dense
              label="Search"
              outlined
              style="max-width: 200px"
              v-model="table.search"
            />
          </div>
          <v-data-table
            :headers="tableHeaders"
            :items="table[category]"
            :search="table.search"
            class="dashboard-data-table"
            mobile-breakpoint="0"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
import { mapGetters } from "vuex";
export default {
  name: "Legend",
  metaInfo: {
    title: "Legend",
  },
  mixins: [TranslationMixin],
  data: () => ({
    categorySelected: 1,
    categories: ["mysql", "couch"],
    table: {
      mysql: [],
      couch: [],
      search: null,
    },
  }),
  computed: {
    ...mapGetters({
      getToken: "User/getToken",
    }),
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("Trait Name"),
          value: "traitName",
        },
        {
          text: this.getTranslation("Definition"),
          value: "definition",
        },
        {
          text: this.getTranslation("Data Type"),
          value: "dataType",
        },
        {
          text: this.getTranslation("Length"),
          value: "length",
        },
      ];
    },
  },
  created: async function () {
    try {
      // Get mysql fields
      const legend = await axios.get("/api/users/legend", {
        params: {
          token: this.getToken,
        },
      });
      this.table.mysql = legend.data;
      // Get pouch db fields
      this.table.couch = this.getPouchDBFields();
    } catch (error) {
      if (error.response) console.log(error.response.data);
      else console.log(error);
      this.table.data = [];
      this.$notify({
        group: "forms",
        text: "The server does not retrieve the data correctly. Please reload.",
        title: "Error",
        type: "error",
      });
    }
  },
  methods: {
    getPouchDBFields: function () {
      // Get pouch db fields
      const pouchDBFields = [];
      this.$utils.getAllPouchDBFields().forEach((item) => {
        const key = item.value;
        if (!pouchDBFields.find((item) => item.traitName === key)) {
          const integers = [
            "size",
            "gps",
            "weight",
            "price",
            "cost",
            "number",
            "dose",
          ];
          const varchars = ["tedby"];
          const timeFields = ["datetime", "time", "date"];
          let dataType = varchars.filter(
            (varcharField) => !!key.toLowerCase().includes(varcharField)
          ).length
            ? "varchar"
            : integers.filter(
                (numberField) => !!key.toLowerCase().includes(numberField)
              ).length
            ? "int"
            : timeFields.filter(
                (timeField) => !!key.toLowerCase().includes(timeField)
              ).length
            ? "datetime"
            : "varchar";
          pouchDBFields.push({ traitName: key, dataType, ...item });
        }
      });
      return pouchDBFields;
    },
  },
};
</script>
