import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify";
import Actions from "../components/icons/Actions.vue";
import Animals from "../components/icons/Animals.vue";
import Bell from "../components/icons/Bell.vue";
import Bull from "../components/icons/Bull.vue";
import Calf from "../components/icons/Calf.vue";
import CalfCow from "../components/icons/CalfCow.vue";
import Clock from "../components/icons/Clock.vue";
import Cloud from "../components/icons/Cloud.vue";
import Comment from "../components/icons/Comment.vue";
import Cow from "../components/icons/Cow.vue";
import CowCalf from "../components/icons/CowCalf.vue";
import Dam from "../components/icons/Dam.vue";
import Dashboard from "../components/icons/Dashboard.vue";
import EIDtag from "../components/icons/EIDtag.vue";
import EIDtagFS from "../components/icons/EIDtagFS.vue";
import HeadBlueWithTag from "../components/icons/HeadBlueWithTag.vue";
import HeadWithTag from "../components/icons/HeadWithTag.vue";
import Heifer from "../components/icons/Heifer.vue";
import HorizontalLogo from "../components/icons/HorizontalLogo.vue";
import IconRight from "../components/icons/IconRight.vue";
import Land from "../components/icons/Land.vue";
import LocationMarker from "../components/icons/LocationMarker.vue";
import Magnifier from "../components/icons/Magnifier.vue";
import MeatSlice from "../components/icons/MeatSlice.vue";
import Medicine from "../components/icons/Medicine.vue";
import MenuCollapse from "../components/icons/MenuCollapse.vue";
import Offspring from "../components/icons/Offspring.vue";
import PaintBrush from "../components/icons/PaintBrush.vue";
import PastureOrPen from "../components/icons/PastureOrPen.vue";
import Pedigree from "../components/icons/Pedigree.vue";
import PedigreeBlue from "../components/icons/PedigreeBlue.vue";
import PedigreeConnector from "../components/icons/PedigreeConnector.vue";
import Pencil from "../components/icons/Pencil.vue";
import PrinterAlt from "../components/icons/PrinterAlt.vue";
import Reports from "../components/icons/Reports.vue";
import Steer from "../components/icons/Steer.vue";
import Upload from "../components/icons/Upload.vue";
import Wifi from "../components/icons/Wifi.vue";

import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      actions: { component: Actions },
      animals: { component: Animals },
      bell: { component: Bell },
      bull: { component: Bull },
      calf: { component: Calf },
      calfCow: { component: CalfCow },
      clock: { component: Clock },
      cloud: { component: Cloud },
      comment: { component: Comment },
      cow: { component: Cow },
      cowCalf: { component: CowCalf },
      dam: { component: Dam },
      dashboard: { component: Dashboard },
      eidTag: { component: EIDtag },
      eidTagFS: { component: EIDtagFS },
      headBlueWithTag: { component: HeadBlueWithTag },
      headWithTag: { component: HeadWithTag },
      heifer: { component: Heifer },
      horizontalLogo: { component: HorizontalLogo },
      iconRight: { component: IconRight },
      land: { component: Land },
      locationMarker: { component: LocationMarker },
      magnifier: { component: Magnifier },
      meatSlice: { component: MeatSlice },
      medicine: { component: Medicine },
      menuCollapse: { component: MenuCollapse },
      offspring: { component: Offspring },
      paintBrush: { component: PaintBrush },
      pastureOrPen: { component: PastureOrPen },
      pedigree: { component: Pedigree },
      pedigreeBlue: { component: PedigreeBlue },
      pedigreeConnector: { component: PedigreeConnector },
      pencil: { component: Pencil },
      printerAlt: { component: PrinterAlt },
      reports: { component: Reports },
      steer: { component: Steer },
      upload: { component: Upload },
      wifi: { component: Wifi },
    },
  },
  theme: {
    themes: {
      dark: {
        primary: colors.blue,
        secondary: colors.blue.lighten1,
        accent: colors.teal.accent4,
        error: colors.red,
        info: colors.grey.darken3,
        warning: colors.deepOrange.darken1,
      },
    },
  },
});
