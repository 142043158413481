var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 1)?_c('v-sheet',{staticClass:"rounded-lg mb-4",attrs:{"elevation":"5"}},[_c('div',{staticClass:"pt-4 px-2 pb-1 d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_vm._t("top-header"),_vm._t("header",function(){return [_c('h4',{staticClass:"ma-0 text-center"},[(_vm.isCull)?_c('span',{staticClass:"red--text mr-2"},[_vm._v("Cull")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])]}),_c('div',{staticClass:"w-100 h-auto d-flex justify-center"},[_c('v-row',{staticClass:"ma-0 justify-center",staticStyle:{"font-size":"1.8rem"}},[(!_vm.animal)?_c('v-btn',{attrs:{"color":"primary","disabled":"","text":""}},[_c('span',{staticStyle:{"font-size":"1.8rem"},attrs:{"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.information.tagValues)+" ")])]):_c('a',{attrs:{"href":("/animal-details?id=" + (_vm.information.guid)),"target":"_blank"}},[_c('p',{staticClass:"custom-main-tag pa-2 mb-0"},[_vm._v(" "+_vm._s(_vm.information.tagValues)+" ")])])],1),_vm._t("bottom-header")],2)],2)]),_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4 d-flex flex-column flex-wrap",style:({ 'max-height': _vm.maxHeight + 'px' })},[_vm._l((_vm.items),function(item,idx){return [(item.value == 'age')?_c('v-text-field',{key:idx,staticClass:"mb-2",attrs:{"value":!_vm.information[item.value] || !_vm.information.birthDate
              ? ' '
              : _vm.Animal.getAgeOfTimestamp(_vm.information.birthDate),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation(item.labelType))+" ")])]},proxy:true}],null,true)}):_c('v-text-field',{key:idx,staticClass:"mb-2",attrs:{"value":_vm.information[item.value] || ' ',"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation(item.labelType))+" ")])]},proxy:true}],null,true)})]})],2),(_vm.fertilizations.ai)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("AI")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.ai.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.ai.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.ai.sireTags,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("sire"))+" ")])]},proxy:true}],null,false,2968511595)})],1)],1)]:_vm._e(),(_vm.fertilizations.implant)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("CIDR Implant")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.implant.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.implant.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.implant.gnrhReceived
                ? _vm.getTranslation('Yes')
                : _vm.getTranslation('No'),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("GnRH Received"))+" ")])]},proxy:true}],null,false,422604762)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.getTranslation(
                ("enums.fertilizationStatusImplant." + (_vm.fertilizations.implant.statusId))
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("status"))+" ")])]},proxy:true}],null,false,909607666)})],1)],1)]:_vm._e(),(_vm.fertilizations.removal)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("CIDR Removal")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.removal.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.removal.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.removal.gnrhReceived
                ? _vm.getTranslation('Yes')
                : _vm.getTranslation('No'),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("GnRH Received"))+" ")])]},proxy:true}],null,false,422604762)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.getTranslation(
                ("enums.fertilizationStatusRemoval." + (_vm.fertilizations.removal.statusId))
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("status"))+" ")])]},proxy:true}],null,false,909607666)})],1)],1)]:_vm._e()],2):_vm._e(),(_vm.type === 2)?_c('v-card',[_c('v-card-title',{staticClass:"pb-0",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-0"},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.animalInformation),function(prop,idx){return _c('v-list-item',{key:idx},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center subtitle-1"},[_vm._v(" "+_vm._s(_vm.getLabelTranslation(prop.translateWord))+" ")]),_c('v-col',[(prop.guid)?_c('div',[_c('router-link',{staticClass:"subtitle-2 text-none",attrs:{"to":{
                    name: 'AnimalDetails',
                    query: { id: prop.guid },
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(prop.value)+" ")])],1):_c('div',{class:{
                  'cull-status':
                    prop.translateWord == 'status' && prop.value == 'Cull',
                }},[_vm._v(" "+_vm._s(prop.value)+" ")])])],1)],1)}),1)],1)],1):_vm._e(),(_vm.type === 3)?_c('v-sheet',{staticClass:"rounded-lg mb-4",attrs:{"elevation":"5"}},[_c('div',{staticClass:"pt-4 px-2 pb-1 d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_vm._t("top-header"),_vm._t("header",function(){return [_c('h4',{staticClass:"ma-0 text-center"},[_vm._v(_vm._s(_vm.title))])]}),_c('div',{staticClass:"w-100 h-auto d-flex justify-center"},[_c('v-row',{staticClass:"ma-0 justify-center",staticStyle:{"font-size":"1.8rem"}},[(!_vm.animalInformation.guid)?_c('v-btn',{attrs:{"color":"primary","disabled":"","text":""}},[_c('span',{staticStyle:{"font-size":"1.8rem"},attrs:{"target":"_blank"}},[_vm._v(" ? / ? ")])]):_c('a',{attrs:{"href":("/animal-details?id=" + (_vm.animalInformation.guid)),"target":"_blank"}},[_c('p',{staticClass:"custom-main-tag pa-2 mb-0"},[_vm._v(" "+_vm._s(_vm.animalInformation.tagValues)+" ")])])],1),_vm._t("bottom-header")],2)],2)]),_c('v-divider'),_c('div',{staticClass:"pa-8 pb-0 pt-0 d-flex flex-column flex-wrap",style:({ 'max-height': _vm.maxHeight + 'px' })},_vm._l((_vm.animalInformation.fields),function(prop,idx){return _c('div',{key:idx},[(
            !prop.translateWord ||
            ['damTags', 'sireTags'].indexOf(prop.translateWord) === -1
          )?_c('v-text-field',{staticClass:"mb-2",attrs:{"value":prop.value,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getLabelTranslation(prop.translateWord))+" ")])]},proxy:true}],null,true)}):_vm._e()],1)}),0),_c('v-divider'),(
        _vm.animalInformation.fields &&
        (_vm.animalInformation.fields.damTags ||
          _vm.animalInformation.fields.sireTags)
      )?_c('v-row',{staticClass:"d-flex justify-space-between pt-2"},[(_vm.animalInformation.fields.damTags)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","lg":"6"}},[_c('v-col',{staticClass:"d-flex justify-center align-center pa-0 pb-2"},[_c('h4',[_vm._v(_vm._s(_vm.getTranslation("calfWeanPage.information.dam")))])]),_c('v-row',{staticClass:"d-flex flex-column align-center"},[(!_vm.animalInformation.fields.damTags.guid)?_c('v-btn',{attrs:{"color":"primary","disabled":"","text":""}},[_c('span',{staticStyle:{"font-size":"1.8rem"},attrs:{"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.animalInformation.fields.damTags.value || "? / ?")+" ")])]):_c('v-btn',{staticClass:"pa-0 px-4",attrs:{"color":"primary","text":""}},[_c('router-link',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.8rem","max-width":"410px !important","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"to":{
                name: 'AnimalDetails',
                query: { id: _vm.animalInformation.fields.damTags.guid },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.animalInformation.fields.damTags.value)+" ")])],1)],1)],1):_vm._e(),(_vm.animalInformation.fields.sireTags)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","lg":"6"}},[_c('v-col',{staticClass:"d-flex justify-center align-center pa-0 pb-2"},[_c('h4',[_vm._v(_vm._s(_vm.getTranslation("calfWeanPage.information.sire")))])]),_c('v-row',{staticClass:"d-flex flex-column align-center"},[(!_vm.animalInformation.fields.sireTags.guid)?_c('v-btn',{attrs:{"color":"primary","disabled":"","text":""}},[_c('span',{staticStyle:{"font-size":"1.8rem"},attrs:{"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.animalInformation.fields.sireTags.value || "? / ?")+" ")])]):_c('v-btn',{staticClass:"pa-0 px-4",attrs:{"color":"primary","text":""}},[_c('router-link',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.8rem","max-width":"410px !important","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"to":{
                name: 'AnimalDetails',
                query: { id: _vm.animalInformation.fields.sireTags.guid },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.animalInformation.fields.sireTags.value)+" ")])],1)],1)],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"pa-8 pb-6 pt-4 d-flex flex-column flex-wrap",style:({ 'max-height': _vm.maxHeight + 'px' })},[_vm._l((_vm.items),function(item,idx){return [(item.value == 'age')?_c('v-text-field',{key:idx,staticClass:"mb-2",attrs:{"value":!_vm.information[item.value] || !_vm.information.birthDate
              ? ' '
              : _vm.Animal.getAgeOfTimestamp(_vm.information.birthDate),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation(item.labelType))+" ")])]},proxy:true}],null,true)}):_c('v-text-field',{key:idx,staticClass:"mb-2",attrs:{"value":_vm.information[item.value] || ' ',"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation(item.labelType))+" ")])]},proxy:true}],null,true)})]})],2),(_vm.fertilizations.ai)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("AI")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.ai.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.ai.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.ai.sireTags,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("sire"))+" ")])]},proxy:true}],null,false,2968511595)})],1)],1)]:_vm._e(),(_vm.fertilizations.implant)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("CIDR Implant")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.implant.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.implant.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.implant.gnrhReceived
                ? _vm.getTranslation('Yes')
                : _vm.getTranslation('No'),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("GnRH Received"))+" ")])]},proxy:true}],null,false,422604762)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.getTranslation(
                ("enums.fertilizationStatusImplant." + (_vm.fertilizations.implant.statusId))
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("status"))+" ")])]},proxy:true}],null,false,909607666)})],1)],1)]:_vm._e(),(_vm.fertilizations.removal)?[_c('v-divider'),_c('div',{staticClass:"pa-8 pb-6 pt-4"},[_c('h3',[_vm._v("CIDR Removal")]),_c('v-row',[_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.$utils.renderValueAs(
                _vm.fertilizations.removal.timeRecorded,
                'datetime',
                false
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("timeRecorded"))+" ")])]},proxy:true}],null,false,275926751)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.removal.technician,"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("technician"))+" ")])]},proxy:true}],null,false,2005862142)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.fertilizations.removal.gnrhReceived
                ? _vm.getTranslation('Yes')
                : _vm.getTranslation('No'),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("GnRH Received"))+" ")])]},proxy:true}],null,false,422604762)}),_c('v-text-field',{staticClass:"mb-2",attrs:{"value":_vm.getTranslation(
                ("enums.fertilizationStatusRemoval." + (_vm.fertilizations.removal.statusId))
              ),"dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.getTranslation("status"))+" ")])]},proxy:true}],null,false,909607666)})],1)],1)]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }