<template>
  <v-dialog v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>
        <span class="text-h5" v-if="isCreateOperation">
          {{ getTranslation("create") }}
        </span>
        <span class="text-h5" v-else-if="isDeleteOperation">
          {{ getTranslation("delete") }}
        </span>
        <span class="text-h5" v-else>
          {{ getTranslation("edit") }}
        </span>
      </v-card-title>
      <v-card-text>
        <span class="red--text" v-if="isDeleteOperation"
          >Warning: Deletion permanently removes this from history. Unlike most
          actions, we <em>cannot</em> assist you in recovery of this operation.
          To confirm that you wish to delete, please enter the to number below.
        </span>
        <v-container>
          <v-form
            @submit.prevent="save"
            id="group-form"
            ref="groupForm"
            v-model="formValid"
          >
            <v-text-field
              :label="getTranslation('animalsPages.groupNumber')"
              :rules="[required]"
              autocomplete="off"
              autofocus
              required
              v-model.trim="to"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          :disabled="submitting"
          @click="showDialog = false"
          class="mr-2 rounded-lg normal-case body-1"
          text
        >
          {{ getTranslation("animalsPages.cancel") }}
        </v-btn>
        <v-btn
          :disabled="blockSave"
          :loading="submitting"
          class="rounded-lg primary-button normal-case body-1"
          form="group-form"
          type="submit"
        >
          <span class="px-6">
            {{ getTranslation("animalsPages.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslationMixin from "@/mixins/Translations";

export default {
  name: "GroupDialog",
  props: ["herdMeta", "pouches", "syncTime"],
  mixins: [TranslationMixin],
  data() {
    return {
      formValid: false,
      from: null,
      operation: "create",
      showDialog: false,
      submitting: false,
      to: null,
    };
  },
  watch: {
    showDialog(value) {
      if (!value) {
        Object.assign(this.$data, this.$options.data());
        this.$refs.groupForm.resetValidation();
      }
    },
  },
  computed: {
    blockSave: function () {
      return (
        !this.formValid ||
        this.submitting ||
        (this.isDeleteOperation && !this.nameMatches)
      );
    },
    nameMatches: function () {
      return this.to == this.from;
    },
    isCreateOperation: function () {
      return this.operation === "create";
    },
    isDeleteOperation: function () {
      return this.operation === "delete";
    },
    isUpdateOperation: function () {
      return this.operation === "update";
    },
  },
  methods: {
    open(animalIds, groupNumber, operation) {
      this.operation = operation;

      this.animalIds = animalIds;

      this.to = groupNumber;
      this.from = groupNumber;

      if (this.isCreateOperation) {
        this.to = null;
        this.from = null;
      }

      if (this.isDeleteOperation) {
        this.to = null;
      }

      this.showDialog = true;
    },
    required(value) {
      return (
        Boolean(value) || this.getTranslation("animalsPages.requiredField")
      );
    },
    async updateAnimal(id, from, to) {
      const doc = await this.pouches.organization.get(id);
      const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);

      if (this.isDeleteOperation || this.isUpdateOperation) {
        const types = ["fertilizations", "movements", "pregChecks", "weights"];
        for (const typeIndex in types) {
          const type = types[typeIndex];
          const values = doc[type] || [];

          for (const valueIndex in values) {
            const item = values[valueIndex];

            if (item.groupNumber != from) continue;

            await animal.modify(type, item.id, "groupNumber", to, true, true);
          }
        }

        await animal.modify(
          "comments",
          null,
          "comment",
          `Group number (${from || "nothing"} -> ${to || "nothing"})`,
          false,
          false,
          {
            deleted: true,
          }
        );
      } else {
        await animal.modify("movements", null, "groupNumber", to, false, false);
      }
    },
    async save() {
      let from = this.from;
      let to = this.to;

      if (!this.$refs.groupForm.validate() || this.submitting) return;
      if (this.isDeleteOperation) {
        if (to !== from) {
          this.showDialog = false;
          return;
        }

        // New to number will be null
        to = null;
      }

      this.submitting = true;
      try {
        await Promise.all(
          this.animalIds.map((id) => this.updateAnimal(id, from, to))
        );
      } catch (e) {
        console.error(e);
        this.submitting = false;
        this.notifyError("Error");
        return;
      }
      this.notifySuccess(this.getLabelTranslation("Saved"));
      this.$emit("saved");
      this.showDialog = false;
    },
    notifyInfo: function (message) {
      this.$notify({
        group: "forms",
        text: message || "Success",
        title: "Info",
        type: "success",
      });
    },
    notifySuccess: function (message) {
      this.$notify({
        group: "forms",
        text: message || "Success",
        title: "Success",
        type: "success",
      });
    },
    notifyError: function (message) {
      this.$notify({
        group: "forms",
        text: message || "An error ocurred",
        title: "Error",
        type: "error",
      });
    },
  },
};
</script>
