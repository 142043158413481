<template>
  <div>
    <in-page-menu />
    <online-warning></online-warning>
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <v-col cols="8">
          <div v-if="loading">
            <v-responsive
              :height="loaderHeight"
              class="d-flex align-center"
              width="100vw"
            >
              <v-progress-circular
                :color="loader.color"
                :value="loader.value"
                class="d-flex justify-center mx-auto"
                rotate="360"
                size="250"
                width="15"
              >
                <h2 class="ma-0">
                  {{ loader.value }}
                </h2>
              </v-progress-circular>
            </v-responsive>
          </div>
          <div v-else-if="sortedOrganizations.length == 0">
            <v-alert type="info"> No animals left to move </v-alert>
          </div>
          <div v-else>
            <v-alert
              border="top"
              color="warning"
              colored-border
              dismissible
              elevation="2"
            >
              This page has loaded data for the following animals
              <br />
              If data for any of these animals changes before you transfer and
              you transfer it, it might appear here again.
            </v-alert>
            <v-alert
              border="top"
              color="info"
              colored-border
              elevation="2"
              v-if="reload.time"
            >
              <template v-if="reload.required">
                <template v-if="transfersInProgress > 0">
                  After transfer completes, page will reload.
                </template>
                <template v-else> Reloading... </template>
              </template>
              <template v-else-if="transfersInProgress > 0">
                Transfer is in progress. This page will reload after 5 minutes
                ({{ reload.time }}) or after transfer if that is later.
              </template>
              <template v-else>
                This page will reload after 5 minutes ({{ reload.time }}).
              </template>
            </v-alert>

            <v-menu
              :close-on-content-click="false"
              min-width="auto"
              offset-y
              transition="scale-transition"
              v-model="form.showCalendar"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  hide-details
                  outlined
                  readonly
                  style="font-size: 1.3rem; max-width: 300px"
                  v-bind="attrs"
                  v-model="form.overrideDate"
                  v-on="on"
                >
                  <template #label>
                    <p
                      :class="{ darkInputText: $vuetify.theme.dark }"
                      class="ma-0 biggerFont"
                      style="font-size: 1.7rem"
                    >
                      Override Date
                    </p>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @input="form.showCalendar = false"
                v-model="form.overrideDate"
              ></v-date-picker>
            </v-menu>

            <div
              :key="toOrg.organizationId"
              class="mt-10"
              v-for="toOrg in sortedOrganizations"
            >
              <h3>{{ toOrg.organizationName }}</h3>
              <v-alert
                type="error"
                v-if="getStatus([toOrg.organizationId]).error != ''"
              >
                Error: {{ getStatus([toOrg.organizationId]).error }}
              </v-alert>
              <div
                :key="groupNumber"
                class="ml-10 mt-10"
                v-for="groupNumber in Object.keys(toOrg.groups)"
              >
                <h4>
                  {{ groupNumber }} ({{ toOrg.groups[groupNumber].length }})
                  <v-btn
                    :disabled="
                      reload.required ||
                      getStatus([toOrg.organizationId, groupNumber])
                        .transferring
                    "
                    @click="transferGroupToReceivingRanch(toOrg, groupNumber)"
                  >
                    <span
                      v-if="
                        getStatus([toOrg.organizationId, groupNumber])
                          .transferring
                      "
                    >
                      Transferring
                    </span>
                    <span v-else> Transfer </span>
                  </v-btn>
                </h4>
                <v-alert
                  type="error"
                  v-if="
                    getStatus([toOrg.organizationId, groupNumber]).error != ''
                  "
                >
                  Error:
                  {{ getStatus([toOrg.organizationId, groupNumber]).error }}
                </v-alert>
                <div class="mt-4">
                  <v-data-table
                    :class="{
                      'dark-border': $vuetify.theme.dark,
                      border: !$vuetify.theme.dark,
                    }"
                    :headers="tableHeaders"
                    :items="toOrg.groups[groupNumber]"
                    calculate-widths
                    class="pb-0 pa-2 transfer-table"
                  >
                    <template #item="{ item: animal }">
                      <tr :key="animal.guid">
                        <td
                          class="px-2"
                          :class="{
                            'border-left': !$vuetify.theme.dark,
                            'dark-border-left': $vuetify.theme.dark,
                          }"
                          style="width: 100px"
                        >
                          <v-btn
                            :disabled="
                              reload.required ||
                              getStatus([toOrg.organizationId, groupNumber])
                                .transferring ||
                              getStatus([animal.id]).error != '' ||
                              getStatus([animal.id]).transferred ||
                              getStatus([animal.id]).transferring ||
                              getStatus([animal.id]).skipped
                            "
                            @click="
                              transferGroupToReceivingRanch(
                                toOrg,
                                groupNumber,
                                [animal]
                              )
                            "
                            class="success"
                            >Transfer</v-btn
                          >
                        </td>

                        <td
                          class="px-2"
                          :class="{
                            'border-left': !$vuetify.theme.dark,
                            'dark-border-left': $vuetify.theme.dark,
                          }"
                          style="width: 100px"
                        >
                          <v-btn
                            :disabled="
                              reload.required ||
                              getStatus([toOrg.organizationId, groupNumber])
                                .transferring ||
                              getStatus([animal.id]).error != '' ||
                              getStatus([animal.id]).transferred ||
                              getStatus([animal.id]).transferring
                            "
                            @click="skipTransfer(animal.id)"
                            class="error"
                            >{{
                              getStatus([animal.id]).skipped ? "Unskip" : "Skip"
                            }}</v-btn
                          >
                        </td>
                        <td
                          class="d-flex align-center px-0"
                          :class="{
                            'border-left': !$vuetify.theme.dark,
                            'dark-border-left': $vuetify.theme.dark,
                          }"
                        >
                          <div style="font-size: 1.2rem" class="px-3">
                            <span v-if="getStatus([animal.id]).skipped"
                              >Skipped</span
                            >
                            <span v-else-if="getStatus([animal.id]).transferred"
                              >Transferred</span
                            >
                            <span
                              v-else-if="getStatus([animal.id]).transferring"
                              >Transferring</span
                            >
                            <span
                              v-else-if="getStatus([animal.id]).error != ''"
                            >
                              Error
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    :dark="$vuetify.theme.dark"
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert-octagram
                                  </v-icon>
                                </template>
                                {{ getStatus([animal.id]).error }}
                              </v-tooltip>
                            </span>
                            <span v-else>Ready</span>
                          </div>
                          <v-divider class="mr-3" vertical></v-divider>
                          <div>
                            <router-link
                              :to="{
                                name: 'AnimalDetails',
                                query: { id: animal.id },
                              }"
                              style="font-size: 1.2rem"
                              target="_blank"
                            >
                              {{ animal.tagValues }}
                            </router-link>
                          </div>
                        </td>
                        <td
                          :class="{
                            'border-left border-right': !$vuetify.theme.dark,
                            'dark-border-left dark-border-right':
                              $vuetify.theme.dark,
                          }"
                          style="font-size: 1.2rem"
                        >
                          {{ animal.groupNumber }}
                        </td>
                        <td
                          :class="{
                            'border-left border-right': !$vuetify.theme.dark,
                            'dark-border-left dark-border-right':
                              $vuetify.theme.dark,
                          }"
                          style="font-size: 1.2rem"
                        >
                          {{ animal.timeRecordedFriendly }}
                        </td>
                        <td
                          :class="{
                            'border-right': !$vuetify.theme.dark,
                            'dark-border-right': $vuetify.theme.dark,
                          }"
                        >
                          <v-btn
                            :disabled="!animal.timeRecorded"
                            @click="
                              form.overrideDate = animal.timeRecorded.substring(
                                0,
                                10
                              )
                            "
                            v-if="animal.timeRecorded"
                          >
                            Use date
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";

export default {
  name: "transfer-animals",
  metaInfo: {
    title: "Transfer Animals",
  },
  data: () => ({
    form: {
      overrideDate: null,
      showCalendar: false,
    },
    animalsToReceivingRanch: [], // for debug and internal use
    herdMeta: null,
    loading: true,
    loader: {
      color: "teal darken-1",
      value: 0,
    },
    pouches: null,
    receivingRanchPairs: [],
    reload: {
      required: false,
      time: moment().add("5", "minutes"),
    },
    statuses: {},
    transfersInProgress: 0,
  }),
  mixins: [TranslationMixin],
  destroy: function () {
    clearInterval(this.interval);
  },
  created: function () {
    const reloadTimeUnix = this.reload.time.unix();
    this.reload.time = this.reload.time.format("HH:mm");
    this.interval = setInterval(() => {
      if (this.reload.required || moment().unix() > reloadTimeUnix) {
        this.reload.required = true;
        // Do not reload if transfer is in progress.
        if (this.transfersInProgress > 0) return;
        this.$router.go();
      }
    }, 10000);

    this.herdMeta = this.$herdMeta;
    this.pouches = this.$herdMeta.pouches;

    // console.debug("this.getOrganizationItems", this.getOrganizationItems);
    // console.debug(this.$organizationID);
    this.loader.value = 10;
    this.pouches.organization
      .query("local_views/animalsToReceivingRanch", {
        include_docs: true,
      })
      .then((results) => {
        console.log(results);
        this.animalsToReceivingRanch = results.rows
          .map((result) => {
            const forwardsCompatible = Utils.copyObject(result);
            const found = this.getOrganizationItems.find((organization) => {
              if (
                organization.id == result.value.receivingRanchId ||
                organization.name == result.value.receivingRanch
              )
                return organization;
            });
            if (found) {
              forwardsCompatible.value.receivingOrganizationId = found.id;
              // fall back on id if name is not available
              forwardsCompatible.value.receivingRanch = found.name || found.id;
            } else {
              console.error(
                "Ranch ID is not known. Animal will be skipped.",
                result
              );
            }

            return forwardsCompatible;
          })
          // No need to send animals to ourself
          .filter(
            (result) =>
              result &&
              result.value.receivingOrganizationId &&
              result.value.receivingOrganizationId != this.$organizationID
          );

        // console.debug(this.animalsToReceivingRanch);
        this.loader.value = 25;

        this.receivingRanchPairs = this.animalsToReceivingRanch.reduce(
          (reduction, result) => {
            if (!reduction[result.value.receivingOrganizationId])
              reduction[result.value.receivingOrganizationId] = {
                animals: [],
                groups: {}, // to be populated after this loop
                organizationId: result.value.receivingOrganizationId,
                organizationName: result.value.receivingRanch,
                timeRecorded: result.value.timeRecorded,
                timeRecordedFriendly: Utils.renderValueAs(
                  result.value.timeRecorded,
                  "datetime",
                  true
                ),
              };

            reduction[result.value.receivingOrganizationId].animals.push({
              doc: result.doc,
              groupNumber: result.value.groupNumber,
              id: result.id,
              tagValues: result.value.tagValues,
              timeRecorded: result.value.timeRecorded,
              timeRecordedFriendly: Utils.renderValueAs(
                result.value.timeRecorded,
                "datetime",
                true
              ),
            });
            return reduction;
          },
          {}
        );

        this.loader.value = 50;
        this.receivingRanchPairs = Object.keys(this.receivingRanchPairs)
          .map((organizationId) => this.receivingRanchPairs[organizationId])
          .map((orgWithPendingAnimals) => {
            // Allocate ordered space for group numbers
            orgWithPendingAnimals.animals
              .map(({ groupNumber }) => groupNumber)
              .sort()
              .forEach(
                (groupNumber) =>
                  (orgWithPendingAnimals.groups[groupNumber] = [])
              );

            orgWithPendingAnimals.animals
              // Sort animals by tag
              .sort((a, b) => a.timeRecorded.localeCompare(b.timeRecorded))
              .forEach((animal) => {
                // Store them in the group
                orgWithPendingAnimals.groups[animal.groupNumber].push(animal);
              });

            // orgWithPendingAnimals.animals = null;
            return orgWithPendingAnimals;
          });
        // console.debug("mapped", this.receivingRanchPairs);
        this.loader.value = 100;
        this.loading = false;
      })
      .catch((e) => {
        this.animalsToReceivingRanch = [];
        console.error(e);
      });
  },
  computed: {
    ...mapGetters({
      getName: "User/getName",
      getOrganizationItems: "Organization/getOrganizationItems",
      getOrganizationName: "Organization/getName",
    }),
    loaderHeight: function () {
      return $(window).height() - $(".v-toolbar__content").outerHeight() - 60;
    },
    tableHeaders: function () {
      return [
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        {
          text: "Tags",
          value: "tagValues",
        },
        { text: "Group Number", value: "groupNumber", sortable: false },
        { text: "Move Date", value: "timeRecorded" },
        { text: "", value: "", sortable: false },
      ];
    },
    sortedOrganizations: function () {
      return this.receivingRanchPairs.sort((a, b) => {
        return a.organizationName.localeCompare(b.organizationName);
      });
    },
  },
  methods: {
    skipTransfer: function (id) {
      this.setStatus([id], "skipped", !this.getStatus([id]).skipped);
    },
    closePouchAndFreeUpItems: function (
      remotePouch,
      receivingOrganizationId,
      groupNumber
    ) {
      if (remotePouch)
        remotePouch
          .close()
          .then()
          .catch((e) => {
            console.error("Failure to close db connection", e);
          });

      this.setStatus(
        [receivingOrganizationId, groupNumber],
        "transferring",
        false
      );
      this.transfersInProgress--;
    },
    getStatus: function (ids) {
      const id = ids.join("_");
      let status = {
        error: "",
        skipped: false,
        transferred: false,
        transferring: false,
      };
      if (!this.statuses[id]) Vue.set(this.statuses, id, status);
      return this.statuses[id];
    },
    setStatus: function (ids, key, value) {
      const id = ids.join("_");
      let current = this.getStatus(ids);
      current[key] = value;
      Vue.set(this.statuses, id, current);
    },
    transferGroupToReceivingRanch: async function (
      toOrg,
      groupNumber,
      animals
    ) {
      const receivingOrganizationId = toOrg.organizationId;

      console.debug(
        "status of org + group",
        receivingOrganizationId,
        groupNumber,
        this.getStatus([receivingOrganizationId, groupNumber])
      );

      if (this.getStatus([receivingOrganizationId, groupNumber]).transferring)
        return;

      // console.debug("animals", animals);
      const operableAnimals = (animals || toOrg.groups[groupNumber]).filter(
        (animal) => {
          const status = this.getStatus([animal.id]);
          return !status.skipped && !status.transferred && !status.transferring;
        }
      );

      const operableAnimalDocIds = operableAnimals.map((animal) => animal.id);
      console.debug("operableAnimalDocIds", operableAnimalDocIds);
      if (operableAnimalDocIds.length == 0) return;

      this.setStatus(
        [receivingOrganizationId, groupNumber],
        "transferring",
        true
      );

      // console.debug("remotePouch", remotePouch);
      // console.debug("this.pouches.organization", this.pouches.organization);
      const { remotePouch, auth } = DAOPouches.remotePouch(
        receivingOrganizationId,
        this.$pouchPass
      );

      this.transfersInProgress++;
      auth
        .then(async () => {
          // console.debug("Authenticated", await auth);
          // remotePouch.info(console.debug);

          // To get the latest docs
          // const operableAnimals = (
          //   await this.pouches.organization.bulkGet({
          //     docs: operableAnimalDocIds.map((id) => {
          //       return { id };
          //     }),
          //   })
          // ).results
          //   .map((result) => result.docs.find((doc) => doc.ok))
          //   .map((doc) => doc.ok)
          //   .filter((doc) => doc);
          // Really should verify that they are still not transferred AND still have the same receiving organization

          const overrideMoment = this.form.overrideDate
            ? moment(this.form.overrideDate).toISOString()
            : null;
          const animalDocs = operableAnimals.map((animal) => {
            if (!animal.doc.transfers) animal.doc.transfers = [];

            animal.doc.transfers.push({
              createdOn: new Date().toISOString(),
              from: {
                id: this.$organizationID,
                name: this.getOrganizationName,
              },
              to: {
                id: receivingOrganizationId,
                name: toOrg.organizationName,
              },
              timeRecorded: overrideMoment || animal.timeRecorded,
              userId: this.$userID,
              userName: this.getName,
            });

            return animal.doc;
          });
          // console.debug("animalDocs", animalDocs);
          // return;

          remotePouch
            // Get of animals on remote end; Sometimes they exist if they were transferred away and are now returning.
            .bulkGet({
              docs: operableAnimalDocIds.map((id) => {
                return { id };
              }),
            })
            .then((results) => {
              console.debug("checking for existence", results.results);

              const remoteRevisions = {};
              const animalsThatAlreadyExistInReceivingOrganization =
                results.results.filter((result) => result.docs[0].ok);
              // make copy of animal doc
              if (animalsThatAlreadyExistInReceivingOrganization.length) {
                console.warn(
                  "Some animals already exist on receiving end. Going to use their _rev data."
                );

                animalsThatAlreadyExistInReceivingOrganization.forEach(
                  (result) => {
                    const doc = result.docs[0].ok;
                    // Some animals exist on remote end. In order to overwrite, we need to set revision to match.
                    const exists = animalDocs.some((animal) => {
                      if (animal._id == doc._id) return animal;
                    });
                    if (exists) {
                      remoteRevisions[doc._id] = doc._rev;
                    } else {
                      console.error(
                        "Animal that did exist no longer exists. This shouldn't happen."
                      );
                    }
                  }
                );
              }
              // console.debug("remoteRevisions", remoteRevisions);

              const promises = animalDocs.map((animalDoc) => {
                // For UI to know to display as transferring
                this.setStatus([animalDoc._id], "transferring", true);

                // Store local rev for later use
                const { _rev } = animalDoc;

                // Use the revision that remote has, otherwise remove it
                if (remoteRevisions[animalDoc._id]) {
                  animalDoc._rev = remoteRevisions[animalDoc._id];
                } else {
                  // For remote, this is going to be a new animal, so we remove the _rev
                  delete animalDoc._rev;
                }

                return new Promise((resolve, reject) => {
                  // Push copy to remote organization
                  // Cannot do this in bulk because we'd need to limit to 1MB in size and it is difficult to do that programmatically.
                  // Easier to just push one at a time
                  remotePouch
                    .put(animalDoc)
                    .then((_pushResults) => {
                      this.setStatus([animalDoc._id], "transferred", true);
                      this.setStatus([animalDoc._id], "transferring", false);

                      // Get ready to update local version of the animal (using original local rev)
                      animalDoc._rev = _rev;
                      animalDoc.tableName = "animaltransferred";

                      // Update local to store as transferred
                      this.pouches.organization
                        .put(animalDoc)
                        .catch((e) => {
                          const error =
                            "Animal was copied over, but a newer version already exists. Please refresh the page and send again.";
                          this.setStatus([animalDoc._id], "transferred", false);
                          this.setStatus([animalDoc._id], "error", error);
                          reject(new Error(error));
                        })
                        .then(resolve);
                    })
                    .catch((e) => {
                      if (e.status == "404") {
                        const error =
                          "Database not found. A user needs to log into the receiving organization first.";
                        this.setStatus(
                          [receivingOrganizationId],
                          "error",
                          error
                        );
                        this.setStatus(
                          [receivingOrganizationId, groupNumber],
                          "error",
                          error
                        );
                        return reject(error);
                      }

                      this.setStatus([animalDoc._id], "transferred", false);
                      this.setStatus([animalDoc._id], "transferring", false);
                      this.setStatus(
                        [animalDoc._id],
                        "error",
                        JSON.stringify(e)
                      );
                      const error =
                        "An unknown error occurred. Advised: refresh page. " +
                        JSON.stringify(e);
                      console.error(error);
                      reject(error);
                    });
                });
              });

              Promise.all(promises)
                .catch((e) => {
                  this.$notify({
                    group: "forms",
                    text: "Please view errors.",
                    title: "Error",
                    type: "error",
                  });
                })
                .finally(() => {
                  if (this.form.overrideDate) {
                    this.form.overrideDate = null;

                    this.$notify({
                      group: "forms",
                      text: "Saving complete. Override was reset.",
                      title: "Important",
                      type: "info",
                    });
                  }

                  this.closePouchAndFreeUpItems(
                    remotePouch,
                    receivingOrganizationId,
                    groupNumber
                  );
                });
            })
            .catch(() => {
              this.$notify({
                group: "forms",
                text: "Error encountered.",
                title: "Error",
                type: "error",
              });
              this.closePouchAndFreeUpItems(
                remotePouch,
                receivingOrganizationId,
                groupNumber
              );
            });
          this.setStatus([receivingOrganizationId], "error", "");
          this.setStatus([receivingOrganizationId, groupNumber], "error", "");
        })
        .catch((e) => {
          console.error("Error authenticating or connecting", e, auth);
          this.setStatus([receivingOrganizationId], "error", JSON.stringify(e));
          this.setStatus(
            [receivingOrganizationId, groupNumber],
            "error",
            JSON.stringify(e)
          );
          this.closePouchAndFreeUpItems(
            null,
            receivingOrganizationId,
            groupNumber
          );
        });
    },
  },
};
</script>
<style scoped>
.dark-border {
  border: thin solid rgba(255, 255, 255, 0.12);
}
.dark-border-left {
  border-left: thin solid rgba(255, 255, 255, 0.12);
}
.dark-border-right {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}
</style>
<style lang="scss">
.dark-border > .v-data-table__wrapper > table > thead > tr > th {
  border: thin solid rgba(255, 255, 255, 0.12);
}
.border > .v-data-table__wrapper > table > thead > tr > th {
  border: 1px solid #dee2e6 !important;
}
.transfer-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1.5rem !important;
}
</style>