<template>
  <div>
    <online-warning></online-warning>
    <in-page-menu />
    <div class="ml-3" style="margin-top: 80px; margin-right: 48px">
      <!-- Start Date -->
      <div class="g-row align-center" style="max-width: 350px">
        <div class="g-col g-col-1">
          <v-subheader class="subtitle-1">
            {{ getTranslation("startDate") }}
          </v-subheader>
        </div>
        <div class="g-col g-col-2">
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="showStartDateCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                class="custom-field"
                dense
                hide-details
                outlined
                readonly
                tabindex="1"
                v-bind="attrs"
                v-model="form.startDate"
                v-on="on"
              />
            </template>
            <v-date-picker
              :max="form.endDate"
              @input="showStartDateCalendar = false"
              v-model="form.startDate"
            />
          </v-menu>
        </div>
      </div>
      <!-- End Date -->
      <div class="g-row align-center" style="max-width: 350px">
        <div class="g-col g-col-1">
          <v-subheader class="subtitle-1">
            {{ getTranslation("endDate") }}
          </v-subheader>
        </div>
        <div class="g-col g-col-2">
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="showEndDateCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                class="custom-field"
                dense
                hide-details
                outlined
                readonly
                tabindex="2"
                v-bind="attrs"
                v-model="form.endDate"
                v-on="on"
              />
            </template>
            <v-date-picker
              @input="showEndDateCalendar = false"
              v-model="form.endDate"
            />
          </v-menu>
        </div>
      </div>
      <!-- Origin -->
      <div class="g-row align-center" style="max-width: 350px">
        <div class="g-col g-col-1">
          <v-subheader class="subtitle-1">
            {{ getTranslation("origin") }}
          </v-subheader>
        </div>
        <div class="g-col g-col-2">
          <v-autocomplete
            :items="origins"
            :menu-props="{
              offsetY: true,
              closeOnClick: true,
            }"
            class="custom-field"
            dense
            hide-details
            item-text="label"
            multiple
            outlined
            return-object
            style="max-width: 234px"
            v-model="form.origin"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index < 5 && item.readers">
                <span>{{ item.label }}</span>
              </v-chip>
              <span v-if="index == 5">
                (+{{ form.origin.length - 5 }}
                others)
              </span>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <!-- Destination Readers -->
      <div class="g-row align-center" style="max-width: 350px">
        <div class="g-col g-col-1">
          <v-subheader class="subtitle-1">
            {{ getTranslation("Destination") }}
          </v-subheader>
        </div>
        <div class="g-col g-col-2">
          <v-autocomplete
            :items="destinationReaders"
            :menu-props="{
              offsetY: true,
              closeOnClick: true,
            }"
            class="custom-field"
            dense
            hide-details
            item-text="label"
            multiple
            outlined
            return-object
            style="max-width: 234px"
            v-model="form.destination"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index < 5 && item.readers">
                <span>{{ item.label }}</span>
              </v-chip>
              <span v-if="index == 5">
                (+{{ form.origin.length - 5 }}
                others)
              </span>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <!-- Buttons -->
      <div class="g-row pl-4 mt-4 mb-6">
        <div class="g-col" style="max-width: 200px">
          <v-btn
            :disabled="
              (form.origin && !form.origin.length) ||
              (form.destination && !form.destination.length)
            "
            @click="generateReport"
            class="white--text text-decoration-none"
            color="primary"
            depressed
            min-width="40"
          >
            {{ getTranslation("Generate") }}
          </v-btn>
        </div>
      </div>
      <!-- Table Controls -->
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Manifest-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :items="table.data"
        :ignoreFields="['animal']"
      />
      <v-btn
        :disabled="
          !table.selected.length ||
          !animals.length ||
          !animals.filter((animal) => animal.guid).length
        "
        @click="displayGroupDialog"
        color="primary"
        depressed
        text
      >
        {{ getTranslation("Group Number") }}
      </v-btn>
      <!-- Table -->
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 50, 100, 200, -1],
        }"
        :headers="tableHeaders"
        :items-per-page="200"
        :items="table.data"
        :loading="table.loading"
        class="health-tracker-table dashboard-data-table"
        fixed-header
        height="480"
        item-key="idx"
        mobile-breakpoint="0"
        must-sort
        show-select
        v-model="table.selected"
      >
        <template #item.eid="{ item }">
          <router-link
            :to="{
              name: 'AnimalDetails',
              query: { id: item.animal.guid },
            }"
            class="subtitle-2"
            target="_blank"
            v-if="item.animal"
          >
            {{ item.eid }}
          </router-link>
          <div v-else>
            {{ item.eid }}
          </div>
        </template>
        <template #item.actions="{ item }">
          <v-btn
            @click="createAnimal(item)"
            class="white--text text-decoration-none mr-2"
            color="success"
            depressed
            min-width="40"
            small
            v-if="!item.animal"
          >
            {{ getTranslation("create") }}
          </v-btn>
          <v-btn
            @click="displayAnimalRoute(item)"
            class="pa-0"
            color="info"
            max-width="36"
            min-width="36"
            small
            v-if="
              (item.originLatitude && item.originLongitude) ||
              (item.destinationLatitude &&
                item.destinationLongitude &&
                item.animal &&
                item.animal.guid)
            "
          >
            <v-icon color="white">mdi-map-marker</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="groupDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4 class="mb-0">Add Group Number</h4>
          <v-icon @click="groupDialog.show = false" class="buttons">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text class="py-4">
          <v-text-field
            clearable
            dense
            hide-details
            label="Group Number"
            outlined
            v-model="groupDialog.groupNumber"
          />
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="
              !groupDialog.groupNumber || groupDialog.groupNumber.length == 0
            "
            @click="editGroupNumber"
            color="success"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="mapDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4 class="mb-0">{{ mapDialog.title }}</h4>
          <v-icon @click="mapDialog.show = false" class="buttons">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text class="pa-2">
          <animal-location
            :animalID="(mapDialog.animal && mapDialog.animal.guid) || ''"
            :destination="mapDialog.destination"
            :origin="mapDialog.origin"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";
export default {
  name: "Manifest",
  metaInfo: {
    title: "Manifest",
  },
  mixins: [TranslationMixin],
  data: () => ({
    destinationReaders: [],
    form: {
      destination: [],
      endDate: moment().endOf("day").format("YYYY-MM-DD"),
      origin: [],
      originAntennasSelected: [],
      startDate: moment()
        .startOf("day")
        .subtract(2, "days")
        .format("YYYY-MM-DD"),
    },
    groupDialog: {
      groupNumber: null,
      show: false,
    },
    herdMeta: null,
    mapDialog: { animal: null, show: false, title: null },
    minimumStartDate: moment()
      .startOf("day")
      .subtract(31, "days")
      .format("YYYY-MM-DD"),
    origins: [],
    pouches: null,
    showStartDateCalendar: false,
    showEndDateCalendar: false,
    table: { data: [], loading: false, selected: [] },
  }),
  computed: {
    ...mapGetters({
      getToken: "User/getToken",
    }),
    animals: function () {
      return this.table.selected.map((item) => item.animal);
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("EID"),
          type: "default",
          value: "eid",
          width: 180,
        },
        {
          text: this.getTranslation("origin"),
          type: "default",
          value: "originReader",
          width: 180,
        },
        {
          text: this.getTranslation("Origin Reader Time"),
          type: "default",
          value: "originDatetime",
          width: 180,
        },
        {
          text: this.getTranslation("Destination Reader"),
          type: "default",
          value: "destinationReader",
          width: 200,
        },
        {
          text: this.getTranslation("Destination Reader Time"),
          type: "default",
          value: "destinationDatetime",
          width: 230,
        },
        {
          text: this.getTranslation("group"),
          type: "default",
          value: "groupNumber",
          width: 150,
        },
        {
          text: this.getTranslation("tagLot"),
          type: "default",
          value: "tagLot",
          width: 150,
        },
        {
          sortable: false,
          text: this.getTranslation("actions"),
          type: "default",
          value: "actions",
          width: 160,
        },
      ];
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.$herdMeta.pouches;

    const { data } = await axios.get("/api/readers", {
      params: {
        token: this.getToken,
      },
    });

    this.destinationReaders = HerdMeta.groupDestinationReaders(data);
    this.origins = HerdMeta.groupOriginReaders(data);
    this.form.origin =
      this.origins && this.origins.length ? [this.origins[0]] : [];
    this.form.destination =
      this.destinationReaders && this.destinationReaders.length
        ? [this.destinationReaders[0]]
        : [];
  },
  methods: {
    createAnimal: async function (item) {
      try {
        const newAnimal = HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);
        const tag = {
          status: "active",
          tagId: null,
          tagValue: item.eid,
          timeRecorded: new Date().toISOString(),
          type: "eid",
          userId: this.$userID,
        };
        await newAnimal.insertIDforAnimal(tag, true, false, false);
        await newAnimal.save();
        this.$notify({
          group: "forms",
          text: "Animal created",
          title: "Success",
          type: "success",
        });
        await this.generateReport();
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
    displayAnimalRoute: function (animal) {
      this.mapDialog.show = true;
      this.mapDialog.title = "Animal Route";
      this.mapDialog.animal = animal;
      this.mapDialog.origin = {
        lat: +animal.originLatitude,
        lng: +animal.originLongitude,
      };
      this.mapDialog.destination = {
        lat: +animal.destinationLatitude,
        lng: +animal.destinationLongitude,
      };
    },
    displayGroupDialog: function () {
      this.groupDialog.show = true;
      this.groupDialog.groupNumber = null;
    },
    editGroupNumber: function () {
      const promises = this.animals.map(({ guid }) => {
        return this.pouches.organization
          .get(guid)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            return animal.modify(
              "movements",
              null,
              "groupNumber",
              this.groupDialog.groupNumber,
              false
            );
          })
          .catch((e) => {
            this.$notify({
              group: "forms",
              ignoreDuplicates: true,
              text: "An error ocurred",
              title: "Error",
              type: "error",
            });
            console.error(e);
            return;
          });
      });
      Promise.all(promises)
        .then(async () => {
          this.$notify({
            group: "forms",
            ignoreDuplicates: true,
            text: "Success",
            title: "Success",
            type: "success",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            ignoreDuplicates: true,
            text: "An error ocurred",
            title: "Error",
            type: "error",
          });
          console.error(e);
          return;
        })
        .finally(() => {
          this.groupDialog.show = false;
          this.groupDialog.groupNumber = null;
        });

      this.table.selected = [];
    },
    generateReport: async function () {
      try {
        this.table.loading = true;
        const destinationReadersID = [
          ...new Set(
            this.form.destination.reduce((prev, current) => {
              prev.push(...current.readers.map(({ id }) => id));
              return prev;
            }, [])
          ),
        ];
        const originReadersID = [
          ...new Set(
            this.form.origin.reduce((prev, current) => {
              prev.push(...current.readers.map(({ id }) => id));
              return prev;
            }, [])
          ),
        ];
        const startDate = this.$utils.localToGMT(this.form.startDate);
        const end = new Date(this.form.endDate);
        end.setHours(end.getHours() + 23);
        end.setMinutes(end.getMinutes() + 59);
        end.setSeconds(end.getSeconds() + 59);
        const endDate = this.$utils.localToGMT(end);
        const body = {
          destinationReadersID,
          endDate,
          originReadersID,
          startDate,
        };
        const manifest = await axios.post("/api/readers/manifest", body, {
          params: { token: this.getToken },
        });
        let tableData = [];
        if (manifest.data.length) {
          await this.herdMeta.populateTagSearchSpace(true);
          const promises = manifest.data.map(async (item, idx) => {
            const match =
              await this.herdMeta.getFirstAnimalWithActiveTagAndWhetherIsUniqueV2(
                item.eid,
                null,
                false,
                false,
                false
              );

            return {
              ...item,
              animal: match.animal,
              destinationDatetime: this.$utils.GMTToLocal(
                item.destinationDatetime
              ),
              destinationReader:
                item.destinationFriendlyName ||
                item.destinationReaderBox ||
                "?",
              groupNumber:
                match.animal &&
                match.animal.guid &&
                match.animal.doc &&
                match.animal.doc.derived &&
                match.animal.doc.derived.summaries
                  ? match.animal.doc.derived.summaries.main.groupNumber
                  : "?",
              idx,
              originDatetime: this.$utils.GMTToLocal(item.originDatetime),
              originReader:
                item.originFriendlyName || item.originReaderBox || "?",
              tagLot:
                match.animal &&
                match.animal.guid &&
                match.animal.doc &&
                match.animal.doc.derived &&
                match.animal.doc.derived.summaries
                  ? match.animal.doc.derived.summaries.main.tagLot
                  : "?",
            };
          });
          tableData = await Promise.all(promises);
        }

        this.table.data = tableData;
        this.$notify({
          group: "forms",
          text: "Report generated",
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      } finally {
        this.table.loading = false;
      }
    },
  },
  watch: {
    "form.endDate": function (newVal) {
      const newEndDate = this.$moment(this.$utils.copyObject(newVal));
      this.minimumStartDate = newEndDate
        .subtract(31, "days")
        .format("YYYY-MM-DD");
    },
  },
};
</script>
