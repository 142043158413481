<template>
  <svg viewBox="0 0 20 20">
    <svg
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :stroke="fill"
        d="M11.6115 10.9891L14.1004 8.50024M14.1004 8.50024L11.6115 6.01135M14.1004 8.50024L5.38928 8.50024M9.12261 10.9891V11.6113C9.12261 12.6423 8.28688 13.478 7.25595 13.478H4.76706C3.73613 13.478 2.90039 12.6423 2.90039 11.6113V5.38913C2.90039 4.3582 3.73613 3.52246 4.76706 3.52246H7.25595C8.28688 3.52246 9.12261 4.3582 9.12261 5.38913V6.01135"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.67"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
