<template>
  <dashboard-card>
    <template #title-container>
      <v-row class="d-flex align-center mb-6">
        <v-col class="d-flex" cols="12" md="7">
          <v-icon :primary="!$vuetify.theme.dark" class="mr-4" large>
            {{ "$headWithTag" }}
          </v-icon>
          <div class="text-h5 font-weight-medium dashboard-card-title">
            Progress of Work
          </div>
        </v-col>
        <v-col
          class="ml-auto d-flex align-center justify-md-end"
          cols="12"
          md="5"
        >
          <span class="mr-2 whitespace-nowrap"> Filter By </span>
          <div class="w-40 mr-4">
            <v-select
              class="dashboard-select rounded-lg"
              dense
              elevation="0"
              flat
              hide-details="true"
              :items="intervals"
              label=""
              solo
              v-model="interval"
            />
          </div>
          <v-tooltip left v-if="countMissingBirthdate > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex justify-center lighten-4 mr-2 rounded-circle warning"
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-exclamation
              </v-icon>
            </template>
            <span>
              Some animals are missing a birth date. ({{
                countMissingBirthdate
              }})
            </span>
          </v-tooltip>
          <v-tooltip left v-if="countMissingGender > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex justify-center lighten-4 mr-2 rounded-circle warning"
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-exclamation
              </v-icon>
            </template>
            <span
              >Missing gender may have affected these results. ({{
                countMissingGender
              }})</span
            >
          </v-tooltip>
          <v-tooltip
            left
            v-if="countMissingBirthdate === 0 && countMissingGender === 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="align-center d-inline-flex justify-center lighten-4 rounded-circle success"
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>No errors/warning detected during computation.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template #content>
      <div class="d-flex flex-column gap-y-4 pr-10">
        <div v-for="(item, $index) in summaries[interval]" :key="$index">
          <div class="d-flex font-weight-medium h6">
            <span>
              {{ item.name }}
              <v-btn
                @click="item.showDetails = !item.showDetails"
                icon
                v-if="item.breakout"
              >
                <v-icon v-if="item.showDetails">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </span>
            <span class="ml-auto">{{ item.expected }}</span>
          </div>
          <template v-if="item.breakout">
            <breakout-bar :dataset="item.breakout" v-if="item.showDetails" />
          </template>
          <div class="grey lighten-3 rounded-lg" v-else>
            <div
              :style="getItemStyles(item)"
              class="bg-dark-blue font-weight-medium h6 px-2 py-1 rounded-lg text-right text-white whitespace-nowrap"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </dashboard-card>
</template>

<script>
import BreakoutBar from "./BreakoutBar.vue";

export default {
  components: { BreakoutBar },
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      interval: "30 days",
      intervals: ["30 days", "90 days", "125 days", "1 year"],
      summaries: {},
    };
  },
  computed: {
    countMissingBirthdate: function () {
      return (this.summaries[this.interval] || [])
        .filter(({ countMissingBirthdate }) => countMissingBirthdate)
        .reduce((reduction, item) => reduction + item.countMissingBirthdate, 0);
    },
    countMissingGender: function () {
      return (this.summaries[this.interval] || [])
        .filter(({ countMissingGender }) => countMissingGender)
        .reduce((reduction, item) => reduction + item.countMissingGender, 0);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const results = await this.pouches.organization.animalSummaries(
        false,
        false,
        {
          group_level: 1,
          include_docs: false,
        }
      );

      if (results.rows.length == 0) return;

      const colorOptions = [
        "rgb(41, 80, 143)",
        "rgb(115, 151, 206)",
        "rgb(206, 203, 199)",
        "rgb(240, 140, 15)",
        "rgb(16, 27, 45)",
      ];

      const animals = results.rows
        .map(({ value }) => value)
        .map(({ bullCheck, main, pregCheck }) => ({
          adgTotal: main.adgTotal,
          date: main.birthDate || main.createdOn,
          bullTestDate:
            bullCheck &&
            (bullCheck.testCheckTime ||
              bullCheck.createdOn ||
              bullCheck.lastCheckDate)
              ? bullCheck.testCheckTime ||
                bullCheck.createdOn ||
                bullCheck.lastCheckDate
              : null,
          calving: {
            birthDate: main.birthDate,
            status: main.status || "alive",
          },
          currentWeightDate: main.currentWeightDate,
          gender: main.gender || "?",
          hasBirthDate: !!main.birthDate,
          hasGender: !!main.gender,
          pregCheck: !!pregCheck.pregCheckTime
            ? {
                cycle: pregCheck.cycle,
                timeRecorded: pregCheck.pregCheckTime,
              }
            : null,
        }));

      this.summaries = {};
      this.intervals.forEach((interval, idx) => {
        const threshold = moment()
          .startOf("day")
          .subtract(
            interval.match(new RegExp(/\d+/))[0],
            interval.match(new RegExp(/ (\D+)/))[1]
          )
          .toISOString();

        this.summaries[interval] = [];
        const pregCheckBreakout = this.computePregChecksForInterval(
          animals,
          threshold
        );
        this.summaries[interval].push(pregCheckBreakout);

        // const weanBreakout = this.computeWeansForInterval(animals, threshold);
        // this.summaries[interval].push(weanBreakout);

        const bullTestBreakout = this.computeBullTestsForInterval(
          animals,
          threshold
        );
        this.summaries[interval].push(bullTestBreakout);

        const calvingBreakout = this.computeCalvingsForInterval(
          animals,
          threshold
        );
        this.summaries[interval].push(calvingBreakout);

        this.summaries[interval] = this.summaries[interval].map((progress) => ({
          breakout: !progress.breakout
            ? null
            : progress.breakout.map((breakdown, idx) => ({
                ...breakdown,
                background: colorOptions[idx % colorOptions.length],
                classes: ["text-white"],
              })),
          expected: progress.expected,
          name: progress.name,
          value: progress.value,
          showDetails: true,
        }));
      });
    },
    // bullCheck:
    //   include in expected if gender is male and (bullCheck exists OR birthdate is > 9 months)
    //   include in completed if in threshold
    //   createdOn as fallback, count fallbacks (only if bullCheck does not exist)
    computeBullTestsForInterval(animals, threshold) {
      const bullChecks = animals
        .filter(({ bullTestDate }) => bullTestDate)
        .map(({ bullTestDate }) => bullTestDate)
        .filter((bullTestDate) => bullTestDate >= threshold);

      const thresholdAge = moment()
        .startOf("day")
        .subtract(9, "months")
        .toISOString();
      const countMissingBirthdate = animals.filter(
        ({ bullTestDate, hasBirthDate }) =>
          // only worry about animals if we are not 100% sure they are bulls of appropriate age
          !bullTestDate && !hasBirthDate
      ).length;
      const animalsOldEnoughToSire = animals.filter(
        ({ bullTestDate, date }) => bullTestDate || date >= thresholdAge
      );

      const bulls = animalsOldEnoughToSire.filter(
        // only worry about animals if we are not 100% sure they are bulls
        ({ bullTestDate, gender }) => gender === "male" || bullTestDate
      );

      return {
        breakout: [{ name: " ", value: bullChecks.length }],
        countMissingBirthdate,
        countMissingGender: animalsOldEnoughToSire.length - bulls.length,
        expected: bulls.length,
        name: "Bull test",
      };
    },
    // calving
    //   include if birthdate in threshold OR (pregCheck is in threshold and status is cull/open)
    //   breakdown by status
    computeCalvingsForInterval(animals, threshold) {
      const countMissingBirthdate = animals
        .filter(({ gender }) => gender === "female")
        .map(
          ({ hasBirthDate, pregCheck }) =>
            !hasBirthDate && (!pregCheck || !pregCheck.timeRecorded)
        );

      const threshold20MonthsOld = moment()
        .startOf("day")
        .subtract(20, "months")
        .toISOString();
      const countMissingGender = animals
        .filter(
          ({ date, hasBirthDate }) =>
            hasBirthDate && date >= threshold20MonthsOld
        )
        .map(
          ({ hasGender, pregCheck }) =>
            (!pregCheck || !pregCheck.timeRecorded) && !hasGender
        );

      const expected = animals.filter(
        ({ birthDate, hasBirthDate, gender, pregCheck }) =>
          (pregCheck && pregCheck.timeRecorded) ||
          (gender === "female" &&
            hasBirthDate &&
            birthDate >= threshold20MonthsOld)
      ).length;

      const breakoutMap = [
        animals
          .filter(({ hasBirthDate }) => hasBirthDate)
          .filter(({ date }) => date >= threshold)
          .reduce(
            (reduction, { status }) => {
              if (status !== "dead") {
                reduction.alive++;
              } else {
                reduction[status]++;
              }
              return reduction;
            },
            {
              alive: 0,
              dead: 0,
            }
          ),
      ]
        .map((item) => ({
          // Order matters
          Alive: item["alive"],
          Dead: item["dead"],
          "Not calved": 0,
        }))
        .find((item) => item);

      breakoutMap["Not calved"] =
        expected - breakoutMap.Alive - breakoutMap.Dead;

      Object.keys(breakoutMap).forEach((status) => {
        if (breakoutMap[status] > 0) {
          return;
        }
        // delete empty values from breakoutMap
        delete breakoutMap[status];
      });

      const breakout = Object.keys(breakoutMap).map((status) => ({
        name: status,
        value: breakoutMap[status],
      }));

      return {
        breakout,
        countMissingBirthdate,
        countMissingGender,
        expected,
        name: "Calving",
      };
    },
    // pregCheck
    //   include if in threshold
    //   breakdown by cycle
    computePregChecksForInterval(animals, threshold) {
      const breakoutMap = [
        animals
          .filter(
            (item) =>
              item.pregCheck &&
              !!item.pregCheck.timeRecorded &&
              !!item.pregCheck.cycle
          )
          .map(({ pregCheck }) => pregCheck)
          .map((item) => ({ ...item, cycle: String(item.cycle) }))
          .filter(({ timeRecorded }) => timeRecorded >= threshold)
          .reduce(
            (reduction, item) => {
              const c =
                (item && item.cycle && item.cycle.toLowerCase()) ||
                "notAssigned";
              if (c.includes("open")) reduction.open++;
              else if (c.includes("late")) reduction.late++;
              else reduction[item.cycle]++;
              return reduction;
            },
            {
              "Cycle 1": 0,
              "Cycle 2": 0,
              late: 0,
              open: 0,
            }
          ),
      ]
        .map((item) => ({
          "Cycle 1": item["Cycle 1"],
          "Cycle 2": item["Cycle 2"],
          Late: item.late,
          Open: item.open,
        }))
        .find((item) => item);

      let total = 0;
      Object.keys(breakoutMap).forEach((cycle) => {
        total += breakoutMap[cycle] || 0;
        if (breakoutMap[cycle]) {
          return;
        }
        // delete empty values from breakoutMap
        delete breakoutMap[cycle];
      });

      const breakout =
        Object.keys(breakoutMap).length === 0
          ? [{ name: " ", value: 0 }]
          : Object.keys(breakoutMap).map((cycle) => ({
              name: cycle,
              value: breakoutMap[cycle],
            }));

      return {
        breakout,
        expected: total,
        name: "Preg check",
      };
    },
    // wean:
    //   include in expected if birthdate is < 18 months.
    //   createdOn as fallback, count fallbacks
    //   include in completed if in threshold
    computeWeansForInterval(animals, threshold) {
      const thresholdAge = moment()
        .startOf("day")
        .subtract(1, "year")
        .toISOString();

      const weanableAnimalList = animals
        .filter(({ hasBirthDate }) => hasBirthDate)
        .filter(({ date }) => date >= thresholdAge);

      const weanedAnimalList = weanableAnimalList
        // FIXME: adgTotal is used as proxy for detecting that an animal was weaned.
        // The better way is to find the first wean weight and the date for that
        .filter(({ adgTotal }) => adgTotal != null)
        .filter(({ currentWeightDate }) => currentWeightDate >= threshold);

      const countMissingBirthdate = animals.filter(
        ({ hasBirthDate }) => !hasBirthDate
      ).length;

      return {
        countMissingBirthdate,
        expected: weanableAnimalList.length,
        name: "Wean (FIXME: get wean from animal docs)",
        value: weanedAnimalList.length,
      };
    },
    getItemStyles(item) {
      const width = (item.value * 100) / item.expected;
      const rounded = Math.round(width * 100) / 100;
      const roundedWidth = rounded + "%";
      return { width: roundedWidth, minWidth: "fit-content" };
    },
    getWidth(item) {
      return `${(item.value * 100) / item.expected}%`;
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>
