<template>
  <v-app>
    <v-main>
      <router-view name="header" />
      <router-view :style="pageOffset" />
      <notifications style="margin-top: 80px" group="forms" />
      <div v-if="getUserID != null || isUserLoggedIn">
        <atlassian-form></atlassian-form>
        <!-- <organization-userid-updated></organization-userid-updated> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AtlassianForm from "./components/dialogs/AtlassianForm.vue";
import TranslationMixin from "@/mixins/Translations";
import OrganizationUpdated from "./components/dialogs/OrganizationUpdated.vue";
import { IsLoggedIn } from "./utils/functions/auth";
import { isMobile } from "mobile-device-detect";

export default {
  name: "App",
  mixins: [TranslationMixin],
  components: {
    "atlassian-form": AtlassianForm,
    "organization-userid-updated": OrganizationUpdated,
  },
  created: function () {
    this.$vuetify.theme.dark = this.getDarkMode;
  },
  computed: {
    ...mapGetters({
      getDarkMode: "User/getDarkMode",
      getUserID: "User/getUserID",
    }),
    isUserLoggedIn: function () {
      return IsLoggedIn();
    },
    pageOffset() {
      const authPages = ["SignIn", "SignUp"].includes(this.$route.name);
      if (authPages) return {};

      const breakpoint = this.$vuetify.breakpoint.name;
      const isSmallScreen = ["xs", "sm"].includes(breakpoint);
      const showSharedDrawer = !isMobile && !isSmallScreen;
      const miniVariant = this.$store.state.User.drawerMiniVariant;
      const marginLeft = !showSharedDrawer ? 0 : miniVariant ? "60px" : "256px";
      return { marginLeft };
    },
  },
  watch: {
    getDarkMode: function (val) {
      const key = `darkmode_${this.getUserID}`;
      localStorage.setItem(key, val);
      this.$vuetify.theme.dark = val;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/global.sass'
</style>
