<template>
  <div>
    <v-progress-circular
      indeterminate
      size="50"
      v-if="loading"
    ></v-progress-circular>
    <div v-else>
      <v-select
        :disabled="disabled"
        :items="statuses"
        :menu-props="{ offsetY: true, closeOnClick: true }"
        :readonly="animal.disallowChanges()"
        @input="
          animal.modify('status', null, 'status', status.value, false, false)
        "
        class="mb-3"
        dense
        hide-details
        item-text="label"
        label="Status"
        return-object
        style="width: 250px"
        v-model="status"
      >
      </v-select>
      <p
        @click="showDialog = true"
        class="mb-0 text-decoration-underline"
        style="color: #877e76; cursor: pointer; width: fit-content"
      >
        History
      </p>
    </div>
    <v-dialog
      v-model="showDialog"
      scrollable
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Status History</h4>
          <v-icon class="buttons" @click="showDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-2">
          <status-table :animalID="animalID" @data-editted="updateStatus" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
import StatusTable from "../tables/statusTable.vue";
export default {
  name: "StatusHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
  },
  components: {
    StatusTable,
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      herdMeta: null,
      loading: false,
      pouches: null,
      showDialog: false,
      status: null,
      statuses: [],
      tableData: [],
    };
  },
  computed: {
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.updateStatus();
    };
    this.loading = true;
    this.statuses = this.getEnumOptions("statuses");
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    await this.updateStatus();
    this.loading = false;
  },
  methods: {
    updateStatus: async function () {
      this.loading = true;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.status = this.animal.doc.derived.summaries.main.status.toLowerCase();
      this.loading = false;
    },
  },
};
</script>
