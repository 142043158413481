<template>
  <div
    class="dashboard-background top-16 w-full z-10"
    style="z-index: 201; position: sticky"
  >
    <div class="d-none d-md-block pb-3 pb-md-0 pt-1">
      <v-card elevation="0">
        <v-tabs :show-arrows="true" center-active>
          <v-tab
            :key="item.title"
            :to="item.path"
            @click="handleHref(item)"
            class="normal-case tracking-normal body-1"
            v-for="item in inPageItems"
          >
            <slot name="tab-content" :item="item">
              {{ item.name }}
              <v-chip
                class="ml-4"
                pill
                v-if="activeBadge != null && $route.path == item.path"
              >
                {{ activeBadge }}
              </v-chip>
              <v-btn
                class="light-blue-button ml-3 normal-case rounded-lg"
                small
                text
                v-if="item.beta"
              >
                Beta
              </v-btn>
            </slot>
          </v-tab>
        </v-tabs>
      </v-card>
    </div>
    <div class="d-block d-md-none pb-3 pb-md-0 pt-3 px-2 px-md-6 px-lg-8">
      <div class="px-8 pt-3 pb-1">
        <v-row>
          <h2 class="mr-1" v-if="activeBadge != null">
            <span class="ma-0 badge badge-success">
              {{ activeBadge }}
            </span>
          </h2>
          <v-select
            :hide-details="true"
            :items="inPageItems"
            :return-object="true"
            :value="currentRoute"
            @change="$router.push($event.path)"
            class="rounded-lg w-32 dashboard-select"
            dense
            elevation="0"
            flat
            item-text="name"
            label=""
            solo
          >
            <template #selection="{ item }">
              <slot class="no-wrap" :item="item" name="tab-content">
                <div>
                  {{ item.name }}
                  <span v-if="item.beta"> - Beta</span>
                </div>
              </slot>
            </template>
          </v-select>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMixin from "@/mixins/Header";
import TranslationMixin from "@/mixins/Translations.js";
import { IsSuperAdmin } from "@/utils/functions/auth.js";

export default {
  mixins: [HeaderMixin, TranslationMixin],
  props: {
    activeBadge: {
      default: null,
    },
    // Override this to draw from another menu
    itemsListName: {
      default: "navGroupItems",
      type: String,
    },
  },
  computed: {
    availableNavGroupItems() {
      const isSuperAdmin = IsSuperAdmin();
      return this[this.itemsListName]
        .filter((item) => isSuperAdmin || !item.adminOnly)
        .map((item) => {
          if (!item.subItems) return item;

          const allowedSubItems = item.subItems
            .filter((subItem) => isSuperAdmin || !subItem.adminOnly)
            .filter((subItem) => !subItem.isDivider);
          if (!allowedSubItems.length) return null;

          return { subItems: allowedSubItems };
        })
        .filter((item) => item);
    },
    subItemsByRoute() {
      const subItemsByRoute = {};
      this.availableNavGroupItems.forEach((item) => {
        item.subItems.forEach(
          (subItem) => (subItemsByRoute[subItem.path] = item.subItems)
        );
      });
      return subItemsByRoute;
    },
    inPageItems() {
      return this.subItemsByRoute[this.$route.path];
    },
    currentRoute() {
      return this.inPageItems.find((item) => item.path === this.$route.path);
    },
  },
  methods: {
    handleHref(item) {
      // In almost all cases :to="item.path" will resolve the route
      // but for "/couchdb/_utils..." vue router won't work and we need
      // to redirect with window.location
      if (!item.href) return;

      window.location = item.href;
    },
  },
};
</script>

<style scoped>
>>> .v-slide-group__prev {
  margin-left: -0.5rem;
}
>>> .v-slide-group__next {
  margin-right: -0.5rem;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  border-bottom: 2px solid rgba(255 255 255 / 0.1);
}
.dashboard-select {
  font-size: 1.2rem;
  font-weight: 600;
}
>>> .v-select__slot {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.theme--dark .dashboard-background {
  background: var(--dark-background);
}
>>> .v-slide-group__content.v-tabs-bar__content > a {
  text-decoration: none !important;
}
>>> .v-slide-group__content.v-tabs-bar__content > a:hover {
  text-decoration: none !important;
}
</style>
