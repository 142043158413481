<template>
  <div>
    <!-- Success -->
    <v-dialog
      max-width="200px"
      persistent
      scrollable
      transition="dialog-transition"
      v-model="showSuccessDialog"
    >
      <v-card>
        <v-card-title
          class="d-flex justify-center align-center"
          style="background-color: #388e3c"
        >
          <h4 class="ma-0 white--text">Success</h4>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="$emit('input', false)" color="primary" text>
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// This is how we can implement v-model on a custom component
export default {
  name: "SuccessDialog",
  props: {
    showSuccessDialog: Boolean,
  },
  model: {
    prop: "showSuccessDialog",
    event: "input",
  },
};
</script>
