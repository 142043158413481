import Vue from "vue";
import VueRouter from "vue-router";
import { IsLoggedIn, IsSuperAdmin } from "../utils/functions/auth";
import About from "../views/About.vue";
import AnimalDetails from "../views/AnimalDetails";
import AnimalDetailsBeta from "../views/AnimalDetailsBeta";
import Animals from "../views/Animals.vue";
import AnimalsLists from "../views/AnimalsLists.vue";
import AnimalsGroups from "../views/AnimalsGroups.vue";
import AnimalsHerds from "../views/AnimalsHerds.vue";
import BullTest from "../views/BullTest.vue";
import CalfWean from "../views/CalfWean.vue";
import Comment from "../views/Comment.vue";
import CommodityTypes from "../views/CommodityTypes.vue";
import Dashboard from "../views/Dashboard.vue";
import DbCleanup from "../views/DbCleanup.vue";
import DbDump from "../views/DbDump.vue";
import DbTriage from "../views/DbTriage.vue";
import DbFix from "../views/DbFix.vue";
import DisplayAnimals from "../views/DisplayAnimals.vue";
import DispositionAnimals from "../views/DispositionAnimals.vue";
import DispositionDetails from "../views/DispositionDetails.vue";
import EULA from "../views/EULA.vue";
import FAQ from "../views/FAQ.vue";
import Feedlot from "../views/Feedlot.vue";
import FeedTrucks from "../views/FeedTrucks.vue";
import FertilizationsAI from "../views/FertilizationsAI.vue";
import FertilizationsCidrImplant from "../views/FertilizationsCidrImplant.vue";
import FertilizationsCidrRemoval from "../views/FertilizationsCidrRemoval.vue";
import FileImport from "../views/FileImport.vue";
import FileImportBeta from "../views/FileImportBeta.vue";
import Header from "@/components/headers/Header";
import HealthTracker from "../views/HealthTracker.vue";
import Legend from "../views/Legend.vue";
import ImportRelationships from "../views/ImportRelationships.vue";
import InboundManifests from "../views/InboundManifests.vue";
import Intake from "../views/Intake.vue";
import Manifest from "../views/Manifest.vue";
import ReportInventory from "../views/ReportInventory.vue";
import MySettings from "../views/MySettings.vue";
import Offspring from "../views/Offspring.vue";
import Organizations from "../views/Organizations.vue";
import Packers from "../views/Packers.vue";
import PastureOrPenHeadCounts from "../views/PastureOrPenHeadCounts.vue";
import Closeout from "../views/Closeout.vue";
import PregCheck from "../views/PregCheck.vue";
import Readers from "../views/Readers.vue";
import ReportCalfBirthSummary from "../views/ReportCalfBirthSummary.vue";
import ReportCalfWeanSummary from "../views/ReportCalfWeanSummary.vue";
import ReportPregnancyTestSummary from "../views/ReportPregnancyTestSummary.vue";
import ReviewDuplicateAnimals from "../views/ReviewDuplicateAnimals.vue";
import SickListEmail from "../views/SickListEmail.vue";
import SignIn from "../views/SignIn.vue";
import SignUpInstructions from "../views/SignUpInstructions.vue";
import TransferAnimals from "../views/TransferAnimals.vue";
import Users from "../views/Users.vue";

Vue.use(VueRouter);

// Sorted by components.path
const routes = [
  {
    path: "/",
    redirect: "/sign-in",
  },
  {
    path: "/about",
    name: "About",
    components: {
      default: About,
      header: Header,
    },
  },
  {
    path: "/ai",
    name: "AI",
    components: {
      default: FertilizationsAI,
      header: Header,
    },
  },
  {
    path: "/animals",
    name: "Animals",
    components: {
      default: Animals,
      header: Header,
    },
  },
  {
    path: "/animal-details",
    name: "AnimalDetails",
    components: {
      default: AnimalDetailsBeta,
      header: Header,
    },
  },
  {
    path: "/animal-details-deprecated",
    name: "AnimalDetailsDeprecated",
    components: {
      default: AnimalDetails,
      header: Header,
    },
  },
  {
    path: "/animals-groups",
    name: "AnimalsGroups",
    components: {
      default: AnimalsGroups,
      header: Header,
    },
  },
  {
    path: "/animals-head-counts",
    name: "PastureOrPenHeadCounts",
    components: {
      default: PastureOrPenHeadCounts,
      header: Header,
    },
  },
  {
    path: "/animals-herds",
    name: "AnimalsHerds",
    components: {
      default: AnimalsHerds,
      header: Header,
    },
  },
  {
    path: "/animals-lists",
    name: "AnimalsLists",
    components: {
      default: AnimalsLists,
      header: Header,
    },
  },
  {
    path: "/bull-test",
    name: "BullTest",
    components: {
      default: BullTest,
      header: Header,
    },
  },
  {
    path: "/calf-wean",
    name: "CalfWean",
    components: {
      default: CalfWean,
      header: Header,
    },
  },
  {
    path: "/cidr-removal",
    name: "CIDR Removal",
    components: {
      default: FertilizationsCidrRemoval,
      header: Header,
    },
  },
  {
    path: "/cidr-implant",
    name: "CIDR Implant",
    components: {
      default: FertilizationsCidrImplant,
      header: Header,
    },
  },
  {
    path: "/comment",
    name: "Comment",
    components: {
      default: Comment,
      header: Header,
    },
  },
  {
    path: "/commodities",
    name: "Commodities",
    components: {
      default: CommodityTypes,
      header: Header,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    components: {
      default: Dashboard,
      header: Header,
    },
  },
  {
    path: "/display-animals",
    name: "DisplayAnimals",
    components: {
      default: DisplayAnimals,
      header: Header,
    },
  },
  {
    path: "/disposition-animals",
    name: "DispositionAnimals",
    components: {
      default: DispositionAnimals,
      header: Header,
    },
  },
  {
    path: "/disposition-details&moveCombined=:moveCombined&paging=:paging",
    name: "DispositionDetails",
    components: {
      default: DispositionDetails,
      header: Header,
    },
  },
  {
    path: "/elasticsearch_01",
    beforeEnter: (to, from, next) => {
      const isLogged = IsLoggedIn();
      fetch("/elasticsearch_01")
        .then((response) => {
          if (response.ok) {
            next();
          } else {
            if (isLogged) next("/dashboard");
            else next("/sign-in");
          }
        })
        .catch((error) => {
          if (isLogged) next("/dashboard");
          else next("/sign-in");
        });
    },
  },
  {
    path: "/eula",
    name: "License Agreement",
    components: {
      default: EULA,
      header: Header,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    components: {
      default: FAQ,
      header: Header,
    },
  },
  {
    path: "/file-import",
    name: "FileImport",
    components: {
      default: FileImport,
      header: Header,
    },
  },
  {
    path: "/file-import-beta",
    name: "FileImportBeta",
    components: {
      default: FileImportBeta,
      header: Header,
    },
  },
  {
    path: "/health-tracker",
    name: "HealthTracker",
    components: {
      default: HealthTracker,
      header: Header,
    },
  },
  {
    path: "/feed-trucks",
    name: "FeedTrucks",
    components: {
      default: FeedTrucks,
      header: Header,
    },
  },
  {
    path: "/alpha-feedlot-beta",
    name: "Feedlot",
    components: {
      default: Feedlot,
      header: Header,
    },
  },
  {
    path: "/import-relationships",
    name: "ImportRelationships",
    components: {
      default: ImportRelationships,
      header: Header,
    },
  },
  {
    path: "/inbound-manifests",
    name: "InboundManifests",
    components: {
      default: InboundManifests,
      header: Header,
    },
  },
  {
    path: "/intake",
    name: "Intake",
    components: {
      default: Intake,
      header: Header,
    },
  },
  {
    path: "/superadmin-legend",
    name: "Legend",
    components: {
      default: Legend,
      header: Header,
    },
  },
  {
    path: "/manifest",
    name: "Manifest",
    components: {
      default: Manifest,
      header: Header,
    },
  },
  {
    path: "/my-settings",
    name: "MySettings",
    components: {
      default: MySettings,
      header: Header,
    },
  },
  {
    path: "/report-inventory",
    name: "ReportInventory",
    components: {
      default: ReportInventory,
      header: Header,
    },
  },
  {
    path: "/offspring",
    name: "Offspring",
    components: {
      default: Offspring,
      header: Header,
    },
  },
  {
    path: "/organizations",
    name: "Organizations",
    components: {
      default: Organizations,
      header: Header,
    },
  },
  {
    path: "/closeout",
    name: "Pen Summary",
    components: {
      default: Closeout,
      header: Header,
    },
  },
  {
    path: "/preg-check",
    name: "PregCheck",
    components: {
      default: PregCheck,
      header: Header,
    },
  },
  {
    path: "/readers-and-groups",
    name: "Readers",
    components: {
      default: Readers,
      header: Header,
    },
  },
  {
    path: "/report-calf-birth-summary",
    name: "ReportCalfBirthSummary",
    components: {
      default: ReportCalfBirthSummary,
      header: Header,
    },
  },
  {
    path: "/report-calf-wean-summary",
    name: "ReportCalfWeanSummary",
    components: {
      default: ReportCalfWeanSummary,
      header: Header,
    },
  },
  {
    path: "/report-pregnancy-test-summary",
    name: "ReportPregnancyTestSummary",
    components: {
      default: ReportPregnancyTestSummary,
      header: Header,
    },
  },
  {
    path: "/review-duplicate-animals",
    name: "ReviewDuplicateAnimals",
    components: {
      default: ReviewDuplicateAnimals,
      header: Header,
    },
  },
  {
    path: "/superadmin-db-cleanup",
    name: "DbCleanup",
    components: {
      default: DbCleanup,
      header: Header,
    },
  },
  {
    path: "/superadmin-db-dump",
    name: "DbDump",
    components: {
      default: DbDump,
      header: Header,
    },
  },
  {
    path: "/superadmin-db-triage",
    name: "DbTriage",
    components: {
      default: DbTriage,
      header: Header,
    },
  },
  {
    path: "/superadmin-db-fix",
    name: "DbFix",
    components: {
      default: DbFix,
      header: Header,
    },
  },
  {
    path: "/superadmin-packers",
    name: "Packers",
    components: {
      default: Packers,
      header: Header,
    },
  },
  {
    path: "/superadmin-sick-list-email",
    name: "SickListEmail",
    components: {
      default: SickListEmail,
      header: Header,
    },
  },
  {
    beforeEnter: (to, from, next) => {
      const isLogged = IsLoggedIn();
      if (isLogged) {
        router.replace({ name: "Dashboard" }).catch(console.log);
      } else {
        next();
      }
    },
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    // disabled for now until we monitor sign ups and prevent competitors from peeking at our software.
    // component: SignUp,
    component: SignUpInstructions,
  },
  {
    path: "/transfer-animals",
    name: "TransferAnimals",
    components: {
      default: TransferAnimals,
      header: Header,
    },
  },
  {
    path: "/users",
    name: "Users",
    components: {
      default: Users,
      header: Header,
    },
  },
  {
    path: "*",
    redirect: (to) => {
      // In case that vue redirects to index.html
      // and apply SPA logic instead of server logic
      // handle these routes
      if (
        to.path === "/elasticsearch_01" ||
        to.path === "/elasticsearch_02" ||
        to.path === "/kibana"
      ) {
        return false;
      } else {
        if (IsLoggedIn()) return "/dashboard";
        return "/sign-in";
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLogged = IsLoggedIn();
  const isSuperAdmin = IsSuperAdmin();
  if (
    (to.name !== "SignIn" && to.name !== "SignUp" && !isLogged) ||
    (!isSuperAdmin && to.path && !!to.path.match(/^\/(alpha-|superadmin-)/))
  ) {
    next({ name: "SignIn" });
  } else {
    next();
  }
});

export default router;
