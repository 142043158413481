<template>
  <div>
    <in-page-menu :activeBadge="countForGroupNumber || '?'" />
    <div class="mx-7 mx-md-6 mx-lg-8 pt-20">
      <v-row>
        <v-col cols="12" lg="6" class="pb-sm-0">
          <div class="panel panel-default" v-if="$isMobileScreen">
            <div
              :class="{
                'bg-dark': $vuetify.theme.dark,
              }"
              class="card card-body"
            >
              <h4 class="panel-title">
                <a data-toggle="collapse" href="#entry">Calf Wean Entry </a>
              </h4>
            </div>
          </div>
          <calf-wean-entry
            :class="{ collapse: $isMobileScreen }"
            id="entry"
          ></calf-wean-entry>
        </v-col>
        <v-col cols="12" lg="6" class="py-sm-0 d-flex flex-column">
          <div :style="{ order: $isMobileScreen ? 2 : 1 }">
            <div class="panel panel-default" v-if="$isMobileScreen">
              <div
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
                class="card card-body"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#statistics"
                    >Group Statistics</a
                  >
                </h4>
              </div>
            </div>
            <calf-wean-group-statistics
              :class="{ collapse: $isMobileScreen, 'mt-3': !$isMobileScreen }"
              id="statistics"
            ></calf-wean-group-statistics>
          </div>
          <div :style="{ order: $isMobileScreen ? 1 : 2 }">
            <div class="panel panel-default" v-if="$isMobileScreen">
              <div
                :class="{
                  'bg-dark': $vuetify.theme.dark,
                }"
                class="card card-body"
              >
                <h4 class="panel-title">
                  <a data-toggle="collapse" href="#information"
                    >Animal Information</a
                  >
                </h4>
              </div>
            </div>
            <calf-wean-information
              :class="{ collapse: $isMobileScreen }"
              id="information"
            ></calf-wean-information>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="py-sm-0 py-lg-auto">
          <div class="panel panel-default" v-if="$isMobileScreen">
            <div
              :class="{
                'bg-dark': $vuetify.theme.dark,
              }"
              class="card card-body mb-4"
            >
              <h4 class="panel-title">
                <a data-toggle="collapse" href="#summary">Summary</a>
              </h4>
            </div>
          </div>
          <calf-wean-summary
            :class="{ collapse: $isMobileScreen }"
            id="summary"
          ></calf-wean-summary>
        </v-col>
      </v-row>
      <v-dialog
        max-width="280"
        persistent
        scrollable
        transition="dialog-transition"
        v-model="showAlerts"
      >
        <v-card class="mx-auto">
          <v-card-title class="text-h5 warning">
            <v-icon>mdi-alert</v-icon>
            <div class="ml-2">Alert</div>
          </v-card-title>
          <v-card-text
            class="d-flex align-center justify-center pa-0"
            style="height: 70px; width: 100%"
          >
            <div class="text-h5 text--primary">Cull status detected</div>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              :color="$vuetify.theme.dark ? 'teal accent-2' : 'teal darken-1'"
              @click="showAlerts = false"
              text
            >
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-fab-transition v-if="getStatus.toLowerCase() == 'cull'">
        <v-btn
          :key="'showAlerts'"
          @click="showAlerts = true"
          bottom
          class="v-btn--example"
          color="warning"
          dark
          elevation="5"
          fab
          fixed
          large
          right
        >
          <v-icon>mdi-alert</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import { mapGetters } from "vuex";
import CalfWeanEntry from "@/components/calfWean/CalfWeanEntry.vue";
import CalfWeanGroupStatistics from "@/components/calfWean/GroupStatistics.vue";
import CalfWeanInformation from "@/components/calfWean/CalfInformation.vue";
import CalfWeanSummaryVue from "../components/calfWean/CalfWeanStatistics.vue";
export default {
  name: "calf-wean",
  metaInfo: {
    title: "Calf Wean",
  },
  created: function () {
    EventBus.$on("wean-total", (count) => {
      this.countForGroupNumber = count;
    });
  },
  components: {
    "calf-wean-entry": CalfWeanEntry,
    "calf-wean-group-statistics": CalfWeanGroupStatistics,
    "calf-wean-information": CalfWeanInformation,
    "calf-wean-summary": CalfWeanSummaryVue,
  },
  data: () => ({
    countForGroupNumber: null,
    showAlerts: false,
  }),
  computed: {
    ...mapGetters({ getStatus: "CalfInformation/getStatus" }),
  },
  beforeDestroy: function () {
    EventBus.$off("new-calf-entry");
  },
  watch: {
    getStatus: function (status) {
      if (status.toLowerCase() == "cull") this.showAlerts = true;
      else this.showAlerts = false;
    },
  },
};
</script>
