<template>
  <svg viewBox="0 0 20 20">
    <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        d="M10.5858 1.49521C11.3668 0.714162 12.6332 0.714162 13.4142 1.49521C14.1953 2.27626 14.1953 3.54259 13.4142 4.32364L12.6213 5.11653L9.79289 2.2881L10.5858 1.49521Z"
      />
      <path
        :fill="fill"
        d="M8.37868 3.70232L0 12.081V14.9094H2.82842L11.2071 6.53074L8.37868 3.70232Z"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
