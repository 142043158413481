var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.bredTo && _vm.bredTo.sireId)?_c('div',{staticClass:"g-row mb-4"},[_c('h4',{staticClass:"mb-0 mr-2 title-style"},[_vm._v("Bred To")]),_c('div',{staticClass:"g-row"},[_c('router-link',{staticClass:"h4 mb-0",attrs:{"to":{
          name: 'AnimalDetails',
          query: { id: _vm.bredTo.sireId },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.bredTo.sireTags)+" ")])],1)]):_vm._e(),_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0 title-style"},[_vm._v("Calving History")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Calving_History " + (this.animalID) + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.offspringTags",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"subtitle-2",attrs:{"to":{
          name: 'AnimalDetails',
          query: { id: item.animalId },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.offspringTags)+" ")])]}},{key:"item.birthDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.birthDate, "datetime", true))+" ")]}},{key:"item.createdOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }