<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div class="my-6">
          <h4>{{ t.title }}</h4>
          <v-divider></v-divider>
        </div>
        <p>
          Labore in in enim ea voluptate amet tempor reprehenderit quis qui ut
          cupidatat deserunt officia pariatur non ullamco cupidatat aliqua ut
          sint voluptate pariatur sed fugiat elit occaecat anim pariatur et
          occaecat veniam velit ut exercitation eu mollit eu ullamco adipisicing
          ut commodo ut deserunt qui labore est officia laboris proident amet in
          voluptate pariatur do qui cupidatat esse officia aliqua proident
          mollit nisi laborum nulla tempor ut ut ea cillum sed nisi consectetur
          consectetur veniam nisi dolore ea ullamco veniam velit pariatur nisi
          ad elit adipisicing dolor officia aliqua dolor fugiat nisi sint
          excepteur amet ad nostrud reprehenderit velit elit consectetur in
          officia aliqua velit ut duis exercitation minim voluptate amet labore
          ad ullamco excepteur velit cupidatat amet ex cillum sit minim ut
          tempor laborum ut exercitation exercitation incididunt cillum
          exercitation velit id sed laboris dolor quis voluptate cupidatat et
          aute occaecat consequat incididunt consequat labore sed culpa est
          aliquip pariatur aute ea anim deserunt cillum eiusmod nulla duis in
          magna consectetur velit ex est fugiat in do do sed laboris incididunt
          ea sed ea dolor excepteur id esse aliqua aliquip officia velit dolor
          dolor incididunt consequat occaecat proident sed ea cillum sint minim
          ut fugiat proident aliqua consectetur elit excepteur deserunt
          excepteur amet sint enim proident dolor dolore exercitation enim.
        </p>

        <p>
          Et eiusmod ut in amet duis exercitation laborum id cillum cillum
          excepteur est ex deserunt ea in cillum sunt dolore id labore velit
          commodo do enim incididunt culpa labore in duis ut laboris officia
          sunt reprehenderit proident est pariatur elit magna consectetur sint
          cillum sed fugiat anim pariatur labore enim eu est in consectetur
          cupidatat anim irure enim in nisi veniam qui nulla amet do laboris
          pariatur exercitation aliquip cillum nisi magna eu eiusmod in dolor
          velit nostrud anim officia incididunt voluptate ea dolor ut ullamco
          incididunt dolor veniam aliqua sit laborum cillum cupidatat culpa amet
          exercitation consequat eiusmod ad anim exercitation cupidatat labore
          qui magna minim esse laboris amet sunt id irure do elit ullamco labore
          in do aliquip irure in non ut ut cillum cupidatat fugiat ad cillum
          veniam sed exercitation aliquip mollit ullamco ut sunt ea duis
          pariatur velit in velit deserunt esse dolore aute commodo ut deserunt
          quis minim labore laboris officia incididunt dolore consectetur veniam
          voluptate esse do ea eiusmod dolore et sunt dolore dolor dolore
          eiusmod cillum dolore minim do enim minim sint officia sunt voluptate
          excepteur pariatur velit adipisicing et est laborum esse in cillum.
        </p>

        <p>
          Excepteur ex id id aliquip officia esse enim irure est quis do
          incididunt exercitation labore officia nisi amet laboris officia
          dolore velit id commodo excepteur cupidatat esse occaecat dolor dolore
          et exercitation commodo ad cillum eiusmod et duis ullamco ut enim elit
          ut id aliqua sed eu ut proident mollit mollit cupidatat proident nisi
          esse proident exercitation cillum mollit sint sit irure aliquip et
          dolor sunt deserunt dolore pariatur excepteur voluptate duis dolore
          aute excepteur nisi duis adipisicing laborum et non ullamco ut
          consectetur qui nostrud labore eiusmod sed enim cillum minim culpa
          laboris velit do nisi sint ad et ut dolore officia ut officia ullamco
          et commodo in sit duis non anim ut elit ex nostrud eu voluptate minim
          ut in laboris esse sit cillum ut commodo amet consectetur mollit et
          laboris do voluptate amet do culpa id aliqua dolor tempor dolor in ea
          proident qui reprehenderit occaecat occaecat in ullamco consectetur ut
          veniam pariatur dolor laborum velit duis commodo laborum eiusmod
          exercitation elit dolor consequat sit proident magna sit in deserunt
          cillum amet sint sed sit laboris commodo magna amet proident tempor
          aliquip consequat aliqua nisi dolore pariatur tempor excepteur officia
          aliquip ut enim do fugiat amet veniam est non excepteur irure anim
          dolore culpa nulla minim laborum dolor ut.
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TranslationMixin from "@/mixins/Translations";
export default {
  name: "About",
  metaInfo: {
    title: "About",
  },
  mixins: [TranslationMixin],
  data: () => ({
    table: null,
  }),
  computed: {
    t: function () {
      return {
        title: this.translate.ourStory[this.localeLang],
      };
    },
  },
};
</script>
