var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0"},[_vm._v("Harvest History")]):_vm._e(),(_vm.animal.guid)?_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Harvest_History_" + (_vm.animal.guid) + "_" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 dashboard-data-table",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.animal.disallowChanges(),
        },attrs:{"disabled":_vm.animal.disallowChanges(),"color":"error","max-width":"36","min-width":"36","small":""},on:{"click":function($event){return _vm.deleteRecord(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }