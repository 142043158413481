<template>
  <v-navigation-drawer
    :mini-variant-width="miniVariantWidth"
    class="drawer dashboard-background"
    style="z-index: 202 !important"
    v-bind="{ ...drawerConfig, ...miniVariantBinding }"
    v-model="drawer"
  >
    <v-list-item v-if="allowMiniVariant" @click="mini = !mini">
      <v-list-item-icon>
        <v-icon v-if="mini">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Minimize</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list class="d-flex flex-column" dense nav>
      <v-list-item v-if="showUserMenu">
        <user-menu class="user-menu" :collapsible="false" />
      </v-list-item>
      <v-list-item v-if="showOrganizationSwitcher">
        <organization-switcher :animal-count="totalAnimals" />
      </v-list-item>
      <v-list>
        <v-list-item
          :class="{ 'nav-item-active': isActiveGroupedItem(item) }"
          :to="item.path"
          active-class="nav-item-active"
          class="nav-item"
          v-for="item in availableNavItems"
          :key="item.name"
        >
          <v-list-item-icon>
            <v-icon :dark="isActiveGroupedItem(item)"> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
            <v-list-item-subtitle v-if="item.beta"> Beta </v-list-item-subtitle>
          </v-list-item-content>
          <span
            :class="$vuetify.theme.dark ? 'badge-light' : 'badge-warning'"
            class="badge badge-pill ml-1"
            v-if="item.displayRowCountBadge"
          >
            <h6 class="ma-0 pa-0" v-if="getRowsCount !== null">
              {{ getRowsCount }}
            </h6>
          </span>
        </v-list-item>
        <v-divider v-if="mini"></v-divider>
        <template v-for="(item, $index) in availableNavGroupItemsWithPath">
          <v-divider v-if="mini && item.isDivider"></v-divider>
          <v-list-item
            :class="{
              'nav-item-active v-list-item--active': isActiveGroupedItem(item),
            }"
            :key="$index"
            :to="item.path"
            active-class="nav-item-active"
            class="nav-item"
          >
            <v-list-item-icon>
              <v-icon :dark="isActiveGroupedItem(item)">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
              <v-list-item-subtitle v-if="item.beta">
                Beta
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-list>
    <template v-slot:append>
      <v-list dense nav rounded v-if="showAppendContent">
        <v-list-item>
          <v-list-item-icon class="mr-5">
            <v-avatar color="#3465a1" size="35">
              <span class="font-weight-regular text-white">
                {{ $nameInitials }}
              </span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span
                :class="{ 'grey--text text--darken-2': !$vuetify.theme.dark }"
                class="align-sub inline-block normal-case text-h6"
              >
                {{ getName }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="nav-item-active"
          class="nav-item"
          to="/my-settings"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-list-item
              active-class="nav-item-active"
              class="nav-item"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>mdi-translate</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Language</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              :key="index"
              @click="changeLanguage(language)"
              dense
              link
              v-for="(language, index) in languages"
            >
              <v-list-item-title>
                <v-icon v-if="language.key == localeLang">
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-icon v-else> mdi-checkbox-blank-circle-outline </v-icon>
                {{ language.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item
          @click.stop="toggleDarkMode"
          active-class="nav-item-active"
          class="nav-item"
        >
          <v-list-item-icon>
            <v-icon v-if="$vuetify.theme.dark">
              mdi-moon-waning-crescent
            </v-icon>
            <v-icon v-else>mdi-white-balance-sunny</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dark mode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="showLogoutDialog = true"
          active-class="nav-item-active"
          class="nav-item"
        >
          <v-list-item-icon>
            <v-icon> mdi-logout </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <dev-tools v-if="isSuperAdmin" />
      <div
        :class="{ 'd-none': $store.state.User.drawerMiniVariant }"
        class="pt-10 pb-5"
        v-if="!$store.state.User.drawerMiniVariant"
      >
        <img :src="require('@/assets/icons/powered-by.svg')" height="60" />
      </div>
    </template>
    <logout-dialog
      @close-logout-dialog="showLogoutDialog = false"
      v-model="showLogoutDialog"
    />
  </v-navigation-drawer>
</template>

<script>
import { IsSuperAdmin } from "../../utils/functions/auth";
import { mapGetters, mapActions } from "vuex";
import DevTools from "@/components/DevTools";
import HeaderMixin from "@/mixins/Header";
import LogoutDialog from "@/components/dialogs/LogoutDialog";
import OrganizationSwitcher from "@/components/headers/OrganizationSwitcher.vue";
import TranslationMixin from "@/mixins/Translations.js";
import UserMenu from "@/components/headers/UserMenu.vue";

export default {
  props: {
    drawerConfig: {
      type: Object,
      default: () => ({
        fixed: true,
        floating: true,
        permanent: true,
      }),
    },
    showUserMenu: {
      default: true,
      type: Boolean,
    },
    showOrganizationSwitcher: {
      default: true,
      type: Boolean,
    },
    showAppendContent: {
      default: false,
      type: Boolean,
    },
    allowMiniVariant: {
      default: true,
      type: Boolean,
    },
  },
  mixins: [HeaderMixin, TranslationMixin],
  components: {
    LogoutDialog,
    OrganizationSwitcher,
    UserMenu,
    DevTools,
  },
  data() {
    return {
      drawer: true,
      mini: this.$store.state.User.drawerMiniVariant,
      miniVariantWidth: 60,
      showLogoutDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      getName: "User/getName",
      getRowsCount: "Rows/getRowsCount",
    }),
    isSuperAdmin() {
      return IsSuperAdmin();
    },
    availableNavItems() {
      const items = this.navItems.filter(
        (item) => this.isSuperAdmin || !item.adminOnly
      );
      items.forEach((item) => {
        item.subItemPaths = item.subItems.map((subItem) => subItem.path);
      });
      return items;
    },
    availableNavGroupItems() {
      return this.navGroupItems
        .filter((item) => this.isSuperAdmin || !item.adminOnly)
        .map((item) => {
          if (!item.subItems) {
            return item;
          } else {
            const allowedSubItems = item.subItems
              .filter((subItem) => this.isSuperAdmin || !subItem.adminOnly)
              .filter((subItem) => !subItem.isDivider);
            if (allowedSubItems.length == 0) return null;
            return {
              beta: item.beta,
              icon: item.iconVertical || item.icon,
              isDivider: item.isDivider,
              name: item.name,
              subItems: allowedSubItems,
              iconVertical: item.iconVertical,
              iconHorizontal: item.iconHorizontal,
            };
          }
        })
        .filter((item) => !!item);
    },
    availableNavGroupItemsWithPath() {
      return this.availableNavGroupItems
        .filter((item) => item.subItems.length)
        .map((item) => {
          return {
            path: item.subItems[0].path,
            subItemPaths: item.subItems.map((subItem) => subItem.path),
            ...item,
          };
        });
    },
    miniVariantBinding() {
      return this.allowMiniVariant ? { "mini-variant": this.mini } : {};
    },
  },
  methods: {
    ...mapActions({ setDarkMode: "User/setDarkMode" }),
    toggleDarkMode() {
      this.setDarkMode({ darkMode: !this.$vuetify.theme.dark });
    },
    toggle() {
      this.drawer = !this.drawer;
    },
    isActiveGroupedItem(item) {
      return (
        item.path === this.$route.path ||
        item.subItemPaths.includes(this.$route.path)
      );
    },
  },
  watch: {
    mini(value) {
      this.$store.dispatch("User/setDrawerMiniVariant", value);
    },
    drawer(value) {
      this.$emit("drawer-changed", value);
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  max-height: calc(100% - 64px);
  top: unset !important;
}
.invisible {
  visibility: hidden;
}
.theme--dark .nav-item-active {
  color: white;
}
.theme--light .nav-item-active {
  background: var(--dark-blue);
  color: white !important;
}
.theme--light .nav-item-active::before {
  opacity: 0.12;
}
.nav-item {
  border-radius: 8px;
}
.nav-item .v-list-item__title {
  font-size: 0.9rem;
  font-weight: normal;
}
>>> .theme--light .v-list-group__header.v-list-item--active {
  color: var(--dark-blue);
}

a.nav-item {
  text-decoration: none !important;
}
a.nav-item:hover {
  text-decoration: none !important;
}
</style>
