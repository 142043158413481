<template>
  <svg viewBox="0 0 20 20">
    <svg fill="none" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        :fill="fill"
        clip-rule="evenodd"
        d="M6.01587 5.68188C8.28051 3.41724 11.9522 3.41724 14.2169 5.68188C16.4815 7.94653 16.4815 11.6182 14.2169 13.8829L10.1164 17.9834L6.01587 13.8829C3.75122 11.6182 3.75122 7.94653 6.01587 5.68188ZM10.1164 11.4392C11.0314 11.4392 11.7732 10.6974 11.7732 9.78239C11.7732 8.86733 11.0314 8.12553 10.1164 8.12553C9.20132 8.12553 8.45952 8.86733 8.45952 9.78239C8.45952 10.6974 9.20132 11.4392 10.1164 11.4392Z"
        fill-rule="evenodd"
      />
    </svg>
  </svg>
</template>

<script>
export default {
  computed: {
    fill() {
      if (this.$parent.$attrs.primary) {
        return "rgb(41, 80, 143)";
      } else {
        return this.$vuetify.theme.dark ? "white" : "#877E76";
      }
    },
  },
};
</script>
