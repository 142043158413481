<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0 title-style" v-if="showTitle">
        Heifer Test History
      </h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Heifer_Test_History_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled"
          @click="deleteHeiferTest(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.createdOn="{ item }">
        <div style="max-width: 250px">
          {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
        </div>
      </template>
      <template #item.pelvicMeasure="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'heiferTests',
              item.id,
              'pelvicMeasure',
              item.pelvicMeasure,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          style="max-width: 80px"
          outlined
          v-model="item.pelvicMeasure"
        >
        </v-text-field>
      </template>
      <template #item.testCheckTime="{ item }">
        <div style="max-width: 250px">
          {{ $utils.renderValueAs(item.testCheckTime, "datetime", true) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
  <script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "HeiferTestHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    tableHeaders: function () {
      return [
        { text: this.getTranslation("actions"), value: "actions" },
        { text: this.getTranslation("date"), value: "testCheckTime" },
        { text: this.getTranslation("pelvicMeasure"), value: "pelvicMeasure" },
        { text: this.getTranslation("test"), value: "test" },
        { text: this.getTranslation("result"), value: "result" },
        { text: this.getTranslation("testedBy"), value: "testedBy" },
        { text: this.getTranslation("testMethod"), value: "testMethod" },
        { text: this.getTranslation("comment"), value: "comment" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getTestHistory();
    },
    getTestHistory: function () {
      this.table.loading = true;
      return this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.heiferTestHistory || [];
        })
        .catch((e) => {
          console.log(e);
          throw e;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteHeiferTest: async function (item) {
      try {
        await this.animal.deleteRecord("heiferTests", item.id);
        this.getTestHistory();
        this.$emit("data-editted");
        this.$notify({
          group: "forms",
          text: "Heifer deleted",
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
  },
};
</script>
  <style scoped>
.title-style {
  font-weight: 600;
  font-size: 22px;
}
</style>
  