import { EventBus } from "./Config";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import TranslationMixin from "./Translations";

const HeaderMixin = {
  data: () => ({
    changeWatch: null,
    devicesConnected: null,
    intervals: [],
    next_seq: null,
    pouchSyncInfo: null,
    processing: false,
    searchDialog: {
      resetFields: false,
      show: false,
    },
    showOrganizationDialog: false,
    speed: null,
    totalAnimals: null,
    update_seq: null,
    updating: false,
  }),
  mixins: [TranslationMixin],
  created: function () {
    axios
      .get("/api/users/self/organizations", {
        params: {
          token: this.getUserToken(),
        },
      })
      .then(({ status, data }) => {
        if (status !== 200) {
          this.showErrorOrganizations = true;
          return;
        }
        this.setOrganizationItems({ items: data.organizations });

        // Refresh organization and name
        const orgID = this.getOrganizationID();
        const org = data.organizations.find(({ id }) => id == orgID);
        if (!org) {
          // User appears to have lost permission to access current organization
          // Set up switch
          // Use first listed organization
          this.setOrganizationID({
            organizationID: data.organizations[0].id,
          });
          this.setOrganizationName({ name: data.organizations[0].name });
        } else {
          // Refresh name
          this.setOrganizationName({
            name: org.name || this.getOrganizationName(),
          });
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          console.log(error.response.data);
        else console.log("Error", error.message);
        this.showErrorOrganizations = true;
      });
  },
  mounted: async function () {
    // Wait a bit so that 'local_views/*' exist
    this.intervals.push(
      setTimeout(() => {
        this.updateTotalAnimals(null);
      }, 1000)
    );

    EventBus.$on("herd-connect-destroyed", () => {
      this.devicesConnected = null;
    });

    EventBus.$on("devices", (devices) => {
      this.devicesConnected =
        devices && devices.length
          ? devices.filter(({ connected }) => !!connected)
          : "not valid";
    });

    EventBus.$on("pouch-paused", () => {
      this.updateTotalAnimals(null);
      const current = this.pouchSyncInfo;
      // Avoid stomping (couch might pause and then re-initiate)
      this.intervals.push(
        setTimeout(
          () => {
            if (
              this.pouchSyncInfo &&
              (!current || current.start_time === this.pouchSyncInfo.start_time)
            )
              this.pouchSyncInfo = null;
          },
          // delay so even small, fast syncs end up obvious
          2000
        )
      );
    });

    EventBus.$on("pouch-changed", (pouchSyncInfo) => {
      this.speed = "fast";
      this.pouchSyncInfo = pouchSyncInfo;

      const current = this.pouchSyncInfo;
      this.intervals.push(
        setTimeout(
          () => {
            if (
              this.pouchSyncInfo &&
              current.start_time === this.pouchSyncInfo.start_time
            ) {
              this.speed = "medium";
            }
          },
          // detect slow connection
          5000
        )
      );
      this.intervals.push(
        setTimeout(
          () => {
            if (
              this.pouchSyncInfo &&
              current.start_time === this.pouchSyncInfo.start_time
            ) {
              this.speed = "slow";
            }
          },
          // detect super slow connection (or crashed connection)
          10000
        )
      );
      this.intervals.push(
        setTimeout(
          () => {
            if (
              this.pouchSyncInfo &&
              current.start_time === this.pouchSyncInfo.start_time
            ) {
              this.speed = "super slow";
            }
          },
          // detect possibly dead connection (or crashed connection)
          20000
        )
      );
    });

    EventBus.$on("pouch-failed", () => {
      this.speed = "error";
      this.pouchSyncInfo = {
        direction: "push",
      };
    });

    EventBus.$on("pouch-init-failed", () => {
      this.speed = "error";
      this.pouchSyncInfo = {
        direction: "push",
      };
    });

    this.setUpWatch();
  },
  beforeDestroy: function () {
    // Memory leak still occurs even though we call this. It seems that it is not firing at all or not soon enough.
    // TODO: Find way to fix this memory leak
    if (this.changeWatch && this.changeWatch.cancel) this.changeWatch.cancel();

    EventBus.$off("devices");
    EventBus.$off("herd-connect-destroyed");
    EventBus.$off("pouch-changed");
    EventBus.$off("pouch-failed");
    EventBus.$off("pouch-init-failed");
    EventBus.$off("pouch-paused");

    this.intervals.forEach((interval) => clearInterval(interval));
  },
  methods: {
    // When database changes -> update
    ...mapActions({
      setOrganizationID: "Organization/setOrganizationID",
      setOrganizationItems: "Organization/setOrganizationItems",
      setOrganizationName: "Organization/setName",
      setRowsCount: "Rows/setRowsCount",
      setOrganizationID: "Organization/setOrganizationID",
    }),
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
      getOrganizationName: "Organization/getName",
      getUserToken: "User/getToken",
    }),
    openSearchAnimalDialog: function () {
      this.searchDialog.show = true;
      this.searchDialog.resetFields = !this.searchDialog.resetFields;
    },
    setUpWatch: function () {
      this.changeWatch = localStorage.getItem("pouch-watcher");
      if (!this.changeWatch) {
        this.changeWatch = this.$herdMeta.pouches.organization
          .changes({
            filter: function (doc) {
              return doc.tableName === "animals";
            },
            live: true,
            return_docs: false,
            since: "now",
          })
          .on("change", (change) => {
            this.updateTotalAnimals(change.seq);
          })
          .on("error", function (err) {
            // handle errors
          });

        localStorage.setItem("pouch-watcher", JSON.stringify(this.changeWatch));
      } else {
        this.changeWatch = JSON.parse(this.changeWatch);
      }
    },
    updateSummaries: function (lastAnimalEditTime, totalAnimals) {
      console.debug(
        "selected: totalAnimals, lastAnimalEditTime, for: update summary for organization",
        { totalAnimals, lastAnimalEditTime }
      );
      axios
        .patch(
          `/api/organizations/${this.$organizationID}/summary/${lastAnimalEditTime}/${totalAnimals}`,
          null,
          {
            params: {
              token: this.getUserToken(),
            },
            validateStatus: (status) => status === 200 || status === 304,
          }
        )
        .then(({ status, data }) => {
          if (status === 200)
            console.info("success: update summary", { status, data });
          if (status === 304)
            console.info("ignored: update summary", { status, data });
        })
        .catch((error) => {
          console.error("failed: update summary", error.message);
          this.showErrorOrganizations = true;
        });
    },
    updateTotalAnimals: function (update_seq) {
      if (update_seq !== null && this.update_seq >= update_seq) return;
      this.next_seq = null;
      this.update_seq = update_seq;

      if (this.updating) {
        // Rather than update now, let current query finish and newest request will be fired after that
        this.next_seq = update_seq;
        return;
      }
      this.updating = true;

      const self = this;
      return new Promise((resolve, reject) => {
        const start = moment();
        this.$herdMeta.pouches.organization
          .query("local_views/totalAnimalsAliveCullSick")
          .then((results) => {
            console.debug("updateTotalAnimals().time", moment() - start);
            if (!results.rows.length) {
              this.totalAnimals = 0;
              reject("Rows are empty");
            } else {
              this.totalAnimals = results.rows[0].value;
              resolve();
            }

            if (self.next_seq && update_seq !== null) {
              // Fire off the next request
              self.updateTotalAnimals(self.next_seq);
              return;
            }

            // Updates might be complete, go ahead and compute the lastAnimalEditTime
            this.$herdMeta.pouches.organization
              .query("local_views/lastAnimalEditTime")
              .then((results) => {
                const lastAnimalEditTime = results.rows.find(
                  (item) => item
                ).value;
                self.updateSummaries(lastAnimalEditTime, this.totalAnimals);
                self.updating = false;
              })
              .catch((e) => {
                self.updating = false;
                reject(e);
              });
          })
          .catch((e) => {
            self.updating = false;
            reject(e);
          });
      }).catch((_e) => {});
    },
    openOrganizationDialog: function () {
      this.showOrganizationDialog = true;
    },
    closeOrganizationDialog: function () {
      this.showOrganizationDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
      getRowsCount: "Rows/getRowsCount",
      name: "User/getName",
      type: "User/getType",
      // deepcode ignore InsecureHash: <False alarm.>
      username: "User/getUserEmail",
    }),
    disableOrganizationDialog: function () {
      return this.getOrganizationItems.length == 1;
    },
    navItems: function () {
      // Cannot merge with navGroupItems because this has no children and UX has been built to automatically hide items that have no children
      return [
        {
          icon: "$dashboard",
          name: this.getLabelTranslation("Dashboard"),
          path: "/dashboard",
          subItems: [],
        },
      ];
    },
    // View will automatically hide main elements that do not have any children elements
    navGroupItems: function () {
      // TODO: Store paths in i18n and make one for each locale
      // Note that if a user changes language we'd have to redirect to the new URL
      return [
        {
          displayRowCountBadge: true,
          icon: "$headWithTag",
          name: this.getTranslation("navbar.animals"),
          path: "/animals",
          subItems: [
            {
              name: this.getTranslation("All"),
              path: "/animals",
            },
            {
              name: this.getTranslation("animalsPages.herds"),
              path: "/animals-herds",
            },
            {
              name: this.getLabelTranslation("Groups"),
              path: "/animals-groups",
            },
            {
              name: this.getLabelTranslation("navbar.locationHeadCounts"),
              path: "/animals-head-counts",
            },
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("Inbound Manifests"),
              path: "/inbound-manifests",
            },
            {
              name: this.getTranslation("lists"),
              path: "/animals-lists",
            },
          ],
        },
        {
          icon: "$cowCalf",
          name: this.getTranslation("create"),
          path: "/offspring",
          subItems: [
            {
              name: this.getTranslation("navbar.offspring"),
              path: "/offspring",
            },
            {
              name: this.getTranslation("navbar.commentInField"),
              path: "/comment",
            },
            {
              name: this.getTranslation("navbar.intake"),
              path: "/intake",
            },
            {
              name: this.getTranslation("navbar.bullTest"),
              path: "/bull-test",
            },
            {
              name: this.getTranslation("navbar.pregCheck"),
              path: "/preg-check",
            },
            {
              name: this.getTranslation("navbar.calfWean"),
              path: "/calf-wean",
            },
            {
              name: this.getTranslation("AI"),
              path: "/ai",
            },
            {
              name: this.getTranslation("CIDR Implant"),
              path: "/cidr-implant",
            },
            {
              name: this.getTranslation("CIDR Removal"),
              path: "/cidr-removal",
            },
          ],
        },
        {
          // icon: "mdi-crosshairs-gps",
          icon: "mdi-track-light",
          name: this.getTranslation("navbar.fastTrack"),
          subItems: [
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.displayAnimals"),
              path: "/display-animals",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.disposition"),
              path: "/disposition-animals",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.healthTracker"),
              path: "/health-tracker",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("Manifest"),
              path: "/manifest",
            },
          ],
        },
        {
          icon: "mdi-file-import",
          name: this.getTranslation("import"),
          subItems: [
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.fileETL"),
              path: "/file-import",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("importRelationships.title"),
              path: "/import-relationships",
            },
            {
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.fileETL") + " (New)",
              path: "/file-import-beta",
            },
          ],
        },
        {
          // icon: "$reports",
          icon: "mdi-chart-line",
          name: this.getTranslation("reports"),
          subItems: [
            {
              name: "Calf Birth Summary",
              path: "/report-calf-birth-summary",
            },
            {
              name: "Calf Wean Summary",
              path: "/report-calf-wean-summary",
            },
            {
              name: "Pregnancy Test summary",
              path: "/report-pregnancy-test-summary",
            },
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("translate.inventoryReport.title"),
              path: "/report-inventory",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.closeout"),
              path: "/closeout",
            },
          ],
        },
        {
          icon: "mdi-cog",
          isDivider: true,
          name: this.getTranslation("navbar.admin"),
          subItems: [
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.myOrg"),
              path: "/organizations",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.myUsers"),
              path: "/users",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("Readers And Groups"),
              path: "/readers-and-groups",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("navbar.reviewDuplicateAnimals"),
              path: "/review-duplicate-animals",
            },
            {
              icon: "mdi-home",
              name: this.getTranslation("transferAnimals"),
              path: "/transfer-animals",
            },
          ],
        },
        {
          icon: "mdi-test-tube",
          name: "Beta",
          subItems: [
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.about"),
              path: "/about",
            },
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.myCommodities"),
              path: "/commodities",
            },
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.myFeedtrucks"),
              path: "/feed-trucks",
            },
          ],
        },
        {
          icon: "mdi-format-superscript",
          name: "Super",
          subItems: [
            {
              adminOnly: true,
              icon: "mdi-delete-forever",
              name: this.getTranslation("DB Purge"),
              path: "/superadmin-db-cleanup",
            },
            {
              adminOnly: true,
              icon: "mdi-delete-forever",
              name: "DB Triage",
              path: "/superadmin-db-triage",
            },
            {
              adminOnly: true,
              icon: "mdi-delete-forever",
              name: "DB Meta Dump",
              path: "/superadmin-db-dump",
            },
            {
              adminOnly: true,
              icon: "mdi-delete-forever",
              name: "DB Meta Fix",
              path: "/superadmin-db-fix",
            },
            {
              adminOnly: true,
              beta: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.feedlot"),
              path: "/alpha-feedlot-beta",
            },
            {
              adminOnly: true,
              icon: "mdi-home",
              name: this.getTranslation("Packers"),
              path: "/superadmin-packers",
            },
            {
              adminOnly: true,
              icon: "mdi-home",
              name: this.getTranslation("navbar.sickListEmails"),
              path: "/superadmin-sick-list-email",
            },
          ],
        },
      ];
    },
  },
};

export default HeaderMixin;
