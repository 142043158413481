var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu',{attrs:{"activeBadge":_vm.countForGroupNumber || '?'}}),_c('div',{staticClass:"mx-7 mx-md-6 mx-lg-8 pt-20"},[_c('v-row',[_c('v-col',{staticClass:"pb-sm-0",attrs:{"cols":"12","lg":"6"}},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body",class:{
              'bg-dark': _vm.$vuetify.theme.dark,
            }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#entry"}},[_vm._v("Calf Wean Entry ")])])])]):_vm._e(),_c('calf-wean-entry',{class:{ collapse: _vm.$isMobileScreen },attrs:{"id":"entry"}})],1),_c('v-col',{staticClass:"py-sm-0 d-flex flex-column",attrs:{"cols":"12","lg":"6"}},[_c('div',{style:({ order: _vm.$isMobileScreen ? 2 : 1 })},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body",class:{
                'bg-dark': _vm.$vuetify.theme.dark,
              }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#statistics"}},[_vm._v("Group Statistics")])])])]):_vm._e(),_c('calf-wean-group-statistics',{class:{ collapse: _vm.$isMobileScreen, 'mt-3': !_vm.$isMobileScreen },attrs:{"id":"statistics"}})],1),_c('div',{style:({ order: _vm.$isMobileScreen ? 1 : 2 })},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body",class:{
                'bg-dark': _vm.$vuetify.theme.dark,
              }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#information"}},[_vm._v("Animal Information")])])])]):_vm._e(),_c('calf-wean-information',{class:{ collapse: _vm.$isMobileScreen },attrs:{"id":"information"}})],1)])],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"py-sm-0 py-lg-auto"},[(_vm.$isMobileScreen)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"card card-body mb-4",class:{
              'bg-dark': _vm.$vuetify.theme.dark,
            }},[_c('h4',{staticClass:"panel-title"},[_c('a',{attrs:{"data-toggle":"collapse","href":"#summary"}},[_vm._v("Summary")])])])]):_vm._e(),_c('calf-wean-summary',{class:{ collapse: _vm.$isMobileScreen },attrs:{"id":"summary"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"280","persistent":"","scrollable":"","transition":"dialog-transition"},model:{value:(_vm.showAlerts),callback:function ($$v) {_vm.showAlerts=$$v},expression:"showAlerts"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',{staticClass:"text-h5 warning"},[_c('v-icon',[_vm._v("mdi-alert")]),_c('div',{staticClass:"ml-2"},[_vm._v("Alert")])],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center pa-0",staticStyle:{"height":"70px","width":"100%"}},[_c('div',{staticClass:"text-h5 text--primary"},[_vm._v("Cull status detected")])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.dark ? 'teal accent-2' : 'teal darken-1',"text":""},on:{"click":function($event){_vm.showAlerts = false}}},[_vm._v(" Okay ")])],1)],1)],1),(_vm.getStatus.toLowerCase() == 'cull')?_c('v-fab-transition',[_c('v-btn',{key:'showAlerts',staticClass:"v-btn--example",attrs:{"bottom":"","color":"warning","dark":"","elevation":"5","fab":"","fixed":"","large":"","right":""},on:{"click":function($event){_vm.showAlerts = true}}},[_c('v-icon',[_vm._v("mdi-alert")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }