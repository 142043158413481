import { isMobile } from "mobile-device-detect";
import { mapGetters } from "vuex";
import axios from "axios";
import TranslationMixin from "../mixins/Translations";
import Utils from "../utils/classes/Utils";
import Vue from "vue";

export const EventBus = new Vue();

Vue.prototype.$DAOPouches = new DAOPouches(EventBus);
Vue.prototype.$utils = Utils;
Vue.prototype.$moment = moment;

let loadUnixExpiry;
let herdMeta;
let pouchDbInstance;
export const Config = {
  data: () => ({
    $google: null,
  }),
  computed: {
    ...mapGetters({
      $locale: "User/getLocale",
      $nameInitials: "User/getNameInitials",
      $organizationID: "Organization/getOrganizationID",
      $organizationName: "Organization/getName",
      $pouchPass: "User/getPouchPass",
      $superAdmin: "User/getSuperAdmin",
      $timezone: "User/getTimezone",
      $token: "User/getToken",
      $userID: "User/getUserID",
    }),
    $pouchdb: function () {
      if (!pouchDbInstance) {
        pouchDbInstance = new DAOPouches(EventBus).setUpPluginsAndPouches(
          this.$organizationID,
          this.$pouchPass
        );
      }
      return pouchDbInstance;
    },
    $isMobileScreen: function () {
      if (isMobile) return true;

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return true;
        case "lg":
        case "xl":
          return false;
      }
    },
    // enforces a singleton for HerdMeta
    // allow update after 5s
    $herdMeta: function () {
      let currentUnix = moment().unix();
      if (herdMeta) {
        if (currentUnix > loadUnixExpiry) {
          loadUnixExpiry = currentUnix + 5;
          // heavy-handed, but safer than the alternative
          // ideal: update herdMeta after any document change occurs (or sync event occurs)
          herdMeta.reload();
        }
        return herdMeta;
      }

      loadUnixExpiry = currentUnix + 5;
      herdMeta = new HerdMeta(this.$pouchdb, TranslationMixin, Vue.notify);
      return herdMeta;
    },
  },
  methods: {
    isUserAdmin: function () {
      return axios
        .get("/api/admin/user-org-info", {
          params: { token: this.getToken() },
        })
        .then(({ data }) => {
          return {
            organizationAdmin: data.organizations.includes(
              this.$organizationID
            ),
            superAdmin: !!data.superAdmin,
          };
        })
        .catch((e) => {
          console.log(e.response.data);
          return e;
        });
    },
  },
};
