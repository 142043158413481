<template>
  <div ref="attlassianForm"></div>
</template>
<script>
export default {
  name: "AtlassianForm",
  mounted: function () {
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute(
      "src",
      "https://fortsupply.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=06644c5c"
    );
    this.$refs.attlassianForm.appendChild(scriptTag);
  },
};
</script>
