<template>
  <div>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <online-warning></online-warning>
      <v-col cols="12">
        <template>
          <v-alert type="error" color="red lighten-2" dismissible>
            This tool was created for use with fixing data on animals that have
            been transferred. If you do not understand why this tool was made,
            you probably do not want to use it.
          </v-alert>

          <p v-for="step in steps" :key="step.text">
            <v-btn
              :class="{
                warning:
                  step.error ||
                  (step.precondition && steps[step.precondition].error)
                    ? 'warning'
                    : '',
              }"
              :disabled="
                (step.precondition && !steps[step.precondition].completed) ||
                step.running ||
                (step.completed && !step.canRepeat)
              "
              @click="runstep(step)"
            >
              <v-icon v-if="step.completed">mdi-check-circle-outline</v-icon>
              <v-icon v-else-if="step.running">mdi-reload</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
              {{ step.text }}
            </v-btn>
            <template v-if="step.error">
              {{ step.textError }}
            </template>
          </p>

          <h1 v-if="steps.stepSendToMichael.completed">
            Now repeat with another organization!
          </h1>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DbDump",
  metaInfo: {
    title: "DB Dump",
  },
  data() {
    return {
      steps: {
        stepInitialMetaLoad: {
          cb: null,
          completed: false,
          fn: async () =>
            new Promise((resolve, reject) => {
              this.herdMeta.loaded.fail(reject).then(resolve);
            }),
          precondition: null,
          running: false,
          text: "Load metadata",
        },
        stepConfirmSyncIsComplete: {
          canRepeat: true,
          cb: "stepReloadMetaData",
          completed: false,
          fn: null,
          precondition: "stepInitialMetaLoad",
          running: false,
          text: "Confirm sync is complete",
        },
        stepReloadMetaData: {
          canRepeat: true,
          cb: "stepDumpData",
          completed: false,
          fn: async () =>
            new Promise((resolve, reject) => {
              this.herdMeta.reload().fail(reject).then(resolve);
            }),
          precondition: "stepConfirmSyncIsComplete",
          running: false,
          text: "Reload cache",
        },
        stepDumpData: {
          canRepeat: true,
          cb: "stepCopyData",
          completed: false,
          fn: async () =>
            new Promise((resolve, reject) => {
              const promises = [];
              this.data = {};

              const organizationId = this.$organizationID;
              promises.push(this.herdMeta.getMetaColorsAsync());
              promises[promises.length - 1].then((colors, colorsById) => {
                this.data.colors = colors.map((item) => ({
                  color: item.color,
                  id: item.id,
                  organizationId,
                }));
              });

              promises.push(this.herdMeta.getMetaHerdsAsync());
              promises[promises.length - 1].then((herds, herdsById) => {
                this.data.herds = herds.map((item) => ({
                  id: item.id,
                  name: item.name,
                  organizationId,
                }));
              });

              promises.push(this.herdMeta.getMetaLocationsAsync());
              promises[promises.length - 1].then((locations, locationsById) => {
                this.data.locations = locations.map((item) => ({
                  id: item.id,
                  name: item.name,
                  organizationId,
                }));
              });

              promises.push(this.herdMeta.getMetaMouthScoresAsync());
              promises[promises.length - 1].then(
                (mouthScores, mouthScoresById) => {
                  this.data.mouthScores = mouthScores.map((item) => ({
                    mouthScore: item.mouthScore,
                    id: item.id,
                    organizationId,
                  }));
                }
              );

              promises.push(this.herdMeta.getMetaOriginsAsync());
              promises[promises.length - 1].then((origins, originsById) => {
                this.data.origins = origins.map((item) => ({
                  id: item.id,
                  organizationId,
                  origin: item.origin,
                }));
              });

              promises.push(this.herdMeta.getMetaSexesAsync());
              promises[promises.length - 1].then((sexes, sexesById) => {
                this.data.sexes = sexes.map((item) => ({
                  sex: item.sex,
                  id: item.id,
                  organizationId,
                }));
              });

              promises.push(this.herdMeta.getMetaTagTableAsync());
              promises[promises.length - 1].then((tags, tagsById) => {
                this.data.tags = tags.map((item) => {
                  item.organizationId = organizationId;
                  return item;
                });
              });

              promises.push(this.herdMeta.getMetaVaccinationsAsync());
              promises[promises.length - 1].then(
                (treatments, treatmentsById) => {
                  this.data.treatments = treatments.map((item) => {
                    item.organizationId = organizationId;
                    return item;
                  });
                }
              );

              $.when.apply($, promises).then((_) => resolve());
            }),
          precondition: "stepReloadMetaData",
          running: false,
          text: "Dump data",
        },
        stepCopyData: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: async () => {
            // console.error("data", this.data);
            const content = JSON.stringify(this.data, null, 2);
            await navigator.clipboard.writeText(content);

            // show message
            this.$notify({
              group: "forms",
              text: "Data copied to clipboard!.",
              title: "Copied",
              type: "success",
            });
            return false;
          },
          precondition: "stepDumpData",
          running: false,
          text: "Copy data",
        },
        stepSendToMichael: {
          canRepeat: true,
          cb: null,
          completed: false,
          fn: null,
          precondition: "stepCopyData",
          running: false,
          text: "Confirm: Sent to Michael",
        },
      },
      docs: {},
      herdMeta: null,
      pouches: null,
    };
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;

    this.runstep(this.steps.stepInitialMetaLoad);
  },
  methods: {
    runstep: async function (step) {
      step.running = true;
      if (step.fn) step.error = await step.fn();
      step.running = false;
      step.completed = true;

      if (!step.cb) {
        return;
      }

      this.runstep(this.steps[step.cb]);
    },
  },
};
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>