<template>
  <div class="mr-lg-2 pa-2">
    <v-row>
      <v-col cols="12" class="pa-lg-8">
        <div class="my-lg-10 mb-md-10">
          <h4>{{ labels.dispositionDetails[locale] }}</h4>
          <v-divider></v-divider>
        </div>
        <div v-if="isLoading" class="d-flex justify-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-show="!isLoading">
          <table
            id="dispositionDetailsTable"
            class="table table-striped table-bordered w-100"
            :class="{
              'table-dark-mode': $vuetify.theme.dark,
              'table-dark': $vuetify.theme.dark,
            }"
          >
            <thead>
              <tr>
                <th>{{ labels.timeRecorded[locale] }}</th>
                <th>{{ labels.tag[locale] }}</th>
                <th>{{ labels.visual[locale] }}</th>
                <th>{{ labels.antenna[locale] }}</th>
                <th>{{ labels.location[locale] }}</th>
                <th>AnimalId</th>
              </tr>
            </thead>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TranslationMixin from "../mixins/Translations";

export default {
  name: "DispositionDetails",
  mixins: [TranslationMixin],
  metaInfo: {
    title: "Disposition Details",
  },
  data: () => ({
    herdMeta: null,
    isLoading: true,
    tableData: [],
  }),
  computed: {
    labels: function () {
      return this.translate;
    },
    locale: function () {
      return this.localeLang;
    },
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.makeTable();
    },
    makeTable: async function () {
      const self = this;
      let paging = true;
      if (this.$route.params.paging === "false") paging = false;
      this.tableData = await this.getDispositionDetailsForTable();
      $("#dispositionDetailsTable").DataTable({
        data: this.tableData,
        destroy: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search",
        },
        buttons: [
          { extend: "copyHtml5", className: "btn mb-2 text-white" },
          { extend: "excelHtml5", className: "btn mb-2 text-white" },
          { extend: "csvHtml5", className: "btn mb-2 text-white" },
        ],
        columns: [
          {
            data: "timeRecorded",
            render: function (_data, _type, row, _meta) {
              return self.$utils.renderValueAs(
                row.timeRecorded,
                "datetime",
                true
              );
            },
          },
          {
            data: "eid",
            render: function (data, _type, row, _meta) {
              if (!row.animalId) return data;
              return `<a href='/animal-details?id=${row.animalId}' target="_blank">${data}</a>`;
            },
          },
          { data: "visual" },
          { data: "antenna" },
          { data: "location" },
          { data: "animalId", visible: false },
        ],
        order: [[1, "desc"]],
        dom: "Bfrtip",
        paging: paging,
      });
    },
    getDispositionDetailsForTable: function () {
      return new Promise((resolve, reject) => {
        const self = this;
        this.herdMeta
          .getDispositionDetails(
            self.$route.params.moveCombined,
            self.$organizationID
          )
          .always(() => {
            self.isLoading = false;
          })
          .fail((e) => {
            resolve([]);
          })
          .done((results) => {
            results = results.map((row) => {
              [
                "animalId",
                "antenna",
                "eid",
                "id",
                "location",
                "timeRecorded",
                "visual",
              ].forEach((column) => {
                // This is only necessary to make datatables happier. It does not like to have undefined Values.
                if (row[column]) return;
                row[column] = null;
              });

              return row;
            });
            resolve(results);
          });
      });
    },
  },
};
</script>