<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0" v-if="showTitle">Sex History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Sex_History_${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': animal.disallowChanges(),
          }"
          :disabled="animal.disallowChanges()"
          @click="deleteSex(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.gender="{ item }">
        <td>
          {{
            item.gender
              ? getLabelTranslation(`enums.genders.${item.gender}`)
              : null
          }}
        </td>
      </template>
      <template #item.timeRecorded="{ item }">
        {{ $utils.renderValueAs(item.timeRecorded, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "SexHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      herdMeta: null,
      pouches: null,
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
        },
        { text: this.getTranslation("sex"), value: "sex" },
        { text: this.getTranslation("gender"), value: "gender" },
        { text: this.getTranslation("timeRecorded"), value: "timeRecorded" },
        { text: this.getTranslation("createdOn"), value: "createdOn" },
      ];
    },
  },
  created: async function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    try {
      await this.init();
    } catch (error) {
      console.log(error);
      this.$notify({
        group: "forms",
        text: this.getTranslation("animalDetails.animalNoExist"),
        title: "Error",
        type: "error",
      });
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      const doc = await this.pouches.organization.get(this.animalID);
      this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      this.getSexHistory();
    },
    getSexHistory: function () {
      this.table.loading = true;
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.sexHistory || [];
          this.table.data.forEach((item) => {
            item.sex =
              item.sex ||
              this.animal.herdMeta.getNameById(
                item.sexId,
                this.animal.herdMeta.sexes,
                "sex"
              ) ||
              (this.animal.herdMeta.sexesById[item.sexId] &&
                this.animal.herdMeta.sexesById[item.sexId].sex);
          });
          this.table.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.table.loading = false;
        });
    },
    deleteSex: async function (item) {
      try {
        await this.animal.deleteRecord("sexes", item.id);
        this.getSexHistory();
        this.$emit("data-editted");
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.disable-cursor {
  cursor: default;
}
</style>