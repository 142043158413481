var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('in-page-menu'),_c('online-warning'),_c('v-row',{staticClass:"mx-4 mx-md-6 mx-lg-8 pt-20"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.loading)?_c('div',[_c('v-responsive',{staticClass:"d-flex align-center",attrs:{"height":_vm.loaderHeight,"width":"100vw"}},[_c('v-progress-circular',{staticClass:"d-flex justify-center mx-auto",attrs:{"color":_vm.loader.color,"value":_vm.loader.value,"rotate":"360","size":"250","width":"15"}},[_c('h2',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.loader.value)+" ")])])],1)],1):(_vm.sortedOrganizations.length == 0)?_c('div',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" No animals left to move ")])],1):_c('div',[_c('v-alert',{attrs:{"border":"top","color":"warning","colored-border":"","dismissible":"","elevation":"2"}},[_vm._v(" This page has loaded data for the following animals "),_c('br'),_vm._v(" If data for any of these animals changes before you transfer and you transfer it, it might appear here again. ")]),(_vm.reload.time)?_c('v-alert',{attrs:{"border":"top","color":"info","colored-border":"","elevation":"2"}},[(_vm.reload.required)?[(_vm.transfersInProgress > 0)?[_vm._v(" After transfer completes, page will reload. ")]:[_vm._v(" Reloading... ")]]:(_vm.transfersInProgress > 0)?[_vm._v(" Transfer is in progress. This page will reload after 5 minutes ("+_vm._s(_vm.reload.time)+") or after transfer if that is later. ")]:[_vm._v(" This page will reload after 5 minutes ("+_vm._s(_vm.reload.time)+"). ")]],2):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"font-size":"1.3rem","max-width":"300px"},attrs:{"append-icon":"mdi-calendar","clearable":"","dense":"","hide-details":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"ma-0 biggerFont",class:{ darkInputText: _vm.$vuetify.theme.dark },staticStyle:{"font-size":"1.7rem"}},[_vm._v(" Override Date ")])]},proxy:true}],null,true),model:{value:(_vm.form.overrideDate),callback:function ($$v) {_vm.$set(_vm.form, "overrideDate", $$v)},expression:"form.overrideDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.form.showCalendar),callback:function ($$v) {_vm.$set(_vm.form, "showCalendar", $$v)},expression:"form.showCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.form.showCalendar = false}},model:{value:(_vm.form.overrideDate),callback:function ($$v) {_vm.$set(_vm.form, "overrideDate", $$v)},expression:"form.overrideDate"}})],1),_vm._l((_vm.sortedOrganizations),function(toOrg){return _c('div',{key:toOrg.organizationId,staticClass:"mt-10"},[_c('h3',[_vm._v(_vm._s(toOrg.organizationName))]),(_vm.getStatus([toOrg.organizationId]).error != '')?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Error: "+_vm._s(_vm.getStatus([toOrg.organizationId]).error)+" ")]):_vm._e(),_vm._l((Object.keys(toOrg.groups)),function(groupNumber){return _c('div',{key:groupNumber,staticClass:"ml-10 mt-10"},[_c('h4',[_vm._v(" "+_vm._s(groupNumber)+" ("+_vm._s(toOrg.groups[groupNumber].length)+") "),_c('v-btn',{attrs:{"disabled":_vm.reload.required ||
                    _vm.getStatus([toOrg.organizationId, groupNumber])
                      .transferring},on:{"click":function($event){return _vm.transferGroupToReceivingRanch(toOrg, groupNumber)}}},[(
                      _vm.getStatus([toOrg.organizationId, groupNumber])
                        .transferring
                    )?_c('span',[_vm._v(" Transferring ")]):_c('span',[_vm._v(" Transfer ")])])],1),(
                  _vm.getStatus([toOrg.organizationId, groupNumber]).error != ''
                )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Error: "+_vm._s(_vm.getStatus([toOrg.organizationId, groupNumber]).error)+" ")]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"pb-0 pa-2 transfer-table",class:{
                    'dark-border': _vm.$vuetify.theme.dark,
                    border: !_vm.$vuetify.theme.dark,
                  },attrs:{"headers":_vm.tableHeaders,"items":toOrg.groups[groupNumber],"calculate-widths":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var animal = ref.item;
return [_c('tr',{key:animal.guid},[_c('td',{staticClass:"px-2",class:{
                          'border-left': !_vm.$vuetify.theme.dark,
                          'dark-border-left': _vm.$vuetify.theme.dark,
                        },staticStyle:{"width":"100px"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.reload.required ||
                            _vm.getStatus([toOrg.organizationId, groupNumber])
                              .transferring ||
                            _vm.getStatus([animal.id]).error != '' ||
                            _vm.getStatus([animal.id]).transferred ||
                            _vm.getStatus([animal.id]).transferring ||
                            _vm.getStatus([animal.id]).skipped},on:{"click":function($event){return _vm.transferGroupToReceivingRanch(
                              toOrg,
                              groupNumber,
                              [animal]
                            )}}},[_vm._v("Transfer")])],1),_c('td',{staticClass:"px-2",class:{
                          'border-left': !_vm.$vuetify.theme.dark,
                          'dark-border-left': _vm.$vuetify.theme.dark,
                        },staticStyle:{"width":"100px"}},[_c('v-btn',{staticClass:"error",attrs:{"disabled":_vm.reload.required ||
                            _vm.getStatus([toOrg.organizationId, groupNumber])
                              .transferring ||
                            _vm.getStatus([animal.id]).error != '' ||
                            _vm.getStatus([animal.id]).transferred ||
                            _vm.getStatus([animal.id]).transferring},on:{"click":function($event){return _vm.skipTransfer(animal.id)}}},[_vm._v(_vm._s(_vm.getStatus([animal.id]).skipped ? "Unskip" : "Skip"))])],1),_c('td',{staticClass:"d-flex align-center px-0",class:{
                          'border-left': !_vm.$vuetify.theme.dark,
                          'dark-border-left': _vm.$vuetify.theme.dark,
                        }},[_c('div',{staticClass:"px-3",staticStyle:{"font-size":"1.2rem"}},[(_vm.getStatus([animal.id]).skipped)?_c('span',[_vm._v("Skipped")]):(_vm.getStatus([animal.id]).transferred)?_c('span',[_vm._v("Transferred")]):(_vm.getStatus([animal.id]).transferring)?_c('span',[_vm._v("Transferring")]):(_vm.getStatus([animal.id]).error != '')?_c('span',[_vm._v(" Error "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":_vm.$vuetify.theme.dark,"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-octagram ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getStatus([animal.id]).error)+" ")])],1):_c('span',[_vm._v("Ready")])]),_c('v-divider',{staticClass:"mr-3",attrs:{"vertical":""}}),_c('div',[_c('router-link',{staticStyle:{"font-size":"1.2rem"},attrs:{"to":{
                              name: 'AnimalDetails',
                              query: { id: animal.id },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(animal.tagValues)+" ")])],1)],1),_c('td',{class:{
                          'border-left border-right': !_vm.$vuetify.theme.dark,
                          'dark-border-left dark-border-right':
                            _vm.$vuetify.theme.dark,
                        },staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(animal.groupNumber)+" ")]),_c('td',{class:{
                          'border-left border-right': !_vm.$vuetify.theme.dark,
                          'dark-border-left dark-border-right':
                            _vm.$vuetify.theme.dark,
                        },staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(animal.timeRecordedFriendly)+" ")]),_c('td',{class:{
                          'border-right': !_vm.$vuetify.theme.dark,
                          'dark-border-right': _vm.$vuetify.theme.dark,
                        }},[(animal.timeRecorded)?_c('v-btn',{attrs:{"disabled":!animal.timeRecorded},on:{"click":function($event){_vm.form.overrideDate = animal.timeRecorded.substring(
                              0,
                              10
                            )}}},[_vm._v(" Use date ")]):_vm._e()],1)])]}}],null,true)})],1)],1)})],2)})],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }