<template>
  <dashboard-card>
    <template #title-container>
      <v-row class="align-center d-flex mb-10">
        <v-col class="d-flex" cols="12" lg="7">
          <div class="mr-4">
            <v-icon :primary="!$vuetify.theme.dark">{{ "$pedigree" }}</v-icon>
          </div>
          <div class="dashboard-card-title font-weight-medium text-h5">
            Diversity
          </div>
        </v-col>
        <v-col
          class="align-center d-flex gap-x-4 justify-end ml-auto"
          cols="12"
          lg="5"
        >
          <v-select
            class="dashboard-select rounded-lg"
            dense
            elevation="0"
            flat
            hide-details="true"
            :items="criteriaItems"
            label=""
            solo
            v-model="criteria"
          />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="
                  align-center
                  d-inline-flex
                  justify-center
                  lighten-4
                  rounded-circle
                  success
                "
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>No errors/warning detected during computation.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template #content>
      <table>
        <tbody>
          <tr v-for="(item, $index) in items" :key="$index">
            <td class="pt-2 pb-2 pr-4">
              <div class="font-weight-bold text-md-h6 line-clamp-3">
                {{ item.name }}
              </div>
            </td>
            <td class="pt-2 pb-2 w-full">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :style="getItemStyles(item, $index)"
                    class="pl-2 pr-2 pt-3 pb-3 rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="truncate font-weight-bold">
                      {{ item.value }}
                    </span>
                    <div class="truncate mt-1">Head</div>
                  </div>
                </template>
                <div class="tooltip-width">
                  <strong>{{ item.value }}</strong> <br />
                  Head
                </div>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </dashboard-card>
</template>

<script>
export default {
  props: ["herdMeta", "pouches", "syncTime"],
  data() {
    return {
      counts: {
        color: {},
        herd: {},
        location: {},
        sex: {},
      },
      criteria: "Sex",
      criteriaItems: ["Color", "Herd", "Pasture", "Sex"],
      styleCache: {},
      stylePalette: [
        { background: "rgb(41, 80, 143)", color: "white" },
        { background: "rgb(115, 151, 206)", color: "black" },
        { background: "rgb(206, 203, 199)", color: "black" },
        { background: "rgb(240, 140, 15)", color: "white" },
        { background: "rgb(16, 27, 45)", color: "white" },
      ],
    };
  },
  computed: {
    items() {
      const items = [];
      switch (this.criteria) {
        case "Color":
          return Object.keys(this.counts.color)
            .sort()
            .map((key, idx) => ({
              classes: [],
              name: key,
              style: this.cachedStyleForKey(key),
              value: this.counts.color[key],
            }));
        case "Herd":
          return Object.keys(this.counts.herd)
            .sort()
            .map((key, idx) => ({
              classes: [],
              name: key,
              style: this.cachedStyleForKey(key),
              value: this.counts.herd[key],
            }));
        case "Pasture":
          return Object.keys(this.counts.location)
            .sort()
            .map((key, idx) => ({
              classes: [],
              name: key,
              style: this.cachedStyleForKey(key),
              value: this.counts.location[key],
            }));
        case "Sex":
          return Object.keys(this.counts.sex)
            .sort()
            .map((key, idx) => ({
              classes: [],
              name: key,
              style: this.cachedStyleForKey(key),
              value: this.counts.sex[key],
            }));
      }
    },
    maxValue() {
      return Math.max(...this.items.map((item) => item.value));
    },
  },
  created() {
    this.init();
  },
  methods: {
    cachedStyleForKey(key) {
      const lowerCaseKey = key.toLowerCase();
      if (this.styleCache[lowerCaseKey]) {
        return this.styleCache[lowerCaseKey];
      }

      return (this.styleCache[lowerCaseKey] =
        this.stylePalette[
          Object.keys(this.styleCache).length % this.stylePalette.length
        ]);
    },
    async init() {
      this.pouches.organization
        .animalSummaries(false, false, {
          group_level: 1,
          include_docs: false,
        })
        .then((results) => {
          if (results.rows.length == 0) return;
          this.counts = results.rows
            .map(({ value }) => value)
            .map(({ main }) => main)
            .map(({ color, herd, location, sex, status }) => ({
              color: color || "?",
              herd: herd || "?",
              isStatusPresent: null, // populated in sequential map call
              location: location || "?",
              sex: sex || "?",
              status: (status || "alive").toLowerCase(),
            }))
            .map((item) => ({
              ...item,
              isStatusPresent: ["alive", "cull", "marketable", "sick"].some(
                (s) => s === item.status
              ),
            }))
            .filter(({ isStatusPresent }) => isStatusPresent)
            .reduce(
              (reduction, item) => {
                if (item.color) {
                  if (reduction.color[item.color] == undefined)
                    reduction.color[item.color] = 0;
                  reduction.color[item.color]++;
                }
                if (item.herd) {
                  if (reduction.herd[item.herd] == undefined)
                    reduction.herd[item.herd] = 0;
                  reduction.herd[item.herd]++;
                }
                if (item.location) {
                  if (reduction.location[item.location] == undefined)
                    reduction.location[item.location] = 0;
                  reduction.location[item.location]++;
                }
                if (item.sex) {
                  if (reduction.sex[item.sex] == undefined)
                    reduction.sex[item.sex] = 0;
                  reduction.sex[item.sex]++;
                }

                return reduction;
              },
              {
                color: {},
                herd: {},
                location: {},
                sex: {},
              }
            );
        });
    },
    getColors(index) {
      const nextIndex = index % this.stylePalette.length;
      return this.stylePalette[nextIndex];
    },
    getItemStyles(item, index) {
      const width = (item.value * 100) / this.maxValue;
      const rounded = Math.round(width * 100) / 100;
      const roundedWidth = rounded < 5 ? "10%" : rounded + "%";
      return {
        width: roundedWidth,
        maxWidth: roundedWidth,
        ...this.getColors(index),
      };
    },
  },
  watch: {
    syncTime: {
      deep: false,
      handler: async function (val) {
        await this.init();
      },
    },
  },
};
</script>

<style scoped>
.gap-x-4 {
  column-gap: 1rem;
}
.w-full {
  width: 100%;
}
.tooltip-width {
  max-width: 10rem;
}
</style>
