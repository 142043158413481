<template>
  <div>
    <v-menu elevation="0" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="activator" text v-bind="attrs" v-on="on">
          <v-avatar color="#3465a1" size="35">
            <span class="font-weight-regular text-white">
              {{ $nameInitials }}
            </span>
          </v-avatar>
          <span
            class="align-sub dashboard-card-title font-weight-medium inline-block normal-case text-h6"
          >
            {{ getName }}
          </span>
        </v-btn>
      </template>
      <v-list class="px-2">
        <v-list-item to="/my-settings">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-cog</v-icon>
            Settings
          </v-list-item-title>
        </v-list-item>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title>
                <v-icon class="mr-2">mdi-translate</v-icon> Language
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              @click="changeLanguage(language)"
              dense
              link
              v-for="(language, index) in languages"
              :key="index"
            >
              <v-list-item-title>
                <v-icon v-if="language.key == localeLang">
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-icon v-else> mdi-checkbox-blank-circle-outline </v-icon>
                {{ language.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item @click.stop="toggleDarkMode">
          <v-list-item-title>
            <v-icon v-if="$vuetify.theme.dark" class="mr-2">
              mdi-moon-waning-crescent
            </v-icon>
            <v-icon v-else class="mr-2">mdi-white-balance-sunny</v-icon>
            Dark mode
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/faq">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-frequently-asked-questions</v-icon> Help
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/eula">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-file</v-icon> EULA
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/superadmin-legend" v-if="$superAdmin">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-map</v-icon> Legend
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="showLogoutDialog = true">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-logout</v-icon>
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <logout-dialog
      @close-logout-dialog="showLogoutDialog = false"
      v-model="showLogoutDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LogoutDialog from "@/components/dialogs/LogoutDialog";
import TranslationMixin from "@/mixins/Translations.js";

export default {
  name: "UserMenu",
  mixins: [TranslationMixin],
  components: { LogoutDialog },
  data() {
    return { showLogoutDialog: false };
  },
  computed: {
    ...mapGetters({
      getName: "User/getName",
    }),
  },
  methods: {
    ...mapActions({ setDarkMode: "User/setDarkMode" }),
    toggleDarkMode() {
      this.setDarkMode({ darkMode: !this.$vuetify.theme.dark });
    },
  },
};
</script>

<style scoped>
.activator >>> .v-btn__content {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.activator {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.theme--light .v-menu__content {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.07),
    0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.07);
}
.v-menu__content {
  z-index: 5000 !important;
}
.theme--light .v-list-item__title,
.theme--light .v-list-item__title .v-icon {
  color: #3465a1;
}
a.v-list-item:hover {
  text-decoration: none !important;
}
</style>
