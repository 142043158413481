<template>
  <div>
    <in-page-menu />
    <div class="mx-4 mx-md-6 mx-lg-8 pt-20" v-if="checked">
      <template v-if="affected">
        <h1 style="color: red">
          Affected!
          <span v-if="rowCount > 0">
            - Repaired {{ saved }} of {{ rowCount }} in batch</span
          >
          <span v-if="missed > 0"> - Missed {{ missed }}</span>
        </h1>
        <p>{{ $moment(timestamp).format("YYYY-MM-DD HH:mm") }}</p>
        <v-textarea
          class="mb-3"
          clearable
          dense
          hide-details
          label="place dump here"
          outlined
          rows="2"
          v-model="data"
        ></v-textarea>
        <v-btn
          @click="importPregData"
          :disabled="data == null || data.length == 0"
          >Import</v-btn
        >
      </template>
      <template v-else>
        <h1>Safe!</h1>
        <v-btn @click="exportPregData">Export</v-btn>
        <v-btn
          @click="copyToClipboard"
          :disabled="this.data == null || this.data.length == 0"
          >Copy to clipboard ({{ this.rowCount }})</v-btn
        >
        <template v-if="this.data != null">
          <br />
          <template v-if="this.data.length > 0">
            <v-textarea
              class="mb-3"
              clearable
              dense
              hide-details
              label="data dump"
              outlined
              readonly
              rows="2"
              v-model="data"
            ></v-textarea>
          </template>
          <template v-else>
            <h2>No data to dump</h2>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "DbTriage",
  metaInfo: {
    title: "DB Triage",
  },
  data() {
    return {
      checked: false,
      data: null,
      herdMeta: null,
      pouches: null,
      startOfProblem: moment("2023-02-18T01:20:00.000Z").toISOString(),
      missed: 0,
      rowCount: 0,
      saved: 0,
      timestamp: null,
    };
  },
  computed: {
    affected: function () {
      return this.timestamp !== null && this.timestamp > this.startOfProblem;
    },
  },
  created: function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;

    this.herdMeta.loaded.fail().then(() => {
      this.herdMeta.pouches.organization
        .query("local_views/lastAnimalEditTimeAllWithPregChecks")
        .then((result) => {
          this.checked = true;
          if (result.rows.length === 0) return;

          this.timestamp = moment(result.rows[0].value).toISOString();
        });
    });
  },
  methods: {
    importPregData: function () {
      this.herdMeta.loaded.fail().then(() => {
        const data = JSON.parse(this.data);
        this.missed = 0;
        this.rowCount = data.length;
        this.saved = 0;

        data.forEach(async ({ id, pregChecks }) => {
          const doc = await this.herdMeta.pouches.organization.get(id);

          let needsSave = false;
          doc.pregChecks = doc.pregChecks.map((itemBad) => {
            const found = pregChecks.find((item) => item.id === itemBad.id);

            if (found) {
              needsSave = true;
              console.error("found!");
            }

            return found || itemBad;
          });

          if (!needsSave) {
            this.missed++;
            return;
          }
          const animal = new Animal(id, this.herdMeta, doc, this.$userID);

          // force update
          animal
            .modify(
              "comments",
              null,
              "comment",
              "Force updated (triage fix for preg checks)",
              false,
              false,
              {
                deleted: true,
              }
            )
            .then(() => {
              this.saved++;
            });
        });
      });
    },
    exportPregData: function () {
      this.herdMeta.pouches.organization
        .query("local_views/dumpPregChecks")
        .then((result) => {
          this.rowCount = result.rows.length;

          if (result.rows.length === 0) {
            this.data = [];
            return;
          }

          this.data = JSON.stringify(
            result.rows.map((item) => ({
              id: item.id,
              pregChecks: item.value,
            })),
            null,
            2
          );
        });
    },
    copyToClipboard: async function () {
      // console.error("data", this.data);
      const content = this.data;
      await navigator.clipboard.writeText(content);

      // show message
      this.$notify({
        group: "forms",
        text: "Data copied to clipboard!.",
        title: "Copied",
        type: "success",
      });
    },
  },
};
</script>
<style lang="scss">
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
</style>