<template>
  <div>
    <in-page-menu :activeBadge="summaryTable.data.length" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col>
        <div class="d-flex align-left">
          <v-menu :close-on-content-click="false" max-height="500" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Toggle Visibility
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="index"
                v-for="(field, index) in [
                  'All',
                  ...Object.keys(visibilityState),
                ]"
              >
                <v-checkbox
                  class="ma-0"
                  hide-details
                  v-if="field == 'All'"
                  v-model="toggleVisibility"
                ></v-checkbox>
                <v-checkbox
                  class="ma-0"
                  hide-details
                  v-else
                  v-model="visibilityState[field]"
                ></v-checkbox>
                <v-subheader>
                  {{ getTranslation(field) }}
                </v-subheader>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Reorder -->
          <v-checkbox
            class="mt-0"
            hide-details
            v-model="form.reorderIsActivated"
          >
            <template #label>
              <div class="mt-2">Reorder</div>
            </template>
          </v-checkbox>
        </div>
        <v-row>
          <!-- Form -->
          <v-col cols="12" md="6">
            <v-form @submit.prevent="submitIntakeForm" v-model="form.isValid">
              <draggable draggable=".draggable">
                <search-animal
                  :allowedStatuses="['alive', 'cull', 'marketable', 'sick']"
                  :isDraggable="form.reorderIsActivated"
                  :resetFields="herdConnect.resetFields"
                  @search-result="getAnimalResult"
                  cardStyle
                  class="mb-3"
                  dense
                />
                <v-text-field
                  :append-icon="
                    herdConnect.results.eid === form.fields.eid
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New EID')"
                  :error="formErrorState.eid"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.EID]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.eid"
                >
                  <template #append-outer>
                    <v-checkbox class="ma-0" disabled hide-details></v-checkbox>
                    <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  :append-icon="
                    herdConnect.results.visual === form.fields.visual
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New Visual')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :error="formErrorState.visual"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.visual"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <span
                        @click="toggleIncrementVisual"
                        :class="
                          flagIncrementVisual ? 'btn-success' : 'btn-danger'
                        "
                        class="btn mr-2 p-0"
                      >
                        &nbsp;+1&nbsp;
                      </span>
                      <v-checkbox
                        :disabled="flagIncrementVisual"
                        class="ma-0"
                        hide-details
                        v-model="lockedState.visual"
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.location)"
                  :items="form.options.locations"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  label="Pasture or Pen"
                  outlined
                  return-object
                  v-if="visibilityState.location"
                  v-model="form.fields.location"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.location"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.location = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.status)"
                  :items="form.options.statuses"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Status"
                  outlined
                  return-object
                  v-if="visibilityState.status"
                  v-model="form.fields.status"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.status"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.status = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Backtag"
                  outlined
                  return-object
                  v-if="visibilityState.backtag"
                  v-model="form.fields.backtag"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.backtag"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.backtag = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Group"
                  outlined
                  v-if="visibilityState.group"
                  v-model="form.fields.group"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.group"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.group = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.positiveOrEmpty]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  label="Rectal Temperature"
                  outlined
                  v-if="visibilityState.rectalTemperature"
                  v-model="form.fields.rectalTemperature"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.rectalTemperature"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.rectalTemperature = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :items="form.options.colors"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="color"
                  label="Color"
                  outlined
                  return-object
                  v-if="visibilityState.color"
                  v-model="form.fields.color"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.color"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.color = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.positiveOrEmpty]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  label="Purchase Weight"
                  outlined
                  v-if="visibilityState.purchaseWeight"
                  v-model="form.fields.purchaseWeight"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.purchaseWeight"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.purchaseWeight = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.positiveOrEmpty]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  label="Weight"
                  outlined
                  v-if="visibilityState.weight"
                  v-model="form.fields.weight"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.weight"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.weight = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-row
                  :class="{ draggable: form.reorderIsActivated }"
                  class="ma-0"
                  v-if="visibilityState.purchasePriceItem"
                >
                  <v-col
                    class="d-flex align-center mb-3 pa-0"
                    cols="1"
                    style="max-width: 5%"
                    v-if="form.reorderIsActivated"
                  >
                    <v-icon> mdi-drag </v-icon>
                  </v-col>
                  <v-col
                    :cols="form.reorderIsActivated ? 10 : 11"
                    :style="{ 'min-width': minWidth }"
                    class="pa-0"
                  >
                    <v-row class="ma-0">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                          :rules="[rules.positiveOrEmpty]"
                          class="mb-3"
                          clearable
                          dense
                          hide-details="auto"
                          label="Purchase Price"
                          outlined
                          v-model="form.fields.purchasePriceItem.price"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.purchasePriceItem.price"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                          class="mb-3"
                          clearable
                          dense
                          hide-details
                          label="Type"
                          outlined
                          v-model="form.fields.purchasePriceItem.type"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.purchasePriceItem.type"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="d-flex align-center pa-0"
                    cols="1"
                    style="max-width: 4%"
                  >
                    <v-icon @click="visibilityState.purchasePriceItem = false">
                      mdi-eye-minus
                    </v-icon>
                  </v-col>
                </v-row>

                <v-textarea
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Comment"
                  outlined
                  rows="2"
                  v-if="visibilityState.comment"
                  v-model="form.fields.comment"
                >
                  >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.comment"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.comment = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-textarea>
                <v-row
                  :class="{ draggable: form.reorderIsActivated }"
                  class="ma-0"
                  v-if="visibilityState.vaccinationsList"
                >
                  <v-col
                    class="d-flex align-center mb-3 pa-0"
                    cols="1"
                    style="max-width: 5%"
                    v-if="form.reorderIsActivated"
                  >
                    <v-icon> mdi-drag </v-icon>
                  </v-col>
                  <v-col
                    :cols="form.reorderIsActivated ? 10 : 11"
                    :style="{ 'min-width': minWidth }"
                    class="pa-0"
                  >
                    <v-row class="ma-0">
                      <v-col
                        :key="idx"
                        class="pa-0"
                        cols="12"
                        v-for="({ treatment, doseGiven }, idx) in form.fields
                          .vaccinationsList"
                      >
                        <v-row class="ma-0">
                          <v-col cols="11" class="pa-0">
                            <v-row class="ma-0">
                              <v-col class="pa-0" cols="7" md="8">
                                <v-select
                                  :error="
                                    doseGiven && $utils.isWhitespace(treatment)
                                  "
                                  :items="form.options.treatments"
                                  :menu-props="{
                                    offsetY: true,
                                    closeOnClick: true,
                                  }"
                                  class="mb-3"
                                  clearable
                                  dense
                                  hide-details
                                  item-text="name"
                                  label="Treatment"
                                  outlined
                                  return-object
                                  v-model="
                                    form.fields.vaccinationsList[idx].treatment
                                  "
                                >
                                  <template #append-outer>
                                    <div class="d-flex align-center">
                                      <v-checkbox
                                        class="ma-0"
                                        hide-details
                                        v-model="
                                          lockedState.vaccinationsList[idx]
                                            .treatment
                                        "
                                      ></v-checkbox>
                                    </div>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col class="pa-0" cols="5" md="4">
                                <v-text-field
                                  :error="
                                    treatment && $utils.isWhitespace(doseGiven)
                                  "
                                  :rules="[rules.positiveOrEmpty]"
                                  class="mb-3"
                                  clearable
                                  dense
                                  hide-details="auto"
                                  label="Dose Administered"
                                  outlined
                                  type="number"
                                  v-model="
                                    form.fields.vaccinationsList[idx].doseGiven
                                  "
                                >
                                  <template #append-outer>
                                    <div class="d-flex align-center">
                                      <v-checkbox
                                        class="ma-0"
                                        hide-details
                                        v-model="
                                          lockedState.vaccinationsList[idx]
                                            .doseGiven
                                        "
                                      ></v-checkbox>
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col class="pa-0 d-flex align-center" cols="1">
                            <v-btn
                              @click="addVaccinationItem"
                              class="px-0 mb-3"
                              color="success"
                              max-width="36"
                              min-width="36"
                              v-if="
                                idx === form.fields.vaccinationsList.length - 1
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              @click="removeVaccinationItem(idx)"
                              class="px-0 mb-3"
                              color="error"
                              max-width="36"
                              min-width="36"
                              v-else
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="d-flex align-center pa-0"
                    cols="1"
                    style="max-width: 4%"
                  >
                    <v-icon @click="visibilityState.vaccinationsList = false">
                      mdi-eye-minus
                    </v-icon>
                  </v-col>
                </v-row>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="
                    !form.fields.sex ||
                    !form.fields.sex.sex ||
                    form.fields.sex.sex.length === 0
                  "
                  :items="form.options.sexes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="sex"
                  label="Sex"
                  outlined
                  return-object
                  v-if="visibilityState.sex"
                  v-model="form.fields.sex"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.sex"
                      ></v-checkbox>
                      <v-icon class="mt-1" @click="visibilityState.sex = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :items="form.options.origins"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="origin"
                  label="Origin"
                  outlined
                  return-object
                  v-if="visibilityState.origin"
                  v-model="form.fields.origin"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.origin"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.origin = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-row
                  :class="{ draggable: form.reorderIsActivated }"
                  class="ma-0"
                  v-if="visibilityState.commissionRate"
                >
                  <v-col
                    class="d-flex align-center mb-3 pa-0"
                    cols="1"
                    style="max-width: 5%"
                    v-if="form.reorderIsActivated"
                  >
                    <v-icon> mdi-drag </v-icon>
                  </v-col>
                  <v-col
                    :cols="form.reorderIsActivated ? 10 : 11"
                    :style="{ 'min-width': minWidth }"
                    class="pa-0"
                  >
                    <v-row class="ma-0">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                          :rules="[rules.positiveOrEmpty]"
                          class="mb-3"
                          clearable
                          dense
                          hide-details="auto"
                          label="Commission Rate (CWT)"
                          outlined
                          v-model="form.fields.commissionRate.cwt"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.commissionRate.cwt"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                          class="mb-3"
                          clearable
                          dense
                          hide-details
                          label="Type"
                          outlined
                          v-model="form.fields.commissionRate.type"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.commissionRate.type"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="d-flex align-center pa-0"
                    cols="1"
                    style="max-width: 4%"
                  >
                    <v-icon @click="visibilityState.commissionRate = false">
                      mdi-eye-minus
                    </v-icon>
                  </v-col>
                </v-row>

                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.positiveOrEmpty]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  label="Freight"
                  outlined
                  v-if="visibilityState.freight"
                  v-model="form.fields.freight"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.freight"
                      ></v-checkbox>
                      <v-icon @click="visibilityState.freight = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <!-- Birth Date is visible and saved only when animal has no birth date
                     Birth Date is hidden and not saved when animal already has birth date
                -->
                <div
                  :class="{ draggable: form.reorderIsActivated }"
                  v-if="!hasBirthDate"
                >
                  <v-menu
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="form.showBirthDateCalendar"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :error="
                          (animalIsNew || !hasBirthDate) &&
                          !form.fields.birthDate
                        "
                        :prepend-icon="
                          form.reorderIsActivated ? 'mdi-drag' : ''
                        "
                        @click:append="form.showBirthDateCalendar = true"
                        append-icon="mdi-calendar"
                        class="mb-3"
                        dense
                        hide-details
                        label="Birth Date"
                        outlined
                        readonly
                        style="max-width: 650px"
                        v-bind="attrs"
                        v-model="form.fields.birthDate"
                        v-on="on"
                      >
                        <template #append-outer>
                          <div class="d-flex align-center">
                            <v-checkbox
                              class="ma-0"
                              hide-details
                              v-model="lockedState.birthDate"
                            ></v-checkbox>
                            <v-icon class="mt-1" disabled>
                              mdi-eye-minus
                            </v-icon>
                          </div>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      @input="form.showBirthDateCalendar = false"
                      v-model="form.fields.birthDate"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="form.showPurchaseDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :class="{ draggable: form.reorderIsActivated }"
                      :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                      append-icon="mdi-calendar"
                      class="mb-3"
                      dense
                      hide-details
                      label="Purchase Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="form.fields.purchaseDate"
                      v-on:click:append="form.showDateCalendar = true"
                      v-on="on"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.purchaseDate"
                          ></v-checkbox>
                          <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="form.showPurchaseDateCalendar = false"
                    v-model="form.fields.purchaseDate"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="form.showProcessingDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :class="{ draggable: form.reorderIsActivated }"
                      :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                      append-icon="mdi-calendar"
                      class="mb-3"
                      dense
                      hide-details
                      label="Processing Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="form.fields.processingDate"
                      v-on:click:append="form.showDateCalendar = true"
                      v-on="on"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.processingDate"
                          ></v-checkbox>
                          <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="form.showProcessingDateCalendar = false"
                    v-model="form.fields.processingDate"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Breed"
                  outlined
                  v-if="visibilityState.breed"
                  v-model="form.fields.breed"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.breed"
                      ></v-checkbox>
                      <v-icon @click="visibilityState.breed = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Percent Cross"
                  outlined
                  v-if="visibilityState.percentCross"
                  v-model="form.fields.percentCross"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.percentCross"
                      ></v-checkbox>
                      <v-icon @click="visibilityState.percentCross = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :items="form.options.species"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Species"
                  outlined
                  return-object
                  v-if="visibilityState.species"
                  v-model="form.fields.species"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.species"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.species = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
              </draggable>
              <div class="mt-4">
                <div class="d-flex flex-wrap">
                  <v-btn
                    :disabled="!form.isValid"
                    class="light-blue darken-4 white--text flex-grow-1"
                    large
                    type="submit"
                  >
                    <template v-if="!animalIsNew">
                      {{ getLabelTranslation("save") }}
                    </template>
                    <template v-else>
                      {{ getTranslation("Save New") }}
                    </template>
                  </v-btn>
                  <v-btn
                    @click="clearFields(false)"
                    color="primary"
                    large
                    outlined
                  >
                    {{ getTranslation("Reset") }}
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-wrap">
                <herd-connect
                  :streamLF="streamEID"
                  :streamUHF="streamEID"
                  :streamVisual="streamVisual"
                  :streamWeight="streamWeight"
                />
              </div>
            </v-form>
          </v-col>
          <!-- Summary Table -->
          <v-col cols="12" md="6">
            <animal-information
              :animalInformation="animalInformation"
              :maxHeight="
                ['sm', 'xs'].includes($vuetify.breakpoint.name) ? 850 : 450
              "
              :type="3"
            />
          </v-col>
          <!-- Summary Table -->
          <v-col class="mb-5">
            <h3>Tested Today</h3>
            <!-- Table Controls -->
            <div class="d-flex">
              <export-button
                :changeValueOf="[
                  { prop: 'processingDateFriendly', value: 'processingDate' },
                ]"
                :disabled="!summaryTable.selectedRows.length"
                :headers="summaryTableHeaders"
                :items="summaryTable.selectedRows"
                filename="intakes"
              />
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                class="mb-2"
                clearable
                dense
                hide-details
                label="Search"
                outlined
                style="max-width: 300px"
                v-model="summaryTable.search"
              ></v-text-field>
            </div>
            <v-data-table
              :headers="summaryTableHeaders"
              :items-per-page="5"
              :items="summaryTable.data"
              :search="summaryTable.search"
              class="d-flex flex-column"
              mobile-breakpoint="0"
              show-select
              sortBy="processingDateFriendly"
              sortDesc
              v-model="summaryTable.selectedRows"
            >
              <template #item.tagValues="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.id },
                  }"
                  class="subtitle-2 text-none"
                  target="_blank"
                >
                  {{ item.tagValues }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <cull-alert :status="animalStatus" />
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "Intake",
  mixins: [TranslationMixin],
  metaInfo: {
    title: "Intake",
  },
  data() {
    const formFields = {
      backtag: null,
      birthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      breed: null,
      color: null,
      comment: null,
      commissionRate: {
        cwt: null,
        type: null,
      },
      eid: null,
      freight: null,
      group: null,
      location: null,
      origin: null,
      percentCross: null,
      purchaseDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      purchasePriceItem: {
        price: null,
        type: null,
      },
      purchaseWeight: null,
      processingDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      rectalTemperature: null,
      sex: null,
      species: null,
      status: null,
      vaccinationsList: [{ treatment: null, doseGiven: null }],
      visual: null,
      weight: null,
    };
    const copyFormFields = () => JSON.parse(JSON.stringify(formFields));
    const setValueToObject = (obj, value) =>
      Object.fromEntries(Object.keys(obj).map((key) => [key, value]));
    return {
      animal: null,
      animalInformation: {
        guid: null,
        tagValues: null,
        fields: {
          damTags: { guid: null, translateWord: "damTags", value: null },
          lastWeight: { translateWord: "Last Weight", value: null },
          sex: { translateWord: "sex", value: null },
          color: { translateWord: "color", value: null },
          comment: { translateWord: "comment", value: null },
          status: { translateWord: "status", value: null },
          headDays: { translateWord: "headDays", value: null },
          totalEventIncome: { translateWord: "totalEventIncome", value: null },
          group: { translateWord: "Group", value: null },
          location: { translateWord: "pastureSlashPen", value: null },
          adgTotal: { translateWord: "adgTotal", value: null },
          adgLastTwo: { translateWord: "adg2", value: null },
          origin: { translateWord: "origin", value: null },
          breed: { translateWord: "breed", value: null },
          gender: { translateWord: "gender", value: null },
          birthDate: { translateWord: "birthDate", value: null },
        },
      },
      flagIncrementVisual: false,
      herdMeta: null,
      form: {
        fields: copyFormFields(),
        isValid: false,
        options: {
          colors: [],
          locations: [],
          origins: [],
          sexes: [],
          species: [],
          statuses: [],
          treatments: [],
        },
        reorderIsActivated: false,
        showBirthDateCalendar: false,
        showPurchaseDateCalendar: false,
        showProcessingDateCalendar: false,
      },
      herdConnect: {
        resetFields: false,
        results: {
          eid: null,
          visual: null,
          weight: null,
        },
      },
      lockedState: {
        ...setValueToObject(copyFormFields(), false),
        commissionRate: {
          cwt: false,
          type: false,
        },
        purchasePriceItem: {
          price: false,
          type: false,
        },
        vaccinationsList: [{ treatment: false, doseGiven: false }],
      },
      pouches: null,
      rules: {
        EID: (value) =>
          // convert null to string and numbers to string and strings to string
          /^$|^\d{15}$/.test(
            (value == null ? "" : value + "")
              .replace(/-/g, "")
              .replace(/ /g, "")
          ) || "Invalid ID",
        positiveOrEmpty: (number) =>
          +number > 0 || !number ? true : "Number must be positive or empty",
      },
      summaryTable: {
        data: [],
        search: null,
        selectedRows: [],
      },
      visibilityState: {
        backtag: true,
        breed: true,
        color: true,
        comment: true,
        commissionRate: true,
        eid: true,
        freight: true,
        group: true,
        origin: true,
        percentCross: true,
        purchasePriceItem: true,
        purchaseWeight: true,
        rectalTemperature: true,
        sex: true,
        species: true,
        status: true,
        vaccinationsList: true,
        weight: true,
      },
    };
  },
  computed: {
    hasBirthDate: function () {
      const animal = this.animal;
      return (
        !!animal &&
        !!animal.doc &&
        !!animal.doc.derived &&
        !!animal.doc.derived.summaries &&
        !!animal.doc.derived.summaries.main &&
        !!animal.doc.derived.summaries.main.birthDate
      );
    },
    minWidth: function () {
      return this.form.reorderIsActivated ? "91%" : "96%";
    },
    animalIsNew: function () {
      return !this.animal || this.animal.animalIsNew;
    },
    animalStatus: function () {
      const animal = this.animal;
      return animal &&
        animal.doc &&
        animal.doc.derived &&
        animal.doc.derived.summaries &&
        animal.doc.derived.summaries.main
        ? animal.doc.derived.summaries.main.status
        : "Alive";
    },
    formErrorState: function () {
      const eidEmpty =
        this.form.fields.eid == null || this.form.fields.eid.trim() == "";
      const visualEmpty =
        this.form.fields.visual == null || this.form.fields.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.fields.eid);

      // NOTE: Add more fields with similar logic if you need it
      // Error state does not expect an error message instead only expects a boolean value
      return {
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? true
            : false,
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? true
            : false,
      };
    },
    summaryTableHeaders: function () {
      return [
        {
          text: this.getTranslation("Tag IDs"),
          value: "tagValues",
        },
        {
          text: this.getTranslation("processingDate"),
          value: "processingDateFriendly",
        },
        {
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
        },
        {
          text: this.getTranslation("color"),
          value: "color",
        },
        {
          text: this.getTranslation("sex"),
          value: "sex",
        },
        {
          text: this.getTranslation("status"),
          value: "status",
        },
        {
          text: this.getTranslation("comment"),
          value: "comment",
        },
        {
          text: this.getTranslation("weight"),
          value: "weight",
        },
        {
          text: this.getTranslation("purchaseWeight"),
          value: "purchaseWeight",
        },
        {
          text: this.getTranslation("purchasePrice"),
          value: "purchasePrice",
        },
      ];
    },
    toggleVisibility: {
      set: function (val) {
        this.$utils.setObjectValues(this.visibilityState, val);
      },
      get: function () {
        return this.$utils.evaluateObjectValues(this.visibilityState, true);
      },
    },
  },
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("eid-not-pre-existing");
    EventBus.$off("eid-pre-existing");
    EventBus.$off("visual-not-pre-existing");
    EventBus.$off("visual-pre-existing");
  },
  created: async function () {
    this.init();
    this.getPreviousData();
    await this.populateSummaryTable();
    this.setUpEventWatches();
  },
  methods: {
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    setUpEventWatches: function () {
      EventBus.$tabEvent.on("consumed", ({ type, value }) => {
        if (type === "eid" && this.form.fields.eid === value)
          this.form.fields.eid = null;
        if (type === "visual" && this.form.fields.visual === value)
          this.form.fields.visual = null;
        if (type === "weight" && this.form.fields.weight === value)
          this.form.fields.weight = null;

        if (type === "eid" && this.herdConnect.results.eid === value)
          this.herdConnect.results.eid = null;
        if (type === "visual" && this.herdConnect.results.visual === value)
          this.herdConnect.results.visual = null;
        if (type === "weight" && this.herdConnect.results.weight === value)
          this.herdConnect.results.weight = null;
      });

      EventBus.$on(
        "eid-not-pre-existing",
        (eid) => (this.form.fields.eid = eid)
      );
      EventBus.$on(
        "eid-pre-existing",
        (eid) =>
          (this.form.fields.eid =
            this.form.fields.eid === eid ? "" : this.form.fields.eid)
      );
      EventBus.$on(
        "visual-not-pre-existing",
        (visual) => (this.form.fields.visual = visual)
      );
      EventBus.$on(
        "visual-pre-existing",
        (visual) =>
          (this.form.fields.visual =
            this.form.fields.visual === visual ? "" : this.form.fields.visual)
      );
    },
    streamEID: function (eid) {
      this.herdConnect.results.eid = eid;
    },
    streamVisual: function (visual) {
      this.herdConnect.results.visual = visual;
    },
    streamWeight: function (weight) {
      this.herdConnect.results.weight = weight;
    },
    toggleIncrementVisual: function () {
      this.lockedState.visual = this.flagIncrementVisual =
        !this.flagIncrementVisual;
    },
    incrementVisual: function () {
      if (this.form.fields.visual == null) return;

      const lastDigits = this.form.fields.visual.match(/(0*)(\d+)$/);
      if (!lastDigits) {
        this.$notify({
          group: "forms",
          text: "Cannot increment visual",
          title: "Error",
          type: "error",
        });
        return;
      }

      const prefix = this.form.fields.visual.substring(
        0,
        this.form.fields.visual.length - lastDigits[0].length
      );
      const newTagValue = `${prefix}${lastDigits[1]}${
        parseInt(lastDigits[2], 10) + 1
      }`;
      this.form.fields.visual = newTagValue;
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    getDefaultFormFields: function () {
      return this.$options.data().form.fields;
    },
    getPreviousData: function () {
      const formFields = this.getDefaultFormFields();
      this.form.fields =
        JSON.parse(localStorage.getItem(`${this.$userID}.intakeFormValues`)) ||
        formFields;
      this.flagIncrementVisual =
        JSON.parse(
          localStorage.getItem(`${this.$userID}.flagIncrementVisual`)
        ) || false;
      this.lockedState = JSON.parse(
        localStorage.getItem(`${this.$userID}.intakeLockedState`)
      ) || {
        ...this.$utils.setValueToObject(
          this.$utils.copyObject(formFields),
          false
        ),
        commissionRate: {
          cwt: false,
          type: false,
        },
        purchasePriceItem: {
          price: false,
          type: false,
        },
        vaccinationsList: [{ treatment: false, doseGiven: false }],
      };

      this.visibilityState =
        JSON.parse(
          localStorage.getItem(`${this.$userID}.intakeVisibilityState`)
        ) || this.$utils.setValueToObject(this.getDefaultFormFields(), true);
    },
    getFormByLockedState: function () {
      const form = {};
      const keysToStore = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, true)
      );
      const keysToOmit = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, false)
      );
      keysToStore.forEach((key) => {
        form[key] = this.form.fields[key];
      });
      keysToOmit.forEach((key) => {
        if (key == "purchaseDate" || key == "processingDate")
          form[key] = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
        else form[key] = null;
      });
      const lockedState = this.$utils.copyObject(this.lockedState);
      form.vaccinationsList = lockedState.vaccinationsList;
      form.commissionRate = lockedState.commissionRate;
      form.purchasePriceItem = lockedState.purchasePriceItem;
      form.vaccinationsList.forEach((item, idx) => {
        form.vaccinationsList[idx].treatment = item.treatment
          ? this.form.fields.vaccinationsList[idx].treatment
          : null;
        form.vaccinationsList[idx].doseGiven = item.doseGiven
          ? this.form.fields.vaccinationsList[idx].doseGiven
          : null;
      });
      form.commissionRate.cwt = lockedState.commissionRate.cwt
        ? this.form.fields.commissionRate.cwt
        : null;
      form.commissionRate.type = lockedState.commissionRate.type
        ? this.form.fields.commissionRate.type
        : null;
      form.purchasePriceItem.price = lockedState.purchasePriceItem.price
        ? this.form.fields.purchasePriceItem.price
        : null;
      form.purchasePriceItem.type = lockedState.purchasePriceItem.type
        ? this.form.fields.purchasePriceItem.type
        : null;
      return form;
    },
    storeFormValues: function () {
      const formValues = this.getFormByLockedState();
      localStorage.setItem(
        `${this.$userID}.intakeFormValues`,
        JSON.stringify(formValues)
      );
    },
    submitIntakeForm: function () {
      this.addDataAndNewAnimalIfNecessary()
        .then(async (animal) => {
          this.clearFields(true);
          await this.populateSummaryTable();
        })
        .catch((e) => {
          this.$notify({
            group: "forms",
            text: "An error occurred",
            title: "Error",
            type: "error",
          });
          // This is just to see what kind of error we get
          // console.log(e);
        });
    },
    populateSummaryTable: async function () {
      this.summaryTable.data = await this.getSummaryTableData();
    },
    getSummaryTableData: function () {
      return new Promise((resolve, reject) => {
        HerdMeta.getDailyCreatedTodayAsync(this.pouches).then((results) => {
          const tableData = results
            .map((result) => result.value.summary)
            .map((item) => {
              item.processingDateFriendly = item.processingDate
                ? this.$utils.renderValueAs(
                    item.processingDate,
                    "datetime",
                    true
                  )
                : null;
              item.weight = item.firstWeight;
              return item;
            });
          resolve(tableData);
        });
      });
    },
    addDataAndNewAnimalIfNecessary: function () {
      return new Promise((resolve, reject) => {
        this.animal =
          this.animal || HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);
        this.addDataAsync(
          this.animal,
          this.animal.animalIsNew,
          this.animal.animalIsNew ? "New Intake" : null
        )
          .then((animal) => {
            resolve(animal);
          })
          .catch(reject);
      });
    },
    addDataAsync: function (animal, isNew, reason) {
      return new Promise(async (resolve, reject) => {
        const promises = [];
        const notifications = [];

        const comment = this.form.fields.comment;
        const commentId = comment ? this.$utils.guid() : null;
        const userId = this.$userID;

        let timeRecordedISO = moment(
          moment(this.form.fields.processingDate).format("YYYY-MM-DD")
        ).toISOString();
        const dateIsToday = moment().diff(timeRecordedISO, "days") === 0;

        if (dateIsToday) {
          // Re-init with time
          timeRecordedISO = new Date().toISOString();

          const coords = await this.$utils.getGeoLocation();
          if (coords.latitude || coords.longitude)
            promises.push(
              animal.modify("geopoints", null, "id", coords.id, false, true, {
                ...coords,
                reason: "Intake",
                timeRecorded: timeRecordedISO,
                userId,
              })
            );
        }

        const groupNumber = this.form.fields.group;
        const locationId =
          this.form.fields.location && this.form.fields.location.id;
        if (groupNumber || locationId) {
          promises.push(
            animal.modify(
              "movements",
              null,
              "locationId",
              locationId,
              false,
              true,
              {
                groupNumber,
                location: this.form.fields.location.name,
                reason,
                timeRecorded: timeRecordedISO,
                userId,
              }
            )
          );
        }

        if (this.form.fields.birthDate && !this.hasBirthDate) {
          promises.push(
            animal.modify(
              "birthDates",
              null,
              "birthDate",
              this.form.fields.birthDate,
              false,
              true,
              {
                timeRecorded: timeRecordedISO,
                userId,
              }
            )
          );
        }

        const eid = this.form.fields.eid;
        if (eid && eid.trim()) {
          const tag = {
            status: "active",
            tagId: null,
            tagValue: eid.trim(),
            timeRecorded: timeRecordedISO,
            type: "eid",
          };
          promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
        }

        const visual = this.form.fields.visual;
        if (visual && visual.trim()) {
          const tag = {
            status: "active",
            tagId: null,
            tagValue: visual.trim(),
            timeRecorded: timeRecordedISO,
            type: "visual",
          };
          promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
        }

        const backtag = this.form.fields.backtag;
        if (backtag) {
          const tag = {
            status: "inactive",
            tagId: null,
            tagValue: backtag,
            timeRecorded: timeRecordedISO,
            type: "backtag",
          };
          promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
        }

        const colorId = this.form.fields.color && this.form.fields.color.id;
        if (colorId)
          promises.push(
            animal.modify("colors", null, "colorId", colorId, false, true, {
              color: this.form.fields.color.color,
              commentId,
              timeRecorded: timeRecordedISO,
            })
          );

        const sexId = this.form.fields.sex && this.form.fields.sex.id;
        if (sexId) {
          promises.push(
            animal.modify("sexes", null, "sexId", sexId, false, true, {
              commentId,
              gender: this.form.fields.sex.gender,
              sex: this.form.fields.sex.sex,
              timeRecorded: timeRecordedISO,
              userId,
            })
          );

          const gender = this.form.fields.sex.gender;
          if (gender) {
            promises.push(
              animal.modify("genders", null, "gender", gender, false, true, {
                timeRecorded: timeRecordedISO,
                userId,
              })
            );
          }
        }

        const status = this.form.fields.status && this.form.fields.status.value;
        if (status)
          promises.push(
            animal.modify("status", null, "status", status, false, true, {
              commentId,
              timeRecorded: timeRecordedISO,
            })
          );

        const breed = this.form.fields.breed;
        if (breed)
          promises.push(
            animal.modify("breeds", null, "breed", breed, false, true, {
              timeRecorded: timeRecordedISO,
            })
          );

        const originId = this.form.fields.origin && this.form.fields.origin.id;
        if (originId)
          promises.push(
            animal.modify("origins", null, "originId", originId, false, true, {
              origin: this.form.fields.origin.origin,
              timeRecorded: timeRecordedISO,
            })
          );

        const percentCross = this.form.fields.percentCross;
        if (percentCross)
          promises.push(
            animal.modify(
              "percentCrosses",
              null,
              "percentCross",
              percentCross,
              false,
              true,
              { timeRecorded: timeRecordedISO }
            )
          );

        const species =
          this.form.fields.species && this.form.fields.species.value;
        if (species)
          promises.push(
            animal.modify("species", null, "species", species, false, true, {
              timeRecorded: timeRecordedISO,
            })
          );

        notifications.push("Updated characteristics where specified.");

        const weight = this.form.fields.weight;
        if (weight)
          promises.push(
            animal.modify("weights", null, "weight", weight, false, true, {
              commentId,
              timeRecorded: timeRecordedISO,
            })
          );

        {
          const commissionRate = this.form.fields.commissionRate.cwt;
          const purchasePrice = this.form.fields.purchasePriceItem.price;
          const purchaseWeight = this.form.fields.purchaseWeight;
          if (commissionRate || purchasePrice || purchaseWeight) {
            const freightCost = this.form.fields.freight;
            promises.push(
              animal.modify(
                "salesPurchases",
                null,
                "weight",
                purchaseWeight,
                false,
                true,
                {
                  commentId,
                  commissionType: this.form.fields.commissionRate.type,
                  freightCost: freightCost,
                  price: purchasePrice,
                  priceUnits: this.form.fields.purchasePriceItem.type,
                  purchaseCommissionRate: commissionRate,
                  transactionDate: moment(
                    this.form.fields.purchaseDate,
                    "YYYY-MM-DD"
                  ).toISOString(),
                  transactionType: "purchase",
                  weight: purchaseWeight,
                }
              )
            );
          }
        }

        // const picture = document.getElementById("imageCanvasBase64").value;
        // const pictureId = picture ? Utils.guid() : null;
        if (commentId)
          promises.push(
            animal.modify("comments", null, "comment", comment, false, true, {
              id: commentId,
              // pictureId: pictureId,
              timeRecorded: timeRecordedISO,
            })
          );

        // if (pictureId)
        //   promises.push(
        //     animal.modify("comments", null, "comment", comment, false, true, {
        //       id: commentId,
        //       pictureId: pictureId,
        //       timeRecorded: timeRecordedISO,
        //     })
        //   );

        // Because we only insert 1 row of pregcheck data, we collect only the first vaccinationId even if multiple treatments are provided
        const rectalTemperature = this.form.fields.rectalTemperature;
        const treatments = this.form.fields.vaccinationsList;
        treatments.forEach(({ treatment, doseGiven }) => {
          if (treatment && doseGiven) {
            promises.push(
              animal.modify(
                "treatments",
                null,
                "vaccinationsId",
                treatment.id,
                false,
                true,
                {
                  actualDose: doseGiven,
                  commentId,
                  id: this.$utils.guid(),
                  rectalTemperature,
                  vaccinationTime: timeRecordedISO,
                }
              )
            );
          }
        });

        $.when
          .apply($, promises)
          .fail((e) => {
            this.$notify({
              group: "forms",
              type: "error",
              title: "Error",
              text: "An error occurred.",
            });
            reject(e);
            // FIXME: Consider reloading the animal so temporary data isn't clobbering it
          })
          .done(() => {
            // Although clear will be called, update summary in case user wants that information
            this.getPreviousSummaryValuesAndPopulate(animal);
            $.when(animal.save())
              .fail(reject)
              .done(() => {
                if (isNew) {
                  this.$notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    duration: 10000,
                    text: [
                      `Animal created (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                      ...notifications,
                    ].join("<br />"),
                  });
                } else {
                  this.$notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    duration: 10000,
                    text: [
                      `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                      ...notifications,
                    ].join("<br />"),
                  });
                }

                resolve(animal);
              });
          });
      });
    },
    addVaccinationItem: function () {
      this.form.fields.vaccinationsList.push({
        treatment: null,
        doseGiven: null,
      });
      this.lockedState.vaccinationsList.push({
        treatment: false,
        doseGiven: false,
      });
    },
    removeVaccinationItem: function (idx) {
      this.form.fields.vaccinationsList.splice(idx, 1);
      this.lockedState.vaccinationsList.splice(idx, 1);
    },
    clearFields: function (programmatic) {
      if (programmatic) {
        if (this.form.fields.eid)
          EventBus.$tabEvent.emit("consumed", {
            type: "eid",
            value: this.form.fields.eid,
          });
        if (this.form.fields.visual)
          EventBus.$tabEvent.emit("consumed", {
            type: "visual",
            value: this.form.fields.visual,
          });
        if (this.form.fields.weight)
          EventBus.$tabEvent.emit("consumed", {
            type: "weight",
            value: this.form.fields.weight,
          });
      }

      const fieldsCleared = Object.fromEntries(
        Object.entries(
          this.$utils.filterObjectByValue(this.lockedState, false)
        ).map((pair) => {
          if (["purchaseDate", "processingDate"].includes(pair[0]))
            return [
              pair[0],
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            ];
          return [pair[0], null];
        })
      );
      // Clear vaccinations
      this.lockedState.vaccinationsList.map((vaccinationRow, rowIdx) => {
        if (!vaccinationRow.doseGiven)
          this.form.fields.vaccinationsList[rowIdx].doseGiven = null;
        if (!vaccinationRow.treatment)
          this.form.fields.vaccinationsList[rowIdx].treatment = null;
      });

      const formCleared = { ...this.form.fields, ...fieldsCleared };

      this.form.fields = formCleared || {
        backtag: null,
        breed: null,
        color: null,
        comment: null,
        commissionRate: {
          cwt: null,
          type: null,
        },
        eid: null,
        freight: null,
        group: null,
        location: null,
        origin: null,
        percentCross: null,
        purchaseDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        purchasePriceItem: {
          price: null,
          type: null,
        },
        purchaseWeight: null,
        processingDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        rectalTemperature: null,
        sex: null,
        species: null,
        status: null,
        vaccinationsList: [{ treatment: null, doseGiven: null }],
        visual: null,
        weight: null,
      };

      this.form.fields.eid = null;
      if (!this.lockedState.commissionRate.cwt)
        this.form.fields.commissionRate.cwt = null;
      if (!this.lockedState.commissionRate.type)
        this.form.fields.commissionRate.type = null;
      if (!this.lockedState.purchasePriceItem.price)
        this.form.fields.purchasePriceItem.price = null;
      if (!this.lockedState.purchasePriceItem.type)
        this.form.fields.purchasePriceItem.type = null;

      this.form.fields.visual =
        this.flagIncrementVisual || this.lockedState.visual
          ? this.form.fields.visual
          : null;
      if (this.flagIncrementVisual) {
        this.incrementVisual();
      }

      this.herdConnect.resetFields = !this.herdConnect.resetFields;
      this.herdConnect.results.eid =
        this.herdConnect.results.visual =
        this.herdConnect.results.weight =
          null;

      this.animal = null;
      this.resetAnimalInformation();
    },
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      this.herdMeta.loaded.then(async () => {
        await this.fillSelectOptions();
      });
    },
    getAnimalResult: async function ({ animal }) {
      if (animal) {
        this.animal = animal;
        this.getPreviousSummaryValuesAndPopulate(animal);
      } else {
        this.animal = null;
        this.resetAnimalInformation();
      }
    },
    resetAnimalInformation: function () {
      this.animalInformation = this.$options.data().animalInformation;
    },
    getPreviousSummaryValuesAndPopulate: function (animal) {
      if (!animal) return;
      const summary = animal.getFlatSummary();
      const headDays = Animal.computeHeadDays(summary);
      const information = this.animalInformation.fields;
      information.adgLastTwo.value = summary.adg2;
      information.adgTotal.value = summary.adgTotal;
      information.birthDate.value = summary.birthDate;
      information.breed.value = summary.breed;
      information.color.value = summary.color;
      information.comment.value = summary.comment;
      information.damTags.guid = summary.damId;
      information.damTags.value = summary.damTags;
      information.gender.value = summary.gender;
      information.group.value = summary.groupNumber;
      information.headDays.value = Animal.getAgeOfDuration(
        moment.duration(headDays, "days")
      );
      information.lastWeight.value = summary.currentWeight;
      information.origin.value = summary.origin;
      information.sex.value = summary.sex;
      information.status.value = summary.status;
      information.totalEventIncome.value = summary.totalEventIncome;
      this.animalInformation.guid = animal.guid;
      this.animalInformation.tagValues = summary.tagValues;
      information.location.value = summary.location;
      this.animal = animal;
    },
    fillSelectOptions: async function () {
      // From enums
      this.form.options.species = this.getEnumOptions("species");
      this.form.options.statuses = this.getEnumOptions("statuses");

      // From organization settings
      const locations = await this.herdMeta.getMetaLocationsAsync(
        true,
        true,
        true
      );
      this.form.options.locations = locations || [];
      const colors = await this.herdMeta.getMetaColorsAsync(true, true, true);
      this.form.options.colors = colors || [];
      const sexes = await this.herdMeta.getMetaSexesAsync(true, true, true);
      this.form.options.sexes = sexes || [];
      const origins = await this.herdMeta.getMetaOriginsAsync(true, true, true);
      this.form.options.origins = origins || [];
      const treatments = await this.herdMeta.getMetaVaccinationsAsync(
        true,
        true,
        true
      );
      this.form.options.treatments = treatments || [];
    },
  },
  watch: {
    "form.fields": {
      deep: true,
      handler: "storeFormValues",
    },
    flagIncrementVisual: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.flagIncrementVisual`,
          JSON.stringify(val)
        );
        this.storeFormValues();
      },
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect"),
          JSON.stringify(newVal)
        );

        if (newVal.eid) EventBus.$emit("eid-from-herd-connect", newVal.eid);
        if (newVal.visual)
          EventBus.$emit("visual-from-herd-connect", newVal.visual);
        if (+newVal.weight) this.form.fields.weight = "" + newVal.weight;
      },
    },
    lockedState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.intakeLockedState`,
          JSON.stringify(val)
        );
        this.storeFormValues();
      },
    },
    visibilityState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.intakeVisibilityState`,
          JSON.stringify(val)
        );
      },
    },
  },
};
</script>
<style>
.v-data-table__wrapper {
  order: 2;
}
.v-data-footer {
  order: 1;
}
</style>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
