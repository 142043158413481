<template>
  <div>
    <in-page-menu :activeBadge="activeBadge" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col>
        <div class="d-flex align-left">
          <v-menu :close-on-content-click="false" max-height="500" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Toggle Visibility
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="index"
                v-for="(field, index) in [
                  'All',
                  ...Object.keys(visibilityState),
                ]"
              >
                <v-checkbox
                  class="ma-0"
                  hide-details
                  v-if="field == 'All'"
                  v-model="toggleVisibility"
                ></v-checkbox>
                <v-checkbox
                  class="ma-0"
                  hide-details
                  v-else
                  v-model="visibilityState[field]"
                ></v-checkbox>
                <v-subheader>
                  {{ getTranslation(field) }}
                </v-subheader>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Reorder -->
          <v-checkbox
            class="mt-0"
            hide-details
            v-model="form.reorderIsActivated"
          >
            <template #label>
              <div class="mt-2">Reorder</div>
            </template>
          </v-checkbox>
        </div>
        <v-row>
          <!-- Form -->
          <v-col cols="12" md="6">
            <v-form @submit.prevent="submitCommentForm" v-model="form.isValid">
              <draggable draggable=".draggable">
                <!-- Currently: Allow all statuses -->
                <search-animal
                  :allowedStatuses="[
                    'alive',
                    'cull',
                    'dead',
                    'embryo',
                    'harvested',
                    'inTransit',
                    'lost',
                    'onFeed',
                    'marketable',
                    'missing',
                    'semen',
                    'sick',
                    'sold',
                    'stolen',
                  ]"
                  :isDraggable="form.reorderIsActivated"
                  :resetFields="herdConnect.resetFields"
                  @search-result="getAnimalResult"
                  cardStyle
                  class="mb-3"
                  dense
                />
                <v-text-field
                  :append-icon="
                    herdConnect.results.eid === form.fields.eid
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New EID')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :error="formErrorState.eid"
                  :rules="[rules.EID]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.eid"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        disabled
                        hide-details
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :append-icon="
                    herdConnect.results.visual === form.fields.visual
                      ? 'mdi-cellphone-link'
                      : ''
                  "
                  :class="{ draggable: form.reorderIsActivated }"
                  :label="getTranslation('New Visual')"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :error="formErrorState.visual"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="form.fields.visual"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        disabled
                        hide-details
                      ></v-checkbox>
                      <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.location)"
                  :label="getLabelTranslation('pastureSlashPen')"
                  :items="form.options.locations"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  outlined
                  return-object
                  v-if="visibilityState.location"
                  v-model="form.fields.location"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.location"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.location = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="$utils.isWhitespace(form.fields.status)"
                  :items="form.options.statuses"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  label="Status"
                  outlined
                  return-object
                  v-if="visibilityState.status"
                  v-model="form.fields.status"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.status"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.status = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-select
                  :class="{ draggable: form.reorderIsActivated }"
                  :items="form.options.sexes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  item-text="sex"
                  label="Sex"
                  outlined
                  return-object
                  v-if="visibilityState.sex"
                  v-model="form.fields.sex"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.sex"
                      ></v-checkbox>
                      <v-icon class="mt-1" @click="visibilityState.sex = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-select>
                <v-textarea
                  :class="{ draggable: form.reorderIsActivated }"
                  :error="
                    !form.fields.comment || form.fields.comment.length === 0
                  "
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Comment"
                  outlined
                  rows="2"
                  v-if="visibilityState.comment"
                  v-model="form.fields.comment"
                >
                  >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.comment"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.comment = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-textarea>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Group"
                  outlined
                  v-if="visibilityState.group"
                  v-model="form.fields.group"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.group"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.group = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  :class="{ draggable: form.reorderIsActivated }"
                  :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                  :rules="[rules.positiveOrEmpty]"
                  class="mb-3"
                  clearable
                  dense
                  hide-details="auto"
                  label="Rectal Temperature"
                  outlined
                  v-if="visibilityState.rectalTemperature"
                  v-model="form.fields.rectalTemperature"
                >
                  <template #append-outer>
                    <div class="d-flex align-center">
                      <v-checkbox
                        class="ma-0"
                        hide-details
                        v-model="lockedState.rectalTemperature"
                      ></v-checkbox>
                      <v-icon
                        class="mt-1"
                        @click="visibilityState.rectalTemperature = false"
                        >mdi-eye-minus</v-icon
                      >
                    </div>
                  </template>
                </v-text-field>
                <v-row
                  :class="{ draggable: form.reorderIsActivated }"
                  class="ma-0 d-flex align-center"
                >
                  <v-col
                    class="pa-0 mb-3 mr-2"
                    cols="1"
                    style="max-width: 24px"
                    v-if="form.reorderIsActivated"
                  >
                    <v-icon
                      @click="visibilityState.treatment = false"
                      class="mt-1"
                      >mdi-drag</v-icon
                    >
                  </v-col>
                  <v-col
                    :cols="form.reorderIsActivated ? 10 : 11"
                    class="pa-0 d-flex flex-grow-1 flex-shrink-0"
                    style="max-width: 100%"
                    v-if="visibilityState.treatment"
                  >
                    <v-row class="ma-0">
                      <v-col class="pa-0 mb-3" cols="12" md="6">
                        <v-select
                          :error="
                            form.fields.doseGiven &&
                            $utils.isWhitespace(form.fields.treatment)
                          "
                          :items="form.options.treatments"
                          :menu-props="{ offsetY: true, closeOnClick: true }"
                          clearable
                          dense
                          hide-details
                          item-text="name"
                          label="Treatment"
                          outlined
                          return-object
                          v-model="form.fields.treatment"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.treatment"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="pa-0 mb-3" cols="12" md="6">
                        <v-text-field
                          :error="
                            form.fields.treatment &&
                            $utils.isWhitespace(form.fields.doseGiven)
                          "
                          :rules="[rules.positiveOrEmpty]"
                          clearable
                          dense
                          hide-details="auto"
                          label="Dose Administered"
                          outlined
                          v-model="form.fields.doseGiven"
                        >
                          <template #append-outer>
                            <div class="d-flex align-center">
                              <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="lockedState.doseGiven"
                              ></v-checkbox>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-icon
                      @click="visibilityState.treatment = false"
                      class="mt-1"
                      >mdi-eye-minus</v-icon
                    >
                  </v-col>
                </v-row>

                <v-menu
                  :class="{ draggable: form.reorderIsActivated }"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="form.showDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :prepend-icon="form.reorderIsActivated ? 'mdi-drag' : ''"
                      append-icon="mdi-calendar"
                      class="mb-3"
                      dense
                      hide-details
                      label="Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="form.fields.date"
                      v-on:click:append="form.showDateCalendar = true"
                      v-on="on"
                    >
                      <template #append-outer>
                        <div class="d-flex align-center">
                          <v-checkbox
                            class="ma-0"
                            hide-details
                            v-model="lockedState.date"
                          ></v-checkbox>
                          <v-icon class="mt-1" disabled>mdi-eye-minus</v-icon>
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="form.showDateCalendar = false"
                    v-model="form.fields.date"
                  ></v-date-picker>
                </v-menu>
              </draggable>
              <div class="mt-4">
                <div class="d-flex flex-wrap">
                  <v-btn
                    :disabled="!form.isValid"
                    class="light-blue darken-4 white--text flex-grow-1"
                    large
                    type="submit"
                  >
                    <template v-if="!animalIsNew">
                      {{ getLabelTranslation("save") }}
                    </template>
                    <template v-else>
                      {{ getTranslation("Save New") }}
                    </template>
                  </v-btn>
                  <v-btn
                    @click="clearFields(false)"
                    color="primary"
                    large
                    outlined
                  >
                    {{ getTranslation("Reset") }}
                  </v-btn>
                </div>
              </div>
              <div class="mt-4">
                <div class="d-flex flex-wrap">
                  <herd-connect
                    :streamLF="streamEID"
                    :streamUHF="streamEID"
                    :streamVisual="streamVisual"
                  />
                </div>
              </div>
            </v-form>
          </v-col>
          <!-- Animal Information -->
          <v-col cols="12" md="6">
            <animal-information
              :animalInformation="animalInformation"
              :maxHeight="
                ['sm', 'xs'].includes($vuetify.breakpoint.name) ? 800 : 400
              "
              :type="3"
            />
          </v-col>
          <!-- Summary Table -->
          <v-col class="mb-5">
            <h3>Summary</h3>
            <!-- Table Controls -->
            <div class="d-flex">
              <export-button
                :disabled="!summaryTable.selectedRows.length"
                :headers="summaryTableHeaders"
                :items="summaryTable.selectedRows"
                filename="comments"
              />
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                class="mb-2"
                clearable
                dense
                hide-details
                label="Search"
                outlined
                style="max-width: 300px"
                v-model="summaryTable.search"
              ></v-text-field>
            </div>
            <v-data-table
              :headers="summaryTableHeaders"
              item-key="id"
              :items-per-page="5"
              :items="summaryTable.data"
              :search="summaryTable.search"
              class="d-flex flex-column"
              mobile-breakpoint="0"
              show-select
              v-model="summaryTable.selectedRows"
            >
              <template #item.tagSummary="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.animalId },
                  }"
                  class="subtitle-2 text-none"
                  target="_blank"
                >
                  {{ item.tagSummary }}
                </router-link>
              </template>
              <template #item.date="{ item }">
                {{ $utils.renderValueAs(item.date, "datetime", true) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <cull-alert :status="animalStatus" />
  </div>
</template>
<script>
import { EventBus } from "../mixins/Config";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "Comment",
  mixins: [TranslationMixin],
  metaInfo: {
    title: "Comment",
  },
  data() {
    const formFields = {
      comment: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      eid: null,
      doseGiven: null,
      group: null,
      location: null,
      rectalTemperature: null,
      status: null,
      sex: null,
      treatment: null,
      visual: null,
    };
    const copyFormFields = () => JSON.parse(JSON.stringify(formFields));
    const setValueToObject = (obj, value) =>
      Object.fromEntries(Object.keys(obj).map((key) => [key, value]));
    return {
      activeBadge: "?",
      animal: null,
      animalInformation: {
        guid: null,
        tagValues: null,
        fields: {
          lastWeight: { translateWord: "Last Weight", value: null },
          sex: { translateWord: "sex", value: null },
          color: { translateWord: "color", value: null },
          comment: { translateWord: "comment", value: null },
          status: { translateWord: "status", value: null },
          headDays: { translateWord: "headDays", value: null },
          totalEventIncome: { translateWord: "totalEventIncome", value: null },
          group: { translateWord: "group", value: null },
          location: { translateWord: "pastureSlashPen", value: null },
          adgTotal: { translateWord: "adgTotal", value: null },
          adgLastTwo: { translateWord: "adg2", value: null },
          origin: { translateWord: "origin", value: null },
          breed: { translateWord: "breed", value: null },
          gender: { translateWord: "gender", value: null },
        },
      },
      form: {
        fields: copyFormFields(),
        isValid: false,
        options: {
          locations: [],
          sexes: [],
          statuses: [],
          treatments: [],
        },
        reorderIsActivated: false,
        showDateCalendar: false,
      },
      herdConnect: {
        resetFields: false,
        results: {
          eid: null,
          visual: null,
        },
      },
      herdMeta: null,
      lockedState: setValueToObject(copyFormFields(), false),
      pouches: null,
      rules: {
        EID: (value) =>
          // convert null to string and numbers to string and strings to string
          /^$|^\d{15}$/.test(
            (value == null ? "" : value + "")
              .replace(/-/g, "")
              .replace(/ /g, "")
          ) || "Invalid ID",
        positiveOrEmpty: (number) =>
          +number > 0 || !number ? true : "Number must be positive or empty",
      },
      summaryTable: {
        data: [],
        search: null,
        selectedRows: [],
      },
      visibilityState: {
        comment: true,
        group: true,
        location: true,
        rectalTemperature: true,
        status: true,
        sex: true,
        treatment: true,
      },
    };
  },
  computed: {
    animalStatus: function () {
      const animal = this.animal;
      return animal &&
        animal.doc &&
        animal.doc.derived &&
        animal.doc.derived.summaries &&
        animal.doc.derived.summaries.main
        ? animal.doc.derived.summaries.main.status
        : "Alive";
    },
    animalIsNew: function () {
      return !this.animal || this.animal.animalIsNew;
    },
    formErrorState: function () {
      const eidEmpty =
        this.form.fields.eid == null || this.form.fields.eid.trim() == "";
      const visualEmpty =
        this.form.fields.visual == null || this.form.fields.visual.trim() == "";
      const eidValid = this.isValidEID(this.form.fields.eid);

      // NOTE: Add more fields with similar logic if you need it
      // Error state does not expect an error message instead only expects a boolean value
      return {
        eid:
          this.animalIsNew && ((eidEmpty && visualEmpty) || !eidValid)
            ? true
            : false,
        visual:
          this.animalIsNew && visualEmpty && (eidEmpty || !eidValid)
            ? true
            : false,
      };
    },
    summaryTableHeaders: function () {
      return [
        {
          text: "Tag Values",
          value: "tagSummary",
        },
        {
          text: this.getTranslation("comment"),
          value: "comment",
        },
        {
          text: this.getTranslation("date"),
          value: "date",
        },
      ];
    },
    toggleVisibility: {
      set: function () {
        this.$utils.setObjectValues(
          this.visibilityState,
          !this.toggleVisibility
        );
      },
      get: function () {
        return this.$utils.evaluateObjectValues(this.visibilityState, true);
      },
    },
  },
  beforeDestroy: function () {
    EventBus.$tabEvent.off("consumed");
    EventBus.$off("eid-not-pre-existing");
    EventBus.$off("eid-pre-existing");
    EventBus.$off("visual-not-pre-existing");
    EventBus.$off("visual-pre-existing");
  },
  created: function () {
    this.init();
    this.getPreviousData();
    this.setUpEventWatches();
  },
  methods: {
    init: function () {
      this.herdMeta = this.$herdMeta;
      this.pouches = this.herdMeta.pouches;
      this.herdMeta.loaded.then(async () => {
        this.populateSelectsOptions();
        this.summaryTable.data = await this.getSummaryTableData();
      });
    },
    isValidEID: function (val) {
      return this.rules.EID(val) != "Invalid ID";
    },
    getDefaultFormValues: function () {
      return {
        comment: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        doseGiven: null,
        eid: null,
        group: null,
        location: null,
        rectalTemperature: null,
        status: null,
        sex: null,
        treatment: null,
        visual: null,
      };
    },
    getPreviousData: function () {
      this.form.fields =
        JSON.parse(localStorage.getItem(`${this.$userID}.comment.form`)) ||
        this.getDefaultFormValues();
      this.lockedState =
        JSON.parse(
          localStorage.getItem(`${this.$userID}.comment.lockedState`)
        ) || this.$utils.setValueToObject(this.form.fields, false);
      this.visibilityState =
        JSON.parse(
          localStorage.getItem(`${this.$userID}.comment.visibilityState`)
        ) || this.$utils.setValueToObject(this.form.fields, true);
    },
    getFormValues: function () {
      const lockedFields = Object.keys(
        this.$utils.filterObjectByValue(this.lockedState, true)
      );
      const valuesToStore = this.$utils.filterObjectByArrayOfKeys(
        this.form.fields,
        lockedFields
      );
      return {
        ...this.getDefaultFormValues(),
        ...valuesToStore,
      };
    },
    storeFormValues: function () {
      const form = this.getFormValues();
      localStorage.setItem(
        `${this.$userID}.comment.form`,
        JSON.stringify(form)
      );
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    setUpEventWatches: function () {
      EventBus.$tabEvent.on("consumed", ({ type, value }) => {
        if (type === "eid" && this.form.fields.eid === value)
          this.form.fields.eid = null;
        if (type === "visual" && this.form.fields.visual === value)
          this.form.fields.visual = null;

        if (type === "eid" && this.herdConnect.results.eid === value)
          this.herdConnect.results.eid = null;
        if (type === "visual" && this.herdConnect.results.visual === value)
          this.herdConnect.results.visual = null;
      });

      EventBus.$on(
        "eid-not-pre-existing",
        (eid) => (this.form.fields.eid = eid)
      );
      EventBus.$on(
        "eid-pre-existing",
        (eid) =>
          (this.form.fields.eid =
            this.form.fields.eid === eid ? "" : this.form.fields.eid)
      );
      EventBus.$on(
        "visual-not-pre-existing",
        (visual) => (this.form.fields.visual = visual)
      );
      EventBus.$on(
        "visual-pre-existing",
        (visual) =>
          (this.form.fields.visual =
            this.form.fields.visual === visual ? "" : this.form.fields.visual)
      );
    },
    streamEID: function (eid) {
      this.herdConnect.results.eid = eid;
    },
    streamVisual: function (visual) {
      this.herdConnect.results.visual = visual;
    },
    clearFields: function (programmatic) {
      if (programmatic) {
        if (this.form.fields.eid)
          EventBus.$tabEvent.emit("consumed", {
            type: "eid",
            value: this.form.fields.eid,
          });
        if (this.form.fields.visual)
          EventBus.$tabEvent.emit("consumed", {
            type: "visual",
            value: this.form.fields.visual,
          });
      }

      this.form.fields = this.getFormValues();

      this.herdConnect.resetFields = !this.herdConnect.resetFields;
      this.herdConnect.results.eid = this.herdConnect.results.visual = null;

      this.animal = null;
      this.resetAnimalInformation();
    },
    populateSelectsOptions: async function () {
      this.form.options.locations =
        (await this.herdMeta.getMetaLocationsAsync(true, true, true, false)) ||
        [];
      this.form.options.sexes =
        (await this.herdMeta.getMetaSexesAsync(true, true, true)) || [];
      this.form.options.statuses = this.getEnumOptions("statuses");
      this.form.options.treatments =
        (await this.herdMeta.getMetaVaccinationsAsync(true)) || [];
    },
    submitCommentForm: function () {
      this.addDataAndNewAnimalIfNecessary()
        .then(async (animal) => {
          this.getPreviousSummaryValuesAndPopulate(animal);
          this.clearFields(true);
          this.summaryTable.data = await this.getSummaryTableData();
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            group: "forms",
            text: "An error occurred.",
            title: "Error",
            type: "error",
          });
        });
    },
    getSummaryTableData: function () {
      return new Promise((resolve, reject) => {
        this.pouches.organization
          .query("local_views/commentInField")
          .then((results) => {
            let activeBadge = 0;
            const today = this.$moment().startOf("day").toISOString();

            const tableData = results.rows
              .map((result) => {
                return result.value.comments.map((comment) => {
                  comment.animalId = result.id;
                  comment.date = moment(Animal.getEffectiveDate(comment));
                  comment.tagSummary = result.value.tagSummary;

                  if (today <= comment.date.toISOString()) {
                    activeBadge++;
                  }

                  return comment;
                });
              })
              .reduce((reduction, animalAndComments) => {
                reduction = reduction.concat(animalAndComments);
                return reduction;
              }, [])
              .sort((a, b) => b.date - a.date)
              .splice(0, 100);

            this.activeBadge = activeBadge;

            resolve(tableData);
          });
      });
    },
    getPreviousSummaryValuesAndPopulate: function (animal) {
      if (!animal) return;

      const summary = animal.getFlatSummary();
      this.animalInformation.guid = animal.guid;
      this.animalInformation.tagValues = summary.tagValues;
      const information = this.animalInformation.fields;
      information.adgLastTwo.value = summary.adg2;
      information.adgTotal.value = summary.adgTotal;
      information.breed.value = summary.breed;
      information.color.value = summary.color;
      information.comment.value = summary.comment;
      information.location.value = summary.location;
      information.gender.value = summary.gender;
      information.group.value = summary.groupNumber;
      const headDays = Animal.computeHeadDays(summary);
      information.headDays.value = Animal.getAgeOfDuration(
        moment.duration(headDays, "days")
      );
      information.lastWeight.value = summary.currentWeight;
      information.origin.value = summary.origin;
      information.sex.value = summary.sex;
      information.status.value = summary.status;
      information.totalEventIncome.value = summary.totalEventIncome;
      this.animal = animal;
    },
    addDataAndNewAnimalIfNecessary: function () {
      return new Promise((resolve, reject) => {
        this.animal =
          this.animal || HerdMeta.makeNewAnimal(this.herdMeta, this.$userID);

        this.addDataAsync(this.animal, this.animal.animalIsNew)
          .catch(reject)
          .then((animal) => {
            resolve(animal);
          });
      });
    },
    addDataAsync: function (animal, isNew) {
      return new Promise(async (resolve, reject) => {
        const promises = [];

        const comment = this.form.fields.comment;
        const commentId = comment && comment.length ? this.$utils.guid() : null;
        const userId = this.$userID;

        let timeRecordedISO = moment(
          this.form.fields.date,
          "YYYY-MM-DD"
        ).toISOString();
        const dateIsToday =
          moment()
            .startOf("day")
            .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;

        if (dateIsToday) {
          // Re-init with time
          timeRecordedISO = new Date().toISOString();

          const coords = await this.$utils.getGeoLocation();
          if (coords.latitude || coords.longitude)
            promises.push(
              animal.modify("geopoints", null, "id", coords.id, false, true, {
                ...coords,
                reason: "Comment",
                timeRecorded: timeRecordedISO,
                userId,
              })
            );
        }

        const groupNumber = this.form.fields.group;
        const locationId =
          this.form.fields.location && this.form.fields.location.id;
        if (groupNumber || locationId)
          promises.push(
            animal.modify(
              "movements",
              null,
              "locationId",
              locationId,
              false,
              true,
              {
                groupNumber,
                location: this.form.fields.location.name,
                timeRecorded: timeRecordedISO,
                userId,
              }
            )
          );

        const eid = this.form.fields.eid;
        const visual = this.form.fields.visual;

        if (eid && eid.trim().length) {
          const tag = {
            status: "active",
            tagId: null,
            tagValue: eid.trim(),
            timeRecorded: timeRecordedISO,
            type: "eid",
          };
          promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
        }

        if (visual && visual.trim().length) {
          const tag = {
            status: "active",
            tagId: null,
            tagValue: visual.trim(),
            timeRecorded: timeRecordedISO,
            type: "visual",
          };
          promises.push(animal.insertIDforAnimal(tag, !isNew, false, true));
        }

        const sexId = this.form.fields.sex && this.form.fields.sex.id;
        if (sexId) {
          promises.push(
            animal.modify("sexes", null, "sexId", sexId, false, true, {
              commentId,
              gender: this.form.fields.sex.gender,
              sex: this.form.fields.sex.sex,
              timeRecorded: timeRecordedISO,
            })
          );

          const gender = this.form.fields.sex.gender;
          if (gender) {
            promises.push(
              animal.modify("genders", null, "gender", gender, false, true, {
                timeRecorded: timeRecordedISO,
              })
            );
          }
        }

        const status = this.form.fields.status && this.form.fields.status.value;
        if (status)
          promises.push(
            animal.modify("status", null, "status", status, false, true, {
              commentId,
              timeRecorded: timeRecordedISO,
            })
          );

        // const picture = document.getElementById("imageCanvasBase64").value;
        // const pictureId = picture ? Utils.guid() : null;
        if (commentId)
          promises.push(
            animal.modify("comments", null, "comment", comment, false, true, {
              id: commentId,
              // pictureId: pictureId,
              timeRecorded: timeRecordedISO,
            })
          );

        // if (pictureId) {
        //   promises.push(DAOPouches.savePicture(pictureId));
        //   promises.push(
        //     DAO.insertPicture(
        //       Utils.guid(),
        //       picture,
        //       document.getElementById("imageCanvasType").value
        //     )
        //   );
        // }
        // document.getElementById("imageCanvasBase64").value = "";
        // document.getElementById("imageCanvasType").value = "";

        const rectalTemperature = this.form.fields.rectalTemperature;
        const actualDose = this.form.fields.doseGiven;
        const treatmentId =
          this.form.fields.treatment && this.form.fields.treatment.id;
        if (rectalTemperature !== null || this.form.fields.treatment !== null)
          promises.push(
            animal.modify(
              "treatments",
              null,
              "vaccinationsId",
              treatmentId,
              false,
              true,
              {
                actualDose,
                commentId,
                rectalTemperature,
                vaccinationTime: timeRecordedISO,
              }
            )
          );

        $.when
          .apply($, promises)
          .fail((e) => {
            this.$notify({
              group: "forms",
              text: "An error occurred.",
              title: "Error",
              type: "danger",
            });
            console.error(e);
            reject(e);
          })
          .done(() => {
            $.when(animal.save())
              .fail(reject)
              .done(() => {
                if (isNew) {
                  this.$notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    text: `Created animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                  });
                } else {
                  this.$notify({
                    group: "forms",
                    type: "success",
                    title: "Success",
                    text: `Updated animal (<a href='/animal-details?id=${animal.guid}' target="_blank">View</a>)`,
                  });
                }

                resolve(animal);
              });
          });
      });
    },
    getAnimalResult: function ({ animal }) {
      if (
        animal &&
        animal.doc &&
        animal.doc.derived &&
        animal.doc.derived.summaries &&
        animal.doc.derived.summaries.main
      ) {
        this.animal = animal;
        this.getPreviousSummaryValuesAndPopulate(animal);
      } else {
        this.animal = null;
        this.resetAnimalInformation();
      }
    },
    resetAnimalInformation: function () {
      this.animalInformation = {
        guid: null,
        tagValues: null,
        fields: {
          lastWeight: { translateWord: "Last Weight", value: null },
          sex: { translateWord: "sex", value: null },
          color: { translateWord: "color", value: null },
          comment: { translateWord: "comment", value: null },
          status: { translateWord: "status", value: null },
          headDays: { translateWord: "headDays", value: null },
          totalEventIncome: { translateWord: "totalEventIncome", value: null },
          group: { translateWord: "group", value: null },
          location: { translateWord: "pastureSlashPen", value: null },
          adgTotal: { translateWord: "adgTotal", value: null },
          adgLastTwo: { translateWord: "adg2", value: null },
          origin: { translateWord: "origin", value: null },
          breed: { translateWord: "breed", value: null },
          gender: { translateWord: "gender", value: null },
        },
      };
    },
  },
  watch: {
    "form.fields": {
      deep: true,
      handler: "storeFormValues",
    },
    "herdConnect.results": {
      deep: true,
      handler: function (newVal) {
        localStorage.setItem(
          this.orgUserScoping("herdConnect"),
          JSON.stringify(newVal)
        );

        if (newVal.eid) EventBus.$emit("eid-from-herd-connect", newVal.eid);
        if (newVal.visual)
          EventBus.$emit("visual-from-herd-connect", newVal.visual);
      },
    },
    lockedState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.comment.lockedState`,
          JSON.stringify(val)
        );
        this.storeFormValues();
      },
    },
    visibilityState: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          `${this.$userID}.comment.visibilityState`,
          JSON.stringify(val)
        );
      },
    },
  },
};
</script>
