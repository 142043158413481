var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.showTitle)?_c('h4',{staticClass:"mr-2 mb-0 title-style"},[_vm._v(" Heifer Test History ")]):_vm._e(),_c('export-button',{attrs:{"disabled":_vm.table.data.length == 0,"filename":("Heifer_Test_History_" + _vm.animalID + "-" + (_vm.$utils.renderValueAs(
        new Date().toISOString(),
        'datetime'
      ))),"headers":_vm.tableHeaders,"items":_vm.table.data,"buttonFormat":"icon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items-per-page":-1,"items":_vm.table.data,"loading":_vm.table.loading,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",class:{
          'disable-cursor': _vm.disabled,
        },attrs:{"disabled":_vm.disabled,"color":"error","max-width":"40","min-width":"40"},on:{"click":function($event){return _vm.deleteHeiferTest(item)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1)]}},{key:"item.createdOn",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.createdOn, "datetime", true))+" ")])]}},{key:"item.pelvicMeasure",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"custom-field",staticStyle:{"max-width":"80px"},attrs:{"disabled":_vm.disabled,"dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.animal.modify(
            'heiferTests',
            item.id,
            'pelvicMeasure',
            item.pelvicMeasure,
            true,
            false
          )}},model:{value:(item.pelvicMeasure),callback:function ($$v) {_vm.$set(item, "pelvicMeasure", $$v)},expression:"item.pelvicMeasure"}})]}},{key:"item.testCheckTime",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(_vm.$utils.renderValueAs(item.testCheckTime, "datetime", true))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }